import React,{useState,useEffect,useContext} from 'react'
import { Modal } from 'react-bootstrap';
import API from '../../../api/apis';
import SearchResults from '../../SearchResults';
import DetailSearch from '../../submission/DetailSearch';
import { currentWorkflow,getWorkflowUrl } from '../../ToReviewDetails/commonFunctions';
import Button from '../Button';
import UserContext from '../../../context/UserContext';
export default()=>{
    const user = useContext(UserContext);
    const [searchedData,setSearchedData]=useState([]);
    const [isNewWorkFlow,setIsNewWorkFlow]=useState(false);
    const [searchInput,setSearchInput]=useState(null);
    const [showAllModal,setShowAllModal]=useState(false);
    useEffect(()=>{
        searchTopResults();
    },[searchInput]);
   
    const  searchTopResults = async () => {       
        try {
            const res = await API.post(`views/search/top_five/total_count`, {
                value: searchInput||'',

            });         
            if (res && res.data && res.data.top_five) {
                setSearchedData(res.data.top_five)
            }
            else if (res && (res.data.length === 0)) {
                setSearchedData([])
            }
        }
        catch (error) {
            console.log(error);
            setSearchedData([]);
        }
    }
    return <div className='d-flex align-items-center position-relative mr-2'>
                {/* <input type="text" className="form-control input-sm position-absolute " placeholder='search' style={{ width: '150px', left: '-150px' }}></input> */}
                <DetailSearch
                              
                               onSelection={async(type,data)=>{                             
                                   switch (type) {
                                       case "CLEAR": setSearchedData([]);break;
                                       case "ITEM":
                                       let is_new_workflow=await currentWorkflow(user.userSystemId);
                                       let url= getWorkflowUrl(is_new_workflow,data.id);
                                       window.location = url; break;
                                       case "ALL":setSearchedData([]);setShowAllModal(true);break;
                                          
                                   }                                
                               }}
                               onSearch={e=>{   
                                setSearchInput("");    
                                setSearchInput(e);  
                               }} data={searchedData||[]}></DetailSearch>
                <Modal size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered show={showAllModal} onHide={e=>{setShowAllModal(false);}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Search Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showAllModal ?
                            <SearchResults searchText={searchInput}></SearchResults> :
                            null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={e=>{
                            setShowAllModal(false);
                        }}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
}