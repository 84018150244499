import React, { Component } from 'react'
import { Button, Card, Col, Form, Navbar, Row, Tabs,Container} from 'react-bootstrap';
import AmendmentModalEx from './AmendmentModalEx';
import {isCoordinator, showMessage ,userPermissions } from './ToReviewDetails/commonFunctions';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import API from '../api/apis';
import FileComponentEx from './common/file-component/FileComponentEx';
import FileComponent from './common/file-component/FileComponent';
import InputField from './common/input-fields/InputField';
import BootstrapModal from './common/modal/BootstrapModal';
import StickyNav from './common/sticky-nav/StickyNav';
import AmendmentModal from './AmendmentModal';
import { dateChange, dateStringformat, dateTimeformat, getUserInfo, uploadFile } from '../utility/helperFunctions';
import UserContext from '../context/UserContext';
import { yyyymmddFormat } from './common/dateFormat';
import HistoryFile from './submission/detailSubComponents/HistoryFile';
import ReviewFile from './submission/detailSubComponents/section-1/ReviewFile';
import Loader from './common/loader/Loader';
import { APPROVED, DECLINED } from '../consts';
import { stateToHTML } from 'draft-js-export-html';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import * as IIRBtn  from './common/Button';
import DTAButton from './common/Button/DTAButton';
     
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div 
    className="d-flex"    
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
export default class AmendmentDetailsEx2 extends Component {
    static contextType=UserContext
    state = {
        externalurl :  "http://localhost:1337",
        // owner_id:this.context.userSystemId,
        showLoader : false,
        showLoaderUpload : false ,
        action : "",
        loadTable : true ,        
        viewEditData: {},
        showHideModal: false,
        viewEditFlag: null,
        fileList: [],
        selectedFile: null,
        successFile: false,
        errorFile: false,
        showLoader: false,
        AmendmentDetails: null,
        auditData:[],
        trailData:[],
        activeAmendment:{},
        amendmentData:null
        
    }
    get inputFieldsArray() {
        return [
           
            {
                label: "Amendment Title",
                type: "text",
                name: "amendmentTitle",
                disabled: true,
                
            },
            {
                label: "Protocol Version Number",
                type: "text",
                name: "protocol_version_number",
                
            },
            {
                label: "Approval",
                type: "dropdown",
                placeholder: "Awaiting Review",
                name: "approval",
                options: [
                    "None",
                    "Awaiting Review",
                    "Approved",
                    "Declined",
                    "Under Review"
                ],
                
            },
            {
                label: "Decision Date",
                name: "decision_date",
                disabled: this.state.approval === "Approved" || this.state.approval === "Declined"  ? false : true,
                type: "date",
                
            },
            {
                label: "[EXT] Investigator Rationale",
                type: "textarea",
                name: "investigator_rationale",
               

            },


            {
                label: "Lilly Feedback",
                type: "text-editor",
                name: "feedback",
                onChange: this.onEditorChange,
                
                
              
            },
            {
                label: "Submission",
                type: "text",
                name: "submissionId",
                disabled:true

            },
            // {
            //     label: "Owner",
            //     type: "text",
            //     name: "owner_id",
            //     disabled:true

            // },
            // {
            //     label: "System Information",
            //     type: "alert-new",
            //     //variant: "secondary",
            //     disabled:true
            // },
           
            
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled:true
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
                disabled:true
            },
            


        ];
    }
     async getExternalUrl() {
        let response = '';
        try {
            response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
            externalurl: (response && response.data) ? response.data.exturl : "http://localhost:1337",
        })
    }

    async componentDidMount() {
        let _isCoordinator=isCoordinator(this.context);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            showLoader :  true,
            selectedRole: selectedRole,
            isCoordinator:_isCoordinator,
            access: {
                view: true,
                create: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
        await this.getExternalUrl();
       await this.getAmendmends();
      
    }
    getAuditList = async (id) => {
        try {
            const temp = [];
            const res = await API.get(`audit/amendment/${id}`)
            console.log(res)
            await this.getTrailList();

            this.state.trailData.forEach(x => {
                res.data.forEach(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        if(y.field==="created_at"){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):"";
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                             temp.push(y);
                         }
                         else{
                            y.new_value = (y.new_value);
                            y.old_value = (y.old_value)
                            temp.push(y);
                         }
                        
                    }
                    
                })
            })
            let sortedReviews = temp.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            this.setState({
                auditData: sortedReviews,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/amendment`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    changeShowState = async() => {
    
       
        
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false
        });
        if(!this.state.errorModal){
            this.handleModalCallBack()
        }
        
        this.getAmendmentById();
        //this.getAuditList();
        
    }
    handleDelete = async (fileName) => {
        swal({
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({
                            showLoader : true,
                            action : "delete"
                        })
                        const res =await API.post(`/user/multiple/delete/Amendment/${this.props.submissionId}/${this.state.activeAmendment.id}/${fileName}` , {updated_by : this.context.userSystemId});
                        this.setState({
                            successFile: true,
                            fileMessage: "File deleted!",
                            
                            showLoader : false
                        })

                    }
                    catch (err) {
                        this.setState({ 
                            showLoader : false,
                            errorModal: true });
                    }

                }
            });
            this.getAmendmentById(this.props.submissionId,this.state.activeAmendment.id);

    }
    handleChangeEvent = (e, name) => {
        // debugger;
        let { activeAmendment , activePublicationValue, amendmentData } = this.state;
        activeAmendment = amendmentData.find(p => p.id.toString() === e.value.toString());
        activePublicationValue = {name:e.name,value:e.value};
        this.setState({ activeAmendment, activePublicationValue }, () => {
            this.getAmendmentById();
        });
    }
    handleAmendmentDelete = async () => {
        swal({
            // title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`recycle/amendment/delete/${this.state.activeAmendment.id}/${this.props.submissionId}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getAmendmends(this.props.submissionId);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getAmendmends(this.props.submissionId);
                }
            });
    }
    clearFormData=(callBack,isSuccess)=>{
        this.setState({
            ...this.INITIAL_STATE
        },()=>{callBack(isSuccess);});
    }
    getEditorValueFromHtml = (html="") => {
        if(html){
            const blocksFromHTML = convertFromHTML(html);        
            const state = ContentState.createFromBlockArray(blocksFromHTML);
            return EditorState.createWithContent(state);
        }
        return "";
    }
    getAmendmends=async()=>{
        try {
            
            let {submission_id}=this.props.submissionId
            let publicationList = [];
            const res = await API.get(`amendment/submission/${this.props.submissionId}`)
            let activeAmendment=null;
            if(res.data&&res.data.length>0){
                res.data.map((item,index)=>{
                    item["amendmentTitle"] = item.title;
                });               
                activeAmendment=res.data[0];
                this.state["activePublicationValue"] ={name: res.data[0].amendmentTitle,value: res.data[0].id}; 
                publicationList = res.data.map((item, index) => {
                    return { name: item.title, value: item.id };
                })
            }
            this.setState({ amendmentData: res.data,activeAmendment,publicationList:[...publicationList] },()=>{
                if(this.state.activeAmendment){
                    this.getAmendmentById();
                }
                else {
                    return <div>no amendments</div>
                }    
            });
          
        }
        catch (error) {
           
        }
   }
   onEditorChange = (val, e) => {
    this.setState({
        [e]: val,
        hasChanged: true,
    });
};
   
    getAmendmentById = async () => {
        if(!(this.state.activeAmendment&&this.state.activeAmendment.id))
        {
            return false;
        }
        let {id}=this.state?.activeAmendment??"";
        let rowIndex=this.state.amendmentData.findIndex(p=>p.id===id);
        const res = await API.get(`amendment/getbyid/${id}`); 
        // console.log("check",res.data)
        if(res.data&&res.data.submission_id){
            const AmendmentDetails={...res.data};      
            await this.getFilesList(res.data.submission_id);
           res.data["protocol_version_number"]=res.data.protocol_version_number !==null ? res.data.protocol_version_number : '';
            res.data["feedback"]= res.data.feedback !=="" ? this.getEditorValueFromHtml(res.data.feedback) : "";
            res.data["investigator_rationale"]=res.data.investigator_rationale !== null ? res.data.investigator_rationale.replace(/<[^>]+>/g, '') : "";
            res.data["amendmentTitle"] = res.data.title;
            res.data["decision_date"] = res.data.decision_date !== "" && res.data.decision_date !== null ?
            dateStringformat(res.data.decision_date) : null;
            res.data["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
            res.data["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt):res.data.createdby ;
          
            this.setState({
                ... res.data,
                AmendmentDetails:AmendmentDetails,
                showLoader: false
            });
        }
        else{
            this.setState({
                ... res.data,
                AmendmentDetails:null,
                showLoader: false
            });
        }
       
    }
    onInputChange = async(e,eo) => {
        let { name, value,type } = eo;
        if (eo.type === "date" || eo.type==="text-editor") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                hasChanged:true,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
       
        else 
        { this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }
    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
            error:{}
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
           
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]} 
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                         
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={(e)=> this.onInputChange(e,field)}                      
                    />
                </Col>
            )
        });
    }
    getFilesList = async (submission_id) => {
        try {
            this.setState({
                showLoader: true,
                action: "fetching",
                loadTable: false
            })
            let {id}=this.state.activeAmendment;
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            this.setState({
                showLoader : false,
                loadTable : true,

                fileList: res.data?.filter(file => file.type === `Amendment_${id}`)
            });
        } catch (e) {
            this.setState({
                showLoader : false,
                loadTable : true
            })
            console.log(e)
        }
        console.log("fileList",this.state.fileList)
    }
   

    handleDownload = async (fileName) => {
        try {
            this.setState({
                showLoader : true,
                action : "download"
            })
           let {id}=this.state.activeAmendment;
           let {submission_id}=this.props;
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Amendment/${this.props.submissionId}/${id}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                        
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        showLoader : false,
                        successFile: true,
                        fileMessage:"File Downloaded Successfully!"
                    })
                });
        } catch (e) {
            this.setState({
                errorFile: true,
                showLoader : false
            })
            console.log(e);
        }
    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = this.state.approval === "Approved" || this.state.approval === "Declined"  ? 
        { decision_date: this.state.decision_date } : {}
        const errors = {}
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors }
            });
        });
        return is_valid;
    }
    handleAmendmentUpdate = async () => {
        if(this.formValidation())
        {  
        let state=this.state;
        delete state.submissionId;
        delete state.created_by;
        state.updated_by = this.context.userSystemId;
        state.feedback=state.feedback ? this.getHtmlFromEditorValue(state.feedback):"";
        state["protocol_version_number"]=state.protocol_version_number;
       /*  if(state.approval.toLowerCase()===APPROVED.toLowerCase() || state.approval.toLowerCase()===DECLINED.toLowerCase())
        {
            state.decision_date=new Date();
        } */
        state["decision_date"] = state.decision_date !== "" && state.decision_date !== null ?
        dateChange(state.decision_date) : null;
        try {
            const res = API.put(`amendment/update/`, state);
            this.setState({
                success_body: "Amendment has been Updated!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
                AmendmentDetails:null,
               
            },this.getAmendmentById)
            
        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
    }else{
        this.setState({ errorModal: true })
    }
}
getHtmlFromEditorValue = (val="") => {
    
    const tobesaved = convertToRaw(val.getCurrentContent());
    return stateToHTML(convertFromRaw(tobesaved));

 }
  
    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }
    handleAmendmentSave = async (state) => {
        
        
        delete state.submissionId;
        state.feedback=state.feedback ? this.getHtmlFromEditorValue(state.feedback):"";
        state.submission_id = this.props.submissionId;
        state.created_by = this.context.userSystemId;
       /*  if(state.approval.toLowerCase()===APPROVED.toLowerCase() || state.approval.toLowerCase()===DECLINED.toLowerCase())
        {
            state.decision_date=new Date();
        } */
        state["decision_date"] = state.decision_date != "" && state.decision_date != null ?
        dateChange(state.decision_date) : null;
        state["protocol_version_number"]=state.protocol_version_number
        try {
            const res = await API.post("amendment/add", state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
                AmendmentDetails:null,
                activeForm:''
                
            },()=>{
                this.getAmendmends(this.props.submissionId);
            })
            

        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true }, this.getAmendmends)
        }
    }
    handleFormEvents=(option)=>{
        this.setState({activeForm:option});
    }
    handleModalCallBack=(response)=>{
        if(response===true){
            let callBack=(val)=>{
                this.setState({activeForm:''},()=>{
                    this.getAmendmentById(this.props.submissionId) ;
                });
              }
            //   showMessage({                  
            //     text: "Saved Successfully",
            //     icon: "success",
            //     button: "Ok",
              
            //   },callBack);
            callBack();
        } 
        else{
            this.setState({activeForm:''});
        }           
    }
     formType={
            INITIATE_INSTRUCTION:'INITIATE_INSTRUCTION'
        }
        uploadFileFunction = async () => {
            const formData = new FormData();
            formData.append(
               
                'file',
                this.state.selectedFile,
                this.state.selectedFile.name
            );
            try {
                this.setState({
                    showLoaderUpload :true
                })
                
                let fileUrl = `${this.state.externalurl}/fileupload/multiple/Amendment/${this.props.submissionId}/${this.state.activeAmendment.id}`;
                let res = await axios.post(fileUrl, formData, {
                    headers: {                   
                        'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    },
                });
                this.setState({
                    selectedFile: null,
                    successFile: true,
                    fileMessage: "File uploaded!",
                    showLoaderUpload : false
                });
    
            } catch (e) {
                this.setState({
                    selectedFile: null,
                    errorFile: true,
                    showLoaderUpload : false
                })
                console.log(e);
            }
            this.getAmendmentById(this.props.submissionId,);
        }
    render() {
        const { amendmentData,activeAmendment,AmendmentDetails} = this.state;
        const { showHideModal, viewEditFlag,showLoaderUpload  ,loadTable,  action ,  showLoader, viewEditData } = this.state
        let _isCoordinator=this.state.isCoordinator;
        // if(!amendmentData||!activeAmendment )
        // return <div>no amendment</div>;
        let style={
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
          } 
        return (
            <><div className="new-flow-modal p-3">
                <div className="d-flex  align-items-center">

                    <div className="d-flex flex-grow-1 align-items-center" >
                        {!activeAmendment && <span style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }} className="font-weight-bold ">Amendment Details  </span>}
                        {activeAmendment && this.state.publicationList && this.state.publicationList.length > 0 && <span style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }} className=" font-weight-bold ">Amendment Details for </span>}
                        {activeAmendment && this.state.publicationList && this.state.publicationList.length > 0 && <InputField
                            type="searchableDropdown"
                            placeholder="Select Amendment"
                            value={this.state["activePublicationValue"]}
                            options={this.state.publicationList}
                            onSelect={(e, name) => this.handleChangeEvent(e, name)}
                        />}
                    </div>
                    {_isCoordinator && <div className="d-flex  align-items-center justify-content-end flex-grow-1">
                        {activeAmendment && <IIRBtn.default className="d-flex p-2 align-items-center justify-content-center mr-2 " style="success w-100px" type="submit" onClick={this.handleAmendmentUpdate} disabled={!this.state.hasChanged}>
                           <span>SAVE</span>
                        </IIRBtn.default>}
                        {activeAmendment && <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                <span className="material-icons mr-2">
                                    more_vert
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="bg-dark text-white" >

                                <Dropdown.Item onClick={() => this.handleAmendmentDelete()} className="text-white">Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>}
                        {this.state.activeForm === this.formType.INITIATE_INSTRUCTION && <AmendmentModalEx onClose={this.handleModalCallBack} handleAmendmentSave={this.handleAmendmentSave} submissionId={this.props.submissionId}  ></AmendmentModalEx>}
                           <DTAButton onClick={(e) =>  this.handleFormEvents(this.formType.INITIATE_INSTRUCTION)} icon="ADD" text={"Add Amendment"}></DTAButton>
                    </div>}

                </div>
                <br />
                <Row>
                    {activeAmendment && AmendmentDetails && this.renderInputFields()}
                </Row>

                <Row><Col sm={12}>
                    {AmendmentDetails && activeAmendment && <div> <span className="flex-grow-1 font-weight-bold ">File Upload</span>
                    </div>}
                    <span className="alert-line" />

                    {activeAmendment && AmendmentDetails &&

                        <Row  >
                            <Col sm={12} >


                                {<Row >
                                    <Col sm={8}>
                                        <div className="ml-3 position-relative">
                                            <Form.File
                                                id="custom-file"
                                                className="custom-file-label ml-0"
                                                onChange={this.onFileChange}
                                                label={this.state.selectedFile?.name}
                                                custom
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        {showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </Button>}
                                        {!showLoaderUpload && <Button variant="secondary" onClick={() => uploadFile(this.state.selectedFile, this.state.fileList, this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>}
                                    </Col>
                                </Row>}
                                {<small className="form-text text-muted pl-3">*Maximum size for file upload is 2MB.</small>}
                                <br />
                                {showLoader &&
                                    <Row>
                                        <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                                            <span className="visually-hidden">File {action} in progress </span>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </div>

                                    </Row>}
                                {this.state.fileList.length > 0 && loadTable &&
                                    <FileComponentEx data={this.state.fileList}
                                        accessDelete={this.state.access.delete}


                                        handleDelete={this.handleDelete}
                                        handleDownload={this.handleDownload} />
                                }



                            </Col>
                        </Row>
                    }
                </Col>
                </Row>


                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body={this.state.success_body}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.successFile &&
                    <BootstrapModal
                        show={this.state.successFile}
                        header="Success"
                        body={this.state.fileMessage}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorFile &&
                    <BootstrapModal
                        show={this.state.errorFile}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </div>
            </>
        )
    }
}


