import React, { Component } from 'react'
import { Button, Card, Col, Container, Form, Modal, Navbar, Row, Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import API from '../api/apis';
import UserContext from '../context/UserContext';
import { dateChange,ddmmmyyyyformat, dateStringformat, dateTimeformat, getUserInfo, uploadFile } from '../utility/helperFunctions';
import { yyyymmddFormat } from './common/dateFormat';
import FileComponent from './common/file-component/FileComponent';
import InputField from './common/input-fields/InputField';
import Loader from './common/loader/Loader';
import BootstrapModal from './common/modal/BootstrapModal';
import StickyNav from './common/sticky-nav/StickyNav';

import HistoryFile from './submission/detailSubComponents/HistoryFile';
import ReviewFile from './submission/detailSubComponents/section-1/ReviewFile';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import ToolTipEx from './common/ToolTipEx';
import { DEFAULT } from '../TooltipInfo';
import { isCoordinator } from './ToReviewDetails/commonFunctions';
import NewPublicationEx from './NewPublicationEx';
import FileComponentEx from './common/file-component/FileComponentEx';
import * as IIRBtn  from './common/Button';
import DTAButton from './common/Button/DTAButton';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        className="d-flex align-items-center justify-content-center"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </div>
));
export default class PublicationDetailEx extends Component {
    static contextType = UserContext
    state = {
        _isCoordinator: false,
        publicationData: null,
        activePublicationValue: '',
        publicationList: [],
        activePublication: null,
        showLoader: false,
        showLoaderUpload: false,
        action: "",
        externalurl: "http://localhost:1337",
        submissionId: "",
        submission_id: "",
        viewEditData: {},
        showHideModal: false,
        viewEditFlag: null,
        fileList: [],
        selectedFile: null,
        successFile: false,
        errorFile: false,
        showLoader: false,
        publicationDetails: {},
        publicationDetail:{},
        auditData: [],
        trailData: [],
        showTable: false,
        planned_date_of_submission: null,
        date_of_publication: null
    }
    get inputFieldsArray() {
        return [
            {

                label: "Title *",
                type: "textarea",
                name: "title",

                disabled: true
            },
            {
                label: "Format",
                type: "dropdown",
                placeholder: "None",
                name: "format",
                disabled: true,
                options: ["None", "Manuscript for Journal", "Abstract", "Poster", "Presentation", "Publication", "Other"],

            },
            {
                label: "Primary Author",
                type: "text",

                name: "primary_author",

                disabled: true

            },
            {
                label: "Venue",
                type: "text",

                name: "venue",

                disabled: true

            },
            {
                label: "Review",
                type: "dropdown",
                placeholder: "Select Review",
                name: "review",
                options: ["Not started", "Under Review", "Completed"],

                disabled: true
            },
            {
                label: "Comments",
                type: "textarea",

                name: "comments",
                disabled: true

            },
            {
                label: "Link to Publication",
                type: "textarea",

                name: "link_to_publication",
                disabled: true

            },
            {
                label: "Date of Publication",
                type: "date",

                name: "date_of_publication",
                disabled: true

            },
            {
                label: "Planned Date of Submission",
                type: "date",

                name: "planned_date_of_submission",
                disabled: true

            },
            {
                label: "Submission",
                type: "text",

                name: "submissionId",
                disabled: true

            },
            {
                label: "Lilly Feedback",
                type: "textarea",
                name: "lilly_feedback",
                disabled: true
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className: "createUpdateByfontsize",
                disabled: true
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className: "createUpdateByfontsize",
                disabled: true
            },
        ];
    }
    async getExternalUrl() {
        let response = '';
        try {
            response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
            externalurl: (response && response.data) ? response.data.exturl : "http://localhost:1337",
        })
    }

    componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.getExternalUrl();

        this.setState({
            showLoader: true,
            _isCoordinator
        });
        this.getPublication();

    }

    getPublicationById = async () => {
        let { activePublication } = this.state;
        if (!activePublication || !activePublication.id) {
            this.setState({
                publicationDetail: null,
                showLoader: false,
                isValueChanged:false
            });
            return false;
        }

        let { id } = activePublication;
        const res = await API.get(`publication/getbyid/${id}`);
        
        res.data["lilly_feedback"] = res.data.lilly_feedback !== null ? res.data.lilly_feedback.replace(/<[^>]+>/g, '') : "";
        res.data["date_of_publication"] = res.data.date_of_publication != "" && res.data.date_of_publication != null ?
            dateStringformat(res.data.date_of_publication) : null;
        res.data["planned_date_of_submission"] = res.data.planned_date_of_submission != "" && res.data.planned_date_of_submission != null ?
            dateStringformat(res.data.planned_date_of_submission) : null;
        res.data["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
        res.data["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt) : res.data.createdby;
        await this.getFilesList(res.data.submission_id, id);
        this.setState({
            publicationDetail: res.data,
            showLoader: false,
            isValueChanged:false
        })
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/publication`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    onInputChange = (e, eo) => {
        let { publicationDetail } = this.state;
        if (eo.type === "date") {
            let { name, value } = eo;
            publicationDetail[name] = e;
            publicationDetail[value] = e;

        }
        else {

            publicationDetail[e.target.name] = e.target.value;
        }
        this.setState({
            publicationDetail,
            isValueChanged:true
        });
    }

    onSelectChange = (e, eo) => {
        let { publicationDetail } = this.state;
        publicationDetail[eo.name || eo.target.name] = e;
        this.setState({
            publicationDetail,
            isValueChanged:true
        });
    }
    onSearchSelect = (e, name) => {
        let { publicationDetail } = this.state;
        publicationDetail[name] = e;
        this.setState({
            publicationDetail,
            isValueChanged:true
        });
    }
    checkboxChange = (e) => {
        let { publicationDetail } = this.state;
        publicationDetail[e.target.name] = e.target.checked;
        this.setState({
            publicationDetail,
            isValueChanged:true
        });
    }
    renderInputFields = () => {
        let attributes = {};

        return this.inputFieldsArray.map(field => {
            if (this.state._isCoordinator) {
                attributes = {
                    error: this.state.error?.[field.name],
                    onSelect: field.onSelect || this.onSelectChange,
                    onChange: (field.type !== "checkbox" ? field.onChange || ((e) => this.onInputChange(e, field)) : this.checkboxChange)
                }
            }
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state.publicationDetail[field.name]}
                        disabled={!this.state._isCoordinator}
                        {...attributes}
                    />
                </Col>
            )
        });
    }
    renderFiles = () => {
        let downloadableDocuments = this.state.fileList || [];
        return <div className='d-flex '>
            <ul className='d-flex flex-wrap m-0 p-0'>
                {downloadableDocuments && downloadableDocuments.map((item, index) => {
                    let fileName = item.key.split("\/").pop();
                    return <li key={index} onClick={e => this.handleDownload(fileName)} className='d-flex align-items-center p-2' style={{ cursor: 'pointer' }}>
                        <span>{fileName}</span>
                        <span className="material-icons ml-3">
                            file_download
                        </span>
                    </li>
                })}
            </ul>
        </div>
    }
    getFilesList = async (submission_id, id) => {
        this.setState({
            showTable: false,
            action: "fetching",
            showLoader: true
        })
        try {
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            this.setState({
                fileList: res.data?.filter(file => file.type === `Publication_${id}`&&!file.is_deleted),
                showTable: true,
                showLoader: false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                showLoader: false
            })
        }
    }



    handleDownload = async (fileName) => {
        try {
            this.setState({
                showLoader: true,
                action: "download"
            })
            let { submission_id, id } = this.state.activePublication;
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Publication/${submission_id}/${id}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response.ok) {

                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        showLoader: false,
                        successFile: true,
                        fileMessage: "File Downloaded Successfully!"
                    })
                });
        } catch (e) {
            this.setState({
                showLoader: false,
                errorFile: true
            })
            console.log(e);
        }
    }

    getPublication = async () => {
        try {
            let { submission_id } = this.props;
            let publicationList = [];
            const res = await API.get(`publication/submission/${submission_id}`)
            let activePublication = this.state.activePublication;

            let fileList=[];
            if (res.data && res.data.length > 0) {
                if(!activePublication||!activePublication.id){
                    activePublication = res.data[0];
                    this.state["activePublicationValue"] ={name: res.data[0].title+"-"+ddmmmyyyyformat(res.data[0].createdAt),value: res.data[0].id}; 
                }
                publicationList = res.data.map((item, index) => {
                    return { name: item.title+"-"+ddmmmyyyyformat(item.createdAt), value: item.id };
                })

            }
            this.setState({ publicationData: res.data, activePublication, publicationList,fileList }, () => {
                this.getPublicationById();
            });

        }
        catch (error) {

        }
    }
    handleChangeEvent = (e, name) => {
        // debugger;
        let { activePublication, activePublicationValue, publicationData } = this.state;
        activePublication = publicationData.find(p => p.id.toString() === e.value.toString());
        activePublicationValue = {name:e.name,value:e.value};
        this.setState({ activePublication, activePublicationValue,publicationDetail:null }, () => {
            this.getPublicationById();
        });
    }
    handlePublicationDelete = async () => {
        swal({
         
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res =  await API.post(`recycle/publication/delete/${this.state.activePublication.id}/${this.props.submission_id}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.setState({
                            activePublication:null,
                            publicationData:null,activePublicationValue:null
                        })
                       
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getPublication();
                }
            });
    }
    handleEvent = (e, option) => {
        switch (option) {
            case 'NEW': this.setState({ viewMode: 'NEW' }); break;
            case 'SAVE': this.handleSave(e); break;
            case 'UPDATE': this.handleSave(this.state.publicationDetail); break;
            case 'DELETE': this.handlePublicationDelete(); break;
            
        }
    }
    handleSave = async (state) => {
        // debugger;
        delete state.submissionId;


        state.submission_id = parseInt(this.props.submission_id);
        state.created_by = this.context.userSystemId;
        if (state.id) {
            delete state.created_by;
            state.updated_by = this.context.userSystemId;
        }
        state["date_of_publication"] = state.date_of_publication != "" && state.date_of_publication != null ?
            dateChange(state.date_of_publication) : null;
        state["planned_date_of_submission"] = state.planned_date_of_submission != "" && state.planned_date_of_submission != null ?
            dateChange(state.planned_date_of_submission) : null;
        try {

            const res = await API[state.id ? "put" : "post"](state.id ? 'publication/update/' : "publication/add", state);
            this.setState({
                success_body: state.id ? 'Updated Successfully!' : "Created successfully!",
                successModal: true,
                hasChanged: false,
                viewMode: '',
                viewEditFlag: null,
                viewEditData: {},
            }, () => {
                this.getPublication();
            })

        }
        catch (err) {
            this.setState({ errorModal: true })
        }
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        }, () => {

        });
    }
    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }
    uploadFileFunction = async () => {
        const formData = new FormData();
        formData.append(
            'file',
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        try {
            this.setState({
                showLoaderUpload: true
            })
            // const res = await API.post(`/user/multiple/upload/Publication/${this.state.publicationDetails.submission_id}/${this.props.match.params.publicationId}`, formData);
            let fileUrl = `${this.state.externalurl}/fileupload/multiple/Publication/${this.state.publicationDetails.submission_id}/${this.props.match.params.publicationId}`;
            let res = await axios.post(fileUrl, formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                },
            });
            this.setState({
                selectedFile: null,
                successFile: true,
                fileMessage: "File uploaded!",
                showLoaderUpload: false
            })
        } catch (e) {
            this.setState({
                selectedFile: null,
                errorFile: true,
                showLoaderUpload: false

            })
            console.log(e);
        }

    }
    handleDelete = async (fileName) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({
                            showLoader: true,
                            action: "delete"
                        })
                        const res = await API.post(`/user/multiple/delete/Publication/${this.state.publicationDetails.submission_id}/${this.props.match.params.publicationId}/${fileName}`, { updated_by: this.context.userSystemId });

                        this.setState({
                            successFile: true,
                            fileMessage: "File deleted!",
                            showLoader: false,

                        })
                    }
                    catch (err) {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });
                    }

                }
            });


    }
    handleDelete = async (fileName) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({
                            showLoader: true,
                            action: "delete"
                        })
                        const res = await API.post(`/user/multiple/delete/Publication/${this.props.submission_id}/${this.state.activePublication.id}/${fileName}`, { updated_by: this.context.userSystemId });

                        this.setState({
                            successFile: true,
                            fileMessage: "File deleted!",
                            showLoader: false,

                        },()=>{
                            this.getPublicationById();
                        })
                    }
                    catch (err) {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });
                    }

                }
            });


    }

    handleDownloadEx = async (fileName) => {
        try {
            this.setState({
                showLoader: true,
                action: "download"
            })

            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Publication/${this.props.submission_id}/${this.state.activePublication.id}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response.ok) {

                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        showLoader: false,
                        successFile: true,
                        fileMessage: "File Downloaded Successfully!"
                    })
                });
        } catch (e) {
            this.setState({
                showLoader: false,
                errorFile: true
            })
            console.log(e);
        }
    }

    uploadFileFunction = async () => {
        const formData = new FormData();
        formData.append(
            'file',
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        try {
            this.setState({
                showLoaderUpload: true
            })
            // const res = await API.post(`/user/multiple/upload/Publication/${this.state.publicationDetails.submission_id}/${this.props.match.params.publicationId}`, formData);
            let fileUrl = `${this.state.externalurl}/fileupload/multiple/Publication/${this.props.submission_id}/${this.state.activePublication.id}`;
            let res = await axios.post(fileUrl, formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                },
            });
            this.setState({
                selectedFile: null,
                successFile: true,
                fileMessage: "File uploaded!",
                showLoaderUpload: false
            },()=>{
                this.getPublicationById();
            })
        } catch (e) {
            this.setState({
                selectedFile: null,
                errorFile: true,
                showLoaderUpload: false

            })
            console.log(e);
        }

    }
    render() {
        const { publicationData, activePublication, showLoader, publicationDetail, _isCoordinator } = this.state;
       
        let style = {
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
        }
        return (
            <div className="new-flow-modal position-relative">
                {
                     showLoader&&<div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100" style={{left:0,top:0,backgroundColor:'#EEEEEE60'}}><Loader></Loader></div>
                }
                <div className='d-flex align-items-center  p-3' style={{ fontSize: '1rem' }}>
                    <span className="mr-2"><strong>Publication Details </strong></span>
                   {this.state.publicationList&&this.state.publicationList.length>0&&<div className='d-flex align-items-center'>
                    <span><strong>for</strong> </span>
                    <div>
                        <InputField
                            type="searchableDropdown"
                            placeholder="Select Publication"
                            value={this.state["activePublicationValue"]}
                            options={this.state.publicationList}
                            onSelect={(e, name) => this.handleChangeEvent(e, name)}
                        />
                    </div>
                    </div>} 
                    {_isCoordinator && <div className="d-flex flex-grow-1 justify-content-end align-items-center">
                        {activePublication && activePublication.id && <><IIRBtn.default disabled={!this.state.isValueChanged} onClick={e => this.handleEvent(e, 'UPDATE')}  className="d-flex align-items-center mr-2 p-2 justify-content-center " style="success w-100px" type="submit" >
                            <span>SAVE</span>
                        </IIRBtn.default>
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    <span className="material-icons mr-2">
                                        more_vert
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="bg-dark text-white" >

                                    <Dropdown.Item onClick={e => this.handleEvent(e, 'DELETE')} className="text-white">Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown></>}
                        <ToolTipEx tooltip={DEFAULT.ADD}>                            
                            <DTAButton onClick={(e) => this.handleEvent(e, 'NEW')} icon="ADD" text={"Add Publication"}></DTAButton>
                        </ToolTipEx>

                    </div>}
                </div>
                <Row>
                    {publicationDetail && this.renderInputFields()}
                </Row>
                {activePublication && activePublication.id && publicationDetail&&  <Row>
                    <Col>
                    {this.state._isCoordinator&&<><Row>
                            <Col sm={8}>
                                <div className="ml-3 position-relative">
                                    <Form.File
                                        id="custom-file"
                                        className="custom-file-label ml-0"
                                        onChange={this.onFileChange}
                                        label={this.state.selectedFile?.name}
                                        custom
                                    />
                                    </div>
                            </Col>
                            <Col sm={4}>{this.state.showLoaderUpload &&  <Button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </Button>}
                                {!this.state.showLoaderUpload && 
                                    <Button variant="secondary" onClick={() => uploadFile(this.state.selectedFile, this.state.fileList, this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>
                                }
                            </Col>
                        </Row>
                        <br />

                        <small className="form-text text-muted pl-3">*Maximum size for file upload is 2MB.</small>
                        </>}
                        {showLoader &&
                            <Row>
                                <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                                    <span className="visually-hidden">File {this.state.action} in progress </span>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </div>
                            </Row>}
                        <br />
                       
                        {this.state.fileList.length > 0 &&  this.state.showTable&&
                            <FileComponentEx data={this.state.fileList}
                                accessDelete={this.state._isCoordinator}

                                handleDelete={this.handleDelete}
                                handleDownload={this.handleDownloadEx} />
                        }
                    </Col>
                </Row>}
                {this.state.viewMode && this.state.viewMode === 'NEW' && <NewPublicationEx
                    handleModalShowHide={e => {
                        this.setState({ viewMode: '' })
                    }}
                    showHideModal={this.state.viewMode && this.state.viewMode === 'NEW'}
                    handlePublicationSave={e => {
                        this.handleEvent(e, 'SAVE')
                    }}
                    viewEditFlag={""}
                    handlePublicationUpdate={e => { }}
                    submission_id={this.props.submission_id}></NewPublicationEx>}
                {this.state.successModal &&
                    <BootstrapModal
                        show={true}
                        header="Success"
                        body={this.state.success_body ?? ""}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={true}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </div>
        )
    }
}
