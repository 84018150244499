import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import { switchEnum } from "./switchEnum";
import styles from "./SwitchView.css";

const SwitchView = (props) => {
  const [view, setView] = useState(switchEnum.DETAILS);

  useEffect(async () => {}, []);

  const changeView = (newView) => {
    if (view !== newView) {
      setView(newView);
    //   props.viewMode(newView);
    }
  };
  useEffect(() => {
    // console.log('view>>:', view);
    if (view === switchEnum.DETAILS) {
        props.viewMode(switchEnum.DETAILS)
    } else {
        props.viewMode(switchEnum.HISTORY)
    }
  }, [view]);

  return (
    <div style={{...props.style||{}}}>
      <ListGroup
        horizontal={"sm"}
        defaultActiveKey={switchEnum.DETAILS}
        className="switch-view"
      >
        <ListGroup.Item
          onClick={() => changeView(switchEnum.DETAILS)}
          eventKey={switchEnum.DETAILS}
          className="switch-view-item"
          action
        >
          Details
        </ListGroup.Item>
        <ListGroup.Item
          onClick={() => changeView(switchEnum.HISTORY)}
          eventKey={switchEnum.HISTORY}
          className="switch-view-item"
          action
        >
          History
        </ListGroup.Item>
      </ListGroup>
      <br />
    </div>
  );
};
export default SwitchView;
