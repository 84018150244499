import React, { useState, useEffect } from 'react'
import { Dropdown, Accordion } from 'react-bootstrap';
import API from '../../../api/apis';
import { Button } from 'react-bootstrap';
import UserContext from '../../../context/UserContext';
import {Link} from 'react-router-dom';
import { dateChange, dateStringformat, dateTimeformat, getUserInfo, uploadFile } from '../../../utility/helperFunctions';


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));
export default class Notification extends React.Component {
  static contextType = UserContext;
  state = {
    notificationDocument: []
  }
  constructor(props){
    super(props);
    this._interval_checker=React.createRef(null);
  }
  componentDidMount = async () => {   
    this.fetchNotification();
  }
  getNotificationData=async()=>{
    let responses={
      topFive:null,
      allList:null
  };

  let tasks=[responses.topFive,responses.allList];
  let sysID=this.context.userSystemId;
  responses.topFive= await API.post(`/submissionReview/latestFive/notificationList`,{
    "system_id":sysID
  });
  responses.allList=  await API.post('/submissionReview/notificationList',{
    "system_id":sysID
  });

    if (responses.topFive && responses.topFive.data) {
      let topFiveData = responses.topFive.data;
      sessionStorage.setItem("notification_" + sysID, topFiveData.length);
      this.setState({
        notificationDocument: topFiveData
      });
    }
    let nofification_list=[];
    let referenceInfo=[];
    if (responses.allList && responses.allList.data) {
      let allList = responses.allList.data;
      let groupBySubmissionID=allList.map((item,index)=>{
        return item.submission_id;
      });
      nofification_list=[...new Set (groupBySubmissionID)];
      referenceInfo=nofification_list.map((item)=>{
        let existing=allList.filter(p=>p.submission_id===item);
        let sub_keys=existing.map(q=>q.ref_key);
        return {submission_id:item,sub_keys:[...new Set (sub_keys)]};
      });

    }
    sessionStorage.setItem("notification_sub_" + sysID, JSON.stringify(referenceInfo||[]));
    // let sysID=this.context.userSystemId;
    // let res = await API.post(`/submissionReview/latestFive/notificationList`,{
    //   "system_id":sysID
    // });

    // if (res&&res.data) {
    //   sessionStorage.setItem("notification_"+sysID,res.data.length);
    //   this.setState({
    //     notificationDocument: res.data

    //   })
    // }
  }
  componentWillUnmount(){
    if(this._interval){
        this.clearTimer();
        this._interval_checker=null;
    }
}
clearTimer=()=>{
  sessionStorage.setItem("notification_"+this.context.userSystemId,0);
  clearInterval(this._interval_checker);
}
fetchNotification=()=>{
  if(this._interval_checker){
    this.clearTimer();
  }
  this._interval_checker=setInterval(this.getNotificationData,10000);
}
  render() {
    let { notificationDocument } = this.state;
    
    return <Dropdown >
      <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
        <span className="material-icons ml-7 " style={{ color: "black", marginTop: "1.0rem", }}>account_circle</span>
        <span style={{ color: "black", position: "absolute", marginTop: "0.6rem", marginLeft: "-3px" }}>{[notificationDocument.filter(item => item.read_status === false).length]}</span></Dropdown.Toggle>
      <Dropdown.Menu style={{ width: "300px", height: 'auto',maxHeight:"600px",overflowY:'auto',left:'-150px' }}>
        <div className='p-2'><span className="bi bi-arrow-up mr-2" ></span>
          <span>Notifications</span></div><br />
        <ul >{notificationDocument.map((item,index) => <div key={index} className="d-flex align-items-start">
          {!item.read_status ? <span className="material-icons mr-1" style={{ color: '#0AB3F3', fontSize: "0.9rem", marginLeft: "-35px", marginTop: "15px", left: "10px" }}>circle</span> : <span className="material-icons mr-2" style={{ color: '#0AB3F3', visibility: "hidden", marginLeft: "-35px", fontSize: "0.9rem", left: "10px" }}>circle</span>}

          <span className="material-icons  mr-2 " style={{ fontSize: "1.5rem", color: "rgb(93 93 93)" }}>stars </span>
          <div><div>{item.reviewer_name && item.system_id === this.context.userSystemId ? item.reviewer_name + "(You)" : item.reviewer_name
          }</div>
            <div>{item.notify_review_type === "add-document" && <span>added a new document</span>}
              {item.notify_review_type === "update-init-review" && <span>Updated a review</span>}
              {item.notify_review_type === "add-init-review" && <span>added a new review</span>}
              {item.notify_review_type === "add-comment" && <span>added a new comment</span>}
              {item.notify_review_type === "add-instruction" && <span>added a new Instruction</span>}
              {item.notify_review_type === "assigned-by" && <span>modified a review</span>}
              

            </div>
            <small className="form-text text-muted ">{dateTimeformat(item.created_at)}</small>
            <hr />
          </div>

        </div>)}</ul>
        <div   className={"d-flex align-items-center justify-content-center p-2 "} style={{ textDecoration: "Underline", backgroundColor: "rgb(93 93 93)", color: "white", marginTop: "-35px" }}>
          <Link as={Link} style={{color:"white"}} to='/accounts'>View All</Link>
        </div></Dropdown.Menu></Dropdown>


  }
}