import React, { Component } from 'react'
import { Col, Row, Button, Nav } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import API from "../../../../api/apis";
import DatatableComponent from '../../../common/datatable/DatatableComponent';
// import StudyCompoundModal from '../StudyCompoundModal';
import StudyCompoundModalEx from '../StudyCompoundModalEx';
import Loader from '../../../common/loader/Loader';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import UserContext from '../../../../context/UserContext';
import { dateTimeformat, enableWFActions, renderTableIcons } from '../../../../utility/helperFunctions';
import swal from 'sweetalert';


class StudyCompounds extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        studyCompoundDetails: [],
        error: false,
        successModal: false,
        errorModal: false,
    }
    componentDidMount() {
        this.getStudyCompoundsDetailsBySubmissionId(this.props.submissionDetails.id)
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator") ,
                edit: selectedRole.includes("operational_coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator") ,
                    edit: selectedRole.includes("operational_coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    getStudyCompoundsDetailsBySubmissionId = async (submissionId) => {
        try {
            const studyCompoundDetails = await API.get(`study/submission/${submissionId}`);
            this.setState({
                studyCompoundDetails: studyCompoundDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    // handleModalShowHide = async (flag, viewEditFlag, row) => {
    //     if (row && row.id && viewEditFlag === "edit") {
    //         await this.getStudyCompoundById(row.id)
    //         this.setState({ showHideModal: flag, viewEditFlag });
    //         return true;
    //     }
    //     this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
    // }
    handleModalShowHide = async (flag, id, reload) => {
        if (id) {
            this.setState({ showHideModal: flag, studyCompoundId: id });
        } else {
            this.setState({ showHideModal: flag, studyCompoundId: null }, async () => {
                if (reload) {
                    await this.getStudyCompoundsDetailsBySubmissionId(this.props.submissionDetails.id);
                }
            });
        }
    }

    getStudyCompoundById = async (id) => {
        try {
            const studyCompoundDetails = await API.get(`study/getbyid/${id}`);
            this.setState({
                viewEditData: studyCompoundDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    handleStudyCompoundDetails = (row) => {
        this.props.history.push(`/studycompound/details/${row.id}`, {studyCompoundId: row.id,submissionId: this.props.submissionDetails.id});
    }
    columns = [{
        dataField: 'id',
        text: 'id',
        sort: false,
        hidden: true,
        csvExport: false,
    },{
        dataField: 'study_compounds_name',
        text: 'Study Compound Name',
        sort: true,
        // formatter: (cell, row, rowIndex) => {
        //     return <>
        //         <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
        //             onClick={() => this.handleStudyCompoundDetails(row)}>{row.study_compounds_name}</p>
        //     </>
        // }
        // formatter: (cell, row, rowIndex) => {
        //     return <>
        //         <Nav.Link as={NavLink} to={{pathname:`/studycompound/details/${row.id}`,state:{studyCompoundId: row.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
        //             onClick={(e) => this.handleStudyCompoundDetails(row,e)}>{row.study_compounds_name}</Nav.Link>
        //     </>
        // }
    }, {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        // excludeFilter: true,
        csvExport: false,
        // headerAlign: 'center',
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        formatter: (cell, row, rowIndex) => {
            // var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            
            return <>{enableWFActions()&&<>
                <i className="bi bi-trash-fill" onClick={() => this.handleStudyCompoundDelete(row)}
                    style={{visibility: visibilityDelete, fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>
                {/* <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row)}
                    style={{ visibility: visibilityEdit, fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i> */}
            </>}</>
        }
    }];


    handleStudyCompoundSave = async (state) => {
        delete state.currencyList;
        delete state.showLoader;
       
        state.created_by = this.context.userSystemId;
        state.submissionId = this.props.submissionDetails.id;
        try {
            const res = await API.post("study/add", state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getStudyCompoundsDetailsBySubmissionId(this.props.submissionDetails.id)
            this.props.getSubmissionDetail(this.props.submissionDetails.id);

        } catch (err) {
            this.setState({ errorModal: true });
        }

    }
    handleStudyCompoundUpdate = async (state) => {
        delete state.compoundList;
        delete state.showLoader;
        delete state.created_by;
        Object.keys(state).forEach(ele =>{
            if(ele.includes("role_")&&state[ele]==="None"){
                state[ele]=null
            }
        })

        state.last_modified_by = this.context.userSystemId;
        state.submissionId = this.props.submissionDetails.id;
        // state.created_by = this.context.userSystemId;
        try {
            const res = await API.post("study/update", state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getStudyCompoundsDetailsBySubmissionId(this.props.submissionDetails.id)
            this.props.getSubmissionDetail(this.props.submissionDetails.id);

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }

    rowEvents = {
        onClick: (e, row, rowIndex) => {
            // this.handleModalShowHide(true, 'edit', row)
            if(enableWFActions()){
                this.handleModalShowHide(true, row.id)
            }
            else{
                this.handleStudyCompoundDetails(row);
            }
            
        }
    };
    handleStudyCompoundDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })

          
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    const res = await API.post(`recycle/studycompound/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                    this.setState({
                        success_body: "Deleted successfully!",
                        successModal: true,
                        hasChanged: false
                    });
                    this.getStudyCompoundsDetailsBySubmissionId(this.props.submissionDetails.id)
                } catch (err) {

                    this.setState({ errorModal: true });
                }
              
            } 
          });

        // try {
        //     const res = await API.delete(`study/delete/${row.id}`);
        //     this.setState({
        //         success_body: "Deleted successfully!",
        //         successModal: true,
        //         hasChanged: false
        //     });
        //     this.getStudyCompoundsDetailsBySubmissionId(this.props.submissionDetails.id)
        // } catch (err) {
        //     this.setState({ errorModal: true });
        // }

    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }



    render() {
        const { showHideModal, viewEditFlag, viewEditData, studyCompoundDetails,
            successModal, showLoader, success_body, errorModal, studyCompoundId } = this.state;
            let title=`Study Compounds (${studyCompoundDetails.length})`;
        return (<> {
            showLoader ? <Loader /> :
                <>
                    <Row>
                      
                        <DatatableComponent id="studyCompound" 
                        title={title}
                        pageIcons={renderTableIcons("ADD STUDY COMPOUND",() => this.handleModalShowHide(true),"add",this.state.access.create&&enableWFActions() )}
                        data={studyCompoundDetails} columns={this.columns} pagination="true" rowEvents={this.rowEvents} />
                    </Row>
                    {
                        showHideModal &&
                        <StudyCompoundModalEx
                            showHideModal={showHideModal}
                            handleModalShowHide={this.handleModalShowHide}
                            studyCompoundId={studyCompoundId}
                            // handleStudyCompoundSave={this.handleStudyCompoundSave}
                            // handleStudyCompoundUpdate={this.handleStudyCompoundUpdate}
                            // viewEditFlag={viewEditFlag}
                            // viewEditData={viewEditData}
                            submissionId={this.props.submissionDetails.id}
                        />
                    }
                    {this.state.successModal &&
                        <BootstrapModal
                            show={successModal}
                            header="Success"
                            body={success_body}
                            changeShowState={this.changeShowState}
                        />
                    }
                    {this.state.errorModal &&
                        <BootstrapModal
                            show={errorModal}
                            variant="danger"
                            header="Ooops!!"
                            body="An error occured! Please try again later!"
                            changeShowState={this.changeShowState}
                        />
                    }
                </>
        }
        </>
        )
    }
}


export default withRouter(StudyCompounds);