/**************************************************************************************************************************************************
*   MODULE NAME     : Set Roles modal
*   AUTHOR          :  IIR DEV Team
*   DATE            :  08-JULY-2021
*   DESCRIPTION     :  All Positive workflows logic(Activity Add and update form field)
*   Copyright 2021 Eli Lilly and Company
*   All rights reserved
*
/**************************************************************************************************************************************************
*   *** CHANGE HISTORY ***
/**************************************************************************************************************************************************
*   TRACKING NUMBER: CHXXXXXXXX
*   DESCRIPTION: <IIR>: New System Development
/**************************************************************************************************************************************************/

import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Modal } from 'react-bootstrap';

import Select from "react-select";

const SetRoles = (props) => {
    const [selectedRole, setSelectedRole] = useState({ label: "Select Role", value: props.userRole });
    const { setRolesModalFunction, setRolesModalFlag, rolesList } = props;

    const onHandleSelectChange = async (selectedOption) => {
        await setSelectedRole(selectedOption);
        await setRolesModalFunction(false, selectedOption.value)
        for (const key in localStorage) {
            if (key.includes("filter_")) {
                localStorage.removeItem(key)
            }
        }
    }
    return (
        <Modal show={setRolesModalFlag}
            onHide={() => setRolesModalFunction(null, selectedRole)} centered className="modal-500"
        >
            <Modal.Header closeButton>
                <Modal.Title>Switch Role
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="selectRole">
                    <Row>
                        <Col sm={12} >
                            <Form.Group controlId="selectRole">
                                <Form.Label></Form.Label>
                                <Select
                                    name="selectedRole"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    menuPortalTarget={document.body}
                                    options={rolesList}
                                    value={selectedRole}
                                    maxMenuHeight={130}
                                    onChange={selectedOption =>
                                        onHandleSelectChange(selectedOption)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default SetRoles;
