import React, { Component } from 'react';
import { Col, Row, Dropdown, Form, Button } from 'react-bootstrap';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import DatatableComponent from '../common/datatable/DatatableComponent';
import NewConceptEx from '../NewConceptEx';
import { Link, withRouter } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert'
import API from '../../api/apis';
import UserContext from '../../context/UserContext';
import { dateformat, dateTimeformat, dateTimeStringformat, dateStringformat } from '../../utility/helperFunctions';
import Loader from '../common/loader/Loader';
import swal from "sweetalert";
import CreateViewModal from './CreateViewModal';
import LoaderEx from '../common/loader/LoaderEx';

const initialColumns = [
  {
    dataField: 'id',
    text: 'Record Number',
    filter: textFilter({
      defaultValue: localStorage.getItem("filter_recordNo1"),
      onFilter: (e) => {
        localStorage.setItem("filter_recordNo1", e)
      }
    }),
    sort: true,
    csvExport: true,
    stickyColumnShown: true,
    csvFormatter: (cellContent, row) => { return row && row.id ? row.id : ""; },
    formatter: (cell, row, rowIndex) => { return <> {"Submission-" + (row.id + "").padStart(4, '0')} </> }
  },
]
class Submission extends Component {
  static contextType = UserContext;
  state = {
    resetView: true,
    selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
    isLoading: false,
    submissionList: [],
    userRole: Array.isArray(this.context.userRolesList) ? 'Administrator' : this.context.userRolesList,
    reloadTable: Array.isArray(this.context.userRolesList) ? 'Administrator' : this.context.userRolesList,
    Administrator: false,
    viewList: [],
    selectedView: null,
    customViewColumns: [...initialColumns],
    customViewSubmissionList: [],
    is_new_workflow:true,
    statusOptions: [],
    stageOptions: [],
    request_typeOptions:[],
    secondary_request_typeOptions: [],
    // columns:[]
  }
  filterProperty = {};
  colSelected = {
    "Global Coordinator - Oncology":
      ["id", "country_data", "local_regulatory_no", "request_type", "therapeutic_area",
        "fpv_actual", "impact_no", "trial_alias", "title", "expected_enrollment",
        "current_enrollment_actual", "current_enrollment_delta", "investigator_first_name",
        "investigator_last_name", "lpv_estimate", "fsr_estimate", "stage", "status", "tumor_indication", "compound", "total_grant",
        "msl_data", "assigned_data"],
    "Global Coordinator - Diabetes":
      ["id", "country_data", "local_regulatory_no", "legacy_therapeutic_area", "createdAt", "request_type", "therapeutic_area",
        "fpv_actual", "impact_no", "trial_alias", "title", "expected_enrollment",
        "current_enrollment_actual", "current_enrollment_delta", "investigator_first_name",
        "investigator_last_name", "stage", "lpv_estimate", "fsr_estimate", "status", "tumor_indication", "compound", "total_grant",
        "msl_data", "assigned_data"],
    "Global Coordinator - Biomeds":
      ["id", "country_data", "local_regulatory_no", "legacy_therapeutic_area", "createdAt", "request_type", "therapeutic_area",
        "fpv_actual", "impact_no", "trial_alias", "title", "expected_enrollment",
        "current_enrollment_actual", "current_enrollment_delta", "investigator_first_name",
        "investigator_last_name", "stage", "status", "lpv_estimate", "fsr_estimate", "tumor_indication", "compound", "total_grant",
        "msl_data", "assigned_data"],
    "Read Only - Oncology":
      ["id", "country_data", "local_regulatory_no", "request_type", "therapeutic_area",
        "fpv_actual", "impact_no", "trial_alias", "title", "expected_enrollment",
        "current_enrollment_actual", "current_enrollment_delta", "investigator_first_name",
        "investigator_last_name", "stage", "status", "indication", "tumor_indication", "compound", "total_grant",
        "msl_data", "assigned_data", "additional_cordinator"],
    "Read Only - Biomeds":
      ["id", "country_data", "local_regulatory_no", "request_type", "therapeutic_area",
        "fpv_actual", "impact_no", "trial_alias", "title", "expected_enrollment",
        "current_enrollment_actual", "current_enrollment_delta", "investigator_first_name",
        "investigator_last_name", "stage", "status", "indication", "tumor_indication", "compound", "total_grant",
        "msl_data", "assigned_data", "investigator_primary_institution", "additional_cordinator"],
    "Read Only - Diabetes":
      ["id", "country_data", "local_regulatory_no", "request_type", "therapeutic_area",
        "fpv_actual", "impact_no", "trial_alias", "title", "expected_enrollment",
        "current_enrollment_actual", "current_enrollment_delta", "investigator_first_name",
        "investigator_last_name", "stage", "status", "indication", "tumor_indication", "compound", "total_grant",
        "msl_data", "assigned_data", "additional_cordinator"],
    "Industry Sponsored":
      ["id", "country_data", "org_name", "lpv_baseline",
        "fpv_baseline", "contract_complete_estimate", "trial_alias", "expected_enrollment",
        "stage", "status", "tumor_indication", "compound", "milestone_comments"],
    "Management User":
      ["id", "country_data", "local_regulatory_no", "request_type", "therapeutic_area", "compound_1", "compound_2",
        "fpv_actual", "impact_no", "trial_alias", "title", "expected_enrollment", "country_1", "country_2", "country_3", "country_4",
        "current_enrollment_actual", "investigator_first_name",
        "investigator_last_name", "stage", "status", "indication", "compound", "numberOfInvestigativeSite",
        , "assigned_data", "investigator_primary_institution", "fpv_estimate", "lpv_estimate", "lpv_actual"],
    //Number of Investigative Sites is left for Management user
    "Operational Coordinator":
      ["id", "country_data", "request_type", "therapeutic_area",
        "fpv_actual", "trial_alias", "trial_category", "title", "stage", "status", "indication", "compound",
        "msl_data", "assigned_data", "investigator_name", "investigator_primary_institution"],
    "Administrator": ["id", "investigator_last_name", "trial_category", "request_type", "therapeutic_area", "stage", "assigned_data", "investigator_primary_institution", "compound", "indication", "tumor_indication", "status"]
  }
  getcolumns1() {
    return [
      {
        dataField: 'ID',
        text: 'Record Number',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_recordNo1"),
          onFilter: (e) => { localStorage.setItem("filter_recordNo1", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter1"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('id'),
        sort: true,
        csvExport: true,
        stickyColumnShown: true,

        // csvExport:,
        csvFormatter: (cellContent, row) => { return row && row.id ? row.id : ""; },
        formatter: (cell, row, rowIndex) => { return <> {"Submission-" + (row.id + "").padStart(4, '0')} </> }
      },
      {
        dataField: 'trial_category',
        text: 'Trial Category',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_trial_category"),
          onFilter: (e) => { localStorage.setItem("filter_trial_category", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter2"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('trial_category'),
        sort: true,
        csvExport: true,

        // csvExport: this.colSel,
        csvFormatter: (cellContent, row) => { return row && row.trial_category ? row.trial_category : ""; }
      },
      {
        dataField: 'concepts[0].request_type',
        text: 'Primary Request Type',
        // filter: textFilter({
        //   defaultValue: localStorage.getItem("filter_request_type"),
        //   onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
        //   getFilter: (filter) => { this.filterProperty["columns1filter3"] = filter }
        // }),// hidden: !this.colSelected[this.state.userRole].includes('request_type'),
        // csvExport: this.colS,
        sort: true,
        csvExport: true,
        formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].request_type) ? row.concepts[0].request_type : '' },
        filter: selectFilter({
          placeholder: 'All',
          options: this.state.request_typeOptions,
          defaultValue: localStorage.getItem("filter_request_type"),
          onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
          getFilter: (filter) => { 
            this.filterProperty["columns1filter3"] = filter }
        }),

        // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].request_type : ""} </> },
        csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].request_type : ""; }
      },
      {
        dataField: 'concepts[0].secondary_request_type',
        text: 'Secondary Request Type',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_sec_request_type"),
          onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
          getFilter: (filter) => { this.filterProperty["columns1filterSecRT"] = filter }
        }),
        sort: true,
        csvExport: true,
        formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].secondary_request_type) ? row.concepts[0].secondary_request_type : '' },
        filter: selectFilter({
          placeholder: 'All',
          options: this.state.secondary_request_typeOptions,
          defaultValue: localStorage.getItem("filter_sec_request_type"),
          onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
          getFilter: (filter) => { this.filterProperty["columns1filterSecRT"] = filter }
        }),
        // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""} </> },
        csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""; }
      },
      {
        dataField: 'concepts[0].therapeutic_area',
        text: 'Therapeutic Area',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_therapeutic_area"),
          onFilter: (e) => { localStorage.setItem("filter_therapeutic_area", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter4"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('therapeutic_area'),
        sort: true,
        csvExport: true,

        // csvExport: this.colSelec,
        formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].therapeutic_area : ""} </> },
        csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].therapeutic_area : ""; }
      },
      {
        dataField: 'trial_alias',
        text: 'Trial Alias',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_trial_alias"),
          onFilter: (e) => { localStorage.setItem("filter_trial_alias", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter5"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('trial_alias'),
        sort: true,
        csvExport: true,

        // csvExport: this.col,
        csvFormatter: (cellContent, row) => { return row && row.trial_alias ? row.trial_alias : ""; }
      },
      {
        dataField: 'stage',
        text: 'Submission Stage',
        // filter: textFilter({
        //   defaultValue: localStorage.getItem("filter_stage"),
        //   onFilter: (e) => { localStorage.setItem("filter_stage", e) },
        //   getFilter: (filter) => { this.filterProperty["columns1filter6"] = filter }
        // }),
        formatter: (cell, row) => { return row['stage']? row.stage : '' },
        filter: selectFilter({
          placeholder: 'All',
          options: this.state.stageOptions,
          defaultValue: localStorage.getItem("filter_stage"),
          onFilter: (e) => { localStorage.setItem("filter_stage", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter6"] = filter }
        }),
        sort: true,
        csvExport: true,

        csvFormatter: (cellContent, row) => { return row && row.stage ? row.stage : ""; }
      }, {
        dataField: 'status',
        text: 'Submission Status',
        // filter: textFilter({
        //   defaultValue: localStorage.getItem("filter_status"),
        //   onFilter: (e) => { localStorage.setItem("filter_status", e) },
        //   getFilter: (filter) => { this.filterProperty["columns1filter7"] = filter }
        // }), 
        headerClasses: "width-240",
        formatter: (cell, row) => { return row['status']? row.status : '' },
        filter: selectFilter({
          placeholder: 'All',
          options: this.state.statusOptions,
          defaultValue: localStorage.getItem("filter_status"),
          onFilter: (e) => { localStorage.setItem("filter_status", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter7"] = filter }
        }),
        sort: true,
        csvExport: true,
        // csvExport: thi,
        csvFormatter: (cellContent, row) => { return row && row.status ? row.status : ""; }
      },
      {
        dataField: 'concepts[0].indication',
        text: '[EXT] Indication(Tumor)',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_tumorIndication"),
          onFilter: (e) => { localStorage.setItem("filter_tumorIndication", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter8"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('tumor_indication'),
        sort: true,
        csvExport: true,
        formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].indication : ""} </> },
        csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].indication : ""; }
      },
      {
        dataField: 'indication',
        text: '[EXT] Other Indication',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_indication"),
          onFilter: (e) => { localStorage.setItem("filter_indication", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter9"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('indication'),
        sort: true,
        csvExport: true,

        csvFormatter: (cellContent, row) => { return row && row.indication ? row.indication : ""; }
      },
      {
        dataField: 'investigator_last_name',
        text: '[EXT] Investigator Last Name',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_investigator_last_name"),
          onFilter: (e) => { localStorage.setItem("filter_investigator_last_name", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter10"] = filter }
        }),            // csvExport: this.colSelected[th,

        sort: true,
        csvExport: true,
        csvFormatter: (cellContent, row) => { return row && row.investigator_last_name ? row.investigator_last_name : ""; }
      },
      {
        dataField: 'concepts[0].primary_institution',
        text: 'Investigator Primary Institution',
        headerClasses: "headerHeight",
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_investigatorPrimaryInstitution"),
          onFilter: (e) => { localStorage.setItem("filter_investigatorPrimaryInstitution", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter11"] = filter }
        }),// csvExport: this.colSelected[this.state.u,
        // hidden: !this.colSelected[this.state.userRole].includes('investigator_primary_institution'),
        sort: true,
        csvExport: true,
        formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].primary_institution : ""} </> },
        csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].primary_institution : ""; }
      },
      {
        dataField: 'concepts[0].compound',
        text: '[EXT] Compound',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_compound"),
          onFilter: (e) => { localStorage.setItem("filter_compound", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter12"] = filter }
        }),// csvExport: this.,
        // hidden: !this.colSelected[this.state.userRole].includes('compound'),
        sort: true,
        csvExport: true,
        formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].compound : ""} </> },
        csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].compound : ""; }
      },
      {
        dataField: 'assigned_data.first_name',
        text: 'Assigned To',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_assignTo"),
          onFilter: (e) => { localStorage.setItem("filter_assignTo", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter13"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('assigned_data'),
        formatter: (cell, row, rowIndex) => { return <> {row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : ""} </> },
        sort: true,
        csvExport: true,
        // csvExport: this.colSe,
        csvFormatter: (cellContent, row) => { return row && row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : "" }
      },
      {
        dataField: 'impact_scale',
        text: 'Impact Scale',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_impact_scale"),
          onFilter: (e) => { localStorage.setItem("filter_impact_scale", e) },
          getFilter: (filter) => { this.filterProperty["columns1filter14"] = filter }
        }),
        sort: true,
        csvExport: true,
       
        csvFormatter: (cellContent, row) => { return row && row.impact_scale  ? row.impact_scale : "" }
      },
    ];
  }
  getcolumns2() {
    return [{
      dataField: 'ID',
      text: 'Record Number',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_recordNo2"),
        onFilter: (e) => { localStorage.setItem("filter_recordNo2", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter1"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('id'),
      sort: true,
      headerClasses: "headerHeight",
      csvExport: true,
      stickyColumnShown: true,

      // csvExport:,
      csvFormatter: (cellContent, row) => { return row && row.id ? row.id : ""; },
      formatter: (cell, row, rowIndex) => { return <> {"Submission-" + (row.id + "").padStart(4, '0')} </> }
    },
    {
      dataField: 'trial_alias',
      text: 'Trial Alias',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_trial_alias"),
        onFilter: (e) => { localStorage.setItem("filter_trial_alias", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter2"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('trial_alias'),
      sort: true,
      csvExport: true,

      // csvExport: this.col,
      csvFormatter: (cellContent, row) => { return row && row.trial_alias ? row.trial_alias : ""; }
    },
    {
      dataField: 'concepts[0].compound',
      text: '[EXT] Compound',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_compound"),
        onFilter: (e) => { localStorage.setItem("filter_compound", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter3"] = filter }
      }),// csvExport: this.,
      // hidden: !this.colSelected[this.state.userRole].includes('compound'),
      sort: true,
      csvExport: true,

      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].compound : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].compound : ""; }
    },
    {
      dataField: 'compound_1_data',
      text: 'Compound 1',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_compound_1_data"),
        onFilter: (e) => { localStorage.setItem("filter_compound_1_data", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter4"] = filter }
      }),// csvExport: this.co,
      // hidden: !this.colSelected[this.state.userRole].includes('compound_1'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <> {row.compound_1_data != null ? row.compound_1_data?.name : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.compound_1_data != null ? row.compound_1_data?.name : "" }

    },
    {
      dataField: 'compound_2_data',
      text: 'Compound 2',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_compound_2_data"),
        onFilter: (e) => { localStorage.setItem("filter_compound_2_data", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter5"] = filter }
      }),
      // csvExport: this.co,
      // hidden: !this.colSelected[this.state.userRole].includes('compound_2'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <> {row.compound_2_data != null ? row.compound_2_data?.name : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.compound_2_data != null ? row.compound_2_data?.name : "" }
    },
    {
      dataField: 'concepts[0].country_data.name',
      text: '[EXT] Country',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_country"),
        onFilter: (e) => { localStorage.setItem("filter_country", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter6"] = filter }
      }), headerClasses: "width-20",

      // csvExport: this.colS,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 && row.concepts[0].country_data != null ? row.concepts[0].country_data?.name : ""} </> },
      // hidden: !this.colSelected[this.state.userRole].includes('country_data'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 && row.concepts[0].country_data != null ? row.concepts[0].country_data?.name : ""; }
    },
    {
      dataField: 'country1.name',
      text: ' Country 1',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_country1"),
        onFilter: (e) => { localStorage.setItem("filter_country1", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter7"] = filter }
      }), headerClasses: "width-20",

      // csvExport: this.c,
      // hidden: !this.colSelected[this.state.userRole].includes('country_1'),
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 && row.country1 != null ? row.country1?.name : ""} </> },
      // hidden: !this.colSelected[this.state.userRole].includes('country_data'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 && row.country1 != null ? row.country1?.name : ""; }
    },
    {
      dataField: 'country2.name',
      text: ' Country 2',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_country2"),
        onFilter: (e) => { localStorage.setItem("filter_country2", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter8"] = filter }
      }), headerClasses: "width-20",
      // csvExport: this.c,
      // hidden: !this.colSelected[this.state.userRole].includes('country_2'),
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 && row.country2 != null ? row.country2?.name : ""} </> },
      // hidden: !this.colSelected[this.state.userRole].includes('country_data'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 && row.country2 != null ? row.country2?.name : ""; }
    },
    {
      dataField: 'country3.name',
      text: ' Country 3',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_country3"),
        onFilter: (e) => { localStorage.setItem("filter_country3", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter9"] = filter }
      }), headerClasses: "width-20",
      // csvExport: this.c,
      // hidden: !this.colSelected[this.state.userRole].includes('country_3'),
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 && row.country3 != null ? row.country3?.name : ""} </> },
      // hidden: !this.colSelected[this.state.userRole].includes('country_data'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 && row.country3 != null ? row.country3?.name : ""; }
    },
    {
      dataField: 'country4.name',
      text: ' Country 4',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_country4"),
        onFilter: (e) => { localStorage.setItem("filter_country4", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter10"] = filter }
      }), headerClasses: "width-20",
      // csvExport: this.c,
      // hidden: !this.colSelected[this.state.userRole].includes('country_4'),
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 && row.country4 != null ? row.country4?.name : ""} </> },
      // hidden: !this.colSelected[this.state.userRole].includes('country_data'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 && row.country4 != null ? row.country4?.name : ""; }
    },
    {
      dataField: 'concepts[0].request_type',
      text: 'Primary Request Type',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_request_type"),
      //   onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
      //   getFilter: (filter) => { this.filterProperty["columns2filter11"] = filter }
      // }),// hidden: !this.colSelected[this.state.userRole].includes('request_type'),
      // csvExport: this.colS,
      formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].request_type) ? row.concepts[0].request_type : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.request_typeOptions,
        defaultValue: localStorage.getItem("filter_request_type"),
        onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter11"] = filter }
      }),
      sort: true,
      csvExport: true,
      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].request_type : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].request_type : ""; }
    },
    {
      dataField: 'concepts[0].secondary_request_type',
      text: 'Secondary Request Type',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_sec_request_type"),
      //   onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
      //   getFilter: (filter) => { this.filterProperty["columns2filterSecRT"] = filter }
      // }),
      sort: true,
      csvExport: true,
      formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].secondary_request_type) ? row.concepts[0].secondary_request_type : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.request_typeOptions,
        defaultValue: localStorage.getItem("filter_sec_request_type"),
        onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
        getFilter: (filter) => { this.filterProperty["columns2filterSecRT"] = filter }
      }),
      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""; }
    },
    {
      dataField: 'stage',
      text: 'Submission Stage',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_stage"),
      //   onFilter: (e) => { localStorage.setItem("filter_stage", e) },
      //   getFilter: (filter) => { this.filterProperty["columns2filter12"] = filter }
      // }),// hidden: !this.colSelected[this.state.userRole].includes('stage'),
      formatter: (cell, row) => { return row['stage'] ? row['stage'] : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.stageOptions,
        defaultValue: localStorage.getItem("filter_stage"),
        onFilter: (e) => { localStorage.setItem("filter_stage", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter12"] = filter }
      }),
      sort: true,
      csvExport: true,
      // csvExport: th,
      csvFormatter: (cellContent, row) => { return row && row.stage ? row.stage : ""; }
    }, {
      dataField: 'status',
      text: 'Submission Status',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_status"),
      //   onFilter: (e) => { localStorage.setItem("filter_status", e) },
      //   getFilter: (filter) => { this.filterProperty["columns2filter13"] = filter }
      // }), 
      headerClasses: "width-240",
      // hidden: !this.colSelected[this.state.userRole].includes('status'),
      sort: true,
      csvExport: true,
      // csvExport: thi,
      csvFormatter: (cellContent, row) => { return row && row.status ? row.status : ""; },
      formatter: (cell, row) => { return row['status'] ? row['status'] : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.statusOptions,
        defaultValue: localStorage.getItem("filter_status"),
        onFilter: (e) => { localStorage.setItem("filter_status", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter13"] = filter }
      }),
    },
    {
      dataField: 'proposal.numberOfInvestigativeSite',
      text: 'Number of Investigative Sites',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_numberOfInvestigativeSite"),
        onFilter: (e) => { localStorage.setItem("filter_numberOfInvestigativeSite", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter14"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('numberOfInvestigativeSite'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected[this.,
      csvFormatter: (cellContent, row) => { return row && row.proposal?.numberOfInvestigativeSite != null ? row.proposal.numberOfInvestigativeSite : ""; }
    },
    {
      dataField: 'expected_enrollment',
      text: 'Expected Enrollment',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_expected_enrollment"),
        onFilter: (e) => { localStorage.setItem("filter_expected_enrollment", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter15"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('expected_enrollment'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected,
      csvFormatter: (cellContent, row) => { return row && row.expected_enrollment ? row.expected_enrollment : ""; }
    }, {
      dataField: 'current_enrollment_actual',
      text: 'Current Enrollment Actual',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_current_enrollment_actual"),
        onFilter: (e) => { localStorage.setItem("filter_current_enrollment_actual", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter16"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('current_enrollment_actual'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected[this.,
      csvFormatter: (cellContent, row) => { return row && row.current_enrollment_actual ? row.current_enrollment_actual : ""; }
    },
    {
      dataField: 'fpv_estimate',
      text: 'FPV (Estimate)',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_fpv_estimate"),
        onFilter: (e) => { localStorage.setItem("filter_fpv_estimate", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter17"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('fpv_estimate'),
      sort: true,
      csvExport: true,
      // csvExport: this.colS,
      formatter: (cell, row, rowIndex) => { return <>{row.fpv_estimate != null ? dateformat(row.fpv_estimate) : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.fpv_estimate ? row.fpv_estimate : ""; }
    }, {
      dataField: 'fpv_actual',
      text: 'FPV Actual',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_fpv_actual"),
        onFilter: (e) => { localStorage.setItem("filter_fpv_actual", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter18"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('fpv_actual'),
      sort: true,
      csvExport: true,
      // csvExport: this.co,
      formatter: (cell, row, rowIndex) => { return <>{row.fpv_actual != null ? dateformat(row.fpv_actual) : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.fpv_actual ? row.fpv_actual : ""; }
    },
    {
      dataField: 'lpv_estimate',
      text: ' LPV (Estimate) ',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_lpv_estimate"),
        onFilter: (e) => { localStorage.setItem("filter_lpv_estimate", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter19"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('lpv_estimate'),
      sort: true,
      csvExport: true,
      // csvExport: this.colS,
      formatter: (cell, row, rowIndex) => { return <>{row.lpv_estimate != null ? dateformat(row.lpv_estimate) : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.lpv_estimate ? row.lpv_estimate : ""; }
    },
    {
      dataField: 'lpv_actual',
      text: 'LPV (Actual) ',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_lpv_actual"),
        onFilter: (e) => { localStorage.setItem("filter_lpv_actual", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter20"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('lpv_actual'),
      sort: true,
      csvExport: true,
      // csvExport: this.co,
      formatter: (cell, row, rowIndex) => { return <>{row.lpv_actual != null ? dateformat(row.lpv_actual) : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.lpv_actual ? row.lpv_actual : ""; }
    },
    {
      dataField: 'investigator_last_name',
      text: '[EXT] Investigator Last Name',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_investigator_last_name"),
        onFilter: (e) => { localStorage.setItem("filter_investigator_last_name", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter21"] = filter }
      }),// csvExport: this.colSelected[th,
      // hidden: !this.colSelected[this.state.userRole].includes('investigator_last_name'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.investigator_last_name ? row.investigator_last_name : ""; }
    },
    {
      dataField: 'investigator_first_name',
      text: '[EXT] Investigator First Name',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_first_name"),
        onFilter: (e) => { localStorage.setItem("filter_first_name", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter22"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('investigator_first_name'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected[thi,
      csvFormatter: (cellContent, row) => { return row && row.investigator_first_name ? row.investigator_first_name : ""; }
    },
    {
      dataField: 'concepts[0].primary_institution',
      text: '[EXT] Investigator Primary Institution',
      headerClasses: "headerHeight",
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_investigatorPrimaryInstitution"),
        onFilter: (e) => { localStorage.setItem("filter_investigatorPrimaryInstitution", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter23"] = filter }
      }),// csvExport: this.colSelected[this.state.u,
      // hidden: !this.colSelected[this.state.userRole].includes('investigator_primary_institution'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].primary_institution : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].primary_institution : ""; }
    },
    {
      dataField: 'assigned_data.first_name',
      text: 'Assigned To',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_assignTo"),
        onFilter: (e) => { localStorage.setItem("filter_assignTo", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter24"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('assigned_data'),
      formatter: (cell, row, rowIndex) => { return <> {row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : ""} </> },
      sort: true,
      csvExport: true,
      // csvExport: this.colSe,
      csvFormatter: (cellContent, row) => { return row && row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : "" }
    },
    {
      dataField: 'impact_scale',
      text: 'Impact Scale',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_impact_scale"),
        onFilter: (e) => { localStorage.setItem("filter_impact_scale", e) },
        getFilter: (filter) => { this.filterProperty["columns2filter25"] = filter }
      }),
      sort: true,
      csvExport: true,
     
      csvFormatter: (cellContent, row) => { return row && row.impact_scale  ? row.impact_scale : "" }
    },
    ];
  }
  getcolumnsCoordinator() {
    return [{
      dataField: 'ID',
      text: 'Record Number',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_recordNo3"),
        onFilter: (e) => { localStorage.setItem("filter_recordNo3", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter1"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('id'),
      sort: true,
      headerClasses: "headerHeight",
      csvExport: true,
      stickyColumnShown: true,

      // csvExport:,
      csvFormatter: (cellContent, row) => { return row && row.id ? row.id : ""; },
      formatter: (cell, row, rowIndex) => { return <> {"Submission-" + (row.id + "").padStart(4, '0')} </> }
    },
    {
      dataField: 'trial_alias',
      text: 'Trial Alias',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_trial_alias"),
        onFilter: (e) => { localStorage.setItem("filter_trial_alias", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter2"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('trial_alias'),
      sort: true,
      csvExport: true,
      // csvExport: this.col,
      csvFormatter: (cellContent, row) => { return row && row.trial_alias ? row.trial_alias : ""; }
    },
    {
      dataField: 'concepts[0].title',
      text: 'Concept Title',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_conceptTitle"),
        onFilter: (e) => { localStorage.setItem("filter_conceptTitle", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter3"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('title'),
      sort: true,
      csvExport: true,
      // csvExport: th,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].title : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].title : ""; }
    },
    {
      dataField: 'trial_category',
      text: 'Trial Category',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_trial_category"),
        onFilter: (e) => { localStorage.setItem("filter_trial_category", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter4"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('trial_category'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSel,
      csvFormatter: (cellContent, row) => { return row && row.trial_category ? row.trial_category : ""; }
    },


    {
      dataField: 'concepts[0].request_type',
      text: 'Primary Request Type',
      filter: textFilter({
        // defaultValue: localStorage.getItem("filter_request_type"),
        // onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
        // getFilter: (filter) => { this.filterProperty["coordinatorFilter5"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('request_type'),
      // csvExport: this.colS,
      sort: true,
      csvExport: true,
      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].request_type : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].request_type : ""; },
      formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].request_type) ? row.concepts[0].request_type : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.request_typeOptions,
        defaultValue: localStorage.getItem("filter_request_type"),
        onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter5"] = filter }
      }),
    },
    {
      dataField: 'concepts[0].secondary_request_type',
      text: 'Secondary Request Type',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_sec_request_type"),
      //   onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
      //   getFilter: (filter) => { this.filterProperty["coordinatorFilterSecRT"] = filter }
      // }),
      sort: true,
      csvExport: true,
      formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].secondary_request_type) ? row.concepts[0].secondary_request_type : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.secondary_request_typeOptions,
        defaultValue: localStorage.getItem("filter_sec_request_type"),
        onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilterSecRT"] = filter }
      }),
      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""; }
    },
    {
      dataField: 'concepts[0].therapeutic_area',
      text: 'Therapeutic Area',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_therapeutic_area"),
        onFilter: (e) => { localStorage.setItem("filter_therapeutic_area", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter6"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('therapeutic_area'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelec,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].therapeutic_area : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].therapeutic_area : ""; }
    },
    {
      dataField: 'stage',
      text: 'Submission Stage',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_stage"),
      //   onFilter: (e) => { localStorage.setItem("filter_stage", e) },
      //   getFilter: (filter) => { this.filterProperty["coordinatorFilter7"] = filter }
      // }),// hidden: !this.colSelected[this.state.userRole].includes('stage'),
      sort: true,
      csvExport: true,
      // csvExport: th,
      csvFormatter: (cellContent, row) => { return row && row.stage ? row.stage : ""; },
      formatter: (cell, row) => { return row['stage'] ? row['stage'] : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.stageOptions,
        defaultValue: localStorage.getItem("filter_stage"),
        onFilter: (e) => { localStorage.setItem("filter_stage", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter7"] = filter }
      }),
    }, {
      dataField: 'status',
      text: 'Submission Status',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_status"),
      //   onFilter: (e) => { localStorage.setItem("filter_status", e) },
      //   getFilter: (filter) => { this.filterProperty["coordinatorFilter8"] = filter }
      // }),
       headerClasses: "width-240",
      // hidden: !this.colSelected[this.state.userRole].includes('status'),
      sort: true,
      csvExport: true,
      // csvExport: thi,
      csvFormatter: (cellContent, row) => { return row && row.status ? row.status : ""; },
      formatter: (cell, row) => { return row['status'] ? row['status'] : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.statusOptions,
        defaultValue: localStorage.getItem("filter_status"),
        onFilter: (e) => { localStorage.setItem("filter_status", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter8"] = filter }
      }),
    },
    {
      dataField: 'concepts[0].country_data.name',
      text: '[EXT] Country',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_country"),
        onFilter: (e) => { localStorage.setItem("filter_country", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter9"] = filter }
      }), headerClasses: "width-20",
      // csvExport: this.colS,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].country_data?.name : ""} </> },
      // hidden: !this.colSelected[this.state.userRole].includes('country_data'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].country_data?.name : ""; }
    },
    {
      dataField: 'concepts[0].compound',
      text: '[EXT] Compound',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_compound"),
        onFilter: (e) => { localStorage.setItem("filter_compound", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter10"] = filter }
      }),// csvExport: this.,
      // hidden: !this.colSelected[this.state.userRole].includes('compound'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].compound : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].compound : ""; }
    },
    {
      dataField: 'concepts[0].investigatorname',
      text: '[EXT] Investigator Primary Name',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_investigatorPrimaryName"),
        onFilter: (e) => { localStorage.setItem("filter_investigatorPrimaryName", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter11"] = filter }
      }),// csvExport: this.colSelect,
      // hidden: !this.colSelected[this.state.userRole].includes('investigator_name'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].investigatorname : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].investigatorname : ""; }
    },
    {
      dataField: 'concepts[0].primary_institution',
      text: '[EXT] Investigator Primary Institution',
      headerClasses: "headerHeight",
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_investigatorPrimaryInstitution"),
        onFilter: (e) => { localStorage.setItem("filter_investigatorPrimaryInstitution", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter12"] = filter }
      }),// csvExport: this.colSelected[this.state.u,
      // hidden: !this.colSelected[this.state.userRole].includes('investigator_primary_institution'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].primary_institution : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].primary_institution : ""; }
    },
    {
      dataField: 'assigned_data.first_name',
      text: 'Assigned To',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_assignTo"),
        onFilter: (e) => { localStorage.setItem("filter_assignTo", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter13"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('assigned_data'),
      formatter: (cell, row, rowIndex) => { return <> {row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : ""} </> },
      sort: true,
      csvExport: true,
      // csvExport: this.colSe,
      csvFormatter: (cellContent, row) => { return row && row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : "" }
    },
    {
      dataField: 'Additional_cordinator',
      text: 'Additional Coordinator',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_additional_cordinator"),
        onFilter: (e) => { localStorage.setItem("filter_additional_cordinator", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter14"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('additional_cordinator'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected[t,
      csvFormatter: (cellContent, row) => { return row && row.additional_cordinator ? row.additional_cordinator : ""; }
    },
    {
      dataField: 'msl_data.first_name',
      text: 'Primary  MSL',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_primaryMsl"),
        onFilter: (e) => { localStorage.setItem("filter_primaryMsl", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter15"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('msl_data'),
      sort: true,
      csvExport: true,
      // csvExport: this.,
      formatter: (cell, row, rowIndex) => { return <> {row.msl_data != null ? row.msl_data?.first_name + " " + row.msl_data?.last_name : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.msl_data != null ? row.msl_data?.first_name + " " + row.msl_data?.last_name : "" }
    },
    {
      dataField: 'impact_scale',
      text: 'Impact Scale',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_impact_scale"),
        onFilter: (e) => { localStorage.setItem("filter_impact_scale", e) },
        getFilter: (filter) => { this.filterProperty["coordinatorFilter15"] = filter }
      }),
      sort: true,
      csvExport: true,
     
      csvFormatter: (cellContent, row) => { return row && row.impact_scale  ? row.impact_scale : "" }
    },
    ];
  }
  getcolumnsGCOncology() {
    return [{
      dataField: 'ID',
      text: 'Record Number',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_recordNo4"),
        onFilter: (e) => { localStorage.setItem("filter_recordNo4", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter1"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('id'),
      sort: true,
      headerClasses: "headerHeight",
      csvExport: true,
      stickyColumnShown: true,

      // csvExport:,
      csvFormatter: (cellContent, row) => { return row && row.id ? row.id : ""; },
      formatter: (cell, row, rowIndex) => { return <> {"Submission-" + (row.id + "").padStart(4, '0')} </> }
    },
    {
      dataField: 'stage',
      text: 'Submission Stage',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_stage"),
      //   onFilter: (e) => { localStorage.setItem("filter_stage", e) },
      //   getFilter: (filter) => { this.filterProperty["gcOncologyFilter2"] = filter }
      // }),// hidden: !this.colSelected[this.state.userRole].includes('stage'),
      sort: true,
      csvExport: true,

      // csvExport: th,
      csvFormatter: (cellContent, row) => { return row && row.stage ? row.stage : ""; },
      formatter: (cell, row) => { return row['stage'] ? row['stage'] : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.stageOptions,
        defaultValue: localStorage.getItem("filter_stage"),
        onFilter: (e) => { localStorage.setItem("filter_stage", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter2"] = filter }
      }),
    }, {
      dataField: 'status',
      text: 'Submission Status',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_status"),
      //   onFilter: (e) => { localStorage.setItem("filter_status", e) },
      //   getFilter: (filter) => { this.filterProperty["gcOncologyFilter3"] = filter }
      // }), 
      headerClasses: "width-240",
      // hidden: !this.colSelected[this.state.userRole].includes('status'),
      sort: true,
      csvExport: true,

      // csvExport: thi,
      csvFormatter: (cellContent, row) => { return row && row.status ? row.status : ""; },
      formatter: (cell, row) => { return row['status'] ? row['status'] : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.statusOptions,
        defaultValue: localStorage.getItem("filter_status"),
        onFilter: (e) => { localStorage.setItem("filter_status", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter3"] = filter }
      }),
    },
    {
      dataField: 'concepts[0].country_data.name',
      text: '[EXT] Country',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_country"),
        onFilter: (e) => { localStorage.setItem("filter_country", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter4"] = filter }
      }), headerClasses: "width-20",

      // csvExport: this.colS,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].country_data?.name : ""} </> },
      // hidden: !this.colSelected[this.state.userRole].includes('country_data'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].country_data?.name : ""; }
    },
    {
      dataField: 'investigator_last_name',
      text: '[EXT] Investigator Last Name',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_investigator_last_name"),
        onFilter: (e) => { localStorage.setItem("filter_investigator_last_name", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter5"] = filter }
      }),
      // csvExport: this.colSelected[th,
      // hidden: !this.colSelected[this.state.userRole].includes('investigator_last_name'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.investigator_last_name ? row.investigator_last_name : ""; }
    },
    {
      dataField: 'investigator_first_name',
      text: '[EXT] Investigator First Name',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_first_name"),
        onFilter: (e) => { localStorage.setItem("filter_first_name", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter6"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('investigator_first_name'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected[thi,
      csvFormatter: (cellContent, row) => { return row && row.investigator_first_name ? row.investigator_first_name : ""; }
    },
    {
      dataField: 'trial_alias',
      text: 'Trial Alias',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_trial_alias"),
        onFilter: (e) => { localStorage.setItem("filter_trial_alias", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter7"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('trial_alias'),
      sort: true,
      csvExport: true,
      // csvExport: this.col,
      csvFormatter: (cellContent, row) => { return row && row.trial_alias ? row.trial_alias : ""; }
    },
    {
      dataField: 'concepts[0].request_type',
      text: 'Primary Request Type',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_request_type"),
      //   onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
      //   getFilter: (filter) => { this.filterProperty["gcOncologyFilter8"] = filter }
      // }),
      // hidden: !this.colSelected[this.state.userRole].includes('request_type'),
      // csvExport: this.colS,
      sort: true,
      csvExport: true,
      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].request_type : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].request_type : ""; },
      formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].request_type) ? row.concepts[0].request_type : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.request_typeOptions,
        defaultValue: localStorage.getItem("filter_request_type"),
        onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter8"] = filter }
      }),
    },
    {
      dataField: 'concepts[0].secondary_request_type',
      text: 'Secondary Request Type',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_sec_request_type"),
      //   onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
      //   getFilter: (filter) => { this.filterProperty["gcOncologyFilterSecRT"] = filter }
      // }),
      sort: true,
      csvExport: true,

      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""; },
      formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].secondary_request_type) ? row.concepts[0].secondary_request_type : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.secondary_request_typeOptions,
        defaultValue: localStorage.getItem("filter_sec_request_type"),
        onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilterSecRT"] = filter }
      }),
    },
    {
      dataField: 'concepts[0].therapeutic_area',
      text: 'Therapeutic Area',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_therapeutic_area"),
        onFilter: (e) => { localStorage.setItem("filter_therapeutic_area", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter9"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('therapeutic_area'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelec,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].therapeutic_area : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].therapeutic_area : ""; }
    },
    {
      dataField: 'concepts[0].compound',
      text: '[EXT] Compound',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_compound"),
        onFilter: (e) => { localStorage.setItem("filter_compound", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter10"] = filter }
      }),// csvExport: this.,
      // hidden: !this.colSelected[this.state.userRole].includes('compound'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].compound : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].compound : ""; }
    },
    {
      dataField: 'concepts[0].indication',
      text: '[EXT] Indication(Tumor)',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_tumorIndication"),
        onFilter: (e) => { localStorage.setItem("filter_tumorIndication", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter11"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('tumor_indication'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].indication : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].indication : ""; }
    },
    {
      dataField: 'assigned_data.first_name',
      text: 'Assigned To',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_assignTo"),
        onFilter: (e) => { localStorage.setItem("filter_assignTo", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter12"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('assigned_data'),
      formatter: (cell, row, rowIndex) => { return <> {row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : ""} </> },
      sort: true,
      csvExport: true,
      // csvExport: this.colSe,
      csvFormatter: (cellContent, row) => { return row && row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : "" }
    },
    {
      dataField: 'study_title',
      text: 'Study Title',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_study_title"),
        onFilter: (e) => { localStorage.setItem("filter_study_title", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter13"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('title'),
      sort: true,
      csvExport: true,
      // csvExport: th,
      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].title : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.study_title ? row.study_title : ""; }
    },
    {
      dataField: 'expected_enrollment',
      text: 'Expected Enrollment',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_expected_enrollment"),
        onFilter: (e) => { localStorage.setItem("filter_expected_enrollment", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter14"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('expected_enrollment'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected,
      csvFormatter: (cellContent, row) => { return row && row.expected_enrollment ? row.expected_enrollment : ""; }
    }, {
      dataField: 'current_enrollment_actual',
      text: 'Current Enrollment Actual',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_current_enrollment_actual"),
        onFilter: (e) => { localStorage.setItem("filter_current_enrollment_actual", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter15"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('current_enrollment_actual'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected[this., 
      csvFormatter: (cellContent, row) => { return row && row.current_enrollment_actual ? row.current_enrollment_actual : ""; }
    },
    {
      dataField: 'current_enrollment_delta',
      text: 'Current Enrollment Delta',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_current_enrollment_delta"),
        onFilter: (e) => { localStorage.setItem("filter_current_enrollment_delta", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter16"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('current_enrollment_delta'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected[this,
      csvFormatter: (cellContent, row) => { return row && row.current_enrollment_delta ? row.current_enrollment_delta : ""; }
    },
    {
      dataField: 'msl_data.first_name',
      text: 'Primary  MSL',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_primaryMsl"),
        onFilter: (e) => { localStorage.setItem("filter_primaryMsl", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter17"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('msl_data'),
      sort: true,
      csvExport: true,
      // csvExport: this.,
      formatter: (cell, row, rowIndex) => { return <> {row.msl_data != null ? row.msl_data?.first_name + " " + row.msl_data?.last_name : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.msl_data != null ? row.msl_data?.first_name + " " + row.msl_data?.last_name : "" }
    },
    {
      dataField: 'concepts[0].primary_institution',
      text: '[EXT] Investigator Primary Institution',
      headerClasses: "headerHeight",
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_investigatorPrimaryInstitution"),
        onFilter: (e) => { localStorage.setItem("filter_investigatorPrimaryInstitution", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter18"] = filter }
      }),// csvExport: this.colSelected[this.state.u,
      // hidden: !this.colSelected[this.state.userRole].includes('investigator_primary_institution'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].primary_institution : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].primary_institution : ""; }
    },
    {
      dataField: 'local_regulatory_no',
      text: 'Local Regulatory Approval Number',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_local_regulatory_no"),
        onFilter: (e) => { localStorage.setItem("filter_local_regulatory_no", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter19"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('local_regulatory_no'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected,
      csvFormatter: (cellContent, row) => { return row && row.local_regulatory_no ? row.local_regulatory_no : ""; }
    },
    {
      dataField: 'impact_scale',
      text: 'Impact Scale',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_impact_scale"),
        onFilter: (e) => { localStorage.setItem("filter_impact_scale", e) },
        getFilter: (filter) => { this.filterProperty["gcOncologyFilter20"] = filter }
      }),
      sort: true,
      csvExport: true,
     
      csvFormatter: (cellContent, row) => { return row && row.impact_scale  ? row.impact_scale : "" }
    },

    ]
  };
  getcolumnsGCDbio() {
    return [{
      dataField: 'ID',
      text: 'Record Number',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_recordNo5"),
        onFilter: (e) => { localStorage.setItem("filter_recordNo5", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter1"] = filter }
      }),
      /// hidden: !this.colSelected[this.state.userRole].includes('id'),
      sort: true,
      headerClasses: "headerHeight",
      csvExport: true,
      stickyColumnShown: true,

      // csvExport:,
      csvFormatter: (cellContent, row) => { return row && row.id ? row.id : ""; },
      formatter: (cell, row, rowIndex) => { return <> {"Submission-" + (row.id + "").padStart(4, '0')}</> }
    },
    {
      dataField: 'concepts[0].country_data.name',
      text: '[EXT] Country',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_country"),
        onFilter: (e) => { localStorage.setItem("filter_country", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter2"] = filter }
      }), headerClasses: "width-20",

      // csvExport: this.colS,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].country_data?.name : ""} </> },
      // hidden: !this.colSelected[this.state.userRole].includes('country_data'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].country_data?.name : ""; }
    },
    {
      dataField: 'concepts[0].compound',
      text: '[EXT] Compound',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_compound"),
        onFilter: (e) => { localStorage.setItem("filter_compound", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter3"] = filter }
      }),
      // csvExport: this.,
      // hidden: !this.colSelected[this.state.userRole].includes('compound'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].compound : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].compound : ""; }
    },
    {
      dataField: 'trial_category',
      text: 'Trial Category',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_trial_category"),
        onFilter: (e) => { localStorage.setItem("filter_trial_category", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter4"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('trial_category'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSel,
      csvFormatter: (cellContent, row) => { return row && row.trial_category ? row.trial_category : ""; }
    },
    {
      dataField: 'investigator_last_name',
      text: '[EXT] Investigator Last Name',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_investigator_last_name"),
        onFilter: (e) => { localStorage.setItem("filter_investigator_last_name", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter5"] = filter }
      }),
      // csvExport: this.colSelected[th,
      // hidden: !this.colSelected[this.state.userRole].includes('investigator_last_name'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.investigator_last_name ? row.investigator_last_name : ""; }
    },
    {
      dataField: 'study_title',
      text: 'Study Title',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_study_title"),
        onFilter: (e) => { localStorage.setItem("filter_study_title", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter6"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('title'),
      sort: true,
      csvExport: true,
      // csvExport: th,
      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].title : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.study_title ? row.study_title : ""; }
    },
    {
      dataField: 'concepts[0].request_type',
      text: 'Primary Request Type',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_request_type"),
      //   onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
      //   getFilter: (filter) => { this.filterProperty["gcdBioFilter7"] = filter }
      // }),
      // hidden: !this.colSelected[this.state.userRole].includes('request_type'),
      // csvExport: this.colS,
      sort: true,
      csvExport: true,
      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].request_type : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].request_type : ""; },
      formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].request_type) ? row.concepts[0].request_type : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.request_typeOptions,
        defaultValue: localStorage.getItem("filter_request_type"),
        onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter7"] = filter }
      }),
    },
    {
      dataField: 'concepts[0].secondary_request_type',
      text: 'Secondary Request Type',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_sec_request_type"),
      //   onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
      //   getFilter: (filter) => { this.filterProperty["gcdBioFilterSecRT"] = filter }
      // }),
      sort: true,
      csvExport: true,
      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""; },
      formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].secondary_request_type) ? row.concepts[0].secondary_request_type : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.secondary_request_typeOptions,
        defaultValue: localStorage.getItem("filter_sec_request_type"),
        onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilterSecRT"] = filter }
      }),
    },
    {
      dataField: 'concepts[0].therapeutic_area',
      text: 'Therapeutic Area',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_therapeutic_area"),
        onFilter: (e) => { localStorage.setItem("filter_therapeutic_area", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter8"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('therapeutic_area'),
      sort: true,
      csvExport: true,

      // csvExport: this.colSelec,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].therapeutic_area : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].therapeutic_area : ""; }
    },
    {
      dataField: 'legacy_therapeutic_area',
      text: 'Legacy Therapeutic Area ',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_legacy_therapeutic_area"),
        onFilter: (e) => { localStorage.setItem("filter_legacy_therapeutic_area", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter9"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('legacy_therapeutic_area'),
      sort: true,
      csvExport: true,

      // csvExport: this.colSelected[thi,
      csvFormatter: (cellContent, row) => { return row && row.legacy_therapeutic_area ? row.legacy_therapeutic_area : ""; }
    },
    {
      dataField: 'stage',
      text: 'Submission Stage',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_stage"),
      //   onFilter: (e) => { localStorage.setItem("filter_stage", e) },
      //   getFilter: (filter) => { this.filterProperty["gcdBioFilter10"] = filter }
      // }),// hidden: !this.colSelected[this.state.userRole].includes('stage'),
      sort: true,
      csvExport: true,

      // csvExport: th,
      csvFormatter: (cellContent, row) => { return row && row.stage ? row.stage : ""; },
      formatter: (cell, row) => { return row['stage'] ? row['stage'] : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.stageOptions,
        defaultValue: localStorage.getItem("filter_stage"),
        onFilter: (e) => { localStorage.setItem("filter_stage", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter10"] = filter }
      }),
    }, {
      dataField: 'status',
      text: 'Submission Status',
      // filter: textFilter({
        // defaultValue: localStorage.getItem("filter_status"),
        // onFilter: (e) => { localStorage.setItem("filter_status", e) },
        // getFilter: (filter) => { this.filterProperty["gcdBioFilter11"] = filter }
      // }), 
      headerClasses: "width-240",

      // hidden: !this.colSelected[this.state.userRole].includes('status'),
      sort: true,
      csvExport: true,
      // csvExport: thi,
      csvFormatter: (cellContent, row) => { return row && row.status ? row.status : ""; },
      formatter: (cell, row) => { return row['status'] ? row['status'] : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.statusOptions,
        defaultValue: localStorage.getItem("filter_status"),
        onFilter: (e) => { localStorage.setItem("filter_status", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter11"] = filter }
      }),
    },
    {
      dataField: 'createdAt',
      text: 'Submission Created Date',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_createdAt"),
        onFilter: (e) => { localStorage.setItem("filter_createdAt", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter12"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].incl,
      formatter: (cell, row, rowIndex) => { return <>{row.createdAt != null ? dateformat(row.createdAt) : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.createdAt ? row.createdAt : ""; }
    },
    {
      dataField: 'fpv_actual',
      text: 'FPV Actual',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_fpv_actual"),
        onFilter: (e) => { localStorage.setItem("filter_fpv_actual", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter13"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('fpv_actual'),
      sort: true,
      csvExport: true,
      // csvExport: this.co,
      formatter: (cell, row, rowIndex) => { return <>{row.fpv_actual != null ? dateformat(row.fpv_actual) : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.fpv_actual ? row.fpv_actual : ""; }
    },
    {
      dataField: 'lpv_estimate',
      text: ' LPV (Estimate) ',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_lpv_estimate"),
        onFilter: (e) => { localStorage.setItem("filter_lpv_estimate", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter14"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('lpv_estimate'),
      sort: true,
      csvExport: true,
      // csvExport: this.colS,
      formatter: (cell, row, rowIndex) => { return <>{row.lpv_estimate != null ? dateformat(row.lpv_estimate) : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.lpv_estimate ? row.lpv_estimate : ""; }
    },
    {
      dataField: 'fsr_estimate',
      text: 'FSR (Estimate)',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_fsr_estimate"),
        onFilter: (e) => { localStorage.setItem("filter_fsr_estimate", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter15"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('fsr_estimate'),
      sort: true,
      csvExport: true,
      // csvExport: this.colS,
      formatter: (cell, row, rowIndex) => { return <>{row.fsr_estimate != null ? dateformat(row.fsr_estimate) : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.fsr_estimate ? row.fsr_estimate : ""; }
    },
    {
      dataField: 'trial_alias',
      text: 'Trial Alias',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_trial_alias"),
        onFilter: (e) => { localStorage.setItem("filter_trial_alias", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter16"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('trial_alias'),
      sort: true,
      csvExport: true,
      // csvExport: this.col,
      csvFormatter: (cellContent, row) => { return row && row.trial_alias ? row.trial_alias : ""; }
    },
    {
      dataField: 'expected_enrollment',
      text: 'Expected Enrollment',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_expected_enrollment"),
        onFilter: (e) => { localStorage.setItem("filter_expected_enrollment", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter17"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('expected_enrollment'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected,
      csvFormatter: (cellContent, row) => { return row && row.expected_enrollment ? row.expected_enrollment : ""; }
    }, {
      dataField: 'current_enrollment_actual',
      text: 'Current Enrollment Actual',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_current_enrollment_actual"),
        onFilter: (e) => { localStorage.setItem("filter_current_enrollment_actual", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter18"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('current_enrollment_actual'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected[this.,
      csvFormatter: (cellContent, row) => { return row && row.current_enrollment_actual ? row.current_enrollment_actual : ""; }
    },
    {
      dataField: 'total_grant',
      text: 'Total Grant',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_total_grant"),
        onFilter: (e) => { localStorage.setItem("filter_total_grant", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter19"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('total_grant'),
      sort: true,
      csvExport: true,
      // csvExport: this.col,
      csvFormatter: (cellContent, row) => { return row && row.total_grant ? row.total_grant : ""; }
    },
    {
      dataField: 'msl_data.first_name',
      text: 'Primary  MSL',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_primaryMsl"),
        onFilter: (e) => { localStorage.setItem("filter_primaryMsl", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter20"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('msl_data'),
      sort: true,
      csvExport: true,
      // csvExport: this.,
      formatter: (cell, row, rowIndex) => { return <> {row.msl_data != null ? row.msl_data?.first_name + " " + row.msl_data?.last_name : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.msl_data != null ? row.msl_data?.first_name + " " + row.msl_data?.last_name : "" }
    },
    {
      dataField: 'assigned_data.first_name',
      text: 'Assigned To',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_assignTo"),
        onFilter: (e) => { localStorage.setItem("filter_assignTo", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter21"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('assigned_data'),
      formatter: (cell, row, rowIndex) => { return <> {row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : ""} </> },
      sort: true,
      csvExport: true,
      // csvExport: this.colSe,
      csvFormatter: (cellContent, row) => { return row && row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : "" }
    },
    {
      dataField: 'impact_scale',
      text: 'Impact Scale',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_impact_scale"),
        onFilter: (e) => { localStorage.setItem("filter_impact_scale", e) },
        getFilter: (filter) => { this.filterProperty["gcdBioFilter22"] = filter }
      }),
      sort: true,
      csvExport: true,
     
      csvFormatter: (cellContent, row) => { return row && row.impact_scale  ? row.impact_scale : "" }
    },


    ];
  }
  getcolumnsRO() {
    return [{
      dataField: 'ID',
      text: 'Record Number',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_recordNo6"),
        onFilter: (e) => { localStorage.setItem("filter_recordNo6", e) },
        getFilter: (filter) => { this.filterProperty["roFilter1"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('id'),
      sort: true,
      headerClasses: "headerHeight",
      csvExport: true,
      stickyColumnShown: true,

      // csvExport:,
      csvFormatter: (cellContent, row) => { return row && row.id ? row.id : ""; },
      formatter: (cell, row, rowIndex) => { return <> {"Submission-" + (row.id + "").padStart(4, '0')} </> }
    },

    {
      dataField: 'trial_alias',
      text: 'Trial Alias',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_trial_alias"),
        onFilter: (e) => { localStorage.setItem("filter_trial_alias", e) },
        getFilter: (filter) => { this.filterProperty["roFilter2"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('trial_alias'),
      sort: true,
      csvExport: true,
      // csvExport: this.col,
      csvFormatter: (cellContent, row) => { return row && row.trial_alias ? row.trial_alias : ""; }
    },
    {
      dataField: 'concepts[0].title',
      text: 'Concept Title',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_conceptTitle"),
        onFilter: (e) => { localStorage.setItem("filter_conceptTitle", e) },
        getFilter: (filter) => { this.filterProperty["roFilter3"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('title'),
      sort: true,
      csvExport: true,
      // csvExport: th,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].title : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].title : ""; }
    },
    {
      dataField: 'concepts[0].compound',
      text: '[EXT] Compound',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_compound"),
        onFilter: (e) => { localStorage.setItem("filter_compound", e) },
        getFilter: (filter) => { this.filterProperty["roFilter4"] = filter }
      }),// csvExport: this.,
      // hidden: !this.colSelected[this.state.userRole].includes('compound'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].compound : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].compound : ""; }
    },
    {
      dataField: 'concepts[0].country_data.name',
      text: '[EXT] Country',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_country"),
        onFilter: (e) => { localStorage.setItem("filter_country", e) },
        getFilter: (filter) => { this.filterProperty["roFilter5"] = filter }
      }), headerClasses: "width-20",
      // csvExport: this.colS,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].country_data?.name : ""} </> },
      // hidden: !this.colSelected[this.state.userRole].includes('country_data'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].country_data?.name : ""; }
    },

    {
      dataField: 'concepts[0].request_type',
      text: 'Primary Request Type',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_request_type"),
      //   onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
      //   getFilter: (filter) => { this.filterProperty["roFilter6"] = filter }
      // }),
      // hidden: !this.colSelected[this.state.userRole].includes('request_type'),
      // csvExport: this.colS,
      sort: true,
      csvExport: true,
      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].request_type : ""} </> },
      formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].request_type) ? row.concepts[0].request_type : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.request_typeOptions,
        defaultValue: localStorage.getItem("filter_request_type"),
        onFilter: (e) => { localStorage.setItem("filter_request_type", e) },
        getFilter: (filter) => { this.filterProperty["roFilter6"] = filter }
      }),
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].request_type : ""; }
    },
    {
      dataField: 'concepts[0].secondary_request_type',
      text: 'Secondary Request Type',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_sec_request_type"),
      //   onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
      //   getFilter: (filter) => { this.filterProperty["roFilterSecRT"] = filter }
      // }),
      sort: true,
      csvExport: true,

      // formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].secondary_request_type : ""; },
      formatter: (cell, row) => { return (row.concepts.length > 0 && row.concepts[0].secondary_request_type) ? row.concepts[0].secondary_request_type : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.secondary_request_typeOptions,
        defaultValue: localStorage.getItem("filter_sec_request_type"),
        onFilter: (e) => { localStorage.setItem("filter_sec_request_type", e) },
        getFilter: (filter) => { this.filterProperty["roFilterSecRT"] = filter }
      }),
    },
    {
      dataField: 'stage',
      text: 'Submission Stage',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_stage"),
      //   onFilter: (e) => { localStorage.setItem("filter_stage", e) },
      //   getFilter: (filter) => { this.filterProperty["roFilter7"] = filter }
      // }),
      formatter: (cell, row) => { return row['status']? row.status : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.stageOptions,
        defaultValue: localStorage.getItem("filter_stage"),
        onFilter: (e) => { localStorage.setItem("filter_stage", e) },
        getFilter: (filter) => { this.filterProperty["roFilter7"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('stage'),
      sort: true,
      csvExport: true,
      // csvExport: th,
      csvFormatter: (cellContent, row) => { return row && row.stage ? row.stage : ""; }
    }, {
      dataField: 'status',
      text: 'Submission Status',
      // filter: textFilter({
      //   defaultValue: localStorage.getItem("filter_status"),
      //   onFilter: (e) => { localStorage.setItem("filter_status", e) },
      //   getFilter: (filter) => { this.filterProperty["roFilter8"] = filter }
      // }), 
      headerClasses: "width-240",
      formatter: (cell, row) => { return row['status']? row.status : '' },
      filter: selectFilter({
        placeholder: 'All',
        options: this.state.statusOptions,
        defaultValue: localStorage.getItem("filter_status"),
        onFilter: (e) => { localStorage.setItem("filter_status", e) },
        getFilter: (filter) => { this.filterProperty["roFilter8"] = filter }
      }),
      // hidden: !this.colSelected[this.state.userRole].includes('status'),
      sort: true,
      csvExport: true,
      // csvExport: thi,
      csvFormatter: (cellContent, row) => { return row && row.status ? row.status : ""; }
    },

    {
      dataField: 'concepts[0].indication',
      text: '[EXT] Indication(Tumor)',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_tumorIndication"),
        onFilter: (e) => { localStorage.setItem("filter_tumorIndication", e) },
        getFilter: (filter) => { this.filterProperty["roFilter9"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('tumor_indication'),
      sort: true,
      hidden: this.state.selectedRole === 'read_only_diabetes',
      csvExport: this.state.selectedRole !== 'read_only_diabetes',
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].indication : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].indication : ""; }
    },
    {
      dataField: 'indication',
      text: '[EXT] Other Indication',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_indication"),
        onFilter: (e) => { localStorage.setItem("filter_indication", e) },
        getFilter: (filter) => { this.filterProperty["roFilter10"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('indication'),
      sort: true,
      csvExport: true,
      // csvExport: this.co,
      csvFormatter: (cellContent, row) => { return row && row.indication ? row.indication : ""; }
    },
    {
      dataField: 'investigator_last_name',
      text: '[EXT] Investigator Last Name',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_investigator_last_name"),
        onFilter: (e) => { localStorage.setItem("filter_investigator_last_name", e) },
        getFilter: (filter) => { this.filterProperty["roFilter11"] = filter }
      }),// csvExport: this.colSelected[th,
      // hidden: !this.colSelected[this.state.userRole].includes('investigator_last_name'),
      sort: true,
      csvExport: true,
      csvFormatter: (cellContent, row) => { return row && row.investigator_last_name ? row.investigator_last_name : ""; }
    },
    {
      dataField: 'investigator_first_name',
      text: '[EXT] Investigator First Name',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_first_name"),
        onFilter: (e) => { localStorage.setItem("filter_first_name", e) },
        getFilter: (filter) => { this.filterProperty["roFilter12"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('investigator_first_name'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected[thi,
      csvFormatter: (cellContent, row) => { return row && row.investigator_first_name ? row.investigator_first_name : ""; }
    },

    {
      dataField: 'concepts[0].primary_institution',
      text: '[EXT] Investigator Primary Institution',
      headerClasses: "headerHeight",
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_investigatorPrimaryInstitution"),
        onFilter: (e) => { localStorage.setItem("filter_investigatorPrimaryInstitution", e) },
        getFilter: (filter) => { this.filterProperty["roFilter13"] = filter }
      }),// csvExport: this.colSelected[this.state.u,
      // hidden: !this.colSelected[this.state.userRole].includes('investigator_primary_institution'),
      sort: true,
      csvExport: true,
      formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].primary_institution : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].primary_institution : ""; }
    },
    {
      dataField: 'assigned_data.first_name',
      text: 'Assigned To',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_assignTo"),
        onFilter: (e) => { localStorage.setItem("filter_assignTo", e) },
        getFilter: (filter) => { this.filterProperty["roFilter14"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('assigned_data'),
      formatter: (cell, row, rowIndex) => { return <> {row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : ""} </> },
      sort: true,
      csvExport: true,
      // csvExport: this.colSe,
      csvFormatter: (cellContent, row) => { return row && row.assigned_data != null ? row.assigned_data?.first_name + " " + row.assigned_data?.last_name : "" }
    },
    {
      dataField: 'Additional_cordinator',
      text: 'Additional Coordinator',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_additional_cordinator"),
        onFilter: (e) => { localStorage.setItem("filter_additional_cordinator", e) },
        getFilter: (filter) => { this.filterProperty["roFilter15"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('additional_cordinator'),
      sort: true,
      csvExport: true,
      // csvExport: this.colSelected[t,
      csvFormatter: (cellContent, row) => { return row && row.additional_cordinator ? row.additional_cordinator : ""; }
    },
    {
      dataField: 'msl_data.first_name',
      text: 'Primary  MSL',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_primaryMsl"),
        onFilter: (e) => { localStorage.setItem("filter_primaryMsl", e) },
        getFilter: (filter) => { this.filterProperty["roFilter16"] = filter }
      }),// hidden: !this.colSelected[this.state.userRole].includes('msl_data'),
      sort: true,
      csvExport: true,
      // csvExport: this.,
      formatter: (cell, row, rowIndex) => { return <> {row.msl_data != null ? row.msl_data?.first_name + " " + row.msl_data?.last_name : ""} </> },
      csvFormatter: (cellContent, row) => { return row && row.msl_data != null ? row.msl_data?.first_name + " " + row.msl_data?.last_name : "" }
    },
    {
      dataField: 'impact_scale',
      text: 'Impact Scale',
      filter: textFilter({
        defaultValue: localStorage.getItem("filter_impact_scale"),
        onFilter: (e) => { localStorage.setItem("filter_impact_scale", e) },
        getFilter: (filter) => { this.filterProperty["roFilter17"] = filter }
      }),
      sort: true,
      csvExport: true,
     
      csvFormatter: (cellContent, row) => { return row && row.impact_scale  ? row.impact_scale : "" }
    },
    ];
  }
  getcolumnsIS() {
    return [
      {
        dataField: 'ID',
        text: 'Record Number',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_recordNo7"),
          onFilter: (e) => { localStorage.setItem("filter_recordNo7", e) },
          getFilter: (filter) => { this.filterProperty["isFilter1"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('id'),
        sort: true,
        headerClasses: "headerHeight",
        csvExport: true,
        stickyColumnShown: true,

        // csvExport:,
        csvFormatter: (cellContent, row) => { return row && row.id ? row.id : ""; },
        formatter: (cell, row, rowIndex) => { return <> {"Submission-" + (row.id + "").padStart(4, '0')} </> }
      },
      {
        dataField: 'concepts[0].therapeutic_area',
        text: 'Therapeutic Area',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_therapeutic_area"),
          onFilter: (e) => { localStorage.setItem("filter_therapeutic_area", e) },
          getFilter: (filter) => { this.filterProperty["isFilter2"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('therapeutic_area'),
        sort: true,
        csvExport: true,

        // csvExport: this.colSelec,
        formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].therapeutic_area : ""} </> },
        csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].therapeutic_area : ""; }
      },
      {
        dataField: 'org_name',
        text: '[EXT] Organisation Name',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_org_name"),
          onFilter: (e) => { localStorage.setItem("filter_org_name", e) },
          getFilter: (filter) => { this.filterProperty["isFilter3"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('org_name'),
        sort: true,
        csvExport: true,

        // csvExport: this.,
        csvFormatter: (cellContent, row) => { return row && row.org_name ? row.org_name : ""; }
      },

      {
        dataField: 'stage',
        text: 'Submission Stage',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_stage"),
          onFilter: (e) => { localStorage.setItem("filter_stage", e) },
          getFilter: (filter) => { this.filterProperty["isFilter4"] = filter }
        }),
        // hidden: !this.colSelected[this.state.userRole].includes('stage'),
        sort: true,
        csvExport: true,
        // csvExport: th,
        csvFormatter: (cellContent, row) => { return row && row.stage ? row.stage : ""; }
      }, {
        dataField: 'status',
        text: 'Submission Status',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_status"),
          onFilter: (e) => { localStorage.setItem("filter_status", e) },
          getFilter: (filter) => { this.filterProperty["isFilter5"] = filter }
        }), headerClasses: "width-240",

        // hidden: !this.colSelected[this.state.userRole].includes('status'),
        sort: true,
        csvExport: true,
        // csvExport: thi,
        csvFormatter: (cellContent, row) => { return row && row.status ? row.status : ""; }
      },
      {
        dataField: 'trial_alias',
        text: 'Trial Alias',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_trial_alias"),
          onFilter: (e) => { localStorage.setItem("filter_trial_alias", e) },
          getFilter: (filter) => { this.filterProperty["isFilter6"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('trial_alias'),
        sort: true,
        csvExport: true,

        // csvExport: this.col,
        csvFormatter: (cellContent, row) => { return row && row.trial_alias ? row.trial_alias : ""; }
      },
      {
        dataField: 'concepts[0].compound',
        text: '[EXT] Compound',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_compound"),
          onFilter: (e) => { localStorage.setItem("filter_compound", e) },
          getFilter: (filter) => { this.filterProperty["isFilter7"] = filter }
        }),// csvExport: this.,
        // hidden: !this.colSelected[this.state.userRole].includes('compound'),
        sort: true,
        csvExport: true,
        formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].compound : ""} </> },
        csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].compound : ""; }
      },
      {
        dataField: 'expected_enrollment',
        text: 'Expected Enrollment',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_expected_enrollment"),
          onFilter: (e) => { localStorage.setItem("filter_expected_enrollment", e) },
          getFilter: (filter) => { this.filterProperty["isFilter8"] = filter }

        }),// hidden: !this.colSelected[this.state.userRole].includes('expected_enrollment'),
        sort: true,
        csvExport: true,
        // csvExport: this.colSelected,
        csvFormatter: (cellContent, row) => { return row && row.expected_enrollment ? row.expected_enrollment : ""; }
      },
      {
        dataField: 'current_enrollment_actual',
        text: 'Enrollment (Actual)',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_current_enrollment_actual"),
          onFilter: (e) => { localStorage.setItem("filter_current_enrollment_actual", e) },
          getFilter: (filter) => { this.filterProperty["isFilter9"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('current_enrollment_actual'),
        sort: true,
        csvExport: true,
        // csvExport: this.colS,
        csvFormatter: (cellContent, row) => { return row && row.current_enrollment_actual ? row.current_enrollment_actual : ""; }
      },
      {
        dataField: 'fpv_baseline',
        text: '[EXT] FPV (Baseline) ',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_fpv_baseline"),
          onFilter: (e) => { localStorage.setItem("filter_fpv_baseline", e) },
          getFilter: (filter) => { this.filterProperty["isFilter10"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('fpv_baseline'),
        sort: true,
        csvExport: true,
        // csvExport: this.colS,
        csvFormatter: (cellContent, row) => { return row && row.fpv_baseline ? row.fpv_baseline : ""; }
      },
      {
        dataField: 'concepts[0].indication',
        text: '[EXT] Indication(Tumor)',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_tumorIndication"),
          onFilter: (e) => { localStorage.setItem("filter_tumorIndication", e) },
          getFilter: (filter) => { this.filterProperty["isFilter11"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('tumor_indication'),
        sort: true,
        csvExport: true,
        formatter: (cell, row, rowIndex) => { return <>{row.concepts.length > 0 ? row.concepts[0].indication : ""} </> },
        csvFormatter: (cellContent, row) => { return row && row.concepts.length > 0 ? row.concepts[0].indication : ""; }
      },
      {
        dataField: 'contract_complete_estimate',
        text: 'Contract Complete (Estimate)',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_contract_complete_estimate"),
          onFilter: (e) => { localStorage.setItem("filter_contract_complete_estimate", e) },
          getFilter: (filter) => { this.filterProperty["isFilter12"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('contract_complete_estimate'),
        sort: true,
        csvExport: true,
        // csvExport: this.colSelected[this.s,
        csvFormatter: (cellContent, row) => { return row && row.contract_complete_estimate ? row.contract_complete_estimate : ""; }
      },
      {
        dataField: 'lpv_baseline',
        text: '[EXT] LPV (Baseline) ',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_lpv_baseline"),
          onFilter: (e) => { localStorage.setItem("filter_lpv_baseline", e) },
          getFilter: (filter) => { this.filterProperty["isFilter13"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('lpv_baseline'),
        sort: true,
        csvExport: true,
        // csvExport: this.colS,
        formatter: (cell, row, rowIndex) => { return <>{row.lpv_baseline != null ? dateformat(row.lpv_baseline) : ""} </> },
        csvFormatter: (cellContent, row) => { return row && row.lpv_baseline ? row.lpv_baseline : ""; }
      },
      {
        dataField: 'milestone_comments',
        text: 'Milestone Comments',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_milestone_comments"),
          onFilter: (e) => { localStorage.setItem("filter_milestone_comments", e) },
          getFilter: (filter) => { this.filterProperty["isFilter14"] = filter }
        }),// hidden: !this.colSelected[this.state.userRole].includes('milestone_comments'),
        sort: true,
        csvExport: true,
        // csvExport: this.colSelecte,
        csvFormatter: (cellContent, row) => { return row && row.milestone_comments ? row.milestone_comments : ""; }
      },
      {
        dataField: 'impact_scale',
        text: 'Impact Scale',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_impact_scale"),
          onFilter: (e) => { localStorage.setItem("filter_impact_scale", e) },
          getFilter: (filter) => { this.filterProperty["isFilter15"] = filter }
        }),
        sort: true,
        csvExport: true,
       
        csvFormatter: (cellContent, row) => { return row && row.impact_scale  ? row.impact_scale : "" }
      },
    ];
  }

  componentDidUpdate(previousProps, previousState) {
  
    if(previousProps.userInfo.selectedRole!=this.props.userInfo.selectedRole){
      window.setTimeout(()=>{
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
          selectedRole: selectedRole,
          userRole: this.context.userRolesList ? this.context.userRolesList : 'Administrator',
          reloadTable: this.context.userRolesList,
          isLoading: true
        }, () => { this.getSubmissionList(true); }); 
      },500)
      
    }
    
    // //console.log(this.context.userRolesList);
    // //console.log(previousState.userRole)
    //if (!Array.isArray(this.context.userRolesList) && (previousState.userRole != this.context.userRolesList)) {
        //this.setState(
            // { reloadTable: false }
       // )
       // this.setState({

            //userRole: this.colSelected[this.context.userRolesList] ? this.context.userRolesList : 'Administrator',
           // reloadTable: true
       // })
        //console.log(this.context.userRolesList)
   // }
    //console.log(this.context.userRolesList);
    //console.log(previousState.userRole)
    
    // const localname=localStorage.getItem("filter_dropdown");    
    // let selectedViewObj=(localname&&localname.toString().toLowerCase()!="all") ? JSON.parse(localname) : "";
    // console.log("Filter>>>>>>>>>>>>>>>>>>>>>>>>>Role>>>>>",`${typeof(selectedViewObj)}  -  ${this.context.userRolesList}  -  ${previousState.userRole}`)
    // if ( !Array.isArray(this.context.userRolesList) && (previousState.userRole != this.context.userRolesList)) {
    //   let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
      // this.setState({
      //   selectedRole: selectedRole,
      //   userRole: this.context.userRolesList ? this.context.userRolesList : 'Administrator',
      //   reloadTable: this.context.userRolesList,
      //   isLoading:true
      // }, () => { this.getSubmissionList(true); }); //console.log("userRole", this.state.userRole) })

    //}
    
  }


  rowEvents = {
    onClick: (e, row, rowIndex) => {
      let {is_new_workflow}=this.state;
      if(is_new_workflow){
        sessionStorage.setItem("flow-req-from","submission");
        this.props.history.push(`/toreview/details/${row.id}`);
      }
      else{
        this.props.history.push(`/submissions/${row.id}`);
      }
      
    }
  };
  
  getAllSubmission = async () => {
    let data = [];
    let res;
    let i = 0;
    let count = await API.get("submission/count/data");
    count = count.data.count;
    this.slicer=this.getSlicerData(count);
    let numOfCalls = Math.ceil(count / this.slicer);
    let {reloadTable}=this.state;
    do {
      res = API.post("submission/newsliced/data", {
        limit: this.slicer,
        offset: this.slicer * i,
        role:reloadTable

      });
      data.push(res);
      i++;
    }
    while (i < numOfCalls)

    let records = [];

    await Promise.all(data)
      .then((data) => {

        records = [];
        data.map((res) => {
          records = [...records, ...res.data];
        })

        return records;
      })
      .catch((e) => {
        //console.log(e)
        records = [];
      });
    return records;
  }
  getSubmissionsByArea = async (area) => {
    let data = [];
    let res;
    let i = 0;
    let numOfCalls = "";
    
    let {reloadTable}=this.state;
    if (this.state.selectedRole.includes("industry_sponsored")) {
      let count = await API.post("submission/count/data/getbyarea", {
        request_type: ["Industry Sponsored"]
      });
      count = count.data.count;
      this.slicer=this.getSlicerData(count);
      numOfCalls = Math.ceil(count / this.slicer);
    }
    else {
      let count = await API.post("submission/count/data/getbyarea", {
        area: area
      });
      count = count.data.count;
      this.slicer=this.getSlicerData(count);
      numOfCalls = Math.ceil(count / this.slicer);
    }


    do {
      if (this.state.selectedRole.includes("industry_sponsored")) {
        res = API.post("submission/newsliced/data/getbyarea", {
          request_type: ["Industry Sponsored"],
          limit: this.slicer,
          offset: this.slicer * i,
          role:reloadTable

        });
      }
      else {
        res = API.post("submission/newsliced/data/getbyarea", {
          area: area,
          limit: this.slicer,
          offset: this.slicer * i,
          role:reloadTable

        });
      }

      data.push(res);
      i++;
    }
    while (i < numOfCalls)

    let records = [];

    await Promise.all(data)
      .then((data) => {

        records = [];
        data.map((res) => {
          records = [...records, ...res.data];
        })

        return records;
      })
      .catch((e) => {
        //console.log(e)
        records = [];
      });
    return records;
  }
  getUserList = async () => {
    const res = await API.get(`user/data`)

    this.setState({ usersList: res.data });
  }
  getSubmissionList = async (clearFilter) => {
    
    await this.getUserList();
    this.setState({
      isLoading: true,
      submissionList: [],
      resetView: true,
      selectedView: null
    })
    //console.log("test1")
    let res;
    let statusOptions, stageOptions, request_typeOptions, secondary_request_typeOptions;
    //console.log("--selectedrole----", this.state.selectedRole)
    if (this.state.selectedRole.includes("oncology")) {
      res = await this.getSubmissionsByArea(["Oncology"]);
      res.map(submission => {
        submission["ID"] = (submission.id + "").padStart(4, '0')
        let result = submission.additional_cordinator != null ? this.state.usersList.filter(x => x.system_id === (submission.additional_cordinator))[0] : "";
        submission["Additional_cordinator"] = result ? result.first_name + " " + result.last_name : ""
      });

    }
    else if (this.state.selectedRole.includes("diabetes")) {
      res = await this.getSubmissionsByArea(["Diabetes & Obesity"]);
      res.map(submission => {
        submission["ID"] = (submission.id + "").padStart(4, '0')
        let result = submission.additional_cordinator != null ? this.state.usersList.filter(x => x.system_id === (submission.additional_cordinator))[0] : "";
        submission["Additional_cordinator"] = result ? result.first_name + " " + result.last_name : ""
      });
    }
    else if (this.state.selectedRole.includes("biomeds")) {
      res = await this.getSubmissionsByArea([

        "None",
        "Immunology",
        "Neurodegenerative Diseases",
        "Headache & Pain"
      ]
      );
      res.map(submission => {
        submission["ID"] = (submission.id + "").padStart(4, '0')
        let result = submission.additional_cordinator != null ? this.state.usersList.filter(x => x.system_id === (submission.additional_cordinator))[0] : "";
        submission["Additional_cordinator"] = result ? result.first_name + " " + result.last_name : ""
      });
    }
    else if (this.state.selectedRole.includes("industry_sponsored")) {
      res = await this.getSubmissionsByArea(["Industry Sponsored"]);
      res.map(submission => {
        submission["ID"] = (submission.id + "").padStart(4, '0')
        let result = submission.additional_cordinator != null ? this.state.usersList.filter(x => x.system_id === (submission.additional_cordinator))[0] : "";
        submission["Additional_cordinator"] = result ? result.first_name + " " + result.last_name : ""
      });
    }
    else {

      res = await this.getAllSubmission();
      res.map(submission => {
        submission["ID"] = (submission.id + "").padStart(4, '0')
        let result = submission.additional_cordinator != null ? this.state.usersList.filter(x => x.system_id === (submission.additional_cordinator))[0] : "";
        submission["Additional_cordinator"] = result ? result.first_name + " " + result.last_name : ""
      });

      
    }

    statusOptions = [...new Set(res.map(submission => submission.status))];
      let nullIndex = statusOptions.indexOf(null);
      if(nullIndex !== -1){
        statusOptions.splice(nullIndex, 1)
      }
      statusOptions = statusOptions.map(item=>{
        // if(item===null){
        //   return {label: 'Empty', value: ''};
        // }else{
          return {label: item, value: item}
        // }
      })
      statusOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      //
      stageOptions = [...new Set(res.map(submission => submission.stage))];
      nullIndex = stageOptions.indexOf(null);
      if(nullIndex !== -1){
        stageOptions.splice(nullIndex, 1)
      }
      stageOptions = stageOptions.map(item=>{
        // if(item===null){
        //   return {label: 'Empty', value: ''};
        // }else{
          return {label: item, value: item}
        // }
      })
      stageOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      //
      request_typeOptions = [...new Set(res.map(submission => submission.concepts[0]?.request_type))];
      nullIndex = request_typeOptions.indexOf(null);
      if(nullIndex !== -1){
        request_typeOptions.splice(nullIndex, 1)
      }
      let undefinedIndex = request_typeOptions.indexOf(undefined);
      if(undefinedIndex !== -1){
        request_typeOptions.splice(undefinedIndex, 1)
      }
      let emptyStringIndex = request_typeOptions.indexOf('');
      if(emptyStringIndex !== -1){
        request_typeOptions.splice(emptyStringIndex, 1)
      }
      request_typeOptions = request_typeOptions.map(item=>{
        // if(item===null){
        //   return {label: 'Empty', value: ''};
        // }else{
          return {label: item, value: item}
        // }
      })
      request_typeOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      //
      secondary_request_typeOptions = [...new Set(res.map(submission => submission.concepts[0]?.secondary_request_type))];
      nullIndex = secondary_request_typeOptions.indexOf(null);
      if(nullIndex !== -1){
        secondary_request_typeOptions.splice(nullIndex, 1)
      }
      undefinedIndex = secondary_request_typeOptions.indexOf(undefined);
      if(undefinedIndex !== -1){
        secondary_request_typeOptions.splice(undefinedIndex, 1)
      }
      emptyStringIndex = secondary_request_typeOptions.indexOf('');
      if(emptyStringIndex !== -1){
        secondary_request_typeOptions.splice(emptyStringIndex, 1)
      }
      secondary_request_typeOptions = secondary_request_typeOptions.map(item=>{
        // if(item===null){
        //   return {label: 'Empty', value: ''};
        // }else{
          return {label: item, value: item}
        // }
      })
      secondary_request_typeOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

    this.setState({ submissionList: res, isLoading: false, statusOptions, stageOptions, request_typeOptions, secondary_request_typeOptions},()=>{
     
      if(clearFilter){
        this.handleClearAllFilter();
      }
    });

  }
  reset_therapeutic = async () => {
    this.setState({
      isLoading:true
    });
    let res = await this.getAllSubmission();
    res.map(submission => {
      submission["ID"] = (submission.id + "").padStart(4, '0')
      let result = submission.additional_cordinator != null ? this.state.usersList.filter(x => x.system_id === (submission.additional_cordinator))[0] : "";
      submission["Additional_cordinator"] = result ? result.first_name + " " + result.last_name : ""
    });
    this.setState({
      submissionList: res,
      resetView: false,
      isLoading:false
    });

  }
  
  async componentDidMount() {
    const localname=localStorage.getItem("filter_dropdown");
    let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
    let selectedViewObj=localname != "all"&& localname !="undefined"&&localname!="" ? JSON.parse(localname) : null;
    
    // this.setState({
    //   isLoading: true,
    // });
    
    // let is_new_workflow=localStorage.getItem("is_new_workflow");
    // let stateValues={
    //   selectedRole: selectedRole,
    //   isLoading: true
    // };
    // if(is_new_workflow){
      //   stateValues.is_new_workflow=is_new_workflow==="true"?true:false;
      // }  
    this.setState({
      selectedRole: selectedRole,
      isLoading: true
    });    
    await this.fetchWorkflowToggle();
    await this.getViewList(false);
    // if (selectedViewObj) {
    //   this.handleViewDropdown(selectedViewObj)

    // }
    
  }
  /*METHOD TO FETCH WORKFLOW TOGGLE STATE FROM DB*/
  fetchWorkflowToggle = async () => {
    try{
      const res = await API.get(`enrollmentOptimization/userSettings/fetch/${this.context.userSystemId}`);
      if(res?.data){
        // localStorage.setItem("is_new_workflow", res?.data?.new_workflow_toggle);
        this.setState({is_new_workflow:res?.data?.new_workflow_toggle});
      }
    }
    catch(e) {
      //console.log(e);
    }
  }
  /*METHOD TO SAVE WORKFLOW TOGGLE STATE CHANGE IN DB*/
  saveWorkflowToggle = (workflowToggleChangedValue) => {
    try{
      const res =  API.post(`enrollmentOptimization/userSettings/upsert`,{
        'system_id': this.context.userSystemId,
        'new_workflow_toggle': workflowToggleChangedValue
      });
    }
    catch(e) {
      //console.log(e);
    }
  }
  /**
   * Get All custom View list Data and load submission as per View
  */
 getViewList = async (viewAction) => {
   const res = await API.get(`views/list/${this.context.userSystemId}`);
    let defaultView = null;
    if (viewAction) {
      defaultView = res?.data?.filter(itemView => itemView.id === this.state.selectedView?.id);
    } else {
      defaultView = res?.data?.filter(itemView => itemView.is_default_view === true);
    }
    //this.setState({ selectedView: null })


    const localname=localStorage.getItem("filter_dropdown");  
    let selectedViewObj=localname != "all"&& localname !="undefined"&&localname!="" ? JSON.parse(localname) : null;
    let view=null;
    if(selectedViewObj?.id){
      view=selectedViewObj;
      await this.getViewLevelDataRecords(selectedViewObj?.id)
    }
    else if (defaultView?.length > 0) {
      view= defaultView?.length > 0 ? defaultView[0] : null;
      await this.getViewLevelDataRecords(defaultView[0]?.id)
    } else {
      await this.getSubmissionList();
    }
    this.setState({
      viewList: res?.data,
      selectedView: view,
      isLoading: false
    })
  }
  slicer=200;
  getSlicerData=(total)=>{
    //for future , we can add some logic based on data
    if(total<500) return total;
    return 500;     
  }
  getCustomViewData = async (id) => {
    let data = [];
    let res;
    let i = 0;
    let count = await API.get(`/views/filteredData/count/${id}`);
    count = count.data.count;
    this.slicer=this.getSlicerData(count);
    let numOfCalls = Math.ceil(count / this.slicer);

    do {
      res = API.post(`/views/filteredData/getbyid`, {
        id,
        limit: this.slicer,
        offset: this.slicer * i

      });
      data.push(res);
      i++;
    }
    while (i < numOfCalls)

    let records = {
      filter_column: [],
      filtered_data: []
    };

    await Promise.all(data)
      .then((data) => {

        records.filtered_data = [];
        records.filter_column = [];
        data.map((res,index) => {
          res.key_col=`${res.id}_${i}`;
          if (records.filter_column.length === 0) {
            records.filter_column = res.data.filter_column;
          }
          records.filtered_data = [...records.filtered_data, ...res.data.filtered_data];
        })

        return records;
      })
      .catch((e) => {
        //console.log(e)
        records = {
          filter_column: [],
          filtered_data: []
        };
      });
    return records;
  }

  genrateColumnsObject = (dataField, text, type, dropdown_type, filterType, optionsName) => {
    // console.log('paramas:', dataField, text, type, dropdown_type, filterType)
    let filterText = "filter_" + dataField.toLowerCase().replace(" ", "_");
    filterText = filterText.replace(".","_");
    return ({
      dataField: dataField,
      text: text,
      filter: filterType==='textFilter' ? textFilter({
        defaultValue: localStorage.getItem(filterText),
        onFilter: (e) => { 
          // e && localStorage.setItem(filterText, e); 
          if(e){
            localStorage.setItem(filterText, e)
          }else{
            localStorage.removeItem(filterText)
          }
        },
        getFilter: (filter) => { 
          this.filterProperty[filterText] = filter }
      }) : selectFilter({
        placeholder: 'All',
        options: optionsName,
        defaultValue: localStorage.getItem(filterText),
        onFilter: (e) => { e && localStorage.setItem(filterText, e) },
        getFilter: (filter) => { 
          this.filterProperty[filterText] = filter }
      }),
      sort: true,
      csvExport: true,
      stickyColumnShown: true,
      formatter: (cell, row, rowIndex) => {
        return <>
          {row && row[dataField] != null ?
            type === "date" ? dropdown_type === "datetime-local" ?
              dateTimeStringformat(row[dataField])
              : dateStringformat(row[dataField]) :
              row[dataField] === true ? "True" : row[dataField] === false ? "False" : row[dataField] : ""
            }
        </>
      },
      csvFormatter: (cellContent, row) => {
        return row && row[dataField] ?
          type === "date" ? dropdown_type === "datetime-local" ?
            dateTimeStringformat(row[dataField])
            : dateStringformat(row[dataField]) :
            row[dataField] === true ? "True" : row[dataField] === false ? "False" : row[dataField] : ""
      }

    })
  }
  /** Get Custom View Submission Records */
  getViewLevelDataRecords = async (viewId) => {
    this.setState({
      isLoading: true
    })
    try {
      // let res = await API.get(`/views/filteredData/getbyid/${viewId}`);
      let res = await this.getCustomViewData(viewId);

      // let { customViewColumns } = this.state;
      let customViewColumns = [
        {
          dataField:'key_col',
          hidden:true,
          csvExport:false
        },
        {
        dataField: 'id',
        text: 'Record Number',
        filter: textFilter({
          defaultValue: localStorage.getItem("filter_recordNo1"),
          onFilter: (e) => { localStorage.setItem("filter_recordNo1", e) },
          getFilter: (filter) => { this.filterProperty["filter_recordNo1"] = filter }
        }),
        sort: true,
        csvExport: true,
        stickyColumnShown: true,
        csvFormatter: (cellContent, row) => { 
          if(row&&row["drug_delivery_sites.impact_site"]){
            row["drug_delivery_sites.impact_site"]="\t"+row["drug_delivery_sites.impact_site"]??"";
          }
          if(row&&row["submission.investigator_zipcode"]){
            row["submission.investigator_zipcode"]="\t"+row["submission.investigator_zipcode"]??"";
          }
          if(row&&row["submission.org_zipcode"]){
            row["submission.org_zipcode"]="\t"+row["submission.org_zipcode"]??"";
          }
          if(row&&row["drug_delivery_sites.zip_postal_code"]){
            row["drug_delivery_sites.zip_postal_code"]="\t"+row["drug_delivery_sites.zip_postal_code"]??"";
          }
          if(row&&row["submission.nct_study"]){
            row["submission.nct_study"]="\t"+row["submission.nct_study"]??"";
          }
          return row && row.id ? row.id : ""; 
        },
        formatter: (cell, row, rowIndex) => { return <> {"Submission-" + (row.id + "").padStart(4, '0')} </> }
      }];

      let statusOptions = [...new Set(res?.filtered_data.map(submission => submission['submission.status']))];
      let nullIndex = statusOptions.indexOf(null);
      if(nullIndex !== -1){
        statusOptions.splice(nullIndex, 1)
      }
      statusOptions = statusOptions.map(item=>{
        // if(item===null){
        //   return {label: 'Empty', value: ''};
        // }else{
          return {label: item, value: item}
        // }
      })
      statusOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      //
      let stageOptions = [...new Set(res?.filtered_data.map(submission => submission['submission.stage']))];
      nullIndex = stageOptions.indexOf(null);
      if(nullIndex !== -1){
        stageOptions.splice(nullIndex, 1)
      }
      stageOptions = stageOptions.map(item=>{
        // if(item===null){
        //   return {label: 'Empty', value: ''};
        // }else{
          return {label: item, value: item}
        // }
      })
      stageOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      
      //
      let request_typeOptions = [...new Set(res?.filtered_data.map(submission => submission['concept.request_type']))];
      nullIndex = request_typeOptions.indexOf(null);
      if(nullIndex !== -1){
        request_typeOptions.splice(nullIndex, 1)
      }
      let undefinedIndex = request_typeOptions.indexOf(undefined);
      if(undefinedIndex !== -1){
        request_typeOptions.splice(undefinedIndex, 1)
      }
      let emptyStringIndex = request_typeOptions.indexOf('');
      if(emptyStringIndex !== -1){
        request_typeOptions.splice(emptyStringIndex, 1)
      }
      request_typeOptions = request_typeOptions.map(item=>{
        // if(item===null){
        //   return {label: 'Empty', value: ''};
        // }else{
          return {label: item, value: item}
        // }
      })
      request_typeOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      //
      let secondary_request_typeOptions = [...new Set(res?.filtered_data.map(submission => submission['concept.secondary_request_type']))];
      nullIndex = secondary_request_typeOptions.indexOf(null);
      if(nullIndex !== -1){
        secondary_request_typeOptions.splice(nullIndex, 1)
      }
      undefinedIndex = secondary_request_typeOptions.indexOf(undefined);
      if(undefinedIndex !== -1){
        secondary_request_typeOptions.splice(undefinedIndex, 1)
      }
      emptyStringIndex = secondary_request_typeOptions.indexOf('');
      if(emptyStringIndex !== -1){
        secondary_request_typeOptions.splice(emptyStringIndex, 1)
      }
      secondary_request_typeOptions = secondary_request_typeOptions.map(item=>{
        // if(item===null){
        //   return {label: 'Empty', value: ''};
        // }else{
          return {label: item, value: item}
        // }
      })
      secondary_request_typeOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

      this.setState({
        statusOptions, stageOptions, 
        request_typeOptions, secondary_request_typeOptions
      }, 
      ) //await

      const selectFilterColumns = ['submission.status', 'submission.stage', 
      'concept.request_type', 'concept.secondary_request_type'
    ]
      res?.filter_column.map(async (column) => {
        if( selectFilterColumns.indexOf(column.field_name)!== -1 ){
          let optionsName;
          switch (column.field_name){
            case 'submission.status':
              optionsName = this.state.statusOptions;
              break;
            case 'submission.stage':
              optionsName = this.state.stageOptions;
              break;
            case 'concept.request_type':
              optionsName = this.state.request_typeOptions;
              break;
            case 'concept.secondary_request_type':
              optionsName = this.state.secondary_request_typeOptions;
              break;
          }
          const geratedColumnObject = await this.genrateColumnsObject(column.field_name, column.field_label, column.field_type, column.dropdown_type, 'selectFilter', optionsName);
          customViewColumns.push(geratedColumnObject);
        }else{
          const geratedColumnObject = await this.genrateColumnsObject(column.field_name, column.field_label, column.field_type, column.dropdown_type, 'textFilter');
          customViewColumns.push(geratedColumnObject);
        }
        
      })
      const customViewSubmissionList = res?.filtered_data
      this.setState({
        // statusOptions,
        customViewColumns,
        customViewSubmissionList,
      });
    }
    catch (e) {
      //console.log(e)
    }
  }
  /** Handle View Dropdown **/
  handleViewDropdown = async (viewDetails) => {
    this.setState({
      isLoading: true,
      customViewColumns: []
    }, () => {
      Object.keys(localStorage).map(item => {
        // if (item.includes("filter_")) {
         
        //   localStorage.removeItem(item)
         
        // }
        
      })
    })
    

    if (viewDetails === "all") {
      await this.getSubmissionList();
      localStorage.setItem("filter_dropdown",viewDetails)
      
      this.setState({
        selectedView: null,
        
        isLoading: false,
        
      })
      localStorage.setItem("filter_dropdown", JSON.stringify(viewDetails))
      return false
     
        
      
    }
    await this.getViewLevelDataRecords(viewDetails?.id)
    this.setState({
      selectedView: viewDetails,
      isLoading: false
    })
    
    localStorage.setItem("filter_dropdown", JSON.stringify(viewDetails))
    
    
  }


  handleCreateViewModalShowHide = async (flag, viewEditFlag) => {
    this.setState({ showHideCreateViewModal: flag, viewEditFlag });
  }
  /** Handle Delete functionality of View */
  handleDeleteView = async () => {
    swal({
      title: "Are you sure?",
      text: `Once ${this.state.selectedView.view_name} view deleted, you will not be able to recover!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          try {
            const res = await API.delete(`views/delete/${this.state.selectedView.id}`);
            localStorage.removeItem("filter_dropdown")
            this.getViewList(false);
            swal("Success", "View Deleted Successfully!", "success")
          }
          catch (err) {
            swal("Error", err, "error")
          }
        }
      });

  }

  renderPageIcons=()=>{
    return <>
      {/* <Button size="sm" 
          onClick={() => 
          {
            this.setState({clearSearch:new Date().getTime()});
				    this.handleClearAllFilter();
          }} 
          className="d-flex align-items-center ml-2" 
          variant="outline-dark"> */}
              {/* *** <span className=" material-icons mr-1 m-0">clear </span> */}
              {/* <span 
               className=" material-icons mr-1 m-0"><i className="bi bi-funnel-fill mr-1 m-0"></i> </span> */}
              {/* *** <i className="bi bi-funnel-fill mr-1 m-0"></i> */}
              {/* <span style={{ fontSize: ' 0.75rem' }}>CLEAR ALL FILTER</span>
      </Button> */}


      <Dropdown className="align-items-center ml-2">
                
                <Dropdown.Toggle className=""  variant="outline-dark" id="filter_dropdown-basic">
                  {this.state.selectedView ? this.state.selectedView?.view_name:"All"
                  }
                </Dropdown.Toggle>
                
                <Dropdown.Menu>
                  <Dropdown.Item  onClick={() => this.handleViewDropdown("all")} >All</Dropdown.Item>
                  {
                    
                    this.state.viewList?.map(view => <Dropdown.Item onClick={() => this.handleViewDropdown(view)} >{view.view_name}</Dropdown.Item>)
                  }
                </Dropdown.Menu>
              </Dropdown>
      
      <Button size="sm" 
          onClick={() => this.handleCreateViewModalShowHide(true, null)} 
          className="d-flex align-items-center ml-2" 
          variant="outline-dark">
              <span className=" material-icons mr-1 m-0">visibility </span>
              <span style={{ fontSize: ' 0.75rem' }}>CREATE VIEW</span>
      </Button>

      {(this.state.selectedRole.includes("global_coordinator") || (this.state.selectedRole.includes("administrator"))) && <Button size="sm"
                    onClick={()=>this.handleConceptForm(true)} 
                    className="d-flex align-items-center ml-2" 
                    variant="outline-dark">
                        <span className="material-icons mr-1 m-0">add </span>
                        <span style={{ fontSize: ' 0.75rem' }}>NEW SUBMISSION</span>
              </Button>}
    </>;
  }

  handleClearAllFilter = () => {
    const filterPropertyNames = [  'columns1filter3', 'columns1filterSecRT', 'columns1filter6', 'columns1filter7', 'columns2filter11', 'columns2filterSecRT', 'columns2filter12', 'columns2filter13', 'coordinatorFilter5', 'coordinatorFilterSecRT', 'coordinatorFilter8', 'coordinatorFilter7', 'gcOncologyFilter3', 'gcOncologyFilter2', 'gcOncologyFilter8', 'gcOncologyFilterSecRT', 'gcdBioFilterSecRT', 'gcdBioFilter7', 'gcdBioFilter10', 'gcdBioFilter11', 'roFilterSecRT', 'roFilter6', 'roFilter7', 'roFilter8'];
    Object.keys(this.filterProperty).forEach((key, idx) => {   
       let filterFunction = this.filterProperty[key];
      try {
        filterFunction('');
      }
      catch (exp) {

      }
    });
    Object.keys(localStorage).map(item => {
      if (item.includes("filter_")) {
        localStorage.removeItem(item)
      }
    })
    
  }
  handleConceptForm=(flag, refresh)=>{
    this.setState({
      showconceptForm:flag
    })
    if(refresh===true){this.getViewList(false);}
  }
  render() {
    const { reloadTable, submissionList, resetView, isLoading, showHideCreateViewModal, viewEditFlag, viewList, selectedView,
      customViewColumns, customViewSubmissionList } = this.state;
    return (
      <div className="inpage-content">
        {isLoading && <LoaderEx />}
         <> <Alert className="alert-head" key="submission" variant="light">
          <Row>
            <Col sm={6}>
              <div className="d-flex align-itesm-center">
              <Alert.Heading>
                <span><i className="bi bi-journal-medical" style={{ fontSize: "1.5rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                Submissions</Alert.Heading>
                {!isLoading &&<Form.Group className="ml-2" style={{width:'200px',marginTop:'0.25rem'}}>
                        <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label="New Workflow"
                        name="is_new_workflow"                       
                        defaultChecked={this.state.is_new_workflow}
                        value={this.state.is_new_workflow}
                        onChange={(e)=>{
                            const { name, value } = e.target;
                            let is_new_workflow = e.target.checked;
                            // localStorage.setItem("is_new_workflow", is_new_workflow);
                            this.setState({is_new_workflow});
                            this.saveWorkflowToggle(e.target.checked);
                        }}
                        />
                        </Form.Group>}
              </div>
              
               
            </Col>

            <Col sm="6" className="text-right">
              {this.context.userRolesList.includes("Global Coordinator") && resetView && (this.state.selectedView == null) && <div className="new-button-link ml-4" style={{cursor:'pointer'}} onClick={this.reset_therapeutic}>
                <span><i className="bi bi-x-square-fill" style={{ fontSize: "1.2rem", margin: ".1rem", padding: ".1rem" }}></i></span> Reset Therapeutic Area
              </div>}
              {/* {(this.state.selectedRole.includes("global_coordinator") || (this.state.selectedRole.includes("administrator"))) && <Link className="new-button-link ml-4" to={{ pathname: "/concept/new" }}>
                <span><i className="bi bi-plus-square-fill" style={{ fontSize: "1.2rem", margin: ".1rem", padding: ".1rem" }}></i></span> New Submission
              </Link>} */}

            </Col>
          </Row>
        </Alert>
          <Row className='mb-3'>
            <Col md={3}>
              {/* <Dropdown className="record_view_dropdown shadow-none">
                
                <Dropdown.Toggle className="shadow-none"  variant="default" id="filter_dropdown-basic">
                  {selectedView ? selectedView?.view_name:"All"
                  }
                </Dropdown.Toggle>
                
                <Dropdown.Menu>
                  <Dropdown.Item  onClick={() => this.handleViewDrodown("all")} >All</Dropdown.Item>
                  {
                    
                    viewList?.map(view => <Dropdown.Item onClick={() => this.handleViewDropdown(view)} >{view.view_name}</Dropdown.Item>)
                  }
                </Dropdown.Menu>
              </Dropdown> */}

            </Col>
            <Col md={9} className="d-flex align-items-center justify-content-end " style={{paddingRight:'2rem'}}>
              {
                selectedView && <>
                  <Button size="sm" className="d-flex align-items-center ml-2" 
                    variant="outline-dark"
                    onClick={() => this.handleDeleteView()}>
                    <span className="material-icons" > delete</span>
                    <span style={{ fontSize: ' 0.75rem' }}>DELETE VIEW</span>
                  </Button>
                  <Button size="sm" className="d-flex align-items-center ml-2" 
                    variant="outline-dark"
                    onClick={() => this.handleCreateViewModalShowHide(true, true)}>
                    <span className="material-icons" > edit</span>
                    <span style={{ fontSize: ' 0.75rem' }}>EDIT VIEW</span>
                  </Button>

                </>
              }
              {/* <Link className="new-button-link ml-4"
                onClick={() => this.handleCreateViewModalShowHide(true, null)}>
                <span className="bi bi-plus-square-fill" >  Create View</span>
              </Link> */}
            </Col>
          </Row>
        </>
        {!isLoading &&
        <Row className={"submissiontable"}>
            {
              selectedView && selectedView.id ?
                <DatatableComponent data={customViewSubmissionList}
                  alwaysOpenSearch={true}
                  className="submissioncs"
                  columns={customViewColumns}
                  rowEvents={this.rowEvents}
                  filter={filterFactory()}
                  pagination="true"
                  id="submissionAdmin"
                  csvName="submissionList"
                  pageIcons={ this.renderPageIcons()}
                  handleClearAllFilter={this.handleClearAllFilter}
                  submissionSizePerPage ={localStorage.getItem("submissionSizePerPage") || 50} /> :
                (<>
                  {reloadTable === "Administrator" &&

                    <DatatableComponent data={submissionList}
                    pageIcons={ this.renderPageIcons()}
                      alwaysOpenSearch={true}
                      className="submissioncs"
                      columns={this.getcolumns1()}
                      filter={filterFactory()}
                      rowEvents={this.rowEvents}
                      pagination="true"
                      id="submissionAdmin"
                      csvName="submissionList"
                      handleClearAllFilter={this.handleClearAllFilter}
                      submissionSizePerPage ={localStorage.getItem("submissionSizePerPage") || 50} />
                  }
                  {reloadTable === "Management User" &&

                    <DatatableComponent data={submissionList}
                      alwaysOpenSearch={true}
                      className="submissioncs"
                      columns={this.getcolumns2()}
                      rowEvents={this.rowEvents}
                      pagination="true"
                      filter={filterFactory()}
                      id="submissionMU"
                      csvName="submissionList"
                      pageIcons={ this.renderPageIcons()}
                      handleClearAllFilter={this.handleClearAllFilter}
                      submissionSizePerPage ={localStorage.getItem("submissionSizePerPage") || 50} />
                  }
                  {reloadTable === "Global Coordinator - Oncology" &&

                    <DatatableComponent data={submissionList}
                      alwaysOpenSearch={true}
                      className="submissioncs"
                      columns={this.getcolumnsGCOncology()}
                      rowEvents={this.rowEvents}
                      pagination="true"
                      id="submissionGCO"
                      filter={filterFactory()}
                      csvName="submissionList"
                      pageIcons={ this.renderPageIcons()}
                      handleClearAllFilter={this.handleClearAllFilter}
                      submissionSizePerPage ={localStorage.getItem("submissionSizePerPage") || 50} />
                  }
                  {(reloadTable === "Global Coordinator - Diabetes" || reloadTable === "Global Coordinator - Biomeds") &&

                    <DatatableComponent data={submissionList}
                      alwaysOpenSearch={true}
                      className="submissioncs"
                      columns={this.getcolumnsGCDbio()}
                      rowEvents={this.rowEvents}
                      pagination="true"
                      filter={filterFactory()}
                      id="submissionGCBD"
                      csvName="submissionList"
                      pageIcons={ this.renderPageIcons()}
                      handleClearAllFilter={this.handleClearAllFilter}
                      submissionSizePerPage ={localStorage.getItem("submissionSizePerPage") || 50} />
                  }
                  {(reloadTable === "Read Only - Oncology" || reloadTable === "Read Only - Biomeds" || reloadTable === "Read Only - Diabetes") &&

                    <DatatableComponent data={submissionList}
                      alwaysOpenSearch={true}
                      className="submissioncs"
                      columns={this.getcolumnsRO()}
                      rowEvents={this.rowEvents}
                      pagination="true"
                      id="submissionRO"
                      filter={filterFactory()}
                      csvName="submissionList"
                      pageIcons={ this.renderPageIcons()}
                      handleClearAllFilter={this.handleClearAllFilter}
                      submissionSizePerPage ={localStorage.getItem("submissionSizePerPage") || 50} />
                  }

                  {reloadTable === "Operational Coordinator" &&

                    <DatatableComponent data={submissionList}
                      alwaysOpenSearch={true}
                      className="submissioncs"
                      columns={this.getcolumnsCoordinator()}
                      rowEvents={this.rowEvents}
                      pagination="true"
                      filter={filterFactory()}
                      id="submissionOC"
                      csvName="submissionList"
                      pageIcons={ this.renderPageIcons()}
                      handleClearAllFilter={this.handleClearAllFilter}
                      submissionSizePerPage ={localStorage.getItem("submissionSizePerPage") || 50} />
                  }
                  {reloadTable === "Industry Sponsored" &&

                    <DatatableComponent data={submissionList}
                      alwaysOpenSearch={true}
                      className="submissioncs"
                      columns={this.getcolumnsIS()}
                      rowEvents={this.rowEvents}
                      pagination="true"
                      id="submissionIS"
                      filter={filterFactory()}
                      csvName="submissionList"
                      pageIcons={ this.renderPageIcons()}
                      handleClearAllFilter={this.handleClearAllFilter}
                      submissionSizePerPage ={localStorage.getItem("submissionSizePerPage") || 50} />
                  }
                </>)
            }
          </Row>}
        {showHideCreateViewModal &&
          <CreateViewModal
            showHideCreateViewModal={showHideCreateViewModal}
            handleCreateViewModalShowHide={this.handleCreateViewModalShowHide}
            viewEditFlag={viewEditFlag}
            viewId={selectedView?.id}
            getViewList={this.getViewList}
          />}
          {this.state.showconceptForm&&<NewConceptEx showconceptForm={this.state.showconceptForm} handleConceptForm={this.handleConceptForm}/>}
      </div>
    )
  }
}

export default withRouter(Submission);