import React,{useState,useEffect} from 'react'
import DatatTableEx from '../../common/DataTableEx'
import { isCoordinator } from '../commonFunctions';
import UserContext from '../../../context/UserContext';
import API from '../../../api/apis';
import moment from 'moment';
import DTAButton from '../../common/Button/DTAButton';
import Button from '../../common/Button';
import NewPublicationEx from '../../NewPublicationEx2';
import NewPublicationEx2 from '../../NewPublicationEx2';
import { OverlayTrigger } from "react-bootstrap";
import swal from 'sweetalert';
import { GLOBAL_DATE_FORMAT, GLOBAL_DATE_FORMAT_NOTIME, iirDateFormatEx } from '../../../utility/dateHelper';

export default class PublicationList extends React.Component{
    static contextType = UserContext;
    constructor(props){
        super(props);
        this.state={
            source:[],
            showLoader:false,
            showPublicationForm:false
        }
    }
    columns = [
        {   
            dataField: 'id',
            text: 'id',
            sort: true,
            hidden:true,
            csvExport:false,
            excludeFilter:true
        },
        {   
            dataField: 'publication_id',
            text: 'Publication ID',
            sort: true,
        },
        {
            dataField: 'date_lilly_review_needed_by',
            text: 'Date Lilly Review Needed By',
            sort: true
        },
        {
            dataField: 'primary_author',
            text: 'Primary Author',
            sort: true,
        },
        {
            dataField: 'planned_date_of_submission',
            text: 'Planned Date of Submission',
            sort: true
        },
        // {
        //     dataField: 'status_health',
        //     text: 'Status Health',
        //     sort: true
        // },
        {
            dataField: 'supported',
            text: 'Supported',
            sort: true,
            csvExport:false
        },{
            dataField: '',
            text: 'Actions',
            sort: false,
            csvExport: false,
            excludeFilter: true,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
            formatter: (cell, row, rowIndex) => {
                let data=row;
                return <div className="d-flex" style={{userSelect:'none'}}>
                    {this.state.is_coordinator && <div>
                        <OverlayTrigger trigger="click" rootClose={true} rootCloseEvent={"click"}
                            placement="bottom" delay={{ show: 700, hide: 0 }}
                            overlay={<ul className="bg-white text-white dropdown-menu show">
                                <li onClick={e => {
                                    e.preventDefault(); e.stopPropagation();
                                    this.handlePublicationDelete(data.id, this.props.submissionId);
                                }}
                                    className="text-danger font-weight-bold dropdown-item">DELETE</li>
                            </ul>}><span className="material-icons">more_vert</span></OverlayTrigger>
                    </div>}
                </div>
            }
        }
    ];
     APIUrls={
        "LIST":'reviewsTab/publication/list/'
     }
    handlePublicationDelete = async (publicationId, submissionId) => {
        swal({
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res =  await API.post(`recycle/publication/delete/${publicationId}/${submissionId}`,{system_id : this.context.userSystemId});
                        swal({
                            text: "Deleted Successfully",
                            icon: "success",
                        });
                    }
                    catch (err) {
                        swal({
                            text: "Failed to delete!",
                            icon: "error",
                        });
                        console.error('Failed to delete Publication: ', err);
                    }
                    this.getPublicationList();
                }
            });
    }

     componentDidMount(){

        this.setState({ is_coordinator: isCoordinator(this.context) })
        this.getPublicationList();
     }
    
     getPublicationList=async()=>{
            let {submissionId}=this.props;
            let {source}=this.state;
            source=[];
            this.setState({showLoader:true});
            let response=await API.get(`${this.APIUrls.LIST}${submissionId}`);
            if(response&&response.data){
              
                let tem_data=response.data;
                source= tem_data.map((item,index)=>{
                    let { planned_date_of_submission,reviewers,venue, date_lilly_review_needed_by, format, target_journal_congress, created_at, status_health } = item;
                    let reviewersList=[];
                    reviewers.forEach(p=>{
                        if(p.reviewers_details){
                            reviewersList=[...reviewersList,...p.reviewers_details];
                        }
                       
                    })
                    planned_date_of_submission = planned_date_of_submission ? iirDateFormatEx(planned_date_of_submission, false) : "";
                    date_lilly_review_needed_by = date_lilly_review_needed_by ? iirDateFormatEx(date_lilly_review_needed_by, false) : "";
                    let formatArray = [format || '', venue || '', moment.utc(created_at, false).local().format(GLOBAL_DATE_FORMAT)];
                    let publication_id = formatArray.filter(p => p.length > 0).join(",");
                    let submittedList = reviewersList.filter(p => p.review_status === "SUBMITTED").length;
                    let supported = `${submittedList} / ${reviewersList.length}`;
                    status_health = status_health && status_health.toString().trim().length > 0 ? status_health : 'In Progress';
                    return {...item,supported,planned_date_of_submission,date_lilly_review_needed_by,publication_id,status_health};
                });

            }
            this.setState({source:[...source],showLoader:false});
     } 
    renderPageIcons=()=>{
        let is_coordinator=isCoordinator(this.context);       
       
        return is_coordinator&&<div className="d-flex align-items-center justify-content-center">
            <DTAButton  icon="ADD" onClick={e=>{
                this.setState({showPublicationForm:true});
            }} text={"ADD PUBLICATION"}></DTAButton>
        </div>
    }
    handleEvents=(e)=>{
        switch(e.option)
        {
            case 'ONCLOSE':this.setState({showPublicationForm:false,id:null}, this.getPublicationList);break;
            case 'SHOWPUBLICATION':this.setState({showPublicationForm:true,id:e.data.id});break;
        }
    }
    render(){
        let {source,showLoader,showPublicationForm,id}=this.state;
        let {submissionId}=this.props;
        return <><DatatTableEx
        id="publication_list"
        data={source}
        columns={this.columns}
        pagination="true"
        titleClass="title-lg"
        autoFilter={true}
        showLoader={showLoader}
        pageIcons={ this.renderPageIcons()}
        rowEvents={
           {
            onClick: (e, row, rowIndex) => {
                this.handleEvents({option:'SHOWPUBLICATION',data:{id:row["id"]}});                  
              }
           }
        }   
        csvName="publication_list"
        />
        {showPublicationForm&&<NewPublicationEx2 handleEvents={this.handleEvents} showHideModal={true} publication_id={id}  submission_id={submissionId}></NewPublicationEx2>}
        </>
    }
}
