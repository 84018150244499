import React,{ReactElement,
    useCallback,
    useMemo,
    useRef,
    useState,useImperativeHandle, useEffect} from 'react'
    import {EditorState,convertFromRaw,convertToRaw} from 'draft-js';
    import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
    defaultSuggestionsFilter,
  } from '@draft-js-plugins/mention';
  import '@draft-js-plugins/mention/lib/plugin.css';
  import '../../../../../node_modules/@draft-js-plugins/mention/lib/plugin.css'
  import styles from './index.module.css';
 

const EditorEx=React.forwardRef (({mentions,refresh},refv)=>{
      const ref = useRef(null);
      useImperativeHandle(refv, () => ({
        onExtractData: () => {
          const contentState = editorState.getCurrentContent();
          const raw = convertToRaw(contentState);
          return raw;
        },
        getMentions:()=>{
          const contentState = editorState.getCurrentContent();
            const raw = convertToRaw(contentState);
            let mentionedUsers = [];
            for (let key in raw.entityMap) {
              const ent = raw.entityMap[key];
              if (ent.type === "mention") {
                mentionedUsers.push(ent.data.mention.id);
              }
            }
        return mentionedUsers;
        }
      }));
     //const [mentions, setMentions] = useState(mentionSouce || []);

      const [editorState, setEditorState] = useState(() => {// EditorState.createEmpty()
        return EditorState.createEmpty()
      }
      );
      const [open, setOpen] = useState(false);
      const [suggestions, setSuggestions] = useState(mentions);
      const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
          mentions,
          mentionComponent: (props) => {
            let { mention } = props;
            return <span className='text-primary'>{mention.name}</span>
          }
        });
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
      },[]);
      
      const onOpenChange = useCallback((_open) => {
        setOpen(_open);
      }, []);
      const onSearchChange = useCallback((value) => {       
        if (value && value.length > 0)
          setSuggestions(defaultSuggestionsFilter(value, mentions));
      }, []);     
      useEffect(() => {
        if(refresh){         
          setEditorState(() => {
            return EditorState.createEmpty()
          });
        }       
      }, [refresh]);
    return (
        <div
        className={["border",styles.editor].join(" ")}
          onClick={()=>{
            if(ref.current){
             // ref.current.focus(); 
            }
          }}
        >
          <Editor
            editorKey={'editor'}
            editorState={editorState}
            onChange={setEditorState}
            plugins={plugins}
            stripPastedStyles={true}
            placeholder="Please type a message"
            ref={ref}
          />
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestions}
            onSearchChange={onSearchChange}
            onAddMention={() => {
              // get the mention object selected
            }}
          />
        </div>);
  });
  export default EditorEx;