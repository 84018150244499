import React, { Component } from 'react';

import { Navbar, Nav ,NavDropdown} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import UserContext from '../../../context/UserContext';
import SetRoles from './SetRoles';
import logo from '../../../static/images/lilly-logo.png';
import Notification from './notification';
import Search from './Search';

export default class Topnav extends Component {

    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        setRolesModalFlag: false,
        rolesList: []
    }
    componentDidMount() {
        let userInfo = this.context;
        const rolesListfilter = [];
        userInfo.rolesList.map(list => {
           
           const obj={label:list,
        value:list};

            rolesListfilter.push(obj);
        })
       

        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        window.location.pathname!=="/dashboard" ? localStorage.getItem("role") : Array.isArray(userInfo.userRolesList) ? localStorage.setItem("role",userInfo.userRolesList[0]):localStorage.setItem("role",userInfo.userRolesList)
        this.setState({
            rolesList: rolesListfilter,
            selectedRole: selectedRole,
           
        });
    }
    componentDidUpdate(previousProps, previousState) {
        //console.log(this.context.selectedRole);
        //console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }


    setRolesModalFunction = (flag, roleValue) => {
        if (flag === true || flag === null) {
            this.setState({
                setRolesModalFlag: flag,
                roleValue:roleValue
                
            });
        } else {
            this.setState({
                setRolesModalFlag: flag,

            }, this.props.submitRoleChange(roleValue));
        }
    }

    render() {
        let userInfo = this.context;
        const {rolesList, setRolesModalFlag,roleValue} = this.state;
        return (
            <>
            <Navbar className="top-nav" collapseOnSelect variant="dark" expand="md" fixed="top">
                <Navbar.Brand as={NavLink} to="/dashboard">
                    <img
                        src={logo}
                        width="60"
                        height="35"
                        className="d-inline-block align-top"
                        alt="logo"
                    />
                    <p className="IIR-logo" style={{color:"black"}}>IIR Portal</p>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="top-nav-collapse" />
                <Navbar.Collapse id="top-nav-collapse">
                    <Nav className="mr-auto">
                    {/* <Nav.Link as={NavLink} to="/dashboard"><i className="bi bi-collection" style={{ fontSize: "1.1rem", color: "white", margin: ".1rem .5rem", padding: ".1rem" }}></i>Dashboard</Nav.Link> */}
                        <Nav.Link as={NavLink} to="/submissions"><i className="bi bi-journal-medical" style={{ fontSize: "1.1rem", color: "black", margin: ".1rem .5rem", padding: ".1rem" }}></i>Submissions</Nav.Link>
                         <Nav.Link as={NavLink} id="li_nav_toreview" to="/toReview" className="d-flex align-items-center">{/*<i className="bi bi-view-list" style={{ fontSize: "1.1rem", color: "white", margin: ".1rem .5rem", padding: ".1rem" }}></i> */}
                        <span className="material-icons mr-1">verified_user</span>
                        To Review</Nav.Link>
                        <Nav.Link as={NavLink} to="/investigators"><i className="bi bi-people" style={{ fontSize: "1.1rem", color: "black", margin: ".1rem .5rem", padding: ".1rem" }}></i>Investigators</Nav.Link>
                        {this.state.selectedRole.includes("administrator")  && 
                        <Nav.Link as={NavLink} to="/delegee"><i className="bi bi-diagram-3" style={{ fontSize: "1.1rem", color: "black", margin: ".1rem .5rem", padding: ".1rem" }}></i>Delegee
                        </Nav.Link>}
                        <Nav.Link as={NavLink} to="/reports"><i className="bi bi-graph-up" style={{ fontSize: "1.1rem", color: "black", margin: ".1rem .5rem", padding: ".1rem" }}></i>Reports</Nav.Link>
                        {/* <Nav.Link as={NavLink} to="/dashboards"><i className="bi bi-graph-up" style={{ fontSize: "1.1rem", color: "white", margin: ".1rem .5rem", padding: ".1rem" }}></i>Dashboards</Nav.Link> */}
                        <Nav.Link as={NavLink} to="/country"><i className="bi bi-globe" style={{ fontSize: "1.1rem", color: "black", margin: ".1rem .5rem", padding: ".1rem" }}></i>Countries</Nav.Link>
                        {!this.state.selectedRole.includes("administrator") && <Nav.Link as={NavLink} to="/compound"><i className="bi bi-eyedropper" style={{ fontSize: "1.1rem", color: "black", margin: ".1rem .5rem", padding: ".1rem" }}></i>Compounds</Nav.Link>}
                        
                <NavDropdown className="dropdown_nav existing mr-4" title="More" id="nav-dropdown">
                    {this.state.selectedRole.includes("administrator")  && <NavDropdown.Item><Nav.Link as={NavLink} to="/compound">Compounds</Nav.Link></NavDropdown.Item>}
                <NavDropdown.Item>
                        <Nav.Link as={NavLink} to="/strength">Strength
                        </Nav.Link>
                </NavDropdown.Item>
                    <NavDropdown.Item>
                        <Nav.Link as={NavLink} to="/presentation">Presentation
                        </Nav.Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                        <Nav.Link as={NavLink} to="/count">Count
                        </Nav.Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                    <Nav.Link as={NavLink} to="/recyclebin">Recycle Bin</Nav.Link>
                    </NavDropdown.Item>
                  {/* {this.state.selectedRole.includes("administrator")  && <NavDropdown.Item>
                        <Nav.Link as={NavLink} to="/delegee">LLIR Delegee
                        </Nav.Link>
                    </NavDropdown.Item>} */}
                    {<NavDropdown.Item>
                        <Nav.Link as={NavLink} to="/roleaudit">UserRole Audit</Nav.Link>
                    </NavDropdown.Item>}
                   
                </NavDropdown>
               
                    </Nav>
                    <Nav>
                     <Search></Search>
                    <Notification/> 
                    {userInfo && <Nav.Link className="username" onClick={() => this.setRolesModalFunction(true, null)} > 
                           <div>
                            Welcome, {userInfo.userFirstName} 
                            <br/><small>{ window.location.pathname!=="/dashboard" ? (localStorage.getItem("role")|| userInfo.rolesList[0] ) : (window.location.pathname=="/dashboard" ? (localStorage.removeItem("role"),userInfo.rolesList[0]): localStorage.getItem("role") )} <i className="dropdown-toggle"  style={{ fontSize: "15px", color: "black", margin: ".1rem", padding: ".1rem" }}></i></small>
                           
                            </div>
                        </Nav.Link>}

                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {
                    setRolesModalFlag &&
                    <SetRoles setRolesModalFlag={setRolesModalFlag}
                        setRolesModalFunction={this.setRolesModalFunction}
                        rolesList={this.state.rolesList}
                        userRole={this.state.roleValue} />
                }
            </>
        )
    }
}
