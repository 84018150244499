import React, { Component } from 'react'
import { Button, Card, Col, Container, Form, Modal, Navbar, Row, Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import API from '../api/apis';
import UserContext from '../context/UserContext';
import { dateChange, dateStringformat, ddmmmyyyyformat,dateTimeformat, getUserInfo, uploadFile } from '../utility/helperFunctions';
import { yyyymmddFormat } from './common/dateFormat';
import FileComponent from './common/file-component/FileComponent';
import InputField from './common/input-fields/InputField';
import Loader from './common/loader/Loader';
import BootstrapModal from './common/modal/BootstrapModal';
import StickyNav from './common/sticky-nav/StickyNav';
import NewPublication from './NewPublication';
import HistoryFile from './submission/detailSubComponents/HistoryFile';
import ReviewFile from './submission/detailSubComponents/section-1/ReviewFile';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import { GLOBAL_DATE_FORMAT } from '../utility/dateHelper';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}&nbsp;
      &#x25bc;
    </a>
  ));
export default class PublicationDetailView extends Component {
    static contextType = UserContext
    state = {
        publicationData:null,
        activePublication:null,
        showLoader: false,
        showLoaderUpload: false,
        action: "",
        externalurl :  "http://localhost:1337",
        submissionId: "",
        submission_id: "",
        viewEditData: {},
        showHideModal: false,
        viewEditFlag: null,
        fileList: [],
        selectedFile: null,
        successFile: false,
        errorFile: false,
        showLoader: false,
        publicationDetails: {},
        auditData: [],
        trailData: [],
        showTable: false,
        planned_date_of_submission:null,
        date_of_publication:null
    }
    get inputFieldsArray() {
        return [
            {

                label: "Title *",
                type: "textarea",
                name: "title",
               
                disabled: true
            },
            {
                label: "Format",
                type: "dropdown",
                placeholder: "None",
                name: "format",
                disabled: true,
                options: ["None", "Manuscript for Journal", "Abstract", "Poster", "Presentation", "Publication", "Other"],
                
            },
            {
                label: "Primary Author",
                type: "text",
               
                name: "primary_author",
               
                disabled: true

            },
            {
                label: "Venue",
                type: "text",
                
                name: "venue",
               
                disabled: true

            },
            {
                label: "Review",
                type: "dropdown",
                placeholder: "Select Review",
                name: "review",
                options: ["Not started", "Under Review", "Completed"],
               
                disabled: true
            },
            {
                label: "Comments",
                type: "textarea",
               
                name: "comments",
                disabled: true

            },
            {
                label: "Link to Publication",
                type: "textarea",
                
                name: "link_to_publication",
                disabled: true

            },
            {
                label: "Date of Publication",
                type: "date",
               
                name: "date_of_publication",
                disabled: true

            },
            {
                label: "Planned Date of Submission",
                type: "date",
               
                name: "planned_date_of_submission",
                disabled: true

            },
            {
                label: "Submission",
                type: "text",
                
                name: "submissionId",
                disabled: true

            },
            {
                label: "Lilly Feedback",
                type: "textarea",
                name: "lilly_feedback",
                disabled: true
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
                disabled: true
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled: true
            },
        ];
    }
    async getExternalUrl() {
        let response ='';
        try {
             response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
        externalurl : (response && response.data) ? response.data.exturl : "http://localhost:1337",
    })
    }
  
     componentDidMount() {
        this.getExternalUrl();
       
        this.setState({
            showLoader : true
        });
        console.log(this.props.review_for);
        if(this.props.review_for){
            this.getPublication();//if reviewfor then only call
            console.log(this.props.review_for);
        }
       
    }
    
    getPublicationById = async () => {
        let {id}=this.state.activePublication;
        const res = await API.get(`publication/getbyid/${id}`);
        
        res.data["lilly_feedback"] = res.data.lilly_feedback !== null ? res.data.lilly_feedback.replace(/<[^>]+>/g, '') : "";
        res.data["date_of_publication"] = res.data.date_of_publication != "" && res.data.date_of_publication != null ?
            dateStringformat(res.data.date_of_publication) : null;
        res.data["planned_date_of_submission"] = res.data.planned_date_of_submission != "" && res.data.planned_date_of_submission != null ?
            dateStringformat(res.data.planned_date_of_submission) : null;
        res.data["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
        res.data["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt) : res.data.createdby;

        let publicationTitle = [res.data.format || '', res.data.venue || '', res.data.created_at?moment.utc(res.data.created_at, false).local().format(GLOBAL_DATE_FORMAT):''].filter(p => p.length > 0).join(", ");
        await this.getFilesList(res.data.submission_id,id);
        this.setState({
             publicationDetail:res.data,
            showLoader: false, publicationTitle
        })
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/publication`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
  
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state.publicationDetail[field.name]}
                        disabled={true}
                       
                    />
                </Col>
            )
        });
    }
    renderFiles=()=>{
        let downloadableDocuments=this.state.fileList||[];
        return <div className='d-flex '>
                <ul className='d-flex flex-wrap m-0 p-0'>
                {downloadableDocuments&&downloadableDocuments.map((item,index)=>{
                    let fileName=item.key.split("\/").pop();
                    return <li key={index} onClick={e=>this.handleDownload(fileName)} className='d-flex align-items-center p-2' style={{cursor:'pointer'}}>
                                <span>{fileName}</span>
                                <span className="material-icons ml-3">
                                    file_download
                                </span>
                    </li>
                })}
                </ul>
            </div>
    }
    getFilesList = async (submission_id,id) => {
        this.setState({
            showTable: false , 
            action : "fetching",
            showLoader : true
        })
        try {
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            this.setState({
                fileList: res.data?.filter(file => file.type === `Publication_${id}`),
                showTable: true,
                showLoader : false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                showLoader : false
            })
        }
    }
    


    handleDownload = async (fileName) => {
        try {
            this.setState({
                showLoader : true , 
                action : "download"
            })
          let {submission_id,id}=this.state.activePublication;
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Publication/${submission_id}/${id}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                        
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        showLoader : false,
                        successFile: true,
                        fileMessage:"File Downloaded Successfully!"
                    })
                });
        } catch (e) {
            this.setState({
                showLoader : false,
                errorFile: true
            })
            console.log(e);
        }
    }
   
    getPublication = async () => {
        try {
            let {submission_id, review_for}=this.props;
            const res = await API.get(`publication/submission/${submission_id}`)
            let activePublication=null;
            if(res.data&&res.data.length>0){
                // activePublication=res.data[0];
                activePublication=res.data.find(p=>p.id==review_for);
            }
            this.setState({ publicationData: res.data,activePublication },()=>{              
                 this.getPublicationById();
            });
          
        }
        catch (error) {
           
        }
    }
    render() {
        const { publicationData,activePublication,showLoader,publicationDetail, publicationTitle} = this.state;
        if(!publicationData||!activePublication )
        return <Loader></Loader>;
        let style={
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
          } 
        return (
            <>
               <div className='d-flex align-items-center p-2' style={{fontSize:'1rem'}}>
                    {/* <span className="mr-2 font-weight-bold">Publication Details for {activePublication.title+"-"+ddmmmyyyyformat(activePublication.createdAt)}</span>  */}
                    <span className="mr-2 font-weight-bold">Publication Details for {publicationTitle}</span>
                    {/* <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                          <span>{activePublication.title||''}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                publicationData&&publicationData.map((item,index)=>{
                                    return <Dropdown.Item onClick={(e)=>{
                                        this.setState({
                                            activePublication:item,
                                            publicationDetail:null,
                                            fileList:[]
                                        },()=>{
                                            this.getPublicationById();
                                        })
                                    }  } key={item.id} eventKey={index}>{item.title}</Dropdown.Item>;
                                })
                            }                          
                        </Dropdown.Menu>
                    </Dropdown> */}
               </div>           
                <Row>
                    <Col md={12}>
                        <Card className="pt-3" style={style}>
                            <Card.Body>
                                <Row>
                                {publicationDetail&&this.renderInputFields()} 
                                </Row>
                                <Row>
                                    <Col>
                                    {this.renderFiles()}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>                    
                </Row>
            </>
        )
    }
}
