import React from "react";
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const TextEditor = (props) => {  
  return (
    <div className="text-editor">
      <Editor 
        editorState={props.value}
        readOnly={props.isReadOnly}
        stripPastedStyles={true}
        onEditorStateChange={(e)=>{props.onChange(e, props.name)} }
      />
    </div>
  );
};

export default TextEditor;
