import React, { Component } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import UserContext from '../../../context/UserContext';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import InputField from '../../common/input-fields/InputField';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { DELEGEE_UPDATE } from '../../../mapping';
import { getUserInfo, getDateDifferenceMonth, dateTimeformat, dateTimeStringfo, dateTimeStringformatrmat, dateTimeStringformat, enableWFActions } from '../../../utility/helperFunctions';
import HistoryFile from './HistoryFile';
import { yyyymmddFormat } from '../../common/dateFormat';
import Loader from '../../common/loader/Loader';

export default class DelegeeDetail extends Component {
    static contextType = UserContext;
    // checkbox returns boolean value
    state={  
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },     
        dataAttest:{},
        owner_id:this.context.userSystemId,
        first_name:"",
        last_updated_by:"",
        last_name:"",
        email:"",
        accepted:false,
        last_modified_by_id:"",
        role_id:"",
        other_role:"",
        accept_privacy_statement:false,
        accept_privacy_statement_attested_date:"",
        no_confidential_information:false,
        no_confidential_info_attested_date:"",
        no_sensitive_personal_information:false,
        no_sensitive_personal_info_attested_date:"",
        working_on_sponsor_behalf:false,
        working_on_sponsor_behalf_attested_date:"",
        delegeeDetails:{},
        rolesList:[],
        patchObject: { id: this.props.match.params.delegeeId },
        cancelModal: false,
        auditData:[],
        trailData:[],
        usersList:[]
    }
    
get inputFieldsArray(){
    return [
        {
            type: "alert",
            label: "Information",
            variant: "secondary"
        },
        {
            label: "LLIR Delegee Name",
            type: "text", 
            name: "name",
            disabled: true
        },
        {
            label: "Owner",
            type: "text", 
            name: "owner_id",
            disabled : !this.state.access.edit,
        },
        {
            label: "[EXT] First Name",
            type: "text",
            placeholder: "Enter [EXT] First Name",
            name: "first_name",
            disabled : !this.state.access.edit,
        },
        {
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
        },
        {
            label: "[EXT] Last Name",
            type: "text",
            placeholder: "Enter [EXT] Last Name",
            name: "last_name",
            disabled : !this.state.access.edit,
        },
        {
            label: "[EXT] Email",
            type: "text",
            placeholder: "Enter [EXT] Email",
            name: "email",
            disabled : !this.state.access.edit,
        },
        {
            label: "Accepted",
            type: "checkbox",
            disabled : !this.state.access.edit,
            name: "accepted",
        },
        {
            label: "Submission",
            type: "text",
            placeholder: "Enter Submission",
            name: "submissionId",
            disabled : !this.state.access.edit,
        },
        {
            label: "[EXT] Roles",
            type: "searchableDropdown",
            placeholder: "Select [EXT] Roles",
            name: "role_id",
            options: this.state.rolesList,
            onSelect: this.onCountryChange,
            disabled : !this.state.access.edit,
        },
        {
            label: "[EXT] Other Role",
            type: "text",
            placeholder: "Enter [EXT] Other Role",
            name: "other_role",
            disabled : !this.state.access.edit,
        },
        {
            type: "alert",
            label: "Delegee Attestations",
            variant: "secondary",
            disabled : !this.state.access.edit,
        },
        {
            label: "Accept Privacy Statement",
            type: "checkbox",
            placeholder: "Enter Accept Privacy Statement",
            name: "accept_privacy_statement",
            
        },
       
        {
            label: " Accept Privacy Statement Attested Date",
            type: "datetime-local",
            
            name: "accept_privacy_statement_attested_date",
            
        },
        {
            label: "No confidential information",
            type: "checkbox",
            placeholder: "Enter No confidential information",
            name: "no_confidential_information",
             
        },
       
        {
            label: " No confidential info. Attested Date",
            type: "datetime-local",
           
            name: "no_confidential_info_attested_date",
            
        },
        {
            label: "No sensitive personal information",
            type: "checkbox",
            placeholder: "Enter No sensitive personal information",
            name: "no_sensitive_personal_information",
            
        },
        
        {
            label: "No sensitive personal info Attested Date",
            type: "datetime-local",
           
            name: "no_sensitive_personal_info_attested_date",
            
        },
        {
            label: "Working on Sponsor Behalf",
            type: "checkbox",
            placeholder: "Enter Working on Sponsor Behalf",
            name: "working_on_sponsor_behalf",
            
        },
        {
            label: "Working on Sponsor Behalf Attested Date",
            type: "datetime-local",
          
            name: "working_on_sponsor_behalf_attested_date",
            
        },
        {
            label: "Created By",
            type: "text",
            className:"createUpdateByfontsize",
            name: "created_by_id",
            disabled:true
        },
        {
            label: "Last Modified By",
            type: "text",
            className:"createUpdateByfontsize",
            name: "last_modified_by_id",
            disabled: true
        },
    ];
}
async componentDidMount() {
    
    let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
    this.setState({
        selectedRole: selectedRole,
        access: {
            view: true,
            create: selectedRole.includes("administrator"),
            edit:  selectedRole.includes("administrator"),
            delete: selectedRole.includes("administrator"),
        },
       
    });
    await this.getDelegeeDetails(this.props.match.params.delegeeId)   
    await this.getAuditList(this.props.match.params.delegeeId)
}
componentDidUpdate(previousProps, previousState) {
    console.log(this.context.selectedRole);
    console.log(previousState.selectedRole);
    let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
    if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator"),
                edit:  selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
            
        }, () => { console.log("userRole", this.state.selectedRole) })
    }
}


// getDelegeeDetails = async (delegeeId) => {
//     if(delegeeId){
//         const res = await API.get(`delegee/${delegeeId}`);
//         this.setState({
//             delegeeDetails: res.data
//         });
//         getattestdata(res.data.submission_id)
//     }
    
// }
getDelegeeDetails = async (delegeeId) => {
    if(delegeeId){
        const res = await API.get(`delegee/${delegeeId}`);
        this.setState({
            delegeeDetails: res.data.data
        }, () => {this.setProposalFields()}
        );
        this.getRoleslist()
    }
}
getRoleslist = async () =>{
    const res = await API.get("seed/roles/fetch");
    const resultArray = (res.data?.data || []).map(elm => ({ name: elm.name, value: elm.role_id}));
    console.log("result",resultArray)
    this.setState({
        rolesList: resultArray,
        role_id: resultArray.find(cnt => ""+cnt.value === this.state.delegeeDetails.role_id) || null,   
    });     
}
onCountryChange = (e, name) => {
    this.setState({
        [name]: e,
        patchObject:  {...this.state.patchObject, [name]: e.value},
        hasChanged: true
    });
    
}
setProposalFields = async () => 
{ this.setState({
    name:this.state.delegeeDetails.name,
    first_name:this.state.delegeeDetails.first_name,
    last_updated_by:this.state.delegeeDetails.last_updated_by,
    last_modified_by_id:this.state.delegeeDetails.last_modified_by_id?.length>0 ? await getUserInfo(this.state.delegeeDetails.last_modified_by_id) +"  "+ dateTimeformat(this.state.delegeeDetails.last_modified_date) : "",
    created_by_id:this.state.delegeeDetails.created_by_id?.length>0 ? await getUserInfo(this.state.delegeeDetails.created_by_id) +"  "+ dateTimeformat(this.state.delegeeDetails.createdAt) : "",
    last_name:this.state.delegeeDetails.last_name,
    email:this.state.delegeeDetails.email,
    accepted:this.state.delegeeDetails.accepted || false ,
    submissionId:"Submission-" +this.state.delegeeDetails.submissionId,
    role_id:this.state.delegeeDetails.role_id || "",
    other_role:this.state.delegeeDetails.other_role,
    accept_privacy_statement:this.state.delegeeDetails.accept_privacy_statement,
    accept_privacy_statement_attested_date:this.state.delegeeDetails.accept_privacy_statement_attested_date?.length>0  ? dateTimeStringformat(this.state.delegeeDetails.accept_privacy_statement_attested_date).substring(0,16) :"",
    no_confidential_information:this.state.delegeeDetails.no_confidential_information,
    no_confidential_info_attested_date:this.state.delegeeDetails.no_confidential_info_attested_date?.length>0  ? dateTimeStringformat(this.state.delegeeDetails.no_confidential_info_attested_date).substring(0,16) :"",
    no_sensitive_personal_information:this.state.delegeeDetails.no_sensitive_personal_information,
    no_sensitive_personal_info_attested_date:this.state.delegeeDetails.no_sensitive_personal_info_attested_date?.length>0  ? dateTimeStringformat(this.state.delegeeDetails.no_sensitive_personal_info_attested_date).substring(0,16) :"",
    working_on_sponsor_behalf:this.state.delegeeDetails.working_on_sponsor_behalf,
    working_on_sponsor_behalf_attested_date:this.state.delegeeDetails.working_on_sponsor_behalf_attested_date?.length>0  ? dateTimeStringformat(this.state.delegeeDetails.working_on_sponsor_behalf_attested_date).substring(0,16) :"",
    owner_id:await getUserInfo(this.state.delegeeDetails.owner_id),
})

}


    // getStateList = async (country) => {
    //     const res = await API.get(`seed/states/data/${country}`);
    //     const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
    //     this.setState({ 
    //         stateList: resultArray,
    //         stateRepresentativeState: resultArray.find(state => state.value+"" === this.state.stateRepresentativeState) || ""
    //     });
   // }
   

  
    onInputChange = (e,eo) => {
        if (eo.type === "datetime-local") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
       else 
        {this.setState({
            [e.target.name]: e.target.value,
            patchObject:  {...this.state.patchObject, [DELEGEE_UPDATE[e.target.name]]: e.target.value},
            hasChanged: true
        });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [DELEGEE_UPDATE[eo.name || eo.target.name]]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            hasChanged: true
        });        
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [DELEGEE_UPDATE[name]]: e.value},
            hasChanged: true
        }); 
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject:  {...this.state.patchObject, [DELEGEE_UPDATE[e.target.name]]: e.target.checked},
            hasChanged: true
        });
    }
    getHtmlFromEditorValue = (val) => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }

    getEditorValueFromHtml = (html) => {
        const blocksFromHTML = convertFromHTML(html);        
        const state = ContentState.createFromBlockArray(blocksFromHTML);
        return EditorState.createWithContent(state);
    }
    changeShowState = () => {
        this.setState({
            cancelModal:false
        })
        this.getAuditList(this.props.match.params.delegeeId)
            this.props.history.push(`/submissions/${this.state.delegeeDetails.submissionId}`);
      
       
      
           
    }
    cancel =()=>{
        this.setState({
            cancelModal:true
          });  

    }
   


    onEditorChange = (val, e) => {
        this.setState({[e]: val},
          );
        
    };

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    key={field.label}
                    error={this.state.error?.[field.name]}
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e) => { this.onInputChange(e, field) }) : this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)
       
        this.setState({usersList: res.data});
    }
    getAuditList = async (id) => {
        try {
            const temp = [];
            const res = await API.get(`audit/delegee/${id}`)
            console.log(res)
            await this.getTrailList();
            await this.getUserList();

            this.state.trailData.forEach(x => {
                res.data.forEach(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        let fieldLabels = ["updated_by"]
                        if (fieldLabels.includes(y.field)) {
                            let newValueText =y.new_value!=null ? this.state.usersList.filter(x => x.system_id === (y.new_value))[0] : "";
                            y.new_value = newValueText!="" ? newValueText?.first_name + " " + newValueText?.last_name : "";
                            let oldValueText =y.old_value!=null ? this.state.usersList.filter(x => x.system_id === (y.old_value))[0] : "";
                            y.old_value =oldValueText!="" ? oldValueText?.first_name + " " + oldValueText?.last_name : "";
                            temp.push(y);
                        }
                        else if(y.field==="created_at"){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):"";
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                             temp.push(y);
                         }
                        else {
                            y.new_value = (y.new_value);
                            y.old_value = (y.old_value)
                            temp.push(y);
                        }
                    }

                })
            })
            this.setState({
                auditData: temp,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/delegee`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    formValidation = () => {
        let is_valid = true;
        // if(this.state['proposalTitle'].trim() === "") {
        //     is_valid = false;
        //     this.setState({
        //         error: {
        //             proposalTitle: true
        //         }
        //     })
        // }
        return is_valid;
    }

    submitForm = async () => {
       if(this.formValidation()){
        const newObject={};
            const obj = { ...this.state.patchObject };
            console.log("systemName",this.context.userSystemId)
        obj['last_modified_by_id'] = this.context.userSystemId;
        Object.keys(obj).forEach((key) => {
            if (obj[key] === "" || obj[key] === null ) {
                return false;
            } 
            else{
                if(key.includes("date")){
                    newObject[key]= new Date(this.state[key]).toISOString()
                }
                else
                newObject[key] = obj[key];
            }
        });
       let delegeeId=this.props.match.params.delegeeId
        try {
            const res = await API.post(`delegee/update`,newObject);
            this.setState({successModal: true});
        }catch(err){
            this.setState({errorModal: true});
      }
     }
     }
     onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.delegeeDetails.submissionId}`)
    }
    render() {
        // console.log(this.props.match.params.submissionId)
        if (!this.state.submissionId)
        return <Loader />
        return (
            <Container className="form-page">
                <div onClick={this.onNavOut} className="back-button">
                    <i className="bi bi-arrow-left">
                    {" "}{this.state.submissionId}
                            </i>
                </div>
                <h2 className="text-center">Delegee Detail</h2>
                <Row>
                        {this.renderInputFields()}
                        {/* <TextEditor /> */}
                    {this.state.access.edit &&enableWFActions()&&
                        <div className="w-100 d-flex justify-content-center">
                            <Button className="ml-1" variant="primary" type="submit" onClick={this.cancel}>
                            Cancel
                        </Button>
                        <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm}>
                            Save
                        </Button>
                        </div>}
                </Row>
                <Row className="mt-4">
                <Col sm={12}>
                        <Card body className="p-2">
                            <h3 className="mb-5 text-center">History</h3>
                           
                           <HistoryFile historyData={this.state.auditData}  historyTitle="Delegee History"/>
                            {/* <DatatableComponent data={this.state.auditData} columns={this.columns} rowEvents={this.rowEvents} pagination="true" />   */}
                        </Card>                     
                    </Col>
                </Row>
                {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Delegee has been updated!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.cancelModal &&
                    <BootstrapModal 
                        show={this.state.cancelModal} 
                        header="Cancel"
                        body="Cancel Data"
                       changeShowState={this.changeShowState}
                    />
                }
            </Container>
        )
    }
}
