import React from "react";
import Tab from "../ToReviewDetails/Tab";
import EnrollmentExpenses from "./EnrollmentExpenses";
import MilestoneExpenses from "./MilestoneExpenses";
import Overview from "./Overview";
import History from "./History";

export default class extends React.Component{
    constructor(props){
        super(props);
        this.state={
            tabSource:[
                {key:'Overview',text:'Overview',isActive:true},
                {key:'MilestoneExpenses',text:'Milestone Expenses',isActive:false},
                {key:'EnrollmentExpenses',text:'Enrollment Expenses',isActive:false},
                {key:'History',text:'History',isActive:false},
            ]
        }
    }
   
    handleEvents=(event,data)=>{
        switch(event){
            case 'TAB-CHANGE':this.handleTabChange(data);break;
        }
    }
    handleTabChange=(data,callback)=>{
       
        let { tabSource } = this.state;
        let activeTab = tabSource.filter(p => p.isActive);
        if (activeTab.length > 0) {
            activeTab[0].isActive = false;
        }
        let current = tabSource.find(p => p.key == data.key);
        current.isActive = true;
        this.setState({ tabSource:[...tabSource] }, () => {
            if (callback) {
                callback();
            }
        });
    }
    
    renderTabComponent=()=>{
        let {tabSource}=this.state;
        let {submissionId}=this.props;
        let activeTab=tabSource.find(p=>p.isActive)||{};        
        switch(activeTab.key){
            case "Overview":return <Overview submissionId={submissionId}/>;break;
            case "MilestoneExpenses":return <MilestoneExpenses submissionId={submissionId} ></MilestoneExpenses >;break;
            case "EnrollmentExpenses":return <EnrollmentExpenses submissionId={submissionId}/>;break;
            case "History":return <History submissionId={submissionId}/>;break;
            default :return  <div className="p-5 font-weight-bold d-flex align-items-center justify-content-center"><span>Loading...</span></div>;break;
        }
    }
    render(){
        let {tabSource}=this.state;
        return <div className="mt-3">
        <Tab source={tabSource} onChange={this.handleEvents}></Tab>
            <div>
                {this.renderTabComponent()}  
            </div>
        </div>
    }
}