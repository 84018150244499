import React,{useState,useEffect,useRef} from 'react'
import { Chart } from "react-google-charts";
import {Form,Alert} from 'react-bootstrap'
import './Charts.css'

  
  
  export default ({source,title,width,height,chartType,defaultRange,slider})=> {
    const chartObj=useRef(null);
    const [rangeValue,setRangeValue]=useState(defaultRange||1)
    const [chartData,setChartData]=useState(source||[]);
    const [chartOptions,setChartOptions]=useState({
      title: title??"",
      curveType: "function",
      colors: ['#a6cee3', '#1f78b4', '#b2df8a'],
      legend: { position: "bottom" },
      focusTarget: 'category',
      tooltip: {
        trigger: "both"
      },
      pointSize: 5,
    });
    useEffect(()=>{
          return ()=>{
            chartObj.current=null;
          }
    },[])
    if(!source||source.length<=0){
      return <Alert  variant="warning">
     No data!
    </Alert>
    }
    return (
      <><Chart
      chartEvents={
        [
          {
            eventName: "ready",
            callback: ({ chartWrapper, google }) => {
              const chart = chartWrapper.getChart();
              chartObj.current=chart;
              if(rangeValue){
                chart.setSelection([
                  {
                    row: parseInt(rangeValue)-1,
                    column: null
                  }])
              }
            }
          }
        ]
      }
        chartType={chartType||"LineChart"}
        width={width||"100%"}
        height={height||'auto'}
        data={chartData}
        options={chartOptions}        
      />
     {slider&&<div className='d-flex align-items-center justify-content-center mt-3'>
        <div className='w-50'>
        <Form>
          <Form.Group controlId="formBasicRangeCustom">
            {/* <Form.Label>Range</Form.Label> #009688*/}
            <Form.Control type="range" onChange={e=>{
                  if (chartObj.current) {
                    chartObj.current.setSelection([
                      {
                        row: parseInt(e.target.value) - 1,
                        column: null
                      }])
                  }
                  setRangeValue(e.target.value);        
            }} custom size='10' step={1} min={1} max={chartData.length-1} value={rangeValue}/>
          </Form.Group>
        </Form>
        </div>
      </div>}
       
      </>
    );
  }