import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Accordion, Button, Card, Col, Container, Form, Row,Modal } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import {isCoordinator, showMessage ,userPermissions } from '../../ToReviewDetails/commonFunctions';
import API from '../../../api/apis';
import DiagnosticDetailsEx from './DiagnosticDetailsEx';
import TranslationalDetailsEx from './TranslationalDetailsEx';
import NewProposalEx from '../../NewProposalEx'
import BootstrapModal from '../../common/modal/BootstrapModal';
import { CLINICAL_TRIAL_PHASE, DOSING_DSCHEDULE } from '../../../consts';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import FileUploader from '../../ToReviewDetails/FileUploader'
// import Loader from '../../common/loader/Loader';
import { PROPOSAL_UPDATE } from '../../../mapping';
import UserContext from '../../../context/UserContext';
import { dateTimeformat,dateStringformat, getDateDifferenceMonth, getUserInfo, uploadFile, dateChange,getPrintViewTextBox } from '../../../utility/helperFunctions';
import History from './section-2/History';
import FileComponentEx from '../../common/file-component/FileComponentEx';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import swal from 'sweetalert';
import DatatTableEx from '../../common/DataTableEx';
import DatatableComponent from '../../common/datatable/DatatableComponent';
import HistoryFile from './HistoryFile';
import ReviewFile from './section-1/ReviewFile';
//import '../../common/pdf.css'
import axios from 'axios';
import '../../ToReviewDetails/InitiateReview/custom-css.css'
import DTAButton from '../../common/Button/DTAButton';
import * as IIRBtn from '../../common/Button/index';
import LoaderEx from '../../common/loader/LoaderEx';
import SwitchHistory from '../../common/SwitchHistory';
import { OBJ_TYPES } from '../../common/SwitchHistory/historyConsts';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div     
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  ));
export default class ProposalDetailsEx extends Component {

    static contextType = UserContext;
titleRef = React.createRef();
    state = {
        externalurl :  "http://localhost:1337",
        fileInfo:{
            selectedFileName:'',
            customName:'',
            file:null
        },
        showLoader : false,
        showLoaderUpload : false ,
        historyRefresh:true,
        action : "",
        loadTable : true ,
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
       
        proposalSubmitted: "",
        createdBy: "",
        lastModifiedBy: "",
        submissionId: "",
        submission_id: "",
        investigatorName: "",
        last_updated_by: "",
        proposalTitle: "",
        shippingImportLicenseRequired: "",
        iNDApplication: "",
        uSDepartmentOfVetAffairs: "",
        scientificRationaleForStudy: "",
        studyType: "",
        primaryAndSecondaryObjectives: "",
        ifOtherStudyType: "",
        lengthOfWashout: "",
        studyToUseAnimals: "",
        iACUCDocumentationAttached: "",
        descriptionOfAnimalUsed: "",
        dosesConcentrations: "",
        aCAndUCommitteeApproval: "",
        endpoints: "",
        modelMethodology: "",
        targetStartDate: "",
        statisticalAnalysis: "",
        reportSubmissionDate: "",
        humanSamplesInvolved: "",
        publicationDateAnticipated: "",
        typeOfHumanSamples: "",
        projectedMilestoneTiming: "",
        uniquePersonalClinicalSamples: "",
        biobankOwner: "",
        sampleSize: "",
        dateOfFirstPatientVisit: "",
        numberOfTherapyGroups: "",
        dateFirstPtEntersTreatment: "",
        anticipatedQuarterlyEnrollment: "",
        dateLastPatientEntersTrial: "",
        numberOfInvestigativeSite: "",
        dateLastPatientEntersTreatment: "",
        potentialCountries: "",
        dateOfLastPatientVisit: "",
        multiCountryTrial: "",
        dateOfReportSubmission: "",
        clinicalTrialPhase: [],
        dateOfPublicationSubmission: "",
        includePreClinicalPortion: "",
        leadInPeriod: "",
        assignmentToTreatment: "",
        blinding: "",
        design: "",
        blindingDoneBy: "",
        controls: "",
        patientType: "",
        extension: "",
        patientGender: "",
        extensionLength: "",
        patientTreatmentPeriod: "",
        ageRangeStart: "",
        studyOnOffLabel: "",
        ageRangeEnd: "",
        lineOfTherapy: "",
        lillyToSupplyStudyCompound: "",
        ifNoWhy: "",
        lillyStudyCompound1: "",
        requireFifthLillyCompound: "",
        lillyStudyCompound1Other: "",
        lillyStudyCompound5: "",
        compound1AvailableMaterial: "",
        lillyStudyCompound5Other: "",
        compound1AvailableMaterialOther: "",
        compound5AvailableMaterial: "",
        compound1Formulation: "",
        compound5AvailableMaterialOther: "",
        compound1DosingSchedule: "",
        compound5Formulation: "",
        compound1DosingScheduleOther: "",
        compound5DosingSchedule: "",
        compound1AdministeredDose: "",
        compound5DosingScheduleOther: "",
        compound1AmountQuantity: "",
        compound5AdministeredDose: "",
        requestedAmtOfComp1PreClin: "",
        compound5AmountQuantity: "",
        requireSecondLillyCompound: "",
        requestedAmtOfComp5PreClin: "",
        lillyStudyCompound2: "",
        requireSixthLillyCompound: "",
        lillyStudyCompound2Other: "",
        lillyStudyCompound6: "",
        compound2AvailableMaterial: "",
        lillyStudyCompound6Other: "",
        compound2AvailableMaterialOther: "",
        compound6AvailableMaterial: "",
        compound2Formulation: "",
        compound6AvailableMaterialOther: "",
        compound2DosingSchedule: "",
        compound6Formulation: "",
        compound2DosingScheduleOther: "",
        compound6DosingSchedule: "",
        compound2AdministeredDose: "",
        compound6DosingScheduleOther: "",
        compound2AmountQuantity: "",
        compound6AdministeredDose: "",
        requestedAmtOfComp2PreClin: "",
        compound6AmountQuantity: "",
        requireThirdLillyCompound: "",
        requestedAmtOfComp6PreClin: "",
        lillyStudyCompound3: "",
        requireSeventhLillyCompound: "",
        lillyStudyCompound3Other: "",
        lillyStudyCompound7: "",
        compound3AvailableMaterial: "",
        lillyStudyCompound7Other: "",
        compound3AvailableMaterialOther: "",
        compound7AvailableMaterial: "",
        compound3Formulation: "",
        compound7Formulation: "",
        compound3DosingSchedule: "",
        compound7AvailableMaterialOther: "",
        compound3DosingScheduleOther: "",
        compound7DosingSchedule: "",
        compound3AdministeredDose: "",
        compound7DosingScheduleOther: "",
        compound3AmountQuantity: "",
        compound7AdministeredDose: "",
        requestedAmtOfComp3PreClin: "",
        compound7AmountQuantity: "",
        requireFourthLillyCompound: "",
        requestedAmtOfComp7PreClin: "",
        lillyStudyCompound4: "",
        lillyStudyCompound4Other: "",
        compound4AvailableMaterial: "",
        compound4AvailableMaterialOther: "",
        compound4Formulation: "",
        compound4DosingSchedule: "",
        compound4DosingScheduleOther: "",
        compound4AdministeredDose: "",
        compound4AmountQuantity: "",
        requestedAmtOfComp4PreClin: "",
        requestingFunding: "",
        nonLillySupport: "",
        amountOfFunds: "",
        nameOfCompany: "",
        currency: "",
        typeOfSupport: "",
        references: "",
        hCPProposalComments: "",
        totalStudyLength: "",
        enrollmentPeriod: "",
        feedback: "",
        successModal: false,
        errorModal: false,
        successFile: false,
        errorFile: false,
        compoundList: [],
        proposalDetails: {},
        hasChanged: false,
        lillyStudyCompound1MaterialList: [],
        lillyStudyCompound2MaterialList: [],
        lillyStudyCompound3MaterialList: [],
        lillyStudyCompound4MaterialList: [],
        lillyStudyCompound5MaterialList: [],
        lillyStudyCompound6MaterialList: [],
        lillyStudyCompound7MaterialList: [],
        currencyList: [],
        
        fileList: [],
        //patchObject: { "id": this.state. proposalDetails.id},
       patchObject:{},
        selectedFile: null,
        auditData: [],
        trailData: [],
        usersList: [],
        templateFlag:false,
        radio:0,
    }
   
    get inputFieldsArrayInformation() {
        return[
        {
            label: "Proposal Submitted",
            type: "checkbox",
            name: "proposalSubmitted"
        },
        {
            label: "Created By",
            type: "text",
            name: "createdBy",
            className:"createUpdateByfontsize",
            disabled: true
        },
        {
            label: "Last Modified By",
            type: "text",
            name: "lastModifiedBy",
            className:"createUpdateByfontsize",
            disabled: true
        },
        {
            label: "Submission",
            type: "text",
            placeholder: "Select Submission",
            name: "submissionId",
            disabled: true
        },
        {
            label: "Investigator Name",
            type: "text",
            name: "investigatorName",
            disabled: true
        },
        
        ]
    }
get inputFieldsArrayProposalIIR() {
    return [
       
        {
            label: "[EXT] Proposal Title",
            type: "textarea",
            name: "proposalTitle"
        },
        {
            label: "[EXT] Shipping Import License Required?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "shippingImportLicenseRequired",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        {
            label: "[EXT] IND Application",
            type: "dropdown",
            placeholder: "Select any option",
            name: "iNDApplication",
            options: [
                "None",
                "Yes",
                "No",
                "Not Conducted in US"
            ],
            helptext: "Is this study to be conducted under a US Investigational New Drug Application?"
        },
        {
            label: "[EXT] US Department of Vet. Affairs",
            type: "dropdown",
            placeholder: "Select any option",
            name: "uSDepartmentOfVetAffairs",
            options: [
                "None",
                "Yes",
                "No",
                "N/A"
            ]
        },
    ]}
get inputFieldsArrayProposal_Study() {
    return [
    {
        label: "[EXT] Scientific Rationale for Study",
        type: "text-editor",
        name: "scientificRationaleForStudy",    
        onChange: this.onEditorChange,
        helptext: "Study rationale and how it relates to Lilly product."
    },
    {
        label: "[EXT] Study Type",
        type: "dropdown",
        placeholder: "Select any option",
        name: "studyType",
        options: [
            "None",
            "Clinical Efficacy/Safety",
            "Clinical Pharmacology",
            "Retrospective Analysis",
            "Prospective Observational Study",
            "In Vitro",
            "In Vivo",
            "Ex Vivo",
            "Both In Vitro & In Vivo",
            "Other"
        ],
        helptext: "Clinical Efficacy/Safety, Clinical Pharmacology, Retrospective Analysis, Prospective Observational Study"
    },
    {
        label: "[EXT] Primary and Secondary Objectives",
        type: "textarea",
        name: "primaryAndSecondaryObjectives",

        helptext: "List the main study objective that the study will focus on, any secondary objectives should also be provided."
    },
    {
        label: "[EXT] If Other Study Type",
        type: "text",
        placeholder: "Enter Other Study Type",
        name: "ifOtherStudyType",
        helptext: "Please explain"
    },
    {
        label: "[EXT] Length of Washout (if Applicable)",
        type: "text",
        placeholder: "Enter Length of Washout",
        name: "lengthOfWashout",
    },]
}    
get inputFieldsArrayNCRI() { 
    return[
{
    label: "[EXT] Study to use animals",
    type: "dropdown",
    placeholder: "Select any Option",
    name: "studyToUseAnimals",
    options: [
        "None",
        "Yes",
        "No"
    ]
},
{
    label: "[EXT] IACUC Documentation attached?",
    type: "dropdown",
    placeholder: "Select any Option",
    name: "iACUCDocumentationAttached",
    options: [
        "None",
        "Yes",
        "No"
    ]
},
{
    label: "[EXT] Description of Animal Used",
    type: "textarea",
    name: "descriptionOfAnimalUsed"
},
{
    label: "[EXT] Doses/Concentrations",
    type: "textarea",
    name: "dosesConcentrations"
},
{
    label: "[EXT] AC&U Committee approval?",
    type: "dropdown",
    placeholder: "Select any Option",
    name: "aCAndUCommitteeApproval",
    options: [
        "None",
        "Yes",
        "No"
    ]
},
{
    label: "[EXT] Endpoints",
    type: "text",
    placeholder: "Enter Endpoints",
    name: "endpoints"
},
{
    label: "[EXT] Model/Methodology",
    type: "textarea",
    name: "modelMethodology"
},
{
    label: "[EXT] Target Start Date",
    type: "date",
    name: "targetStartDate"
},
{
    label: "[EXT] Statistical Analysis",
    type: "textarea",
    name: "statisticalAnalysis"
},
{
    label: "[EXT] Report Submission Date",
    type: "date",
    name: "reportSubmissionDate"
},
{
    label: "[EXT] Human samples involved",
    type: "dropdown",
    placeholder: "Select any Option",
    name: "humanSamplesInvolved",
    options: [
        "None",
        "Yes",
        "No"
    ]
},
{
    label: "[EXT] Publication Date Anticipated",
    type: "date",
    name: "publicationDateAnticipated"
},
{
    label: "[EXT] Type of Human Samples",
    type: "dropdown",
    placeholder: "Select any Option",
    name: "typeOfHumanSamples",
    options: [
        "None",
        "Cells From Biobank",
        "Study Specific"
    ]
},
{
    label: "[EXT] Projected Milestone Timing",
    type: "text",
    placeholder: "Enter Projected Milestone Timing",
    name: "projectedMilestoneTiming"
},
{
    label: "[EXT] Unique, Personal, Clinical samples",
    type: "dropdown",
    placeholder: "Select any Option",
    name: "uniquePersonalClinicalSamples",
    options: [
        "None",
        "Yes",
        "No"
    ]
},
{
    label: "[EXT] Biobank owner",
    type: "dropdown",
    placeholder: "Select any Option",
    name: "biobankOwner",
    options: [
        "None",
        "Institution",
        "Third Party"
    ]
},]}
get inputFieldsArrayClinical() {
    return [ 
    {
        label: "[EXT] Sample Size",
        type: "text",
        placeholder: "Enter Sample Size",
        name: "sampleSize",
        helptext: "Screen failures are not included in enrollment estimations"
    },
    {
        label: "[EXT] Date of First Patient Visit",
        type: "date",
        name: "dateOfFirstPatientVisit",
        helptext: "Anticipated date"
    },
    {
        label: "[EXT] Number of Therapy Groups",
        type: "text",
        placeholder: "Enter Number of Therapy Groups",
        name: "numberOfTherapyGroups"
    },
    {
        label: "[EXT] Date First Pt Enters Treatment",
        type: "date",
        name: "dateFirstPtEntersTreatment",
        helptext: "Anticipated date of the first patient entering treatment"
    },
    {
        label: "[EXT] Anticipated quarterly enrollment",
        type: "text",
        placeholder: "Enter Anticipated quarterly enrollment",
        name: "anticipatedQuarterlyEnrollment",
        helptext: "Screen failures are not included in enrollment estimations."
    },
    {
        label: "[EXT] Date Last Patient Enters Trial",
        type: "date",
        name: "dateLastPatientEntersTrial",
        helptext: "Anticipated date the last patient enters the trial"
    },
    {
        label: "[EXT] Number of Investigative Sites",
        type: "text",
        placeholder: "Enter Number of Investigative Sites",
        name: "numberOfInvestigativeSite"
    },
    {
        label: "[EXT] Date Last Patient Enters Treatment",
        type: "date",
        name: "dateLastPatientEntersTreatment",
        helptext: "Anticipated date the last patient enters the treatment"
    },
    {
        label: "[EXT] Potential Countries",
        type: "textarea",
        name: "potentialCountries"
    },
    {
        label: "[EXT] Date of Last Patient Visit",
        type: "date",
        name: "dateOfLastPatientVisit",
        helptext: "Anticipated date of the last patient visit"
    },
    {
        label: "Multi-Country Trial",
        type: "checkbox",
        name: "multiCountryTrial"
    },
    {
        label: "[EXT] Date of Report Submission",
        type: "date",
        name: "dateOfReportSubmission",
        helptext: "Anticipated date the final report will be submitted to Lilly"
    },
    {
        label: "[EXT] Clinical Trial Phase",
        type: "multi-select",
        placeholder: "Select Clinical Trial Phase",
        name: "clinicalTrialPhase",
        options: CLINICAL_TRIAL_PHASE
    },
    {
        label: "[EXT] Date of Publication Submission",
        type: "date",
        name: "dateOfPublicationSubmission"
    },
    {
        label: "[EXT] Include PreClinical Portion?",
        type: "checkbox",
        name: "includePreClinicalPortion"
    },
    {
        label: "[EXT] Lead-in Period",
        type: "multi-select",
        placeholder: "Select Lead-in Period",
        name: "leadInPeriod",
        options: [
            { value: "None", label: "None" },
            { value: "Placebo", label: "Placebo" },
            { value: "Previous Therapy", label: "Previous Therapy" },
            { value: "Previous Therapy Failure", label: "Previous Therapy Failure" }
        ],
        helptext: "None, Placebo, Previous Therapy, Previous Therapy Failure"
    },
    {
        label: "Total Study Length (In Months)",
        type: "text",
        name: "totalStudyLength",
        disabled: true
    },
    {
        label: "[EXT] Assignment to Treatment",
        type: "multi-select",
        placeholder: "Select Assignment to Treatment",
        name: "assignmentToTreatment",
        options: [
            { value: "Randomized", label: "Randomized" },
            { value: "Non-Randomized", label: "Non-Randomized" },
            { value: "Stratified", label: "Stratified" }
        ],
        helptext: "Randomized, Non Randomized, Stratified"
    },
    {
        label: "Enrollment Period (In Months)",
        type: "text",
        name: "enrollmentPeriod",
        disabled: true
    },
    {
        label: "[EXT] Blinding",
        type: "multi-select",
        placeholder: "Select Blinding",
        name: "blinding",
        options: [
            { value: "Open Label", label: "Open Label" },
            { value: "Single-blind", label: "Single-blind" },
            { value: "Double-blind", label: "Double-blind" },
        ],
        helptext: "Open Label, Single Blind, Double Blind"
    },
    {
        label: "[EXT] Design",
        type: "multi-select",
        placeholder: "Select Design",
        name: "design",
        options: [
            { value: "Uncontrolled", label: "Uncontrolled" },
            { value: "Parallel", label: "Parallel" },
            { value: "Crossover", label: "Crossover" },
            { value: "Length of Washouts", label: "Length of Washout" }
        ],
        helptext: "Uncontrolled, Parallel, Crossover,Length of Washout"
    },
    {
        label: "[EXT] Blinding Done By",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "blindingDoneBy",
        options: [
            "None",
            "Lilly",
            "Site",
            "Not Applicable"
        ],
        helptext: "Lilly, Site, Not Applicable"
    },
    {
        label: "[EXT] Controls",
        type: "multi-select",
        placeholder: "Select Controls",
        name: "controls",
        options: [
            { value: "Active", label: "Active" },
            { value: "Placebo", label: "Placebo" },
            { value: "Both", label: "Both" },
            { value: "None", label: "None" },
        ],
        helptext: "Active, Placebo, Both, None"
    },
    {
        label: "[EXT] Patient Type",
        type: "multi-select",
        placeholder: "Select Patient Type",
        name: "patientType",
        options: [
            { value: "Inpatients", label: "Inpatients" },
            { value: "Outpatients", label: "Outpatients" },
            { value: "Normal Volunteer", label: "Normal Volunteer" }
        ],
        helptext: "Inpatient, Outpatient, Normal Volunteer"
    },
    {
        label: "[EXT] Extension",
        type: "multi-select",
        placeholder: "Select Extension",
        name: "extension",
        options: [
            { value: "Open Label", label: "Open Label" },
            { value: "Double Blind", label: "Double Blind" },
            { value: "None", label: "None" },
            { value: "length", label: "length" }
        ],
        helptext: "Open Label, Double Blind, None"
    },
    {
        label: "[EXT] Patient gender",
        type: "multi-select",
        placeholder: "Select Patient gender",
        name: "patientGender",
        options: [
            { value: "Male", label: "Male" },
            { value: "Female", label: "Female" },
            { value: "Both", label: "Both" }
        ],
        helptext: "Male, Female, Both"
    },
    {
        label: "[EXT] Extension Length",
        type: "text",
        placeholder: "Enter Extension Length",
        name: "extensionLength"
    },
    {
        label: "[EXT] Patient Treatment Period",
        type: "text",
        placeholder: "Enter Patient Treatment Period",
        name: "patientTreatmentPeriod",
        helptext: "Please define the treatment period of the average patient in months, please indicate if it will not be a full month (e.g. 2.5)"
    },
    {
        label: "[EXT] Age Range Start",
        type: "text",
        placeholder: "Enter Age Range Start",
        name: "ageRangeStart"
    },
    {
        label: "[EXT] Study On/Off Label",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "studyOnOffLabel",
        options: [
            "None",
            "Off Label",
            "On Label"
        ]
    },
    {
        label: "[EXT] Age Range End",
        type: "text",
        placeholder: "Enter Age Range End",
        name: "ageRangeEnd"
    },
    {
        label: "[EXT] Line of Therapy",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "lineOfTherapy",
        options: [
            "None",
            "1st Line",
            "2nd Line",
            "3rd Line",
            "Adjuvant",
            "Mainteinance",
            "Metastatic",
            "Neoadjuvant",
            "Other",
            "Perioperative"
        ]
    },]
}
get inputFieldsArrayStudyCompound() {
    return[
       

        //[EXT] Lilly to Supply Study Compound
        {
            label: "[EXT] Lilly to Supply Study Compound",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "lillyToSupplyStudyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] If No, why?
        {
            label: "[EXT] If No, why?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "ifNoWhy",
            options: [
                "None",
                "The Study doesn't involve an intervention",
                "Local laws/regulations prohibit it",
                "Third-party support is being provided"
            ]
        },

        //[EXT] Lilly Study Compound 1	
        {
            label: "[EXT] Lilly Study Compound 1",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 1",
            name: "lillyStudyCompound1",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] require fifth Lilly compound?
        {
            label: "[EXT] require fifth Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireFifthLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Lilly Study Compound 1 Other
        {
            label: "[EXT] Lilly Study Compound 1 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 1 Other",
            name: "lillyStudyCompound1Other"
        },
        //[EXT] Lilly Study Compound 5
        {
            label: "[EXT] Lilly Study Compound 5",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 5",
            name: "lillyStudyCompound5",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] Compound 1 Available Material
        {
            label: "[EXT] Compound 1 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 1 Available Material",
            name: "compound1AvailableMaterial",
            options: this.state.lillyStudyCompound1MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Lilly Study Compound 5 Other
        {
            label: "[EXT] Lilly Study Compound 5 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 5 Other",
            name: "lillyStudyCompound5Other"
        },
        //[EXT]Compound 1 Available Material Other
        {
            label: "[EXT]Compound 1 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 1 Available Material Other",
            name: "compound1AvailableMaterialOther"
        },
        //[EXT] Compound 5 Available Material	
        {
            label: "[EXT] Compound 5 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 5 Available Material",
            name: "compound5AvailableMaterial",
            options: this.state.lillyStudyCompound5MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Compound 1 Formulation	
        {
            label: "[EXT] Compound 1 Formulation",
            type: "text",
            placeholder: "Enter Compound 1 Formulation",
            name: "compound1Formulation"
        },
        //[EXT]Compound 5 Available Material Other	
        {
            label: "[EXT]Compound 5 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 5 Available Material Other",
            name: "compound5AvailableMaterialOther"
        },
        //[EXT] Compound 1 dosing Schedule
        {
            label: "[EXT] Compound 1 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 1 dosing Schedule",
            name: "compound1DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 5 Formulation	
        {
            label: "[EXT] Compound 5 Formulation",
            type: "text",
            placeholder: "Enter Compound 5 Formulation",
            name: "compound5Formulation"
        },
        //[EXT] Compound 1 dosing Schedule Other
        {
            label: "[EXT] Compound 1 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 1 dosing Schedule Other",
            name: "compound1DosingScheduleOther"
        },
        //[EXT] Compound 5 dosing Schedule
        {
            label: "[EXT] Compound 5 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 5 dosing Schedule",
            name: "compound5DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 1 Administered Dose
        {
            label: "[EXT] Compound 1 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 1 Administered Dose",
            name: "compound1AdministeredDose"
        },
        //[EXT] Compound 5 dosing Schedule Other
        {
            label: "[EXT] Compound 5 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 5 dosing Schedule Other",
            name: "compound5DosingScheduleOther"
        },
        //[EXT] Compound 1 Amount (Quantity)
        {
            label: "[EXT] Compound 1 Amount (Quantity)",
            type: "text",
            placeholder: "Enter Compound 1 Amount (Quantity)",
            name: "compound1AmountQuantity",
            helptext: "Provide amount of medication needed per patient"
        },
        //[EXT] Compound 5 Administered Dose	
        {
            label: "[EXT] Compound 5 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 5 Administered Dose",
            name: "compound5AdministeredDose"
        },
        //[EXT] Requested amt of comp 1 (PreClin)	
        {
            label: "[EXT] Requested amt of comp 1 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 1 (PreClin)",
            name: "requestedAmtOfComp1PreClin",
            helptext: "Please designate the amount of compound that you need for this study"
        },
        //[EXT] Compound 5 Amount (Quantity)
        {
            label: "[EXT] Compound 5 Amount (Quantity)",
            type: "text",
            placeholder: "Enter Compound 5 Amount (Quantity)",
            name: "compound5AmountQuantity",
            helptext: "Provide amount of medication needed per patient"
        },
        //[EXT] require second Lilly compound?	
        {
            label: "[EXT] require second Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireSecondLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Requested amt of comp 5 (PreClin)
        {
            label: "[EXT] Requested amt of comp 5 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 5 (PreClin)",
            name: "requestedAmtOfComp5PreClin",
            helptext: "Please designate the amount of compound that you need for this study"
        },
        //[EXT] Lilly Study Compound 2	
        {
            label: "[EXT] Lilly Study Compound 2",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 2",
            name: "lillyStudyCompound2",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] require sixth Lilly compound?	
        {
            label: "[EXT] require sixth Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireSixthLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Lilly Study Compound 2 Other
        {
            label: "[EXT] Lilly Study Compound 2 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 2 Other",
            name: "lillyStudyCompound2Other"
        },
        //[EXT] Lilly Study Compound 6
        {
            label: "[EXT] Lilly Study Compound 6",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 6",
            name: "lillyStudyCompound6",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] Compound 2 Available Material	
        {
            label: "[EXT] Compound 2 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 2 Available Material",
            name: "compound2AvailableMaterial",
            options: this.state.lillyStudyCompound2MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Lilly Study Compound 6 Other
        {
            label: "[EXT] Lilly Study Compound 6 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 6 Other",
            name: "lillyStudyCompound6Other"
        },
        //[EXT]Compound 2 Available Material Other
        {
            label: "[EXT]Compound 2 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 2 Available Material Other",
            name: "compound2AvailableMaterialOther"
        },
        //[EXT] Compound 6 Available Material
        {
            label: "[EXT] Compound 6 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 6 Available Material",
            name: "compound6AvailableMaterial",
            options: this.state.lillyStudyCompound6MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Compound 2 Formulation	
        {
            label: "[EXT] Compound 2 Formulation",
            type: "text",
            placeholder: "Enter Compound 2 Formulation",
            name: "compound2Formulation"
        },
        //[EXT]Compound 6 Available Material Other
        {
            label: "[EXT]Compound 6 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 6 Available Material Other",
            name: "compound6AvailableMaterialOther"
        },
        //[EXT] Compound 2 dosing Schedule
        {
            label: "[EXT] Compound 2 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 2 dosing Schedule",
            name: "compound2DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 6 Formulation
        {
            label: "[EXT] Compound 6 Formulation",
            type: "text",
            placeholder: "Enter Compound 6 Formulation",
            name: "compound6Formulation"
        },
        //[EXT] Compound 2 dosing Schedule Other
        {
            label: "[EXT] Compound 2 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 2 dosing Schedule Other",
            name: "compound2DosingScheduleOther"
        },
        //[EXT] Compound 6 dosing Schedule
        {
            label: "[EXT] Compound 6 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 6 dosing Schedule",
            name: "compound6DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 2 Administered Dose
        {
            label: "[EXT] Compound 2 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 2 Administered Dose",
            name: "compound2AdministeredDose"
        },
        //[EXT] Compound 6 dosing Schedule Other
        {
            label: "[EXT] Compound 6 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 6 dosing Schedule Other",
            name: "compound6DosingScheduleOther"
        },
        //[EXT] Compound 2 Amount (Quantity)
        {
            label: "[EXT] Compound 2 Amount (Quantity)",
            type: "text",
            placeholder: "Enter Compound 2 Amount (Quantity)",
            name: "compound2AmountQuantity",
            helptext: "Provide amount of medication needed per patient"
        },
        //[EXT] Compound 6 Administered Dose
        {
            label: "[EXT] Compound 6 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 6 Administered Dose",
            name: "compound6AdministeredDose"
        },
        //[EXT] Requested amt of comp 2 (PreClin)
        {
            label: "[EXT] Requested amt of comp 2 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 2 (PreClin)",
            name: "requestedAmtOfComp2PreClin",
            helptext: "Please designate the amount of compound that you need for this study"
        },
        //[EXT] Compound 6 Amount (Quantity)
        {
            label: "[EXT] Compound 6 Amount (Quantity)",
            type: "text",
            placeholder: "Enter Compound 6 Amount (Quantity)",
            name: "compound6AmountQuantity",
            helptext: "Provide amount of medication needed per patient"
        },
        //[EXT] require third Lilly compound?
        {
            label: "[EXT] require third Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireThirdLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Requested amt of comp 6 (PreClin)	
        {
            label: "[EXT] Requested amt of comp 6 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 6 (PreClin)",
            name: "requestedAmtOfComp6PreClin",
            helptext: "Please designate the amount of compound that you need for this study"
        },
        //[EXT] Lilly Study Compound 3	
        {
            label: "[EXT] Lilly Study Compound 3",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 3",
            name: "lillyStudyCompound3",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] require seventh Lilly compound?	
        {
            label: "[EXT] require seventh Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireSeventhLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Lilly Study Compound 3 Other
        {
            label: "[EXT] Lilly Study Compound 3 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 3 Other",
            name: "lillyStudyCompound3Other"
        },
        //[EXT] Lilly Study Compound 7	
        {
            label: "[EXT] Lilly Study Compound 7",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 7",
            name: "lillyStudyCompound7",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] Compound 3 Available Material
        {
            label: "[EXT] Compound 3 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 3 Available Material",
            name: "compound3AvailableMaterial",
            options: this.state.lillyStudyCompound3MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Lilly Study Compound 7 Other
        {
            label: "[EXT] Lilly Study Compound 7 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 7 Other",
            name: "lillyStudyCompound7Other"
        },
        //[EXT]Compound 3 Available Material Other	
        {
            label: "[EXT]Compound 3 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 3 Available Material Other",
            name: "compound3AvailableMaterialOther"
        },
        //[EXT] Compound 7 Available Material
        {
            label: "[EXT] Compound 7 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 7 Available Material",
            name: "compound7AvailableMaterial",
            options: this.state.lillyStudyCompound7MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Compound 3 Formulation
        {
            label: "[EXT] Compound 3 Formulation",
            type: "text",
            placeholder: "Enter Compound 3 Formulation",
            name: "compound3Formulation"
        },
        //[EXT] Compound 7 Formulation	
        {
            label: "[EXT] Compound 7 Formulation",
            type: "text",
            placeholder: "Enter Compound 7 Formulation",
            name: "compound7Formulation"
        },
        //[EXT] Compound 3 dosing Schedule
        {
            label: "[EXT] Compound 3 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 3 dosing Schedule",
            name: "compound3DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT]Compound 7 Available Material Other
        {
            label: "[EXT]Compound 7 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 7 Available Material Other",
            name: "compound7AvailableMaterialOther"
        },
        //[EXT] Compound 3 dosing Schedule Other
        {
            label: "[EXT] Compound 3 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 3 dosing Schedule Other",
            name: "compound3DosingScheduleOther"
        },
        //[EXT] Compound 7 dosing Schedule
        {
            label: "[EXT] Compound 7 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 7 dosing Schedule",
            name: "compound7DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 3 Administered Dose
        {
            label: "[EXT] Compound 3 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 3 Administered Dose",
            name: "compound3AdministeredDose"
        },
        //[EXT] Compound 7 dosing Schedule Other
        {
            label: "[EXT] Compound 7 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 7 dosing Schedule Other",
            name: "compound7DosingScheduleOther"
        },
        //[EXT] Compound 3 Amount (Quantity)
        {
            label: "[EXT] Compound 3 Amount (Quantity)",
            type: "text",
            placeholder: "Enter Compound 3 Amount (Quantity)",
            name: "compound3AmountQuantity",
            helptext: "Provide amount of medication needed per patient"
        },
        //[EXT] Compound 7 Administered Dose
        {
            label: "[EXT] Compound 7 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 7 Administered Dose",
            name: "compound7AdministeredDose"
        },
        //[EXT] Requested amt of comp 3 (PreClin)
        {
            label: "[EXT] Requested amt of comp 3 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 3 (PreClin)",
            name: "requestedAmtOfComp3PreClin",
            helptext: "Please designate the amount of compound that you need for this study"
        },
        //[EXT] Compound 7 Amount (Quantity)
        {
            label: "[EXT] Compound 7 Amount (Quantity)",
            type: "text",
            placeholder: "Enter Compound 7 Amount (Quantity)",
            name: "compound7AmountQuantity",
            helptext: "Provide amount of medication needed per patient"
        },
        //[EXT] require fourth Lilly compound?
        {
            label: "[EXT] require fourth Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireFourthLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Requested amt of comp 7 (PreClin)
        {
            label: "[EXT] Requested amt of comp 7 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 7 (PreClin)",
            name: "requestedAmtOfComp7PreClin",
            helptext: "Please designate the amount of compound that you need for this study"
        },
        //[EXT] Lilly Study Compound 4
        {
            label: "[EXT] Lilly Study Compound 4",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 4",
            name: "lillyStudyCompound4",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] Lilly Study Compound 4 Other
        {
            label: "[EXT] Lilly Study Compound 4 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 4 Other",
            name: "lillyStudyCompound4Other"
        },
        //[EXT] Compound 4 Available Material
        {
            label: "[EXT] Compound 4 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 4 Available Material",
            name: "compound4AvailableMaterial",
            options: this.state.lillyStudyCompound4MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT]Compound 4 Available Material Other
        {
            label: "[EXT]Compound 4 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 4 Available Material Other",
            name: "compound4AvailableMaterialOther"
        },
        //[EXT] Compound 4 Formulation	
        {
            label: "[EXT] Compound 4 Formulation",
            type: "text",
            placeholder: "Enter Compound 4 Formulation",
            name: "compound4Formulation"
        },
        //[EXT] Compound 4 dosing Schedule
        {
            label: "[EXT] Compound 4 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 4 dosing Schedule",
            name: "compound4DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 4 dosing Schedule Other
        {
            label: "[EXT] Compound 4 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 4 dosing Schedule Other",
            name: "compound4DosingScheduleOther"
        },
        //[EXT] Compound 4 Administered Dose
        {
            label: "[EXT] Compound 4 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 4 Administered Dose",
            name: "compound4AdministeredDose"
        },
        //[EXT] Compound 4 Amount (Quantity)
        {
            label: "[EXT] Compound 4 Amount (Quantity)",
            type: "text",
            placeholder: "Enter Compound 4 Amount (Quantity)",
            name: "compound4AmountQuantity",
            helptext: "Provide amount of medication needed per patient"
        },
        //[EXT] Requested amt of comp 4 (PreClin)
        {
            label: "[EXT] Requested amt of comp 4 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 4 (PreClin)",
            name: "requestedAmtOfComp4PreClin",
            helptext: "Please designate the amount of compound that you need for this study"
        },
    ]
}
get inputFieldsArrayFinancial() {
    return [ 
    {
        label: "[EXT] Requesting Funding",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "requestingFunding",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "[EXT] Non-Lilly Support?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "nonLillySupport",
        options: [
            "None",
            "Yes",
            "No"
        ],
        helptext: "Is support being provided by another company?"
    },
    {
        label: "[EXT] Amount of funds",
        type: "text",
        placeholder: "Enter Amount of funds",
        name: "amountOfFunds"
    },
    {
        label: "Currency",
        type: "searchableDropdown",
        placeholder: "Select Currency",
        name: "currency",
        options: this.state.currencyList,
        onSelect: this.onSearchSelect
    },
    
    {
        label: "[EXT] Name of Company",
        type: "text",
        placeholder: "Enter Name of Company",
        name: "nameOfCompany"
    },
    {
        label: "[EXT] Type of Support",
        type: "text",
        placeholder: "Enter Type of Support",
        name: "typeOfSupport",
        helptext: "Grant, Drug, Both"
    },]
}
get inputFieldsArrayOtherInfo() {
    return [
    {
        label: "[EXT] References",
        type: "textarea",
        name: "references",

    },
    {
        label: "[EXT] HCP Proposal Comments",
        type: "textarea",
        name: "hCPProposalComments",

    }, ]
}
    // get inputFieldsArrayFeedback() {
    //     return [
           
    //         /*********************************************/
            
    //         /*********************************************/
            
    //         /*********************************************/
           
    //         /*********************************************/
            
    //         /*************************************************************************/
           
    //         /*****************************************************************************/
           
    //         /*****************************************************************************/
           
            
    //         {
    //             label: "Lilly Feedback (Investigator Visible)",
    //             type: "textarea",
    //             name: "feedback",

    //         },
    //     ];
    // }
    async getExternalUrl() {
        let response ='';
        try {
             response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
        externalurl : (response && response.data) ? response.data.exturl : "http://localhost:1337",
    })
    }
    formType={
        INITIATE_INSTRUCTION:'INITIATE_INSTRUCTION'
    }
    async componentDidMount() {

        let res=await API.get('reviewConcept/schemaInformation/proposals')

        console.log(res)

        this.setState({

            proposallist:res.data

        })
        await this.getExternalUrl();
        let _isCoordinator=isCoordinator(this.context);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            showLoader :  true,
            selectedRole: selectedRole,
            isCoordinator:_isCoordinator,
            access: {
                view: true,
                create: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
        // console.time('APICalls: ')
    //     await this.getCompoundList();
    //     await this.getProposaltDetails (this.props.submissionId)
    //    await this.getAuditList( this.props.submissionId);
        await Promise.all([this.getCompoundList(), this.getProposaltDetails (this.props.submissionId), 
            // this.getAuditList(this.props.submissionId)
        ]);
        // console.timeEnd('APICalls: ')
        this.setState({
            showLoader :  false,})
    }
    componentDidUpdate(previousProps, previousState) {
        // console.log(this.context.selectedRole);
        // console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        let _isCoordinator=isCoordinator(this.context);
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                isCoordinator:_isCoordinator,
                access: {
                    view: true,
                    create: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }


    onCompoundSelect = (e, name) => {
        this.setState({
            [name]: e,
            patchObject: { ...this.state.patchObject, [PROPOSAL_UPDATE[name]]: e.value },
            hasChanged: true
        });
        this.getMaterialList(name, e.value)
    }

    getCurrencyList = async () => {
        try {
            const res = await API.get(`seed/currency/data`)
            const resultArray = res.data?.data || [].map(elm => ({ name: `${elm.value} - ${elm.name}`, value: elm.value }));
            this.setState({
                currencyList: resultArray,
                currency: resultArray.find(curr => curr.value === this.state.proposalDetails.currency),
            });
        } catch (e) {
            console.log("Error in currency API")
        }
    }

    getMaterialList = async (name, compound) => {
        try {
            const res = await API.get(`seed/dosage_strength/compound/${compound}`);
            const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.name }));
            this.setState({ [name + 'MaterialList']: resultArray });
        } catch (e) {
            console.log("Error in compound material Api")
        }
    }

    getProposaltDetails = async () => {
        // if (proposalId) {
        //     const res = await API.get(`proposal/getbyid/${proposalId}`);
        const submissionId = this.props.submissionId;
        if (submissionId) {
            const res = await API.get(`proposal/submission/${submissionId}`);
            if(res.data&&res.data.id){
            this.setState({
                proposalDetails: res.data,
                patchObject:{"id":res.data.id}

            }, () => { this.setProposalFields(); this.getCurrencyList();  this.getAuditList(this.state.proposalDetails.id); }
            );
           await this.getFilesList(res.data.submissionId)
        }}
    }

    getFilesList = async (submissionId) => {
        try {
            this.setState({
                showLoader : true , 
                action : "fetching",
                loadTable : false
            })
            const res = await API.get(`/user/proposals3/getByid/${submissionId}`);
            this.setState({
                fileList: res.data?.filter(file => file.type === "Proposal"),
                showLoader : false,
                loadTable : true

            });
        } catch (e) {
            this.setState({
                showLoader : false,
                loadTable : true
            })
            console.log(e);
        }
    }

    getHtmlFromEditorValue = (val="") => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }

    getEditorValueFromHtml = (html="") => {
        if(html){
            const blocksFromHTML = convertFromHTML(html);        
            const state = ContentState.createFromBlockArray(blocksFromHTML);
            return EditorState.createWithContent(state);
        }
        return "";
    }

    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            hasChanged: true,
            patchObject: { ...this.state.patchObject, [PROPOSAL_UPDATE[e]]: val },
        });
    };
    
    setProposalFields = async () => {
        // console.log(this.state.id)
        this.setState({
            id:this.state.proposalDetails.id,
            proposalSubmitted: this.state.proposalDetails.proposalSubmitted || false,
            createdBy: await getUserInfo(this.state.proposalDetails.createdBy) + "  " + dateTimeformat(this.state.proposalDetails.createdAt) || "",
            lastModifiedBy: this.state.proposalDetails.last_modified_by?.length > 0 ? await getUserInfo(this.state.proposalDetails.last_modified_by) + "  " + dateTimeformat(this.state.proposalDetails.updatedAt) : await getUserInfo(this.state.proposalDetails.createdBy) + "  " + dateTimeformat(this.state.proposalDetails.createdAt),
            submissionId: "Submission-" + this.state.proposalDetails.submissionId || "",
            investigatorName: this.state.proposalDetails.submission?.investigator_name || "",
            last_updated_by: this.state.proposalDetails.last_updated_by || "",
            proposalTitle: this.state.proposalDetails.proposalTitle || "",
            shippingImportLicenseRequired: this.state.proposalDetails.shippingImportLicenseRequired || "None",
            iNDApplication: this.state.proposalDetails.iNDApplication || "None",
            uSDepartmentOfVetAffairs: this.state.proposalDetails.uSDepartmentOfVetAffairs || "None",
            //scientificRationaleForStudy: this.state.proposalDetails.scientificRationaleForStudy,
          scientificRationaleForStudy: this.state.proposalDetails.scientificRationaleForStudy !==null ? this.getEditorValueFromHtml(this.state.proposalDetails.scientificRationaleForStudy) : "",
            studyType: this.state.proposalDetails.studyType || "None",
            //primaryAndSecondaryObjectives: this.state.proposalDetails.primaryAndSecondaryObjectives ,
            primaryAndSecondaryObjectives: this.state.proposalDetails.primaryAndSecondaryObjectives !== null ? this.state.proposalDetails.primaryAndSecondaryObjectives : "",
            feedback: this.state.proposalDetails.feedback || "",
            ifOtherStudyType: this.state.proposalDetails.ifOtherStudyType || "",
            lengthOfWashout: this.state.proposalDetails.lengthOfWashout || "",
            studyToUseAnimals: this.state.proposalDetails.studyToUseAnimals || "None",
            iACUCDocumentationAttached: this.state.proposalDetails.iACUCDocumentationAttached || "None",
            descriptionOfAnimalUsed: this.state.proposalDetails.descriptionOfAnimalUsed || "",
            dosesConcentrations: this.state.proposalDetails.dosesConcentrations || "",
            aCAndUCommitteeApproval: this.state.proposalDetails.aCAndUCommitteeApproval || "None",
            endpoints: this.state.proposalDetails.endpoints || "",
            modelMethodology: this.state.proposalDetails.modelMethodology || "",
            targetStartDate: this.state.proposalDetails.targetStartDate ? dateStringformat(this.state.proposalDetails.targetStartDate) : null,
            statisticalAnalysis: this.state.proposalDetails.statisticalAnalysis || "",
            reportSubmissionDate: this.state.proposalDetails.reportSubmissionDate ? dateStringformat(this.state.proposalDetails.reportSubmissionDate) : null,
            humanSamplesInvolved: this.state.proposalDetails.humanSamplesInvolved || "",
            publicationDateAnticipated: this.state.proposalDetails.publicationDateAnticipated ? dateStringformat(this.state.proposalDetails.publicationDateAnticipated) : null,
            typeOfHumanSamples: this.state.proposalDetails.typeOfHumanSamples || "None",
            projectedMilestoneTiming: this.state.proposalDetails.projectedMilestoneTiming || "None",
            uniquePersonalClinicalSamples: this.state.proposalDetails.uniquePersonalClinicalSamples || "None",
            biobankOwner: this.state.proposalDetails.biobankOwner || "None",
            sampleSize: this.state.proposalDetails.sampleSize || "",
            dateOfFirstPatientVisit: this.state.proposalDetails.dateOfFirstPatientVisit ? dateStringformat(this.state.proposalDetails.dateOfFirstPatientVisit) : null,
            numberOfTherapyGroups: this.state.proposalDetails.numberOfTherapyGroups || "",
            dateFirstPtEntersTreatment: this.state.proposalDetails.dateFirstPtEntersTreatment ? dateStringformat(this.state.proposalDetails.dateFirstPtEntersTreatment) : null,
            anticipatedQuarterlyEnrollment: this.state.proposalDetails.anticipatedQuarterlyEnrollment || "",
            dateLastPatientEntersTrial: this.state.proposalDetails.dateLastPatientEntersTrial ? dateStringformat(this.state.proposalDetails.dateLastPatientEntersTrial) : null,
            numberOfInvestigativeSite: this.state.proposalDetails.numberOfInvestigativeSite || "",
            dateLastPatientEntersTreatment: this.state.proposalDetails.dateLastPatientEntersTreatment ? dateStringformat(this.state.proposalDetails.dateLastPatientEntersTreatment) : null,
            potentialCountries: this.state.proposalDetails.potentialCountries || "",
            dateOfLastPatientVisit: this.state.proposalDetails.dateOfLastPatientVisit ? dateStringformat(this.state.proposalDetails.dateOfLastPatientVisit) : null,
            multiCountryTrial: this.state.proposalDetails.multiCountryTrial || false,
            dateOfReportSubmission: this.state.proposalDetails.dateOfReportSubmission ? dateStringformat(this.state.proposalDetails.dateOfReportSubmission) : null,
            clinicalTrialPhase: this.state.proposalDetails.clinicalTrialPhase?.length > 0 ? this.state.proposalDetails.clinicalTrialPhase?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            includePreClinicalPortion: this.state.proposalDetails.includePreClinicalPortion || false,
            dateOfPublicationSubmission: this.state.proposalDetails.dateOfPublicationSubmission ? dateStringformat(this.state.proposalDetails.dateOfPublicationSubmission) : null,
            assignmentToTreatment: this.state.proposalDetails.assignmentToTreatment?.length > 0 ? this.state.proposalDetails.assignmentToTreatment?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            leadInPeriod: this.state.proposalDetails.leadInPeriod ? this.state.proposalDetails.leadInPeriod?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            design: this.state.proposalDetails.design ? this.state.proposalDetails.design?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            blinding: this.state.proposalDetails.blinding ? this.state.proposalDetails.blinding?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            controls: this.state.proposalDetails.controls ? this.state.proposalDetails.controls?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            blindingDoneBy: this.state.proposalDetails.blindingDoneBy || "None",
            extension: this.state.proposalDetails.extension ? this.state.proposalDetails.extension?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            patientType: this.state.proposalDetails.patientType ? this.state.proposalDetails.patientType?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            extensionLength: this.state.proposalDetails.extensionLength,
            patientGender: this.state.proposalDetails.patientGender ? this.state.proposalDetails.patientGender?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            ageRangeStart: this.state.proposalDetails.ageRangeStart || "",
            patientTreatmentPeriod: this.state.proposalDetails.patientTreatmentPeriod || "",
            ageRangeEnd: this.state.proposalDetails.ageRangeEnd || "None",
            studyOnOffLabel: this.state.proposalDetails.studyOnOffLabel || "",
            lineOfTherapy: this.state.proposalDetails.lineOfTherapy || "None",
            lillyToSupplyStudyCompound: this.state.proposalDetails.lillyToSupplyStudyCompound,
            ifNoWhy: this.state.proposalDetails.ifNoWhy,
            lillyStudyCompound1: this.state.proposalDetails.lillyStudyCompound1_data != null ? this.state.compoundList.find(compound => compound.value === this.state.proposalDetails.lillyStudyCompound1_data.id): "",
            requireFifthLillyCompound: this.state.proposalDetails.requireFifthLillyCompound || "None",
            lillyStudyCompound1Other: this.state.proposalDetails.lillyStudyCompound1Other,
            lillyStudyCompound5:this.state.proposalDetails.lillyStudyCompound5_data != null ? this.state.compoundList.find(compound => compound.value === this.state.proposalDetails.lillyStudyCompound5_data.id) : "",
            compound1AvailableMaterial: this.state.proposalDetails.compound1AvailableMaterial?.value || [],
            lillyStudyCompound5Other: this.state.proposalDetails.lillyStudyCompound5Other,
            compound1AvailableMaterialOther: this.state.proposalDetails.compound1AvailableMaterialOther,
            compound5AvailableMaterial: this.state.proposalDetails.compound5AvailableMaterial?.value || [],
            compound1Formulation: this.state.proposalDetails.compound1Formulation,
            compound5AvailableMaterialOther: this.state.proposalDetails.compound5AvailableMaterialOther,
            compound1DosingSchedule: this.state.proposalDetails.compound1DosingSchedule || "None",
            compound5Formulation: this.state.proposalDetails.compound5Formulation,
            compound1DosingScheduleOther: this.state.proposalDetails.compound1DosingScheduleOther,
            compound5DosingSchedule: this.state.proposalDetails.compound5DosingSchedule || "None",
            compound1AdministeredDose: this.state.proposalDetails.compound1AdministeredDose,
            compound5DosingScheduleOther: this.state.proposalDetails.compound5DosingScheduleOther,
            compound1AmountQuantity: this.state.proposalDetails.compound1AmountQuantity,
            compound5AdministeredDose: this.state.proposalDetails.compound5AdministeredDose,
            requestedAmtOfComp1PreClin: this.state.proposalDetails.requestedAmtOfComp1PreClin,
            compound5AmountQuantity: this.state.proposalDetails.compound5AmountQuantity,
            requireSecondLillyCompound: this.state.proposalDetails.requireSecondLillyCompound || "None",
            requestedAmtOfComp5PreClin: this.state.proposalDetails.requestedAmtOfComp5PreClin,
            lillyStudyCompound2: this.state.proposalDetails.lillyStudyCompound2_data!=null ? this.state.compoundList.find(compound => compound.value === this.state.proposalDetails.lillyStudyCompound2_data.id) : "",
            requireSixthLillyCompound: this.state.proposalDetails.requireSixthLillyCompound || "None",
            lillyStudyCompound2Other: this.state.proposalDetails.lillyStudyCompound2Other,
            lillyStudyCompound6: this.state.proposalDetails.lillyStudyCompound6_data != null ?this.state.compoundList.find(compound => compound.value === this.state.proposalDetails.lillyStudyCompound6_data.id) : "",
            compound2AvailableMaterial: this.state.proposalDetails.compound2AvailableMaterial?.value || [],
            lillyStudyCompound6Other: this.state.proposalDetails.lillyStudyCompound6Other,
            compound2AvailableMaterialOther: this.state.proposalDetails.compound2AvailableMaterialOther,
            compound6AvailableMaterial: this.state.proposalDetails.compound6AvailableMaterial?.value || [],
            compound2Formulation: this.state.proposalDetails.compound2Formulation,
            compound6AvailableMaterialOther: this.state.proposalDetails.compound6AvailableMaterialOther,
            compound2DosingSchedule: this.state.proposalDetails.compound2DosingSchedule || "None",
            compound6Formulation: this.state.proposalDetails.compound6Formulation,
            compound2DosingScheduleOther: this.state.proposalDetails.compound2DosingScheduleOther,
            compound6DosingSchedule: this.state.proposalDetails.compound6DosingSchedule || "None",
            compound2AdministeredDose: this.state.proposalDetails.compound2AdministeredDose,
            compound6DosingScheduleOther: this.state.proposalDetails.compound6DosingScheduleOther,
            compound2AmountQuantity: this.state.proposalDetails.compound2AmountQuantity,
            compound6AdministeredDose: this.state.proposalDetails.compound6AdministeredDose,
            requestedAmtOfComp2PreClin: this.state.proposalDetails.requestedAmtOfComp2PreClin,
            compound6AmountQuantity: this.state.proposalDetails.compound6AmountQuantity,
            requireThirdLillyCompound: this.state.proposalDetails.requireThirdLillyCompound || "None",
            requestedAmtOfComp6PreClin: this.state.proposalDetails.requestedAmtOfComp6PreClin,
            lillyStudyCompound3: this.state.proposalDetails.lillyStudyCompound3_data != null ? this.state.compoundList.find(compound => compound.value === this.state.proposalDetails.lillyStudyCompound3_data.id) : "",
            requireSeventhLillyCompound: this.state.proposalDetails.requireSeventhLillyCompound || "None",
            lillyStudyCompound3Other: this.state.proposalDetails.lillyStudyCompound3Other,
            lillyStudyCompound7:this.state.proposalDetails.lillyStudyCompound7_data != null ? this.state.compoundList.find(compound => compound.value === this.state.proposalDetails.lillyStudyCompound7_data.id) : "",
            compound3AvailableMaterial: this.state.proposalDetails.compound3AvailableMaterial?.value || [],
            lillyStudyCompound7Other: this.state.proposalDetails.lillyStudyCompound7Other,
            compound3AvailableMaterialOther: this.state.proposalDetails.compound3AvailableMaterialOther,
            compound7AvailableMaterial: this.state.proposalDetails.compound7AvailableMaterial?.value || [],
            compound3Formulation: this.state.proposalDetails.compound3Formulation,
            compound7Formulation: this.state.proposalDetails.compound7Formulation,
            compound3DosingSchedule: this.state.proposalDetails.compound3DosingSchedule || "None",
            compound7AvailableMaterialOther: this.state.proposalDetails.compound7AvailableMaterialOther,
            compound3DosingScheduleOther: this.state.proposalDetails.compound3DosingScheduleOther,
            compound7DosingSchedule: this.state.proposalDetails.compound7DosingSchedule || "None",
            compound3AdministeredDose: this.state.proposalDetails.compound3AdministeredDose,
            compound7DosingScheduleOther: this.state.proposalDetails.compound7DosingScheduleOther,
            compound3AmountQuantity: this.state.proposalDetails.compound3AmountQuantity,
            compound7AdministeredDose: this.state.proposalDetails.compound7AdministeredDose,
            requestedAmtOfComp3PreClin: this.state.proposalDetails.requestedAmtOfComp3PreClin,
            compound7AmountQuantity: this.state.proposalDetails.compound7AmountQuantity,
            requireFourthLillyCompound: this.state.proposalDetails.requireFourthLillyCompound || "None",
            requestedAmtOfComp7PreClin: this.state.proposalDetails.requestedAmtOfComp7PreClin,
            lillyStudyCompound4: this.state.proposalDetails.lillyStudyCompound4_data != null ? this.state.compoundList.find(compound => compound.value === this.state.proposalDetails.lillyStudyCompound4_data.id) : "",
            lillyStudyCompound4Other: this.state.proposalDetails.lillyStudyCompound4Other,
            compound4AvailableMaterial: this.state.proposalDetails.compound4AvailableMaterial?.value || [],
            compound4AvailableMaterialOther: this.state.proposalDetails.compound4AvailableMaterialOther,
            compound4Formulation: this.state.proposalDetails.compound4Formulation,
            compound4DosingSchedule: this.state.proposalDetails.compound4DosingSchedule || "None",
            compound4DosingScheduleOther: this.state.proposalDetails.compound4DosingScheduleOther,
            compound4AdministeredDose: this.state.proposalDetails.compound4AdministeredDose,
            compound4AmountQuantity: this.state.proposalDetails.compound4AmountQuantity,
            requestedAmtOfComp4PreClin: this.state.proposalDetails.requestedAmtOfComp4PreClin,
            requestingFunding: this.state.proposalDetails.requestingFunding || "None",
            nonLillySupport: this.state.proposalDetails.nonLillySupport || "None",
            amountOfFunds: this.state.proposalDetails.amountOfFunds,
            nameOfCompany: this.state.proposalDetails.nameOfCompany,
            typeOfSupport: this.state.proposalDetails.typeOfSupport,
            totalStudyLength: getDateDifferenceMonth(this.state.proposalDetails.dateOfFirstPatientVisit, this.state.proposalDetails.dateOfPublicationSubmission) || null,
            enrollmentPeriod: getDateDifferenceMonth(this.state.proposalDetails.dateOfFirstPatientVisit, this.state.proposalDetails.dateLastPatientEntersTrial) || null,
            references: this.state.proposalDetails.references || "",
            hCPProposalComments: this.state.proposalDetails.hCPProposalComments || "",
            submission_id: this.state.proposalDetails.submissionId
        }, this.setMaterialFields)
    }

    setMaterialFields = () => {
        const fieldList = ["lillyStudyCompound1", "lillyStudyCompound2", "lillyStudyCompound3", "lillyStudyCompound4", "lillyStudyCompound5", "lillyStudyCompound6", "lillyStudyCompound7"]
        fieldList.forEach((field, index) => {
            if (this.state[field] && this.state[field]?.name) {
                // console.log("state",this.state[field])
                this.getMaterialList(field, this.state[field]?.value)
                this.setState({
                    [`compound${index + 1}AvailableMaterial`]: { name: this.state.proposalDetails[`compound${index + 1}AvailableMaterial`], value: this.state.proposalDetails[`compound${index + 1}AvailableMaterial`] }
                })
            }
        })
    }

    getCompoundList = async () => {
        const res = await API.get(`seed/compound/getalldata`)
        const resultArray = res.data.filter(elm => elm.is_part_of_proposal===true );
        const arr = resultArray.map(elm=>({ name: elm.name, value: elm.id }) );
        this.setState({ compoundList: arr });
    }

    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: dateChange(e) },
                hasChanged: true
            });
        }
        else 
        { this.setState({
            [e.target.name]: e.target.value,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},
            hasChanged: true
        });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [PROPOSAL_UPDATE[eo.name || eo.target.name]]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            hasChanged: true
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
            patchObject: { ...this.state.patchObject, [PROPOSAL_UPDATE[name]]: e.value },
            hasChanged: true
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject: { ...this.state.patchObject, [PROPOSAL_UPDATE[e.target.name]]: e.target.checked },
            hasChanged: true
        });
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)

        this.setState({ usersList: res.data });
    }
    getAuditList = async (id) => {
        try {
            const temp = [];
            const res = await API.get(`audit/proposal/${id}`)
            // console.log(res)
            // console.time('AuditListAPICalls: ')
            // await this.getTrailList();
            // await this.getUserList();
            await Promise.all([this.getTrailList(), this.getUserList()])
            // console.timeEnd('AuditListAPICalls: ')
            this.state.trailData.forEach(x => {
                res.data.forEach(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        let fieldLabels = ["updated_by"]
                        if (fieldLabels.includes(y.field)) {
                            let newValueText =y.new_value!=null ? this.state.usersList.filter(x => x.system_id === (y.new_value))[0] : "";
                            y.new_value = newValueText!="" ? newValueText?.first_name + " " + newValueText?.last_name : "";
                            let oldValueText =y.old_value!=null ? this.state.usersList.filter(x => x.system_id === (y.old_value))[0] : "";
                            y.old_value =oldValueText!="" ? oldValueText?.first_name + " " + oldValueText?.last_name : "";
                            temp.push(y);
                        }
                        else if(y.field==="created_at"){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):"";
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                             temp.push(y);
                         }
                        else {
                            y.new_value = (y.new_value);
                            y.old_value = (y.old_value)
                            temp.push(y);
                        }

                    }


                })
            })
            let sortedReviews = temp.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            this.setState({
                auditData: sortedReviews,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/proposal`)
            // console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    renderInputFieldsProposalIIR = () => {
        return this.inputFieldsArrayProposalIIR.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsProposal_Study = () => {
        return this.inputFieldsArrayProposal_Study.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                         disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        isReadOnly={field.isReadOnly ? field.isReadOnly : !this.state.access.edit }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsInformation = () => {
        return this.inputFieldsArrayInformation.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col  sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                          disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsClinical = () => {
        return this.inputFieldsArrayClinical.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                          disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsNCRI = () => {
        return this.inputFieldsArrayNCRI.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                         disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsStudyCompound = () => {
        return this.inputFieldsArrayStudyCompound.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                          disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsOtherInfo = () => {
        return this.inputFieldsArrayOtherInfo.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                          disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    // renderInputFieldsFeedback = () => {
    //     return this.inputFieldsArrayFeedback.map(field => {
    //         if (field.type === "alert")
    //             return <Col sm={12} key={field.label}><InputField {...field} /></Col>
    //         return (
    //             <Col sm={6} key={field.label}>
    //                 <InputField
    //                     {...field}
    //                     key={field.label}
    //                       disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
    //                     error={this.state.error?.[field.name]}
    //                     value={this.state[field.name]}
    //                     onSelect={field.onSelect || this.onSelectChange}
    //                     onChange={field.type !== "checkbox" ? field.onChange || this.onInputChange : this.checkboxChange}
    //                 />
    //             </Col>
    //         )
    //     });
    // }
    renderInputFieldsFinancial = () => {
        return this.inputFieldsArrayFinancial.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                          disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.proposalDetails.submissionId}`)
    }

    formValidation = () => {
        
        let is_valid = true;
        let error={};
        error["lengthValidation"]={};
        if(this.state['proposalTitle'].trim() === "") {
            is_valid = false;
            error.proposalTitle=true;            
        }
        let{proposallist}=this.state;
        
        const obj = ({ ...this.state.patchObject });
        //let error={};
        for (let [key, value] of Object.entries(proposallist)) {
            if(value){
                if(this.state[key]&&this.state[key].length>value){
                    is_valid = false;
                    error[key] = true;
                    error["lengthValidation"][key]=true;
                }
            }
        }
                this.setState({
                    error
                })

        return is_valid;
    }
    submitForm = async () => {
       if(this.formValidation())
        { 
           
           
            const editorFields = [
            // "references",
            // "hCPProposalComments",
            "scientificRationaleForStudy",
            // "primaryAndSecondaryObjectives"
        ]
        const obj = { ...this.state.patchObject };
        editorFields.forEach(f => {
            if(f in obj)
                obj[f] = this.getHtmlFromEditorValue(this.state[f])
        });

        obj['last_modified_by'] = this.context.userSystemId;
        try {
            console.log(this.state.patchObject)
            // const res = await API.put("proposal/update",obj) ||await API.post("proposal/add", {"proposal": {...obj}, "submission" : {
            //     id : this.props.location.state.submissionId
            //     // stage :"Proposal"                
            // }}) 
        //    console.log(res)
            this.setState({historyRefresh:false});
            if(this.state.proposalDetails.submissionId){ 
            var res= await API.put("proposal/update",obj)}
             else  { var res=await API.post("proposal/add", {"proposal":{...obj},"submission" : {
                id : this.props.submissionId
                                
            }})} 

            
            this.setState({
                successModal: true,
                hasChanged: false,
                historyRefresh:true,
                error:{},
                id:this.props.submissionId,
                patchObject: { "id": this.state.proposalDetails.id}
            })
        } catch (err) {
            this.setState({ errorModal: true,historyRefresh:true});
        }  
        }
        else{
            this.setState({
                errorModal:true
            })
        }
          
        
    }

    changeShowState = async() => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false,
        });
        if(!this.state.errorModal)
      await  this.getProposaltDetails(this.props.submissionId);
      await  this.getAuditList(this.state.proposalDetails.id);
    }

    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }

    uploadFileFunction = async () => {
        this.setState({
            showLoaderUpload : true,
            historyRefresh: false
        })
        const formData = new FormData();
        formData.append(
            'file',
            this.state.selectedFile,
            this.state.selectedFile?.name
        );
        formData.append(
            'created_by',
            this.context.userSystemId
        );
        try {
            // const res =await API.post(`/user/proposals3/upload/Proposal/${this.state.proposalDetails.submissionId}`, formData);
            let fileUrl = `${this.state.externalurl}/fileupload/Proposal/${this.state.proposalDetails.submissionId}`;
            let res = await axios.post(fileUrl, formData, {
                headers: {                   
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                },
            });
            this.setState({
                selectedFile: null,
                successFile: true,
                fileMessage: "File uploaded!",
                showLoaderUpload : false

            })
            // await this.getFilesList(res.data.submissionId)

        } catch (e) {
            this.setState({
                selectedFile: null,
                errorFile: true,
                showLoaderUpload : false

            })
            console.log(e);
        } finally {
            this.setState({ historyRefresh: true });
        }
    }
    handleFormEvents=(option)=>{
        this.setState({activeForm:option});
    }
    handleModalCallBack=(response)=>{
        if(response===true){
            let callBack=(val)=>{
                this.setState({activeForm:''},()=>{
                    this.getProposaltDetails(this.props.submissionId) ;
                });
              }
            //   showMessage({                  
            //     text: "Saved Successfully",
            //     icon: "success",
            //     button: "Ok",
              
            //   },callBack);
           callBack();
        } 
        else{
            this.setState({activeForm:''});
        }           
    }
    handleDelete = async (fileName) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({
                            showLoader : true,
                            action : "deletion",
                            historyRefresh: false
                        })
                        const res = await API.post(`/user/proposals3/delete/Proposal/${this.state.proposalDetails.submissionId}/${fileName}`, {updated_by : this.context.userSystemId});
                        this.setState({
                            successFile: true,
                            fileMessage: "File deleted!",
                            
                            showLoader : false
                        })
                    
                    }
                    catch (err) {
                        this.setState({ 
                            showLoader : false,
                            errorModal: true });
                    } finally {
                        this.setState({ historyRefresh: true })
                    }

                }
            });


    }
    // downloadTemplate=()=>{
    //     console.log("Clicked Template");
    //     this.setState({
    //         templateFlag:true
    //     })
    // }
    getErrorDescriptions=()=>{

        let {error,proposallist}=this.state;
        console.log("error1",error)
        let allFields=[...this.inputFieldsArrayNCRI,
            ...this.inputFieldsArrayStudyCompound,
            ...this.inputFieldsArrayFinancial,
            ...this.inputFieldsArrayOtherInfo,
            ...this.inputFieldsArrayClinical,
            ...this.inputFieldsArrayInformation,...this.inputFieldsArrayProposalIIR,...this.inputFieldsArrayProposal_Study];
    
        let messages = [];
    
        for (let [key, value] of Object.entries(error)) {
    
            let exist = allFields.find(p => p.name === key);
    
            if (exist) {
    
                let messageText=exist.label;
                if(error["lengthValidation"][key]){
                    messageText=messageText+' - Maximum Length should be less than '+proposallist[key]+" chars"
                }
                messages.push(messageText);
    
            }
    
        }
    
        return <ul>
    
            {messages&&messages.map((item,index)=>{
    
                return <li>{item}</li>
    
            })}
    
        </ul>}
    downloadTemplate = async() => {
        // console.log("radio click",nr)
        // this.setState({
        //   radio: nr
        // });
        // https://6gah8x5fyc.execute-api.us-east-2.amazonaws.com/dev/proposal/generate/<Submiisionid>/<pdflag>
      try {
            this.setState({
                showLoader : true,
                action : "download"
            })
            const res = await API.get(`/proposal/generate/${this.state.proposalDetails.submissionId}/${false}`,{
                responseType: 'blob',
              
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute('download',`Submission-${this.state.proposalDetails.submissionId}_ExCNR_Form.docx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                successFile: true,
                showLoader : false,
                fileMessage: "File Downloaded Successfully!"
            })
        } catch (e) {
            this.setState({
                errorFile: true,
                showLoader : false

            })}
            // else
            // console.log("fail")
      }
    downloadClinicalForm = async() => {
        try {
            this.setState({
                showLoader : true,
                action : "download"
            })
            const res = await API.get(`proposal/generateClinicalForm/${this.state.proposalDetails.submissionId}/${false}`,{
                responseType: 'blob',
              
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute('download',`Submission-${this.state.proposalDetails.submissionId}_Clinical_Form.docx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                successFile: true,
                showLoader : false,
                fileMessage: "File Downloaded Successfully!"
            })
        } catch (e) {
            this.setState({
                errorFile: true,
                showLoader : false

            })}
    }
    handleDownload = async (fileName) => {
        try {
            this.setState({
                showLoader : true,
                action : "download"
            })
            /*const res = await API.get(`/user/proposals3/download/getByid/Proposal/${this.state.proposalDetails.submissionId}/${fileName}`,{
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', decodeURIComponent(fileName));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                successFile: true,
                showLoader : false,
                fileMessage: "File Downloaded Successfully!"
            })*/
            let fileUrl = `${this.state.externalurl}/filedownload/Proposal/${this.state.proposalDetails.submissionId}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                       
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorFile: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        successFile: true,
                        fileMessage: "File Downloaded Successfully!",
                        showLoader :  false
                    })
                });
        } catch (e) {
            this.setState({
                errorFile: true,
                showLoader : false

            })
            console.log(e);
        }
    }
    handlePrint=()=>{
        this.setState({isPrintMode:true},this.doPrint)
    }
    doPrint=()=>{
        let waitForCompletion=window.print();
        this.setState({isPrintMode:false})
    }
    handleProposalDelete = async () => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`recycle/proposal/delete/${this.state.proposalDetails.id}/${this.props.submissionId}`,{system_id : this.context.userSystemId});
                       this.setState({proposalDetails:{}});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getProposaltDetails (this.props.submissionId)
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getProposaltDetails (this.props.submissionId)
                }
            });
    }
    handleBackClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth' })}
      handleStartClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth',block:'start' })}
    render() {
                    let {fileInfo, showLoaderUpload  ,loadTable,  action ,  showLoader } = this.state;
                   
                    let _isCoordinator=this.state.isCoordinator;


            return(<>
                {showLoader && <LoaderEx />}
                <Accordion ref={this.titleRef} className="border rounded mb-2 shadow-sm">
                    <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}} >

                        <Accordion.Toggle  as={Card.Header} eventKey="0"  >
                        <Row>
                        <Col sm={8}><div className="flex-grow-1 font-weight-bold ">Proposal Details</div>
                        </Col>
                        <Col sm={4} className="text-right d-print-none">
                            <div className="d-flex justify-content-end align-items-center"> {_isCoordinator && this.state.access.edit && this.state.proposalDetails.submissionId && <IIRBtn.default className="d-flex align-items-center justify-content-center p-2 mr-2 " style="success w-100px" type="submit" onClick={(e)=>{this.submitForm(); e.preventDefault(); e.stopPropagation()}} disabled={!this.state.hasChanged}>
                                <span>SAVE</span>
                            </IIRBtn.default>}
                                {!this.state.proposalDetails.submissionId && _isCoordinator && <div className="d-flex justify-content-end align-items-center">

                                    <DTAButton onClick={(e) => {this.handleFormEvents(this.formType.INITIATE_INSTRUCTION); e.preventDefault(); e.stopPropagation()}} style={{width:'230px'}} icon="ADD" text={"Add Proposal Details"}></DTAButton>
                                </div>}
                                
                                {this.state.proposalDetails.submissionId && _isCoordinator && <div>
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                            <span className="material-icons mr-2">
                                                more_vert
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="bg-dark text-white" >
                                            <Dropdown.Item><div onClick={e=>{
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.handlePrint();
                                                }} className="text-white">Print</div>
                                            </Dropdown.Item>
                                            <Dropdown.Item> <div onClick={(e)=>{
                                                e.preventDefault(); 
                                                e.stopPropagation();
                                                this.downloadTemplate();
                                                }} className="text-white">Download ExCNR Form</div>
                                            </Dropdown.Item>
                                            <Dropdown.Item> <div onClick={(e)=>{
                                                e.preventDefault(); e.stopPropagation();
                                                this.downloadClinicalForm(); 
                                                }} className="text-white">Download Clinical Form</div>
                                            </Dropdown.Item>
                                            <Dropdown.Item> <div onClick={(e) => {
                                                e.preventDefault(); e.stopPropagation();
                                                this.handleProposalDelete(); }} className="text-white">Delete</div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>}

                            </div>
                        </Col></Row>
                    {/* <span className="alert-line"></span> */}
                    
                        </Accordion.Toggle>
                        {this.state.activeForm===this.formType.INITIATE_INSTRUCTION && <NewProposalEx  submissionId={this.props.submissionId} show={true} onClose={this.handleModalCallBack}></NewProposalEx>}
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            {this.state.proposalDetails.submissionId&& <div> <Row>{this.renderInputFieldsInformation()}</Row>
                <span className="alert-line"></span>
                <div className="flex-grow-1 font-weight-bold mb-3">Proposal:IIR Application</div>
                <Row>{this.renderInputFieldsProposalIIR()}</Row>
                <span className="alert-line"></span>
                <div className="flex-grow-1 font-weight-bold mb-3"> Proposal: Study Description</div>
               <Row>{this.renderInputFieldsProposal_Study()}</Row> 
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3"> Non Clinical Research Info</div>
                <Row> {this.renderInputFieldsNCRI()}</Row>
                <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3">  Clinical Research Info</div>
                <Row> {this.renderInputFieldsClinical()}</Row>
                <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3"> Study Compound Support</div>
               <Row> {this.renderInputFieldsStudyCompound()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3"> Financial Support</div>
               <Row> {this.renderInputFieldsFinancial()}</Row>
               <span className="alert-line"></span>
               <div className="flex-grow-1 font-weight-bold mb-3"  > Other Information</div>
               
               <Row> {this.renderInputFieldsOtherInfo()}</Row>
               <span className="alert-line"></span></div>}

               {this.state.proposalDetails.submissionId&&  <span className="flex-grow-1 font-weight-bold mb-3">File Upload</span>}
{this.state.proposalDetails.submissionId&& <Row>
    <Col sm={12}><div  >
               <Row className="mt-4 hidePrint" >
                    <Col sm={12}>
                        
                           
                            {this.state.access.edit && <Row>
                                <Col sm={8}>
                                <div className="ml-3 position-relative">
                                    <Form.File
                                        id="custom-file"
                                        className="custom-file-label ml-0"
                                        onChange={this.onFileChange}
                                        label={this.state.selectedFile?.name}
                                        custom
                                    />
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    {showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </Button>}
                                    {!showLoaderUpload && <Button variant="secondary" onClick={()=> uploadFile(this.state.selectedFile,this.state.fileList,this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>}
                                </Col>
                            </Row>}
                            {this.state.access.edit && <small className="form-text text-muted pl-3">*Maximum size for file upload is 2MB.</small>}
                            <br />
                            {showLoader &&
                                <Row>
                                    <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                                        <span className="visually-hidden">File {action} in progress </span>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </div>

                                </Row>}
                            {this.state.fileList.length > 0 && loadTable && 
                                <Row> <Col sm={12} className='p-4 w-100'><FileComponentEx data={this.state.fileList}
                                    accessDelete={this.state.access.delete}

                                    handleDelete={this.handleDelete}
                                    handleDownload={this.handleDownload} /></Col></Row>
                            }
                             <span className="alert-line"></span>

                        
                    </Col>
                </Row>
                </div></Col></Row>}
                {this.state.proposalDetails&&this.state.proposalDetails.id&&this.state.historyRefresh&&<Row className="mt-4">
                    <Col sm={12}>
                            <span className="flex-grow-1 font-weight-bold">History</span>
                            <SwitchHistory 
                            object_type={OBJ_TYPES.PROPOSAL} 
                            object_id={this.state.proposalDetails.id} 
                            /> 
                    </Col>
                </Row>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    {/* //accordian2 */}
                    {/* <Card style={{"margin-bottom":" 0! important","box-shadow":"none"}}>
                        
                        <Accordion.Toggle as={Card.Header} eventKey="1" >
                            Primary Investigator
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <PrimaryInvestigator submissionDetails={submissionDetails} ref={this.childPrimary} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card> */}
                </Accordion>
                <DiagnosticDetailsEx submissionId={this.props.submissionId} />
                <TranslationalDetailsEx submissionId={this.props.submissionId} />
                
            
            
            <div ><div className="new-flow-modal">
                <br/>
               {/* <div className="w-100 d-flex justify-content-center">
                        {this.state.access.edit && <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                            Save
                        </Button>}
                    </div> */}
                    {/* <DatatTableEx
                    pageIcons={[<i className="bi bi-plus"></i>]}
                    /> */}
                    {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Proposal has been Updated!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body={<><div>An error occured! Please try again later!</div>{this.getErrorDescriptions()}</>}
                       
                        changeShowState={this.changeShowState}
                    />
                }
                 {this.state.successFile &&
                    <BootstrapModal
                        show={this.state.successFile}
                        header="Success"
                        body={this.state.fileMessage}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorFile &&
                    <BootstrapModal
                        show={this.state.errorFile}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
           
                </div></div></>
           )
            
        
    }
}

