import React, { Component } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import {isCoordinator, showMessage ,userPermissions } from '../../ToReviewDetails/commonFunctions';
import NewProtocolEx from '../../NewProtocolEx';
import FileComponentEx from '../../common/file-component/FileComponentEx';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { CLINICAL_TRIAL_PHASE, DOSING_DSCHEDULE } from '../../../consts';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Loader from '../../common/loader/Loader';
import { PROTOCOL_UPDATE } from '../../../mapping';
import UserContext from '../../../context/UserContext';
import History from './section-2/History';
import FileComponent from '../../common/file-component/FileComponent';
import { getUserInfo, getDateDifferenceMonth, dateTimeformat, uploadFile, dateStringformat, dateChange,getPrintViewTextBox } from '../../../utility/helperFunctions';
import swal from 'sweetalert';
import ReviewFile from './section-1/ReviewFile';
import { findAllByTestId } from '@testing-library/react';
import '../../common/pdf.css'
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import * as IIRBtn  from '../../common/Button';
import DTAButton from '../../common/Button/DTAButton';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div     
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
export default class ProtocolDetail extends Component {
    static contextType = UserContext;
    titleRef = React.createRef();
    state = {
        externalurl :  "http://localhost:1337",
        showLoader: false,
        showLoaderUpload: false,
        action: "",
        loadTable: true,

        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        protocolSubmitted: false,
        createdByID:"",
        lastModifiedBy: "",
        submissionId: "",
        investigatorName: "",
        last_updated_by: "",
        protocolTitle: "",
        iACUCDocumentationAttached: "None",
        descriptionOfAnimalUsed: "",
        dosesConcentrations: "",
        aCAndUCommitteeApproval: "None",
        endpoints: "",
        modelMethodology: "",
        targetStartDate: null,
        statisticalAnalysis: "",
        reportSubmissionDate: null,
        humanSamplesInvolved: "None",
        publicationDateAnticipated: null,
        typeOfHumanSamples: "None",
        projectedMilestoneTiming: "",
        uniquePersonalClinicalSamples: "None",
        biobankOwner: "None",
        sampleSize: "",
        dateOfFirstPatientVisit: null,
        numberOfTherapyGroups: "",
        dateFirstPtEntersTreatment: null,
        anticipatedQuarterlyEnrollment: "",
        dateLastPatientEntersTrial: null,
        numberOfinvestigativeSite: "",
        dateLastPatientEntersTreatment: null,
        potentialCountries: "",
        dateOfLastPatientVisit: null,
        multiCountryTrial:false,
        dateOfReportSubmission: null,
        clinicalTrialPhase: [],
        includePreClinicalPortion: false,
        dateOfPublicationSubmission: null,
        assignmentToTreatment: [],
        leadInPeriod: [],
        design: [],
        blinding: [],
        controls: [],
        blindingDoneBy: "None",
        extension: [],
        patientType: [],
        extensionLength: "",
        patientGender: [],
        ageRangeStart: "",
        patientTreatmentPeriod: "",
        // studyOnOffLabel: "",
        ageRangeEnd: "",
        // LineOfTherapy: "",
        lillyToSupplyStudyCompound: "None",
        ifNoWhy: "None",
        lillyStudyCompound1: "",
        lillyStudyCompound1Other: "",
        compound1AvailableMaterial: "",
        compound1AvailableMaterialOther: "",
        compound1Formulation: "",
        compound1DosingSchedule: "",
        compound1DosingSchedulOther: "",
        compound1AdministeredDose: "",
        // compound1AmountQuantity: "",
        requestedAmtOfComp1PreClin: "",
        requireSecondLillyCompound: "",
        requestedAmtOfComp5PreClin: "",
        lillyStudyCompound2: "",
        requireSixthLillyCompound: "None",
        lillyStudyCompound2Other: "",
        compound2AvailableMaterial: "",
        compound2AvailableMaterialOther: "",
        compound2Formulation: "",
        compound2DosingSchedule: "None",
        compound2DosingScheduleOther: "",
        compound2AdministeredDose: "",
        // compound2AmountQuantity: "",
        requestedAmtOfComp2PreClin: "",
        requireThirdLillyCompound: "",
        requestedAmtOfComp6PreClin: "",
        lillyStudyCompound3: "",
        requireSeventhLillyCompound: "None",
        lillyStudyCompound3Other: "",
        compound3AvailableMaterial: "",
        compound3AvailableMaterialOther: "",
        compound3Formulation: "",
        compound3DosingSchedule: "None",
        compound3DosingScheduleOther: "",
        compound3AdministeredDose: "",
        // compound3AmountQuantity: "",
        requestedAmtOfComp3PreClin: "",
        requireFourthLillyCompound: "None",
        lillyStudyCompound4: "",
        lillyStudyCompound4Other: "",
        compound4AvailableMaterial: "",
        compound4AvailableMaterialOther: "",
        compound4Formulation: "",
        compound4DosingSchedule: "None",
        compound4DosingScheduleOther: "",
        compound4AdministeredDose: "",
        // compound4AmountQuantity: "",
        requestedAmtOfComp4PreClin: "",
        requestingFunding: "None",
        nonLillySupport: "None",
        amountOfFunds: "",
        nameOfCompany: "",
        currency: "",
        typeOfSupport: "",
        hcpProtocolTitle: "",
        feedback: "",
        totalStudyLength:"",
        enrollmentPeriod:"",
        protocolComments: "",
        budgetOfficeContactName: "",
        contractContactName: "",
        budgetOfficeStreetAddress: "",
        contractContactStreetAddress: "",
        budgetOfficeCity: "",
        contractContactCity: "",
        budgetOfficeEmail: "",
        contractContactEmail: "",
        budgetOfficePhoneNumber: "",
        contractContactPhoneNumber: "",
        pharmacyContactName: "",
        pharmacyContactCity: "",
        pharmacyContactEmail: "",
        pharmacyContactPhoneNumber: "",
        pharmacyContactStreetAddress: "",
        protocolNotes:"",
        compoundList: [],
        lillyStudyCompound1MaterialList: [],
        lillyStudyCompound2MaterialList: [],
        lillyStudyCompound3MaterialList: [],
        lillyStudyCompound4MaterialList: [],
        successFile: false,
        fileMessage : "",
        errorFile: false,
        successModal: false,
        errorModal: false,
        protocolDetails: {},
        fileList: [],
        patchObject: { },
        selectedFile: null
    }


   
    get inputFieldsArrayInformation() {
        return[
            {
                label: "Protocol Submitted",
                type: "checkbox",
                name: "protocolSubmitted"
            },
           
         
             {
                label: "Created By",
                type: "text",
                name: "createdByID",
                className:"createUpdateByfontsize",
                disabled: true
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "lastModifiedBy",
                className:"createUpdateByfontsize",
                disabled: true
            },
            {
                label: "Submission",
                type: "text",
                placeholder: "Select Submission",
                name: "submissionId",
                disabled: true
            },
            {
                label: "Protocol Notes",
                type: "text",
               
                name: "protocolNotes"
            },
            {
                label: "Investigator Name",
                type: "text",
                name: "investigatorName",
                disabled: true
            },
           
            ]
    }
    get inputFieldsArrayProposalIIR() {
        return [
           
            {
                label: "[EXT] HCP Protocol Title",
                type: "textarea",
                name: "protocolTitle"
            },
            {
                label: "Protocol Comments",
                type: "textarea",
                name: "protocolComments",
                
            },
        ]}
    
    get inputFieldsArrayNCRI() { 
        return[
            {
                label: "[EXT] IACUC Documentation attached?",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "iACUCDocumentationAttached",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
            },
            {
                label: "[EXT] Description of Animal Used",
                type: "textarea",
                name: "descriptionOfAnimalUsed"
            },
            {
                label: "[EXT] Doses/Concentrations",
                type: "textarea",
                name: "dosesConcentrations"
            },
            {
                label: "[EXT] AC&U Committee approval?",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "aCAndUCommitteeApproval",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
            },
            {
                label: "[EXT] Endpoints",
                type: "text",
                placeholder: "Enter Endpoints",
                name: "endpoints"
            },
            {
                label: "[EXT] Model/Methodology",
                type: "textarea",
                name: "modelMethodology"
            },
            {
                label: "[EXT] Target Start Date",
                type: "date",
                name: "targetStartDate"
            },
            {
                label: "[EXT] Statistical Analysis",
                type: "textarea",
                name: "statisticalAnalysis"
            },
            {
                label: "[EXT] Report Submission Date",
                type: "date",
                name: "reportSubmissionDate"
            },
            {
                label: "[EXT] Human samples involved",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "humanSamplesInvolved",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
            },
            {
                label: "[EXT] Publication Date Anticipated",
                type: "date",
                name: "publicationDateAnticipated"
            },
            {
                label: "[EXT] Type of Human Samples",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "typeOfHumanSamples",
                options: [
                    "None",
                    "Cells From Biobank",
                    "Study Specific"
                ]
            },
            {
                label: "[EXT] Projected Milestone Timing",
                type: "text",
                placeholder: "Enter Projected Milestone Timing",
                name: "projectedMilestoneTiming"
            },
            {
                label: "[EXT] Unique, Personal, Clinical samples",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "uniquePersonalClinicalSamples",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
            },
            {
                label: "[EXT] Biobank owner",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "biobankOwner",
                options: [
                    "None",
                    "Institution",
                    "Third Party"
                ]
            },]}
    get inputFieldsArrayClinical() {
        return [ 
            {
                label: "[EXT] Sample Size",
                type: "text",
                placeholder: "Enter Sample Size",
                name: "sampleSize",
                helptext:"Screen failures are not included in enrollment estimations",
            },
            {
                label: "[EXT] Date of First Patient Visit",
                type: "date",
                name: "dateOfFirstPatientVisit",
                helptext:"Anticipated date",
            },
            {
                label: "[EXT] Number of Therapy Groups",
                type: "text",
                placeholder: "Enter Number of Therapy Groups",
                name: "numberOfTherapyGroups"
            },
            {
                label: "[EXT] Date First Pt Enters Treatment",
                type: "date",
                name: "dateFirstPtEntersTreatment",
                helptext:"Anticipated date of the first pateint entering treatment"
            },
            {
                label: "[EXT] Anticipated quarterly enrollment",
                type: "text",
                placeholder: "Enter Anticipated quarterly enrollment",
                name: "anticipatedQuarterlyEnrollment",
                helptext:"Screen failures are not included in enrollment estimations "
            },
            {
                label: "[EXT] Date Last Patient Enters Trial",
                type: "date",
                name: "dateLastPatientEntersTrial",
                helptext:"Anticipated date the last patient enters the trial",
            },
            // ********
            {
                label: "[EXT] Number of Investigative Sites",
                type: "text",
                placeholder: "Enter Number of Investigative Sites",
                name: "numberOfinvestigativeSite"
            },
            {
                label: "[EXT] Date Last Patient Enters Treatment",
                type: "date",
                name: "dateLastPatientEntersTreatment",
                helptext:"Anticipated date the last patient enters the treatment"
            },
            {
                label: "[EXT] Potential Countries",
                type: "text",
                name: "potentialCountries"
            },
            {
                label: "[EXT] Date of Last Patient Visit",
                type: "date",
                name: "dateOfLastPatientVisit",
                helptext:"Anticipated date of the last patient visit"
            },
            // multi
            {
                label: "Multi-Country Trial",
                type: "checkbox",
                name: "multiCountryTrial"
            },
            {
                label: "[EXT] Date of Report Submission",
                type: "date",
                name: "dateOfReportSubmission",
                helptext:"Anticipated date the final report will be submitted to Lilly "
            },
            {
                label: "[EXT] Clinical Trial Phase",
                type: "multi-select",
                placeholder: "Select Clinical Trial Phase",
                name: "clinicalTrialPhase",
                options: [
                    {
                        label: "I",
                        value: "I"
                    },
                    {
                        label: "II",
                        value: "II"
                    },
                    {
                        label: "III",
                        value: "III"
                    },
                    {
                        label: "IV",
                        value: "IV"
                    },
                ],
            },
            {
                label: "[EXT] Date of Publication Submission",
                type: "date",
                name: "dateOfPublicationSubmission"
            },
            {
                label: "[EXT] Include PreClinical Portion?",
                type: "checkbox",
                name: "includePreClinicalPortion"
            },
            {
                label: "[EXT] Lead-in Period",
                type: "multi-select",
                placeholder: "Select Lead-in Period",
                name: "leadInPeriod",
                options: [
                    { value: "None", label: "None" },
                    { value: "Placebo", label: "Placebo" },
                    { value: "Previous Therapy", label: "Previous Therapy" },
                    { value: "Previous Therapy Failure", label: "Previous Therapy Failure" }
                ],
                helptext:"None,Placebo,Previous Therapy,Previous Therapy Failure"
            },
            {
                label: "Total Study Length (In Months)",
                type: "text",
                name: "totalStudyLength",
                disabled: true
            },
            {
                label: "[EXT] Blinding",
                type: "multi-select",
                placeholder: "Select Blinding",
                name: "blinding",
                options: [
                    { value: "Open Label", label: "Open Label" },
                    { value: "Single-blind", label: "Single-blind" },
                    { value: "Double-blind", label: "Double-blind" },
                ],
                helptext:"Open Label,Single-blind,Double-blind"
            },
            {
                label: "Enrollment Period (In Months)",
                type: "text",
                name: "enrollmentPeriod",
                disabled: true
            },
            {
                label: "[EXT] Blinding Done By",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "blindingDoneBy",
                options: [
                    "None",
                    "Lilly",
                    "Site",
                    "Not Applicable"
                ],
                helptext:"Lilly,Site,Not Applicable"
            },
            {
                label: "[EXT] Assignment to Treatment",
                type: "multi-select",
                placeholder: "Select Assignment to Treatment",
                name: "assignmentToTreatment",
                options: [
                    { value: "Randomized", label: "Randomized" },
                    { value: "Non-Randomized", label: "Non-Randomized" },
                    { value: "Stratified", label: "Stratified" }
                ],
                helptext:"Randomized,Non-Randomized,Stratified"
            },
           
            {
                label: "[EXT] Patient Type",
                type: "multi-select",
                placeholder: "Select Patient Type",
                name: "patientType",
                options: [
                    { value: "Inpatients", label: "Inpatients" },
                    { value: "Outpatients", label: "Outpatients" },
                    { value: "Normal Volunteer", label: "Normal Volunteer" }
                ],
                helptext:"Inpatients,Outpatients,Normal Volunteer"
            },
            ///////////////////
            {
                label: "[EXT] Design",
                type: "multi-select",
                placeholder: "Select Design",
                name: "design",
                options: [
                    { value: "Uncontrolled", label: "Uncontrolled" },
                    { value: "Parallel", label: "Parallel" },
                    { value: "Crossover", label: "Crossover" },
                    { value: "Length of Washouts", label: "Length of Washout" }
                ],
                helptext:"Uncontrolled,Parallel,Crossover,Length of Washouts"
            },
          
            {
                label: "[EXT] Patient gender",
                type: "multi-select",
                placeholder: "Select Patient gender",
                name: "patientGender",
                options: [
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                    { value: "Both", label: "Both" }
                ],
            },
            {
                label: "[EXT] Controls",
                type: "multi-select",
                placeholder: "Select Controls",
                name: "controls",
                options: [
                    { value: "Active", label: "Active" },
                    { value: "Placebo", label: "Placebo" },
                    { value: "Both", label: "Both" },
                    { value: "None", label: "None" },
                ],
                helptext:"Active,Placebo,Both,None"
            },
            {
                label: "[EXT] Patient Treatment Period",
                type: "text",
                placeholder: "Enter Patient Treatment Period",
                name: "patientTreatmentPeriod",
                helptext:"Please define the treatment period of the average patient in months, please indicate if it will not be a full month (e.g. 2.5)"
            },
            {
                label: "[EXT] Extension",
                type: "multi-select",
                placeholder: "Select Extension",
                name: "extension",
                options: [
                    { value: "Open Label", label: "Open Label" },
                    { value: "Double Blind", label: "Double Blind" },
                    { value: "None", label: "None" },
                    { value: "length", label: "length" }
                ],
            },
          
            {
                label: "[EXT] Extension Length",
                type: "text",
                placeholder: "Enter Extension Length",
                name: "extensionLength"
            },
           
            {
                label: "[EXT] Age Range Start",
                type: "text",
                placeholder: "Enter Age Range Start",
                name: "ageRangeStart"
            },
           
            // {
            //     label: "[EXT] Study On/Off Label",
            //     type: "dropdown",
            //     placeholder: "Select any Option",
            //     name: "studyOnOffLabel",
            //     options: [
            //         "None",
            //         "Off Label",
            //         "On Label"
            //     ]
            // },
            {
                label: "[EXT] Age Range End",
                type: "text",
                placeholder: "Enter Age Range End",
                name: "ageRangeEnd"
            },]
         // {
            //     label: "[EXT] Line of Therapy",
            //     type: "dropdown",
            //     placeholder: "Select any Option",
            //     name: "LineOfTherapy",
            //     options: [
            //         "None",
            //         "1st Line",
            //         "2nd Line",
            //         "3rd Line",
            //         "Adjuvant",
            //         "Mainteinance",
            //         "Metastatic",
            //         "Neoadjuvant",
            //         "Other",
            //         "Perioperative"
            //     ]
            // },]
    }
    
    get inputFieldsArrayStudyCompound() {
        return [  {
            label: "[EXT] Lilly to Supply Study Compound",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "lillyToSupplyStudyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] If No, why?
        {
            label: "[EXT] If No, why?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "ifNoWhy",
            options: [
                "None",
                "The Study doesn't involve an intervention",
                "Local laws/regulations prohibit it",
                "Third-party support is being provided"
            ]
        },

        //[EXT] Lilly Study Compound 1	
        {
            label: "[EXT] Lilly Study Compound 1",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 1",
            name: "lillyStudyCompound1",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] require fifth Lilly compound?

        //[EXT] Lilly Study Compound 1 Other
        {
            label: "[EXT] Lilly Study Compound 1 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 1 Other",
            name: "lillyStudyCompound1Other"
        },
        //[EXT] Lilly Study Compound 5

        //[EXT] Compound 1 Available Material
        {
            label: "[EXT] Compound 1 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 1 Available Material",
            name: "compound1AvailableMaterial",
            options: this.state.lillyStudyCompound1MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Lilly Study Compound 5 Other

        //[EXT]Compound 1 Available Material Other
        {
            label: "[EXT]Compound 1 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 1 Available Material Other",
            name: "compound1AvailableMaterialOther"
        },
        //[EXT] Compound 5 Available Material	

        //[EXT] Compound 1 Formulation	
        {
            label: "[EXT] Compound 1 Formulation",
            type: "text",
            placeholder: "Enter Compound 1 Formulation",
            name: "compound1Formulation"
        },
        //[EXT]Compound 5 Available Material Other	

        //[EXT] Compound 1 dosing Schedule
        {
            label: "[EXT] Compound 1 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 1 dosing Schedule",
            name: "compound1DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 5 Formulation	

        //[EXT] Compound 1 dosing Schedule Other
        {
            label: "[EXT] Compound 1 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 1 dosing Schedule Other",
            name: "compound1DosingSchedulOther"
        },
        //[EXT] Compound 5 dosing Schedule

        //[EXT] Compound 1 Administered Dose
        {
            label: "[EXT] Compound 1 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 1 Administered Dose",
            name: "compound1AdministeredDose"
        },
        //[EXT] Compound 5 dosing Schedule Other

        //[EXT] Compound 1 Amount (Quantity)
        // {
        //     label: "[EXT] Compound 1 Amount (Quantity)",
        //     type: "text",
        //     placeholder: "Enter Compound 1 Amount (Quantity)",
        //     name: "compound1AmountQuantity"
        // },
        //[EXT] Compound 5 Administered Dose	

        //[EXT] Requested amt of comp 1 (PreClin)	
        {
            label: "[EXT] Requested amt of comp 1 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 1 (PreClin)",
            name: "requestedAmtOfComp1PreClin",
            helptext:"Please designate the amount of compound that you need for this study",
        },
        //[EXT] Compound 5 Amount (Quantity)

        //[EXT] require second Lilly compound?	
        {
            label: "[EXT] require second Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireSecondLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Requested amt of comp 5 (PreClin)
        // {
        //     label: "[EXT] Requested amt of comp 5 (PreClin)",
        //     type: "text",
        //     placeholder: "Enter Requested amt of comp 5 (PreClin)",
        //     name: "requestedAmtOfComp5PreClin"
        // },
        //[EXT] Lilly Study Compound 2	
        {
            label: "[EXT] Lilly Study Compound 2",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 2",
            name: "lillyStudyCompound2",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] require sixth Lilly compound?	

        //[EXT] Lilly Study Compound 2 Other
        {
            label: "[EXT] Lilly Study Compound 2 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 2 Other",
            name: "lillyStudyCompound2Other"
        },
        //[EXT] Lilly Study Compound 6

        //[EXT] Compound 2 Available Material	
        {
            label: "[EXT] Compound 2 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 2 Available Material",
            name: "compound2AvailableMaterial",
            options: this.state.lillyStudyCompound2MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Lilly Study Compound 6 Other

        //[EXT]Compound 2 Available Material Other
        {
            label: "[EXT]Compound 2 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 2 Available Material Other",
            name: "compound2AvailableMaterialOther"
        },
        //[EXT] Compound 6 Available Material

        //[EXT] Compound 2 Formulation	
        {
            label: "[EXT] Compound 2 Formulation",
            type: "text",
            placeholder: "Enter Compound 2 Formulation",
            name: "compound2Formulation"
        },
        //[EXT]Compound 6 Available Material Other

        //[EXT] Compound 2 dosing Schedule
        {
            label: "[EXT] Compound 2 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 2 dosing Schedule",
            name: "compound2DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 6 Formulation

        //[EXT] Compound 2 dosing Schedule Other
        {
            label: "[EXT] Compound 2 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 2 dosing Schedule Other",
            name: "compound2DosingScheduleOther"
        },
        //[EXT] Compound 6 dosing Schedule

        //[EXT] Compound 2 Administered Dose
        {
            label: "[EXT] Compound 2 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 2 Administered Dose",
            name: "compound2AdministeredDose"
        },
        //[EXT] Compound 6 dosing Schedule Other

        //[EXT] Compound 2 Amount (Quantity)
        // {
        //     label: "[EXT] Compound 2 Amount (Quantity)",
        //     type: "text",
        //     placeholder: "Enter Compound 2 Amount (Quantity)",
        //     name: "compound2AmountQuantity"
        // },
        //[EXT] Compound 6 Administered Dose

        //[EXT] Requested amt of comp 2 (PreClin)
        {
            label: "[EXT] Requested amt of comp 2 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 2 (PreClin)",
            name: "requestedAmtOfComp2PreClin",
            helptext:"Please designate the amount of compound that you need for this study",
        },
        //[EXT] Compound 6 Amount (Quantity)

        //[EXT] require third Lilly compound?
        {
            label: "[EXT] require third Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireThirdLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Requested amt of comp 6 (PreClin)	

        //[EXT] Lilly Study Compound 3	
        {
            label: "[EXT] Lilly Study Compound 3",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 3",
            name: "lillyStudyCompound3",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] require seventh Lilly compound?	

        //[EXT] Lilly Study Compound 3 Other
        {
            label: "[EXT] Lilly Study Compound 3 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 3 Other",
            name: "lillyStudyCompound3Other"
        },
        //[EXT] Lilly Study Compound 7	

        //[EXT] Compound 3 Available Material
        {
            label: "[EXT] Compound 3 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 3 Available Material",
            name: "compound3AvailableMaterial",
            options: this.state.lillyStudyCompound3MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Lilly Study Compound 7 Other

        //[EXT]Compound 3 Available Material Other	
        {
            label: "[EXT]Compound 3 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 3 Available Material Other",
            name: "compound3AvailableMaterialOther"
        },
        //[EXT] Compound 7 Available Material

        //[EXT] Compound 3 Formulation
        {
            label: "[EXT] Compound 3 Formulation",
            type: "text",
            placeholder: "Enter Compound 3 Formulation",
            name: "compound3Formulation"
        },
        //[EXT] Compound 7 Formulation	

        //[EXT] Compound 3 dosing Schedule
        {
            label: "[EXT] Compound 3 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 3 dosing Schedule",
            name: "compound3DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT]Compound 7 Available Material Other

        //[EXT] Compound 3 dosing Schedule Other
        {
            label: "[EXT] Compound 3 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 3 dosing Schedule Other",
            name: "compound3DosingScheduleOther"
        },
        //[EXT] Compound 7 dosing Schedule

        //[EXT] Compound 3 Administered Dose
        {
            label: "[EXT] Compound 3 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 3 Administered Dose",
            name: "compound3AdministeredDose"
        },
        //[EXT] Compound 7 dosing Schedule Other

        //[EXT] Requested amt of comp 3 (PreClin)
        {
            label: "[EXT] Requested amt of comp 3 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 3 (PreClin)",
            name: "requestedAmtOfComp3PreClin",
            helptext:"Please designate the amount of compound that you need for this study",
        },
        //[EXT] Compound 7 Amount (Quantity)

        //[EXT] require fourth Lilly compound?
        {
            label: "[EXT] require fourth Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireFourthLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Requested amt of comp 7 (PreClin)

        //[EXT] Lilly Study Compound 4
        {
            label: "[EXT] Lilly Study Compound 4",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 4",
            name: "lillyStudyCompound4",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] Lilly Study Compound 4 Other
        {
            label: "[EXT] Lilly Study Compound 4 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 4 Other",
            name: "lillyStudyCompound4Other"
        },
        //[EXT] Compound 4 Available Material
        {
            label: "[EXT] Compound 4 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 4 Available Material",
            name: "compound4AvailableMaterial",
            options: this.state.lillyStudyCompound4MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT]Compound 4 Available Material Other
        {
            label: "[EXT]Compound 4 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 4 Available Material Other",
            name: "compound4AvailableMaterialOther"
        },
        //[EXT] Compound 4 Formulation	
        {
            label: "[EXT] Compound 4 Formulation",
            type: "text",
            placeholder: "Enter Compound 4 Formulation",
            name: "compound4Formulation"
        },
        //[EXT] Compound 4 dosing Schedule
        {
            label: "[EXT] Compound 4 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 4 dosing Schedule",
            name: "compound4DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 4 dosing Schedule Other
        {
            label: "[EXT] Compound 4 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 4 dosing Schedule Other",
            name: "compound4DosingScheduleOther"
        },
        //[EXT] Compound 4 Administered Dose
        {
            label: "[EXT] Compound 4 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 4 Administered Dose",
            name: "compound4AdministeredDose"
        },
        //[EXT] Compound 4 Amount (Quantity)

        //[EXT] Requested amt of comp 4 (PreClin)
        {
            label: "[EXT] Requested amt of comp 4 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 4 (PreClin)",
            name: "requestedAmtOfComp4PreClin",
            helptext:"Please designate the amount of compound that you need for this study",
        },
        /*****************************************************************************/
      ]
    }
    get inputFieldsArrayFinancial() {
        return [ 
            {
                label: "[EXT] Non-Lilly Support?",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "nonLillySupport",
                options: [
                    "None",
                    "Yes",
                    "No"
                ],
                helptext:"Is support being provided by another company?"
            },
    
            {
                label: "[EXT] Name of Company",
                type: "text",
                placeholder: "Enter Name of Company",
                name: "nameOfCompany"
            },
    
            {
                label: "[EXT] Type of Support",
                type: "text",
                placeholder: "Enter Type of Support",
                name: "typeOfSupport",
                helptext:"Grant,Drug,Both"
            },]
    }
    get inputFieldsArrayContactInfo() {
        return [
            {
                label: "Budget Office Contact Name",
                type: "text",
                placeholder: "Enter Budget Office Contact Name",
                name: "budgetOfficeContactName"
            },
            {
                label: "Contract Contact Name",
                type: "text",
                placeholder: "Enter Contract Contact Name",
                name: "contractContactName"
            },

            {
                label: "Budget Office Street Address",
                type: "text",
                placeholder: "Enter Budget Office Street Address",
                name: "budgetOfficeStreetAddress"
            },
            {
                label: "Contract Contact Street Address",
                type: "text",
                placeholder: "Enter Contract Contact Street Address",
                name: "contractContactStreetAddress"
            },

            {
                label: "Budget Office City",
                type: "text",
                placeholder: "Enter Budget Office City",
                name: "budgetOfficeCity"
            },
            {
                label: "Contract Contact City",
                type: "text",
                placeholder: "Enter Contract Contact City",
                name: "contractContactCity"
            },
            {
                label: "Budget Office Email",
                type: "text",
                placeholder: "Enter Budget Office Email",
                name: "budgetOfficeEmail"
            },
            {
                label: "Contract Contact Email",
                type: "text",
                placeholder: "Enter Contract Contact Email",
                name: "contractContactEmail"
            },
            {
                label: "Budget Office Phone Number",
                type: "text",
                placeholder: "Enter Budget Office Phone Number",
                name: "budgetOfficePhoneNumber"
            },
            {
                label: "Contract Contact Phone Number",
                type: "text",
                placeholder: "Enter Contract Contact Phone Number",
                name: "contractContactPhoneNumber"
            },
            {
                label: "Pharmacy Contact Name",
                type: "text",
                placeholder: "Enter Pharmacy Contact Name",
                name: "pharmacyContactName"
            },
            {
                label: "Pharmacy Contact City",
                type: "text",
                placeholder: "Enter Pharmacy Contact City",
                name: "pharmacyContactCity"
            },
            {
                label: "Pharmacy Contact Email",
                type: "text",
                placeholder: "Enter Pharmacy Contact Email",
                name: "pharmacyContactEmail"
            },
            {
                label: "Pharmacy Contact Phone Number",
                type: "text",
                placeholder: "Enter Pharmacy Contact Phone Number",
                name: "pharmacyContactPhoneNumber"
            },
            {
                label: "Pharmacy Contact Street Address",
                type: "text",
                placeholder: "Enter Pharmacy Contact Street Address",
                name: "pharmacyContactStreetAddress"
            }, ]
    }
        // get inputFieldsArrayFeedback() {
        //     return [
               
        //         /*********************************************/
                
        //         /*********************************************/
                
        //         /*********************************************/
               
        //         /*********************************************/
                
        //         /*************************************************************************/
               
        //         /*****************************************************************************/
               
        //         /*****************************************************************************/
               
                
        //         {
        //             label: "Lilly Feedback (Investigator Visible)",
        //             type: "textarea",
        //             name: "feedback",
    
        //         },
        //     ];
        // }
    
        async getExternalUrl() {
            let response ='';
            try {
                 response = await API.get(`user/externalurl`);
            }
            catch (err) {
                console.log(err);
            }
            this.setState({
            externalurl : (response && response.data) ? response.data.exturl : "http://localhost:1337",
        })
        }
        formType={
            INITIATE_INSTRUCTION:'INITIATE_INSTRUCTION'
        }
    async componentDidMount() {
        let res=await API.get('reviewConcept/schemaInformation/protocols')

        console.log(res)

        this.setState({

            proposallist:res.data

        })
        await this.getExternalUrl();
        let _isCoordinator=isCoordinator(this.context);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            showLoader : true,
            isCoordinator:_isCoordinator,
            loadTable : true ,
            action : "fetching",
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                delete:selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator"),
            },
        });
        await this.getCompoundList();
        this.getProtocolDetails(this.props.submissionId);
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let _isCoordinator=isCoordinator(this.context);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                isCoordinator:_isCoordinator,
                access: {
                    view: true,
                    create: selectedRole.includes("global_coordinator")  ||  selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                    delete:selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

// getCalculatedFields = async () => {
//     if(this.state.dateOfFirstPatientVisit>this.state.publicationDateAnticipated,(month(this.state.dateOfFirstPatientVisit) +
//  (12*(year(this.state.dateOfFirstPatientVisit)-year(this.state.publicationDateAnticipated))) - month(this.state.publicationDateAnticipated)),
//  (month(this.state.publicationDateAnticipated) +
//     (12*(year(this.state.publicationDateAnticipated)-year(this.state.dateOfFirstPatientVisit))) - month(this.state.dateOfFirstPatientVisit)))
//     {
//         this.state.totalStudyLength=this.state.totalStudyLength;
//     }
// }
// getEnrollmentField = async () =>{
//     if((this.state.dateOfFirstPatientVisit>this.state.dateLastPatientEntersTrial,(month(this.state.dateOfFirstPatientVisit) +
//    (12*(year(this.state.dateOfFirstPatientVisit)-year(this.state.dateLastPatientEntersTrial))) - month(this.state.dateLastPatientEntersTrial)),
//    (month(this.state.dateLastPatientEntersTrial) +
//    (12*(year(this.state.dateLastPatientEntersTrial)-year(this.state.dateOfFirstPatientVisit))) - month(this.state.dateOfFirstPatientVisit)))) 
// {
//     this.state.enrollmentPeriod=this.state.enrollmentPeriod;
// }
// }
 

    getProtocolDetails = async () => {
        const submissionId = this.props.submissionId;
        if (submissionId) {
            const res = await API.post(`/reviewsTab/review/detail/getBySubmissionId`,  {"review_type": "protocol",
            "submission_id":submissionId });
            this.setState({
                protocolDetails: res.data,
                patchObject:{"id":res.data.id}
            }, this.setProtocolFields);
          await  this.getFilesList(res.data.submissionId)
        }
    }

    getFilesList = async (submissionId) => {
        try{
            this.setState({
                showLoader: true,
                action: "fetching",
                loadTable: false
            })

            const res = await API.get(`/user/proposals3/getByid/${submissionId}`);
            this.setState({
                loadTable : true ,
                showLoader : false,
                fileList: res.data?.filter(file => file.type === "Protocol")
            });
        }catch(e){
            console.log(e)
            this.setState({showLoader : false})
        }
    }

    getHtmlFromEditorValue = (val = "") => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }

    getEditorValueFromHtml = (html="") => {
        if(html){
            const blocksFromHTML = convertFromHTML(html);        
            const state = ContentState.createFromBlockArray(blocksFromHTML);
            return EditorState.createWithContent(state);
        }
        return "";
    }

    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            hasChanged: true,
            patchObject: { ...this.state.patchObject, [PROTOCOL_UPDATE[e]]: val },
        });
    };

    setProtocolFields = async () => {
        this.setState({
            createdByID: await getUserInfo(this.state.protocolDetails.createdByID)  +"  "+ dateTimeformat(this.state.protocolDetails.createdAt) || "",
            lastModifiedBy:  this.state.protocolDetails.last_modified_by?.length>0 ? await getUserInfo(this.state.protocolDetails.last_modified_by) +"  "+ dateTimeformat(this.state.protocolDetails.updatedAt) : await getUserInfo(this.state.protocolDetails.createdByID) +"  "+ dateTimeformat(this.state.protocolDetails.createdAt),
            submissionId: "Submission-" + this.state.protocolDetails.submissionId || "",
            investigatorName: this.state.protocolDetails.investigator_name || "",
            last_updated_by:  this.state.protocolDetails.last_updated_by || "",
            protocolSubmitted: this.state.protocolDetails.protocolSubmitted || false,
           
            protocolNotes:this.state.protocolDetails.protocolNotes || "",
            protocolTitle: this.state.protocolDetails.protocolTitle || "",
           
            iACUCDocumentationAttached: this.state.protocolDetails.iACUCDocumentationAttached,
            descriptionOfAnimalUsed: this.state.protocolDetails.descriptionOfAnimalUsed,
            dosesConcentrations: this.state.protocolDetails.dosesConcentrations,
            aCAndUCommitteeApproval: this.state.protocolDetails.aCAndUCommitteeApproval,
            endpoints: this.state.protocolDetails.endpoints,
            modelMethodology: this.state.protocolDetails.modelMethodology,
            targetStartDate: this.state.protocolDetails.targetStartDate ? dateStringformat(this.state.protocolDetails.targetStartDate) : null,
            statisticalAnalysis: this.state.protocolDetails.statisticalAnalysis,
            reportSubmissionDate: this.state.protocolDetails.reportSubmissionDate ? dateStringformat(this.state.protocolDetails.reportSubmissionDate) : null,
            humanSamplesInvolved: this.state.protocolDetails.humanSamplesInvolved,
            publicationDateAnticipated: this.state.protocolDetails.publicationDateAnticipated ? dateStringformat(this.state.protocolDetails.publicationDateAnticipated) : null,
            typeOfHumanSamples: this.state.protocolDetails.typeOfHumanSamples,
            projectedMilestoneTiming: this.state.protocolDetails.projectedMilestoneTiming,
            uniquePersonalClinicalSamples: this.state.protocolDetails.uniquePersonalClinicalSamples,
            biobankOwner: this.state.protocolDetails.biobankOwner,
            sampleSize: this.state.protocolDetails.sampleSize,
            dateOfFirstPatientVisit: this.state.protocolDetails.dateOfFirstPatientVisit ? dateStringformat(this.state.protocolDetails.dateOfFirstPatientVisit) : null,
            numberOfTherapyGroups: this.state.protocolDetails.numberOfTherapyGroups,
            dateFirstPtEntersTreatment: this.state.protocolDetails.dateFirstPtEntersTreatment ? dateStringformat(this.state.protocolDetails.dateFirstPtEntersTreatment) : null,
            anticipatedQuarterlyEnrollment: this.state.protocolDetails.anticipatedQuarterlyEnrollment,
            dateLastPatientEntersTrial: this.state.protocolDetails.dateLastPatientEntersTrial ? dateStringformat(this.state.protocolDetails.dateLastPatientEntersTrial) : null,
            numberOfinvestigativeSite: this.state.protocolDetails.numberOfinvestigativeSite || "",
            dateLastPatientEntersTreatment: this.state.protocolDetails.dateLastPatientEntersTreatment ? dateStringformat(this.state.protocolDetails.dateLastPatientEntersTreatment) : null,
            potentialCountries: this.state.protocolDetails.potentialCountries,
            dateOfLastPatientVisit: this.state.protocolDetails.dateOfLastPatientVisit ? dateStringformat(this.state.protocolDetails.dateOfLastPatientVisit) : null,
            multiCountryTrial:this.state.protocolDetails.multiCountryTrial,
            dateOfReportSubmission: this.state.protocolDetails.dateOfReportSubmission ? dateStringformat(this.state.protocolDetails.dateOfReportSubmission) : null,
            clinicalTrialPhase: this.state.protocolDetails.clinicalTrialPhase?.length > 0 ? this.state.protocolDetails.clinicalTrialPhase?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            includePreClinicalPortion: this.state.protocolDetails.includePreClinicalPortion || false,
            dateOfPublicationSubmission: this.state.protocolDetails.dateOfPublicationSubmission ? dateStringformat(this.state.protocolDetails.dateOfPublicationSubmission) : null,
           
            assignmentToTreatment: this.state.protocolDetails.assignmentToTreatment?.length > 0 ? this.state.protocolDetails.assignmentToTreatment?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            leadInPeriod: this.state.protocolDetails.leadInPeriod ? this.state.protocolDetails.leadInPeriod?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            design: this.state.protocolDetails.design ? this.state.protocolDetails.design?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            blinding: this.state.protocolDetails.blinding ? this.state.protocolDetails.blinding?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            controls: this.state.protocolDetails.controls ? this.state.protocolDetails.controls?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            blindingDoneBy: this.state.protocolDetails.blindingDoneBy || "None",
            totalStudyLength:getDateDifferenceMonth(this.state.protocolDetails.dateOfFirstPatientVisit,this.state.protocolDetails.dateOfPublicationSubmission) || null,
            enrollmentPeriod:getDateDifferenceMonth(this.state.protocolDetails.dateOfFirstPatientVisit,this.state.protocolDetails.dateLastPatientEntersTrial) || null,
            extension: this.state.protocolDetails.extension ? this.state.protocolDetails.extension?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            patientType: this.state.protocolDetails.patientType ? this.state.protocolDetails.patientType?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            extensionLength: this.state.protocolDetails.extensionLength,
            patientGender: this.state.protocolDetails.patientGender ? this.state.protocolDetails.patientGender?.trim().split(';').join()?.split(',').map(obj => { return { value: obj, label: obj } }) : null,
            ageRangeStart: this.state.protocolDetails.ageRangeStart || "",
            patientTreatmentPeriod: this.state.protocolDetails.patientTreatmentPeriod || "",
            ageRangeEnd: this.state.protocolDetails.ageRangeEnd || "None",
            // studyOnOffLabel: this.state.protocolDetails.studyOnOffLabel || "",
            // LineOfTherapy: this.state.protocolDetails.LineOfTherapy || "None",
            lillyToSupplyStudyCompound: this.state.protocolDetails.lillyToSupplyStudyCompound,
            ifNoWhy: this.state.protocolDetails.ifNoWhy,
            lillyStudyCompound1: this.state.protocolDetails.lillyStudyCompound1!=null ? this.state.compoundList.find(compound => compound.value === this.state.protocolDetails.lillyStudyCompound1): "",
            lillyStudyCompound1Other: this.state.protocolDetails.lillyStudyCompound1Other,
            compound1AvailableMaterial: this.state.protocolDetails.compound1AvailableMaterial?.value || [],
            compound1AvailableMaterialOther: this.state.protocolDetails.compound1AvailableMaterialOther,
            compound1Formulation: this.state.protocolDetails.compound1Formulation,
            compound1DosingSchedule: this.state.protocolDetails.compound1DosingSchedule,
            compound1DosingSchedulOther: this.state.protocolDetails.compound1DosingSchedulOther,
            compound1AdministeredDose: this.state.protocolDetails.compound1AdministeredDose,
            // compound1AmountQuantity: this.state.protocolDetails.compound1AmountQuantity,
            requestedAmtOfComp1PreClin: this.state.protocolDetails.requestedAmtOfComp1PreClin,
            requireSecondLillyCompound: this.state.protocolDetails.requireSecondLillyCompound,
            requestedAmtOfComp5PreClin: this.state.protocolDetails.requestedAmtOfComp5PreClin,
            lillyStudyCompound2: this.state.protocolDetails.lillyStudyCompound2!=null ? this.state.compoundList.find(compound => compound.value === this.state.protocolDetails.lillyStudyCompound2) : "",
            lillyStudyCompound2Other: this.state.protocolDetails.lillyStudyCompound2Other,
            compound2AvailableMaterial: this.state.protocolDetails.compound2AvailableMaterial?.value || [],
            compound2AvailableMaterialOther: this.state.protocolDetails.compound2AvailableMaterialOther,
            compound2Formulation: this.state.protocolDetails.compound2Formulation,
            compound2DosingSchedule: this.state.protocolDetails.compound2DosingSchedule,
            compound2DosingScheduleOther: this.state.protocolDetails.compound2DosingScheduleOther,
            compound2AdministeredDose: this.state.protocolDetails.compound2AdministeredDose,
            // compound2AmountQuantity: this.state.protocolDetails.compound2AmountQuantity,
            requestedAmtOfComp2PreClin: this.state.protocolDetails.requestedAmtOfComp2PreClin,
            requireThirdLillyCompound: this.state.protocolDetails.requireThirdLillyCompound,
            lillyStudyCompound3: this.state.protocolDetails.lillyStudyCompound3 != null ? this.state.compoundList.find(compound => compound.value === this.state.protocolDetails.lillyStudyCompound3) : "",
            lillyStudyCompound3Other: this.state.protocolDetails.lillyStudyCompound3Other,
            compound3AvailableMaterial: this.state.protocolDetails.compound3AvailableMaterial?.value || [],
            compound3AvailableMaterialOther: this.state.protocolDetails.compound3AvailableMaterialOther,
            compound3Formulation: this.state.protocolDetails.compound3Formulation,
            compound3DosingSchedule: this.state.protocolDetails.compound3DosingSchedule,
            compound3DosingScheduleOther: this.state.protocolDetails.compound3DosingScheduleOther,
            compound3AdministeredDose: this.state.protocolDetails.compound3AdministeredDose,
            requestedAmtOfComp3PreClin: this.state.protocolDetails.requestedAmtOfComp3PreClin,
            requireFourthLillyCompound: this.state.protocolDetails.requireFourthLillyCompound,
            lillyStudyCompound4: this.state.protocolDetails.lillyStudyCompound4 != null ? this.state.compoundList.find(compound => compound.value === this.state.protocolDetails.lillyStudyCompound4) : "",
            lillyStudyCompound4Other: this.state.protocolDetails.lillyStudyCompound4Other,
            compound4AvailableMaterial: this.state.protocolDetails.compound4AvailableMaterial?.value || [],
            compound4AvailableMaterialOther: this.state.protocolDetails.compound4AvailableMaterialOther,
            compound4Formulation: this.state.protocolDetails.compound4Formulation,
            compound4DosingSchedule: this.state.protocolDetails.compound4DosingSchedule,
            compound4DosingScheduleOther: this.state.protocolDetails.compound4DosingScheduleOther,
            compound4AdministeredDose: this.state.protocolDetails.compound4AdministeredDose,
            requestedAmtOfComp4PreClin: this.state.protocolDetails.requestedAmtOfComp4PreClin,
            nonLillySupport: this.state.protocolDetails.nonLillySupport || "None",
            nameOfCompany: this.state.protocolDetails.nameOfCompany,
            typeOfSupport: this.state.protocolDetails.typeOfSupport,
            hcpProtocolTitle: this.state.protocolDetails.hcpProtocolTitle || "",
            feedback: this.state.protocolDetails.feedback|| "",
            protocolComments: this.state.protocolDetails.protocolComments || "",
            budgetOfficeContactName: this.state.protocolDetails.budgetOfficeContactName,
            contractContactName: this.state.protocolDetails.contractContactName,
            budgetOfficeStreetAddress: this.state.protocolDetails.budgetOfficeStreetAddress,
            contractContactStreetAddress: this.state.protocolDetails.contractContactStreetAddress,
            budgetOfficeCity: this.state.protocolDetails.budgetOfficeCity,
            contractContactCity: this.state.protocolDetails.contractContactCity,
            budgetOfficeEmail: this.state.protocolDetails.budgetOfficeEmail,
            contractContactEmail: this.state.protocolDetails.contractContactEmail,
            budgetOfficePhoneNumber: this.state.protocolDetails.budgetOfficePhoneNumber,
            contractContactPhoneNumber: this.state.protocolDetails.contractContactPhoneNumber,
            pharmacyContactName: this.state.protocolDetails.pharmacyContactName,
            pharmacyContactCity: this.state.protocolDetails.pharmacyContactCity,
            pharmacyContactEmail: this.state.protocolDetails.pharmacyContactEmail,
            pharmacyContactPhoneNumber: this.state.protocolDetails.pharmacyContactPhoneNumber,
            pharmacyContactStreetAddress: this.state.protocolDetails.pharmacyContactStreetAddress,
        }, this.setMaterialFields)
    }

    getCompoundList = async () => {
        const res = await API.get(`seed/compound/getalldata`)
        const resultArray = res.data.filter(elm => elm.is_part_of_proposal===true );
        const arr = resultArray.map(elm=>({ name: elm.name, value: elm.id }) );
        this.setState({ compoundList: arr });
    }
    onCompoundSelect = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [PROTOCOL_UPDATE[name]]: e.value},
            hasChanged: true
        });
        this.getMaterialList(name, e.value)
    }
    setMaterialFields = () => {
        const fieldList = ["lillyStudyCompound1","lillyStudyCompound2","lillyStudyCompound3","lillyStudyCompound4"]
        fieldList.forEach((field, index) => {
            if(this.state[field] && this.state[field].name){
                this.getMaterialList(field,this.state[field].value)
                this.setState({
                   [`compound${index+1}AvailableMaterial`]: {name:this.state.protocolDetails[`compound${index+1}AvailableMaterial`], value: this.state.protocolDetails[`compound${index+1}AvailableMaterial`]}
                })
            }
        })
    }
    getMaterialList = async (name, compound) => {
        try {
            const res = await API.get(`seed/dosage_strength/compound/${compound}`);
            const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.name}));
            this.setState({[name+'MaterialList']: resultArray});
        }catch(e) {
            console.log("Error in compound material Api")
        }
    }
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: dateChange(e) },
                hasChanged: true
            });
        }
        else 
        { this.setState({
            [e.target.name]: e.target.value,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},
            hasChanged: true
        });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [PROTOCOL_UPDATE[eo.name || eo.target.name]]: Array.isArray(e) ? e.map(obj => obj.label).join(";") : e },
            hasChanged: true
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
            patchObject: { ...this.state.patchObject, [PROTOCOL_UPDATE[name]]: e.value },
            hasChanged: true
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject: { ...this.state.patchObject, [PROTOCOL_UPDATE[e.target.name]]: e.target.checked },
            hasChanged: true
        });
    }

    renderInputFieldsProposalIIR = () => {
        return this.inputFieldsArrayProposalIIR.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsInformation = () => {
        return this.inputFieldsArrayInformation.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsClinical = () => {
        return this.inputFieldsArrayClinical.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsNCRI = () => {
        return this.inputFieldsArrayNCRI.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsStudyCompound = () => {
        return this.inputFieldsArrayStudyCompound.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsContactInfo = () => {
        return this.inputFieldsArrayContactInfo.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    // renderInputFieldsFeedback = () => {
    //     return this.inputFieldsArrayFeedback.map(field => {
    //         if (field.type === "alert")
    //             return <Col sm={12} key={field.label}><InputField {...field} /></Col>
    //         return (
    //             <Col sm={6} key={field.label}>
    //                 <InputField
    //                     {...field}
    //                     key={field.label}
    //                     disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
    //                     error={this.state.error?.[field.name]}
    //                     value={this.state[field.name]}
    //                     onSelect={field.onSelect || this.onSelectChange}
    //                     onChange={field.type !== "checkbox" ? field.onChange || this.onInputChange : this.checkboxChange}
    //                 />
    //             </Col>
    //         )
    //     });
    // }
    renderInputFieldsFinancial = () => {
        return this.inputFieldsArrayFinancial.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        {...getPrintViewTextBox(field,this.state.isPrintMode)}
                        disabled = {field.disabled ? field.disabled : !this.state.isCoordinator }
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                         onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.protocolDetails.submissionId}`)
    }
    formValidation = () => {
        let is_valid = true;
        let error={};
        error["lengthValidation"]={};
        if (this.state['protocolTitle'].trim() === "") {
            is_valid = false;
            error.protocolTitle=true;
            // this.setState({
            //     error: {
            //         protocolTitle: true
            //     }
            // })
        }
        let{proposallist}=this.state;
        
        const obj = ({ ...this.state.patchObject });
        //let error={};
        for (let [key, value] of Object.entries(proposallist)) {
            if(value){
                if(this.state[key]&&this.state[key].length>value){
                    is_valid = false;
                    error[key] = true;
                    error["lengthValidation"][key]=true;
                }
            }
        }
       this.setState({error})
        return is_valid;
    }
    getErrorDescriptions=()=>{

        let {error,proposallist}=this.state;
    
        let allFields=[...this.inputFieldsArrayNCRI,
            ...this.inputFieldsArrayStudyCompound,
            ...this.inputFieldsArrayFinancial,
            ...this.inputFieldsArrayContactInfo,
            ...this.inputFieldsArrayClinical,
            ...this.inputFieldsArrayInformation,...this.inputFieldsArrayProposalIIR,];
    
        let messages = [];
    
        for (let [key, value] of Object.entries(error)) {
    
            let exist = allFields.find(p => p.name === key);
    
            if (exist) {
    
                let messageText=exist.label;
                if(error["lengthValidation"][key]){
                    messageText=messageText+' - Maximum Length should be less than '+proposallist[key]+" chars"
                }
                messages.push(messageText);
    
            }
    
        }
    
        return <ul>
    
            {messages&&messages.map((item,index)=>{
    
                return <li>{item}</li>
    
            })}
    
        </ul>}

    submitForm = async () => {
    if(this.formValidation())
        { const editorFields = [

            "feedback",
            "protocolComments"
        ]
        
        const obj = { ...this.state.patchObject };
        // editorFields.forEach(f => {
        //     if (f in obj)
        //         obj[f] = this.getHtmlFromEditorValue(this.state[f])
        // });
        obj['last_modified_by'] = this.context.userSystemId;
        obj['iirUrl']=window.location.origin;
        obj['submissionId']=this.state.protocolDetails.submissionId;
        if('protocolSubmitted' in obj){
            obj['oldValProtocolSubmitted'] = this.state.protocolDetails.protocolSubmitted
        }
      
        try {
            const res = await API.put("protocol/update", obj);
            this.setState({ successModal: true,error:{} });
        } catch (err) {
            this.setState({ errorModal: true });
        }}
        else{
            this.setState({
                errorModal:true
            })
        }
    }
    changeShowState = async() => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false,
        });
        if(!this.state.errorModal)
       await  this.getProtocolDetails(this.props.submissionId);
    }
    onFileChange = (e) => {
        this.setState({selectedFile: e.target.files[0]})
    }

    uploadFileFunction = async () => {
        const formData = new FormData();
        formData.append(        
        'file',
        this.state.selectedFile,
        this.state.selectedFile.name
        );
        formData.append(
            'created_by',
            this.context.userSystemId
        );
        // console.log("formData>>:",formData);
        try{
            this.setState({
                showLoaderUpload : true
            })
            // const res = await API.post(`/user/proposals3/upload/Protocol/${this.state.protocolDetails.submissionId}`, formData);
            let fileUrl = `${this.state.externalurl}/fileupload/Protocol/${this.state.protocolDetails.submissionId}`;
            let res = await axios.post(fileUrl, formData, {
                headers: {                   
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                },
            });
            this.setState({
                selectedFile: null,
                successFile: true,
                fileMessage: "File uploaded!",

                showLoaderUpload : false
            })

        }catch(e){
            this.setState({
                selectedFile: null,
                errorFile: true,
                showLoaderUpload : false
            })
            console.log(e);
        }
    }
    handleProposalDelete = async () => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`recycle/protocol/delete/${this.state.protocolDetails.id}/${this.props.submissionId}`,{system_id : this.context.userSystemId});
                        this.setState({proposalDetails:{}});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        //this.getProtocol(this.props.submissionDetails.id);
                        this.getProtocolDetails(this.props.submissionId) ;
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    //this.getProtocol(this.props.submissionDetails.id)
                    this.getProtocolDetails(this.props.submissionId) ;
                }
            });
    }
    handleFormEvents=(option)=>{
        this.setState({activeForm:option});
    }
    handleModalCallBack=(response)=>{
        if(response===true){
            let callBack=(val)=>{
                this.setState({activeForm:''},()=>{
                    this.getProtocolDetails(this.props.submissionId) ;
                });
              }
            //   showMessage({                  
            //     text: "Saved Successfully",
            //     icon: "success",
            //     button: "Ok",
              
            //   },callBack);
            callBack();
        } 
        else{
            this.setState({activeForm:''});
        }           
    }
    handleDelete = async (fileName) =>{
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    this.setState({
                        showLoader : true,
                        action :"delete"
                    })
                    const res =await  API.post(`/user/proposals3/delete/Protocol/${this.state.protocolDetails.submissionId}/${fileName}`, {updated_by : this.context.userSystemId});
                    this.setState({
                        successFile: true,
                        fileMessage: "File deleted!",                        
                        showLoader : false                        
                    })

                }
                 catch (err) {
                    this.setState({ errorModal: true , 
                    showLoader : false });
                }
              
            } 
          });
        
     }

     handleDownload = async (fileName) =>{
        try{
            this.setState({
                showLoader : true,
                action : "download"
            })
           /* const res = await API.get(`/user/proposals3/download/getByid/Protocol/${this.state.protocolDetails.submissionId}/${fileName}`,{
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute('download', decodeURIComponent(fileName));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            this.setState({
                showLoader : false,
                successFile: true,
                fileMessage:"File Downloaded Successfully!"
            })*/
            let fileUrl = `${this.state.externalurl}/filedownload/Protocol/${this.state.protocolDetails.submissionId}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                       
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorFile: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        successFile: true,
                        fileMessage: "File Downloaded Successfully!",
                        showLoader :  false
                    })
                });
        }catch(e){
            this.setState({
                showLoader : false,
                errorFile: true
            })
            console.log(e);
        }
     }
     handleBackClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth' })}
     handlePrint=()=>{
        this.setState({isPrintMode:true},this.doPrint)
    }
    doPrint=()=>{
        let waitForCompletion=window.print();
        this.setState({isPrintMode:false})
    }
    render() {
        let { showLoaderUpload  , loadTable , action ,  showLoader } = this.state;
  
        let _isCoordinator=this.state.isCoordinator;


        return(<div ><div className="new-flow-modal">
            <br/>
         <div className="p-3"> 
                <Row> <Col sm={8}><div className="flex-grow-1 font-weight-bold ">Protocol Details</div>
                </Col> <Col sm={4} className="text-right d-print-none">
                        <div className="d-flex justify-content-end align-items-center"> {_isCoordinator && this.state.access.edit && this.state.protocolDetails.submissionId && <IIRBtn.default className="d-flex align-items-center justify-content-center p-2 mr-2 " style="success w-100px" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                          <span>SAVE</span>
                        </IIRBtn.default>}
                            {!this.state.protocolDetails.submissionId && _isCoordinator && <div className="d-flex justify-content-end align-items-center">

                               <DTAButton  onClick={(e) => this.handleFormEvents(this.formType.INITIATE_INSTRUCTION)} icon="ADD" text={"Add Protocol"}></DTAButton>
                            </div>}
                            {/* {!this.state.protocolDetails.submissionId&&<i className="bi bi-plus " style={{fontsize:"2rem"}}></i>} */}
                            {this.state.protocolDetails.submissionId && _isCoordinator && <div>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        <span className="material-icons mr-2">
                                            more_vert
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="bg-dark text-white" >
                                        <Dropdown.Item onClick={() => this.handlePrint()} className="text-white">Print</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.handleProposalDelete()} className="text-white">Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>}

                        </div>



                    </Col></Row>
                <span className="alert-line"></span>
                {this.state.activeForm===this.formType.INITIATE_INSTRUCTION && <NewProtocolEx  submissionId={this.props.submissionId} show={true} onClose={this.handleModalCallBack}></NewProtocolEx>}
    
             
            
          {this.state.protocolDetails.submissionId&& <div> <Row>{this.renderInputFieldsInformation()}</Row>
            <span className="alert-line"></span>
            <div className="flex-grow-1 font-weight-bold mb-3">Protocol Information</div>
            <Row>{this.renderInputFieldsProposalIIR()}</Row>
            <span className="alert-line"></span>
            {/* <div className="flex-grow-1 font-weight-bold mb-3"> Proposal: Study Description</div>
           <Row>{this.renderInputFieldsProposal_Study()}</Row> 
           <span className="alert-line"></span> */}
           <div className="flex-grow-1 font-weight-bold mb-3"> Non Clinical Research Info</div>
            <Row> {this.renderInputFieldsNCRI()}</Row>
            <span className="alert-line"></span>
           <div className="flex-grow-1 font-weight-bold mb-3">  Clinical Research Info</div>
            <Row> {this.renderInputFieldsClinical()}</Row>
            <span className="alert-line"></span>
           <div className="flex-grow-1 font-weight-bold mb-3"> Study Compound Support</div>
           <Row> {this.renderInputFieldsStudyCompound()}</Row>
           <span className="alert-line"></span>
           <div className="flex-grow-1 font-weight-bold mb-3"> Financial Support</div>
           <Row> {this.renderInputFieldsFinancial()}</Row>
           <span className="alert-line"></span>
           <div className="flex-grow-1 font-weight-bold mb-3"  >Contact Information</div>
           
           <Row> {this.renderInputFieldsContactInfo()}</Row>
           <span className="alert-line"></span></div>}

           {this.state.protocolDetails.submissionId&&  <span className="flex-grow-1 font-weight-bold mb-3">File Upload</span>}
{this.state.protocolDetails.submissionId&& <Row>
<Col sm={12}><div style={{marginLeft:'3%'}} >
           <Row className="mt-4 hidePrint" >
                <Col sm={12}>
                    
                       
                        {this.state.access.edit && <Row>
                            <Col sm={8}>
                            <div className="ml-3 position-relative">
                                    <Form.File
                                        id="custom-file"
                                        className="custom-file-label ml-0"
                                        onChange={this.onFileChange}
                                        label={this.state.selectedFile?.name}
                                        custom
                                    />
                                    </div>
                            </Col>
                            <Col sm={4}>
                                {showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </Button>}
                                {!showLoaderUpload && <Button variant="secondary" onClick={()=> uploadFile(this.state.selectedFile,this.state.fileList,this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>}
                            </Col>
                        </Row>}
                        {this.state.access.edit && <small className="form-text text-muted pl-3">*Maximum size for file upload is 2MB.</small>}
                        <br />
                        {showLoader &&
                            <Row>
                                <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                                    <span className="visually-hidden">File {action} in progress </span>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </div>

                            </Row>}
                        {this.state.fileList.length > 0 && loadTable && 
                            <FileComponentEx data={this.state.fileList}
                                accessDelete={this.state.access.delete}

                                handleDelete={this.handleDelete}
                                handleDownload={this.handleDownload} />
                        }
                         <span className="alert-line"></span>

                    
                </Col>
            </Row>
            </div></Col></Row>}
           </div>
           {/* <div className="w-100 d-flex justify-content-center">
                    {this.state.access.edit && <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                        Save
                    </Button>}
                </div> */}
                {/* <DatatTableEx
                pageIcons={[<i className="bi bi-plus"></i>]}
                /> */}
                {this.state.successModal &&
                <BootstrapModal 
                    show={this.state.successModal} 
                    header="Success"
                    body="Protocol has been Updated!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal 
                    show={this.state.errorModal} 
                    variant="danger"
                    header="Ooops!!"
                    body={<><div>An error occured! Please try again later!</div>{this.getErrorDescriptions()}</>}
                   
                    changeShowState={this.changeShowState}
                />
            }
             {this.state.successFile &&
                <BootstrapModal
                    show={this.state.successFile}
                    header="Success"
                    body={this.state.fileMessage}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorFile &&
                <BootstrapModal
                    show={this.state.errorFile}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
       
            </div></div>
       )
        
    
}
}


       
           
  
