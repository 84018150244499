import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { dateTimeformat } from '../../../utility/helperFunctions';
import DataTableEx from '../DataTableEx';
import DatatableComponent from '../datatable/DatatableComponent';


export default class FileComponentEx extends Component {

    state = {
        data: []
    }
    columns = [{
        dataField: 'FileName',
        text: 'File Name',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>

                <span >{row.FileName.split("filedate")[0]}</span>
                <span style={{ color: "gray", display: "block",fontSize: "12px", fontStyle: "italic" }}>{row.FileName.split("filedate")[1]}</span>

            </>
        }
    },
    {
        dataField: 'Actions',
        text: ''
    }];

    componentDidMount() {

        let sortedReviews  = [];
        if(this.props.type === "sharepoint"){
            sortedReviews = this.props.data;
        }
        else{
        sortedReviews = this.props.data.filter(file => file.is_deleted === false);
        }
         sortedReviews = sortedReviews.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
        const data = sortedReviews.map(fileName => {
            let visibilityDelete = this.props.accessDelete ? "visible" : "hidden";
            console.log("files",fileName.key.split("\/").pop() + "filedate" + (fileName.updatedAt ? dateTimeformat(fileName.updatedAt) : ""))
            return {
                'FileName': fileName.key.split("\/").pop() + "filedate" + (fileName.updatedAt ? dateTimeformat(fileName.updatedAt) : ""),
                'Actions': <>
                    <i className="bi bi-cloud-download-fill"
                        onClick={this.props.handleDownload ? () => this.props.handleDownload(encodeURIComponent(fileName.key.split("\/").pop())) : null}
                        style={{ fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "right" }}>
                    </i>
                    <i className="bi bi-trash-fill"
                        onClick={this.props.handleDelete ? () => this.props.handleDelete(encodeURIComponent(fileName.key.split("\/").pop())) : null}
                        style={{ visibility: visibilityDelete, fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "right" }}>
                    </i>
                </>
            }
        })
        console.log(data)
        this.setState({ data: data });
    }

    render() {
        return (
            <Row>
                <Col sm={12}>
                <span className="flex-grow-1 font-weight-bold mb-3">Files({this.state.data.length})</span>
                </Col>
                <DataTableEx id="fileComp"
                 csvName="files"
               
                filterChange={()=>{}}
                 data={this.state.data} columns={this.columns} pagination="true" />
            </Row>
        )
    }
}


   
   
      
    

    

       