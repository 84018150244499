import React from 'react'
import DatatTableEx from '../../../common/DataTableEx';
import Table from 'react-bootstrap/Table';
import styles from '../../review.module.css'
import Button from '../../../common/Button';
import CancelReview from './CancelReview'
import ResubmitReview from './ResubmitReview';
import API from '../../../../api/apis';
import { isCoordinator, showMessage } from '../../commonFunctions';
import UserContext from '../../../../context/UserContext';
import Review from '../../Review';
import ToolTipEx from '../../../common/ToolTipEx';
import { DEFAULT, REVIEWS } from '../../../../TooltipInfo';
import moment from 'moment';
import { GLOBAL_DATE_FORMAT_NOTIME } from '../../../../utility/dateHelper';

export default class ReviewerList extends React.Component
{
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state={
            source:[],
            modalView:{form:""},
            showLoader:false,
            coordinatorReviewComplete: props.rowStatus!=='In Progress'
        }
        this.review_status_column="review_data";
    }

    viewEnum={
        CANCEL:"CANCEL",
        RESUBMIT:"RESUBMIT",
        REVIEW:"REVIEW",
        VIEWONLY:'VIEWONLY',
        EDIT:'EDIT'
    }
    columns = [
        {
            dataField: 'id',
            text: 'id',
            sort: true,
            hidden:true
        },
        {
            dataField: 'reviewer_name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'is_committee',
            text: 'Committee',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return row["is_committee"]?"Yes":"No";
            }
        },
        {
            dataField: 'reviewer_type',
            text: 'Reviewer Type',
            sort: true,
        },
        {
            dataField: 'reviewer_email',
            text: 'Email',
            sort: true
            
        },
        {
            dataField: 'status',
            text: 'Review Status',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                let status="";
                if(row[this.review_status_column]&&row[this.review_status_column].length>0){                  
                   if(row[this.review_status_column][0]["status"]!=="SUBMITTED"){
                        status="Not Started"
                   }
                   else{                    
                    if(row["is_committee"] ===true){
                        status=row["is_proposal_for_study_invited"]?"Approved":"Declined";
                        if(row["is_proposal_for_study_invited"]==null||row["is_proposal_for_study_invited"]==undefined||this.props.review_type.toLowerCase()!="concept"){
                            status="";  
                        }
                        if(row["committee_approval"]&&row["committee_approval"].length>0){
                            status=row["committee_approval"];  
                        }
                    }
                    else{
                        status=row["review_status"]?"Approved":"Declined";                        
                    }
                   }
                }
                else  
                 status="Not Started";
                 return status;
            }
        },  
        {
            dataField: 'approval_order_type',
            text: 'Order',
            sort: true,
            formatter: (cell, row, rowIndex) => {
               
                if(row["approval_order_type"]==="Sequential"){
                    return `${row["approval_order_type"]} ${row["approval_order"]}`;
                }
                return row["approval_order_type"];
            }
        },
        {
            dataField: 'due_date',
            text: 'Due Date',
            sort: true,
            formatter:(cell, row, rowIndex)=>{
                if(row["due_date"]&&row["due_date"].length>0){
                    return moment(row["due_date"],"YYYY-MM-DD",false).format(GLOBAL_DATE_FORMAT_NOTIME);
                }
            }
        }
        ];
    setDemoData=()=>{

        let data={
            id:1,
            name:"Rajesh N G",
            reviewer_email:"ng_rajesh@network.lilly.com",
            status:"SUBMITTED",
            approval_order_type:"Sequential",
            approval_order:1,
            due_date:"22/08/2022",
            reviewer_type:"Medical Reviewer",
            is_committee:true
        }
        let {source}=this.state;
        for (var i = 0; i < 5; i++) {
            let d = JSON.parse(JSON.stringify(data));
            d.id = i + 1;
            d.approval_order = i + 1;
            source.push(d)
        }
        this.setState({source:[...source]});
    }

    componentDidMount(){
       // this.setDemoData();
        this.getReviewerList();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.rowStatus !== this.props.rowStatus) {
         this.setState({coordinatorReviewComplete:this.props.rowStatus!=='In Progress'});
        }            
        if(this.props.requiredReviewerRefresh&&(prevProps.requiredReviewerRefresh!=this.props.requiredReviewerRefresh)){           
            this.getReviewerList();  
        }
      }
    getReviewerList=async()=>{
        try{
            this.setState({showLoader:true});
            //const res = await API.get(`reviewConcept/concept/reviews/list/${this.props.reviewerId||''}`);     
            const res = await API.post(`reviewsTab/reviews/list`,{
                    "review_type": this.props.review_type,
                    "review_header_id": this.props.reviewerId||''
            });   

            if(res&&res.data){
                this.setState({source:res.data||[],showLoader:false});
            }
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
    }
    handleEvents=(option,data)=>{
        switch (option) {
            case this.viewEnum.CANCEL: this.setState({ modalView: { form: option, data: data.id } }); break;
            case this.viewEnum.RESUBMIT: this.setState({ modalView: { form: option, data: this.getReviewId(data) } }); break;
            case this.viewEnum.REVIEW: this.setState({
                modalView: {
                    form: option, data: {
                        "submission_id": this.props.submission_id,
                        "reviewers_detail_id": data.id,
                        "review_header_id": data.review_header_id,
                        "isCommiteeReview": data["is_committee"],
                        "review_type":this.props.review_type,
                        "addtnl_review_form":data["addtnl_review_form"],
                         "review_for": this.props.review_for,
                         formView: 'EDIT',
                    }
                }
            }); break;
            case this.viewEnum.VIEWONLY:
                this.setState({
                    modalView: {
                        form: option, data: {
                            "submission_id": this.props.submission_id,
                            "reviewers_detail_id": data.id,
                            "review_header_id": data.review_header_id,
                            "isCommiteeReview": data["is_committee"],
                            "review_submission_id": this.getReviewId(data),
                            "review_type":this.props.review_type,
                            "addtnl_review_form":data["addtnl_review_form"],
                            formView: 'READONLY',
                            "review_for": this.props.review_for
                        }
                    }
                }); break;
            case "CANCEL-VIEW": this.setState({ modalView: { form: "" } }); break;
            case "SAVE-CANCELLATION": this.handleReviewerCancellation(data); break;
            case "SAVE-RESUBMIT": this.handleReviewResubmit(data); break;
        }
    }
    getReviewId=(data)=>{
        if (data[this.review_status_column] && data[this.review_status_column].length > 0 && data[this.review_status_column][0]["status"] === "SUBMITTED") {
            return data[this.review_status_column][0]["id"];
        }  
        return null;
 }
    handleReviewResubmit=async(data)=>{
        if(isCoordinator(this.context)){
            let formBody={
                ...data,
                status:'RESUBMIT',
                "submission_id": this.props.submission_id,
                "updated_by": this.context.userSystemId,
                "review_type": this.props.review_type
            };
            const res = await API.post("reviewsTab/review/update", formBody);
            await this.showResponseMessages(res);
        }
    }
    showResponseMessages=async (res)=>{
        if (res.data.error || res.data.errors) {
            showMessage({ 
                text: "Failed to update the record!",
                icon: "error",
                button: "Ok"
        });
        }
        else{
            showMessage({ 
                text: "Saved Successfully",
                icon: "success",
                button: "Ok"
        });
        }
        this.letRefreshStates();
    }
    letRefreshStates=()=>{
        this.setState({modalView:{form:""}},()=>{
            this.getReviewerList();
            if(this.props.handleSubFormCallback){                   
                this.props.handleSubFormCallback("REFRESH");
            }
        });
    }
    handleReviewerCancellation=async(data)=>{
        if(isCoordinator(this.context)){
            
            let formBody={
                ...data,
                "updated_by": this.context.userSystemId,
                "is_deleted": true,
                "review_type": this.props.review_type,
                "review_header_id": this.props.reviewerId||'',
                "submission_id": this.props.submission_id
            };
            const res = await API.post("reviewConcept/reviewersDetails/update", formBody);
            await this.showResponseMessages(res);
           
        }       
    }
    isValidForReview=(data)=>{
        let isReviewSubmitted=this.isReviewSubmitted(data);
        let is_coordinator=isCoordinator(this.context);
        let is_disabled=isReviewSubmitted;
        if(!is_coordinator && data["reviewer_system_id"]!==this.context.userSystemId){           
            is_disabled=true; 
        }
        if(!is_coordinator && data["reviewer_system_id"]===this.context.userSystemId){ 
            if(data["approval_order_type"]==="Sequential"){
                let {source}=this.state;
                let previousOrder=source.find(p=>p.approval_order===(data["approval_order"]-1));
                if(previousOrder&&!this.isReviewSubmitted(previousOrder)){
                    is_disabled=true;    
                }
            };
        }
        return is_disabled
    }
    isReviewSubmitted=(data)=>{
        return (data[this.review_status_column]&&data[this.review_status_column].length>0&& data[this.review_status_column][0]["status"]==="SUBMITTED");;
    }
    renderActionArea=(data)=>{
        let isReviewSubmitted=this.isReviewSubmitted(data);
        let is_coordinator=isCoordinator(this.context);        
        let is_reviewDisabled=this.isValidForReview(data);
        let is_me=data["reviewer_system_id"]===this.context.userSystemId;
        return <>
               <Button className="mr-3 mb-2" style="success" disabled={is_reviewDisabled || this.state.coordinatorReviewComplete} onClick={e=>this.handleEvents(this.viewEnum.REVIEW,data)}>
                    <div className="d-flex align-items-center">
                        <span className="material-icons mr-1" style={{ fontSize: '1rem' }}>
                            done
                        </span>
                        <span>Review</span>
                    </div>
                </Button>
                {isReviewSubmitted && is_coordinator && <><Button className="mr-3 mb-2" style="warning" disabled={this.state.coordinatorReviewComplete} onClick={e=>this.handleEvents(this.viewEnum.RESUBMIT,data)}>
                    <div className="d-flex align-items-center">
                        <span className="material-icons mr-1" style={{ fontSize: '1rem' }}>
                            refresh
                        </span>
                        <span>Edit</span>
                    </div>
                </Button></>}
                {isReviewSubmitted && <Button className="mr-3 mb-2" style="accent"  onClick={e=>this.handleEvents(this.viewEnum.VIEWONLY,data)}>
                    <div className="d-flex align-items-center">
                        <span className="material-icons mr-1" style={{ fontSize: '1rem' }}>
                            attach_file
                        </span>
                        <span>Submitted</span></div></Button>}
                        {
                           is_coordinator && <Button className="mr-3 mb-2" style="danger" disabled={this.state.coordinatorReviewComplete} onClick={e=>this.handleEvents(this.viewEnum.CANCEL,data)}>
                            <div className="d-flex align-items-center">
                                <span className="material-icons mr-1" style={{ fontSize: '1rem' }}>
                                    cancel
                                </span>
                                <span>Cancel</span></div></Button>
                        }
               
    
        </>
    }
    handleOnModalclose=(e)=>{
        this.setState({modalView:{form:""}},()=>{
            this.getReviewerList();
            if(this.props.handleSubFormCallback){                   
                this.props.handleSubFormCallback("REFRESH");
            }
        });
    }
    isReviewProcessStarted=()=>{
        let {source}=this.state;
        let isThereAnyReview=source.filter(p=>p[this.review_status_column]&&p[this.review_status_column].length>0);
        return isThereAnyReview.length>0;
    }
    renderTable=()=>{
        let {source,showLoader}=this.state;
        let Columns=this.columns.filter(p=>!p.hidden).map((item,index)=>{
            return <th key={item.dataField}>{item.text}</th>
        });
       
      let Rows=  source.map((item,index)=>{
            return <tr key={item.id}>
                    {
                        this.columns.filter(p=>!p.hidden).map((col,Colindex)=>{
                            let cell=item[col.dataField];
                            let row=item;
                            return <td key={col.dataField}>{(col.formatter?col.formatter(cell,row,index): item[col.dataField])}</td>
                        })
                    }
                    {<td>
                       {this.renderActionArea(item)}
                    </td>}
            </tr>
        });
        return  <Table responsive="sm">
                    <thead><tr>{Columns}
                    <th >
                        {!this.state.coordinatorReviewComplete &&<div className='d-flex align-items-center justify-content-end'>
                        {isCoordinator(this.context) && source && source.length>0 && <ToolTipEx tooltip={DEFAULT.EDIT}><span onClick={e=>{
                            e.preventDefault();
                            e.stopPropagation();
                             if(this.props.handleSubFormCallback){                   
                                this.props.handleSubFormCallback("EDITREVIEWERS",{id:this.props.reviewerId});
                            }
                            
                            /*if(!this.isReviewProcessStarted()){
                                if(this.props.handleSubFormCallback){                   
                                    this.props.handleSubFormCallback("EDITREVIEWERS",{id:this.props.reviewerId});
                                }
                            }
                            else{
                                showMessage({ 
                                    text: "The review process has been already started!",
                                    icon: "warning",
                                    button: "Ok"
                            });
                            }
                            */
                            
                        }} className="material-icons mr-4">
                            edit
                        </span></ToolTipEx>}
                        </div>}
                    </th></tr></thead>
                    <tbody>{Rows}
                    {
                       (!source || source.length<=0 )&& <tr>
                            <td colSpan={this.columns.filter(p=>!p.hidden).length}>
                             {showLoader?"Loading":"No Records!"}    
                            </td>
                        </tr>
                    }
                    </tbody>
        </Table>
    }
    render(){
        let {modalView}=this.state;
        return <div>
           {this.renderTable()}
           
           {modalView.form===this.viewEnum.CANCEL&&<CancelReview id={modalView.data} handleEvents={this.handleEvents}></CancelReview>}
           {modalView.form===this.viewEnum.RESUBMIT&&<ResubmitReview id={modalView.data} handleEvents={this.handleEvents}></ResubmitReview>}
           {(modalView.form===this.viewEnum.REVIEW||modalView.form===this.viewEnum.VIEWONLY)&&<Review onClose={this.handleOnModalclose} data={modalView.data} modalInfo={{title:"",titleDescription:"",show:true}} handleEvents={this.handleEvents}></Review>}
        </div>
    }
}