import React, { Component } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import DatatableComponent from '../../common/datatable/DatatableComponent';

export default class DrugDeliverySiteFiles extends Component {

    columns = [{
        dataField: 'File Name',
        text: 'File Name',
        sort: true
    },{
        dataField: 'Actions',
        text: ''
    }];

    data = [
        {
            'File Name': "MSP.docx",
            'Actions': <>
             <i className="bi bi-trash-fill" style={{ fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "right" }}></i>
                <i className="bi bi-cloud-download-fill" style={{ fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "right" }}></i>
                </>
        }
    ];

    render() {
        return (
            <>
                <Row>
                    <Col sm={6}>
                        <Form.File
                            id="custom-file"
                            label="Select File"
                            custom
                        />
                    </Col>
                    <Col sm={6}>
                        <Button variant="secondary">Upload Attachment</Button>
                    </Col>
                </Row>
                <br/>
                <Row>
                <Col md={7}>                    
                    <h6 className="table-top"> Files({this.data.length})</h6>
                </Col>
                <DatatableComponent data={this.data} columns={this.columns} rowEvents={this.rowEvents} pagination="true" />
                </Row>
            </>
        )
    }
}
