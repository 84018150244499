import React, { Component } from 'react'
import { THERAPEUTIC_AREA, REQUEST_TYPE, INDICATIONS } from '../../../consts';
import { Button, Col, Row, Card, Form } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../../common/sticky-nav/StickyNav';
import InputField from '../../common/input-fields/InputField';
// import History from './History';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../../common/loader/Loader';
import { yyyymmddFormat } from '../../common/dateFormat';
import UserContext from '../../../context/UserContext';

import swal from 'sweetalert';
import { getUserInfo, dateTimeformat, enableWFActions } from '../../../utility/helperFunctions';
import StudyCompoundModal from './StudyCompoundModal';



class StudyCompoundDetails extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        hasChanged: false,
        submissionId:"",
        currencyList: [],
        fileList: [],
        selectedFile: null,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
    }
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        }

    ];

    async componentDidMount() {
        await this.getCompoundList();
        await this.getStudyCompoundById(this.props.match.params.studyCompoundId);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator") ,
                edit: selectedRole.includes("operational_coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator") ,
                    edit: selectedRole.includes("operational_coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getCurrencyList = async () => {
        try {
            const currencyList = await API.get(`seed/currency/data`);
            this.setState({
                currencyList: currencyList.data.data||[],
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    getStudyCompoundById = async (id) => {
        try {
            const studyCompoundDetails = await API.get(`study/getbyid/${id}`);
            const viewEditData = {...studyCompoundDetails.data};
            studyCompoundDetails.data["submission_Id"] = "Submission-" + studyCompoundDetails.data.submissionId;
            studyCompoundDetails.data["createdby"] = studyCompoundDetails.data.created_by?.length ? await getUserInfo(studyCompoundDetails.data.created_by) + "  " + dateTimeformat(studyCompoundDetails.data.createdAt) : "";
            studyCompoundDetails.data["last_modifiedby"] = studyCompoundDetails.data.last_modified_by?.length ? await getUserInfo(studyCompoundDetails.data.last_modified_by) + "  " + dateTimeformat(studyCompoundDetails.data.updatedAt) : studyCompoundDetails.data.createdby;

            this.setState({
                ...studyCompoundDetails.data,
                showLoader: false,
                submissionId:studyCompoundDetails.data.submissionId,
                viewEditData:viewEditData

            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }


    get inputFieldsArray() {
        return [
            {
                label: "Study Compounds Name",
                type: "text",
                name: "study_compounds_name",
                disabled: true,
            },
            {
                label: "Submission",
                type: "text",
                placeholder: "Select Submission",
                name: "submission_Id",
                disabled: true
            },
            {
                label: "[EXT] Compound",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                disabled: true,
                onSelect: this.onSearchSelect,
                name: "compound",
            }, {
                label: "Compound Role",
                type: "text",
                disabled: true,
                name: "compound_role",
            }, {
                label: "Compound 1",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                disabled: true,
                onSelect: this.onSearchSelect,
                name: "compound_1",
            }, {
                label: "Compound 1 Role",
                type: "text",
                disabled: true,
                name: "compound_role_1",
            }, {
                label: "Compound 2",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                disabled: true,
                onSelect: this.onSearchSelect,
                name: "compound_2",
            }, {
                label: "Compound 2 Role",
                type: "text",
                disabled: true,
                name: "compound_role_2",
            }, {
                label: "Compound 3",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                disabled: true,
                onSelect: this.onSearchSelect,
                name: "compound_3",
            }, {
                label: "Compound 3 Role",
                type: "text",
                disabled: true,
                name: "compound_role_3",
            }, {
                label: "Compound 4",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                disabled: true,
                onSelect: this.onSearchSelect,
                name: "compound_4",
            }, {
                label: "Compound 4 Role",
                type: "text",
                disabled: true,
                name: "compound_role_4",
            }, {
                label: "Compound 5",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                disabled: true,
                onSelect: this.onSearchSelect,
                name: "compound_5",
            }, {
                label: "Compound 5 Role",
                type: "text",
                disabled: true,
                name: "compound_role_5",
            }, {
                label: "Compound 6",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                disabled: true,
                onSelect: this.onSearchSelect,
                name: "compound_6",
            }, {
                label: "Compound 6 Role",
                type: "text",
                disabled: true,
                name: "compound_role_6",
            },

            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled: true,
                edit: true
            }, {
                label: "Last Modified By",
                type: "text",
                name: "last_modifiedby",
                className:"createUpdateByfontsize",
                disabled: true,
                edit: true
            },

        ];
    }


    onInputChange = (e) => {
        this.setState({ hasChanged: true })
        this.setState({ [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({ hasChanged: true })
        this.setState({
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {

            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.submissionId}`)
    }

    // submitForm = async () => {
    //     const reforecastObj = {}
    //     try {
    //         const res = await API.patch("reforecast/update", reforecastObj);
    //         this.setState({ successModal: true });
    //     } catch (err) {
    //         this.setState({ errorModal: true });
    //     }
    // }


    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false,
        });
        this.getStudyCompoundById(this.props.match.params.id);
    }

    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
}
getCompoundList = async () => {
    try {
        let compoundList = await API.get(`seed/compound/fulldata`);
        const resultArray = (compoundList.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id }));

        this.setState({
            compoundList: resultArray,
            showLoader: false
        })
    } catch (e) {
        console.log(e);
        this.setState({
            error: true,
            showLoader: false
        })
    }
}

handleStudyCompoundUpdate = async (state) => {
    delete state.compoundList;
    delete state.showLoader;
    delete state.created_by;
    Object.keys(state).forEach(ele =>{
        if(ele.includes("role_")&&state[ele]==="None"){
            state[ele]=null
        }
    })

    state.last_modified_by = this.context.userSystemId;
    state.submissionId = this.state.submissionId;
    // state.created_by = this.context.userSystemId;
    try {
        const res = await API.post("study/update", state);
        this.setState({
            success_body: "Updated successfully!",
            successModal: true,
            hasChanged: false,
            showHideModal: false,
            viewEditFlag: null,
            viewEditData: {},
        });
        this.getStudyCompoundById(state.id)

    } catch (err) {
        this.setState({ errorModal: true });
    }
}

    uploadFileFunction = async () => {
        const formData = new FormData();
        formData.append(
            'files',
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        try {
            const res = await API.post(`/trial/upload/expense/${this.state.submissionId}/${this.props.match.params.expensesId}`, formData);
            this.setState({
                selectedFile: null,
                successFile: true,
                fileMessage: "File uploaded!"
            })
        } catch (e) {
            this.setState({
                selectedFile: null,
                errorFile: true
            })
            console.log(e);
        }
    }
    handleDelete = async (fileName) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try {
                    const res = API.delete(`/study/delete/expense/${this.state.submissionId}/${this.props.match.params.expensesId}`);
                    swal("Deleted Successfully", {
                        icon: "success",
                      });
                     
                }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
              
            } 
          });
        // try {
        //     const res = await API.delete(`/study/delete/expense/${this.state.submissionId}/${this.props.match.params.expensesId}`);
        //     this.setState({
        //         successFile: true,
        //         fileMessage: "File Deleted!"
        //     })
        // } catch (e) {
        //     this.setState({
        //         errorFile: true
        //     })
        //     console.log(e);
        // }
    }

    handleDownload = async (fileName) => {
        try {
            const res = await API.get(`/trial/download/getByid/expense/${this.state.submissionId}/${this.props.match.params.expensesId}`);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                successFile: true,
                fileMessage: "File Downloaded Successfully!"
            })
        } catch (e) {
            this.setState({
                errorFile: true
            })
            console.log(e);
        }
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, success_body, errorModal } = this.state;

        if (!this.state.submissionId)
            return <Loader />
        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button">
                        <i className="bi bi-arrow-left">
                            {" "}{this.state.submission_Id}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Study Compound - {this.state.study_compounds_name} </h4>
                    {this.state.access.edit && enableWFActions()&& <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                 {this.renderInputFields()}
                                {/* <div className="w-100 d-flex justify-content-center">
                                   <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                                        Save
                                    </Button>
                                </div> */}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
               
                
            </Row>
            {
                        showHideModal &&
                        <StudyCompoundModal
                            showHideModal={showHideModal}
                            handleModalShowHide={this.handleModalShowHide}
                            handleStudyCompoundSave={this.handleStudyCompoundSave}
                            handleStudyCompoundUpdate={this.handleStudyCompoundUpdate}
                            viewEditFlag={viewEditFlag}
                            viewEditData={viewEditData}
                            submissionId={this.state.submissionId}
                        />
                    }
            {this.state.successModal &&
                <BootstrapModal
                    show={this.state.successModal}
                    header="Success"
                    body="Study Compound has been Updated!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={this.state.errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.successFile &&
                <BootstrapModal
                    show={this.state.successFile}
                    header="Success"
                    body={this.state.fileMessage}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorFile &&
                <BootstrapModal
                    show={this.state.errorFile}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }

        </>
    }
}

export default withRouter(StudyCompoundDetails);
