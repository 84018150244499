import React, { Component } from 'react';

import { Col, Row, Container, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import { dateChange } from '../../../utility/helperFunctions';
import InputField from '../../common/input-fields/InputField';
import BootstrapModal from '../../common/modal/BootstrapModal';

class ReviewConcept extends Component {
    static contextType=UserContext
  state={
      date_of_review:null,
      name_medical_reviewer_committee:"",
      proposal_for_proposed_study:"",
      successModal:false,
      errorModal:false,
      patchObject:{"submission_id":this.props.submissionId}
  }
  get inputFieldsArray(){
    return  [
        {
            label: "Name of Reviewer or Committee *",
            type: "text",
            placeholder: "Enter Name",
            name: "name_medical_reviewer_committee",
           
        },
        {
            label: "Date of Review *",
            type: "date",
            placeholder: "Enter Review Date",
            name: "date_of_review",
           
        },
        {
            label: "Do you invite a proposal for the proposed study? *",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "proposal_for_proposed_study",
            options: ["Yes", "No"]
        },
        {
            label: "Comments",
            type: "textarea",
            placeholder: "write comments...",
            name: "comments",
           
        }
    ]};
    componentDidMount(){
        console.log("this.props",this.props)
    }
onInputChange = (e,eo) => {
    if (eo.type === "date") {
        let { name, value } = eo;
        this.setState({
            [name]: e,
            [value]: e,
            patchObject: { ...this.state.patchObject, [eo.name]: e },
        });
    }
    else     
    {this.setState({[e.target.name]: e.target.value});
        if(e.target.value !== "")
            this.setState({error: { ...this.state.error, [e.target.name]: false},
                patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},});}
           
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [eo.name || eo.target.name]: e==="Yes" ? true : false},
        });        
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
        }); 
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    key={field.label}
                    error={this.state.error?.[field.name]}
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange ||((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
       this.props.history.push(`/submissions/${this.props.submissionId}`)
    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = { date_of_review: this.state.date_of_review,
            name_medical_reviewer_committee:this.state.name_medical_reviewer_committee,
            proposal_for_proposed_study:this.state.proposal_for_proposed_study }
        const errors = {}
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors }
            });
        });

        return is_valid;
    }
submitForm=async()=>{
    if(this.formValidation()){
        this.state.patchObject["created_by"]=this.context.userSystemId;
        this.state.patchObject["date_of_review"] = this.state.patchObject.date_of_review != "" && this.state.patchObject.date_of_review != null ?
            dateChange(this.state.patchObject.date_of_review): null;
    try {
        const res = await API.post("/review/concept/add", this.state.patchObject);
        this.setState({successModal: true});
    }catch(err){
        this.setState({errorModal: true});
  }
    }
    console.log(this.state)
    
  
}
    onNavOut = () => {
        this.props.history.push(`/submissions/${this.props.submissionId}`)
    }
    
    render() {
        
        return (
            <Container fluid className="form-page">
                <h4 className="text-center mb-4">Concept Review</h4><hr />
                <Row>
                    {this.renderInputFields()}
                    <div className="w-100 d-flex justify-content-center">
                        <Button className="ml-1 mt-4 px-3" variant="primary" type="submit" onClick={this.submitForm}>
                            Save
                        </Button>
                    </div>
                </Row>
                {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Created Successfully!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </Container>
        )
    }
}
export default withRouter(ReviewConcept)