import React,{useState,useEffect} from "react";
import { FormLabel } from "react-bootstrap";
import FileUploader from "../../ToReviewDetails/FileUploader";
import Papa from 'papaparse';
import {validator,column_mapper} from './field_constants'
export default({formData,navigation})=>{
const[fileInfo,setFileInfo]=useState(null);
const [errorMessage,setErrorMessage]=useState(null);

const validateFileType=(file)=>{
    var fileName = file.name;
    let type=fileName.split('.').pop();
    if(type.toLowerCase()!="csv"){
        setErrorMessage("Invalid file type");
        setFileInfo(null);
        return false;
    } 
    var fileSize = file.size/1000/1000;
    if(fileSize>2){
        setFileInfo(null);
        setErrorMessage("File size should not be greater than 2 MB");
        return false;
    }

    
    return true;
};
const downloadCsv=()=>{
   
    var csv = Papa.unparse([validator]);

    var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var csvURL =  null;
    if (navigator.msSaveBlob)
    {
        csvURL = navigator.msSaveBlob(csvData, 'download.csv');
    }
    else
    {
        csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'bulk_import_template.csv');
    tempLink.click();
}
    return <div  className="d-flex flex-column align-items-center">
        <div className="d-flex flex-column w-50">
            <FormLabel className="pl-2">Please select the csv file you would like to upload <span className="pl-2 text-danger">{errorMessage?`(${errorMessage})`:''}</span></FormLabel>
            <FileUploader placeholder="Upload the document" fileInfo={fileInfo?.csvFile??null} onChange={e => {
                let activeFile = e.target.files[0];
                setErrorMessage(null); 
                if(validateFileType(activeFile)){
                    setFileInfo({ csvFile: { selectedFileName: activeFile.name, file: activeFile } });
                    Papa.parse(activeFile, {
                        header: true,
                        skipEmptyLines: true,
                        dynamicTyping: true,
                        error:(error,file)=>{
                            console.error("Parsing Failed:",error,file);
                            setFileInfo(null);
                            if(formData&&formData.handleEvents){
                                formData.handleEvents(null,{option:'CSVDATA',csv:null});
                            }
                        },
                        complete: (results, file) => {
                           try{
                            console.error("Parsing :",results);
                               if (results&&results.data&&formData && formData.handleEvents) {
                                    if(results.data&&results.data.length>0){
                                        let keyData=results.data[0];
                                       
                                        let isValid=true;
                                        for(const [key,value] of Object.entries(validator)){
                                            let isExist=Object.entries(keyData).filter(p=>p[0]===key);
                                            if(isExist.length<=0){
                                                isValid=false;
                                            }
                                        }
                                        if(isValid){
                                            let systemFormatData=results.data.map((item)=>{
                                                let newFormat={};
                                                for(const [key,value] of Object.entries(item)){
                                                    newFormat[column_mapper[key]]=value;
                                                }
                                                return newFormat;
                                            });
                                            results.data=systemFormatData;
                                            formData.handleEvents(null, { option: 'CSVDATA', csv: results });
                                        }
                                        else{
                                            formData.handleEvents(null, { option: 'CSVDATA', csv: {data:[]} });
                                            setFileInfo(null);
                                            setErrorMessage("Invalid template");
                                            
                                        }
                                    }
                                    else{
                                        formData.handleEvents(null, { option: 'CSVDATA', csv: {data:[]}  });
                                        setFileInfo(null);
                                        setErrorMessage("Invalid file");
                                       
                                    }
                                   
                               }
                           }
                           catch(exp){
                            console.error("Parsing Failed:",exp);
                           }
                            
                        }
                    })
                }
                
            }}></FileUploader>
            <div className="p-2 d-flex flex-column">
                <div><a href="#" className="mr-1" onClick={e=>downloadCsv()}>Click here</a><span> to download the template format.</span></div>
                <div><a href="https://collab.lilly.com/:x:/r/sites/LLIR/Root_Prod/Help%20Docs/Bulk%20Milestone%20Expenses%20Help%20Document.xlsx?d=w54fa5ccb9619435b99ad5766c4babf17&csf=1&web=1&e=CTkQWw" target="_blank" className="mr-1 mt-1" >Click here</a><span> to view the help document for milestone data entry.</span></div>
            </div>
    </div></div>
}