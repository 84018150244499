export const DEFAULT ={
    CANCEL:'Cancel this operation',
    ADD:'Create a new record',
    SEARCH:'Search for the record',
    SAVE:'Save the record',
    FILTER:'Filter',
    DELETE:'Delete the record',
    EDIT:'Update the record',
    DOWNLOAD:'Download'
}
export const REVIEWS={
    INITIATE:'Initiate new review',
    INITIATE_EX:'Add Review',
    REVIEW:'Create new Review',
    RESUBMIT:'Initiate review resubmit',
    CANCEL:'Cancel the reviewer',
    SUBMITTED:'View Submitted Review'
}
export const FINANCE={
    ADD:'Add Milestone Expense',
}
export const PRESENTATION={
    ADD:'Add Presentation',
}
export const COMPOUND={
    ADD:'Add Compound',
}
export const DELEGEE={
    ADD:'Add Delegee',
}
export const INVESTIGATORS={
    ADD:'Add Investigator',
}
