import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import API from '../../../api/apis';
import {yyyymmddFormat} from  '../../common/dateFormat';
import { getUserInfo,dateTimeformat, dateStringformat } from '../../../utility/helperFunctions';
import Loader from '../../common/loader/Loader';

class ReforecastModal extends React.Component {
    state = {
        compoundList: [],
        hasChanged: false,
        submission:`Submission- ${this.props.submissionId}`,
        submissionId: this.props.submissionId,
        reforcastTitle:"",
        isLoader:true,
        approval:"Awaiting Review",
        fpv:null,
        decisionDate:null,
        fpet:null,
        lpet:null,
        fsr:null,
        lpetx:null,
        lpv:null
    }
    async componentDidMount() {
        const { viewEditData, viewEditFlag } = this.props;
       
        if (viewEditFlag === "edit") {
            viewEditData["fpv"] = viewEditData.fpv != "" && viewEditData.fpv != null ?
            dateStringformat(viewEditData.fpv) : null;
            viewEditData["decisionDate"] = viewEditData.decisionDate != "" && viewEditData.decisionDate != null ?
            dateStringformat(viewEditData.decisionDate) : null;
            viewEditData["fpet"] = viewEditData.fpet != "" && viewEditData.fpet != null ?
            dateStringformat(viewEditData.fpet) : null;
            viewEditData["lpet"] = viewEditData.lpet != "" && viewEditData.lpet != null ?
            dateStringformat(viewEditData.lpet) : null;
            viewEditData["fsr"] = viewEditData.fsr != "" && viewEditData.fsr != null ?
            dateStringformat(viewEditData.fsr) : null; 
            viewEditData["lpetx"] = viewEditData.lpetx != "" && viewEditData.lpetx != null ?
            dateStringformat(viewEditData.lpetx) : null;
            viewEditData["lpv"] = viewEditData.lpv != "" && viewEditData.lpv != null ?
            dateStringformat(viewEditData.lpv) : null;
            viewEditData["createdby"] =viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by)+"  "+ dateTimeformat(viewEditData.createdAt) : "";

           viewEditData["updatedby"] =viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData["createdby"] ;
           viewEditData["reforcastTitle"] =`Submission- ${viewEditData.submissionId} :Reforecast- ${this.props.name}`
        
           // viewEditData["probability"] = viewEditData.probability != "" && viewEditData.probability != null ?
            // viewEditData.probability+"%" : null;
            // viewEditData["currency"] = this.state.currencyList.filter(item => item.id === viewEditData.currency_iso_code)[0];
            this.setState({
               
                ...this.state,
                isLoader:false,
                ...viewEditData
            })
        }
       else{
        this.setState({
            isLoader:false
        })
       } 
    }

    get inputFieldsArray() {
        return [{
                label: "Reforecast Title",
                type: "text",
                name: "reforcastTitle",
                disabled: true,
                edit:true
               
            },
            {
                label: "[EXT] Quarterly Enrollment",
                type: "number",
                min:"0",
                name: "quarterly_enrollment",
            },
            {
                label: "Approval",
                type: "dropdown",
                placeholder: "Awaiting Review",
                name: "approval",
                options: [
                    "None",
                    "Awaiting Review",
                    "Approved",
                    "Declined",
                    "Under Review"
                ],
            },
              {
                label: "[EXT] FPV",
                name:  "fpv",
                type:  "date"
            },
            {
                label: "Decision Date",
                name:  "decisionDate",
                type:  "date"
            }, {
                label: "[EXT] FPET",
                name:  "fpet",
                type:  "date"
            },    {
                label: "[EXT] LPET",
                name:  "lpet",
                type:  "date"
            }, {
                label: "[EXT] LPETx",
                name:  "lpetx",
                type:  "date"
            },   {
                label: " [EXT] LPV",
                name:  "lpv",
                type:  "date"
            },  {
                label: "[EXT] FSR",
                name:  "fsr",
                type:  "date"
            },  
             {
                label: "Lilly Feedback",
                type: "textarea",
                name: "feedback",
                // onChange: this.onEditorChange
            },  
            {
                label: "[EXT] Investigator Rationale",
                type: "textarea",
                name: "investigator_rationale",
                // onChange: this.onEditorChange
            },
            {
                label: "Submission",
                type: "text",
                name: "submission",
                
            },
           {
                label: "Last Updated By",
                type: "text",
                name: "last_updated_by",
            }, 
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled: true,
                edit:true
            }, {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
                disabled: true,
                edit:true
            }
        ];
    }
    formValidation = () => {
        let is_valid = true;
        // const initialValues = {
        //    quarterly_enrollment: this.state.quarterly_enrollment
        // }
        const errors = {};
        let customerror={};
        if (this.state.quarterly_enrollment<0) {
            customerror["quarterly_enrollment"] = "Negative Number is not allowed";


            this.setState({
                error: { ...errors },
                customerror: { ...customerror }
            });
            return false;
        }
          else
            { this.setState({
                error: { ...errors },
                customerror:{...customerror}
            });}

        return is_valid;
    }
    onSave = () => {
        if (this.formValidation()) {
            this.props.handleReforecastSave(this.state);
        }
    }
    onUpdate = () => {
        if (this.formValidation()) {
            this.props.handleReforecastUpdate(this.state);
        }
    }

    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
                hasChanged: true
            });
        }
        else 
        { this.setState({
            [e.target.name]: e.target.value,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},
            hasChanged: true
        });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
        });
    }
    getHtmlFromEditorValue = (val) => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            lillyFeedback: {...this.state.lillyFeedback, [e]: this.getHtmlFromEditorValue(val)}
        });
    };

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleReforecastSave, viewEditFlag, handleReforecastUpdate} = this.props;
        const { isLoader } = this.state;
        
        return (
            <div>
                
                <Modal show={showHideModal} size="lg">
                {this.state.isLoader=== true ?
        <Loader /> :  <div> <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit Reforecast` :  "New Reforecast"}
                        </Modal.Title>
                    </Modal.Header>
                     <Modal.Body >
                   <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                                viewEditFlag === "edit" ?
                                    <Button variant="primary" onClick={this.onUpdate}>
                                        Save
                     </Button> : <Button variant="primary" onClick={this.onSave}>
                                        Save
                    </Button>
                        }

                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default ReforecastModal;