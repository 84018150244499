import React,{useEffect,useState} from "react";
import { Button } from "react-bootstrap";
export default({handleAuthentication})=>{
    return <div className="d-flex align-items-center justify-content-center position-fixed w-100 h-100 " style={{ zIndex: 9999, left: 0, top: 0, backgroundColor: '#00000095' }}>
        <div className="p-5 w-50 shadow rounded border-1 d-flex flex-column align-items-center bg-white">
                        <span className="material-icons" style={{fontSize:'5rem'}}>
                            token
                        </span>
                        <h4 className="font-weight-bold mt-3 mb-3">Your session has expired</h4>
                        <h6>Please click on refresh button to refresh the session.</h6>
                        <Button variant="dark" className="m-5" onClick={e => {
                            handleAuthentication();
                        }}>Refresh</Button>
        </div>
        
    </div>;
}