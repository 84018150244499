import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { Component } from 'react'
import {  Col, Modal, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import UserContext from '../context/UserContext';
import { dateChange,dateStringformat, dateTimeformat, getUserInfo } from '../utility/helperFunctions';
import { yyyymmddFormat } from './common/dateFormat';
import InputField from './common/input-fields/InputField';
import Loader from './common/loader/Loader';
import API from '../api/apis';
import BootstrapModal from './common/modal/BootstrapModal';
import Button from './common/Button'

class AmendmentModalEx extends Component {
    static contextType=UserContext
    state = {
        title: "",
        // owner_id:"",
        protocol_version_number:"",
        approval:"Awaiting Review",
        decision_date :  null,
        submissionId:"Submission-"+this.props.submissionId,
        isLoader:true,
    }
    get inputFieldsArray() {
        return [
            {
                label: "Protocol Version Number",
                type: "text",
                name: "protocol_version_number",
            },
           
            {
                label: "Approval",
                type: "dropdown",
                placeholder: "Awaiting Review",
                name: "approval",
                options: [
                    "None",
                    "Awaiting Review",
                    "Approved",
                    "Declined",
                    "Under Review"
                ]
            },
            {
                label: `Decision Date ${this.state.approval === "Approved" || this.state.approval === "Declined"  ? "*" : ""}`,
                name:  "decision_date",
                type:  "date",
                disabled: this.state.approval === "Approved" || this.state.approval === "Declined"  ? false : true,
                maxDate:new Date()
            },
            {
                label: "[EXT] Investigator Rationale",
                type: "textarea",
                name: "investigator_rationale",
            },
            {
                label: "Submission",
                type: "text",
                placeholder: "Enter Submission",
                disabled:true,
                name: "submissionId",

            },
            {
                label: "Lilly Feedback",
                type: "text-editor",
                name: "feedback",
                onChange: this.onEditorChange,
               },
           
            // {
            //     label: "Owner",
            //     type: "text",
            //     name: "owner_id",

            // },
            
           
           
            

        ];
    }
   async componentDidMount() {
        const { viewEditFlag, viewEditData } = this.props;
        // this.state.owner_id= await getUserInfo(this.context.userSystemId)
        if (viewEditFlag === "edit") {
            viewEditData["submissionId"]="Submission-"+viewEditData.submission_id;
            viewEditData["feedback"]= viewEditData.feedback !=="" ? this.getEditorValueFromHtml(viewEditData.feedback) : "";
            viewEditData["decision_date"] = viewEditData.decision_date !== "" && viewEditData.decision_date !== null ?
            dateStringformat(viewEditData.decision_date) : null;
            viewEditData["investigator_rationale"]=viewEditData.investigator_rationale !== null ? viewEditData.investigator_rationale.replace(/<[^>]+>/g, '') : "";
            viewEditData["createdby"] = viewEditData.created_by?.length ?await  getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
        viewEditData["updatedby"] = viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
            viewEditData["amendmentTitle"] =`Submission- ${viewEditData.submission_id} :Amendment- ${this.props.name}`
            this.setState({
                //set Your form state details hererev
                ...this.state,
                isLoader:false,
                // owner_id: await getUserInfo(this.context.userSystemId),
                ...viewEditData
            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
    }
    
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
        });
    };
    getEditorValueFromHtml = (html="") => {
        if(html){
            const blocksFromHTML = convertFromHTML(html);        
            const state = ContentState.createFromBlockArray(blocksFromHTML);
            return EditorState.createWithContent(state);
        }
        return "";
    }
    onInputChange = async(e,eo) => {
        let { name, value,type } = eo;
        if (eo.type === "date" || eo.type==="text-editor") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
       
        else 
        { this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }
    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
            error:{}
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = this.state.approval === "Approved" || this.state.approval === "Declined"  ? 
        { decision_date: this.state.decision_date } : {}
        const errors = {}
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors }
            });
        });
        return is_valid;
   }
   
    onSave = () => {
        if (this.formValidation()) {
            window.setTimeout(() => {
                this.props.handleAmendmentSave(this.state);
            }, 100);
        }
    }
    onUpdate = () => {
        if (this.formValidation()) {
            window.setTimeout(() => {
                this.props.handleAmendmentUpdate(this.state);
            }, 100);
        }
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
           
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={(e)=> this.onInputChange(e,field)}
                    />
                </Col>
            )
        });
    }
    clearFormData=(callBack,isSuccess)=>{
        this.setState({
            ...this.INITIAL_STATE
        },()=>{callBack(isSuccess);});
    }
    onHide=()=>{
        let {onClose}=this.props;
        if(onClose){
            
            this.clearFormData(onClose);
            
        }
    }

    render() {
        const { handleModalShowHide, showHideModal, viewEditFlag  } = this.props;
        let {modalProps,formData,fileInfo,isIUDOperation}=this.state;
        let loaderConfig={};
        if(isIUDOperation){
             loaderConfig={disabled:"disabled"};
        }
        
        return (
            <div>
                 <Modal show={true} size="lg">
                   <div> <Modal.Header >
                     <Modal.Title id="contained-modal-title-vcenter">
                     <h5 className="font-weight-bold"> Add a New Amendment</h5>
         <div style={{fontSize:'1rem'}}>
            <span>Please fill out this form to add a new Amendment </span>
         </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="new-flow-modal" >
                   <Row>
                            {this.renderInputFields()}
                        </Row>
                        {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Amendment has been created!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body={<><div>An error occured! Please try again later!</div></>}
                        changeShowState={this.changeShowState}
                    />}
                    </Modal.Body>
                   
                    <Modal.Footer >
                       <div  className="d-flex align-items-center justify-content-center w-100 p-2">
                        <Button {...loaderConfig} onClick={this.onHide} className="mr-2 p-2" style="danger w-100px">Cancel</Button>
                        {
                             <Button {...loaderConfig} onClick={this.onSave} className="p-2 d-flex align-items-center justify-content-center" style="success w-100px">
                                    <i className="bi bi-plus" ></i><span>Add</span>
                                </Button>
                        }</div>

                    </Modal.Footer>
                    </div>
                </Modal>
            </div>
        )
    }
}


export default AmendmentModalEx;