import React, { Component } from 'react';

import { Col, Container, Row, Button } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { ATTESTATION } from '../../../mapping';
import { DateTime } from 'luxon';
import UserContext from '../../../context/UserContext';
import { dateTimeStringformat } from '../../../utility/helperFunctions';

export default class Attestation extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        extNoConfidentialInformation:  this.props.submissionDetails.no_confidential_information || false,
        no_confidential_info_attested_date: this.props.submissionDetails.no_confidential_info_attested_date!== null ? dateTimeStringformat(this.props.submissionDetails.no_confidential_info_attested_date) : "",
        extNoSensitivePersonalInformation:  this.props.submissionDetails.no_sensitive_personal_information || false,
        no_sensitive_pi_attested_date:this.props.submissionDetails.no_sensitive_pi_attested_date !== null ?  dateTimeStringformat(this.props.submissionDetails.no_sensitive_pi_attested_date) : "",
        extIntellectualPropertyRights:  this.props.submissionDetails.intellectual_property_rights || false,
        intellectual_property_attested_date:this.props.submissionDetails.intellectual_property_attested_date!== null ?  dateTimeStringformat(this.props.submissionDetails.intellectual_property_attested_date) : "",
        extAcceptPrivacyStatement:  this.props.submissionDetails.accept_privacy_statement || false,
        accept_privacy_statement_attested_date:this.props.submissionDetails.accept_privacy_statement_attested_date!== null ?  dateTimeStringformat(this.props.submissionDetails.accept_privacy_statement_attested_date) : "",
        extelegeesawareofconfidentiality: this.props.submissionDetails.delegees_aware_of_confidentiality || false,
        delegees_aware_of_conf_attested_date:this.props.submissionDetails.delegees_aware_of_conf_attested_date!== null ? dateTimeStringformat(this.props.submissionDetails.delegees_aware_of_conf_attested_date)   : "",
        extDelegeesknowrequirements: this.props.submissionDetails.delegees_instructed_of_requirements ||  false,
        delegees_instructed_of_req_attested_date:this.props.submissionDetails.delegees_instructed_of_req_attested_date!== null ? dateTimeStringformat(this.props.submissionDetails.delegees_instructed_of_req_attested_date)  : "",
        extIattesttodevelopingthisstudy:  this.props.submissionDetails.attest_to_develop_study || false,
        attest_to_develop_study_date:this.props.submissionDetails.attest_to_develop_study_date !== null ? dateTimeStringformat(this.props.submissionDetails.attest_to_develop_study_date) : "",
        extIwillproperlyandsafelydestroy:  this.props.submissionDetails.properly_safely_destroy || false,
        properly_safely_destroy_date:this.props.submissionDetails.properly_safely_destroy_date !== null ? dateTimeStringformat(this.props.submissionDetails.properly_safely_destroy_date) : "",
        extNoPatentApplication:  this.props.submissionDetails.no_patent_application || false,
        no_patent_application_date:this.props.submissionDetails.no_patent_application_date !== null ? dateTimeStringformat(this.props.submissionDetails.no_patent_application_date)  : "",
        extInformLillyofPatent:  this.props.submissionDetails.inform_lilly_of_patent || false,
        inform_lilly_of_patent_date:this.props.submissionDetails.inform_lilly_of_patent_date !== null ? dateTimeStringformat(this.props.submissionDetails.inform_lilly_of_patent_date)  : "",
        extGiveLillyLicense:  this.props.submissionDetails.give_lilly_license || false,
        give_lilly_license_date:  this.props.submissionDetails.give_lilly_license_date !== null ? dateTimeStringformat(this.props.submissionDetails.give_lilly_license_date)  : "",
        hasChanged: false,
        successModal: false,
        errorModal: false,
        patchObject: {"id": this.props.submissionDetails.id}
    };
    componentDidMount() {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        })
    }
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
        this.setState({
            selectedRole: selectedRole,
            access: {
           view: true,
           create:  selectedRole.includes("administrator"),
           edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
           delete: selectedRole.includes("administrator"),
       },
        })
    }
    }
    get inputFieldsArray() {
        return  [
        {
            label: "[EXT] No confidential information",
            type: "checkbox",
            name:"extNoConfidentialInformation",
            helptext:"Attestation at Submission"
        },
        {
            label: "No confidential info Attested Date",
            type: "datetime-local",
            name:"no_confidential_info_attested_date",
        },
        {
            label: "[EXT] No sensitive personal information",
            type: "checkbox",
            name:"extNoSensitivePersonalInformation",
            helptext:"Attestation at Submission"
        },
        {
            label: "No sensitive PI Attested Date",
            type: "datetime-local",
            name:"no_sensitive_pi_attested_date",
        },
        {
            label: "[EXT] Intellectual property rights",
            type: "checkbox",
            name:"extIntellectualPropertyRights",
            helptext:"Attestation at Submission"
        },
        {
            label: "Intellectual property Attested Date",
            type: "datetime-local",
            name:"intellectual_property_attested_date",
        },
        {
            label: "[EXT] Accept Privacy Statement",
            type: "checkbox",
            name:"extAcceptPrivacyStatement",
            helptext:"Attestation at Submission"
        },
        {
            label: "Accept Privacy Statement Attested Date",
            type: "datetime-local",
            name:"accept_privacy_statement_attested_date",
        },
        {
            label: "[EXT] Delegees aware of confidentiality",
            type: "checkbox",
            name:"extelegeesawareofconfidentiality",
            helptext:"Attestation when adding Delegee"
        },
        {
            label: "Delegees aware of conf. Attested Date",
            type: "datetime-local",
            name:"delegees_aware_of_conf_attested_date",
        },
        {
            label: "[EXT] Delegees know requirements",
            type: "checkbox",
            name:"extDelegeesknowrequirements",
            helptext:"Attestation when adding Delegee"
        },
        {
            label: "Delegees instructed of req Attested Date",
            type: "datetime-local",
            name:"delegees_instructed_of_req_attested_date",
        },
        {
            label: "[EXT] I attest to developing this study",
            type: "checkbox",
            name:"extIattesttodevelopingthisstudy",
            helptext:"Attestation at  Proposal Submission(Uncheck to unlock Proposal)"
        },
        {
            label: "Developing this study Attested Date",
            type: "datetime-local",
            name:"attest_to_develop_study_date",
        },
        {
            label: "[EXT] I will properly and safely destroy",
            type: "checkbox",
            name:"extIwillproperlyandsafelydestroy",
            helptext:"Attestation at  Proposal Submission(Uncheck to unlock Proposal)"
        },
        {
            label: "Safely destroy study drug Attested Date",
            type: "datetime-local",
            name:"properly_safely_destroy_date",
        },
        {
            label: "[EXT] No Patent Application",
            type: "checkbox",
            name:"extNoPatentApplication",
        },
        {
            label: "No Patent Application Attest Date",
            type: "datetime-local",
            name:"no_patent_application_date",
        },
        {
            label: "[EXT] Inform Lilly of Patent",
            type: "checkbox",
            name:"extInformLillyofPatent",
        },
        {
            label: "Inform Lilly of Patent Attest Date",
            type: "datetime-local",
            name:"inform_lilly_of_patent_date",
        },
        {
            label: "[EXT] Give Lilly License",
            type: "checkbox",
            name:"extGiveLillyLicense",
        },
        {
            label: "Give Lilly License Attest Date",
            type: "datetime-local",
            name:"give_lilly_license_date",
        },
    ]
    }    

    checkboxChange = (e) => {
        const {name, checked} = e.target;
        this.setState({
            [name]: checked,
            patchObject:  {...this.state.patchObject, [ATTESTATION[name]]: checked},
            hasChanged: true
        });
    }

    onInputChange = (e,eo) => {
        if (eo.type === "datetime-local") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
       else 
       { const { name, value } = e.target;
        this.setState({
            [name]: value,
            patchObject:  {...this.state.patchObject, [ATTESTATION[name]]:  value + ":00.000Z"},
            hasChanged: true
        });}
    }
    
    
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    disabled = {field.disabled ? field.disabled : !this.state.access.edit }
                    value={this.state[field.name]??''}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? ((e) => { this.onInputChange(e, field) }): this.checkboxChange}
                    
                />
            </Col>
            )
        });
    }

    submitForm = async () =>  {
        
        try {
            const res = await API.patch("submission/update", this.state.patchObject);
            this.setState({
                successModal: true,
                patchObject: {"id": this.props.submissionDetails.id},
                hasChanged: false
            });
        }catch(err){
            this.setState({errorModal: true});
        }
    }
    attestationData=()=>{
        const newObject={};
        const obj = {...this.state.patchObject};
        Object.keys(obj).forEach((key) => {
            if (obj[key] === "" || obj[key] === null ) {
                return false;
            } 
            else{
                if(key.includes("date")){
                    newObject[key]= new Date(this.state[key]).toISOString()
                }
                else
                newObject[key] = obj[key];
            }
        });
        delete newObject.error;
        // this.state.patchObject={};
        return newObject;
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    render() {
        return (
            <Container>
            <Row>
                {this.renderInputFields()}
            </Row>
            {/* <div className="w-100 d-flex justify-content-center">
            <Button className="px-4" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                Save
            </Button>
        </div> */}
        {this.state.successModal &&
            <BootstrapModal 
                show={this.state.successModal} 
                header="Success"
                body="Updated successfully!"
                changeShowState={this.changeShowState}
            />
        }
        {this.state.errorModal &&
            <BootstrapModal 
                show={this.state.errorModal} 
                variant="danger"
                header="Ooops!!"
                body="An error occured! Please try again later!"
                changeShowState={this.changeShowState}
            />
        }
        </Container>
        )
    }
}
