import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import API from '../../../api/apis';
import {yyyymmddFormat} from  '../../common/dateFormat';
import { getUserInfo,dateTimeformat, dateStringformat } from '../../../utility/helperFunctions';
import Loader from '../../common/loader/Loader';

class ReviewPublicationModal extends React.Component {
    state = {
        compoundList: [],
        hasChanged: false,
        submission:"",
        submissionId: this.props.submissionId,
        reforcastTitle:"",
        approval:"Awaiting Review",
        reviewTitle:this.props.title,
        type:this.props.type,
        reviewNumber:"",
        isLoader:true,
        date_of_review:null
    }
    async componentDidMount() {
        const { viewEditData, viewEditFlag } = this.props;
       
        if (viewEditFlag === "edit") {
            viewEditData["date_of_review"] = viewEditData.date_of_review != "" && viewEditData.date_of_review != null ?
       dateStringformat(viewEditData.date_of_review) : null;
            viewEditData["publication_delayed_in_order"]=viewEditData.publication_delayed_in_order===true?"Yes":"No";
            viewEditData["are_results_of_all_objectives_included"]=viewEditData.are_results_of_all_objectives_included===true?"Yes":"No";
            viewEditData["createdby"] = viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
            viewEditData["updatedby"] = viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
            this.setState({
               
                ...this.state,
                submission:`Submission- ${viewEditData.submission_id}`,
                reviewNumber:`R-${viewEditData.id}`,
                ...viewEditData,
                isLoader:false
            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
    }

    get inputFieldsArray() {
        return  [
            {
                label: "Review Number ",
                type: "text",
                disabled:true,
                name: "reviewNumber",
               
            },
            {
                label: "Review Title",
                type: "text",
                disabled:true,
                name: "reviewTitle",
               
            },
            {
                label: "Record Type",
                type: "text",
                disabled:true,
                name: "type",
               
            },
           
            {
                label: "Submission",
                type: "text",
                disabled:true,
                name: "submission",
               
            },
            {
                label: "Type of Review",
                type: "text",
                disabled:true,
                name: "type",
               
            },
            {
                label: "Date of Review",
                type: "date",
                placeholder: "Enter Review Date",
                name: "date_of_review",
               
            },
            {
                label: "Publication Title",
                type: "textarea",
                name: "title",
               
            },
            {
                label: "Name of Reviewer or Committee",
                type: "text",
                placeholder: "Enter Name",
                name: "name_medical_reviewer_committee",
               
            },
           
            {
                label: "Delay due to IP/Patent?",
                type: "dropdown",
                name: "publication_delayed_in_order",
                placeholder: "Select Publication Delayed",
                options: ["Yes", "No"]
            },
            {
                label: "What is the concern & course of action?",
                type: "textarea",
                name: "concern_course_of_action",
               
            },
            {
                label: "Primary/ Secondary results included?",
                type: "dropdown",
                name: "are_results_of_all_objectives_included",
                placeholder: "Select Result Objective Inclusion",
                options: ["Yes", "No"]
            },        
            
            {
                label: "Comments",
                type: "textarea",
                name: "comments",
               
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
               disabled:true
            }
            ,{
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
               disabled:true
            }
        ];
    }


    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else
        { this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
        });
    }
    getHtmlFromEditorValue = (val) => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            lillyFeedback: {...this.state.lillyFeedback, [e]: this.getHtmlFromEditorValue(val)}
        });
    };

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={((e)=>{this.onInputChange(e,field)})}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleReforecastSave, viewEditFlag, handlePublicationUpdate} = this.props;
        const { submissionId } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg">
                {this.state.isLoader=== true ?
        <Loader /> :  <div>   <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit Publication Review` :  "New Reforecast"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                                viewEditFlag === "edit" ?
                                    <Button variant="primary" onClick={() => handlePublicationUpdate(this.state)}>
                                        Save
                     </Button> : <Button variant="primary" onClick={() => handleReforecastSave(this.state)}>
                                        Save
                    </Button>
                        }

                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default ReviewPublicationModal;