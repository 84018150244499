import React, { Component } from 'react'
import { THERAPEUTIC_AREA, REQUEST_TYPE, INDICATIONS, APPROVED, DECLINED } from '../../../consts';
import { Button, Col, Row, Card } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../../common/sticky-nav/StickyNav';
import InputField from '../../common/input-fields/InputField';
// import History from './History';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../../common/loader/Loader';
import {yyyymmddFormat} from  '../../common/dateFormat';
import { getUserInfo,dateTimeformat, dateStringformat, dateChange, enableWFActions } from '../../../utility/helperFunctions';
import UserContext from '../../../context/UserContext';
import ReforecastModal from './ReforecastModal';
import HistoryFile from './HistoryFile';

class ReforecastDetails extends Component {
    static contextType =UserContext ;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        hasChanged: false,
        submissionId: "",
        viewEditData:{},
        currencyList: [],
        showHideModal: false,
        viewEditFlag: null,
        reforcastName:this.props.match.params.rowIndex,
        auditData:[],
        trailData:[],
        usersList:[],
        fpv:null,
        decisionDate:null,
        fpet:null,
        lpet:null,
        fsr:null,
        lpetx:null,
        lpv:null
        // patchObject:{"id":this.props.match.params.reforcastId}
    }
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value":"0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value":"0 Days 0 Hours 20 Mins"
        }
       
    ];


    get inputFieldsArray() {
        return [{
                label: "Reforecast Title",
                type: "text",
                name: "reforcastTitle",
                disabled: true,
                edit:true
            },
            {
                label: "[EXT] Quarterly Enrollment",
                type: "text",
                name: "quarterly_enrollment",
                disabled: true,
            },
            {
                label: "Approval",
                type: "text",
                placeholder: "Awaiting Review",
                name: "approval",
                disabled: true,
            },
              {
                label: "[EXT] FPV",
                name:  "fpv",
                type:  "date",
                disabled: true,
            },
            {
                label: "Decision Date",
                name:  "decisionDate",
                type:  "date",
                disabled: true,
            }, {
                label: "[EXT] FPET",
                name:  "fpet",
                type:  "date",
                disabled: true,
            },    {
                label: "[EXT] LPET",
                name:  "lpet",
                type:  "date",
                disabled: true,
            },
               {
                label: "[EXT] LPETx",
                name:  "lpetx",
                type:  "date",
                disabled: true,
            },  {
                label: " [EXT] LPV",
                name:  "lpv",
                type:  "date",
                disabled: true,
            },   {
                label: "[EXT] FSR",
                name:  "fsr",
                type:  "date",
                disabled: true,
            }, 
            {
                label: "Lilly Feedback",
                type: "textarea",
                name: "feedback",
                disabled: true,
                // onChange: this.onEditorChange
            },  
            {
                label: "[EXT] Investigator Rationale",
                type: "textarea",
                name: "investigator_rationale",
                disabled: true,
                // onChange: this.onEditorChange
            },
            {
                label: "Submission",
                type: "text",
                name: "submission",
                disabled: true,
                
            },
           {
                label: "Last Updated By",
                type: "text",
                name: "last_updated_by",
                disabled: true,
            }, 
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled: true,
                edit:true
            }, {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
                disabled: true,
                edit:true
            }
        ];
    }
 async componentDidMount() {
      
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:   selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
        await this.getReforcastByExpenseId(this.props.match.params.reforcastId,this.props.match.params.rowIndex);
        this.getAuditList(this.props.match.params.reforcastId);
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:   selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
   
 
    getReforcastByExpenseId = async (id,name) => {
        try {
            const reforcastDetails = await API.get(`reforecast/getbyid/${id}`);
            const viewEditData = {...reforcastDetails.data};
           console.log("reforcastName",this.state.reforcastName)
            reforcastDetails.data["reforcastTitle"]="Submission-"+reforcastDetails.data.submissionId+" :Reforecast-"+this.props.match.params.rowIndex;
           
            reforcastDetails.data["createdby"]=reforcastDetails.data.created_by?.length ? await getUserInfo(reforcastDetails.data.created_by)+"  "+ dateTimeformat(reforcastDetails.data.createdAt) : "";
            reforcastDetails.data["updatedby"]=reforcastDetails.data.updated_by?.length > 0 ? await getUserInfo(reforcastDetails.data.updated_by) + "  " + dateTimeformat(reforcastDetails.data.updatedAt):reforcastDetails.data.createdby ;
            reforcastDetails.data["submission"]=`Submission- ${reforcastDetails.data.submissionId}`
            reforcastDetails.data["fpv"] = reforcastDetails.data.fpv != "" && reforcastDetails.data.fpv != null ?
            dateStringformat(reforcastDetails.data.fpv) : null;
            reforcastDetails.data["decisionDate"] = reforcastDetails.data.decisionDate != "" && reforcastDetails.data.decisionDate != null ?
            dateStringformat(reforcastDetails.data.decisionDate) : null;
            reforcastDetails.data["fpet"] = reforcastDetails.data.fpet != "" && reforcastDetails.data.fpet != null ?
            dateStringformat(reforcastDetails.data.fpet) : null;
            reforcastDetails.data["lpet"] = reforcastDetails.data.lpet != "" && reforcastDetails.data.lpet != null ?
            dateStringformat(reforcastDetails.data.lpet) : null;
            reforcastDetails.data["fsr"] = reforcastDetails.data.fsr != "" && reforcastDetails.data.fsr != null ?
            dateStringformat(reforcastDetails.data.fsr) : null;
            reforcastDetails.data["lpetx"] = reforcastDetails.data.lpetx != "" && reforcastDetails.data.lpetx != null ?
            dateStringformat(reforcastDetails.data.lpetx) : null;
            reforcastDetails.data["lpv"] = reforcastDetails.data.lpv != "" && reforcastDetails.data.lpv != null ?
            dateStringformat(reforcastDetails.data.lpv) : null;
            
            this.setState({
                ...reforcastDetails.data,
                showLoader: false,
                submissionId:reforcastDetails.data.submissionId,
                viewEditData:viewEditData
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
}
    handleReforecastUpdate = async (state) => {
        // state["date_received"] = state.date_received != "" && state.date_received != null ?
        //     new Date(state.date_received) : null;
        if(state.approval.toLowerCase()===APPROVED.toLowerCase() || state.approval.toLowerCase()===DECLINED.toLowerCase())
        {
            state.decisionDate=new Date();
        }
        state["fpv"] = state.fpv != "" && state.fpv != null ?
       dateChange(state.fpv) : null;
    state["decisionDate"] = state.decisionDate != "" && state.decisionDate != null ?
       dateChange(state.decisionDate) : null;
    state["fpet"] = state.fpet != "" && state.fpet != null ?
       dateChange(state.fpet) : null;
    state["lpet"] = state.lpet != "" && state.lpet != null ?
       dateChange(state.lpet) : null;
    state["fsr"] = state.fsr != "" && state.fsr != null ?
       dateChange(state.fsr) : null;
    state["lpetx"] = state.lpetx != "" && state.lpetx != null ?
       dateChange(state.lpetx) : null;
    state["lpv"] = state.lpv != "" && state.lpv != null ?
       dateChange(state.lpv) : null;
        // state["probability"] = state.probability != "" && state.probability != null ?
        //     parseInt(state.probability) : null;
        state.updated_by = this.context.userSystemId;
        try {
            const res = await API.post("reforecast/update", state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getReforcastByExpenseId(this.props.match.params.reforcastId,this.props.match.params.rowIndex);

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)
       
        this.setState({usersList: res.data});
    }
    getAuditList = async (id) => {
        try {
                const temp = [];
                const res = await API.get(`audit/reforecast/${id}`)
                console.log(res)
                await this.getTrailList();
    await this.getUserList();
                this.state.trailData.forEach(x => {
                    res.data.forEach(y => {
                        if (x.key.toLowerCase() === y.field.toLowerCase()) {
                            y["Field"] = x.label;
                            let fieldLabels=["updated_by"]
                            let fieldDates=[
                            "fpet","fpv","fsr",
                            "lpet",
                            "lpetx","lpv"]
                            if(fieldLabels.includes(y.field)){
                               let newValueText=y.new_value!=null ? this.state.usersList.filter(x=> x.system_id===(y.new_value))[0]:"" ;
                            y.new_value=newValueText!="" ? newValueText?.first_name+" "+newValueText?.last_name :"";
                          let  oldValueText=y.old_value!=null ? this.state.usersList.filter(x=> x.system_id===(y.old_value))[0]:"" ;
                            y.old_value=oldValueText!="" ? oldValueText?.first_name+" "+oldValueText?.last_name : "";
                                temp.push(y);
                            }
                            else if(fieldDates.includes(y.field)){
                                y.new_value=y.new_value!== null ? dateStringformat(y.new_value):"";
                                y.old_value=y.old_value!== null ? dateStringformat(y.old_value):"";
                                 temp.push(y); 
                            }
                            else if(y.field==="created_at"){
                                y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):"";
                                y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                                 temp.push(y);
                             }
                         else   {y.new_value=(y.new_value);
                            y.old_value=(y.old_value)
                               temp.push(y);}
                        }
    
                    })
                })
                let sortedReviews = temp.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                this.setState({
                    auditData: sortedReviews,
                })
    
            }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/reforecast`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
                hasChanged: true
            });
        }
        else 
        { this.setState({
            [e.target.name]: e.target.value,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},
            hasChanged: true
        });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }

    checkboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked ,
           
        
    })}
    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
        });
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            hasChanged: true
        });
    };
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.submissionId}`)
    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getReforcastByExpenseId(this.props.match.params.reforcastId,this.props.match.params.rowIndex);
        this.getAuditList(this.props.match.params.reforcastId);
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, success_body, errorModal,successModal } = this.state;
        if (!this.state.submission)
        return <Loader />
        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                        {" "}{this.state.submission}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Reforecast </h4>
                   
                   {this.state.access.edit&&enableWFActions() && <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav> 
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                                {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                                        Save
                                    </Button>
                                </div> */}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="data-cards">
                        <Card.Body>
                            <Tabs defaultActiveKey="history">
                                <Tab eventKey="history" title="History">
                               
                           <HistoryFile historyData={this.state.auditData}  historyTitle="Reforecast History"/>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {
                showHideModal &&
                <ReforecastModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleReforecastSave={this.handleReforecastSave}
                    handleReforecastUpdate={this.handleReforecastUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    submissionId={this.state.submissionId}
                   name={this.props.match.params.rowIndex}
                />
            }
            {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {/* {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            } */}
        </>
    }
}

export default withRouter(ReforecastDetails);
