import React from 'react'
import API from '../../../../api/apis';
import NewInstruction from '../NewInstruction'
import {isCoordinator, showMessage ,userPermissions } from '../../commonFunctions';
import UserContext from '../../../../context/UserContext';
import EditInstruction from './EditInstruction';
import { DEFAULT } from '../../../../TooltipInfo';
import ToolTipEx from '../../../common/ToolTipEx';
import DTAButton from '../../../common/Button/DTAButton';
export default class InstructionList extends React.Component{
    static contextType = UserContext;
    constructor(props){
        super(props);
        this.state={
            instructionList:[],
            userPermissions:{},
            is_coordinator:false,
            contentStyle:null,
            styleUpdated:false
        }
    }
    formType={
        INITIATE_INSTRUCTION:'INITIATE_INSTRUCTION'
    }
    componentDidMount(){
       
        let permissionSet = userPermissions(this.context);
        let is_coordinator=isCoordinator(this.context);
        this.setState({ userPermissions: permissionSet,is_coordinator },()=>{
            this.getInstructionList();
        });
    }
    componentDidUpdate(){
       
       let {styleUpdated,instructionList,contentStyle}=this.state;
       if(!styleUpdated&&instructionList.length>0 && !contentStyle){
            var slides = document.getElementsByClassName("xyz");
            if(slides&&slides.length>0){
                let contentStyle={};
                for (var i = 0; i < slides.length; i++) {
                    let documentObj=slides.item(i);
                    if(documentObj&&documentObj.clientHeight>50){
                        let key=documentObj.getAttribute("id");                  
                        contentStyle[key]=true;                 
                    }
                }
                if(Object.entries(contentStyle).length>0){
                    this.setState({contentStyle:contentStyle,styleUpdated:true});
                }
                
            }
       }  
       
    }
    getInstructionList=async()=>{
        try{
            this.setState({showLoader:true});
            const res = await API.get(`reviewConcept/instructionList/${this.props.reviewerId||''}`);           
            if(res&&res.data){
                let data=(res.data||[]).filter(p=>p.instructions_to);
                let is_coordinator = isCoordinator(this.context);
                if (!is_coordinator) {
                    data = data.filter((p) => p.instructions_to === this.context.userSystemId)
                } 
                this.setState({instructionList:data,showLoader:false,styleUpdated:false,contentStyle:null});
            }
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
       
    }
    EmptyInstruction=({showLoader})=>{
        return <blockquote className="blockquote">

            {showLoader && <div className="d-flex align-items-center justify-content-center p-2">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </div>}
            {
                !showLoader && <p className="mb-0">The instruction is not available </p>
            }
        </blockquote>
    }
    
    handleFormEvents=(option)=>{
        this.setState({activeForm:option});
    }
    handleModalCallBack=(response)=>{
        if(response===true){
            let callBack=(val)=>{
                this.setState({activeForm:''},()=>{
                    this.getInstructionList();
                });
              }
              showMessage({                  
                text: "Saved Successfully",
                icon: "success",
                button: "Ok",
              
              },callBack);
        } 
        else{
            this.setState({activeForm:''});
        }           
    }
    handleEditViewEvents=(e,data)=>{
        
        switch(e){
            case 'SAVE':this.setState({activeForm:null,formData:{}},()=>{
                this.getInstructionList();
            });break;
            case 'CANCEL':this.setState({activeForm:null,formData:{}});break;
            case 'EDIT':this.setState({activeForm:'EDIT',formData:data||{}});break;
        }
    }

    renderEditView=()=>{
        //handleEvents

        return <EditInstruction handleEvents={this.handleEditViewEvents} {...this.state.formData}></EditInstruction>
    }
    renderShowMore=()=>{
        let {showMoreData,showMore}=this.state;
        return <div  className='position-fixed d-flex align-items-center justify-content-center' style={{backgroundColor:'#DEDEDE50', left:0,top:0, width:'100vw',zIndex:'999', height:'100vh'}}>
                <div className="d-flex w-75 p-2 flex-column  bg-white rounded shadow border">
                <div className='d-flex  w-100 pr-2 pt-2'>
                    <span className='flex-grow-1 pl-3'><h4>Instruction for {showMoreData.reviewer_name}</h4></span>
                    <span onClick={e=>{
                        this.setState({showMore:false,showMoreData:{}});
                    }} className="material-icons" style={{cursor:'pointer'}}>
                        close
                    </span>
                </div>
                <div  className="w-100 p-3 border-top" style={{maxHeight:'350px',overflowY:'auto'}}>
                    <div style={{clear:'both'}} dangerouslySetInnerHTML={{__html: showMoreData.content || ""}}>

                    </div>
                </div>
                </div>

        </div>
    }
    render(){
        let {instructionList,userPermissions,is_coordinator,showMoreData,showMore}=this.state;
        return <div className="container">
            {this.state.activeForm==="EDIT"&&this.renderEditView()}
            {this.state.activeForm!="EDIT"&&<>
                <div className="d-flex justify-content-end align-items-center mt-3">
                    <div style={{ width: 'auto' }} className="d-flex justify-content-between align-items-center">
                        {is_coordinator &&<ToolTipEx tooltip={DEFAULT.ADD}>
                        <DTAButton  onClick={(e) => this.handleFormEvents(this.formType.INITIATE_INSTRUCTION)} icon="ADD" text={"Add Instruction"}></DTAButton>
                            </ToolTipEx>}
                    </div>
                </div>
                <ul className="list-unstyled mt-3" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {
                        instructionList && instructionList.length > 0 && instructionList.map((item, index) => {
                            return <li key={index} className="media mb-3 border-bottom pb-3">
                                <div className="media-body">
                                    <h5 className="mt-0 mb-1 font-weight-bold" style={{ fontSize: '0.9rem', maxHeight: '100px', overflowY: 'auto' }}>{item.reviewer_name}
                                        {is_coordinator && <span className="d-flex justify-content-end align-items-center"><ToolTipEx tooltip={DEFAULT.EDIT}><i onClick={e=>this.handleEditViewEvents("EDIT",item)} className="bi bi-pencil mr-2" style={{ fontSize: '1.1rem' }} /></ToolTipEx></span>}</h5>
                                        <div  className="xyz" id={"x_"+item.id} ref={ref=>this.contentPlaceholder=ref}>
                                             <div style={Object.assign({},{clear:'both'},this.state.contentStyle&&this.state.contentStyle["x_"+item.id]?{maxHeight:'50px',overflow:'hidden'}:{})}  dangerouslySetInnerHTML={{__html: item.content || ""}} >
                                        </div>
                                        {this.state.contentStyle&&this.state.contentStyle["x_"+item.id]&&<div className='font-weight-bold d-flex justify-content-center'>
                                            <span className='p-2' onClick={e=>{ this.setState({showMore:true,showMoreData:item});}}>Show More</span>
                                            </div>}
                                   </div>
                                </div>

                            </li>
                        })
                    }
                   
                    {showMore&&this.renderShowMore()}
                    {
                       (!instructionList || instructionList.length <= 0) && <this.EmptyInstruction showLoader={this.state.showLoader}></this.EmptyInstruction>
                    }
                </ul>
            </>}
            
                    {this.state.activeForm===this.formType.INITIATE_INSTRUCTION && <NewInstruction users={this.props.users} reviewerId={this.props.reviewerId} show={true} onClose={this.handleModalCallBack}></NewInstruction>}
                </div>
    }
}