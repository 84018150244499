import React, { Component } from 'react';
import { Col, Button } from 'react-bootstrap';
/** import $ from 'jquery';
import jqueryui from 'jquery-ui';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/selectable.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/selectable';
import 'jquery-ui/themes/base/resizable.css';
import 'jquery-ui/themes/base/draggable.css';
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/plugin';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/draggable'; **/
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import './datatable.css';
import DataFilter  from './DataFilter';
import TableSearch from './TableSearch';
import ToolTipEx from '../ToolTipEx';
import { DEFAULT } from '../../../TooltipInfo';
import cellEditFactory from 'react-bootstrap-table2-editor';

export default class DatatTableEx extends Component {
	state={
		expanded:[]
	}
	constructor(props){
			super(props);
			
			this.state={
				data:[...new Set( this.props.data||[])],
				filterSource:[...(this.props.filterSource||[])],
				activeFilter:[]
				
			}
	}
	/** componentDidMount(){
		$("th" ).resizable({
			minWidth: 28,
			handles:"e",
			resize: function (event, ui) {
				var sizerID = "#" + $(event.target).attr("id") + "-sizer";
				$(sizerID).width(ui.size.width);
			  }
		   });		  
	} **/
	componentDidUpdate(prevProps, prevState){
       //console.log("-----componentDidUpdate-------",prevProps.data != this.props.data)
        if (prevProps.data !== this.props.data) {
			//console.log("componentDidUpdate(prevProps, prevState)",this.props.data);
            this.setState({data:[...new Set( this.props.data||[])],filterSource:[...(this.props.filterSource||[])]},()=>{				
				if(this.props.autoFilter){
					this.renderFilterSource(this.refreshWithFilter);
				}
				else{
					this.refreshWithFilter();
				}				
			});;

          }
    }
	componentDidMount(){
		if(this.props.autoFilter){
			this.renderFilterSource(this.refreshWithFilter);
		}
	}
	refreshWithFilter=()=>{
		let{activeFilter}=this.state;
		if(activeFilter&&activeFilter.length>0){
			this.handleFilter(activeFilter);
		}
	}
	customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			&nbsp; Showing {from} to {to} of {size} Results
		</span>
	);
	emptyDataMessage = () => { return 'No data available '; }
	selectRow = {

		clickToSelect: true,
		onSelect: (row, isSelect, rowIndex, e) => {
			
		}
	};
	onSizePerPageChange = (sizePerPage, page) => {
		if (!!this.props.submissionSizePerPage) {
			localStorage.setItem("submissionSizePerPage", sizePerPage);
		}
	}
	options = {
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: '<',
		nextPageText: '>',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		hidePageListOnlyOnePage: true,
		alwaysShowAllBtns: true,
		paginationTotalRenderer: this.customTotal,
		disablePageTitle: true,
		sizePerPageList: [{
			text: '5', value: 5
		}, {
			text: '10', value: 10
		},
		{
			text: '25', value: 25
		},
		{
			text: '50', value: 50
		},
		{
			text: '100', value: 100
		},],
		sizePerPage: this.props.submissionSizePerPage || 5,
		onSizePerPageChange: this.onSizePerPageChange
	};
	handleFilter=(selection)=>{
		this.setState({activeFilter:selection});
		if(selection&&selection.length>0){
			let {data}=this.props;
			let filterOutput=[];
			selection.map((filterGroup,index)=>{
				if(filterGroup.key&&filterGroup.items&&filterGroup.items.length>0){
					let colSplit=filterGroup.key.split(".");
					let filterData=[];
					if(colSplit.length>1){                       
						 filterData=data.filter(x=>(filterGroup.items).includes(x[colSplit[0]][colSplit[1]]));
                    }
					else{
						 filterData=data.filter(x=>(filterGroup.items).includes(x[filterGroup.key]));						
					}
					if(filterData&&filterData.length>0){
						filterOutput.push(...new Set(filterData));
					}
				}
			});
			if(filterOutput&&filterOutput.length>0){
				if(this.props.filterChange){
					this.props.filterChange("NEW",filterOutput,selection)
				}
				this.setState({data:[...new Set(filterOutput)]});
			}

		}
		else{
			if(this.props.filterChange){
			this.props.filterChange("RESET",null);
			}
			this.setState({data:[...this.props.data]});
		}
	}
	handleOnExpand=(row, isExpand, rowIndex, e)=>{		
		if (isExpand) {
			this.setState(() => ({
			  expanded: [...(this.state.expanded||[]), row["key"]]
			}));
		  } else {
			this.setState(() => ({
			  expanded: (this.state.expanded||[]).filter(x => x !==row["key"])
			}));
		  }
	}
	expandRow = {
	//onlyOneExpanding: true,
	   expandByColumnOnly: true,
	   showExpandColumn: true,
		   renderer: row => {
			   let {handleRowDetails}=this.props;
			   return handleRowDetails?handleRowDetails(row):null;
		   },
		   onExpandAll:(isExpandAll, results, e)=>{
				this.setState({expanded:[-2]});
			   // if(this.props.onExpandAll){
			   // 	this.props.onExpandAll(isExpandAll);
			   // }
		   },
		   expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			   if (isAnyExpands) {
				 return <i className="bi bi-chevron-up"></i>
			   }
			   return <i className="bi bi-chevron-down"></i>;
			 },
		   expandColumnRenderer: ({ expanded }) => {
			   if (expanded) {
				 return (
				   <i className="bi bi-chevron-up"></i>
				 );
			   }
			   return (
				   <i className="bi bi-chevron-down"></i>
			   );
			 }
		 };
	renderFilterSource=(callback)=>{
		let {filterSource,data}=this.state;
		let {autoFilter,columns}=this.props;
		if(autoFilter){
			if(data&&data.length>0){
				let filterCollection= columns.filter(
					p => (!p.excludeFilter)).map((col, index) => {
					let columnValues = (data || []).map((item, key) => {
						if(col.dataField.split(".").length>1){
							return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
						}
						return item[col.dataField];
					});
					return {
						group:col.text,key:col.dataField,items:[...new Set(columnValues)]};
				});
				this.setState({filterSource:[...(filterCollection||[])]},()=>{if(callback){callback();}});		
			}
		}
	}
	
	render() {
		
		const { ExportCSVButton } = CSVExport;
		const { csvName, data, columns, pagination, rowEvents, selectRow, handleClearAllFilter,isExpander,rowClasses } = this.props;
		let removePagination= true;
		let {clearSearch}=this.state;
		let searchedText = localStorage.getItem("filter_" + this.props.id) || ""
		let expandRow={  };
		if(isExpander){
			expandRow={expandRow:this.expandRow};

			expandRow.onExpand=this.handleOnExpand;
			expandRow.expanded =(this.state.expanded||[]);
			
		}
		let cellEditProps = {};
		if (this.props.isCellEdit) {
			let {handleCellSave,handleAfterSave}=this.props;
			cellEditProps = { cellEdit: cellEditFactory({ mode: 'click',
			blurToSave: true,
			afterSaveCell: (oldValue, newValue, row, column) => {
				
				if(handleAfterSave){
					handleAfterSave(oldValue, newValue, row, column);
				}
			 },
			beforeSaveCell(oldValue, newValue, row, column, done) {
			
				if((newValue == null || typeof(newValue)==='undefined'||newValue.toString().trim().length<=0)&&column.isMandatory){
					done(false);
				}
				else if(column.customType=="number"&&((newValue == null || typeof(newValue)==='undefined')||isNaN(newValue))){
					done(false);
				}
				else if(handleCellSave){
					handleCellSave(oldValue, newValue, row, column,done);
				}				
				return { async: true };
			  }
			}) ,
			
		}
		} 
		const MySearch = (props) => {
			let input;
			const handleClick = () => {

				props.onSearch(input.value);

				if (this.props.id !== undefined) { localStorage.setItem("filter_" + this.props.id, input.value); }

				searchedText = input.value


			};
			return (
				<div className="margin-bottom">
					<input
						className="form-control"
						style={{ backgroundColor: 'white' }}
						ref={n => input = n}
						type="text"
						value={searchedText}
						onChange={handleClick}
						placeholder="Search"
					/>

				</div>
			);
		};
		const ExportCSVEx = (props) => {
			const handleClick = () => {
			  props.onExport();
			};
			return (
				// <i className="bi bi-file-arrow-down-fill mr-1" onClick={handleClick} style={{ fontSize: '1.5rem' }}></i>
				// <ToolTipEx tooltip={DEFAULT.DOWNLOAD}><span className="material-icons" onClick={handleClick} style={{ cursor: 'pointer' }}>file_download</span></ToolTipEx>
				<ToolTipEx tooltip={DEFAULT.DOWNLOAD}>
					<Button size="sm" 
                    onClick={handleClick} 
					style={{ cursor: 'pointer' }}
                    className="d-flex align-items-center" 
                    variant="outline-dark">
						<span className="material-icons mr-1 m-0"  >file_download</span>
                        <span style={{ fontSize: ' 0.75rem' }}>DOWNLOAD</span>
            		</Button>
					
				</ToolTipEx>
			);
		  };
		return (
			
			<ToolkitProvider
				wrapperClasses="table-responsive"
				bootstrap4
				keyField={columns[0]?.dataField}
				data={this.state.data}
				columns={columns}
				search={{ defaultSearch: searchedText }}
				exportCSV={
					{
						fileName: `${csvName??"New Csv File"}.csv`,
						blobType: 'text/csv;charset=ansi',
						onlyExportFiltered: true,
						exportAll: false
					}
				}
			>
				{
					props => {
						const dtProps={
							wrapperClasses:["table-responsive custom-datatable",removePagination?"fullscroll":""].join(" "),
							rowEvents:rowEvents,
							bordered:false,
							hover: true,
							 noDataIndication:this.emptyDataMessage(),
							filter:filterFactory(),
							rowClasses:rowClasses??""
						};
						
						return <Col md={12}>
						{/* <Col sm="4" className="ml-auto">
							<MySearch {...props.searchProps} />
						</Col>
						<Col sm={8} className="text-right">
							{handleClearAllFilter &&
								<button type="button" class="react-bs-table-csv-btn btn btn-default mr-2" onClick={() => handleClearAllFilter()}>
									<i class="bi bi-funnel-fill"></i> Clear All Filter </button>
							}
							{
								csvName ?
									<ExportCSVButton {...props.csvProps} ><i class="bi bi-cloud-arrow-down"></i> Export </ExportCSVButton>
									: null
							}
						</Col> */}
						<div className="row mb-1">
									<div className="col-md-12">
										<div className='d-flex align-items-center' style={{ paddingLeft: '15px', paddingRight: '15px' }}>
											<div className={`flex-grow-1 font-weight-bold ${(this.props.titleClass||"")}`} >
												<span>{this.props.title}</span>
											</div>
											<div style={{ width: 'auto' }} className="d-flex justify-content-between align-items-center">
												{/* <i className="bi bi-search mr-3" style={{ fontSize: '1rem' }}></i> */}
												{/* {handleClearAllFilter &&
													<button type="button" class="react-bs-table-csv-btn btn btn-default mr-2" onClick={() =>
													{ 
														this.setState({clearSearch:new Date().getTime()});
														handleClearAllFilter();
														
													}}>
														<i class="bi bi-funnel-fill"></i> Clear All Filter </button>
												} */}
												{handleClearAllFilter &&
												<Button size="sm" 
												onClick={() => 
												{
												  this.setState({clearSearch:new Date().getTime()});
														  handleClearAllFilter();
												}} 
												className="d-flex align-items-center ml-2 mr-2" 
												variant="outline-dark">
													{/* *** <span className=" material-icons mr-1 m-0">clear </span> */}
													<span 
													 className=" material-icons mr-1 m-0"><i className="bi bi-funnel-fill mr-1 m-0"></i> </span>
													{/* *** <i className="bi bi-funnel-fill mr-1 m-0"></i> */}
													<span style={{ fontSize: ' 0.75rem' }}>CLEAR ALL FILTER</span>
											</Button>}
												
												<TableSearch {...props.searchProps } clearSearch={clearSearch} alwaysOpenSearch={this.props.alwaysOpenSearch} tableId={this.props.id||''}></TableSearch>
												{this.state.filterSource&&this.state.filterSource.length>0&&<DataFilter activeFilterSelection={this.props.activeFilterSelection||[]} filterCallback={this.handleFilter} filterSource={this.state.filterSource}></DataFilter>}
												<ExportCSVEx { ...props.csvProps }></ExportCSVEx>
												{this.props.pageIcons?this.props.pageIcons:<></>}
											</div>
										</div>
									</div>
								</div>
						<div className="row">
						<Col md={12}>
							{pagination&&!removePagination ? !!selectRow ?
								(<BootstrapTable {...props.baseProps}  
									{...dtProps}
									{...expandRow}
									pagination={paginationFactory(this.options)}
									{...cellEditProps}
									selectRow={selectRow} />) :
								(<BootstrapTable {...props.baseProps} 
									{...dtProps}
									{...expandRow}
									{...cellEditProps}
									pagination={paginationFactory(this.options)}
									 />) :
								(<BootstrapTable {...props.baseProps}  {...cellEditProps}	{...dtProps} {...expandRow}/>)
							}
								{this.props.showLoader && <div style={{left:0,top:0,backgroundColor:"#DEDEDE30"}} className="d-flex w-100 h-100 align-items-center justify-content-center position-absolute p-2">
									<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								</div>}
						</Col>
						</div>
					</Col>
					}
				}
			</ToolkitProvider>
		)
	}
}


