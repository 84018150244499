import React,{useState,useEffect} from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../../common/Button'

export default (props)=>{
    return <Modal
        show={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header  className='border-0'>
            <Modal.Title id="contained-modal-title-vcenter">
               Error
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.children||null}
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex align-items-center justify-content-center'>
            <Button style='danger h-md' className="p-2 pr-3 pl-3 d-flex align-items-center" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
    </Modal>

}