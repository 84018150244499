import React, { Component } from 'react'
import { Col, Row, Container, Button } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { withRouter } from 'react-router-dom';
import { dateChange } from '../../../utility/helperFunctions';
class ReviewApplicationProposal extends Component {
    static contextType=UserContext
    state={
        date_of_review:null,
        name_medical_reviewer_committee:"",
        support_for_proposal:"",
        selectedFile: null,
        patchObject:{"submission_id":this.props.submissionId}
    }
  get inputFieldsArray(){
    return   [
        {
            label: "Name of Reviewer or Committee *",
            type: "text",
            placeholder: "Enter Name",
            name: "name_medical_reviewer_committee",
           
        },
        {
            label: "Date of Review *",
            type: "date",
            placeholder: "Enter Review Date",
            name: "date_of_review",
           
        },
        {
            label: "Does the study address an important problem in line with the goals of the IIR Program?",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "does_study_address_an_important_problem",
            options: ["Yes", "No"]
        },
        {
            label: "Could scientific knowledge or clinical care be advanced?",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "advance_scientific_or_clinical_care",
            options: ["Yes", "No"]
        },
        {
            label: "Is the framework, design, methods and analyses adequately developed and appropriate for the aim of this study?",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "is_framework_design_methods_developed",
            options: ["Yes", "No"]
        },
        {
            label: "Is the project novel in its approach, method of investigation or potential outcomes?",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "project_approach_method_outcome_novel",
            options: ["Yes", "No"]
        },
        {
            label: "Are the aims of the study original and innovative?",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "are_aims_of_study_original_innovative",
            options: ["Yes", "No"]
        },
        {
            label: "Rank the importance of this proposal on a scale of 1-5 (1= lowest importance and 5=highest importance)",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "rank_the_importance",
            options: [ "1", "2", "3","4", "5"]
        },
        
        {
            label: "Is the investigator well suited to carry out this study?",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "investigator_suited",
            options: ["Yes", "No"]
        },
        
        {
            label: "Are proposed enrollment and milestones achievable?",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "enrollment_milestones_achievable",
            options: ["Yes", "No"]
        },
        
        {
            label: "Is the requested budget appropriate for this study?",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "requested_budget_appropriate",
            options: ["Yes", "No"]
        },
        
        {
            label: "Do you recommend support for this proposal? *",
            type: "dropdown",
            placeholder: "Please Select An Option",
            name: "support_for_proposal",
            options: ["Yes", "No"]
        },
        {
            label: "Comments",
            type: "textarea",
            placeholder: "write comments...",
            name: "comments",
           
        }

    ]};
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else 
        { this.setState({[e.target.name]: e.target.value});
        if(e.target.value !== "")
            this.setState({error: { ...this.state.error, [e.target.name]: false},
                patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},});
           }
    }

    onSelectChange = (e, eo) => {
        if(e==="Yes" || e==="No")
    {    this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [eo.name || eo.target.name]: e==="Yes" ? true : false},
        }); }       
        else
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [eo.name || eo.target.name]: e},
            hasChanged: true
        }); 
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
        }); 
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    // onFileChange = (e) => {
    //     this.setState({selectedFile: e.target.files[0]})
    // }
    // uploadFile = () => {
    //     const formData = new FormData();
    //     formData.append(        
    //     'file',
    //     this.state.selectedFile,
    //     this.state.selectedFile.name
    //     );
    //     try{
    //         const res = API.post(`/user/proposals3/upload/Protocol/${this.props.submissionId}`, formData);
    //         this.setState({
    //             selectedFile: null,
    //             successFile: true
    //         })
    //     }catch(e){
    //         this.setState({
    //             selectedFile: null,
    //             errorFile: true
    //         })
    //         console.log(e);
    //     }
    // }
    formValidation = () => {
        let is_valid = true;
        const initialValues = { date_of_review: this.state.date_of_review,
            name_medical_reviewer_committee:this.state.name_medical_reviewer_committee,
            support_for_proposal:this.state.support_for_proposal }
        const errors = {}
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors }
            });
        });

        return is_valid;
    }
    submitForm=async()=>{
        if(this.formValidation()){
            console.log(this.state)
            this.state.patchObject["created_by"]=this.context.userSystemId;
            this.state.patchObject["date_of_review"] = this.state.patchObject.date_of_review != "" && this.state.patchObject.date_of_review != null ?
            dateChange(this.state.patchObject.date_of_review) : null;
            try {
                const res = await API.post("/review/proposal/add", this.state.patchObject);
                this.setState({successModal: true});
            }catch(err){
                this.setState({errorModal: true});
          }
        }
        
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.props.history.push(`/submissions/${this.props.submissionId}`)
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    key={field.label}
                    error={this.state.error?.[field.name]}
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange ||((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }

    render() {
        console.log(this.props)
        return (
            <Container fluid className="form-page">
                <h4 className="text-center mb-4">Proposal Review</h4><hr />
                <Row>
                    {this.renderInputFields()}
                    <div className="w-100 d-flex justify-content-center">
                        <Button className="ml-1 mt-4 px-3" variant="primary" type="submit" onClick={this.submitForm}>
                            Save
                        </Button>
                    </div>
                </Row>
                {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Created Successfully!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </Container>
        )
    }
}
export default withRouter(ReviewApplicationProposal)