import React from "react";
import { Modal } from 'react-bootstrap';

export default class SupportSuccessModal extends React.Component {

    render() {
        const { showHideSupportSuccessModal } = this.props;

        return (
            <div>
                <Modal show={showHideSupportSuccessModal} size="lg" className="modal-mdx"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div style={{ marginRight: "-1.3rem", "backgroundColor": "black", "color": "cyan" }}>
                        <Modal.Header style={{ border: 0, marginRight: "2.1rem" }}>
                            <Modal.Title className="w-100 d-flex justify-content-center">
                                <h3 style={{ fontSize: '1.2rem', marginLeft: "0.75rem" }} class='modal-title text-center w-100'>
                                    SUBMITTING SUPPORT HAS BEEN SUCCESSFULLY PROCESSED
                                </h3>
                            </Modal.Title>
                        </Modal.Header>
                    </div>
                </Modal>
            </div>
        )
    }
};
