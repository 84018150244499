import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import DatatableComponent from './common/datatable/DatatableComponent';
import Alert from 'react-bootstrap/Alert';
import UserContext from './../context/UserContext';
import API from './../api/apis';
import { dateStringformat } from './../utility/helperFunctions';
import DatatTableEx from './common/DataTableEx';
import { textFilter } from 'react-bootstrap-table2-filter';
import ToolTipEx from './common/ToolTipEx';
import { iirDateFormat } from '../utility/dateHelper';
class ToReview extends Component {
    static contextType = UserContext;
   
   
     constructor(props){
        super(props);
        this.state = {
            toReviewList: [],
            
            showLoader: false,
            filterSource:null,
            fixedSource:[],
            activeFilterSelection:[]
        }
        this._interval=React.createRef(null);
    }
    handleSubmissionDetails = (row) => {
        //this.props.history.push(`/submissions/${row.submissionId}`);
        sessionStorage.setItem("flow-req-from","toreview");
        this.props.history.push(`/toreview/details/${row.submissionId}`);

    }
    columnEvent={onClick: (e, column, columnIndex, row, rowIndex) => {
        //this.props.history.push(`/toreview/details/${row.submissionId}`);
        sessionStorage.setItem("flow-req-from","toreview");
        if(column.dataField!='is_follow'){
            this.props.history.push(`/toreview/details/${row.submissionId}`);  
        }
       
    }}
    columns = [
    {
        dataField: 'id',
        text: 'id',
        sort: true,
        hidden:true,
        csvExport: false 
        
    },
    {
        dataField: 'concept.compound',
        text: 'Compound',
        sort: true,
        
    },
    {
        dataField: 'concept.therapeutic_area',
        text: 'Therapeutic Area',
        sort: true
    },
    {
        dataField: 'review_type',
        text: 'Task',
        sort: true
    },
    {
        dataField: 'submissionId',
        text: 'Submission #',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={() => this.handleSubmissionDetails(row)}>{`Submission-${row.submissionId}`.padStart(7, '0')}</p>
            </>
        }
    },
    {
        dataField: 'submission.investigator_last_name',
        text: 'Pl Last Name',
        sort: true
    },
    {
        dataField: 'submission.stage',
        text: 'Stage',
        sort: true
    },
    {
        dataField: 'due_date',
        text: 'Due Date',
        sort: true,
        // formatter: (cell, row, rowIndex) => row.due_date ? dateStringformat(row.due_date ) : ""
    },
    {
        dataField: 'reviewer_name',
        text: 'Assigned Reviewer',
        sort: true,
        formatter: (cell, row, rowIndex) =>{
            return <ToolTipEx tooltip={row.reviewer_name}><div  style={{maxWidth:'300px',whiteSpace:"nowrap", overflow:'hidden',textOverflow:'ellipsis'}}>{row.reviewer_name}</div></ToolTipEx>
        }
    },
    {
        dataField: 'submission.status',
        text: 'Submission Status',
        sort: true
    },
    {
        dataField: 'is_follow',
        text: 'Follow',
        
        formatter: (cell, row, rowIndex) => {
            
            var  isStartVisible = row.is_follow ? "bi bi-star-fill" : "bi bi-star";
            return <>
        
                <i className={isStartVisible} onClick={() => this.handlestar(row)}
                    style={{ fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem" }}></i>
                     

</>
        }}
       
    ];
    

    componentDidMount() {
        this.setState({selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole},()=>{
            this.attachColumEvent();
            this.getToReviewList(this.context.userSystemId);
            this.maintainTabHighlight();
        });
    }
    componentWillUnmount(){
           
        if(this._interval){
            clearInterval(this._interval);
            this._interval=null;
        }
    }
    maintainTabHighlight=()=>{
        try{
          let li_nav_toreview=document.getElementById("li_nav_toreview");
          li_nav_toreview.classList.remove("active");
          li_nav_toreview.classList.add("active");
        }
        catch(exp){
  
        }
      }
    attachColumEvent=()=>{
        this.columns.map((column,index)=>{
            column.events=this.columnEvent;
            });
    }
    handlestar = async(row)=>{
        this.setState({showLoader:true});
        try{

            const stateObj = {
                id: row.id,
                is_follow: !row.is_follow
            }
            const follow = await API.post("review/reviewer/update", stateObj);
            
            if (follow.status == 200) {
                var res = await API.get(`review/reviewer/list/${this.context.userSystemId}`);
                //this.getToReviewList(this.context.userSystemId);
                let sourceData=this.getUserSpecificData(res.data||[]);
                let array = this.getPinnedData(sourceData || []);

                this.setState({
                    toReviewList: array,
                })
            }
            this.setState({showLoader:false});
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false}); 
        }
      
    
    }
    getUserSpecificData=(data)=>{
        let  selectedRole= Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        let filterData=data;
        if (selectedRole.includes("read_only_oncology")) {           
            filterData=data.filter(p=>p["concept"]&&p["concept"]["therapeutic_area"]&&p["concept"]["therapeutic_area"].toLowerCase()==="oncology");
        }
        else if (selectedRole.includes("read_only_diabetes")) {
            filterData=data.filter(p=>p["concept"]&&p["concept"]["therapeutic_area"]&&p["concept"]["therapeutic_area"].toLowerCase()==="diabetes & obesity");
        }
        else if (selectedRole.includes("read_only_biomeds")) {
            filterData=data.filter(p=>{
                if(p["concept"]&&p["concept"]["therapeutic_area"])
                {
                   return [
                        "none",
                        "immunology",
                        "neurodegenerative diseases",
                        "headache & pain"
                    ].includes(p["concept"]["therapeutic_area"].toLowerCase());                    
                }
                return false;
            });

        }
        return filterData;
    }   
    getToReviewList = async (id) => {
        this.setState({showLoader:true});
        try{
            const res = await API.get(`review/reviewer/list/${id}`);
            this.setState({showLoader:false});
            
            if(res&&res.data){
                let formattedData= res.data.map((record)=>({
                    ...record,
                    due_date : (record.due_date ? iirDateFormat(record.due_date) : record.due_date),

                }));              
                res.data= formattedData || [];
            }
            let sourceData=this.getUserSpecificData(res.data||[]);
            let filterSource=await this.getFilterSource({data:sourceData});
            let pinnedData=this.getPinnedData(sourceData);
            this.setState({ toReviewList: pinnedData || [] ,filterSource},()=>{
                this.runNotificationFetch();
            });
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
    }
    getPinnedData=(source)=>{
        const followtrue=source.filter(item=>item.is_follow==true);
         const followfalse=source.filter(item=>item.is_follow==false);
         const array=[...followtrue,...followfalse];
        return [...array];
        //  source.sort((x,y)=>{ return x.is_follow === true ? -1 : y.code === true ? 1 : 0; });
        //  source.map((item,index)=>{
        //     item.stamp=Date.now()
        //  })
        
        //  return [...source];
    }
    filterChange=(type,data,afs)=>{
        // console.log(this);
    }
    getFilterSource=async (res)=>{
        if(res&&res.data&&res.data.length>0){
            let filterCollection= this.columns.filter(p=>!p.hidden).map((col,index)=>{
                let columnValues=(res.data || []).map((item,key)=>{
                    if(col.dataField.split(".").length>1){
                        return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
                    }
                    return item[col.dataField];
                });
                return {group:col.text,key:col.dataField,items:[...new Set(columnValues)]};
            });
            return filterCollection;
        }
        return null;
          
    }
    handleRowClasses=(row, rowIndex)=>{             
        return "dx_"+row.submissionId;
    }
    runNotificationFetch=()=>{
        if(this._interval){
            clearInterval(this._interval);
        }
        this._interval=setInterval(this.collectNotifications,5000);
    }
    collectNotifications=()=>{
     
        let sysID=this.context.userSystemId;
        let notificationList=sessionStorage.getItem("notification_sub_" + sysID);
        if(notificationList){
            let parsedResponse=JSON.parse(notificationList);
            if(parsedResponse&&parsedResponse.length>0){
                let {  toReviewList } = this.state;
                parsedResponse.forEach((item)=>{
                    this.changeRowStyle(toReviewList,item.submission_id,false);
                });
            }
        }

    }
    changeRowStyle=(sourceData,keyField,isClear)=>{
        (sourceData || []).forEach((rowItem) => {
            let allNodes = document.getElementsByClassName(`dx_${keyField}`);
            if (allNodes && allNodes.length > 0) {
                allNodes[0].classList[isClear?"remove":"add"]('font-weight-bold');
            }
        });
    }
    render() {
        return <>
            {/* <div className="inpage-content"> */}
                {/* <Alert className="alert-head" key="submission" variant="light">
                    <Row>
                        <Col sm={6}>
                            <Alert.Heading>
                                <span><i class="bi bi-journal-medical" style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                                To Review({this.state.toReviewList.length})</Alert.Heading>
                        </Col>
                    </Row>
                </Alert> */}
                <Row>
                    <DatatTableEx
                        id="toReview"
                        data={this.state.toReviewList}
                        filterChange={this.filterChange}
                       // rowEvents={this.rowEvents}
                        columns={this.columns}
                        pagination="true"
                        title={"To Review"}
                        titleClass="title-lg"
                        showLoader={this.state.showLoader}
                        rowClasses={this.handleRowClasses}
                        filterSource={this.state.filterSource}
                        // activeFilterSelection={this.state.activeFilterSelection}
                        // pageIcons={[<i className="bi bi-plus"></i>]}
                        csvName="toReviewList" />
                </Row>
            {/* </div> */}
        </>
    }
}

export default withRouter(ToReview);