import React,{useState,useEffect,useCallback,useRef} from 'react'
import styles from './Tab.module.css';
export default ({source,onChange,notifications})=>{
   
    const renderNotification=(item)=>{
        if(notifications&&notifications.length>0){
            let exists=notifications.find(p=>p===item.key);
            if(exists){
                return <div style={{width:'8px',height:'8px',right:0,top:0}} className="rounded bg-primary position-absolute"></div>
            }
        }
        return <></>;
    }
 
    return <><ul className={['d-flex p-0 m-0']} style={{listStyle:"none"}}>
        {
            source&&source.map((item,index)=>{
                return <li onClick={e=>onChange("TAB-CHANGE",item)} className={['m-1 p-2 position-relative',(item.isActive?styles.tabActive:''),styles.tabItem].join(" ")} style={{fontSize:'1rem',cursor:'pointer'}} key={item.key}>
                    {item.text}
                    {renderNotification(item)}
                    </li>
            })
        }
</ul>

</>
}