import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { Col, Row, Dropdown, Button } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import API from '../../../api/apis';
import CompoundCountryModal from './CompoundCountryModal';
import swal from 'sweetalert'
import { ROW_SELECT_SINGLE } from 'react-bootstrap-table-next';
import DatatableComponent from '../datatable/DatatableComponent';
import BootstrapModal from '../modal/BootstrapModal';
import UserContext from '../../../context/UserContext';

class CompoundCountry extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access : {
            view : true,
            create : false,
            edit : false,
            delete : false,
        },
        investigators: [],
        compoundList: [],
        countryId:this.props.countryId,
        compoundId:0,
    }
    columns = [{
        dataField: 'name',
        text: 'Compound Name',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
               
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                     onClick={() => this.handleReforecastDetails(row)}>{row.name}</p>
            </>
            
        }
    },
    {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            return <>
               {/* <i className="bi bi-trash-fill" onClick={() => this.handleCountryDelete(row)}
                    style={{visibility: visibilityDelete, fontSize: "1.2rem", color: "black", padding: ".1rem", float: "right" }}></i> */}
                <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row)}
                    style={{ visibility: visibilityEdit ,fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>
            </>
        }
    }];
    handleReforecastDetails = (row) => {
        this.props.history.push(`/compounddetails`,{compoundId:row.value,countryName:this.props.countryName,countryId:this.props.countryId});
    }
    rowEvents = {
        onClick: (e, row, rowIndex) => {

        
        }
      };

    componentDidMount() {
        this.getCompoundList();
        // console.log(this.props);
        // this.setState({
        //     investigators: this.dataFormattingForTable()
        // });
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: !selectedRole.includes("operational_coordinator"),
                create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
            reloadTable: true,

        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: !selectedRole.includes("operational_coordinator"),
                    create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
                reloadTable: true,

            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getCompoundList = async () => {
        const res = await API.get(`seed/getCompoundbyCountry/${this.props.countryId}`)
        const resultArray = res.data.compounds.map(elm => ({ name:  (elm.name==="Other" ? `${elm.name}  | ${elm.therapeutic_area}` : `${elm.name}`), value: elm.id }));
        console.log(resultArray)
        this.setState(
            { 
                compoundList: resultArray,
               
            }
        );
    }
    getCompoundId = async (id) => {
        try {
            const expenseDetails = await API.get(`seed/compound/getbyid/${id}`);
            this.setState({
                viewEditData: expenseDetails.data,
                showLoader: false
            })
            console.log("countrybyId",this.state.viewEditData)
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    handleModalShowHide = async (flag, viewEditFlag, row) => {
        console.log(row)
          if (row && row.value && viewEditFlag === "edit") {
             this.state.compoundId=row.value;
             this.getCompoundId(row.value)
              this.setState({ showHideModal: flag, viewEditFlag});
              return true;
          }
          this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
      }
      getReforcastByExpenseId = async (id) => {
        try {
            const expenseDetails = await API.get(`/seed/getCompoundbyCountry/${id}`);
            this.setState({
                viewEditData: expenseDetails.data,
                showLoader: false
            })
            console.log("countrybyId",this.state.viewEditData)
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
      handleReforecastSave = async (state) => {
        state["countryId"]=this.props.countryId;
        state.created_by = this.context.userSystemId;
          try {
              const res = await API.post("seed/countrycompound/edit/add", state);
              this.setState({
                  success_body: "Created successfully!",
                  successModal: true,
                  hasChanged: false,
                  showHideModal: false,
                  viewEditFlag: null,
                  viewEditData: {},
              });
              this.getCompoundList();
  
          } catch (err) {
              this.setState({ errorModal: true });
          }
      }
      handleReforecastUpdate = async (state) => {
        state["countryId"]=this.props.countryId;
        state.updated_by = this.context.userSystemId;
          try {
              const res = await API.post(`seed/countrycompound/edit/update/${this.state.compoundId}/${this.props.countryId}`, state);
              this.setState({
                  success_body: "Updated successfully!",
                  successModal: true,
                  hasChanged: false,
                  showHideModal: false,
                  viewEditFlag: null,
                  viewEditData: {},
              });
              this.getCompoundList();
  
          } catch (err) {
              this.setState({ errorModal: true });
          }
      }
      handleCountryDelete = async (row) => {
          console.log(row)
          swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this data!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                  try {
                      const res =  API.delete(`seed/countrycompound/delete/${this.state.compoundId}/${this.props.countryId}`);
                      swal("Deleted Successfully", {
                          icon: "success",
                        });
                        this.getCompoundList();
                  }
                   catch (err) {
                      this.setState({ errorModal: true });
                  }
                
              } 
            });
         
      }
      changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
       this.getCompoundList();
    }
    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal,deleteModal } = this.state;
        return <>
            <div className="inpage-content">
                <Alert className="alert-head" key="submission" variant="light">
                    <Row>
                        <Col sm={6}>
                        <Alert.Heading>
                            <span><i  style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                             Compound</Alert.Heading>
                        </Col>
                        <Col sm="auto" className="ml-auto">
                        {this.state.access.edit &&<Link className="new-button-link" onClick={() => this.handleModalShowHide(true, null, {})}>
                                <span>
                                    <i 
                                    class="bi bi-plus-square-fill" 
                                    style={{ fontSize: "1.2rem", margin: ".1rem", padding: ".1rem" }} onClick={() => this.handleModalShowHide(true, null, {})}
                                    ></i></span>
                                Map Compound 
                            </Link>}
                        </Col>
                    </Row>
                </Alert>
                <Row>
                    {/* <Col md={6}>
                        <Dropdown  className="record_view_dropdown">
                            <Dropdown.Toggle variant="default"  id="dropdown-basic">
                                Recently Viewed
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Recently Viewed</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col> */}

                    <DatatableComponent id="compoundCountry" data={this.state.compoundList} columns={this.columns} rowEvents={this.rowEvents} pagination="true"  />
                </Row>
            </div>
        {showHideModal && 
            <CompoundCountryModal
            showHideModal={showHideModal}
            handleReforecastSave={this.handleReforecastSave}
            handleReforecastUpdate={this.handleReforecastUpdate}
            handleModalShowHide={this.handleModalShowHide}
            viewEditFlag={viewEditFlag}
            viewEditData={viewEditData}
            countryName={this.props.countryName}
            countryId={this.props.countryId}
            
           />}
            {this.state.successModal &&
            <BootstrapModal
                show={successModal}
                header="Success"
                body={success_body}
                changeShowState={this.changeShowState}
            />
        }
       {this.state.errorModal &&
            <BootstrapModal
                show={errorModal}
                variant="danger"
                header="Ooops!!"
                body="An error occured! Please try again later!"
                changeShowState={this.changeShowState}
            />
        } 
        </>
         
    }
}

export default withRouter(CompoundCountry);