import React from 'react'
import { Col, Row, Button, Table, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import InputField from '../../common/input-fields/InputField';
import '../../Finance/MilestoneExpenses/ExpenseDetails/index'
import UserContext from '../../../context/UserContext';
import { isCoordinator } from '../../ToReviewDetails/commonFunctions';
export default class CompoundEx2 extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            invoiceItems: this.props.CountryData.compounds || [],
            error: {
                name: null
            },
            formData: {
                name: null
            },
            compound_ids: this.props.compound_ids,
            selected_id: [],
            compound_deleted_ids: this.props.compound_deleted_ids
        };
    }
    componentDidMount = () => {

        let is_coordinator = isCoordinator(this.context);
        this.setState({ is_coordinator });
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (
            (prevProps.CountryData != this.props.CountryData))
            this.setState({
                invoiceItems: this.props.CountryData.compounds || [],
            })

    }
    getInvoiceItems = () => {
        return this.state.invoiceItems || [];
    }
    handleEvents = (option, data, changeField) => {
        let { invoiceItems, formData, compound_ids,compound_deleted_ids } = this.state;
        let emptyFormData = {
            name: ''
        };
        switch (option) {
            case 'ADD':
                if (this.doValidate()) {
                    return false;
                }
                let temp_id = invoiceItems.length + 1;
                //console.log("test",invoiceItems,formData,this.state.invoiceItems.filter(item => item.Object === formData.name.value))
                    
                    
                    
                   
                if(!this.state.invoiceItems.filter(item => item.id === formData.name.value)[0]){
                    
                    
                    
                   
                invoiceItems.push({
                    id:formData.name.value,
                    name: formData.name.name,
                    temp_id
                })
                
                compound_ids.push(formData.name.value)}
               
               
                this.setState({
                    invoiceItems,
                    formData: emptyFormData,compound_ids
                   
                });
                
                break;
            case "REMOVE":
                invoiceItems.splice(
                    invoiceItems.indexOf(data), 1
                );
                
                compound_deleted_ids.push(data.id);

                this.setState({
                    invoiceItems:[...invoiceItems], formData: emptyFormData, compound_deleted_ids
                });
                break;
        }
    }
    onBeforeDelete = (callback) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: false,
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "Ok",
                    value: true,
                    visible: true,
                    className: "btn-danger",
                    closeModal: true
                }
            }
        })
            .then(async (replace) => {
                if (replace) {
                    if (callback) {
                        callback();
                    }
                }
            });
    }
    handleValueChange = (e, name) => {


        let { formData } = this.state;
       
        formData[name] = e;
        

        this.setState({
            formData: { ...this.state.formData }
        });
    }
    doValidate = () => {
        let { invoiceItems, formData } = this.state;
        let { name } = formData;
        let error = [];
        let hasError = false;
        let customText = "Required";
        if (!name || name.toString().trim().length <= 0) {
            error.name = customText;
            hasError = true;
        }
        this.setState({ error: { ...error } });
        return hasError;
    }
    renderFields = () => {
        let { formData, error, is_coordinator } = this.state;

        return <><Row>
            <Col md={10}>

                <InputField disabled={!is_coordinator} name="name"  value={formData["name"]} options={this.props.CompoundList}
                    onSelect={this.handleValueChange} label="Compound Name*" type="searchableDropdown"></InputField>
            </Col>
            <Col md={2}>
                <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                    <Button disabled={!is_coordinator} onClick={e => this.handleEvents("ADD")} size="sm"
                        className="d-flex align-items-center mt-3"
                        variant="dark">
                        <span className="material-icons mr-1 m-0">add </span>
                        <span style={{ fontSize: ' 0.75rem' }}>ADD</span>
                    </Button>
                </div>

            </Col>
        </Row>
            <Row>
                <Col md={5}>
                </Col>
                <Col md={3}>
                    {error["name"] && <p style={{ marginTop: '-1rem' }} className="text-danger ">{error["name"]}</p>}
                </Col>
                <Col md={2}>
                </Col>
            </Row>
        </>
    }

    renderActionButton = (item, key) => {
        let { is_coordinator } = this.state;
       
        return <div classname="d-flex w-10 h-100 align-items-center justify-content-enter">

            {is_coordinator && <span onClick={e => {
                this.onBeforeDelete(() => {
                    this.handleEvents("REMOVE", item, "is_deleted")
                });
            }
            } className="material-icons" style={{ cursor: 'pointer' }}>
                close
            </span>}
        </div>
    }
    renderTable = () => {
        let { invoiceItems } = this.state;
        return <div style={{ maxHeight: '400px', overflowY: 'auto', fontSize: '.75rem' }}><Table responsive="sm">
            <thead style={{ position: 'sticky', top: -2, backgroundColor: '#FFF', zIndex: 1, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <tr>
                    <th className='bg-white w-75'>Compound</th>
                    <th className='text-center  bg-white'>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    invoiceItems && invoiceItems.map((item, index) => {
                        return <tr>

                            <td>{item.name ?? ""}</td>

                            <td className="text-center">{this.renderActionButton(item, index)}</td>
                        </tr>
                    })
                }
            </tbody>
        </Table>
        </div>;
    }
    render() {
        let { formData } = this.state;
        return <div className='d-flex flex-column mt-2'>
            <h5 className="pl-1">Compound</h5>
            {this.renderFields()}
            {this.renderTable()}
        </div>
    }
}