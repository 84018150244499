import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { Col, Row, Dropdown ,Button} from 'react-bootstrap';
import DatatableComponent from '../datatable/DatatableComponent';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import API from '../../../api/apis';
import CountryModal from './countryModal';
import swal from 'sweetalert';
import UserContext from '../../../context/UserContext';
import BootstrapModal from '../modal/BootstrapModal';
import Loader from '../loader/Loader';
class Country extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access : {
            view : true,
            create : false,
            edit : false,
            delete : false,
        },
        investigators: [],
        countryList: [],
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        data: [],
        error: false,
        successModal: false,
        errorModal: false,
        isLoading :  false,
       
    }
    columns = [{
        dataField: 'name',
        text: 'Country Name',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                     onClick={() => this.handleDrugDeliverySiteDetails(row)}>{row.name}</p>
            </>
        }
    },
    { 

        dataField: 'value',//to prevent duplication 
        hidden: true, 
        csvExport: false 

    }, 
     {
        dataField: '',
        text: "Actions",
        csvExport : false,
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            return <>
               {/* <i className="bi bi-trash-fill" onClick={() => this.handleCountryDelete(row)}
                    style={{visibility: visibilityDelete , fontSize: "1.2rem", color: "black", padding: ".1rem", float: "right" }}></i> */}
                <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row,rowIndex)}
                    style={{visibility: visibilityEdit , fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>
            </>
        }
    }];
    rowEvents = {
        onClick: (e, row, rowIndex) => {
          console.log(row['Investigator Id']);
         
        }
      };

    componentDidMount() {
        this.getCountryList();
  
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
            },
            reloadTable: true,

        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                },
                reloadTable: true,

            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getCountryList = async () => {
        this.setState({
            isLoading : true, 
        })
        const res = await API.get("seed/country/getall");
        if(res.data && Object.keys(res.data).length>0)
        {const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ countryList: resultArray,isLoading:false });}
        else 
            { this.setState(
                { 
                    countryList: [],
                   
                }
            );}

    }
    handleModalShowHide = async (flag, viewEditFlag, row) => {
      console.log(row)
        if (row && row.value && viewEditFlag === "edit") {
            await this.getReforcastByExpenseId(row.value)
            this.setState({ showHideModal: flag, viewEditFlag });
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
    }
    handleDrugDeliverySiteDetails = (row) => {
        this.props.history.push(`/country/details/${row.value}`,{countryId:row.value,countryName:row.name});
    }
    handleReforecastSave = async (state) => {
        state.created_by = this.context.userSystemId;
        try {
            const res = await API.post("seed/country/edit/add", state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getCountryList();

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleReforecastUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        try {
            const res = await API.post("seed/country/edit/update", state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getCountryList();

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleCountryDelete = async (row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try {
                    const res =  API.delete(`seed/country/delete/${row.value}`);
                    swal("Deleted Successfully", {
                        icon: "success",
                      });
                    this.getCountryList();
                }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
                this.getCountryList();
            } 
          });
       
    }
    getReforcastByExpenseId = async (id) => {
        try {
            const expenseDetails = await API.get(`seed/country/getbyid/${id}`);
            this.setState({
                viewEditData: expenseDetails.data,
                showLoader: false
            })
            console.log("countrybyId",this.state.viewEditData)
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getCountryList();
    }
    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal,deleteModal,isLoading } = this.state;
        return <>
            <div className="inpage-content">
            {isLoading && <Loader />}
                {!isLoading &&  <Alert className="alert-head" key="submission" variant="light">
                    <Row>
                        <Col sm={6}>
                        <Alert.Heading>
                            <span><i className="bi bi-globe" style={{ fontSize: "1.5rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                             Countries</Alert.Heading>
                        </Col>
                        <Col sm="auto" className="ml-auto">
                       {this.state.access.create && <Link className="new-button-link" onClick={() => this.handleModalShowHide(true, null, {})}>
                                <span>
                                    <i 
                                    class="bi bi-plus-square-fill" 
                                    style={{ fontSize: "1.2rem", margin: ".1rem", padding: ".1rem" }} onClick={() => this.handleModalShowHide(true, null, {})}
                                    ></i></span>
                                New Country
                            </Link>}
                        </Col>
                    </Row>
                </Alert>}
                {!isLoading &&   <Row>
                    {/* <Col md={6}>
                        <Dropdown  className="record_view_dropdown">
                            <Dropdown.Toggle variant="default"  id="dropdown-basic">
                                Recently Viewed
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Recently Viewed</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col> */}

                    <DatatableComponent id="countryList" csvName="Countries" data={this.state.countryList} columns={this.columns} rowEvents={this.rowEvents} pagination="true"  />
                </Row>}
            </div>
           { showHideModal &&
               <CountryModal
            showHideModal={showHideModal}
            handleModalShowHide={this.handleModalShowHide}
            handleReforecastSave={this.handleReforecastSave}
            handleReforecastUpdate={this.handleReforecastUpdate}
            viewEditFlag={viewEditFlag}
            viewEditData={viewEditData}
           />}
            {this.state.successModal &&
            <BootstrapModal
                show={successModal}
                header="Success"
                body={success_body}
                changeShowState={this.changeShowState}
            />
        }
       {this.state.errorModal &&
            <BootstrapModal
                show={errorModal}
                variant="danger"
                header="Ooops!!"
                body="An error occured! Please try again later!"
                changeShowState={this.changeShowState}
            />
        } 
       </>
    }
}

export default withRouter(Country);