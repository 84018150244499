import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import API from '../../../api/apis';
import { dateTimeformat, getUserInfo } from '../../../utility/helperFunctions';
import InputField from '../../common/input-fields/InputField';
import Loader from '../../common/loader/Loader';

class DrugOptionsForSiteModal extends React.Component {
    
    state = {
        compoundList: [],
        hasChanged: false,
        strengthList:[],
        presentationList:[],
        countList:[],
        name:this.props.name,
        compound:"",
        count:"",
        strength:"",
        presentation:"",
        isLoader:true
    }
    async componentDidMount() {
         await this.getCompoundList();
       await this.getStrengthList();
       await this.getPresentationList();
       await this.getCountList();
        const { viewEditData, viewEditFlag,name } = this.props;
        viewEditData["record_Id"] = "Site-Drug-"+ viewEditData.id;
        viewEditData["compound"] = this.state.compoundList.filter(item => item.value === viewEditData.compound_id)[0];
        viewEditData["strength"] = this.state.strengthList.filter(item => item.value === viewEditData.strength_id)[0];
        viewEditData["presentation"] = this.state.presentationList.filter(item => item.value === viewEditData.presentation_id)[0];
        viewEditData["count"] = this.state.countList.filter(item => item.value === viewEditData.count_id)[0];
        viewEditData["createdby"] =viewEditData.created_by?.length ?  await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt): "";
        viewEditData["updatedby"] =viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
        
        if (viewEditFlag === "edit" ) {
            this.setState({
                ...this.state,
                ...viewEditData,
                isLoader:false
            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
    }

    get inputFieldsArray() {
        const { viewEditFlag } = this.props;
        return [{
            type: "alert",
            label: "Information",
            variant: "secondary"
        },
        {
            label: "Compound *",
            type: "searchableDropdown",
            name: "compound",
            options: this.state.compoundList,
            onSelect: this.onSearchSelect,
            
        },
        {
            label: "Site *",
            type: "text",
            name: "name",
            // options: this.state.siteList,
            // onSelect: this.onSearchSelect,
            disabled:true 
        },
        {
            label: "Count *",
            type: "searchableDropdown",
            name: "count",
            options: this.state.countList,
            onSelect: this.onSearchSelect3,
           
        },
        {
            label: "Strength *",
            type: "searchableDropdown",
            name: "strength",
            options: this.state.strengthList,
            onSelect: this.onSearchSelect1,
            
        },
        {
            label: "Presentation *",
            type: "searchableDropdown",
            name: "presentation",
            options: this.state.presentationList,
            onSelect: this.onSearchSelect2,
           
        },
        {
            label: "Record ID",
            type: "text",
            name: "record_Id",
            disabled: true
        },
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled: true,
            viewEdit: true
        },
        {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled: true,
            viewEdit: true
        }
        ];
    }
    getCompoundList = async () => {
        const res = await API.get(`seed/compound/list`)
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
        this.setState(
            { 
                compoundList: resultArray,
               
            }
        );
    }
    getStrengthList = async () => {
        const res = await API.get(`seed/strength/data`)
        const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id}));
        console.log("strength",resultArray)
        this.setState(
            { 
                strengthList: resultArray,
               
            }
        );
    }
    getPresentationList = async () => {
        const res = await API.get(`seed/presentation/data`)
        const resultArray = (res.data?.data || []).map(elm => ({ name: elm.name, value: elm.id}));
        
        this.setState(
            { 
                presentationList: resultArray,
               
            }
        );
    }
    getCountList = async () => {
        const res = await API.get(`seed/count/data`)
        const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id}));
        console.log("count",resultArray)
        this.setState(
            { 
                countList: resultArray,
               
            }
        );
    }

    onInputChange = (e) => {
        this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            compound_id: e.value,

        });
    }
    onSearchSelect1 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            strength_id: e.value,

        });
    }
    onSearchSelect2 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            presentation_id: e.value,

        });
    }
    onSearchSelect3 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            count_id: e.value,

        });
    }
    formValidation = () => {
        let is_valid = true;
        
             const   initialValues={
                   
                   compound:this.state.compound,
                   count: this.state.count,
                   strength:this.state.strength,
                   presentation:this.state.presentation,
                  
                  }
                  const errors = {}
                  Object.keys(initialValues).forEach((key) => {
                    if (initialValues[key] === "" || initialValues[key] === null ) {
                        errors[key] = true;
                        is_valid = false;
                        
                    } 
                    this.setState({
                        error:  { ...errors}
                    });
                });
                  
        return is_valid;

    }
    onSave = () => {

        if(this.formValidation(this.state)){
          this.props.handleDrugOptionsForSiteSave(this.state);
        }
         
      }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.viewEdit && this.props.viewEditFlag !=  "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleDrugOptionsForSiteSave, viewEditFlag, handleDrugOptionsForSiteUpdate} = this.props;
        const { record_Id } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg" className="drugOptionForSiteModal">
                {this.state.isLoader=== true ?
        <Loader /> :  <div>   <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit Drug Options For Site` : viewEditFlag === "view" ?
                                    `View ${record_Id}` : "New Drug Options For Site"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                            viewEditFlag != "view" ?
                                viewEditFlag === "edit" ?
                                    <Button variant="primary" onClick={() => handleDrugOptionsForSiteUpdate(this.state)}>
                                        Save
                     </Button> : <Button variant="primary" onClick={this.onSave}>
                                        Save
                    </Button> : null
                        }

                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default DrugOptionsForSiteModal;