const  validator={
    milestone:'',
    estimated_date:'',
    invoice_number:'',                                           
    estimated_amount:'',
    invoice_received_date:'',
    actual_amount:'',
    new_milestone:'',
    internal_comments:''
};
const column_mapper={
    "milestone":"milestone",
    'estimated_date':"estimated_date",
    'invoice_number':"invoice",                                           
    'estimated_amount':"estimated_cost",
    'invoice_received_date':"date_received",
    'actual_amount':"actual_amount",
    'new_milestone':"new_milestone",
    'internal_comments':"comments"
}
const column_captions={
    "milestone":"Milestone",
    'estimated_date':"Estimated Date",
    'invoice':"Invoice Number",                                           
    'estimated_cost':"Estimated Amount",
    'date_received':"Invoice Received Date",
    'actual_amount':"Actual Amount",
    'new_milestone':"New Milestone",
    'comments':"Internal Comments",
    'currency_name':"Local Currency"
}
const error_messages_parts={
    "CONTRACT EXECUTION":'Contract Execution (Contract Complete Estimate Date)',
    "FPV":'FPV (Estimated Date) ',
    "LPETX":'LPETx (Estimated Date) ',
    "LPET":'LPET (Estimated Date)',
    "FINAL STUDY REPORT":'Final Study Report (Estimated Date)',
    "LPV":'LPV (Estimated Date)',
    "MILESTONE_PART":"value should not be empty in Milestones section under details.",
    "NEW_MILESTONE":"Milestone does not exist for the submission. Please indicate in upload file which milestones are new.",
    "DATE":"Date format is not valid. Please use the following format: dd/mmm/yyyy. Example: 10/Dec/2022.",
    "ESTIMATED_AMOUNT":"Entered value for estimated cost should be a number.",
    "ACTUAL_AMOUNT":"Entered value for actual amount should be a number.",
    "OTH":"Fields mismatch: only fields in the template are allowed. Please remove any extra fields from the uploaded file.",
    "EMPTY_MILESTONE":"Contains Empty Milestone"
}
export {validator,column_mapper,column_captions,error_messages_parts}