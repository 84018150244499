import React, { Component } from 'react'
import { Col, Row, Button, Nav } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import DatatableComponent from '../../../common/datatable/DatatableComponent';
import ReforecastModal from '../ReforecastModal';
import API from '../../../../api/apis';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import UserContext from '../../../../context/UserContext';
import { dateChange, dateStringformat, dateTimeformat,enableWFActions,getUserInfo } from '../../../../utility/helperFunctions';
import { APPROVED, DECLINED } from '../../../../consts';
import swal from 'sweetalert';
class Reforecast extends Component {

    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        data: [],
        error: false,
        successModal: false,
        errorModal: false,
        reforcastIndex:"",
       
    }

    componentDidMount() {
        this.getReforcastDetailsBySubmissionId(this.props.submissionDetails.id);

        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:   selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:   selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    getReforcastDetailsBySubmissionId = async (submissionid) => {
        try {
            const res = await API.get(`reforecast/submission/${submissionid}`)
            console.log("responsedelegee", res)
            res.data.map((item, index) => item["sno"] = index+1);
 
            this.setState({ data: res.data });
          

        }
        catch (error) {
            console.log("error", error);
        }



    }
    handleModalShowHide = async (flag, viewEditFlag, row,rowIndex) => {
        this.state.reforcastIndex=rowIndex+1;
        console.log("reforcast",row)
        if (row && row.id && viewEditFlag === "edit") {
            await this.getReforcastByExpenseId(row.id,rowIndex)
            this.setState({ showHideModal: flag, viewEditFlag });
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
    }
    getReforcastByExpenseId = async (id) => {
        try {
            const expenseDetails = await API.get(`reforecast/getbyid/${id}`);
            this.setState({
                viewEditData: expenseDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    handleReforecastDetails = (row,name,rowIndex) => {
        this.props.history.push(`/reforecast/details/${row.id}/${rowIndex+1}`, { reforecastId: row.id ,name:name,rowIndex:rowIndex});
    }
    columns = [{
        dataField: 'submissionId',
        text: 'Reforecast Title',
        sort: true,
        headerClasses: "width-240",
        formatter: (cell, row, rowIndex) => {
            return <>
                <Nav.Link as={NavLink} to={{pathname:`/reforecast/details/${row.id}/${rowIndex+1}`,state: {amendmentId: row.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={() => this.handleReforecastDetails(row,`Submission-${row.submissionId}:Reforecast-${row.sno}`,rowIndex)}>{`Submission-${row.submissionId}:Reforecast-${row.sno}`}</Nav.Link>
            </>
        }
    }, {
        dataField: 'approval',
        text: 'Approval',
        sort: true
    }, {
        dataField: 'decisionDate',
        text: 'Decision Date',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem","margin-bottom": "0" }}
                    onClick={() => this.handleReforecastDetails(row)}>{row.decisionDate?.length>0 ?dateStringformat(row.decisionDate):""}</p>
            </>
        }
    }, {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
                        var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            
            return <> {enableWFActions()&&<>
                <i className="bi bi-trash-fill" onClick={() => this.handleReforcastDelete(row)}
                    style={{ visibility: visibilityDelete,fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>
                <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row,rowIndex)}
                    style={{ visibility: visibilityEdit,fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>
            </>}</>
        }
    }];

    data = [
        {
            'reforecastTitle': "Submission-2922: Reforecast- 1",
            'approval': "Awaiting Review",
            'decisionDate': "12/02/2018"
        }
    ];


    handleReforecastSave = async (state,saveNew) => {
        // delete state.currencyList;
        // state["approval"]=state.approval;
        if(state.approval.toLowerCase()===APPROVED.toLowerCase() || state.approval.toLowerCase()===DECLINED.toLowerCase())
        {
            state.decisionDate=new Date();
        }
        state["fpv"] = state.fpv != "" && state.fpv != null ?
            dateChange(state.fpv) : null;
        state["decisionDate"] = state.decisionDate != "" && state.decisionDate != null ?
            dateChange(state.decisionDate) : null;
        state["fpet"] = state.fpet != "" && state.fpet != null ?
            dateChange(state.fpet) : null;
        state["lpet"] = state.lpet != "" && state.lpet != null ?
            dateChange(state.lpet) : null;
        state["fsr"] = state.fsr != "" && state.fsr != null ?
            dateChange(state.fsr) : null;
        state["lpetx"] = state.lpetx != "" && state.lpetx != null ?
            dateChange(state.lpetx) : null;
        state["lpv"] = state.lpv != "" && state.lpv != null ?
            dateChange(state.lpv) : null;
        state.created_by = this.context.userSystemId;
        try {
            const res = await API.post("reforecast/add", state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getReforcastDetailsBySubmissionId(this.props.submissionDetails.id)
            this.props.getSubmissionDetail(this.props.submissionDetails.id);
            if(saveNew){
                this.handleModalShowHide(true, null, null)
             }

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleReforecastUpdate = async (state) => {
        // state["date_received"] = state.date_received != "" && state.date_received != null ?
        //    dateChange(state.date_received) : null;
        if(state.approval.toLowerCase()===APPROVED.toLowerCase() || state.approval.toLowerCase()===DECLINED.toLowerCase())
        {
            state.decisionDate=new Date();
        }
        state["fpv"] = state.fpv != "" && state.fpv != null ?
       dateChange(state.fpv) : null;
    state["decisionDate"] = state.decisionDate != "" && state.decisionDate != null ?
       dateChange(state.decisionDate) : null;
    state["fpet"] = state.fpet != "" && state.fpet != null ?
       dateChange(state.fpet) : null;
    state["lpet"] = state.lpet != "" && state.lpet != null ?
       dateChange(state.lpet) : null;
    state["fsr"] = state.fsr != "" && state.fsr != null ?
       dateChange(state.fsr) : null;
    state["lpetx"] = state.lpetx != "" && state.lpetx != null ?
       dateChange(state.lpetx) : null;
    state["lpv"] = state.lpv != "" && state.lpv != null ?
       dateChange(state.lpv) : null;
        // state["probability"] = state.probability != "" && state.probability != null ?
        //     parseInt(state.probability) : null;
        state.updated_by = this.context.userSystemId;
        try {
            const res = await API.post("reforecast/update", state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getReforcastDetailsBySubmissionId(this.props.submissionDetails.id)
            this.props.getSubmissionDetail(this.props.submissionDetails.id);

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleReforcastDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    const res = await API.post(`recycle/reforecast/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                    swal("Deleted Successfully", {
                        icon: "success",
                      });
                    this.getReforcastDetailsBySubmissionId(this.props.submissionDetails.id)
                }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
              
            } 
          });
       
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal } = this.state;
        return <>
            <Row>
                <Col md={6}>
                    <h6 className="table-top"> Reforecast ({this.state.data.length})</h6>
                </Col>
                <Col md={2}>
                   {this.state.access.create && enableWFActions()&&  <Link as={Button}>
                        <Button variant="outline-light" className="new-button" onClick={() => this.handleModalShowHide(true, null, null)}>New</Button>
                    </Link>}
                </Col>
                <DatatableComponent data={this.state.data} columns={this.columns} pagination="true" id="reforecast"/>
            </Row>
            {
                showHideModal &&
                <ReforecastModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleReforecastSave={this.handleReforecastSave}
                    handleReforecastUpdate={this.handleReforecastUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    submissionId={this.props.submissionDetails.id}
                    name={this.state.reforcastIndex}

                />
            }

            {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
        </>
    }
}


export default withRouter(Reforecast);