import React from 'react'
import {Modal,Button, Row, Col} from 'react-bootstrap';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import InputField from '../../common/input-fields/InputField';
import Form from 'react-bootstrap/Form';
import { showMessage} from '../commonFunctions';
import {DateTime} from "luxon"
import { yyyymmddFormat } from '../../common/dateFormat';
import PropTypes from 'prop-types';
import ReviewForm from './ReviewForm';
import SwitchView from '../../common/SwitchView';
import { switchEnum } from '../../common/SwitchView/switchEnum';
import SwitchHistory from '../../common/SwitchHistory';
import { OBJ_TYPES } from '../../common/SwitchHistory/historyConsts';
export default class Review extends React.Component{
   constructor(props) {
    super(props)
    this.state = {
        viewDetails: true,
    };
   }
   renderHistorySwitch =(includeStyle)=>{
        let style={ marginBottom: '2rem' };
        if(includeStyle){
            style=Object.assign({},style,includeStyle);
        }
        return (this.props?.data?.review_submission_id) && <SwitchView
        style = {style}
        viewMode={(value) => {
            if (value === switchEnum.DETAILS) {
                this.setState({ viewDetails: true });
            } else {
                this.setState({ viewDetails: false });
            }
        }}
    />;
   }
    render(){
        let {modalInfo}=this.props;
        let {isCommiteeReview, review_type}=this.props.data;
        const { viewDetails } = this.state;
        let bodyStyle={};
        if(!viewDetails){
            bodyStyle={marginBottom:'3rem'};
        }

        return <Modal
        size={(isCommiteeReview&&viewDetails?"md":"xl")}
        show={modalInfo.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
               <Modal.Header style={{ border: 0 }}>
                {this.renderHistorySwitch({marginBottom: '.75rem'})}
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="font-weight-bold">&nbsp;</h5>
                    <div style={{ fontSize: '1rem' }}>
                        {/* <span>{modalInfo.titleDescription||''}</span> */}
                    </div>
                </Modal.Title>
               
                  <div className='d-flex justify-content-end align-items-center' >
                    {!isCommiteeReview && this.state.viewDetails &&<span className="material-icons mr-3" onClick={e=>{
                        let sessionKey=`review-tab-data`;
                        sessionStorage.removeItem(sessionKey);
                        let data=this.props.data;
                        data.isNewWindow=true;
                        sessionStorage.setItem(sessionKey,btoa(JSON.stringify(this.props.data)));
                         window.open("/submit-review/", "_blank");
                        if(this.props.onClose){
                            this.props.onClose();
                        }
                    }} style={{cursor:'pointer'}}>
                        open_in_new
                    </span>}
                    <span className="material-icons" onClick={this.props.onClose} style={{cursor:'pointer'}}>
                        close
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body className="new-flow-modal" style={bodyStyle}>
                {viewDetails ? 
                    <ReviewForm {...this.props.data} onClose={this.props.onClose}></ReviewForm>
                : <SwitchHistory 
                    style = {{ marginTop: '-5rem' }} 
                    object_type={ `${review_type.toLowerCase()}_review_audits` } 
                    object_id={this.props?.data?.review_submission_id} 
                  />}
            </Modal.Body>
        </Modal>
    }
}
