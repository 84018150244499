import React, { Component } from 'react';
import DatatTableEx from '../common/DataTableEx';
import { Col, Row,Button } from 'react-bootstrap';
import API from '../../api/apis';
import ReforecastModal from './ReforecastModal';
import UserContext from '../../context/UserContext';
import BootstrapModal from '../common/modal/BootstrapModal';
import { dateChange, dateStringformat, dateTimeformat,getUserInfo } from '../../utility/helperFunctions';
import { APPROVED,DECLINED } from '../../consts';
import FeedbackModal from './FeedbackModal';
import SupportModal from './SupportModal';
import SupportSuccessModal from './SupportSuccessModal';
import { isCoordinator } from '../ToReviewDetails/commonFunctions';

export default class Reforecast extends Component {
    static contextType = UserContext;

    constructor(props){
        super(props);
        this.state={
            reforecastList:[],
            filterSource:[],
            showLoader:false,
            showHideFeedModal:false,
            showHideSupportModal:false,
            showHideSupportSuccessModal: false,
            currentRowId: null,
            filterSource:[],
            viewEditFlag: null,
            viewEditData: {},
            userPermissions:{view:true},
            is_Coordinator:false,
            access: false
        }}

columns=[
    {
        dataField: 'id',
        text: 'id',
        sort: true,
        hidden:true,
        csvExport: false ,
        formatter:(cell,row,rowIndex)=>{
            return <p onClick={()=>this.handleModalShowHide(true,"edit",row,rowIndex)}></p>
        }
    },
    {
        dataField: 'title',
        text: 'Title',
        sort: true,
        // formatter:(cell,row,rowIndex)=>{
        //     return <p onClick={()=>this.handleModalShowHide(true,"edit",row,rowIndex)}>{`Submission-${this.props.submissionId}:Reforecast-${rowIndex+1}`}</p>
        // }

    },
    {
        dataField: 'approval',
        text: 'Approval',
        sort: true,  
    },
    {
        dataField: 'decision_Date',
        text: 'Decision Date',
        sort: true,


    },
    {
        dataField: 'investigator_rationale',
        text: 'Investigator Rationale',
        sort: true,


    },
    {
        dataField: 'is_follow',
        text: 'Follow',
        csvExport: false,
        formatter: (cell, row, rowIndex) => {
            console.log("row",row)
            var isStartVisible = row.is_follow ? "bi bi-star-fill" : "bi bi-star";
            return <>
                <i className={isStartVisible} onClick={() => this.handlestar(row)}
                    style={{ fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem" }}></i>
            </>
        }  
    },
    {
        dataField: 'actions',
        text: 'Actions',
        csvExport: false,
        formatter:(cell,row,rowIndex)=>this.renderActions(cell,row,rowIndex)
    },
]
    componentDidMount() {
        this.attachColumEvent()
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator ,access:_isCoordinator}, () => {
            this.getreforecastDetails(this.context.userSystemId);
        });
    }
    columnEvent = {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            if (column.dataField != 'is_follow' && column.dataField != 'actions') {
                this.handleModalShowHide(true, "edit", row, rowIndex)
            }

        }
    }
    renderActions=(cell,row,rowIndex)=>{
        let {is_Coordinator}=this.state;
        if(is_Coordinator){
            return <div className='d-flex align-items-center'>
            <Button size="sm" onClick={() => this.handleFeedModalShowHide(true,row)} className="d-flex align-items-center mr-1" variant="outline-dark">
                <span className="material-icons mr-1 m-0">
                    message
                </span>
                <span style={{ fontSize: ' 0.75rem' }}>FEEDBACK</span>
            </Button>
                <Button size="sm" disabled={row["is_support"]} 
                onClick={() => {
                    if (!row["is_support"]) { this.handleSupportModalShowHide(true, row.id) }
                }} 
                className="d-flex align-items-center" 
                variant={row["is_support"] ? "outline-secondary" : "outline-success"}>
                    <span className="material-icons mr-1 m-0">
                        check
                    </span>
                    <span style={{ fontSize: ' 0.75rem' }}>SUPPORT</span>
                </Button>
        </div>;
        }
        else if(row["is_support"]){
            return <div className='d-flex align-items-center'>           
                <Button size="sm" disabled={row["is_support"]} 
                className="d-flex align-items-center" 
                variant={row["is_support"] ? "outline-secondary" : "outline-success"}>
                    <span className="material-icons mr-1 m-0">
                        check
                    </span>
                    <span style={{ fontSize: ' 0.75rem' }}>SUPPORT</span>
                </Button>
        </div>;
        }
        return <></>
        
    }
handlestar = async(row)=>{
    let formbody={      
            "ref_key":row.id,
            "ref_type": "reforecast",
            "submission_id": this.props.submissionId,
            "system_id":this.context.userSystemId,
            "created_by":this.context.userSystemId,
}
    let res=await API.post(`/reforecastOptimization/followUnfollow`,formbody);
    if(res){
        let formbody={
            "submission_id":this.props.submissionId,
            "system_id":this.context.userSystemId
        }
        const response=await API.post(`/reforecastOptimization/list`,formbody)
        let responses=response.data.map((item,index)=>{
            let {approval,is_follow,is_support,submissionId,title,decisionDate,investigator_rationale,id}=item;
            return{
                decision_Date:decisionDate?dateStringformat(decisionDate):"",
                id,title:`Submission-${submissionId}:Reforecast-${index+1}`,investigator_rationale,approval,is_follow,is_support
            }})
        this.setState({reforecastList:responses})
    }
    
   
    } 
    getFilterSource=async (res)=>{
        if(res&&res.data&&res.data.length>0){
            let filterCollection= this.columns.filter(
                p => (!p.hidden && (p.dataField != "actions")&&(p.dataField!="is_follow"))).map((col, index) => {
                let columnValues = (res.data || []).map((item, key) => {
                    
                    
                    if(col.dataField.split(".").length>1){

                        return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
                        
                    }
                    
                    return item[col.dataField];
                });
                return {
                    
                    group:col.text,key:col.dataField,items:[...new Set(columnValues)]};
            });
            return filterCollection;
        }
        return null;
          
    } 
getreforecastDetails= async ()=>{
    let formbody={
        "submission_id":this.props.submissionId,
        "system_id":this.context.userSystemId
    }
    const res=await API.post(`/reforecastOptimization/list`,formbody)

    console.log("data",res.data)
    if(res&&res.data){
        
            let response=res.data.map((item,index)=>{
            let {approval,is_follow,is_support,submissionId,title,decisionDate,investigator_rationale,id}=item;
            return{
                decision_Date:decisionDate?dateStringformat(decisionDate):"",
                id,title:`Submission-${submissionId}:Reforecast-${index+1}`,investigator_rationale,approval,is_follow,is_support
            }})
            let filterSource=await this.getFilterSource({data:response?response:[]});
            this.setState({reforecastList:response,filterSource:[...(filterSource||[])]})

}}
getReforcastDetailsBySubmissionId = async (submissionid) => {
    try {
        const res = await API.get(`reforecast/submission/${submissionid}`)
        console.log("responsedelegee", res)
        res.data.map((item, index) => item["sno"] = index+1);

        this.setState({ data: res.data });
      

    }
    catch (error) {
        console.log("error", error);
    }




}   
attachColumEvent=()=>{
    this.columns.map((column,index)=>{
        column.events=this.columnEvent;
        });
}
    getReforcastByExpenseId = async (id) => {
        try {
            const expenseDetails = await API.get(`reforecastOptimization/getbyid/${id}`);
            this.setState({
                viewEditData: expenseDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

 
 handleModalShowHide = async (flag, viewEditFlag, row,rowIndex) => {
    this.state.reforcastIndex=rowIndex+1;
    console.log("reforcast",row)
    if (row && row.id && viewEditFlag === "edit") {
        await this.getReforcastByExpenseId(row.id,rowIndex)
        this.setState({ showHideModal: flag, viewEditFlag ,reforcastIndex:rowIndex+1});
        return true;
    }

    
    this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
}
handleFeedModalShowHide=(flag,data)=>{
    this.setState({showHideFeedModal:flag,activeReforecast:data});
}

//Show/Hide Support Modal - Reforecast
handleSupportModalShowHide=(flag, rowId)=>{
    this.setState({showHideSupportModal:flag,currentRowId:rowId});
   this.getreforecastDetails();
}

//Show/Hide Support Success Modal - Reforecast
showHideSupportSuccessMsg = (flag)=>{
    this.setState({showHideSupportSuccessModal:flag});
}

handleReforecastSave = async (state,saveNew) => {

    if(state.approval.toLowerCase()===APPROVED.toLowerCase() || state.approval.toLowerCase()===DECLINED.toLowerCase())
    {
        state.decisionDate=new Date();
    }
    state["fpv"] = state.fpv != "" && state.fpv != null ?
        dateChange(state.fpv) : null;
    state["decisionDate"] = state.decisionDate != "" && state.decisionDate != null ?
        dateChange(state.decisionDate) : null;
    state["fpet"] = state.fpet != "" && state.fpet != null ?
        dateChange(state.fpet) : null;
    state["lpet"] = state.lpet != "" && state.lpet != null ?
        dateChange(state.lpet) : null;
    state["fsr"] = state.fsr != "" && state.fsr != null ?
        dateChange(state.fsr) : null;
    state["lpetx"] = state.lpetx != "" && state.lpetx != null ?
        dateChange(state.lpetx) : null;
    state["lpv"] = state.lpv != "" && state.lpv != null ?
        dateChange(state.lpv) : null;
    state.created_by = this.context.userSystemId;
    try {
        const res = await API.post("reforecastOptimization/add", state);
        this.setState({
            success_body: "Created successfully!",
            successModal: true,
            hasChanged: false,
            showHideModal: false,
            viewEditFlag: null,
            viewEditData: {},
        });
        this.getReforcastDetailsBySubmissionId(this.props.submissionId);
        this.getreforecastDetails(this.props.submissionId)
        //this.props.getSubmissionDetail(this.props.submissionId);
        if(saveNew){
            this.handleModalShowHide(true,null,null)
         }

    } catch (err) {
        this.setState({ errorModal: true });
    }
}
addReforecast=(flag)=>{
    this.setState({showHideModal:flag})
}

handleReforecastUpdate = async (state) => {

    if(state.approval.toLowerCase()===APPROVED.toLowerCase() || state.approval.toLowerCase()===DECLINED.toLowerCase())
    {
        state.decisionDate=new Date();
    }
    state["fpv"] = state.fpv != "" && state.fpv != null ?
   dateChange(state.fpv) : null;
state["decisionDate"] = state.decisionDate != "" && state.decisionDate != null ?
   dateChange(state.decisionDate) : null;
state["fpet"] = state.fpet != "" && state.fpet != null ?
   dateChange(state.fpet) : null;
state["lpet"] = state.lpet != "" && state.lpet != null ?
   dateChange(state.lpet) : null;
state["fsr"] = state.fsr != "" && state.fsr != null ?
   dateChange(state.fsr) : null;
state["lpetx"] = state.lpetx != "" && state.lpetx != null ?
   dateChange(state.lpetx) : null;
state["lpv"] = state.lpv != "" && state.lpv != null ?
   dateChange(state.lpv) : null;
    // state["probability"] = state.probability != "" && state.probability != null ?
    //     parseInt(state.probability) : null;
    state.updated_by = this.context.userSystemId;
    try {
        const res = await API.post("reforecastOptimization/update", state);
        this.setState({
            success_body: "Updated successfully!",
            successModal: true,
            hasChanged: false,
            showHideModal: false,
            viewEditFlag: null,
            viewEditData: {},
        });
        this.getReforcastDetailsBySubmissionId(this.props.submissionId);
        this.getreforecastDetails(this.props.submissionId)
        //this.props.getSubmissionDetail(this.props.submissionDetails.id);

    } catch (err) {
        this.setState({ errorModal: true });
    }
}
changeShowState = () => {
    this.setState({
        successModal: false,
        errorModal: false
    });
}
handleFeedbackEvent=(e)=>{
    switch(e){
        case 'CLOSE': this.setState({ showHideFeedModal: false });break;
    }
}
render(){
    const { showHideModal, viewEditFlag, viewEditData, data,
        successModal, showLoader, success_body, errorModal,showHideFeedModal, showHideSupportModal, currentRowId, showHideSupportSuccessModal } = this.state;
    return <>
    <Row><DatatTableEx showLoader={this.state.showLoader}
            
            filterSource={this.state.filterSource}
            filterChange={()=>{}} csvName="reforecast_details"
     id="reforecast" columns={this.columns} data={this.state.reforecastList}
     pageIcons={ this.state.is_Coordinator&&<Button size="sm" onClick={()=>this.handleModalShowHide(true,null,null)}  className="d-flex align-items-center ml-2" variant="outline-dark"><span className="material-icons mr-1 m-0">
     add
     </span><span style={{fontSize:' 0.75rem'}}>ADD REFORECAST</span></Button>}/></Row>
     {showHideModal&&<ReforecastModal 
                         showHideModal={showHideModal}
                         handleModalShowHide={this.handleModalShowHide}
                         handleReforecastSave={this.handleReforecastSave}
                         handleReforecastUpdate={this.handleReforecastUpdate}
                         errorModal = {errorModal}
                         viewEditFlag={viewEditFlag}
                         viewEditData={viewEditData}
                         addReforecast={this.addReforecast}
                         
                         name={this.state.reforcastIndex}
 submissionId={this.props.submissionId}/>}
         
         {showHideFeedModal &&
                <FeedbackModal
                handleFeedbackEvent={this.handleFeedbackEvent}
                submissionId={this.props.submissionId}
                showHideFeedModal={showHideFeedModal}
                data={this.state.activeReforecast??{}}
                handleFeedModalShowHide={this.handleFeedModalShowHide}/>}
        {showHideSupportModal && <SupportModal currentRowId={currentRowId} showHideSupportModal={showHideSupportModal} handleSupportModalShowHide={this.handleSupportModalShowHide} />}
              
             {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
    </>
}

}