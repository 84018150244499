import moment from 'moment'
/*
https://lilly-jira.atlassian.net/browse/MDITIIRP-4351
##/AAA/####  ie DD/MMM/YYYY
*/
const GLOBAL_DATE_FORMAT="DD/MMM/YYYY hh:mm A";
const GLOBAL_DATE_FORMAT_NOTIME="DD/MMM/YYYY";
function iirDateFormat(inputTZ,requiredTime=false){
    if(inputTZ){
        let timeFormat="hh:mm A";
        if(!requiredTime)timeFormat='';
        let dateFormat=`DD/MMM/YYYY ${timeFormat}`.trim();
        let momentObj=moment.utc(inputTZ,false);
        if(momentObj.isValid()){
            let response=momentObj.local().format(dateFormat);
            return response;
        }
        return inputTZ.toString();
    }
    return "";  
    
}
function iirDateFormatEx(inputTZ,requiredTime=false){
    if(inputTZ){
        let timeFormat="hh:mm A";
        if(!requiredTime)timeFormat='';
        let dateFormat=`DD/MMM/YYYY ${timeFormat}`.trim();
        let momentObj=moment(inputTZ,"YYYY-MM-DD",false);
        if(momentObj.isValid()){
            let response=momentObj.format(dateFormat);
            return response;
        }
        return inputTZ.toString();
    }
    return "";  
    
}
export {iirDateFormat,iirDateFormatEx,GLOBAL_DATE_FORMAT,GLOBAL_DATE_FORMAT_NOTIME};