import React from 'react'
import style from './MultiReviewerList.module.css'
import Dropdown from 'react-bootstrap/Dropdown';
import { yyyymmddFormat } from '../../common/dateFormat';
import { dateStringformatEx } from '../../../utility/helperFunctions';
const DeleteToggle = React.forwardRef(({ children, onClick }, ref) => (
        <span className="material-icons"  ref={ref}  onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}>
        more_vert
        </span>    
  ));
export default class MultiReviewerList extends React.Component{

    constructor(props) {
        super(props);  
       
        this.state={
            source:this.props.source||[]
        };
    }
    componentDidUpdate(prevProps, prevState){        
        if (prevProps.source !== this.props.source) {
            this.setState({source:this.props.source||[]});
          }
    }
    menuTypesEnum={
        "DELETE_REVIEWERS":0,
        "SELECTION":1
    }
    manageSelection=(data)=>{
        let {source}=this.state;
        if(data){
           let existing = source.find(p=>p.reviewer_system_id===data.reviewer_system_id);
            if(existing){
                existing.is_selected=!existing.is_selected;
                if(this.props.onChange){
                    this.props.onChange(source,"SELECTION");
                }
                // this.setState({source},()=>{
                //     if(this.props.onSelection){
                //         this.props.onSelection(source);
                //     }
                // });
            }
        }
    }
    manageDeletion=(data)=>{
        let {source}=this.state;
        if(data){
            let existing = source.findIndex(p=>p.reviewer_system_id===data.reviewer_system_id);
             if(existing>-1){
                 source.splice(existing,1);
                 let updatedSource=this.reOrderSequentialData(source);
                 if(this.props.onChange){
                    this.props.onChange(source,"DELETION");
                }
                //  this.setState({source:updatedSource},()=>{
                //      if(this.props.onSelection){
                //          this.props.onSelection(source);
                //      }
                //  });
             }
         }
    }
    handleEvents=(e)=>{
        switch(e.option){
            case this.menuTypesEnum.SELECTION:this.manageSelection(e.data);break;
            case this.menuTypesEnum.DELETE_REVIEWERS:this.manageDeletion(e.data);break;
        }
    }
    reOrderSequentialData=(data)=>{
        
        if(data&&data.length>0){
            let sequential_source=(data).filter(p=>p.approval_order==="Sequential");
            if(sequential_source&&sequential_source.length>0){
                let ordererdData=sequential_source.filter(p=>p.order);
                let nonOrdererdData=sequential_source.filter(p=>!p.order);
                ordererdData.sort((a, b)=> {
                    return a.order - b.order;
                 });
                 ordererdData.map((item,index)=>{
                    item.order=index+1;
                 });  
                 let lastIndexValue=0;
                 if(ordererdData.length>0)
                 lastIndexValue=ordererdData[ordererdData.length-1].order;
                
                 nonOrdererdData.map((item,index)=>{
                    item.order=index+1+lastIndexValue;
                 });  
                 nonOrdererdData.sort((a, b)=> {
                    return a.order - b.order;
                 });    
                 sequential_source=[...ordererdData,...nonOrdererdData];           
            }
            let others=(data).filter(p=>p.approval_order!=="Sequential");
            return [...sequential_source,...others];
        }
        return data;
    }
    onDragStart=(ev,source)=>{
        if(source&&source.approval_order==="Sequential"){
            ev.dataTransfer.setData("text/plain",JSON.stringify(source||{}));
            ev.dataTransfer.effectAllowed = "copy";
        }
    }
    onDragOver=(ev)=>{
        ev.preventDefault();
        ev.dataTransfer.dropEffect = "copy";
    }
    onDragLeave=(ev)=>{
        ev.preventDefault();
    }
    onDrop=(ev)=>{
        let parentElm=ev.target.closest('li');
        let isDropable=parentElm.getAttribute("isDropable");  
          
        if(isDropable){
            ev.preventDefault();       
            const data = ev.dataTransfer.getData("text/plain");
            let source=JSON.parse(data);
            if(source&&source.approval_order==="Sequential"){    
                let targetId=parentElm.getAttribute("id");
                this.handleSwap(source.reviewer_system_id,targetId);
            }
        }
    }
    handleSwap=(sourceId,targetId)=>{
       
        let {source}=this.state;
        let data1 = source.findIndex(p=>p.reviewer_system_id===sourceId);
        let data2 = source.findIndex(p=>p.reviewer_system_id===targetId);
        let dd1=source[data1].due_date;
        let dd2=source[data2].due_date;
        let sourceData=JSON.parse(JSON.stringify(source[data1]));
        let targetData=JSON.parse(JSON.stringify(source[data2]));
       
        let tempData=source[data2];
        source[data2]= source[data1];
        source[data1]=tempData;       

        source[data1].order= sourceData.order;
        source[data2].order= targetData.order;      
        
        source[data1].due_date=dd1;//sourceData.due_date;
        source[data2].due_date=dd2;//targetData.due_date;
        this.setState({source});
    }
    renderItem=()=>{
        let {source}=this.state;
        //console.warn(source)
        let orderbyData=this.reOrderSequentialData(source);
        //console.warn(orderbyData)        
       let response=  (orderbyData||[]).map((item,index)=>{       
        let dndProps={

        };
        if(item.approval_order==="Sequential"){
            dndProps={
                draggable:true,           
                onDragStart:(ev)=>this.onDragStart(ev,item),
                onDrop:this.onDrop ,
                onDragOver:this.onDragOver ,
                onDragLeave:this.onDragLeave
            };
        }
        if(item.disable_edit){
            delete dndProps.onDrop;
        }
            return <li  {...dndProps} isDropable="true" key={item.reviewer_system_id} id={item.reviewer_system_id}  className={[style.reviewerBlock,(item.is_selected?style.reviewerBlockSelection:'')].join(" ")} 
            style={{backgroundColor:item.disable_edit?'#DEDEDE50':'',cursor:item.disable_edit?'not-allowed':''}}
            onClick={e=>{
                if(!item.disable_edit){
                    this.handleEvents({option:this.menuTypesEnum.SELECTION,data:item})
                }
            }
            }>
                <div className={style.reviewerBlockItem}  style={{cursor:item.disable_edit?'not-allowed':''}}>
                    <div className={style.reviewerBlockItemRow}  style={{cursor:item.disable_edit?'not-allowed':''}}>                         
                        <div>
                            {item.reviewer_name}
                        </div>
                        <div>
                           { item.is_committee&&(item.is_committee==="Yes"||item.is_committee===true) && 'Committee - '} {item.approval_order==="Sequential"&&item.approval_order+" "+item.order}
                           {item.approval_order!=="Sequential"&&item.approval_order}
                        </div>
                    </div>
                    <div className={style.reviewerBlockItemRow}>
                        <div>
                            {item.reviewer_type}
                        </div>
                        <div>
                            {(item.due_date?dateStringformatEx(item.due_date):"")}
                        </div>
                    </div>
                </div>
                <div className={style.reviewIconArea} onClick={e=>{
                    e.preventDefault();
                    e.stopPropagation();
                    
                }}>
               {!item.disable_edit&&<Dropdown>
                    <Dropdown.Toggle as={DeleteToggle} id="dropdown-custom-components"></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="1" onClick={e=>{this.handleEvents({option:this.menuTypesEnum.DELETE_REVIEWERS,data:item})}}>
                            <div className='d-flex align-items-center text-danger'>
                                <span className="material-icons mr-1">
                                delete
                                </span>
                                <span>Delete</span>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
                </div>
            </li>
        });
        //console.log(response);
        return response;
    }
    render(){
        return <div className={style.wrapperContaniner}>
                <ul className={style.reviewWrapper}>
                    {this.renderItem()}
                </ul>
        </div>
    }

}