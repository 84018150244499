import React, { Component } from 'react'
import { Col, Row, Button, Nav } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import API from "../../../../../api/apis";
import DatatableComponent from '../../../../common/datatable/DatatableComponent';
import DrugOrderModal from './DrugOrderModal';
import Loader from '../../../../common/loader/Loader';
import BootstrapModal from '../../../../common/modal/BootstrapModal';
import swal from 'sweetalert'
import UserContext from '../../../../../context/UserContext';
import {convertISOStringMMDDYYYY} from '../../../../common/dateFormat';
import { dateChange, dateStringformat, enableWFActions } from '../../../../../utility/helperFunctions';


class DrugOrder extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        drugOrderDetails: [],
        error: false,
        successModal: false,
        errorModal: false,
    }

    componentDidMount() {
        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:   selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
            },
        });
    this.getDrugOrderDetailsBySubmissionId(this.props.submissionDetails.id)
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:   selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }


    getDrugOrderDetailsBySubmissionId = async (submissionId) => {
        try {
            const drugOrderDetails = await API.get(`sites/drugorders/all/${submissionId}?search=submission_id`);
            this.setState({
                drugOrderDetails: drugOrderDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    handleModalShowHide = async (flag, viewEditFlag, row) => {
        if (row && row.id && viewEditFlag === "edit") {
            await this.getdrugOrderById(row.id)
            this.setState({ showHideModal: flag, viewEditFlag });
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
    }

    getdrugOrderById = async (id) => {
        try {
            const drugOrderDetails = await API.get(`sites/drugorders/getbyid/${id}`);
            this.setState({
                viewEditData: drugOrderDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    handleDrugOrderDetails = (row) => {
        this.props.history.push(`/drugOrder/details/${row.id}`, { id: row.id, submissionId: this.props.submissionDetails.id });
    }

    columns = [{
        dataField: 'id',
        text: 'Record ID',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
             <Nav.Link as={NavLink} to={{pathname:`/drugOrder/details/${row.id}`,state:{id: row.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={() => this.handleDrugOrderDetails(row)}>R-{row.id ? row.id.toString().padStart(4,'0') : row.id}</Nav.Link>
               
            </>
        }
    }, {
        dataField: 'date_requested',
        text: 'Date Requested',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
               {row.date_requested?.length>0 ? dateStringformat(row.date_requested):""}
            </>
        }
    }, {
        dataField: 'llir_status',
        text: 'Status',
        sort: true
    }, {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            return <>
                {/* <i className="bi bi-trash-fill" onClick={() => this.handleDrugOrderDelete(row)}
                    style={{ visibility: visibilityDelete , fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i> */}
                {enableWFActions() && <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row)}
                    style={{ visibility: visibilityEdit , fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>}
            </>
        }
    }];

    handleDrugOrderSave = async (state) => {
        state.created_by = this.context.userSystemId;
        state.submission_id = this.props.submissionDetails.id;
        state.broker_country_id = state.broker_country_id?.value;
        state.broker_state_id = state.broker_state_id?.value || state.broker_state_id;
        state.order_site_id = state.order_site_id?.value;
        state.site_drug_order_id = state.drug_compound_id?.sitedrugoption?.id;
        state.drug_compound_id = state.drug_compound_id?.value;
        state.has_the_drug_delivery_shipment_address="No";
        if(state.is_attest_to_the_details_of_this_order===true)
        {state.date_requested=new Date()}
        state.date_needed = state.date_needed != "" && state.date_needed != null ?
        dateChange(state.date_needed) : null;
        try {
            const res = await API.post(`sites/drugorders/${state.order_site_id}/upsert`, {drugOrder:state});
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getDrugOrderDetailsBySubmissionId(this.props.submissionDetails.id)

        } catch (err) {
            this.setState({ errorModal: true });
        }

    }
    handleDrugOrderUpdate = async (state) => {
        delete state.created_by1;
        delete state.updated_by1;
        delete state.createdAt;
        delete state.created_by;
        delete state.created_at;
        state.updated_by = this.context.userSystemId;
        state.submission_id = this.props.submissionDetails.id;
        state.id = state.record_id;
        state.broker_country_id = state.broker_country_id?.value || state.broker_country_id;
        state.broker_state_id = state.broker_state_id?.value || state.broker_state_id;
        state.order_site_id = state.order_site_id?.value ||  state.order_site_id;
        state.drug_compound_id = state.drug_compound_id?.value || state.drug_compound_id;
        state.date_needed = state.date_needed != "" && state.date_needed != null ?
        dateChange(state.date_needed) : null;
        state.has_the_drug_delivery_shipment_address="No";
        if(state.is_attest_to_the_details_of_this_order===true)
        {state.date_requested=new Date()}
        try {
            const res = await API.post(`sites/drugorders/${state.order_site_id}/upsert`,  {drugOrder:state});
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getDrugOrderDetailsBySubmissionId(this.props.submissionDetails.id);
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }


    handleDrugOrderDelete = async (row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`sites/drugorders/${row.id}`);
                        this.setState({
                            success_body: "Deleted successfully!",
                            successModal: true,
                            hasChanged: false,
                            drugOrderDetails:[]
                        });
                        this.getDrugOrderDetailsBySubmissionId(this.props.submissionDetails.id)
                    } catch (err) {
                        this.setState({ errorModal: true });
                    }
                }
            });

    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, drugOrderDetails,
            successModal, showLoader, success_body, errorModal } = this.state;
        return (<> {
            showLoader ? <Loader /> :
                <>
                    <Row>
                        <Col md={6}>
                            <h6 className="table-top"> Drug Orders ({drugOrderDetails.length})</h6>
                        </Col>
                        <Col md={2}>
                            {this.state.access.create && enableWFActions() && <Link as={Button}>
                                <Button variant="outline-light" className="new-button" onClick={() => this.handleModalShowHide(true, null, null)}>New</Button>
                            </Link>}
                        </Col>
                        <DatatableComponent id="drugOrder" data={drugOrderDetails} columns={this.columns} pagination="true" />
                    </Row>
                    {
                        showHideModal &&
                        <DrugOrderModal
                            showHideModal={showHideModal}
                            handleModalShowHide={this.handleModalShowHide}
                            handleDrugOrderSave={this.handleDrugOrderSave}
                            handleDrugOrderUpdate={this.handleDrugOrderUpdate}
                            viewEditFlag={viewEditFlag}
                            viewEditData={viewEditData}
                            submissionId={this.props.submissionDetails.id}
                        />
                    }
                    {this.state.successModal &&
                        <BootstrapModal
                            show={successModal}
                            header="Success"
                            body={success_body}
                            changeShowState={this.changeShowState}
                        />
                    }
                    {this.state.errorModal &&
                        <BootstrapModal
                            show={errorModal}
                            variant="danger"
                            header="Ooops!!"
                            body="An error occured! Please try again later!"
                            changeShowState={this.changeShowState}
                        />
                    }
                </>
        }
        </>
        )
    }
}

export default withRouter(DrugOrder);

