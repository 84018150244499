import React from 'react'
import API from '../../../api/apis';
import SharepointConnect from '../SharepointConnect';
const getReviewTypeDetails=async({submission_id,review_type})=>{
    
     if(submission_id){
         const res = await API.post(`reviewsTab/review/detail/getBySubmissionId`,{
            "review_type": review_type,
            "submission_id": submission_id
         });
        return res.data||{};
     }
 }
 const getMilestoneData=async({record_id,review_type})=>{
    
    if(record_id&&review_type){
        let endpoint=`${review_type.toLowerCase()}/getbyid/${record_id}`;
        const res = await API.get(endpoint);
       return res.data||{};
    }
   return null;
}
const getLastSubmittedReview=async({submission_id,review_type,review_header_id,reviewers_detail_id})=>{  
  
    if(submission_id&&review_type&&review_header_id&&reviewers_detail_id){
        const res = await API.post(`reviewsTab/reviews/getLatest/resubmitReviews`,{
            submission_id,
            review_type,
            review_header_id,
            reviewers_detail_id
        });
       
        if(res&&res.data && !(res.data.error || res.data.errors)){
            return res.data;
        }
    }
    return null;
 }
 const getReviewData=async({review_submission_id,review_type})=>{  
  
   if(review_submission_id){
       const res = await API.post(`reviewsTab/review/details`,{
           "review_type": review_type,
           "id": review_submission_id
       });
      
       if(res&&res.data && !(res.data.error || res.data.errors)){
           return res.data;
       }
   }
   return null;
}
const getSharepointFiles=async({ submission_id, review_type, review_header_id, reviewers_detail_id, review_submission_id,additional,isOldReview } )=>{
   
    let item = {
        submissionId: submission_id,
        review_type: review_type,
        review_header_id: review_header_id,
        reviewers_detail_id: `${reviewers_detail_id}/${review_type.toLowerCase()}review-${review_submission_id}${additional||""}`,
        review_id:review_submission_id,
        isOldReview:isOldReview
    };
   
    let sc = new SharepointConnect();
    let stateInfo = {};
    await sc.loadConfigInformation().then((data) => {
        stateInfo = data;
    });
    sc = new SharepointConnect(Object.assign(stateInfo, item));
    let data = await sc.fetchFiles();
    let records = [];
    if (data && data.length > 0) {
       records = [...records, ...data];
    }
    return records;
}
const downloadSharepointFile=async(data)=>{
    let sc = new SharepointConnect();
    let stateInfo = {};
    await sc.loadConfigInformation().then((data) => {
        stateInfo = data;
    });
   await sc.downloadFile(data.ServerRelativeUrl,data.Name);

}
 export {getReviewTypeDetails,getReviewData,getSharepointFiles,downloadSharepointFile,getMilestoneData,getLastSubmittedReview}