import React, { Component } from 'react'
import { Button, Col, Nav, Row } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import UserContext from '../../../../context/UserContext';
import DatatableComponent from '../../../common/datatable/DatatableComponent';

class Concept extends Component {
    static contextType = UserContext;
    state = {
    selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
    access: {
        view: true,
        create: false,
        edit: false,
        delete: false,
        
    },
    data: []
}
   

    async componentDidMount() {
        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:   selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
        await this.data();
     }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:   selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    async data(){
        let data = await  this.props.submissionDetails.concepts.map(concept => {
            return {
                response:concept,
                '[EXT] Concept Title': concept.title,
                '[EXT] Indication': concept.indication,
                'Request Type': concept.request_type
            }
        })
        this.setState({
            data: data
        })
}
    // rowEvents = {
    //     onClick: (e, row, rowIndex) => {
    //         //TODO 
    //         //MAKE SURE ROW VALUE REMAIN SAMES WHEN WE SEARCH FOR PARTICULAR RECORDS
    //       this.props.history.push(`/concept/details/${this.props.submissionDetails.concepts[rowIndex].id}`, {conceptId: this.props.submissionDetails.concepts[rowIndex].id});
    //     }
    // };
conceptDetails=(row)=>{
    this.props.history.push(`/concept/details/${row.response.id}`, {conceptId: row.response.id});
}
    render() {
        const {selectedRole} =this.state;
        let columns = [{
            dataField: '[EXT] Concept Title',
            text: '[EXT] Concept Title',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>
                    <Nav.Link as={NavLink} to={{pathname:`/concept/details/${row.response.id}`,state:{conceptId: row.response.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                        onClick={() => this.conceptDetails(row)}>{row.response.title}</Nav.Link>
                </>
            }
        }, {
            dataField: '[EXT] Indication',
            text: '[EXT] Indication',
            sort: true
        }, {
            dataField: 'Request Type',
            text: 'Request Type',
            sort: true
        },
        {
            dataField: '',
            text: "Actions",
            headerClasses: "width-90",
            formatter: (cell, row, rowIndex) => {
                var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
                    var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
                return <>

                    {/* <i className="bi bi-trash-fill" onClick={() => this.handleEnrollDelete(row)}
                        style={{visibility: visibilityDelete, fontSize: "1.2rem", color: "black", padding: ".1rem", float: "right" }}></i> */}
                     <i className="bi bi-pencil-square" onClick={() => this.conceptDetails(row)}
                    style={{visibility: visibilityEdit,fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>

                   
                    

                </>
            }
        }];
        return (
            <Row>
                <Col md={6}>                    
                    <h6 className="table-top"> Concepts ({this.props.submissionDetails.concepts?.length})</h6>                    
                </Col>
                {/* <Col md={2}>                    
                <Link as={Button} to={{pathname: "/concept/new", state: { submissionId: this.props.submissionDetails.id }}}>
                    <Button 
                    variant="outline-light" 
                    className="new-button"
                    disabled={this.props.submissionDetails.concepts?.length > 0}
                    >New</Button>    
                </Link>                    
                </Col> */}
                <DatatableComponent id="concept" data={this.state.data} columns={columns} rowEvents={this.rowEvents} />
            </Row>
        )
    }
}

export default withRouter(Concept);