import React from 'react'
import { Modal, Col, Row } from 'react-bootstrap';
import API from '../../../api/apis';
import InputField from '../input-fields/InputField';
import { dateTimeformat } from '../../../utility/helperFunctions';
import Loader from '../loader/Loader';
import LoaderEx from '../loader/LoaderEx';
import UserContext from '../../../context/UserContext';
import { isCoordinator, showMessage } from './../../ToReviewDetails/commonFunctions';
import Button from "../../common/Button";
import DatatTableEx from '../DataTableEx';
import SwitchView from '../SwitchView';
import { switchEnum } from '../SwitchView/switchEnum';
import SwitchHistory from '../SwitchHistory';
import { OBJ_TYPES } from '../SwitchHistory/historyConsts';

class CompoundModalEx extends React.Component {
    static contextType = UserContext;
    state = {
        countryList: [],
        name: "",
        therapeutic_area: "",
        request_type: "",
        userList: [],
        // ppm: "",
        // ptl: "",
        loading: true,
        //is_part_of_proposal:false,
        active: false,
        is_retired: false,
        patchObject: {},
        viewDetails: true
    }
    SERVICES = {
        "CREATE-COMPOUND": 'seed/compound/edit/add',
        "GET-COMPOUND": 'seed/compound/modify/getbyid',
        "UPDATE-COMPOUND": 'seed/compound/modify/update'
    }
    async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator });
        const { compoundId } = this.props;

        if (compoundId) {
            let compoundData = await this.getCompoundById(compoundId);

            if (compoundData) {
                compoundData["createdby"] = compoundData.created_by?.length ?
                    compoundData.created_name + " " +
                    dateTimeformat(compoundData.createdAt) : "";

                compoundData["updatedby"] = compoundData.updated_by?.length > 0 ?
                    compoundData.updated_name + ' ' +
                    //  await getUserInfo(viewEditData.updated_by) + "  " + 
                    dateTimeformat(compoundData.updatedAt) : compoundData.createdby;
                // compoundData["ptlName"] = this.state.userList.filter(elm => elm.system_id === compoundData.ptl)[0];
                // compoundData["ppmName"] = this.state.userList.filter(elm => elm.system_id === compoundData.ppm)[0];
                let countryList = compoundData.countries;
                countryList.map((item) => item['is_compound_org'] = item.is_compound);
                if(compoundData?.ly_number){
                    compoundData.ly_number = compoundData.ly_number.replace('LY','');
                }

                this.setState({
                    ...this.state,
                    ...compoundData,
                    countryList,
                    loading: false
                });
            } else {
                showMessage({ text: "Failed", icon: "error", button: "Ok" },
                    () => this.props.handleModalShowHide(false));
            }
        }
        else {
            this.setState({
                loading: false,
            })
        }
    }
    getCompoundById = async (compoundId) => {
        try {
            const res = await API.get(`${this.SERVICES['GET-COMPOUND']}/${compoundId}`);
            if (res.data.success) {
                return res.data.data || null;
            } else {
                showMessage({ text: "Failed", icon: "error", button: "Ok" },
                    () => this.props.handleModalShowHide(false));
            }
        } catch (e) {
            console.log(e);
            showMessage({ text: "Failed", icon: "error", button: "Ok" },
                () => this.props.handleModalShowHide(false));
        }
        return null;

    }
    get inputFieldsArray() {
        return [
            {
                label: "Compound Name *",
                type: "text",
                name: "name",

            },
            {
                label: "Therapeutic Area *",
                type: "dropdown",
                name: "therapeutic_area",
                options: [

                    "None",
                    "Oncology",
                    "Diabetes & Obesity",
                    "Immunology",
                    "Neurodegenerative Diseases",
                    "Headache & Pain"

                ]

            },
            // {
            //     label: "Request Type *",
            //     type: "dropdown",
            //     name: "request_type",
            //     options: [
            //         "None",
            //         "Consortium Collaboration",
            //         "Diagnostic Research",
            //         "Translational Research",
            //         "ESCR",
            //         "ExIST",
            //         "IIT",
            //         "Industry Sponsored",
            //         "Pre-clinical"
            //     ]
            // },
            {
                label: "Sub-Therapeutic Area",
                type: "dropdown",
                name: "sub_therapeutic_area",
                options: [
                    "None",
                    "Cell Signaling",
                    "Diabetes & Metabolic Disease",
                    "Generalized Pain & Headache",
                    "Immunology Compounds",
                    "Immuno-Oncology",
                    "Microenvironment",
                    "Neurodegenerative Disease & Alzheimer’s"]
            },
            {
                label: " Research Project Code",
                type: "text",
                name: "research_project_code",
            },
            {
                label: "LY Number",
                type: "ly_num",
                name: "ly_number",
                onDrop:(e)=>{
                    e.preventDefault();
                    return false;
                } ,   
                onPaste:(e)=>{
                    e.preventDefault();
                    return false;
                },
                onKeyPress:(e)=>{
                    /*Allowing only Digits */
                    var regex = new RegExp("^[0-9]+$");
                    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                    if (regex.test(str)) {
                        return true;
                    }            
                    e.preventDefault();
                    return false;
                },
            },
            {
                label: "Active to External",
                type: "checkbox",
                name: "active",
            },
            // {
            //     label: "Is Part of Proposal?",
            //     type: "checkbox",
            //     name: "is_part_of_proposal",
            // },
            {
                label: "Retired",
                type: "checkbox",
                name: "is_retired",
                disabled: true
            },
            // {
            //     label: "Part Of Proposal",
            //     type: "checkbox",
            //     name: "is_part_of_proposal",

            // },
            // 
            // {
            //     label: "LSN#",
            //     type: "text",
            //     name: "lsn",

            // },
            // {
            //     label: "PTL",
            //     type: "searchableDropdown",
            //     name: "ptlName",
            //     options: this.state.userList,
            //     onSelect: this.onSearchSelect

            // },
            // {
            //     label: "PPM",
            //     type: "searchableDropdown",
            //     name: "ppmName",
            //     options: this.state.userList,
            //     onSelect: this.onSearchSelect1
            // },
            {
                label: "Compound Description",
                type: "text",
                name: "compound_description",

            },
            {
                label: "Lilly Compound?",
                type: "checkbox",
                name: "is_lilly_compound",
            },
            // {
            //     label: "Research Areas of Current Focus - CM",
            //     type: "textarea",
            //     name: "research_areas_of_current_focus_cm",
            // },
            // {
            //     label: "Research Areas of Current Focus - NCM",
            //     type: "textarea",
            //     name: "research_areas_of_current_focus_ncm",
            // },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className: "createUpdateByfontsize",
                disabled: true,
                edit: true
            }, {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className: "createUpdateByfontsize",
                disabled: true,
                edit: true
            }
        ];
    }
    setLoader = (status) => {
        this.setState({ loading: status });
    }
    handleSave = async (state) => {
        try {
            this.setLoader(true);
            
            if (await this.formValidation()) {
                let { compoundId } = this.props;
                
                const textFields = ['name',
                'research_project_code',
                'ly_number',
                'compound_description'];
                
                const { patchObject } = state;
                let patchObjectKeys = Object.keys(patchObject);
                patchObjectKeys.forEach((key) => {
                    if (textFields.includes(key)) {
                        patchObject[key] = patchObject[key].trim();
                    }
                }
                )

                if(patchObject?.ly_number){
                    patchObject.ly_number = 'LY' + patchObject.ly_number;
                }
                let payload = {};

                if (compoundId) {
                    let select_country_ids = []
                    state.countryList.map((item) => {
                        if (item.is_compound === true) {
                            select_country_ids.push(item.id);
                        }
                    })

                    let updateObj = {
                        id: compoundId,
                        name: state.name.trim(),
                        therapeutic_area: state.therapeutic_area,
                        // sub_therapeutic_area: state.sub_therapeutic_area,
                        // research_project_code: state.research_project_code,
                        // ly_number: state.ly_number,
                        // active: state.active,
                        // is_retired: state.is_retired,
                        // compound_description: state.compound_description,
                        // is_lilly_compound: state.is_lilly_compound,
                        ...patchObject,
                        updated_by: this.context.userSystemId,
                        select_country_ids: select_country_ids.join(','),
                        is_select_all: false
                    }
                    payload = { ...updateObj }

                } else {
                    let addObj = {
                        name: state.name.trim(),
                        therapeutic_area: state.therapeutic_area,
                        // sub_therapeutic_area: state.sub_therapeutic_area,
                        // research_project_code: state.research_project_code,
                        // ly_number: state.ly_number,
                        // active: state.active,
                        // is_retired: state.is_retired,
                        // compound_description: state.compound_description,
                        ...patchObject,
                        is_lilly_compound: state.is_lilly_compound,
                        created_by: this.context.userSystemId
                    };
                    payload = { ...addObj }
                }
                let url = compoundId ? this.SERVICES['UPDATE-COMPOUND'] : this.SERVICES['CREATE-COMPOUND'];
                let compoundResponse = await API.post(url, payload);
                if (compoundResponse && compoundResponse.data.success) {
                    showMessage({ text: "Saved Successfully", icon: "success", button: "Ok" }, () => {
                        this.props.handleModalShowHide(false, null, true);
                    });
                }
                else {
                    showMessage({ text: "Failed", icon: "error", button: "Ok" });
                }
            }
        }
        catch (exp) {

        }
        finally {
            this.setLoader(false);
        }

    }

    onInputChange = (e) => {
        this.setState({ hasChanged: true, [e.target.name]: e.target.value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value } });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: e }
        });
    }
    // onSearchSelect = (e, name) => {
    //     this.setState({
    //         hasChanged: true,
    //         [name]: e,
    //         // ptl: e.system_id,
    //         // stateName:e.name
    //     });
    // }
    // onSearchSelect1 = (e, name) => {
    //     this.setState({
    //         hasChanged: true,
    //         [name]: e,
    //         // ppm: e.system_id,
    //         // stateName:e.name
    //     });
    // }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.checked }
        });
    }
    handleCheckboxChange = (rowId, row) => {
        const { countryList } = this.state;
        const updatedCheckboxes = countryList.map((row) => {
            if (row.id === rowId) {
                return {
                    ...row,
                    is_compound: !row.is_compound,
                };
            }
            return row;
        });
        // const isCheckedAll = updatedCheckboxes.every((row) => row.is_compound);
        const selectedRows = [];
        updatedCheckboxes.map((item) => {
            if (item.is_compound) {
                selectedRows.push(item.id);
            }
        })
        this.setState({
            countryList: updatedCheckboxes,
            selectedRows: selectedRows,
            // isCheckedAll,
        });

    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = {
            name: this.state.name,
            therapeutic_area: this.state.therapeutic_area,
            //request_type:this.state.request_type,

        }
        const errors = {}
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null || initialValues[key].toString().trim().length <= 0) {
                errors[key] = true;
                is_valid = false;

            }
            this.setState({
                error: { ...errors }
            }, () => {
                try {
                    this.modalWindow._modal.dialog.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
                catch (exp) {
                    console.log(exp)
                }
            });
        });

        return is_valid;

    }
    columns =

        [{
            dataField: "id",
            text: "id",
            hidden: true,
            sort: false,
            csvExport: false,
        },
        {
            dataField: 'name',
            text: 'Country Name',
            sort: true,

        },
        {
            dataField: 'is_compound',
            text: "Actions",
            csvExport: false,
            headerClasses: "width-90",
            formatter: (cell, row, rowIndex) => {

                return <>

                    <input type='checkbox' disabled={!this.state.is_Coordinator} checked={row.is_compound} onChange={() => this.handleCheckboxChange(row.id, row)}
                    ></input>
                </>
            }
        },
        {
            dataField: 'is_compound_org',
            text: "Actions",
            hidden: true
        }];
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.name !== "createdBy" || field.name !== "updatedBy") {
                if (!this.state.is_Coordinator) {

                    field.disabled = true;
                }
            }
            if (field.edit && !this.props.compoundId)
                return false;
            return (
                <Col sm={6} key={field.name}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || this.onInputChange : this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleReforecastSave, handleReforecastUpdate, compoundId } = this.props;
        const { loading, is_Coordinator, countryList, name, viewDetails } = this.state;
        return (
            <div>
                <Modal
                    ref={ref => this.modalWindow = ref}
                    show={showHideModal}
                    size="lg"
                    // size="md"
                    className="modal-mdx new-flow-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="d-flex border-0" >
                        <Modal.Title className="w-100 d-flex justify-content-center flex-grow-1 text-left">
                            <h5 className='modal-title text-left w-100 font-weight-bold'>
                                {compoundId ?
                                    `${name} Compound` : "Add a New Compound"}
                            </h5>

                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" onClick={() => handleModalShowHide(false)} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="position-relative" >
                        {loading === true ? <LoaderEx /> : <></>}
                        { compoundId && 
                            <SwitchView
                                viewMode={(value) => {
                                    if (value === switchEnum.DETAILS) {
                                        this.setState({ viewDetails: true });
                                    } else {
                                        this.setState({ viewDetails: false });
                                    }
                                }}
                            /> 
                        }

                        { viewDetails ? <>
                            <Row>
                                {this.renderInputFields()}
                            </Row>
                            {compoundId ? <Row><DatatTableEx
                                id="countryList" csvName={`Countries_${name}`} data={countryList} columns={this.columns} pagination="true" title='Countries' titleClass='title-lg' />
                            </Row> : <></>}
                            
                        </> 
                        : <SwitchHistory 
                            object_type={OBJ_TYPES.COMPOUND} 
                            object_id={compoundId} 
                            showRowType={true}
                          /> 
                        }
                    </Modal.Body>
                    { viewDetails && <Modal.Footer className="d-flex border-0 justify-content-center">
                        <Button style="danger w-100px" onClick={() => handleModalShowHide(false)} className="d-flex align-items-center p-2 justify-content-center"><span>Cancel</span></Button>
                        {is_Coordinator ?
                            <Button style="success w-100px" onClick={() => this.handleSave(this.state)} className="d-flex align-items-center p-2 justify-content-center"><span>{compoundId ? 'SAVE' : 'ADD'}</span></Button>
                            : <></>}
                    </Modal.Footer> }
                </Modal>
            </div >
        )
    }

}

export default CompoundModalEx;