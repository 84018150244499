import React from 'react'
import {Modal} from 'react-bootstrap';
import API from '../../../api/apis';
import Button from '../../common/Button';
import { showMessage } from '../../ToReviewDetails/commonFunctions';
import CsvUpload from './CsvUpload';
import Header from './Header';
import Preview from './Preview';
import UserContext from '../../../context/UserContext';
const BulkEnrollContext = React.createContext({});
export default class BulkEnrollment extends React.Component{
    static contextType=UserContext;
    constructor(props){
        super(props);
        this.state={
            navigation:this.navigationLink[0],
            formData:{
                handleEvents:this.handleStageEvents,
                data:{}
            }
        }
    }
    stage={
        "STAGE_1":"STAGE_1",
        "STAGE_2":"STAGE_2",
        "STAGE_3":"STAGE_3"
    }
    navigationLink=[
        {node:'STAGE_1',next:'STAGE_2',prev:null,caption:"Adding Enrollment by Import"},
        {node:'STAGE_2',next:'STAGE_3',prev:'STAGE_1',caption:"Please review the uploaded CSV"}      ,
        {node:'STAGE_3',next:null,prev:'STAGE_2',caption:"Confirm Added Enrollment"}    
    ]
    componentDidMount(){
       this.getCurrencyList();
    }
  
    getExistingEnrollments=async()=>{
        this.setState({isIUDOperation:true});
        let {submissionId}=this.props;
        let submissionEnrollments={};
        let { navigation,formData } = this.state;
        if(formData.csv&&formData.csv.length>0){
            try {
                let apiResponse = await API.get(`/enrollmentOptimization/submission/${submissionId}`);
                if (apiResponse && apiResponse.data&&apiResponse.data.length>0) {
                   
                   let {csv}=formData;
                   let existingEnrollments=apiResponse.data;
                   let anyRecordUpdated=false;
                    csv.forEach((item,index)=>{
                        let hasData=existingEnrollments.find(p=>p.year==item.year&&p.quarter==item.quarter);
                        if(hasData){
                            anyRecordUpdated=true;
                            item.id=hasData.id;
                            item.old_total_patients_entered_treatment=hasData.total_patients_entered_treatment;
                        }
                    })
                    if(anyRecordUpdated){
                        this.setState({formData:{...formData}});
                    }

    
                }
            }
            catch (exp) {
    
            }
        }
        this.setState({isIUDOperation:false});
    }
    getCurrencyList = async () => {
       
        try {
            const currencyList = await API.get(`seed/currency/data`);
            let submissionDetails = await API.get(`submission/getbyid/${this.props.submissionId}`);
            let currency_id="";
            if(submissionDetails&&submissionDetails.data&&submissionDetails.data.success){
                currency_id=submissionDetails.data.data.currency_id;
                
            }        
            if(currencyList&&currencyList.data&&currencyList.data.success){ 
               let currency= currencyList.data.data.filter(item => item.id ===currency_id)[0];
                this.setState({
                    currency,currency_id
                })
            }
           
        } catch (e) {
            console.log(e);
           
        }
    }
    handleStageEvents=(e,data)=>{
       
        switch(data.option){
            case "CSVDATA":
            let {formData,currency}=this.state;
            formData.csv=data.csv.data||[];  
            try{
                if(formData.csv.length>0){
                    formData.csv.forEach((item)=>{
                        item.currency_id=this.state.currency_id;
                        item.user_name=`${this.context.userFirstName} ${this.context.userLastName}`
                        if(currency){
                            item.currency_name=  currency.name;
                        }
                    });
                }  
            }
            catch(exp){

            }           
            this.setState({formData},this.getExistingEnrollments);
            break;
        }
    }
    handleBulkInsert=async ()=>{
        this.setState({isIUDOperation:true});
        try{
            let {formData}=this.state;
            let {csv}=formData;
            
            csv.forEach((item)=>{
                delete item.user_name;
            });
            let data = {
                "common_details": {
                    "created_by": this.context.userSystemId,
                    "updated_by": this.context.userSystemId,
                    "submissionId": this.props.submissionId
                },
                "enrollment_details": csv
            };
            let apiResponse=await API.post(`/enrollmentOptimization/bulk/upsert`,data);
            if(apiResponse&&apiResponse.data){
                let response=apiResponse.data;
                if(response===true){
                    showMessage({
                        text: "Enrollments Submitted!",
                        icon: "success",
                        button: "Ok",
    
                    }, (e) => {
                        if(this.props.onClose){
                            this.props.onClose(e);
                        }
                      });
                }
                else{
                    let message= "Oops! Something went wrong.";
                    if(response.error&&response.error.toLowerCase()=="Unique Constraint Error".toLowerCase())
                    {
                        message="Record(s) already exist!."
                    }
                    showMessage({
                        text: message,
                        icon: "error",
                        button: "Ok",
    
                    }, (e) => {
                       
                      });
                    console.log("Error Bulk Update",response);
                }

            }
            console.log("handleBulkInsert",apiResponse)
        }
        catch(ex){
            console.log("handleBulkInsert,error",ex)
        }
        finally{
            this.setState({isIUDOperation:false});
        }

    }
    handleEvents=async (e,option)=>{
        let { navigation,isIUDOperation } = this.state;
        if(isIUDOperation)return false;
        let filterMode=null;
        if(option==="NEXT"&&(!this.state.formData.csv||this.state.formData.csv.length<=0)){
            return false;
        }
       
         if(option==="NEXT"&&navigation.node&&navigation.next){        
           
            switch(navigation.node){
                case this.stage.STAGE_2:
                    let validationStatus=await this.doValidateData();
                  
                    if(validationStatus){
                        filterMode="next";
                    }
                    else{
                        filterMode=null;
                    }
                    break;
                 default:
                    filterMode="next";break;
            }
        }
        else if(option==="NEXT"&&navigation.node&&navigation.node===this.stage.STAGE_3){
            this.handleBulkInsert();
        }
        else  if(option==="BACK"&&navigation.node&&navigation.prev){
            if(navigation.node===this.stage.STAGE_2){
                let {formData}=this.state;
                formData.csv=null;
                this.setState({formData:{...formData}});
            }
            filterMode="prev";
        }
        else  if(option==="BACK"&&navigation.node&&!navigation.prev){
            if(this.props.onClose){
                this.props.onClose(e);
            }
        }
        if(filterMode){
           
            let nextView=this.navigationLink.find(p=>p.node===navigation[filterMode]);
            let data={navigation:nextView};
            this.setState({...data});
        }
    }
    getForm=()=>{
        let { navigation,formData } = this.state;
        let view=this.stage;
        let pageProps={
            formData,
            navigation
        };
        switch (navigation.node) {
            case view.STAGE_1:          
            return (<CsvUpload {...pageProps}></CsvUpload>);break;
            case view.STAGE_2:
            case view.STAGE_3:
           
            return (<Preview {...pageProps} isFinalStage={navigation.node===view.STAGE_3}></Preview>);break;
        }
    }
    doValidateData=async()=>{
        let {submissionId}=this.props;
        let submissionEnrollments={};
        let { navigation,formData } = this.state;
        this.setState({isIUDOperation:true});
        let field="total_patients_entered_treatment";
        try{
            let apiResponse=await API.get(`/enrollmentOptimization/enrollmentOverview/${submissionId}`);
           if(apiResponse&&apiResponse.data){
            submissionEnrollments=apiResponse.data;
            debugger;
            if(formData&&formData.csv){
               let {csv}=formData;
               if(csv&&csv.length>0){
                //old_total_patients_entered_treatment
               
                   let ivalidDataNew = csv.filter(p => {
                       return ((!p.id && p[field]&&p[field]!=0&&p[field] < submissionEnrollments.current_enrollment_actual))
                   });
                   let hasAnyIvalidDataExisting = csv.filter(p => {
                       return ((p.id &&p[field]&& p[field]!=0&&p[field] != p.old_total_patients_entered_treatment && p[field] < submissionEnrollments.current_enrollment_actual))
                   });
               if((hasAnyIvalidDataExisting&&hasAnyIvalidDataExisting.length>0) || (ivalidDataNew&&ivalidDataNew.length>0)){
                this.setState({isIUDOperation:false});
                showMessage({
                    text: "Total Patients Entered Treatment should be greater than Current Enrollment(Actual)",
                    icon: "error",
                    button: "Ok",
        
                }, (e) => {
                    
                  });
                return false;
               }
               else{
                let {current_enrollment_actual}=submissionEnrollments;
                let projectedActualEnrollment=current_enrollment_actual;
                csv.sort((a,b) => `${a.year}-${a.quarter}` - `${b.year}-${b.quarter}`);
                let isValid=true;
                let errorObject=null;
                csv.forEach((item,index)=>{
                    if(item[field]&&item[field]!=0){
                        let actual=item[field]-projectedActualEnrollment;
                        if(item.id&&item[field]==item.old_total_patients_entered_treatment){
                            actual=0;
                        }
                        if(actual<0){
                            isValid=false;
                            errorObject=item;
                            return false;
                        }
                    }
                  
                });
                if(!isValid){
                    this.setState({isIUDOperation:false});
                    showMessage({
                        text: "Total Patients Entered Treatment should be greater than Current Enrollment(Actual)",
                        icon: "error",
                        button: "Ok",
            
                    }, (e) => {
                        
                      });
                    return false;
                }
               }
              
               }

            }
           }
        }
        catch(exp){

        }
        this.setState({isIUDOperation:false});
        return true;
    }
    render(){
        let { navigation,isIUDOperation } = this.state;

        return <Modal
        size={"xl"}
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="d-flex flex-column">
                <Header currentStage={navigation.node} onClose={e=>{
                    if(this.props.onClose){
                        this.props.onClose(e);
                    }
                }} caption={navigation.caption}></Header>
                {this.getForm()}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center position-relative">
        {isIUDOperation&&<div className="d-flex align-items-center justify-content-center p-2 position-absolute w-100 h-100">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>}
                <Button style='accent h-md' className="p-2 pr-3 pl-3 d-flex align-items-center mr-5" onClick={e => this.handleEvents(e, 'BACK')}>
                    <div className='d-flex align-items-center text-dark'>
                        <span>BACK</span>
                    </div>
                </Button>
                <Button style='success h-md' className="p-2 pr-3 pl-3 d-flex align-items-center" onClick={e => this.handleEvents(e, 'NEXT')}>
                    <div className='d-flex align-items-center text-dark'>
                        <span>{navigation.node===this.stage.STAGE_3?"SUBMIT":'NEXT'}</span>
                    </div>
                </Button>
        </Modal.Footer>
        </Modal>

    }
}