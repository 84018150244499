import React, {useState,useEffect} from "react";
import './Steps.css'
export default ({source,gap})=>{
    let style={};
    let baseMargin={};
    if(gap){
        style={width:gap};
        baseMargin={marginLeft:`${gap}`};
    }
    return <div className="d-flex align-items-center justify-content-center">
        <ul className="steps_ul" style={baseMargin}>
            {
                source&&source.map((item,index)=>{
                    return <li className={item.active?"active":""} style={style} key={item.text}><div>{item.text}</div></li>
                })
            }
            
        </ul>
    </div>
}