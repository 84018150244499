import React from 'react'
import ReviewListTable from './ReviewLIstTable'
import UserContext from '../../../context/UserContext';
import ProposalDetailsEx from '../../submission/detailSubComponents/ProposalDetailsEx';

import AmendmentDetailsEx2 from '../../AmendmentDetailsEx2';

import ProtocolDetailsEx from '../../submission/detailSubComponents/ProtocolDetailsEx';
import ConceptDetailsEx from '../../submission/detailSubComponents/ConceptDetailsEx';
import PublicationDetailEx from '../../PublicationDetailEx';
import Protocol from '../Protocol';
import Publication from '../Publication';
export default class TabDetails extends React.Component{
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state={
            userPermissions:{view:true}
        }
    }
    formTypes={
        "Concept":'Concept',
        'Proposal':'Proposal',
        'Protocol':'Protocol',
        'Amendment':'Amendment',
        'Publication':'Publication',
    }
    excludedReviewTypes=()=>{
        return [this.formTypes.Protocol,this.formTypes.Amendment,this.formTypes.Publication].includes(this.props.review_type)
    }
    GetForm=()=>{
        let commonParams={
            submissionId:this.props.submissionId
        }
        switch(this.props.review_type||''){
            case this.formTypes.Concept:return  <ConceptDetailsEx {...commonParams} /> ; break;
            case this.formTypes.Proposal:return <ProposalDetailsEx {...commonParams} /> ; break;
            case this.formTypes.Protocol:return <Protocol {...commonParams}></Protocol>; break;
            // case this.formTypes.Protocol:return <ProtocolDetailsEx submissionId={this.props.submissionId} />  ; break;
           // case this.formTypes.Amendment:return  <AmendmentDetailsEx2 submissionId={this.props.submissionId} /> ; break;
            case this.formTypes.Publication:return  <Publication {...commonParams}></Publication>;
            //case this.formTypes.Publication:return <PublicationDetailEx submission_id={this.props.submissionId}></PublicationDetailEx>; break;
        }
    }
    render(){   
        let {userPermissions}=this.state;
        let isExludedType=this.excludedReviewTypes();
        let style={ paddingTop: !isExludedType?'1rem':'0px' };        
        return <div style={style}>
            {!isExludedType&&<div className="w-100 d-print-none">
                <ReviewListTable review_type={this.props.review_type} handleExternalEvents={this.props.handleExternalEvents} submissionId={this.props.submissionId} ></ReviewListTable>
            </div>}        
           <div className="pl-3 pr-3 pb-3" style={style}>
           <this.GetForm ></this.GetForm>
           </div>
        </div>
    }
}