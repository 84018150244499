import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';
import InputField from '../input-fields/InputField';
import Loader from '../loader/Loader';


class StateModal extends React.Component {
    static contextType = UserContext;
    state = {
        owner:"",
        compoundList: [],
        hasChanged: false,
        countryList: [],
        stateList: [],
        name:"",
        isLoader:true
        
    }
 async componentDidMount() {
     this.state.owner=await getUserInfo(this.context.userSystemId)
    await this.getCountryList();
        const { viewEditData, viewEditFlag } = this.props;
        console.log("viewEditData",viewEditData)
      if (viewEditFlag === "edit") {
        viewEditData["created_by"] =viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by)+"  "+ dateTimeformat(viewEditData.createdAt) : "";
        viewEditData["updated_by"] =viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.created_by ;
        
            this.setState({
                //set Your form state details here
                ...this.state,
                ...viewEditData,
                isLoader:false
            })
        }
        else {
            this.setState({
                isLoader:false,
               
            })
        }
    }

    get inputFieldsArray() {
        return [{
            label: "Information *",
            type: "alert",
           varient:"secondary"
        },
        {
            label: "State Name *",
            type: "text",
            name: "name",
           
        },
       
        // {
        //     label: "Owner",
        //     type: "text",
        //     name: "owner",
        //    disabled:true
        // },
        {
            label: "Created By",
            type: "text",
            name: "created_by",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updated_by",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }
        ];
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/data");
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ countryList: resultArray });

    }

    getStateList = async (country) => {
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ stateList: resultArray,
        countryname:country });
    }

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            country_id: e.value

        });
        if (name === "country_id")
            this.getStateList(e.name, "state_province_id")

    }

    onInputChange = (e) => {
        this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
             const   initialValues={
                   name:this.state.name,
                  
                  }
                  const errors = {}
                  Object.keys(initialValues).forEach((key) => {
                    if (initialValues[key] === "" || initialValues[key] === null ) {
                        errors[key] = true;
                        is_valid = false;
                        
                    } 
                    this.setState({
                        error:  { ...errors}
                    });
                });
                  
        return is_valid;

    }
    onSave = () => {

        if(this.formValidation(this.state)){
          this.props.handleReforecastSave(this.state);
        }
         
      }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox"? field.onChange ||this.onInputChange: this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleReforecastSave, viewEditFlag, handleReforecastUpdate} = this.props;
        const { record_Id } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg">
                {this.state.isLoader=== true ?
        <Loader /> :  <div> <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit State ` : "New State"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                                viewEditFlag === "edit" ?
                                    <Button variant="primary" onClick={() => handleReforecastUpdate(this.state)}>
                                        Save
                     </Button> : <Button variant="primary" onClick={this.onSave}>
                                        Save
                    </Button>
                        }

                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default StateModal;