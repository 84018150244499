import React,{useEffect,useState,useCallback} from 'react'
import Button from '../../../../common/Button';
import styles from './index.module.css'
const CANCEL_FORM_CONTENT={
    title:"Reviewer Cancellation",
    content:"Are you sure you want to cancel this review?"
   
}
export default ({handleEvents,id})=>{
    const[formValues,setFormValues]=useState({cancellationReason:''})
    const [formError,setFormError]=useState({cancellationReason:false})
    const handleValidation=()=>{
        let isValid=false;
        if(formValues.cancellationReason && formValues.cancellationReason.trim().length>0){           
            isValid=true;
        }
        setFormError({cancellationReason:!isValid});
        return isValid;
    }
    const handleSave=()=>{
        if(handleValidation()){
            handleEvents("SAVE-CANCELLATION",{id,cancellation_reason:formValues.cancellationReason.trim()});
        }
    }

    return <div className={styles.wrapper}>
            <div className={styles.message_view}>
                <div className={styles.title}>{CANCEL_FORM_CONTENT.title}</div>
                <div className={styles.body}>
                    <span>{CANCEL_FORM_CONTENT.content}</span>
                    <textarea value={formValues.cancellationReason} onChange={e=>{
                        formValues.cancellationReason=e.target.value||'';
                        setFormValues({...formValues});
                    }} rows={4} style={{width:'500px'}} className="form-control mt-2 mb-2" placeholder="Comments for Cancelling"></textarea>
                    {formError.cancellationReason&&<p className="text-danger ml-3">This field is required.</p>}
                </div>
                <div className={styles.footer}>
                <Button className="p-3 mr-2" style="danger" onClick={e=>handleEvents("CANCEL-VIEW","")}>
                        <div className="d-flex align-items-center">                            
                         <span>Cancel</span>
                         </div>
                    </Button>
                    <Button className="p-3" style="warning" onClick={e=>handleSave()}>
                        <div className="d-flex align-items-center">
                            <span className="material-icons mr-1" style={{ fontSize: '1rem' }}>
                                cancel
                            </span>
                         <span>Cancel Review</span>
                         </div>
                    </Button>
                </div>
            </div>
    </div>;
}