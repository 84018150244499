import React, { Component } from 'react';

import { Button, Col, Nav, Row } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import DatatableComponent from '../../../common/datatable/DatatableComponent';
// import InputField from '../../common/input-fields/InputField';
import API from '../../../../api/apis';
import { dateChange, dateStringformat, dateTimeformat, getUserInfo, enableWFActions } from '../../../../utility/helperFunctions';
import swal from 'sweetalert';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import AmendmentModal from '../../../AmendmentModal';
import { APPROVED, DECLINED } from '../../../../consts';
import dateFormat from '../../../common/dateFormat';
import UserContext from '../../../../context/UserContext';
import { stateToHTML } from 'draft-js-export-html';
// import { CLINICAL_TRIAL_PHASE, INDICATIONS, LINE_OF_THERAPY, REQUEST_TYPE, STUDY_PRIORITY, THERAPEUTIC_AREA, TRIAL_CATEGORY_2_STAGE_2_SUBMISSION_STATUS } from '../../../consts';

class Protocol extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        data: [],
        response: {},
        amendmentData: [],
        amendmentIndex: "",
    }

    componentDidMount() {
       
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
              create:  selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator"),
              edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
              delete: selectedRole.includes("global_coordinator")  ||selectedRole.includes("administrator"),
            },
        });
    this.getProtocol(this.props.submissionDetails.id);
        this.getAmendment(this.props.submissionDetails.id)
     }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:  selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator")  ||selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }


    getProtocol = async (submissionid) => {
        const res = await API.get(`protocol/submission/${submissionid}`)
        if (res.data)
            this.setState({
                response: res.data,
                data: [{
                    response:res.data,
                    '[EXT] Protocol Title': res.data.protocolTitle,
                    'Last Modified Date': dateTimeformat(res.data.updatedAt),                    
                    'Last Modified By': res.data.last_modified_by!=null ? await getUserInfo(res.data.last_modified_by) : await getUserInfo(res.data.createdByID),
                    id : res.data.id,
                    ...res.data
                }]
            })
            else{
                this.setState({
                    data : []
                })
            }
    }

    columns = [{
        dataField: '[EXT] Protocol Title',
        text: '[EXT] Protocol Title',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <Nav.Link as={NavLink} to={{pathname:`/protocol/details/${this.state.response.id}`,state: {protocolId: this.state.response.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={(e) => this.handleProtocolDetails(row,e)}>{row.response.protocolTitle}</Nav.Link>
            </>
        }
    }, {
        dataField: 'Last Modified Date',
        text: 'Last Modified Date',
        sort: true
    }, {
        dataField: 'Last Modified By',
        text: 'Last Modified By',
        sort: true
    },
    {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
                var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            return <>
                {enableWFActions() && <i className="bi bi-trash-fill" onClick={() => this.handleProtocolDelete(row)}
                    style={{visibility: visibilityDelete, fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>}
                
            </>
        }
    }];
    amendmentcolumns = [{
        dataField: 'submissionId',
        text: 'Amendment Title',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <Nav.Link as={NavLink} to={{pathname:`/amendmentdetails/${row.id}/${rowIndex+1}`,state: {amendmentId: row.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0"}}
                    onClick={() => this.handleAmendmentDetails(row, `Submission-${row.submissionId}:Amendment-${row.sno}`, rowIndex)}>{`Submission-${row.submission_id}:Amendment-${row.sno}`}</Nav.Link>
            </>
        }

    }, {
        dataField: 'approval',
        text: 'Approval',
        sort: true
    }, {
        dataField: 'decision_date',
        text: 'Decision Date',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.decision_date?.length > 0 ? dateStringformat(row.decision_date) : ""}</p>
            </>
        }
    },
    {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit =( this.state.selectedRole.includes("coordinator") || this.state.selectedRole.includes("administrator")) ? "visible" : "hidden";
            var visibilityDelete = ( this.state.selectedRole.includes("global_coordinator") || this.state.selectedRole.includes("administrator")) ? "visible" : "hidden";
            
            return <>
                {enableWFActions() && <i className="bi bi-trash-fill" onClick={() => this.handleAmendmentDelete(row)}
                    style={{ visibility: visibilityDelete , fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>}
                {enableWFActions() && <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row, rowIndex)}
                    style={{visibility: visibilityEdit , fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>}
            </>
        }
    }]
    handleProtocolDetails =(row) => {
        //TODO 
        //MAKE SURE ROW VALUE REMAIN SAMES WHEN WE SEARCH FOR PARTICULAR RECORDS
        this.props.history.push(`/protocol/details/${this.state.response.id}`, { protocolId: this.state.response.id });
    }
   
    handleModalShowHide = async (flag, viewEditFlag, row, rowIndex) => {
        this.state.amendmentIndex = rowIndex+1;
        if (viewEditFlag === "edit" && row && row.id) {
            await this.getAmendmentById(row.id);
            this.setState({ showHideModal: flag, viewEditFlag })
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} })
    }
    getAmendment = async (submissionid) => {
        try {
            const res = await API.get(`amendment/submission/${submissionid}`)
            res.data.map((item, index) => item["sno"] = index + 1);
            this.setState({ amendmentData: res.data });
            console.log(res.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }
    getAmendmentById = async (id) => {
        const res = await API.get(`amendment/getbyid/${id}`)
        this.setState({ viewEditData: res.data })
    }
    handleAmendmentDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`recycle/amendment/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getAmendment(this.props.submissionDetails.id);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getAmendment(this.props.submissionDetails.id)
                }
            });
    }
    handleProtocolDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`recycle/protocol/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getProtocol(this.props.submissionDetails.id);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getProtocol(this.props.submissionDetails.id)
                }
            });
    }
    getHtmlFromEditorValue = (val="") => {
        return stateToHTML(val.getCurrentContent());
 
     }
    handleAmendmentSave = async (state) => {
        delete state.submissionId;
        state.feedback=state.feedback ? this.getHtmlFromEditorValue(state.feedback):"";
        state.submission_id = this.props.submissionDetails.id;
        state.created_by = this.context.userSystemId;
       /*  if(state.approval.toLowerCase()===APPROVED.toLowerCase() || state.approval.toLowerCase()===DECLINED.toLowerCase())
        {
            state.decision_date=new Date();
        } */
        state["decision_date"] = state.decision_date != "" && state.decision_date != null ?
        dateChange(state.decision_date) : null;
       
        try {
            const res = API.post("amendment/add", state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })
            this.getAmendment(this.props.submissionDetails.id);
        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
    }
    handleAmendmentUpdate = async (state) => {
        state.submission_id = this.props.submissionDetails.id;
        state.updated_by = this.context.userSystemId;
        state.feedback=state.feedback ? this.getHtmlFromEditorValue(state.feedback):"";
        state["decision_date"] = state.decision_date != "" && state.decision_date != null ?
        dateChange(state.decision_date) : null;
        try {
            const res = API.put(`amendment/update/`, state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })
            this.getAmendment(this.props.submissionDetails.id);
        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
    }
    handleAmendmentDetails = (row, name, rowIndex) => {
        this.props.history.push(`/amendmentdetails/${row.id}/${rowIndex+1}`, { amendmentId: row.id, name: name, rowIndex: rowIndex })
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getAmendment(this.props.submissionDetails.id);
    }
    render() {
        const { showHideModal, viewEditFlag, viewEditData, successModal, errorModal, success_body } = this.state
        console.log(this.props)
        return (
            <div>
                <Row>
                    <Col md={6}>
                        <h6 className="table-top"> Protocol ({this.state.data.length})</h6>
                    </Col>
                    <Col md={2}>
                        {this.state.access.create && enableWFActions() && <Link as={Button} to={{ pathname: "/protocol/new", state: { submissionId: this.props.submissionDetails.id } }}>
                            <Button variant="outline-light" className="new-button" disabled={this.state.data?.length > 0}>New</Button>
                        </Link>}
                    </Col>
                    <DatatableComponent id="protocol" data={this.state.data} columns={this.columns} rowEvents={this.rowEvents} />
                </Row>
                <Row>
                    <Col md={4}>
                        <h6 class="table-top">
                            Amendments ({this.state.amendmentData.length})
                        </h6>
                    </Col>
                    <Col md={4}>
                        {this.state.access.create && enableWFActions() && <Link as={Button} onClick={() => this.handleModalShowHide(true, null, {})}>
                            <Button variant="outline-light" className="new-button">New</Button>
                        </Link>}
                    </Col>
                    <DatatableComponent id="amendment" data={this.state.amendmentData} columns={this.amendmentcolumns} pagination="true" />
                </Row>
                {showHideModal &&
                    <AmendmentModal
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handleAmendmentSave={this.handleAmendmentSave}
                        handleAmendmentUpdate={this.handleAmendmentUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}
                        submission_id={this.props.submissionDetails.id}
                        name={this.state.amendmentIndex}
                    />}
                {this.state.successModal &&
                    <BootstrapModal
                        show={successModal}
                        header="Success"
                        body={success_body}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </div>
        )
    }
}


export default withRouter(Protocol)