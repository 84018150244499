import React from 'react'
import {Modal,Button, Row, Col} from 'react-bootstrap';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import InputField from '../../common/input-fields/InputField';
import Form from 'react-bootstrap/Form';
import { showMessage} from '../commonFunctions';
import {DateTime} from "luxon"
import { yyyymmddFormat } from '../../common/dateFormat';
import PropTypes from 'prop-types';
import FileUploader from '../FileUploader';
import {downloadSharepointFile, getLastSubmittedReview, getMilestoneData, getReviewData, getReviewTypeDetails, getSharepointFiles} from './commonReviewFn'
import SharepointConnect from '../SharepointConnect';
import { dateStringformat } from '../../../utility/helperFunctions';
import AdditionalReview from './additionalReview';
import {downloadCSV} from '../../../utility/helperFunctions';
import { iirDateFormat,iirDateFormatEx,GLOBAL_DATE_FORMAT_NOTIME } from '../../../utility/dateHelper';
import moment from 'moment';
export default class BaseReview extends React.Component
{
    constructor(props) {
        super(props);
    }
    async componentDidMount(){
        //console.warn("addtnl_review_form",this.props);
        this.getExternalUrl();
        await this.getExistingAdditionalReview();
        if(this.props.formView==="READONLY"||this.props.formView==="EDIT"||this.props.formType==="COORDINATOR"){
            this.getSubmittedReview();
        }
        if(this.props.formType==="REVIEWER"||this.props.formType==="COORDINATOR"){
            this.getDocInfo();
        }
        else  if(this.props.formType==="COMMITTEE"){
          this.setCommiteeFormDefaultData();
        }
    }
    setCommiteeFormDefaultData=async()=>{
        let reviewForm = await this.getMilestoneInfo();
        if(reviewForm){
            this.setState({
                reviewForm: { ...reviewForm },
                reviewFormOrg:{...reviewForm}
            });
        }
       
    }
    onInputChange = (e, eo) => {
        let {reviewForm,errors}=this.state;
        if (eo.type === "date") {           
            reviewForm[eo.name]=e;
            
        }
        else if (eo.type === "switch") {    
            let {name,value}=e.target;       
            reviewForm[name]=e.target.checked;
            
        }
        else{
            let {name,value}=e.target;
            reviewForm[name]=value;
        }
        this.setState({
            reviewForm:{...reviewForm}
        });
    }
    onSelectChange = (e, eo) => {
        let {reviewForm,errors}=this.state;
        reviewForm[eo.name || eo.target.name]=e
        this.setState({
            reviewForm:{...reviewForm}
        });
    }
    downloadFormData = () => {
        let downloadArray = [];
        if(this.state.isAdditionalReview){
            let addData = this.AdditionalReviewForm.state.originalFields;
            let fieldMappingInfo = this.AdditionalReviewForm.fieldMappingInfo;
            if(addData){
                for (const [k, v] of Object.entries(addData)) {
                    if(k !== 'errors' && (this.AdditionalReviewForm.imageFields.indexOf(k)==-1) && typeof(v)!=='object')
                        downloadArray.push({field: k, value: v});
                }
                downloadArray = downloadArray.map((ele,index)=>({
                    Field: fieldMappingInfo[ele.field],
                    Value: ele.value//((fieldMappingInfo[ele.field]?.toLowerCase().includes('date') && ele.value) ? yyyymmddFormat(ele.value) : ele.value)
                }));
                downloadCSV(downloadArray, 'Additional Review Form');
            }
            
        }else{
            let formType=this.props.formType||"";
            let fields= this.InputElements.filter(p=>p.formType.includes(formType));
            
            downloadArray = fields.map(element => ({
                field: element.label,
                value: ((element.label?.toLowerCase().includes('date') && this.state.reviewFormOrg[element.name]) ? iirDateFormatEx(this.state.reviewForm[element.name]) : this.state.reviewFormOrg[element.name]) 
            }));
            downloadCSV(downloadArray, 'Review Form');
        }
        
    }
    isUndefinedOrNull=(field)=>{
        let _result=field==undefined||field==null;
        if(!_result && typeof field === 'string'){
            _result=field.trim().length<=0;
        }
        return _result ;
    }
    getMilestoneInfo=async()=>{
        let milestoneData=null;       
        let {submission_id,review_type,review_for,formView}=this.props;
        //Handle Default Data for the Publication
        let {reviewForm}=this.state;
        let required_milestones=["publication","amendment"];
        if(formView!="READONLY" && required_milestones.includes(review_type.toLowerCase())){
            milestoneData=await getMilestoneData({review_type:review_type,record_id:review_for});
            if(review_type.toLowerCase()==="publication"){
                if(milestoneData){
                    reviewForm.title=milestoneData.title||"";
                    reviewForm.format=milestoneData.format||"";
                }
            }    
            else if(review_type.toLowerCase()==="amendment"){
                if(milestoneData){
                    reviewForm.protocol_version_number=milestoneData.protocol_version_number||"";
                }
            }             
        }
        return reviewForm;
    }
    getDocInfo=async()=>{
        let {submission_id,review_type,review_for}=this.props;
        let field="";
        switch(review_type.toLowerCase()){
            case "proposal":
            case "protocol":
            case "concept":
            case "amendment":
            case "publication":field="title";review_type='Concept';break;
        }

        let typeData=await getReviewTypeDetails({submission_id,review_type});
        let reviewForm=await this.getMilestoneInfo();
        this.setState({
            investigatorName: typeData.investigatorname || "",                 
            stageTitle: typeData[field] || "",
            reviewForm:{...reviewForm},
            reviewFormOrg:{...reviewForm}
        });
       
    }
    validateAdditionalForm=()=>{
        let {additionalReviewError,addtnl_review_form}=this.state;
        if(!addtnl_review_form){
            return true;
        }
        if(addtnl_review_form&&additionalReviewError){
            let isValid=true;
            for (const [key, value] of Object.entries(additionalReviewError)) {
                isValid=value;
                return !value;
              } 
              return isValid;
        }
        return true;
    }
    getData=()=>{
       
        if(this.validateInput()){
            let {reviewForm,additionalReviewData}=this.state;
            let responseInfo=JSON.parse(JSON.stringify(reviewForm));
            let inputs=this.getFields().filter(p=>p.type==="dropdown");
            inputs.forEach((item)=>{
                if(!["rank_the_importance","status","does_protocol_match_details","committee_approval","concept_in_scope"].includes(item.name)){
                    if(reviewForm[item.name]==="Yes"){responseInfo[item.name]=true}
                    if(reviewForm[item.name]==="No"){responseInfo[item.name]=false}
                }
                // responseInfo[item.name]=(reviewForm[item.name]==="Yes");
            });           
            responseInfo["date_of_review"]= iirDateFormat(reviewForm["date_of_review"]);
            responseInfo["is_coordinator_entry"]= (this.props.formType==="COORDINATOR");
            return responseInfo;
        }
        else{
            let {addtnl_review_form}=this.props;
            if(addtnl_review_form&&this.state.isAdditionalReview){
                this.handleTabChange("");
            }
        }
        return null;
    }
    getExistingAdditionalReview=async ()=>{
        let {addtnl_review_form,review_header_id}=this.props;
        if(addtnl_review_form){
            let response = await API.post("reviewsTab/additionalReview/data", {
                    "review_header_id":review_header_id,
                    "addtnl_review_form": addtnl_review_form
            });
            if(response && response.data){
                let collection=response.data||[];
                if(collection.length>0){
                    if(collection.length>1){
                        collection.sort((a, b) => a.id - b.id);
                    }                
                    let additionalReviewData=collection.pop();
                    this.setState({additionalReviewData:additionalReviewData["review_data"]});
                }
               
            }
        }
    }
    getAdditionalFormData=()=>{
        let {addtnl_review_form}=this.props;
        
        if(addtnl_review_form){
            let {additionalReviewData,additionalReviewFile}=this.state;          
            if(this.AdditionalReviewForm){
                let {files,formValues}=this.AdditionalReviewForm.getFormData();
                additionalReviewData=formValues;
                additionalReviewFile=files||additionalReviewFile;
             }
             return  {additionalReviewData,additionalReviewFile}
        }
        return null;
    }
    getFile=()=>{
        let {fileInfo}=this.state;
        return fileInfo;
    }
    getSharepointFile=()=>{
        let {sharePointFileInfo}=this.state;
        return sharePointFileInfo;
    }
    getFields=()=>{
        
        let formType=this.props.formType||"";
        let fields= this.InputElements.filter(p=>p.formType.includes(formType));
        if(formType==="COMMITTEE"){
            // order by committeOrder            
            fields= fields.sort((a, b) => a.committeOrder - b.committeOrder);
        }  
        else if(formType==="REVIEWER"){
            // order by committeOrder            
            fields= fields.sort((a, b) => a.reviewOrder - b.reviewOrder);
        }      
        return fields;
    }
    getformField=(field,reviewForm,errors,formView,permissionProps)=>{
        let formType=this.props.formType||"";
        if(field.helptext&&field.helpAvailability){
            let required=(field.helpAvailability||[]).filter(p=>p===formType);
            if(required.length<=0){
                delete field.helptext;
            }
        }
        if(field.type!="switch")
        return <InputField
        {...field}
        value={reviewForm[field.name]}                       
        error={errors?.[field.name]}
        onSelect={field.onSelect || this.onSelectChange}
        onChange={(e) => this.onInputChange(e,field)}
        {...permissionProps}
    />
        return  <Form.Group  >
        <Form.Check 
       
        label={field.label}
        type="switch"
        id="custom-switch"
        name={field.name}
        value={reviewForm[field.name]}  
        checked={reviewForm[field.name]}
        style={{fontSize:".75rem"}}
        defaultChecked={(reviewForm[field.name])}
        onChange={(e)=>this.onInputChange(e,field)}
        {...permissionProps}
        />        
        </Form.Group>

    }
    
    renderInputs=()=>{
        let {reviewForm,errors,formView,isAdditionalReview,additionalReviewData,additionalReviewError,additionalReviewFile,sharepointDocumentAdditional}=this.state;
        let {addtnl_review_form}=this.props;
        //console.log(this.state);
        if(isAdditionalReview)return <AdditionalReview formView={formView} sharepointFiles={sharepointDocumentAdditional} refreshHandler={this.setAdditionalFormData} addtnl_review_form={addtnl_review_form} files={additionalReviewFile} errors={additionalReviewError} ref={ref=>this.AdditionalReviewForm=ref} source={additionalReviewData}></AdditionalReview>;
        let permissionProps={};
       
        if(formView==='READONLY'){           
            permissionProps={disabled:'disabled'}
        }
        return this.getFields().map(field => {    

            return (
                <>
                <Col sm={field.col||12} key={field.name}>
                    {field.col === 6 && <div style={{ marginLeft: '2%' }}>
                        {this.getformField(field,reviewForm, errors, formView, permissionProps)}
                    </div>}
                    {field.col != 6 && this.getformField(field,reviewForm, errors, formView, permissionProps)
                    }
                </Col></>
                
                
            )
        });
        
    }
    handleTabChange=(val)=>{
        let {addtnl_review_form}=this.props;
        console.log(addtnl_review_form,'addtnl_review_form')
        if(!addtnl_review_form)return false;
        let {isAdditionalReview,additionalReviewData,additionalReviewError,additionalReviewFile}=this.state;
        switch(val){
            case 'ADDITIONAL':isAdditionalReview=true;break;
            default:isAdditionalReview=false;break;
        }
       
        if(this.AdditionalReviewForm){
            let data=this.AdditionalReviewForm.getFormData();
            additionalReviewData=data.formValues;
            additionalReviewError=data.errors;
            additionalReviewFile=data.files;
         } 
         this.setState({isAdditionalReview,additionalReviewData,additionalReviewError,additionalReviewFile},console.log(this.state, 'stateintabchange'));

    }
    setAdditionalFormData=()=>{
        let {isAdditionalReview,additionalReviewData,additionalReviewError,additionalReviewFile}=this.state;
        if(this.AdditionalReviewForm){
            let data=this.AdditionalReviewForm.getFormData();
            additionalReviewData=data.formValues;
            additionalReviewError=data.errors;
            additionalReviewFile=data.files;
         } 
         this.setState({additionalReviewData,additionalReviewError,additionalReviewFile});
    }
    getProposalFormDescription=()=>{
        return (<>
            Please fill out this proposal review that is a requirement for the submission. Please remember to use good documentation practices and avoid comments that could appear to influence the concept, proposal, protocol, or publications.
            <p>Consider the following questions during your review:  </p>
            <ul>
                <li>Does the study address an important problem? </li>
                <li>Does it advance scientific or clinical care?   </li>
                <li>Are framework, design, & methods developed? </li>
                <li>Is project approach, method, and/or outcome novel? </li>
                <li>Are aims of study original & innovative? </li>
                <li>Is investigator well-suited to carry out study?  </li>
                <li>Are enrollment & milestones feasible?   </li>
                <li>Is requested funding appropriate? </li>
            </ul>
        </>);
    }
    getFormDescription=()=>{
        let {review_type}=this.props;
        let commonText1=`Please fill out this ${review_type.toLowerCase()} review that is a requirement for the submission. Please remember to use good documentation practices and avoid comments that could appear to influence the concept, proposal, protocol, or publications.`;
        let formDescriptions={
            "CONCEPT":commonText1,
            "PROTOCOL":commonText1,
            "PUBLICATION":commonText1,
            "AMENDMENT":commonText1,
            "PROPOSAL":this.getProposalFormDescription(),
        }

        return formDescriptions[review_type.toUpperCase()];
    }
    renderTopArea=()=>{
        let { fileInfo, reviewForm, downloadableDocuments, formView,isAdditionalReview } = this.state;
        let { isCoordinatorApproval,review_type,addtnl_review_form } = this.props;
       
        return <>
        
        {(this.props.formType === "REVIEWER" || this.props.formType === "COORDINATOR") && <Row>
                <Col >
                    <div style={{ marginLeft: '2%' }}>
                        <div>
                            <span onClick={e=>this.handleTabChange('')} style={{cursor:'pointer'}} className={["pb-2 font-weight-bold",!isAdditionalReview?"border-bottom border-danger":""].join(" ")}>{review_type.toUpperCase()} REVIEW</span>
                            {addtnl_review_form&&<span onClick={e=>this.handleTabChange('ADDITIONAL')} style={{cursor:'pointer'}} className={["pb-2 font-weight-bold ml-3",isAdditionalReview?"border-bottom border-danger":""].join(" ")}>ADDITIONAL REVIEW</span>}
                        </div>
                        
                        {!isCoordinatorApproval &&!isAdditionalReview && <div className='mb-3 mt-3' style={{ fontWeight: "500", fontSize: '1.3rem' }}>{review_type} Review for <strong>{this.state.stageTitle}</strong> by <strong>{this.state.investigatorName}</strong> Approval by Reviewer</div>}
                        {/* {isCoordinatorApproval &&!isAdditionalReview && <div className='mb-3 mt-3' style={{ fontWeight: "500", fontSize: '1.3rem' }}>{review_type} Coordinator Review for <strong>{this.state.stageTitle}</strong></div>} */}
                        {isCoordinatorApproval &&!isAdditionalReview && <div className='mb-3 mt-3' style={{ fontWeight: "500", fontSize: '1.3rem' }}>{review_type} Coordinator Documentation</div>}
                        {!isAdditionalReview&&!isCoordinatorApproval && <div className='mb-3 mt-3' >{this.getFormDescription()}</div>}
                        {isCoordinatorApproval&&!isAdditionalReview&&<div className='mb-3 mt-3' >Please fill out this form to document the final decision for the review.</div>}
                    </div>
                
                </Col>
            </Row>}
        </>
    }
    renderFileArea=()=>{
        let {fileInfo,reviewForm,downloadableDocuments,formView,sharePointFileInfo,isAdditionalReview}=this.state;
        if(isAdditionalReview)return <></>;
        let {isCoordinatorApproval}=this.props;
        return <>
        {(!formView||formView!="READONLY")&&<>
           <Col xs={12} sm={6}>
           <div style={{marginLeft:'2%'}}>
           <FileUploader fileProps={{multiple:true}} placeholder="Sharepoint File" fileInfo={sharePointFileInfo} onChange={e=>{
           
                        let activeFile= e.target.files;//[0];    
                        let selectedFileName=[];
                            if (activeFile.length > 0) {
                                for (let i = 0; i < activeFile.length; i++) {
                                    selectedFileName.push(activeFile[i].name);
                                }
                            }
                        this.setState({ sharePointFileInfo: { selectedFileName: selectedFileName.join(","), file: activeFile } });
                    }}></FileUploader>
                   
            </div>
           </Col>
          <Col xs={12}  sm={6}>
          <div style={{marginLeft:'2%'}}>
                {/*<FileUploader fileInfo={fileInfo} onChange={e => {
                    let activeFile = e.target.files[0];
                    this.setState({ fileInfo: { selectedFileName: activeFile.name, file: activeFile } });
                }}></FileUploader>*/}
                </div>
          </Col>
           </>}
        </>
    }
    handleDownload=(data)=>{
        let {review_submission_id,review_header_id,reviewers_detail_id}=this.props;//;
        let fileName=data.key.split("\/").pop();
        let key=`${review_submission_id}-${review_header_id}-${reviewers_detail_id}`;
        let fileUrl = `${this.state.externalurl}/filedownload/Review/${encodeURIComponent(key)}?name=${encodeURIComponent(fileName)}`
        fetch(fileUrl, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'Content-Type': 'application/json',
            },
        })
            .then(async(response) => {
                if (response.ok) {                   
                    return response.blob();
                }
            })
            .then((blob) => {               
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', decodeURIComponent(fileName));
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);               
            });
     }
     getSubmittedReview=async()=>{
        let {review_submission_id,review_type}=this.props;
        let res=null;
        if(review_submission_id){
             res = await getReviewData(this.props);  
        }
        else  if(this.props.formView==="EDIT"){
         
             res = await getLastSubmittedReview(this.props); 
             if(res&&res.id){
                delete res["id"];
                delete res["review_header_id"];
                delete res["reviewersDetailId"];
                delete res["status"];
                delete res["created_by"];
                delete res["updated_by"];
                delete res["created_at"];
                delete res["updatedAt"];
             }
             else{
                res=null;
             }
        }
        //getLastSubmittedReview
        //submission_id,review_header_id,reviewers_detail_id

        if(res){
            //const res = await getReviewData(this.props);           
            if(res){
                let {reviewForm,downloadableDocuments}=this.state;                
                for (const [key, value] of Object.entries(reviewForm)) {
                    reviewForm[key]=res[key];
                    if (typeof res[key] == "boolean") {
                        reviewForm[key]=(res[key]===true?"Yes":"No");
                    }      
                    // if(review_type.toLowerCase()==="protocol"&&key==="is_approved"){
                    //     reviewForm["is_approved"]=res[key];
                    // }              
                  }             
                  
                //   reviewForm["date_of_review"]= yyyymmddFormat(res["date_of_review"]);
                  if(res["date_of_review"]){
                    reviewForm["date_of_review"]=moment(res["date_of_review"],"YYYY-MM-DD",false).format(GLOBAL_DATE_FORMAT_NOTIME)//iirDateFormat(res["date_of_review"]);
                 }
                  downloadableDocuments=res["documents"];
                  let sharepointDocument=await getSharepointFiles(this.props);
                  let sharepointDocumentOldReviews=await getSharepointFiles({...this.props,isOldReview:true});
                  if(sharepointDocumentOldReviews&&sharepointDocumentOldReviews.length>0){
                    sharepointDocument=[...sharepointDocument,...sharepointDocumentOldReviews];
                  }
                  let sharepointDocumentAdditional=await getSharepointFiles({...this.props,additional:'-additionalform'});
                  this.setState({reviewForm,reviewFormOrg:reviewForm,downloadableDocuments,sharepointDocument,sharepointDocumentAdditional});
            }
        }
     }
    
     handleSharepointDownload=async(data)=>{
       await downloadSharepointFile(data);

    }
     getExternalUrl=async ()=> {
        //debugger;
        let response ='';
        try {
             response = await API.get("user/externalurl");
             //console.warn(response);
           
        }
        catch (err) {
            //console.log(err);
        }
        this.setState({         
        externalurl : (response && response.data) ? response.data.exturl : "",
    })
    }
    handleCopyUrl=(e,data,index)=>{
        e.preventDefault();
        e.stopPropagation();
        if(data.LinkingUrl){
            navigator.clipboard.writeText(data.LinkingUrl);
            this.setState({showCopyInfo:true, indexOfIcon:index},this.clearCopyInfo);
        }
            
    }
    clearCopyInfo=()=>{
        setTimeout(()=>{
            this.setState({showCopyInfo:false, indexOfIcon:null}) 
        },500);
    }
    renderAdditionalReviewArea=()=>{
        return <AdditionalReview></AdditionalReview>;
    }
    renderFileDownloadArea=()=>{
        let {fileInfo,reviewForm,downloadableDocuments,formView,sharepointDocument,showCopyInfo,isAdditionalReview}=this.state;
        if(isAdditionalReview)return <></>;
        let {isCoordinatorApproval}=this.props;
      
        return  <>{(formView==="READONLY" || this.props.formType === "COORDINATOR" )&&<Col xs={12}>
        <div className='d-flex '>
            <ul className='d-flex m-0 p-0 flex-column'>
            {downloadableDocuments&&downloadableDocuments.map((item,index)=>{
                return <li key={index} onClick={e=>this.handleDownload(item)} className='d-flex align-items-center p-2' style={{cursor:'pointer'}}>
                            <span>{item.key.split("\/").pop()}</span>
                            <span className="material-icons ml-3">
                                file_download
                            </span>
                </li>
            })}
            {sharepointDocument&&sharepointDocument.map((item,index)=>{
                return <li key={index} onClick={e=>this.handleSharepointDownload(item)} className='d-flex align-items-center position-relative p-2' style={{cursor:'pointer'}}>
                            <span>{item.Name}</span>
                            
                            <span  className="material-icons ml-3" style={{cursor:'pointer'}}>
                                file_download
                            </span>
                    <span class="material-icons ml-3" onClick={e=>this.handleCopyUrl(e,item,index)}>
                        content_copy
                    </span>
                  {showCopyInfo&&(this.state.indexOfIcon===index)&&<div style={{right:-30,top:0,zIndex:100}} className="shadow-sm p-1 rounded-sm position-absolute bg-white">
                        Copied !
                    </div>}
                </li>
            })}
            </ul>
        </div>
      </Col>}</>
    }
}