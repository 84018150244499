import React from "react";
import Charts from "../../common/Charts/Charts";
import DatatTableEx from "../../common/DataTableEx";
import UserContext from "../../../context/UserContext";
import { column_captions } from "./field_constants";
export default class Preview extends React.Component{
    static contextType=UserContext;
    constructor(props){
        super(props);
    }
    columns = [
       
        {
            dataField: 'milestone',
            text: column_captions['milestone'],
           
        },
        {
            dataField: 'estimated_date',
            text: column_captions['estimated_date'],
           
          
        },
        {
            dataField: 'invoice',
            text: column_captions['invoice'],
            
          
        },
        {
            dataField: 'estimated_cost',
            text: column_captions['estimated_cost'],
           
        },
        {
            dataField: 'date_received',
            text: column_captions['date_received'],
           
        },
       
        {
            dataField: 'actual_amount',
            text:  column_captions['actual_amount'],
            
        },
        {
            dataField: 'currency_name',
            text: 'Currency',
           
        },
        {
            dataField: 'comments',
            text:  column_captions['comments'],
            
        }
    ]
    componentDidMount(){

    }
    
    render(){
        return  <>
          
        <DatatTableEx
                        id="enrollment"
                        data={this.props.formData.csv||[]}
                        columns={this.columns}
                        pagination="true"
                        titleClass="title-lg"                                       
                                           
                        csvName="milestone_expenses"
                        />
                        </>
    }
}
