import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import InputField from './common/input-fields/InputField';
import API from '../api/apis';
import BootstrapModal from './common/modal/BootstrapModal';

export default class NewDrugDeliverySite extends Component {

    state = {
        submission: "Submission-"+ this.props.location.state.submissionId,
        contactName: "",
        siteName: "",
        phoneNumber: "",
        streetAddress: "",
        emailAddress: "",
        streetAddress2: "",
        utilizingethicsapproval: false,
        city: "",
        IRBERBStartDate: "",
        addressPrefix: "",
        IRBERBExpirationDate: "",
        country: "",
        impactSite: "",
        state: "",
        last_updated_by: "",
        zipCode: "",
        patchObject: {"id": this.props.location.state.submissionId},
        countryList: [],
        stateList: []
    }

    get inputFieldsArray() { 
        return [            
            {
                label: "Submission",
                type: "text",
                name: "submission",
                disabled: true
            },
            {
                label: "[EXT] Contact Name",
                type: "text",
                name: "contactName"
            },
            {
                label: "[EXT] Site Name",
                type: "text",
                name: "siteName"
            },
            {
                label: "[EXT] Phone Number",
                type: "text",
                name: "phoneNumber"
            },
            {
                label: "[EXT] Street Address",
                type: "text",
                name: "streetAddress"
            },
            {
                label: "[EXT] Email Address",
                type: "text",
                name: "emailAddress"
            },
            {
                label: "[EXT] Street Address 2",
                type: "text",
                name: "streetAddress2"
            },
            {
                label: "[EXT] Utilizing ethics approval?",
                type: "checkbox",
                name: "utilizingethicsapproval?"
            },
            {
                label: "[EXT] City",
                type: "text",
                name: "city"
            },
            {
                label: "[EXT] IRB/ERB Start Date",
                type: "date",
                name: "IRBERBStartDate"
            },
            {
                label: "[EXT] Address Prefix",
                type: "text",
                name: "addressPrefix"
            },
            {
                label: "[EXT] IRB/ERB Expiration Date",
                type: "date",
                name: "IRBERBExpirationDate"
            },
            {
                label: "[EXT] Country",
                type: "searchableDropdown",
                name: "country",
                options: this.state.countryList,
                onSelect: this.onCountryChange
            },
            {
                label: "Site #",
                type: "text",
                name: "impactSite#"
            },
            {
                label: "[EXT] State/Province",
                type: "searchableDropdown",
                name: "state",
                options: this.state.stateList,
                onSelect: this.onSearchSelect
            },
            {
                label: "Last Updated By",
                type: "text",
                name: "last_updated_by",
               
            },
            {
                label: "[EXT] Zip/Postal Code",
                type: "text",
                name: "zipCode",
            }
        ];
}
    componentDidMount() {
        this.getCountryList();    
    }

    getCountryList = async () => {
        const res = await API.get("seed/country/data")
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({ countryList: resultArray}, this.setCountry);        
    }

    getStateList = async (country) => {
        if(country){
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({ 
            stateList: resultArray,
        });
        }
    }

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [name]: e.value, state: ""},
            state: ""
        });
        this.getStateList(e.name);
    }

    onInputChange = (e) => {
        const { name, value} = e.target;
    this.setState({
        [name]: value,
        patchObject:  {...this.state.patchObject, [name]: value},
        hasChanged: true
    });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [eo.name || eo.target.name]: e},
            hasChanged: true
        });        
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [name]: e.value},
            hasChanged: true
        }); 
    }

    checkboxChange = (e) => {
        const { name, checked } = e.target;
        this.setState({
            [name]: checked,
            patchObject:  {...this.state.patchObject, [name]: checked},
            hasChanged: true
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    value={this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={ field.type !== "checkbox"? this.onInputChange: this.checkboxChange }
                />
            </Col>
            )
        });
    }

    submitForm = async () => {
        try {
            const res = await API.patch("submission/update", this.state.patchObject);
            this.setState({
                successModal: true,
                patchObject: {"id": this.props.submissionDetails.id},
                hasChanged: false
            });
        }catch(err){
            this.setState({errorModal: true});
        }
    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.props.location.state.submissionId}`)
    }

    render() {
        return (
            <Container className="form-page">
                <div onClick={this.onNavOut} className="back-button">
                    <i className="bi bi-arrow-left">
                        {" "}Submission-{this.props.location.state.submissionId}
                    </i>
                </div>
                <h2 className="text-center">New Drug Delivery</h2>
                <Row>
                        {this.renderInputFields()}
                        <div className="w-100 d-flex justify-content-center">
                        <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm}>
                            Save
                        </Button>
                        </div>
                </Row>
                {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Concept has been created!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </Container>
        )
    }
}
