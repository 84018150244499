import React, { useEffect, useState,useRef } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { getSubmissionText } from '../../utility/helperFunctions';
import styles from './DetailSearch.module.css'
export default (props) => {

    // const [showSearchBox, setShowSearchBox] = useState(false);
    const [showSearchBox, setShowSearchBox] = useState(true);
    const [showSearchDropDown, setShowSearchDropDown] = useState(false);
    const [inputValue, setInputValue] = useState("")
    const targetRef = useRef(null);
    useEffect(() => {     
        function handleClickOutside(event) {
          if (targetRef.current && !targetRef.current.contains(event.target)) {
            clearSearch();
          }
        }     
        document.addEventListener("mousedown", handleClickOutside);
        return () => {     
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [targetRef]);
    
    
    const handleClick = () => {
        setShowSearchDropDown(true);
        props.onSearch(inputValue);
    };
    const clearSearch=()=>{
        setShowSearchDropDown(false);
        if (props.onSelection) {
            //props.onSelection("CLEAR", {})
        }
       
    };
    
    const handleSearch = () => {
        setShowSearchDropDown(true);
        if (inputValue.length <= 0) {

            setShowSearchBox(!showSearchBox);
            clearSearch();
        }
        else {
            handleClick();
        }

    }
    const handleItemSelection = (type, e) => {
        setShowSearchDropDown(false);
        if (props.onSelection) {
            props.onSelection(type, e)
        }
    }
    const renderSearchResults = () => {

        return <ul className="list-style-none d-flex flex-column m-0 p-2 border rounded position-absolute  w-100 bg-white" style={{ listStyle: 'none',left:0,top:30,zIndex:1000 }}>


            {
                props.data.map((item, index) => {

                    return <li onClick={e => handleItemSelection("ITEM", item)} className={[styles.dropdown_item_, "p-1", "w-100"].join(" ")}>
                        <div>{getSubmissionText(item.id)}</div>
                        <div style={{ fontSize: "small", color: "grey" }}>{`${item["concept.investigatorname"]}${item.trial_alias ? " | " + item.trial_alias : ""}`}</div>

                    </li>
                })
            }
            <li onClick={e => handleItemSelection("ALL", {})} className={[styles.dropdown_item_, "p-1", "w-100", "border-top", "text-center"].join(" ")}>
                Show All
            </li>
        </ul>
    }
    
    return <div className='d-flex align-items-center position-relative' ref={targetRef}  >
        <div className="position-absolute d-flex align-items-center justify-content-end" style={{minWidth: showSearchBox?'175px':'0px',right:0}}>
        {props.showLoader && <div className="d-flex align-items-center justify-content-center p-2" >
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>}
        {showSearchBox && <input  className="form-control form-control-sm mr-1 "  placeholder='Search' onKeyDown={e => {
            let code = (e.keyCode ? e.keyCode : e.which);
            if (code == 13) {
                handleSearch();
            }
        }} onChange={e => { setInputValue(e.target.value); }} value={inputValue}></input>}
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">{"Search terms: Submission Id, Investigator first and last name, trial alias, concept type, proposal and protocol title, compound, Email ID for investigator. MSL email and Investigator ID."}</Tooltip>}>
                <i className="bi bi-search mr-3 text-black" onClick={handleSearch} style={{ fontSize: '1rem', cursor: "pointer" }}></i>
            </OverlayTrigger>
        
        {  
            props.data&&props.data.length>0&&showSearchDropDown&&showSearchBox&& renderSearchResults()        
        }
        </div>
    </div>
}