import React, { Component } from 'react'
import UserContext from '../../../context/UserContext';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { dateTimeformat } from '../../../utility/helperFunctions';
import API from '../../../api/apis';
import InputField from '../../common/input-fields/InputField';
import { THERAPEUTIC_AREA, REQUEST_TYPE, INDICATIONS, SECONDARY_REQUEST_TYPE } from '../../../consts';
import {  Card, Col, Container,Form, Row,Modal } from 'react-bootstrap';
import DatatTableEx from '../../common/DataTableEx';
import '../../ToReviewDetails/InitiateReview/custom-css.css'
import { isCoordinator } from '../../ToReviewDetails/commonFunctions';
import Button from '../../common/Button';
import HistoryFile from './HistoryFile';
import SwitchHistory from '../../common/SwitchHistory';
import { OBJ_TYPES } from '../../common/SwitchHistory/historyConsts';
class ConceptDetailsEx extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        historyRefresh:true,
        successModal: false,
        errorModal: false,
        countryList: [],
        compoundList: [],
        conceptDetails: null,
        hasChanged: false,
        organisationList:[],
        primaryOrganisation:{},
        submission_id:"",
        auditData: [],
        trailData: [],
        usersList: [],
    }
    componentDidMount() {
        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        let _isCoordinator=isCoordinator(this.context);
        this.setState({
            selectedRole: selectedRole,
            isCoordinator:_isCoordinator,
            access: {
                view: true,
                create:   selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
   this.getCountryList();   
        this.getOrganizationlist();
        
     }
     componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:   selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)

        this.setState({ usersList: res.data });
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/concept`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    onInputChange = (e) => {
        this.setState({hasChanged: true})
        this.setState({[e.target.name]: e.target.value});
    }
    onSearchSelect = (e, name) => {
        this.setState({hasChanged: true})
        this.setState({
            [name]: e,
        }); 
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/getall")
        const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({ countryList: resultArray}, this.getConceptDetails);
    }
    onCountryChange = (e,name) => {
        this.setState({hasChanged: true})
        this.setState({[name]: e});
        this.setState({
            compound: "",
            indication: "",
            otherIndication: "",
            extPrimaryInstitution:e.name
        });
        this.getCompoundList(e.value);}
        getAuditList = async (id) => {
            try {
                const temp = [];
                const res = await API.get(`audit/concept/${id}`)
                console.log(res)
                await this.getTrailList();
                await this.getUserList();
                await this.getCountryList();
                this.state.trailData.forEach(x => {
                    res.data.forEach(y => {
                        if (x.key.toLowerCase() === y.field.toLowerCase()) {
                            y["Field"] = x.label;
                            let fieldLabels = ["updated_by"]
                            if (fieldLabels.includes(y.field)) {
                                let newValueText =y.new_value!=null ? this.state.usersList.filter(x => x.system_id === (y.new_value))[0] : "";
                                y.new_value = newValueText!="" ? newValueText?.first_name + " " + newValueText?.last_name : "";
                                let oldValueText =y.old_value!=null ? this.state.usersList.filter(x => x.system_id === (y.old_value))[0] : "";
                                y.old_value =oldValueText!="" ? oldValueText?.first_name + " " + oldValueText?.last_name : "";
                                temp.push(y);
                            }
                            else if(y.field==="country")
                            {
                                let newValueText=y.new_value!=null ? this.state.countryList.filter(x=> x.value+""===(y.new_value))[0] :"" ;
                            y.new_value=newValueText!="" ? newValueText.name : "";
                          let  oldValueText=y.old_value!= null ? this.state.countryList.filter(x=> x.value+""===(y.old_value ))[0] :"";
                            y.old_value=oldValueText!="" ? oldValueText.name :"";
                                temp.push(y);
                            }
                            else if(y.field==="created_at"){
                                y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):""
                                y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                                 temp.push(y);
                             }
                            else {
                                y.new_value = (y.new_value);
                                y.old_value = (y.old_value)
                                temp.push(y);
                            }
    
                        }
    
    
                    })
                })
                let sortedReviews = temp.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                this.setState({
                    auditData: sortedReviews,
                })
    
            }
            catch (err) {
                console.log(err)
            }
        }
    getOrganizationlist=async ()=>{
        
        const res = await API.get("/seed/organisation/data")
        const resultArray =(res.data?.data || [] ).map(elm => ({ label: elm.name,value:elm.name}));
        this.setState({ organisationList: resultArray});
    }
    getCompoundList = async (e,eo) => {
        const res = await API.get(`seed/getCompoundbyCountry/${e}`)
        const arr = res.data.compounds.filter(elm => elm.therapeutic_area===this.state.therapeuticArea );
        const resultArray = arr.map(elm => ({ name: elm.name, value: elm.Country_Compound.compoundId})) || [];
        this.setState({
            compoundList: resultArray,
            compound: resultArray.find(compound => compound.value == this.state.conceptDetails.compound_id) || "",

       });
       //this.getAuditList(this.state.id)
    }
    onSelectChange = (e, eo) => {
        if((eo.name || eo.target.name)==="therapeuticArea")
        {this.setState({[eo.target.name]: e,
            hasChanged: true})
            this.getCompoundList(this.state.extInvestigatorCountry.value);
        }
        else
        {this.setState({hasChanged : true})
        this.setState({
            [eo.name || eo.target.name]: e
        });   }     
    }
    onCountryChange = (e,name) => {
        this.setState({hasChanged: true})
        this.setState({[name]: e});
        this.setState({
            compound: "",
            indication: "",
            otherIndication: "",
            extPrimaryInstitution:e.name
        });
        this.getCompoundList(e.value);
    }
    submitForm = async () =>  {
        const conceptObj = {
            "interventional_study":this.state.interventional_study,
            "request_for_lilly_trial_data":this.state.request_for_lilly_trial_data,
            "vitro_vivo_study":this.state.vitro_vivo_study,
            "assessing_utility_biomarkers":this.state.assessing_utility_biomarkers,
            "investigatorname": this.state.investigatorName,
            "country": this.state.extInvestigatorCountry.value,
            "primary_institution": this.state.primaryOrganisation.value,
            "request_type": this.state.requestType,
            "therapeutic_area": this.state.therapeuticArea,
            "compound_id": this.state.compound.value,
            "indication": this.state.therapeuticArea==="Oncology"? (this.state.indication.length > 0 ? this.state.indication.map(obj => obj.value).join(";") : "") : null,
            "other_indication": this.state.otherIndication,
            "title": this.state.conceptTitle,
            "description": this.state.conceptDescription,
            "id": this.state.id,
            "updated_by": this.context.userSystemId,
            "requesting_research_funding": this.state.requesting_research_funding
            
        }
        try {
            this.setState({historyRefresh:false});
            const res = await API.patch("concept/update", conceptObj);
            if(res.data.success){
            this.setState({successModal: true,historyRefresh:true})}
            else{
                this.setState({errorModal:true,historyRefresh:true})
            }
        }catch(err){
            this.setState({errorModal: true,historyRefresh:true});
        }
    }

    
    getConceptDetails = async () => {
        const submissionId = this.props.submissionId;
        //alert(submissionId);
        
        if(submissionId){
            const res = await API.get(`reviewConcept/concept/getbyid/${submissionId}`);
            //alert(JSON.stringify(res))
            //console.log(res.data)
            if(res&&res.data&&res.data.success){
            this.setState({
                id:res.data.data.id,
                conceptDetails: res.data.data,
                investigatorName: res.data.data.investigatorname || "",
                extInvestigatorCountry: this.state.countryList.find(country => country.value === res.data.data.country) || "",
                extPrimaryInstitution: res.data.data.primary_institution || "",
                requestType: res.data.data.request_type || "",
                therapeuticArea: res.data.data.therapeutic_area || "",
                compound: res.data.data.compound_id,
                indication: res.data.data.indication ? res.data.data.indication?.trim().split(';').join()?.split(',').map(obj => { return {value: obj,label: obj}})  || "" : '',
                otherIndication: res.data.data.other_indication || "",
                conceptTitle: res.data.data.title || "",
                conceptDescription: res.data.data.description || "",
                submissionId: `Submission-${res.data.data.submissionId}` || "",
                submission_id:res.data.data.submissionId,
                vitro_vivo_study:res.data.data.vitro_vivo_study,
                assessing_utility_biomarkers:res.data.data.assessing_utility_biomarkers,
                request_for_lilly_trial_data:res.data.data.request_for_lilly_trial_data,
                interventional_study:res.data.data.interventional_study||null,
                requesting_research_funding: res.data.data.requesting_research_funding || "",
                primaryOrganisation:{label:res.data.data.primary_institution,value:res.data.data.primary_institution}
            }, ()=>this.getCompoundList(this.state.extInvestigatorCountry.value));
        }}
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
       // this.getAuditList(this.state.id)
    }

    render(){
        let _isCoordinator=this.state.isCoordinator;
        let newOptions=["Yes","No"];
        return(
          
           <Container>
            
            
             
        <Row><Col sm={8}> <span className="flex-grow-1 font-weight-bold">Concept Details</span></Col>
        <Col sm={4} className=" d-flex align-items-center justify-content-end "> {_isCoordinator&& <Button className="d-flex align-items-center justify-content-center mr-2 p-2 " style="success w-100px" type="button" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                <span>SAVE</span>
                        </Button>}</Col></Row>
        
            <Modal.Body className="new-flow-modal">
            <Row>
                <Col sm={6}>
                    <InputField
                           label="Investigator name"
                           type= "text"
                           name= "investigatorName"
                          value={this.state.investigatorName}
                           placeholder="Investigator Name"
                           onChange={this.onInputChange }
                          disabled={!_isCoordinator}
                        />
                </Col>
                
                <Col sm={6}>
                    <InputField
                           label="Country"
                           type= "searchableDropdown"
                           name= "extInvestigatorCountry"
                           placeholder="Country"
                           value={this.state.extInvestigatorCountry}
                           options={this.state.countryList}
                           disabled={!_isCoordinator}
                           onSelect={ (e,eo)=>{this.onCountryChange(e,eo)}}
                          
                        />
                </Col>
                <Col sm={6}>
                    <div  className="pl-2" style={{width:'97%'}}>
                    <InputField
                           //label= "Review Type *"
                           label="Primary Institution"
                           type= "creatable"
                           disabled={!_isCoordinator}
                           name= "primaryOrganisation"
                           value={this.state.primaryOrganisation}
                           options={ this.state.organisationList}
                            onSelect= {this.onSearchSelect}
                           placeholder="Primary Institution"
                          
                        />
                    </div>

                   
                </Col><Col sm={6}></Col>
                
            
                        <Col sm={6} style={{ left: "0.5rem", marginBottom: "2rem" }} >

                            <label>{"Is this request for an interventional study (i.e., involving human subjects)?"}</label>
                            <div>

                                {newOptions.map((item, index) => {
                                    return <Form.Check value={item} checked={this.state.interventional_study===item} name={"interventional_study"} onChange={this.onInputChange} inline label={item} type="radio" />
                                })}
                            </div>

                        </Col>
                        <Col sm={6} style={{ left: "0.5rem", marginBottom: "2rem" }} >

                        <label>Is this a request for <u>Lilly trial data or samples</u>?</label>
                        <div>

                            {newOptions.map((item, index) => {
                                return <Form.Check value={item} checked={this.state.request_for_lilly_trial_data===item} name={"request_for_lilly_trial_data"} onChange={this.onInputChange} inline label={item} type="radio" />
                            })}
                        </div>

                        </Col> 
                        <Col sm={6} style={{ left: "0.5rem", marginBottom: "2rem" }} >

                        <label>{"Is this a pre-clinical/non-clinical request for an in vivo (i.e., mouse model) and/or in vitro (i.e., test tube/cell line) study?"}</label>
                        <div>

                            {newOptions.map((item, index) => {
                                return <Form.Check value={item} checked={this.state.vitro_vivo_study===item} name={"vitro_vivo_study"} onChange={this.onInputChange} inline label={item} type="radio" />
                            })}
                        </div>

                        </Col> 
                        <Col sm={6} style={{ left: "0.5rem", marginBottom: "2rem" }} >

                        <label>{"Will you be assessing the utility of diagnostic methods and/or predictive or prognostic biomarkers?"}</label>
                        <div>

                            {newOptions.map((item, index) => {
                                return <Form.Check value={item} checked={this.state.assessing_utility_biomarkers===item} name={"assessing_utility_biomarkers"} onChange={this.onInputChange} inline label={item} type="radio" />
                            })}
                        </div>

                        </Col> 
                        <Col sm={6} style={{ left: "0.5rem", marginBottom: "2rem" }} >

                        <label>{"Are you requesting research funding?"}</label>
                        <div>

                            {newOptions.map((item, index) => {
                                return <Form.Check value={item}checked={this.state.requesting_research_funding===item} name={"requesting_research_funding"} onChange={this.onInputChange} inline label={item} type="radio" />
                            })}
                        </div>

                        </Col><Col sm={6}/>
                <Col sm={6}>
                    <InputField
                     label= "[EXT] Therapeutic Area"
                     type="dropdown"
                     name= "therapeuticArea"
                     value={this.state.therapeuticArea}
                     options= {THERAPEUTIC_AREA}
                     disabled={!_isCoordinator}
                     onSelect={  this.onSelectChange}
                     
                     />
                </Col>
               
                <Col sm={6}>
                    <InputField
                    label="Compound"
                           type= "searchableDropdown"
                           name= "compound"
                           placeholder="Compound"
                           options= {this.state.compoundList}
                           onSelect= {this.onSearchSelect}
                           value={this.state.compound}
                           disabled={!_isCoordinator}
                         
                        />
                </Col>
               
                <Col sm={6}>
                    <InputField
                           label="Indication"
                           type= "multi-select"
                           name= "indication"
                           onSelect= {this.onSelectChange}
                           disabled={this.state.therapeuticArea !== "Oncology" || !_isCoordinator}
                           placeholder="Indication"
                           options={INDICATIONS}
                          
                           //value={this.state.therapeuticArea==="Oncology"? (this.state.indication.length > 0 ? this.state.indication.map(obj => obj.value).join(";") : "") : null}
                        value={this.state.indication}
                          
                        />
                </Col>
                <Col sm={6}>
                    <InputField
                    label="Other Indication"
                           type= "text"
                           name= "otherIndication"
                           placeholder="Other Indication"
                           value={this.state.otherIndication}
                           onChange={this.onInputChange }
                           disabled={!_isCoordinator}
                        
                          
                        /></Col>
                
            
                <Col sm={6}>
                    <InputField
                            label="Submission"
                           type= "text"
                           name= "submissionId"
                           value={this.state.submissionId}
                           placeholder="Submission"
                           disabled={!_isCoordinator}
                          
                        /></Col>
                
                <Col   sm={6}>
                    <InputField  
                    label="Concept Title"
                           type= "textarea"
                           name= "conceptTitle"
                           placeholder="Concept Title"
                           value={this.state.conceptTitle}
                           rows="5"
                           onChange={this.onInputChange }
                           disabled={!_isCoordinator}
                        
                          
                        /></Col>
                {/* <Col sm={6}>
                    <InputField
                            label="[EXT] Requesting Research Funding"
                           type= "dropdown"
                           name= "requesting_research_funding"
                           placeholder="Select"
                           options= {["Yes", "No"]}
                           value={this.state.requesting_research_funding}
                           disabled={!_isCoordinator}
                           onSelect={  this.onSelectChange}
                         
                          
                        />
                </Col> */}
               
                <Col sm={12}>
                   <div style={{marginLeft:'-1rem'}}>
                   <InputField
                    label=" Concept Description"
                           type= "textarea"
                           name= "conceptDescription"
                           placeholder="Concept Description"
                           style={{width:'97%!important'}}
                           rows="3"
                           value={this.state.conceptDescription}
                           onChange={this.onInputChange }
                           disabled={!_isCoordinator}
                          
                        />
                   </div>
                </Col>
                </Row>
               
                </Modal.Body>
                
                {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Concept has been Updated!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
          
          {this.state.id&&this.state.historyRefresh&&<> <span className="alert-line"></span><Row className="mt-4">
                    <Col sm={12}>
                            <span className="flex-grow-1 font-weight-bold">History</span>
                            <SwitchHistory 
                            object_type={OBJ_TYPES.CONCEPT} 
                            object_id={this.state.id} 
                            /> 
                    </Col>
                </Row></>}
           </Container>)}
}
   
export default(ConceptDetailsEx)