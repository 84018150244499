import React from 'react';
import API from '../../api/apis';
import { dateTimeformat } from '../../utility/helperFunctions';
import DatatTableEx from './../common/DataTableEx';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Header from '../ToReviewDetails/Header';
import UserContext from '../../context/UserContext';

export default class Accounts extends React.Component{
    static contextType = UserContext;
    state = {
        showLoader: false,
        // filterSource:null,
    };
    columns = [
        {   
            dataField: 'id',
            text: 'id',
            sort: false,
            hidden:true,
            csvExport: false,
        },
        {
            headerStyle: () => {
                    return { width: "10%" };
                  },
            dataField: 'read_status',
            text: 'Read Status',
            sort: false,
            csvExport: false ,
            formatter: (cell, row, rowIndex) => {
            
                var  isDotVisible = row.read_status ? "" : "rounded bg-primary position-absolute";
                return <>
                    <div style={{width:'8px',height:'8px',margin:'5px 0px 0px 30px'}} className={isDotVisible}></div>
                </>
            }
        },
        {
            dataField: 'reviewer_name',
            text: 'Reviewer Name',
            sort: false,
          
        },
        {
            dataField: 'notify_review_type',
            text: 'Notification',
            sort: false,
        },
        {
            dataField: 'created_at',
            text: 'Created At',
            sort: false,
        },
        {
            dataField: 'submission_id',
            text: 'Details',
            sort: false,
            csvExport: false,
            formatter: (cell, row, rowIndex) => {
            
                var  detailsPath = '/toreview/details/'+row.submission_id;
                return <>
                    <Button variant="primary" onClick={e=>{
                           sessionStorage.setItem("flow-req-from","toreview");
                           this.props.history.push(`/toreview/details/${row.submission_id}`);
                    }} className='' style={{margin:'-5px 0px -5px -10px'}}><div class=''>Details</div></Button>
                </>
            }
        }
        
    ];
    componentDidMount() {
        this.getNotificationList();
    }
    hideOldNotifications = async () => {
        this.setState({showLoader:true});
        try{
            let sysID=this.context.userSystemId;
            const oldData = await API.post('/reviewsTab/oldNotification/data',{
                "system_id":sysID
            });
        }
        catch{

        }
        finally{
            this.setState({showLoader:false});
        }
        
    }
    getNotificationList = async () => {
        await this.hideOldNotifications();
        this.setState({showLoader:true});
        try{
            let sysID=this.context.userSystemId;
            
            const res = await API.post(`/submissionReview/notificationList`,{
                "system_id":sysID
            });
            console.log("in notification list");
            // let filterSource=await this.getFilterSource({data:notificationList});

            if(res&&res.data){
                let notifications= res.data.map((item,index)=>{
                    let { id,system_id,read_status,created_at,reviewer_name,notify_review_type,
                        submission_id, } = item;
                    created_at = dateTimeformat(created_at);
                    switch(notify_review_type){
                        case "add-init-review":notify_review_type='New Review ';break;
                        case "update-init-review":notify_review_type='Review updated';break;
                        case "add-instruction":notify_review_type='New Instruction ';break;
                        case "add-document":notify_review_type='New Document';break;
                        case "add-comment":notify_review_type='New comment';break;
                        case "assigned-by": notify_review_type='Review modified';
                        default: break;
                    }
                    reviewer_name=  (system_id === this.context.userSystemId) ? reviewer_name + " (You)" : reviewer_name;
                    return{
                        id,system_id,read_status,created_at,reviewer_name,notify_review_type,
                        submission_id,
                    }
                });              
                this.setState({ notifications: notifications || [], showLoader:false,});
            }
            else{
                this.setState({ notifications: [], showLoader:false,});
            }
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
    }
    // filterChange=(type,data,afs)=>{
    //     // console.log(this);
    // }
    // getFilterSource=async (res)=>{
    //     if(res&&res.data&&res.data.length>0){
    //         // let filterCollection= this.columns.filter(p=>!p.hidden).map((col,index)=>{
    //             // 
    //         let filterCollection= this.columns.filter(p=>(!p.hidden&&(p.dataField!="read_status")&&(p.dataField!="notify_review_type")&&(p.dataField!="created_at"))).map((col,index)=>{
    //             let columnValues=(res.data || []).map((item,key)=>{
    //                 if(col.dataField.split(".").length>1){
    //                     return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
    //                 }
    //                 return item[col.dataField];
    //             });
    //             return {group:col.text,key:col.dataField,items:[...new Set(columnValues)]};
    //         });
    //         return filterCollection;
    //     }
    //     return null;
          
    // }
    render(){
        return(
            <div style={{paddingTop:'1rem'}}>
                <Header path={"Accounts"}  title={"Notifications"} hrefPath="/accounts"></Header>
                <row>
                    <DatatTableEx
                        id="accounts"
                        data={this.state.notifications}
                        columns={this.columns}
                        pagination="true"
                        titleClass="title-lg"
                        showLoader={this.state.showLoader}
                        // filterChange={this.filterChange}
                        // filterSource={this.state.filterSource}
                        csvName="NotificationList" 
                        />
                </row>
            </div>

        );
    }
}