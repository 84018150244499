import React from 'react'
import { Col, Row,Button, Table, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import InputField from '../../../common/input-fields/InputField';
import './InvoiceList.css'
import UserContext from '../../../../context/UserContext';
import { isCoordinator } from '../../../ToReviewDetails/commonFunctions';
export default class InvoiceLists extends React.Component{
    static contextType=UserContext;
    constructor(props){
        super(props);
        this.state={
            invoiceItems: this.props.source||[],
            error:{
                amount:null,
                frequency:null
            },
            formData:{
                description: null, amount: null, frequency: null
            }
        };
    }
    componentDidMount=()=>{
        let is_coordinator=isCoordinator(this.context);
        this.setState({is_coordinator});
    }
    componentDidUpdate=(prevProps,prevState)=>{
        if((prevProps.source!=this.props.source && this.props.trial_expense_name) || 
        (prevProps.source!=this.props.source && this.props.submitNew )
            ){
            this.setState({
                invoiceItems:this.props.source||[],
            })
        }
        if((prevProps.submitNew !== this.props.submitNew) ){
            this.setState({
                formData:{
                    description: '', amount: '', frequency: ''
                }
            })
        }
    }
    getInvoiceItems=()=>{
        return this.state.invoiceItems||[];
    }
    handleEvents=(option,data,changeField)=>{
        let {invoiceItems,formData}=this.state;
        let emptyFormData={
            description: '',
            amount: '',
            frequency: '' 
         };
        switch(option){
            case 'ADD':     
            if(this.doValidate()){
                return false;
            }  
            let temp_id=invoiceItems.length+1;
            invoiceItems.push({
                description:formData.description,
                amount:formData.amount,
                frequency:formData.frequency,
                temp_id
            });
            this.setState({
                invoiceItems:[...invoiceItems],
                formData:emptyFormData
            });
            break;
            case "REMOVE":
             data.is_deleted=true;            
            case "CHANGE-APPROVE":
                if(data.id&&changeField){
                    if(!data.hasChange){
                        data.hasChange=[];
                    }
                    if( data.hasChange.findIndex(p=>p===changeField)<0){
                        data.hasChange.push(changeField);
                    }
                 }
                this.setState({
                    invoiceItems:[...invoiceItems],
                    formData:emptyFormData
                });
                break;
        }
    }
    onBeforeDelete=(callback)=>{
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: false,
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "Ok",
                    value: true,
                    visible: true,
                    className: "btn-danger",
                    closeModal: true
                }
            }
        })
            .then(async (replace) => {
                if (replace) {
                  if(callback){
                    callback();
                  }
                }
            });
    }
    handleValueChange=(e)=>{
        let {name,value}=e.target;
        let {formData}=this.state;
        formData[name]=value;
        this.setState({formData:{...formData}});
    }
    doValidate=()=>{
        let {invoiceItems,formData}=this.state;
        let { description,
        amount,
        frequency }=formData;
        let error={};
        let hasError=false;
        let customText="Required";
        if(!amount||amount.toString().trim().length<=0){
            error.amount=customText;
            hasError=true;
        }
        if(!frequency||frequency.toString().trim().length<=0){
            error.frequency=customText;
            hasError=true;
        }
        this.setState({error:{...error}});
        return hasError;
    }
    renderFields=()=>{
        let {formData,error,is_coordinator}=this.state;
       
        return <><Row>
            <Col md={5}>
              
                <InputField disabled={!is_coordinator}  name="description" value={formData["description"]} onChange={this.handleValueChange} label="Invoice Item Description" type="text"></InputField>
            </Col>
            <Col md={3}>
                <InputField disabled={!is_coordinator}  name="amount"  value={formData["amount"]} onChange={this.handleValueChange}  label="Projected Amount" type="number"></InputField>
            </Col>
            <Col md={2}>
                <InputField disabled={!is_coordinator}  name="frequency"  value={formData["frequency"]} onChange={this.handleValueChange}  label="Frequency" type="number" min={1} step={1}></InputField>
            </Col>
            <Col md={2}>
                <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                    <Button disabled={!is_coordinator} onClick={e=>this.handleEvents("ADD")} size="sm"
                        className="d-flex align-items-center mt-3"
                        variant="dark">
                        <span className="material-icons mr-1 m-0">add </span>
                        <span style={{ fontSize: ' 0.75rem' }}>ADD</span>
                    </Button>
                </div>

            </Col>
        </Row>
        <Row>
        <Col md={5}> 
          </Col>
          <Col md={3}>
          {error["amount"] && <p style={{marginTop:'-1rem'}} className="text-danger ">{error["amount"]}</p>}
          </Col>
          <Col md={2}>
          {error["frequency"] && <p style={{marginTop:'-1rem'}} className="text-danger ">{error["frequency"]}</p>} 
          </Col>
          <Col md={2}>
            </Col>
        </Row>
        </>
    }
    
    renderActionButton=(item,key)=>{
        let {trial_expense_name}=this.props;
       let {is_coordinator}=this.state;
        return <div className={`d-flex align-items-center  w-10 h-100 justify-content-${trial_expense_name?"end":"center"}`}>
                  {trial_expense_name&& <Form.Group className="m-0 d-flex align-items-center mr-3" style={{width:'130px'  }}>
                        <Form.Check
                            type="switch"                            
                            id={"is_approved_"+key}
                            name={"is_approved_"+key}
                            label={item.is_approved?"Approved":"Not Approved"}
                            defaultChecked={item.is_approved}
                            disabled={!is_coordinator}
                            value={item.is_approved}
                            onChange={(e) => {
                                item.is_approved = e.target.checked;
                                this.handleEvents("CHANGE-APPROVE",item,"is_approved");
                            }}
                        />
                    </Form.Group>}
                   {is_coordinator&&<span onClick={e=>
                        {
                            this.onBeforeDelete(()=>{
                                this.handleEvents("REMOVE",item,"is_deleted")
                            });
                        }
                       } className="material-icons" style={{cursor:'pointer',}}>
                        close
                    </span>}
        </div>
    }
    renderTable=()=>{
        let {invoiceItems}=this.state;
        return <div  style={{maxHeight:'400px',overflowY:'auto',fontSize:'.75rem'}}><Table responsive="sm">
            <thead style={{position:'sticky',top:-2,backgroundColor:'#FFF',zIndex:1,boxShadow:"rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                <tr>
                    <th className='bg-white'>Description</th>
                    <th className="text-right bg-white">Projected Amount</th>
                    <th className="text-center bg-white">Frequency</th>
                    <th  className="text-center bg-white">Action</th>
                </tr>
            </thead>
            <tbody>
            {
                invoiceItems.filter(p=>!p.is_deleted).map((item,index)=>{
                    return <tr>
                        <td>{item.description??""}</td>
                        <td className="text-right">{item.amount??""}</td>
                        <td  className="text-center">{item.frequency??""}</td>
                        <td>{this.renderActionButton(item,index)}</td>
                    </tr>
                })
            }
            </tbody>
            </Table>
            </div> ;
    }
    render(){
        let {formData}=this.state;
        return <div className='d-flex flex-column mt-2'>
            <h5 className="pl-1">Invoices</h5>
            {this.renderFields()}             
            {this.renderTable()}
        </div>
    }
}