import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import API from '../../../api/apis';
import CompoundModal from './CompoundModal';
import swal from 'sweetalert'
import UserContext from '../../../context/UserContext';
import BootstrapModal from '../modal/BootstrapModal';

import DatatTableEx from '../DataTableEx';
import ToolTipEx from '../ToolTipEx';
import { COMPOUND } from '../../../TooltipInfo';
import { isCoordinator, showMessage } from './../../ToReviewDetails/commonFunctions';
import LoaderEx from '../loader/LoaderEx';
import DTAButton from "../Button/DTAButton";
import CompoundModalEx from './CompoundModalEx';


class CompoundEx extends Component {
    static contextType = UserContext;
    state = {
        reloadTable: false,
        compoundList: [],
        successModal: false,
        errorModal: false,
        isLoading: false,
    }
    columns = [
        {
            dataField: 'id',
            text: 'id',
            sort: false,
            hidden: true,
            csvExport: false,
            excludeFilter: true
        },
        {
            dataField: 'name',
            text: 'Compound Name',
            sort: true,
        },
        {
            dataField: 'therapeutic_area',
            text: 'Therapeutic Area',
            sort: true,

        },
        // {
        //     dataField: 'request_type',
        //     text: 'Primary Request Type',
        //     sort: true,

        // },
        {
            dataField: 'ly_number',
            text: 'LY Number',
            sort: true,
        },
        {
            dataField: 'research_project_code',
            text: 'Research Project Code',
            sort: true,
        },
    ];
    handleReforecastDetails = (row) => {
        this.props.history.push(`/detailCompound`, { compoundId: row.id });
    }
    rowEvents = {
        onClick: (e, row, rowIndex) => {
            this.handleModalShowHide(true, row.id)
        }
    };
    
    async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        await this.getCompoundList();
        this.setState({
            is_Coordinator: _isCoordinator,
            reloadTable: true,

        });
    }

    getCompoundList = async () => {
        this.setState({
            isLoading: true,
        })
        try {
            const res = await API.get(`seed/compound/fulldata`)
            if (res && res.data && res.data.success) {
                const resultArray = (res.data?.data || []).map(elm => ({ 
                    name: elm.name, 
                    id: elm.id, 
                    therapeutic_area: elm.therapeutic_area, 
                    // request_type: elm.request_type,
                    ly_number: elm.ly_number,
                    research_project_code: elm.research_project_code
                }));
                this.setState(
                    {
                        compoundList: resultArray,
                        isLoading: false
                    }
                );
            }
            else {
                this.setState(
                    {
                        compoundList: [],
                        isLoading: false

                    }
                );
                // showMessage({ text: "Failed", icon: "error", button: "Ok" });
            }
        } catch {

        }
    }
    handleModalShowHide = async (flag, id, reload) => {
        if (id) {
            this.setState({ showHideModal: flag, compoundId: id });
        }else{
            this.setState({ showHideModal: flag, compoundId:null},async ()=>{
                if(reload){
                    await this.getCompoundList();
                }
            });

        }
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getCompoundList();
    }
    handleExternalEvents = (type, success) => {
        if (success === false) {
            this.setState({ errorModal: true });
        } else {
            switch (type) {
                case 'ADD':
                    if (success) {
                        this.setState({
                            success_body: "Created successfully!",
                            successModal: true,
                            showHideModal: false,
                        });
                    }
                    break;
                case 'UPDATE':
                    if (success) {
                        this.setState({
                            success_body: "Updated successfully!",
                            successModal: true,
                            showHideModal: false,
                        });
                    }
                    break;
            }
        }

    }
    renderPageIcons = () => {
        return this.state.is_Coordinator &&
            <ToolTipEx tooltip={COMPOUND.ADD}>
                <DTAButton onClick={() => this.handleModalShowHide(true)} icon="ADD" text={COMPOUND.ADD}></DTAButton>
            </ToolTipEx>
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal, deleteModal, isLoading,compoundId, } = this.state;
        return <>
            <div className="inpage-content">
                {isLoading && <LoaderEx />}

                {!isLoading && <Row>


                    {this.state.reloadTable &&
                        <DatatTableEx
                            id="compoundList"
                            showLoader={showLoader}
                            title={
                                <Alert.Heading>
                                    <span><i className="bi bi-eyedropper" style={{ fontSize: "1.5rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                                    Compounds
                                </Alert.Heading>
                            }
                            csvName="Compounds"
                            data={this.state.compoundList}
                            columns={this.columns}
                            rowEvents={this.rowEvents}
                            autoFilter={true}
                            filterChange={() => { }}
                            pageIcons={this.renderPageIcons()}
                            pagination="true" />}
                </Row>}
            </div>
            {showHideModal &&
                <CompoundModalEx
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    compoundId={compoundId}
                />}
            {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
        </>

    }
}

export default withRouter(CompoundEx);