import React from 'react';
import API from '../../api/apis';
import DatatTableEx from './../common/DataTableEx';
import UserContext from '../../context/UserContext';
import { Link } from 'react-router-dom';
import { Col, Row, Button, Nav } from 'react-bootstrap';
import EnrollmentModal from "./EnrollmentModal";
import ManualEntryModal from "./ManualEntryModal";
import BootstrapModal from '../common/modal/BootstrapModal';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { isCoordinator} from '../ToReviewDetails/commonFunctions';
import Dropdown from 'react-bootstrap/Dropdown';
import swal from 'sweetalert';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div     
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
export default class EnrollmentData extends React.Component{
    static contextType = UserContext;
    state = {
        showLoader: false,
        showHideModal: false,
        showManualEntryModal:false,
        filterSource:null,
        tableEditView:false,
        activeEnrollment:null,
        success_body: '',
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
    };
    columns = [
        {   
            dataField: 'id',
            text: 'id',
            sort: false,
            hidden:true,
            csvExport: false,
            editable:false
        },
        {
            dataField: 'year',
            text: 'Year',
            sort: true,
            editable:false,
            formatter: (cell, row) => row["year"],
            sortValue: (cell, row) => row["yearQuarter"]
        },
        {
            dataField: 'quarter',
            text: 'Quarter',
            sort: false,
            editable:false
          
        },
        {
            dataField: 'baseline_enrollment',
            text: 'Baseline',
            sort: true,
            isMandatory:false,
            customType:'number',
            classes: (cell, row, rowIndex, colIndex) => {
                if(row["is_edit_view"]){
                 return "bg-grey-edit";
                }
                return ""
               },
               editorStyle:{
                margin:'0',
                width:'parent'
               },
               editable: true
          
        },
        {
            dataField: 'estimated_enrollment',
            text: 'Estimated',
            sort: true,
            isMandatory:false,
            customType:'number',
            classes: (cell, row, rowIndex, colIndex) => {
               if(row["is_edit_view"]){
                return "bg-grey-edit";
               }
               return ""
              },
              editable: true
        },
        {
            dataField: 'actual_enrollment',
            text: 'Actual',
            sort: true,
            editable:false
        },
        // {
        //     dataField: 'baseline_addition',
        //     text: 'Baseline Addition',
        //     sort: true,
        //    editable:false,
        //    csvExport: true,
        // } ,
        // {
        //     dataField: 'estimated_addition',
        //     text: 'Estimated Addition',
        //     sort: true,
        //    editable:false,
        //    csvExport: true,
        // } ,
        // {
        //     dataField:'actual_addition',
        //     text: 'Actual Addition',
        //     sort: true,
        //    editable:false,
        //    csvExport: true,
        // } , 
        {
            dataField: 'patients_currently_on_treatment',
            text: 'Patients Currently on Treatment',
            sort: false,
            isMandatory:false,
            customType:'number',
            classes: (cell, row, rowIndex, colIndex) => {
                if(row["is_edit_view"]){
                 return "bg-grey-edit";
                }
                return ""
               },
               editable: true
        },
        {
            dataField: 'comments',
            text: 'EXT Comments',
            sort: false,
            classes: (cell, row, rowIndex, colIndex) => {
                if(row["is_edit_view"]){
                 return "bg-grey-edit";
                }
                return ""
               },
               editable: true
        },
        
        // {
        //     dataField: 'health_status',
        //     text: 'Health Status',
        //     sort: false,
        //     csvExport: true,
        //     editable:false
        // },
        // {
        //     dataField: 'is_follow_up',
        //     text: 'Follow',
        //     sort: false,
        //     csvExport: false,
        //     editable:false,
        //     events: {
        //         onClick: (e, column, columnIndex, row, rowIndex) => {
        //             e.preventDefault();
        //             e.stopPropagation();
        //         }

        //     },
        //     formatter: (cell, row, rowIndex) => {
            
        //         var  isStartVisible = row.is_follow_up ? "bi bi-star-fill" : "bi bi-star";
        //         return <>
            
        //             <i className={isStartVisible} onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.handleStar(row);}}
        //                 style={{ fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem" }}></i>
                         
    
        //         </>
        //     }
        // },
        {
            dataField: 'delete',
            text: '',
            sort: false,
            csvExport: false,
            editable:false,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.preventDefault();
                    e.stopPropagation();
                }

            },
            formatter: (cell, row, rowIndex) => {
                let data=row;
                let is_coordinator = isCoordinator(this.context);
                return <div className="d-flex" style={{userSelect:'none'}}>
                    {is_coordinator&&<div>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                            <span className="material-icons">
                                more_vert
                            </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="bg-white text-white" >
                                <Dropdown.Item eventKey="1" onClick={e=>{
                                   e.preventDefault(); e.stopPropagation();
                                    this.handleDelete(data.id);}} className="text-danger font-weight-bold">DELETE</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                            
                    </div>}
                </div>
            }
        }
    ]; 
    componentDidMount() {
        this.getEnrollmentList();
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
        // if(localStorage.getItem("saveNew")){
        //     console.log("test1")
        //     this.setState({
        //         successModal: true,
        //         success_body: "Created successfully!",
        //     })
        //     this.handleManualEntryModal(true, null, {})
        //     localStorage.removeItem('saveNew');
            
        // }
    }
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:  selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => {  })
        }        if(localStorage.getItem("saveNew")){
            console.log("test1")
            this.setState({
                successModal: true,
                success_body: "Created successfully!",
            })
            this.handleManualEntryModal(true, null, {})
            localStorage.removeItem('saveNew');
            
        }
    }
    // handleStar = async(row)=>{
    //     this.setState({showLoader:true});
    //     try{

    //         const followRes = await API.post('/reforecastOptimization/followUnfollow',{
    //             ref_key:row.id,
    //             ref_type:'enrollment',
    //             submission_id:this.props.submissionId,
    //             system_id:this.context.userSystemId,
    //             created_by:this.context.userSystemId,
    //         });
    //         if(followRes.status == 200) {
    //             const res = await API.post(`/enrollmentOptimization/list`,{
    //                 "system_id":this.context.userSystemId,
    //                 "submission_id":this.props.submissionId
    //             });
    //             let enrollmentList= res.data;
    //             this.setState({enrollmentList});
    //         }
    //     }
    //     catch(exp){

    //     }
    //     finally{
    //         this.setState({showLoader:false}); 
    //     }
    // }
    getEnrollmentList = async () => {
        this.setState({showLoader:true});
        try{
            let sysID=this.context.userSystemId;
            
            const res = await API.post(`/enrollmentOptimization/list`,{
                "system_id":sysID,
                "submission_id":this.props.submissionId
            });
           let enrollmentList=res.data;
          
            if(enrollmentList&&enrollmentList.length>0){
                enrollmentList.forEach((item,index)=>{
                    item.yearQuarter=`${item.year}-${item.quarter}`;
                })
            }
            let filterSource=await this.getFilterSource({data:enrollmentList});    
            if(enrollmentList&&enrollmentList.length>0&&this.state.tableEditView){           
                enrollmentList.forEach((item)=>{
                    item.is_edit_view=this.state.tableEditView;
                });
            }   
            enrollmentList.sort(function (a, b) { return a.yearQuarter - b.yearQuarter; });
            // let sum = { ba: 0, ea: 0, aa: 0 };
            // enrollmentList.forEach(p => {
            //     sum.ba = sum.ba + p.baseline_enrollment ?? 0;
            //     sum.ea = sum.ea + p.estimated_enrollment ?? 0;
            //     sum.aa = sum.aa + p.actual_enrollment ?? 0;
            //     p.baseline_addition = sum.ba;
            //     p.estimated_addition = sum.ea;
            //     p.actual_addition = sum.aa;
            // })          
                this.setState({ enrollmentList: enrollmentList || [], filterSource});
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
    }
    getSubmissionDetails=async()=>{
        try{
            let submissionDetails = await API.get(`submission/getbyid/${this.props.submissionId}`);
            if(submissionDetails&&submissionDetails.data&&submissionDetails.data.success){
                let {currency_id}=submissionDetails.data.data;
                this.setState({currency_id})
            }
          
        }
        catch(exp){

        }
      
    }
    filterChange=(type,data,afs)=>{
            // console.log(this);
        }
    handleModalShowHide=(flag)=>{
        this.setState({showHideModal:flag});
        if(!flag){
            this.getEnrollmentList();
        }
    }
    handleManualEntryModal=(flag)=>{
        this.setState({showManualEntryModal:flag,activeEnrollment:null});
    }
    handleCallback = (childData,saveNew) =>{
        
        if(childData==='manualEntryModal'){
            this.handleManualEntryModal(true);
            this.setState({
                showHideModal:false,
                successModal:false,
                errorModal:false,
            });
        }
        if(childData==='newEnrollmentModal'){
            this.handleManualEntryModal(false);
            this.setState({
                showHideModal:true,
                successModal:false,
                errorModal:false,
            });
        }
        if(childData==='successModal'){
            let {activeEnrollment}=this.state;
            let message=`${(activeEnrollment?"Updated":"Created")} successfully!`;
            this.handleManualEntryModal(false);
            activeEnrollment=null;
            this.setState({
                success_body: message,
                showHideModal:false,
                successModal:true,
                errorModal:false,
                activeEnrollment
            });
            if(saveNew){
                localStorage.setItem("saveNew", true);
            

            }this.getEnrollmentList();
        }
        if(childData==='errorModal'){
            this.handleManualEntryModal(false);
            this.setState({
                showHideModal:false,
                successModal:false,
                errorModal:true,
            });
        }
        if(childData==='deleteSuccessModal'){
            this.handleManualEntryModal(false);
            this.setState({
                success_body: 'Deleted successfully!',
                showHideModal:false,
                successModal:true,
                errorModal:false,
            });
            this.getEnrollmentList();
        }
    }
    getFilterSource=async (res)=>{
        if(res&&res.data&&res.data.length>0){
            // let filterCollection= this.columns.filter(p=>!p.hidden&&(p.dataField!="is_follow_up")&&(p.dataField!="delete")).map((col,index)=>{
            // let filterCollection= this.columns.filter(p=>!p.hidden&&(p.dataField!="baseline_addition")&&(p.dataField!="estimated_addition")&&(p.dataField!="actual_addition")&&(p.dataField!="delete")).map((col,index)=>{
            let filterCollection= this.columns.filter(p=>!p.hidden&&(p.dataField!="delete")).map((col,index)=>{
                // 
                let columnValues=(res.data || []).map((item,key)=>{
                    if(col.dataField.split(".").length>1){
                        return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
                    }
                    return item[col.dataField];
                });
                return {group:col.text,key:col.dataField,items:[...new Set(columnValues)]};
            });
            return filterCollection;
        }
        return null;
    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    handleEditState=async(e)=>{
        let {tableEditView}=this.state;
        tableEditView=!tableEditView;
        this.setState({tableEditView},this.getEnrollmentList)       
    }
    renderPageIcons=()=>{
        let is_coordinator=isCoordinator(this.context);       
        let {enrollmentList,tableEditView}=this.state;
        return is_coordinator&&<div className="d-flex align-items-center justify-content-center">
           {enrollmentList&&enrollmentList.length>0&&<Button onClick={this.handleEditState} size="sm" 
                   
                    className="d-flex align-items-center ml-2" 
                    variant="outline-dark">
                        <span className="material-icons mr-1 m-0">{tableEditView?"save":"edit"} </span>
                        <span style={{ fontSize: ' 0.75rem' }}>{tableEditView?"SAVE":"EDIT"} TABLE</span>
            </Button>}
            <Button size="sm" 
                    onClick={() => this.handleModalShowHide(true)} 
                    className="d-flex align-items-center ml-2" 
                    variant="outline-dark">
                        <span className="material-icons mr-1 m-0">add </span>
                        <span style={{ fontSize: ' 0.75rem' }}>ADD ENROLLMENT</span>
            </Button>
        </div>
    }
    handleDelete = async (id) => {
        this.setState({showLoader:true});
        
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
                try{
                    let sysID=this.context.userSystemId;
                    
                    const res = await API.post(`/enrollmentOptimization/delete/${id}`,{
                        "updated_by":sysID
                    });
                    if(res&&res.data){
                        this.handleCallback('deleteSuccessModal');
                    }
                    else{
                        this.handleCallback('errorModal');
                    }
                }
                catch(exp){
                    console.log('error caught while deleting enroll.: ',exp);
                    this.handleCallback('errorModal');
                }
                finally{
                    this.setState({showLoader:false});
                }
            }
            else{
                this.setState({showLoader:false});
            }
          });
        
    }
    handleAfterSave=(oldValue, newValue, row, column)=>{

    }
    handleCellSave=(oldValue, newValue, row, column,done)=>{
       this.updateEnrollment(oldValue, newValue, row, column,done)
    }
    updateEnrollment=async(oldValue, newValue, row, column,done)=>{
        this.setState({showLoader:true});
        try{
            let {id,patients_currently_on_treatment,estimated_enrollment,baseline_enrollment,comments}=row;
            let {submissionId}= this.props;
            let updated_by=this.context.userSystemId;
            let payload=  {
                             id,
                            //  baseline_enrollment,
                            //  patients_currently_on_treatment,
                            //  estimated_enrollment,
                             submissionId,
                             //comments,
                             updated_by
                         };
                       
                         if(column.customType=="number"){
                            let val=newValue.toString().trim().length<=0?null:+newValue.toString().trim();
                            payload[column.dataField]=val;
                         }
                         else{
                            payload[column.dataField]=newValue.toString().trim();
                         }
           
            const update_ref = await API.post(`/enrollmentOptimization/update/${id}`,payload);
            if(done){
                done();
            }
        }
        catch(exp){done(false);}
        finally{
            this.setState({showLoader:false});
        }
       

    }
    render(){       
        const { showHideModal, showManualEntryModal, successModal, errorModal,tableEditView,  success_body}=this.state;
        return(
            <>
            <div style={{paddingTop:'1rem'}}>
            
                <row>
                    <DatatTableEx
                        id="enrollment"
                        data={this.state.enrollmentList}
                        columns={this.columns}
                        pagination="true"
                        titleClass="title-lg"
                        showLoader={this.state.showLoader}
                        pageIcons={ this.renderPageIcons()}
                        handleCellSave={this.handleCellSave}
                        handleAfterSave={this.handleAfterSave}                        
                        rowEvents={
                           {
                            onClick: (e, row, rowIndex) => {
                                if(!this.state.tableEditView){
                                    this.setState({showManualEntryModal:true,activeEnrollment:row["id"]})
                                }                             
                              }
                           }
                        }                        
                        filterChange={this.filterChange}
                        filterSource={this.state.filterSource}
                        isCellEdit={ tableEditView }
                        csvName="EnrollmentList"
                        />
                </row>
                {showHideModal &&
                <EnrollmentModal
                showHideModal={showHideModal}
                currency_id={this.state.currency_id||""}
                submissionId={this.props.submissionId}
                handleModalShowHide={this.handleModalShowHide}
                parentCallback = {this.handleCallback} />}
                
                {showManualEntryModal?
                <ManualEntryModal 
                showManualEntryModal={showManualEntryModal}
                handleManualEntryModal={this.handleManualEntryModal}
                submissionId={this.props.submissionId}
                access = {this.state.access}
                parentCallback = {this.handleCallback} 
                id={this.state.activeEnrollment||0}
                currency_id={this.state.currency_id||""} />:<></>}

                {successModal?
                    <BootstrapModal
                        show={successModal}
                        header="Success"
                        body={ success_body }
                        changeShowState={this.changeShowState}
                    />:null}
                
                {errorModal ?
                    <BootstrapModal
                        show={errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />:null}
            </div>
            </>
        );
    }
}