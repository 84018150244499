import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { Col, Row, Dropdown } from 'react-bootstrap';

import DatatableComponent from '../common/datatable/DatatableComponent';


import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import UserContext from '../../context/UserContext';
import BootstrapModal from '../common/modal/BootstrapModal';
import API from '../../api/apis';
import InvestigatorModal from './InvestigatorModal';
import swal from 'sweetalert';

class SubmissionList extends Component {
    static contextType = UserContext;

    state = {
        submissionList:[],
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access : {
            view : true,
            create : false,
            edit : false,
            delete : false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
    }
    columns = [{
        dataField: 'id',
        text: 'Record Number',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                     onClick={() => this.handleSubmissionDetails(row)}>{`Submission-${row.id}`}</p>
            </>
        }},
        {
            dataField: 'unique_submission_identifier',
            text: 'Unique Submission Identifier',
            sort: true,
           },
           {
            dataField: 'status',
            text: 'Submission Status',
            sort: true,
           }, 
           {
            dataField: 'stage',
            text: 'Stage',
            sort: true,
           },
               
    ];
  
    componentDidMount() {
        console.log(this.props);
        this.getsubmissionList(this.props.location.state.investigatorId);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access : {
                view : true,
                create : selectedRole.includes("administrator"),
                edit : selectedRole.includes("coordinator") ||  selectedRole.includes("administrator")  ,
                delete : selectedRole.includes("administrator"),
            },
            
        });
    }
    getsubmissionList = async (id) => {
        const res = await API.get(`submission/investigator/getbyid/${id}`);
        this.setState({ submissionList: res.data?.data?.submissions });

    }
    componentDidUpdate(previousProps, previousState) {      
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;

        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {   
            this.setState({
                selectedRole: selectedRole,
                access : {
                    view : true,
                    create : selectedRole.includes("administrator"),
                    edit : selectedRole.includes("coordinator") ||  selectedRole.includes("administrator"),
                    delete : selectedRole.includes("administrator"),
                },
            },()=>{console.log("userRole",this.state.selectedRole)})            
        }
    }
 
      getInvestigatorById = async (id) => {
        try {
            const res = await API.get(`/submission/investigator/getbyid/${id}`);
            this.setState({
                viewEditData: res.data?.data,
                showLoader: false
            })
         
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
   
    handleSubmissionDetails = (row) => {
        this.props.history.push(`/submissions/${row.id}`);
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal,deleteModal } = this.state;
        return <>
            <div className="inpage-content">
                <Alert className="alert-head" key="submission" variant="light">
                    <Row>
                        <Col sm={6}>
                        <Alert.Heading>
                            <span><i class="bi bi-journal-medical" style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                             Submission({this.state.submissionList.length})</Alert.Heading>
                        </Col>
                      
                    </Row>
                </Alert>
                <Row>
                   

                    <DatatableComponent id="submissionInvestigator" data={this.state.submissionList} columns={this.columns} rowEvents={this.rowEvents} pagination="true"  />
                </Row>
            </div>
           
      </>
    }
}

export default withRouter(SubmissionList);