import React from "react";
import Charts from "../../common/Charts/Charts";
import DatatTableEx from "../../common/DataTableEx";
import UserContext from "../../../context/UserContext";
export default class Preview extends React.Component{
    static contextType=UserContext;
    constructor(props){
        super(props);
    }
    columns = [
       
        {
            dataField: 'year',
            text: 'Year',
           
        },
        {
            dataField: 'quarter',
            text: 'Quarter',
           
          
        },
        {
            dataField: 'baseline_enrollment',
            text: 'Baseline',
            
          
        },
        {
            dataField: 'estimated_enrollment',
            text: 'Estimated',
           
        },
        {
            dataField: 'actual_enrollment',
            text: 'Actual',
           
        },
        {
            dataField: 'currency_name',
            text: 'Currency',
           
        },
        {
            dataField: 'patients_currently_on_treatment',
            text: 'Patients Currently on Treatment',
            
        },
        {
            dataField: 'total_patients_entered_treatment',
            text: 'Total Patients Entered Treatment',
            
        },
        {
            dataField: 'comments',
            text: 'EXT Comments',
            
        },
        {
            dataField: 'user_name',
            text: 'Last Modified By',
            
        }
    ]
    componentDidMount(){

    }
    getChartData=()=>{
        let data=this.props.formData.csv||[];
        let chartSource=[];
       
        if(data.length>0){
            chartSource.push(["Quarter", "Baseline", "Actual","Estimated"]);
            data.forEach(p=>{
                let chartLine=[`${p.year}-${p.quarter}`,p.baseline_enrollment??0,p.actual_enrollment??0,p.estimated_enrollment??0];
                chartSource.push(chartLine);
            })
        }
        return chartSource;
    }
    renderChartArea=()=>{
        let {navigation,formData,isFinalStage}=this.props;
        if(isFinalStage){
            let chartData=this.getChartData();
            return <div className="position-relative" >
            <h4 class='text-center w-100 position-absolute' style={{ top: '0.50rem', zIndex: '99999' }}>
                Enrollment Over Active Trial Lifetime</h4>
            <Charts slider source={chartData||[]} /><br />
            </div>;
           
        }
        return null;
    }
    render(){
        return  <>
            {this.renderChartArea()}
        <DatatTableEx
                        id="enrollment"
                        data={this.props.formData.csv||[]}
                        columns={this.columns}
                        pagination="true"
                        titleClass="title-lg"                                       
                                           
                        csvName="EnrollmentList"
                        />
                        </>
    }
}
