import React from 'react'
import {OverlayTrigger,Tooltip}  from 'react-bootstrap'
export default (props)=>{
    return props.tooltip?<OverlayTrigger
        key={props.key||new Date().toString()}
        placement={props.placement||'bottom'}
        overlay={
            <Tooltip >
              {props.tooltip||''}
            </Tooltip>
        }
    >
        {props.children}
    </OverlayTrigger>:props.children;
}