import React, { Component } from 'react'

import { Button, Col, Row, Card } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../sticky-nav/StickyNav';
import InputField from '../input-fields/InputField';

import API from '../../../api/apis';
import BootstrapModal from '../modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../loader/Loader';
import UserContext from '../../../context/UserContext';
import { yyyymmddFormat } from '../dateFormat';
import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';
import State from './State';
import CompoundCountry from './CompoundCountry';
import CountryModal from './countryModal';


class CountryDetail extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access : {
            view : true,
            create : false,
            edit : false,
            delete : false,
        },
        successModal: false,
        errorModal: false,
        hasChanged: false,
        countryName:this.props.location.state.countryName,
        countryId:this.props.location.state.countryId,
        viewEditData:{},
        showHideModal: false,
        viewEditFlag: null,
        currencyList: [],
        currencyName:""
      
    }
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        }

    ];


    get inputFieldsArray() {
        return [
        {
            label: "Country Name *",
            type: "text",
            name: "name",
            disabled:true
           
        },
        {
            label: "Country Code *",
            type: "text",
            name: "countrycode",
            disabled:true
        },
        {
            label: "Submit a Concept Active",
            type: "checkbox",
            name: "active",
            disabled:true
        },
        {
            label: "Currency",
            type: "searchableDropdown",
            placeholder: "Select Currency",
            name: "currency",
            options: this.state.currencyList,
            onSelect: this.onSearchSelect1,
            disabled:true
        },
        {
            label: "Created By",
            type: "text",
            name: "created_by",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updated_by",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }
       
        ];
    }
    async componentDidMount() {
        
        await this.getCurrencyList();
        await this.getReforcastByExpenseId(this.props.location.state.countryId,this.props.location.state.countryName)
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
            },
            reloadTable: true,

        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                },
                reloadTable: true,

            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getReforcastByExpenseId = async (id,name) => {
        try {

            const expenseDetails = await API.get(`/seed/country/getbyid/${id}`);
            const viewEditData={...expenseDetails.data};
            expenseDetails.data["currency"] = this.state.currencyList.filter(item => item.id === expenseDetails.data.currency_id)[0];
            
            expenseDetails.data["created_by"] =expenseDetails.data.created_by?.length ? await getUserInfo(expenseDetails.data.created_by)+"  "+ dateTimeformat(expenseDetails.data.createdAt) : "";

            expenseDetails.data["updated_by"] =expenseDetails.data.updated_by?.length > 0 ? await getUserInfo(expenseDetails.data.updated_by) + "  " + dateTimeformat(expenseDetails.data.updatedAt):expenseDetails.data.created_by ;
            
           

            this.setState({
                ...expenseDetails.data,
               
                viewEditData:viewEditData,
                showLoader: false
            })
         
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    getCurrencyList = async () => {
        try {
            const currencyList = await API.get(`seed/currency/data`);
            if (currencyList.data.success) {
                this.setState({
                    currencyList: currencyList.data.data,
                    showLoader: false
                })
            }
            else {
                this.setState({
                   currencyList:[]
                })
            }
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    onSearchSelect1 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            currency_id: e.id
        });
    }
    onInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
        });
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.checked },

        })
    }
    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
        });
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            patchObject: { ...this.state.patchObject, [e]: this.getHtmlFromEditorValue(val) },
            hasChanged: true
        });
    };
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }
    handleReforecastUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        try {
            const res = await API.post("seed/country/edit/update", state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getCountryList();

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    onNavOut = () => {
        this.props.history.push(`/country`)
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
}


    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getReforcastByExpenseId(this.props.location.state.countryId,this.props.location.state.countryName)
    }

    render() {

        const { showHideModal, viewEditFlag, viewEditData, success_body, errorModal,successModal } = this.state;
 if (!this.state.countryName && !this.state.countryId)
            return <Loader />
        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                        {" "}{this.state.countryName}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Country Detail </h4>
                   {this.state.access.edit && <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                                {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} >
                                        Save
                                    </Button>
                                </div> */}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
               
                 <Col md={6}>
                    <Card className="data-cards">
                       
                            {/* <Tabs defaultActiveKey="history">
                                <Tab eventKey="history" title="History">
                                    <History historyData={this.historyData}  historyTitle="Reforecast History" />
                                </Tab>
                            </Tabs> */}
                            <State countryName={this.state.countryName} countryId={this.state.countryId} />
                           
                       
                    </Card>
                    <Card className="data-cards">
                       
                            {/* <Tabs defaultActiveKey="history">
                                <Tab eventKey="history" title="History">
                                    <History historyData={this.historyData}  historyTitle="Reforecast History" />
                                </Tab>
                            </Tabs> */}
                            
                            {!(this.state.selectedRole.includes("read_only")||this.state.selectedRole.includes("operational_coordinator")) && this.state.countryId && <CompoundCountry countryId={this.state.countryId} countryName={this.state.countryName}/>}
                       
                    </Card>
                </Col>
            </Row>
            { showHideModal &&
               <CountryModal
            showHideModal={showHideModal}
            handleModalShowHide={this.handleModalShowHide}
            handleReforecastSave={this.handleReforecastSave}
            handleReforecastUpdate={this.handleReforecastUpdate}
            viewEditFlag={viewEditFlag}
            viewEditData={viewEditData}
           />}
            {this.state.successModal &&
                <BootstrapModal
                    show={this.state.successModal}
                    header="Success"
                    body="Country has been Updated!"
                    changeShowState={this.changeShowState}
                />
            }
            {/* {this.state.errorModal &&
                <BootstrapModal
                    show={this.state.errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            } */}
        </>
    }
}

export default withRouter(CountryDetail);
