export const titleType={
    "NEW":"Create a New Milestone Expense",
    "EDIT":"Details"
}
export const eventType={
    "MODAL-CLOSE":"MODAL-CLOSE",
    "NEW-MILESTONE":"NEW-MILESTONE",
    "UPDATE-EXPENSE":"UPDATE-EXPENSE",
    "SAVE":"SAVE",
    "manualEntryModal":"manualEntryModal"
}