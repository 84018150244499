import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import API from '../../../api/apis';
import { getUserInfo, dateTimeformat, dateStringformat } from '../../../utility/helperFunctions';
import InputField from '../../common/input-fields/InputField';
import { yyyymmddFormat } from '../../common/dateFormat';
import Loader from '../../common/loader/Loader';

class DrugDeliverySiteModal extends React.Component {
    state = {
        compoundList: [],
        hasChanged: false,
        countryList: [],
        stateList: [],
        submission: `Submission- ${this.props.submissionId}`,
        submission_id: this.props.submissionId,
        name:"",
        contact_name:"",
        phone_number:"",
        street_address:"",
        email_address:"",
        city:"",
        country_id:"",
        stateid:"",
        zip_postal_code:"",
        address_type:"DRGSUP",
        type_of_material_provided:this.props.type_of_material_provided,
        isLoader:true,
        irb_erb_start_date:null,
        irb_erb_expiration_date:null,
        impact_site:null
    }
 async componentDidMount() {
    await this.getCountryList();
        const { viewEditData, viewEditFlag } = this.props;
        viewEditData["irb_erb_start_date"] = viewEditData.irb_erb_start_date != "" && viewEditData.irb_erb_start_date != null ?
        dateStringformat(viewEditData.irb_erb_start_date) : null;
        viewEditData["irb_erb_expiration_date"] = viewEditData.irb_erb_expiration_date != "" && viewEditData.irb_erb_expiration_date != null ?
        dateStringformat(viewEditData.irb_erb_expiration_date) : null;
        viewEditData["createdby"] =viewEditData.created_by?.length ?  await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt): "";
        viewEditData["updatedby"] =viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
        viewEditData["countryId"] = this.state.countryList.filter(item => item.value === viewEditData.country_id)[0];
        viewEditData["countryid"]= viewEditData["countryId"]?.name;
        await this.getStateList(viewEditData["countryid"]);
        viewEditData["stateid"] =this.state.stateList.filter(item => item.value === viewEditData.state_province_id)[0];
         if (viewEditFlag === "edit") {
            this.setState({
                //set Your form state details here
                ...this.state,
                ...viewEditData,
                submission: viewEditData.submission?.id ? `Submission- ${viewEditData.submission?.id}` : this.state.submission,
                isLoader:false

            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
    }

    get inputFieldsArray() {
        return [{
            label: "Submission *",
            type: "text",
            name: "submission",
            disabled: true
        },
        {
            label: "[EXT] Contact Name *",
            type: "text",
            name: "contact_name",
            helptext:"Please provide the name of the main contact at this site"
        },
        {
            label: "[EXT] Site Name *",
            type: "text",
            name: "name"
        },
        {
            label: "[EXT] Phone Number *",
            type: "text",
            name: "phone_number",
            helptext:"Please provide a phone number to contact this site"
        },
        {
            label: "[EXT] Street Address *",
            type: "text",
            name: "street_address"
        },
        {
            label: "[EXT] Email Address",
            type: "text",
            name: "email_address",
            helptext:"Please provide an email to contact this site"
        },
        {
            label: "[EXT] Street Address 2",
            type: "text",
            name: "ext_street_address_2"
        },
        {
            label: "[EXT] Utilizing ethics approval?",
            type: "checkbox",
            name: "utilizing_central_irb_erb",
            helptext:"Please check if this site does not need its own IRB and will be utilizing a central IRB document"
        },
       {
            label: "[EXT] City *",
            type: "text",
            name: "city"
        },
        {
            label: "[EXT] IRB/ERB Start Date",
            type: "date",
            name: "irb_erb_start_date"
        },
        {
            label: "[EXT] Address Prefix",
            type: "text",
            name: "ext_address_prefix"
        },
        {
            label: "[EXT] IRB/ERB Expiration Date",
            type: "date",
            name: "irb_erb_expiration_date"
        },
        {
            label: "[EXT] Country *",
            type: "searchableDropdown",
            name: "country_id",
            options: this.state.countryList,
            onSelect: this.onCountryChange
        },
        {
            label: "Type of Material Provided",
            type: "text",
            name: "type_of_material_provided",
            disabled: true
        },
        {
            label: "[EXT] State/Province *",
            type: "searchableDropdown",
            name: "stateid",
            options: this.state.stateList,
            onSelect: this.onSearchSelect
        },
        {
            label: "Site # *",
            type: "text",
            name: "impact_site",
            maxlength:5,   
            helptext:'Please enter up to 5 integers only.',
            onDrop:(e)=>{
                e.preventDefault();
                return false;
            } ,   
            onPaste:(e)=>{
                e.preventDefault();
                return false;
            },     
            onBlur:async()=>{
                await this.isValidImpactSite();
            },
            onKeyPress:(e)=>{
                /*Allowing only Digits */
                var regex = new RegExp("^[0-9]+$");
                var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                if (regex.test(str)) {
                    return true;
                }            
                e.preventDefault();
                return false;
            }
        },
       
        {
            label: "[EXT] Zip/Postal Code *",
            type: "text",
            name: "zip_postal_code",
        }, {
            label: "Address Type",
            type: "text",
            name: "address_type",
            disabled:true,
           
        },
        {
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
        },

        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        },
        {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        }
        ];
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/getall");
        const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ countryList: resultArray });

    }
    isValidImpactSite=async()=>{
        let is_valid=true;
        let {submissionId,viewEditData}=this.props;
        let {impact_site,country_id,customError,error}=this.state;
        if(!impact_site||impact_site.toString().trim().length<=0){
            return true;
        }
        if(!customError){
            customError={};
        }
        if(!error){
            error={};
        }
       
        let inputData={
            submission_id: submissionId,
            country_id,
            impact_site:impact_site.toString().trim(),
            id:viewEditData.id||null
        };
        const res = await API.post("/sites/drugdeliverysites/impactSite/validation",inputData);
        if(res&&res.data){
            if(res.data.status===true){
                customError={};
            }
            else{
                is_valid=false;
                customError={impact_site:"Site # is already exist."};
                if(error["impact_site"]){
                    delete error["impact_site"];
                }
            }
        }
        if(isNaN(impact_site.trim())||impact_site.trim().length>5){
            customError={impact_site:"Please enter up to 5 integers only."};
            is_valid=false;
        }
        this.setState({customError:{...customError},error:{...error}});
        return is_valid;
    }
    getStateList = async (country) => {
         if (country) {
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ stateList: resultArray });
    }}

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            country_id: e.value

        },()=>{
            this.isValidImpactSite();
        });
       
            this.getStateList(e.name)

    }

    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
                hasChanged: true
            });
        }
        else 
        {const { name, value} = e.target;
    this.setState({
        [name]: value,
        patchObject:  {...this.state.patchObject, [name]: value},
        hasChanged: true
    });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = async() => {
        let is_valid = true;
        const statecopy = { ...this.state };
                delete statecopy.compoundList;
                delete statecopy.stateList;
                delete statecopy.countryList;
                delete statecopy.hasChanged;
                delete statecopy.error;
             const   initialValues={
                   name:this.state.name,
                   contact_name:this.state.contact_name,
                   phone_number:this.state.phone_number,
                   street_address:this.state.street_address,
                //    email_address:this.state.email_address,
                   city:this.state.city,
                   country_id:this.state.country_id,
                   stateid:this.state.stateid,
                   zip_postal_code:this.state.zip_postal_code,
                   impact_site:this.state.impact_site
                  }
                  const errors = {}
                  Object.keys(initialValues).forEach((key) => {
                    if (initialValues[key] === "" || initialValues[key] === null ) {
                        errors[key] = true;
                        is_valid = false;
                        
                    } 
                    this.setState({
                        error:  { ...errors}
                    });
                });
      
        let isValidSite = await this.isValidImpactSite();
        if (!isValidSite) {
            is_valid = false;
        }
        return is_valid;

    }
    onSave = async() => {
        let status=await this.formValidation(this.state);
        if(status){
          this.props.handleDrugDeliverySave(this.state);
        }
         
      }
      onUpdate = async() => {
        let status=await this.formValidation(this.state);
        if(status){
          this.props.handleDrugDeliveryUpdate(this.state);
        }
         
      }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        customerror={this.state.customError?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox"? field.onChange ||((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                   
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleDrugDeliverySave, viewEditFlag, handleDrugDeliveryUpdate} = this.props;
        const { record_Id } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg">
                {this.state.isLoader=== true ?
        <Loader /> :  <div>   <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit Drug Delivery Site ` : "New Drug Delivery Site"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                            viewEditFlag != "view" ?
                                viewEditFlag === "edit" ?
                                    <Button variant="primary" onClick={this.onUpdate}>
                                        Save
                     </Button> : <Button variant="primary" onClick={this.onSave}>
                                        Save
                    </Button> : null
                        }

                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default DrugDeliverySiteModal;