import React from "react";
import Tab from "../ToReviewDetails/Tab";
import { Accordion, Card, Row, Col, Nav, FormControl,Container, Form, Dropdown, Modal, InputGroup, DropdownButton, Tooltip, OverlayTrigger } from 'react-bootstrap';
import InputField from '../common/input-fields/InputField';
import { stateToHTML } from 'draft-js-export-html';
import UserContext from '../../context/UserContext';
import API from "../../api/apis";
import { showMessage } from "../ToReviewDetails/commonFunctions";
import Button from "../common/Button";
export default class FeedbackModal extends React.Component{
    static contextType=UserContext;
    constructor(props){
        super(props);
        this.state={    
            to_MSL:"",
            from_coordinator:'',
            open_text_entry:"",
            mslInfo:{}
        }
    }
    componentDidMount(){
        this.getMslInformation();
    }
    get inputFieldsArray(){
        return [
            {
                label: "To MSL",
                type: "text",
                placeholder: "To MSL",
                name: "to_MSL",
                readonly:"readonly"
                
            },
            {
                label: "From Coordinator",
                type: "text",
                placeholder: "From Coordinator",
                name: "from_coordinator",
                readonly:"readonly"
                   
            }, 
            {
                label: "Feedback Open Text Entry",
                type: "textarea",
                placeholder: "Feedback Open Text Entry" ,
                name: "open_text_entry",
               
            
            },      
        ];
    }
    onInputChange = (e,eo) => {
        if (eo.type === "datetime-local") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else {
             this.setState({[e.target.name]: e.target.value});
             if(e.target.value !== "")
                this.setState({error: { ...this.state.error, [e.target.name]: false}});}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
        });        
    }
    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
        }); 
    }
    getMslInformation=async()=>{
        this.setState({showLoader:true});
        try{
            let {submissionId}= this.props;
            const res = await API.get(`/reforecastOptimization/MSLList/${submissionId}`);
            if(res&&res.data&&res.data.length>0){
                let {mslInfo,to_MSL,from_coordinator}=this.state;
                mslInfo=res.data[0];
                to_MSL=`${mslInfo.first_name??""} ${mslInfo.last_name??""}`
                console.warn(this.context)
                from_coordinator=`${this.context.userFirstName} ${this.context.userLastName}`;
                this.setState({mslInfo,to_MSL,from_coordinator});
            }
           
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
    }
    handleSubmit=async()=>{
        this.setState({showLoader:true});
        try{
            let {open_text_entry,mslInfo,to_MSL,from_coordinator}=this.state;
            let payLoad={
                "feedback": open_text_entry,
                "to_msl": mslInfo.email,
                "msl_name":to_MSL,
                "coordinator_name": from_coordinator,
                "submission_id": this.props.submissionId,
                "created_by":this.context.userSystemId
            };

            const res = await API.post(`/reforecastOptimization/feedback/submit`,payLoad);
            if(res&&res.data&&res.data.error&&res.data.error.length>0){
                console.error("submit feedback: ",res.data.error);
                showMessage({
                    text:res.data.error,
                    icon: "error",
                    button: "Ok",
                }, (e) => { }); 
            }
            else if(res&&res.data&&res.data.success){
                showMessage({
                    text: "Submitting feedback has been successfully processed",
                    icon: "success",
                    button: "Ok",
                }, (e) => {this.props.handleFeedbackEvent("CLOSE") });
            }
            else{
                console.error("submit feedback: ",res.data.error);
                showMessage({
                    text: "Failed!",
                    icon: "error",
                    button: "Ok",
                }, (e) => { }); 
            }
           
        }
        catch(exp){
            console.error("submit feedback Catch: ",exp);
        }
        finally{
            this.setState({showLoader:false});
        } 
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if(field.name!=="open_text_entry"){
                return (
                    <Col sm={6} key={field.name}>
                        <InputField
                            {...field}
                            value={this.state[field.name]}
                            error={this.state.error?.[field.name]}
                            onSelect={field.onSelect || this.onSelectChange}
                            onChange={field.onChange ||((e)=>this.onInputChange(e,field))}
                        />
                    </Col>
                )
            }
            else{
                return (
                    <Col sm={12} key={field.name}>
                        <InputField 
                            {...field}
                            value={this.state[field.name]}
                            error={this.state.error?.[field.name]}
                            onSelect={field.onSelect || this.onSelectChange}
                            onChange={field.onChange ||((e)=>this.onInputChange(e,field))}
                        />
                    </Col>
                )
            }
            
        });
    }
    render() {
        const { handleFeedModalShowHide, showHideFeedModal} = this.props;
        const { record_Id } = this.state;
        let {mslInfo}=this.state;
        return (
            <div>
                <Modal show={showHideFeedModal} size="lg" className="modal-mdx"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header className="border-0"  >
                        <Modal.Title className="w-100 d-flex justify-content-center pt-3 pl-3">
                            <h5  class='modal-title text-left w-100'>
                                Feedback for {this.props.data.title}</h5>
                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" onClick={e=>{
                                this.props.handleFeedbackEvent("CLOSE")
                            }} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="new-flow-modal  p-3" >
                        <Row >
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer  className="w-100 d-flex justify-content-right border-0  p-3 ">
                     {mslInfo&&mslInfo.email&&mslInfo.email.length>0&& <Button style="success " className='d-flex  align-items-center p-2' onClick={this.handleSubmit} >
                            <span class="material-icons mr-1 p-0"  >send</span>
                            <span >Submit Feedback</span>
                        </Button>}
                    </Modal.Footer>

                </Modal>
            </div>
        )
    }
};

    

   