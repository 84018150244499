import React, { Component } from 'react'
import { Col, Row, Button } from 'react-bootstrap';
import DatatableComponent from './common/datatable/DatatableComponent';
import { Link, withRouter } from 'react-router-dom';
import API from '../api/apis';
import UserContext from '../context/UserContext';
import Loader from './common/loader/Loader';
import { currentWorkflow, getWorkflowUrl } from './ToReviewDetails/commonFunctions';
 class SearchResults extends Component {
     static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,       
        data : []     ,
        isLoading : true     

    }
    async componentDidMount() {       
        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        let is_new_workflow=await currentWorkflow(this.context.userSystemId);
        this.setState({
            selectedRole: selectedRole, 
            is_new_workflow 
        });
    

        await this.getResults(this.props.searchText);
    }
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
               
            }, () => {  })
        }
    }
    componentWillUnmount = () =>{
        localStorage.removeItem("filter_searchresults");
    }
    
    columns = [{
        dataField: 'id',
        text: 'Submission Id',
        sort: true
       
    },
    {
        dataField: 'concept.investigatorname',
        text: 'Investigator Name',
        formatter: (cell, row, rowIndex) => { return <>{row["concept.investigatorname"] ? row["concept.investigatorname"] : ""} </> },
        sort: true
       
    },
    {
        dataField: 'trial_alias',
        text: 'Trial Alias',
        sort: true
       
    },
    

   ];
   
    getResults = async (searchText) => {
       try
       { const res = await API.post(`views/search`, {
            value: searchText,
            limit: 1000,
            offset: 0,
            count: false
        })
        console.log(res.data)
        if(res && res.data){
            this.setState({
                data : res.data , 
                isLoading : false
            })
        }
    }
    catch(error){
        console.log(error);
        this.setState({
            data : [],
            isLoading :  false
        })
        
    }
         
    }
   
    rowEvents = {
        onClick: (e, row, rowIndex) => {
            localStorage.removeItem("filter_searchresults");
        //   this.props.history.push(`/submissions/${row.id}`  );
        let url= getWorkflowUrl(this.state.is_new_workflow,row.id);
        window.location =  url;
       
        }
    };

  
    render() {
        const {data  , isLoading} = this.state;
     
        return (
            <>
            {isLoading && <Loader />}
            {!isLoading  && <Row>
                
                <DatatableComponent id="searchresults" data={data} rowEvents={this.rowEvents} columns={this.columns} pagination="true" />
            </Row>}
            </>
        )
    }
}
export default withRouter(SearchResults);
