import React, { Component } from 'react';
import DatatTableEx from '../common/DataTableEx';
import { Col, Row,Button } from 'react-bootstrap';
import API from '../../api/apis';
import { dateStringformat, dateTimeformat } from '../../utility/helperFunctions';
export default class History extends Component {
    state={
        historyList:[],
        filterSource:[]
    }
    columns=[
        {
            dataField: 'id',
            text: 'id',
            sort: true,
            hidden:true,
            csvExport: false ,

        },
        {
            dataField: 'date',
            text: 'Date',
            sort: true,
            sortValue: (cell, row) => new Date(row["date"])
        },
        {
            dataField: 'field',
            text: 'Field',
            sort: true,
        },
        {
            dataField: 'user',
            text: 'User',
            sort: true,
        },
        {
            dataField: 'old_value',
            text: 'Original Value',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>
                    {row.old_value != null ? (row.old_value) : ""}
                </>
            }
        },
        {
            dataField: 'new_value',
            text: 'New Value',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>
                    {row.new_value != null ? (row.new_value) : ""}
                </>
            }
        },
        {
            dataField: 'row_type',
            text: 'Row Type',
            sort: true,
        },
    ]
    componentDidMount=()=>{
        this.getColumns();
    }
    getColumns=async()=>{
        let res=await API.get(`/enrollmentOptimization/enrollmentHistory/${this.props.submissionId}`);
        let fields="";
        let date="";
        let user="";
   let enrollmentFieldCollection={"created_at":"Created","baseline_enrollment":"Baseline Enrollment","estimated_enrollment":"Estimated Enrollment","actual_enrollment": "Actual Enrollment","quarter":"* [EXT] Quarter",
   "year":"* [EXT] Year","baseline_budget":"Baseline Budget","estimated_budget":"Estimated Budget","actual_budget":"Actual Budget","currency":"Currency","patients_currently_on_treatment":"[EXT] Patients Currently on Treatment","patients_currently_on_followup":"[EXT] Patients Currently on Followup","SubmissionId_str":"submission","patients_completed_trial":"[EXT] Patients Completed Trial","patients_discontinued_from_treatment":"[EXT] Pts Disc. from Treatment","total_patients_entered_treatment":"{EXT] Total Patients Entered Treatment","created_by":"Created By","updated_by":"Updated By",
   "comments" : "[EXT] Comments"};
   let reforecastFieldCollection=
   {"reforcastTitle":"Reforecast Title",
    "quarterly_enrollment":"[EXT] Quarterly Enrollment","approval":"Approval","fpv":"[EXT] FPV",
"decisionDate":"Decision Date","fpet":"[EXT] FPET","lpet":"[EXT] LPET","lpetx":"[EXT] LPETx","lpv":" [EXT] LPV",
"fsr":"[EXT] FSR","feedback":"Lilly Feedback","investigator_rationale":"[EXT] Investigator Rationale",
"submission":"Submission","last_updated_by":"Last Updated By","createdby":"Created By","updated_by":"Updated By","created_at":"Created"
};

let feedbackFieldCollection={
    "to_msl": "To MSL","created_at":"Created","updated_by":"Updated By",
    "from_coordinator": "From Coordinator",
     "feedback_entry":"Feedback Open Text Entry"}
    
     let formatValues=res.data.map((item,index)=>{
  let {id,action,old_value, new_value,created_at,created_by,updated_at, row_type, first_name,last_name,field}=item;
        if(row_type==="Enrollment"){
             fields=enrollmentFieldCollection[field]
        }
        if(row_type==="Reforecast"){
            fields=reforecastFieldCollection[field]

       }
       if(row_type==="Feedback"){
        fields=feedbackFieldCollection[field]

   }
       if(fields=="Created"){
        new_value=new_value!=null?dateTimeformat(new_value):""
       }
       if(action="INSERT"){
        date=created_at!=null?dateTimeformat(created_at):"";
        
       }
       if(action="UPDATE"){
        date=updated_at!=null?dateTimeformat(updated_at):"";
       
       
       }
        
        return{
     date:date,
           field:fields||"",
        row_type:row_type,old_value:old_value,new_value:new_value,user:first_name+" "+last_name
           
        }})
        let filterSource=await this.getFilterSource({data:formatValues?formatValues:[]});
        this.setState({
            historyList:formatValues,filterSource:[...(filterSource||[])]
        })
    }
    getFilterSource=async (res)=>{
        if(res&&res.data&&res.data.length>0){
            let filterCollection= this.columns.filter(
                p => (!p.hidden && (p.dataField != "actions")&&(p.dataField!="is_follow"))).map((col, index) => {
                let columnValues = (res.data || []).map((item, key) => {
                    
                    
                    if(col.dataField.split(".").length>1){

                        return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
                        
                    }
                    
                    return item[col.dataField];
                });
                return {
                    
                    group:col.text,key:col.dataField,items:[...new Set(columnValues)]};
            });
            return filterCollection;
        }
        return null;
          
    } 
    render(){
        return <DatatTableEx filterSource={this.state.filterSource}
        filterChange={()=>{}}columns={this.columns} csvName="history_details" pagination="true" data={this.state.historyList}/>
    }
}