import React, { Component } from 'react'
import { Button, Col, Row, Card, Form } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../../../../common/sticky-nav/StickyNav';
import InputField from '../../../../common/input-fields/InputField';
import API from '../../../../../api/apis';
import BootstrapModal from '../../../../common/modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../../../../common/loader/Loader';
import {yyyymmddFormat, convertISOStringMMDDYYYY } from '../../../../common/dateFormat';
import FileComponent from '../../../../common/file-component/FileComponent';
import { getUserInfo, dateTimeformat, uploadFile, dateStringformat, dateChange, enableWFActions } from '../../../../../utility/helperFunctions';
import DrugOrderModal from './DrugOrderModal';
import UserContext from '../../../../../context/UserContext';
import swal from 'sweetalert';
import axios from 'axios';


class DrugOrderDetails extends Component {
    static contextType = UserContext;

    state = {
        externalurl :  "http://localhost:1337",
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        drugDeliverySitesList: [],
        countryList: [],
        stateList: [],
        drugNameList: [],
        strength: [],
        presentation: [],
        count: [],
        hasChanged: false,
        fileList: [],
        selectedFile: null,
        drugOrder: {},
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        submission_id:""
    }

    async getExternalUrl() {
        let response ='';
        try {
             response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
        externalurl : (response && response.data) ? response.data.exturl : "http://localhost:1337",
    })
    }
  
    async componentDidMount() {
       await this.getExternalUrl();
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:   selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
            },
        });
        
        await this.getCountryList();
        await this.getdrugOrderById(this.props.match.params.id);
        await this.getFilesList(this.props.match.params.id)
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:   selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }


    getDrugDeliverySitesList = async (submissionId) => {
        try {
            const res = await API.get(`sites/drugdeliverysites/all/${submissionId}`);
            const drugDeliverySitesList = res.data.map(elm => ({ name:`${elm.name}-${elm.city}-${elm.country.name}`, value: elm.id }));
            this.setState({
                drugDeliverySitesList: drugDeliverySitesList,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    getCountryList = async () => {
        const res = await API.get("seed/country/data")
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ countryList: resultArray });
    }

    getStateList = async (country) => {
        if (country) {
            const res = await API.get(`seed/states/data/${country}`);
            const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
            this.setState({
                stateList: resultArray,
            });
        }
    }
    getdrugOrderById = async (id) => {
        try {
            const drugOrderDetails = await API.get(`sites/drugorders/getbyid/${id}`);
            const viewEditData = { ...drugOrderDetails.data };
            if(drugOrderDetails.data?.broker_country_id){
                let countryName = this.state.countryList.filter(item => item.value === drugOrderDetails.data?.broker_country_id)
                await this.getStateList(countryName[0].name);
            }
            await this.getSitedrugOptionsBySiteId(drugOrderDetails.data?.order_site_id);
            drugOrderDetails.data["recordNo"]="R-"+drugOrderDetails.data.id.toString().padStart(4,'0')
            this.state.submission_id=drugOrderDetails.data.submission_id;
            drugOrderDetails.data["submission"] = "Submission-" + drugOrderDetails.data.submission_id;
            drugOrderDetails.data["createdBy"] = drugOrderDetails.data.created_by?.length ? await getUserInfo(drugOrderDetails.data.created_by) + "  " + dateTimeformat(drugOrderDetails.data.createdAt) : "";
            drugOrderDetails.data["updatedBy"] = drugOrderDetails.data.updated_by?.length ? await getUserInfo(drugOrderDetails.data.updated_by) + "  " + dateTimeformat(drugOrderDetails.data.updatedAt) : drugOrderDetails.data["createdBy"];
            drugOrderDetails.data["date_requested"] = drugOrderDetails.data.date_requested != "" && drugOrderDetails.data.date_requested != null ?
                dateStringformat(drugOrderDetails.data.date_requested) : null;
            drugOrderDetails.data["estimated_delivery_date"] = drugOrderDetails.data.estimated_delivery_date != "" && drugOrderDetails.data.estimated_delivery_date != null ?
            dateStringformat(drugOrderDetails.data.estimated_delivery_date) : null;
            drugOrderDetails.data["date_needed"] = drugOrderDetails.data.date_needed != "" && drugOrderDetails.data.date_needed != null ?
                dateStringformat(drugOrderDetails.data.date_needed) : null;
                await this.getDrugDeliverySitesList(this.state.submission_id);
            this.setState({
                viewEditData:viewEditData,
                drugOrder: {
                    ...this.state.drugOrder,
                    ...drugOrderDetails.data,
                },
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    getSitedrugOptionsBySiteId = async (siteId) => {
        try {
            let drugNameList = [],
                strength = [],
                presentation = [],
                count = [];
            const res = await API.get(`sites/sitedrugoptions/all/${siteId}`);
            res.data.map(item => {
                drugNameList.push({ sitedrugoption: item, name: item.compound.name, value: item.compound.id });
                presentation.push({ compound_id: item.compound_id, name: item.presentation.name, value: item.presentation.id });
                count.push({ compound_id: item.compound_id, name: item.count.name, value: item.count.id });
                strength.push({ compound_id: item.compound_id, name: item.strength.name, value: item.strength.id });
            })
            this.setState({
                drugNameList,
                strength,
                presentation,
                count,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    get inputFieldsArray() {
        return [{
            type: "alert",
            label: "Information",
            variant: "secondary"
        },
        {
            label: "Order Site *",
            name: "order_site_id",
            type: "searchableDropdown",
            placeholder: "Search Drug Delivery Sites...",
            options: this.state.drugDeliverySitesList,
            onSelect: this.onOrderSiteSearchSelect,
            disabled: true
        }, {
            label: "Submission *",
            type: "text",
            name: "submission",
            disabled: true
        },
        {
            label: "Ordered by",
            type: "text",
            name: "llir_ordered_by",
            disabled: true
        },
        {
            label: "Status",
            name: "llir_status",
            type: "dropdown",
            disabled: true,
            options: [
                "Draft",
                "New",
                "Ordered",
                "Delivered",
                "Complete",
                "On Hold",
                "Cancelled"
            ]
        },
        {
            label: "Record ID",
            type: "text",
            name: "recordNo",
            disabled: true,
        },
        {
            label: "Date Requested",
            type: "text",
            name: "date_requested",
            disabled: true,
        },
        {
            label: "Request #",
            name: "llir_request",
            type: "text",
            disabled: true,
        },
        {
            label: "Estimated Delivery Date",
            type: "text",
            name: "estimated_delivery_date",
            disabled: true,
        },
        {
            label: "PO Number",
            name: "llir_po_number",
            type: "text",
            disabled: true,
        },
        {
            label: 'Broker Address ("For non-US orders only")',
            type: "alert",
            variant: "secondary"
        }, {
            label: "Attention to/Recipient Name",
            type: "text",
            name: "attention_to_recipient_name",
            disabled: true
        }, {
            label: "Street Address",
            name: "broker_street_address",
            type: "text",
            disabled: true
        }, {
            label: "Broker Contact Number",
            type: "number",
            name: "broker_contact_number",
            disabled: true
        },
        {
            label: "City",
            type: "text",
            name: "broker_city",
            disabled: true
        },
        {
            label: "Country",
            type: "searchableDropdown",
            placeholder: "Select Countries",
            name: "broker_country_id",
            options: this.state.countryList,
            onSelect: this.onCountryChange,
            disabled: true
        },
        {
            label: "Zip/Postal Code",
            type: "text",
            name: "broker_zip_postal_code",
            disabled: true
        },
        {
            label: "State",
            type: "searchableDropdown",
            placeholder: "Select State",
            name: "broker_state_id",
            options: this.state.stateList,
            onSelect: this.onSearchSelect,
            disabled: true
        },
        {
            type: "alert",
            label: "Drug Order",
            variant: "secondary"
        },
        {
            label: "Drug Name *",
            type: "searchableDropdown",
            placeholder: "Search Compounds...",
            name: "drug_compound_id",
            options: this.state.drugNameList,
            onSelect: this.onCompoundsSearchSelect,
            disabled: true
        },
        {
            label: "Strength *",
            type: "searchableDropdown",
            placeholder: "Search Strengths...",
            name: "strength_id",
            options: this.state.strength,
            onSelect: this.onSearchSelect,
            disabled: true
        },
        {
            label: "Presentation *",
            type: "searchableDropdown",
            placeholder: "Search Presentations...",
            name: "presentation_id",
            options: this.state.presentation,
            onSelect: this.onSearchSelect,
            disabled: true
        },
        {
            label: "Count *",
            type: "searchableDropdown",
            placeholder: "Search Counts...",
            name: "count_id",
            options: this.state.count,
            onSelect: this.onSearchSelect,
            disabled: true
        },
        {
            label: "Quantity *",
            type: "number",
            name: "llir_quantity_number",
            disabled: true
        },
        {
            type: "alert",
            label: "Shipping details",
            variant: "secondary"
        },
        {
            label: "Date Needed",
            type: "date",
            name: "date_needed",
            disabled: true
        },
        {
            label: "Import License *",
            type: "dropdown",
            name: "import_license",
            options: [
                "...None...",
                "Yes",
                "No"
            ],
            disabled: true
        },
        {
            label: "Delivery Restrictions",
            type: "text",
            name: "delivery_restrictions",
            disabled: true
        },
        {
            label: "Comments",
            type: "text",
            name: "comments",
            disabled: true
        },
        {
            type: "alert",
            label: "Attestation Details",
            variant: "secondary",
            disabled: true
        },
        {
            label: "Attest to the details of this order",
            type: "checkbox",
            name: "is_attest_to_the_details_of_this_order",
            disabled: true,
        },
        {
            label: "Created By",
            type: "text",
            name: "createdBy",
            disabled: true,
            className:"createUpdateByfontsize",
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updatedBy",
            disabled: true,
            className:"createUpdateByfontsize",
        }
        ];
    }


    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else
        { this.setState({ hasChanged: true })
        this.setState({ [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({ hasChanged: true })
        this.setState({
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state.drugOrder[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={((e)=>{this.onInputChange(e,field)})}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.submission_id}`)
    }


    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false,
        });
        this.getdrugOrderById(this.props.match.params.id);
    }

    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }

    getFilesList = async (submission_id) => {
        this.setState({
            showTable: false , 
            action : "fetching",
            showLoader : true
        })
        try {
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            this.setState({
                // fileList: res.data?.filter(file => file.type === `Publication_${this.props.match.params.publicationId}`),
                fileList:[],
                showTable: true,
                showLoader : false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                showLoader : false
            })
        }
    }
    uploadFileFunction = async () => {

        const formData = new FormData();
        formData.append(
            'file',
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        // try {
        //     const res = await API.post(`/user/multiple/upload/drugOrder/${this.state.submission_id}/${this.props.match.params.id}`, formData);
        // let fileUrl = `${this.state.externalurl}/fileupload/multiple/drugOrder/${this.state.submission_id}/${this.props.match.params.id}`;
        //     let res = await axios.post(fileUrl, formData, {
        //         headers: {                   
        //             'Authorization': 'Bearer ' + localStorage.getItem("token"),
        //         },
        //     });
        //     this.setState({
        //         selectedFile: null,
        //         successFile: true,
        //         fileMessage: "File uploaded!"
        //     })
        // } catch (e) {
        //     this.setState({
        //         selectedFile: null,
        //         errorFile: true
        //     })
        //     console.log(e);
        // }
    }
    handleDelete = async (fileName) => {
        // try {
        //     const res = await API.post(`/user/multiple/delete/drugOrder/${this.state.submission_id}/${this.props.match.params.id}`);
        //     this.setState({
        //         successFile: true,
        //         fileMessage: "File Deleted!"
        //     })
        // } catch (e) {
        //     this.setState({
        //         errorFile: true
        //     })
        //     console.log(e);
        // }
    }

    handleDownload = async (fileName) => {
        // try {
        //     const res = await API.get(`/user/multiple/download/getByid/drugOrder/${this.state.submission_id}/${this.props.match.params.id}`);
        //     const url = window.URL.createObjectURL(new Blob([res.data]));
        //     const link = document.createElement("a");
        //     link.href = url;
        //     link.setAttribute('download', decodeURIComponent(fileName));
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        //     this.setState({
        //         successFile: true,
        //         fileMessage: "File Downloaded Successfully!"
        //     })
        // } catch (e) {
        //     this.setState({
        //         errorFile: true
        //     })
        //     console.log(e);
        // }
    }

    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
    }
    handleDrugOrderUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        state.submission_id = this.state.submission_id;
        state.id = state.record_id;
        state.broker_country_id = state.broker_country_id?.value || state.broker_country_id;
        state.broker_state_id = state.broker_state_id?.value || state.broker_state_id;
        state.order_site_id = state.order_site_id?.value || state.order_site_id;
        state.drug_compound_id = state.drug_compound_id?.value || state.drug_compound_id;
        state.has_the_drug_delivery_shipment_address="No";
        state.date_needed = state.date_needed != "" && state.date_needed != null ?
            dateChange(state.date_needed) : null;
            if(state.is_attest_to_the_details_of_this_order===true)
        {state.date_requested=new Date()}
        try {
            const res = await API.post(`sites/drugorders/${state.order_site_id}/upsert`, { drugOrder: state });
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    render() {
        const { showHideModal, viewEditFlag, viewEditData, success_body, errorModal, successModal } = this.state;
        if (!this.state.submission_id)
            return <Loader />
        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                            {" Submission-"}{this.state.submission_id}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "60%" }}>Drug Order-{this.props.match.params.id} </h4>
                    <div className="text-right" style={{ "width": "20%" }}>
                        {this.state.access.edit && enableWFActions() &&
                            <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>}
                    </div>
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="data-cards">
                        <Card.Body>
                            <Tabs defaultActiveKey="files">
                                <Tab eventKey="files" title="Files">
                                    {this.state.access.edit && enableWFActions() && <Row>
                                        <Col sm={8}>
                                            <Form.File
                                                id="custom-file"
                                                className="custom-file-label"
                                                onChange={this.onFileChange}
                                                label={this.state.selectedFile?.name}
                                                custom
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Button variant="secondary" onClick={()=> uploadFile(this.state.selectedFile,this.state.fileList,this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>
                                        </Col>
                                    </Row>}
                                    <br />

                                    {this.state.access.edit && enableWFActions() && <small className="form-text text-muted">*Maximum size for file upload is 2MB.</small>}
                                    <br />
                                    {this.state.fileList.length > 0 &&
                                        <FileComponent data={this.state.fileList}
                                            accessDelete = {this.state.access.delete}

                                            handleDelete={this.handleDelete}
                                            handleDownload={this.handleDownload} />
                                    }

                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {
                showHideModal &&
                <DrugOrderModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleDrugOrderSave={this.handleDrugOrderSave}
                    handleDrugOrderUpdate={this.handleDrugOrderUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    submissionId={this.state.submission_id}
                />
            }
            {successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.successFile &&
                <BootstrapModal
                    show={this.state.successFile}
                    header="Success"
                    body={this.state.fileMessage}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorFile &&
                <BootstrapModal
                    show={this.state.errorFile}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }

        </>
    }
}

export default withRouter(DrugOrderDetails);
