import React from "react";
import { Col, Row } from 'react-bootstrap';
import InputField from '../common/input-fields/InputField'
import API from '../../api/apis';
import { dateChange } from "../../utility/helperFunctions";
import UserContext from '../../context/UserContext';
import BootstrapModal from "../common/modal/BootstrapModal";
import { isCoordinator } from '../ToReviewDetails/commonFunctions';
import Button from "../common/Button";
import { formatNumber } from "./FormatNumber";

export default class EnrollmentExpenses extends React.Component {
    static contextType = UserContext
    state = {
        financeDetails: [],
        _isCoordinator: false,

        patchObject: {}
    }
    get inputFieldsArray() {
        return [
            {
                label: "Start Up Cost",
                type: "text",
                placeholder: "Enter Start Up Cost",
                name: "start_up",
                helptext: "Total Start Up Cost for Trial",
                customerror: (!this.state.patchObject.start_up) ? "" : (new RegExp(/^\d*\.?\d*$/).test(this.state.patchObject.start_up + "") ? "" : "Only decimal and numbers are allowed"),

            },
            {
                label: "Cost Per Patient",
                type: "text",
                placeholder: "Enter Cost Per Patient",
                name: "cost_per_patient",
                helptext: "Cost Per Enrolled Patient",
                customerror: (!this.state.patchObject.cost_per_patient) ? "" : (new RegExp(/^\d*\.?\d*$/).test(this.state.patchObject.cost_per_patient + "") ? "" : "Only decimal and numbers are allowed"),

            },
            {
                label: "Max Trial Expenses",
                type: "text",
                placeholder: "Enter Max Trial Expenses",
                name: "max_trial_expense",
                helptext: "Total Available to spend outside of patient enrollment",
                customerror: (!this.state.patchObject.max_trial_expense) ? "" : (new RegExp(/^\d*\.?\d*$/).test(this.state.patchObject.max_trial_expense + "") ? "" : "Only decimal and numbers are allowed"),

            },
            {
                label: "Final Payment",
                type: "text",
                placeholder: "Enter Final Payment",
                name: "final_payment",
                helptext: "Payment upon receiving FSR",
                customerror: (!this.state.patchObject.final_payment) ? "" : (new RegExp(/^\d*\.?\d*$/).test(this.state.patchObject.final_payment + "") ? "" : "Only decimal and numbers are allowed"),

            },




        ]
    }
    async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.getfinancedata();

        this.setState({
            _isCoordinator,

        });

    }


    getfinancedata = async () => {
        let currency_id = null;
        let res = await API.get(`/finance/overviewDetail/${this.props.submissionId}`)
        if (res && res.data) {
            let currency_code = res.data.currency_code;
            let start_up=res.data?.start_up != null &&  currency_code!= null ? currency_code+" "+formatNumber(res.data.start_up)  : "";
            let cost_per_patient=res.data?.cost_per_patient != null &&  currency_code!= null ? currency_code+" "+formatNumber(res.data.cost_per_patient)  : "";
            let max_trial_expense=res.data?.max_trial_expense != null &&  currency_code!= null ? currency_code+" "+formatNumber(res.data.max_trial_expense)  : "";
            let final_payment=res.data?.final_payment != null &&  currency_code!= null ? currency_code+" "+formatNumber(res.data.final_payment)  : "";
            
            this.setState({
                ...res.data, currency_code, start_up, 
                cost_per_patient, max_trial_expense, 
                final_payment
            },
            )

        }


    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            return (
                <Col sm={6} key={field.label}> <InputField
                    {...field}
                    disabled={field.disabled || !this.state._isCoordinator}
                    value={this.state[field.name]}
                    onChange={this.onInputChange}
                    onSelect={field.onSelect || this.onSelectChange}
                /></Col>)
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
            value: e.value,
            patchObject: { ...this.state.patchObject, currency_id: e.id },
        });
    }
    onSelectChange = (e, eo) => {

        this.setState({
            [eo.name || [eo.target.name]]: e,
            patchObject: { ...this.state.patchObject, [eo.name || [eo.target.name]]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }
    onInputChange = (e, eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: dateChange(e) },
            });
        }
        else {
            this.setState({
                [e.target.name]: e.target.value,
                patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
            });
            if (e.target.value !== "")
                this.setState({ error: { ...this.state.error, [e.target.name]: false } });
        }
    }

    onInputChange = (e) => {

        let { name, value } = e.target;
        this.setState({
            [name]: value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
        });
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });
    }

    onSave = async (state) => {
        let error = this.state.patchObject;

        let allFields = [...this.inputFieldsArray];
        let anyerror = false;

        for (let [key, value] of Object.entries(error)) {
            let exist = allFields.find(p => p.name === key);
            if (exist && exist.customerror) {
                anyerror = true;
            }
        }

        if (anyerror) {
            return false;
        }

        state = {
            submissionId: this.props.submissionId,
            ...state,
            last_modified_by:this.context.userSystemId
        }
        let numericFields = ['start_up','cost_per_patient', 'max_trial_expense', 'final_payment'];
        numericFields.forEach(element => {
            if(element in state && state[element]===""){
                state[element] = null;
            }
        });

        try {
            const res = await API.post(`/enrollmentOptimization/enrollmentOverview/update`, state);
            this.setState({
                successModal: true,
                success_body: "Updated successfully",


            })
            this.getfinancedata();

        }
        catch (err) {
            this.setState({ errorModal: true })
        }




    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }
    render() {
        return (
            <div className="new-flow-modal" >
                <div className="d-flex flex-grow-1 justify-content-end align-items-center mr-4">
                    {this.state._isCoordinator && <Button style="success w-100px" onClick={() => this.onSave(this.state.patchObject)} className="d-flex align-items-center justify-content-center p-2"><span>SAVE</span></Button>}</div>
                <Row>{this.renderInputFields()}</Row>

                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body={this.state.success_body}
                        changeShowState={this.changeShowState}
                        buttonText="Ok"
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An Error has Occurred!please try again"
                        changeShowState={this.changeShowState}
                    />}
            </div>
        )
    }

}