import React, { useState, useEffect, useContext } from 'react';
import SwitchHistory from '../SwitchHistory';
import { OBJ_TYPES } from '../SwitchHistory/historyConsts';
import UserContext from '../../../context/UserContext';


const RoleAudit = (props) => {
    const myContext = useContext(UserContext);

    return (
        <div style={{ ...props.style || {} }}>
            <SwitchHistory
                object_type = { OBJ_TYPES.USER }
                object_id = { myContext.selectedRole.includes("administrator") ? null : myContext.userSystemId }
            />
        </div>
    );

};
export default RoleAudit;