import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import { yyyymmddFormat } from '../../common/dateFormat';

import { getUserInfo, dateTimeformat, dateStringformat } from '../../../utility/helperFunctions';
import Loader from '../../common/loader/Loader';

class ExpensesModal extends React.Component {
    state = {
        currencyList: [],
        hasChanged: false,
        submissionId: "Submission-" + this.props.submissionId,
        expense_title: "",
        currency:"",
        isLoader:true,
        currency_iso_code: this.props.currency_iso_code,
        date_received:null
    }
    async componentDidMount() {
        const { viewEditData, viewEditFlag } = this.props;
        await this.getCurrencyList();
        let currencyName=this.state.currencyList.filter(item => item.id === this.props.currency_iso_code)[0]
        this.setState({currency:currencyName})
        if (viewEditFlag === "edit") {
            viewEditData["date_received"] = viewEditData.date_received != "" && viewEditData.date_received != null ?
                dateStringformat(viewEditData.date_received) : null;
            viewEditData["probability"] = viewEditData.probability != "" && viewEditData.probability != null ?
                viewEditData.probability + "%" : null;
            viewEditData["currency"] = this.state.currencyList.filter(item => item.id === viewEditData.currency_iso_code)[0];
            viewEditData["createdby"] = viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
            viewEditData["updatedby"] = viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
            viewEditData["total_cost1"] = viewEditData.currency?.value + " " + viewEditData.total_cost;
            this.setState({
                ...this.state,
                ...viewEditData,
                isLoader:false,
                submissionId: "Submission-" + viewEditData.submissionId
            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
    }
    getCurrencyList = async () => {
        try {
            const currencyList = await API.get(`seed/currency/data`);
            currencyList.data?.data || [].map(item => item["name"]= item.value + " - " + item.name);
            this.setState({
                currencyList: currencyList.data.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    get inputFieldsArray() {
        return [{
            type: "alert",
            label: "Information",
            variant: "secondary"
        },
        {
            label: "Expense Title * ",
            type: "text",
            name: "expense_title",
        }, {
            label: "Total Cost",
            type: "text",
            name: "total_cost1",
            disabled: true,
            edit: true
        },
        {
            label: "Estimated Cost",
            type: "number",
            name: "estimated_cost",
        },
        {
            label: "Invoice #",
            type: "text",
            name: "invoice",
        },
        {
            label: "Probability",
            type: "dropdown",
            placeholder: "None",
            name: "probability",
            options: [
                "None",
                "25%",
                "50%",
                "75%",
                "100%"
            ]
        },
        {
            label: "Date Received",
            name: "date_received",
            type: "date"
        },
        {
            label: "Vendor #",
            type: "text",
            name: "vendor"
        },
        {
            label: "[EXT] Comments",
            name: "comments",
            type: "textarea"
        }, {
            label: "Vendor Name",
            name: "vendor_name",
            type: "text"
        }, {
            label: "Submission",
            type: "text",
            placeholder: "Select Submission",
            name: "submissionId",
            disabled: true
        }, {
            label: "Approval",
            type: "dropdown",
            placeholder: "Awaiting Decision",
            name: "approval",
            options: [
                "None",
                "Approved",
                "Declined",
                "Awaiting Decision"
            ]
        }, {
            label: "Converted Currency",
            name: "converted_curreny",
            type: "number"
        }, {
            label: "Currency",
            type: "searchableDropdown",
            placeholder: "Select Currency",
            name: "currency",
            options: this.state.currencyList,
            onSelect: this.onSearchSelect
        }, {
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
        }, {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        },
        {
            type: "alert",
            label: "Expenses",
            variant: "secondary"
        }, {
            label: "[EXT] Item #1 Description",
            type: "text",
            name: "item_1_description",
        },
        {
            label: "[EXT] Item #4 Description",
            type: "text",
            name: "item_4_description",
        }, {
            label: "[EXT] Item #1 Frequency",
            type: "number",
            name: "item_1_frequency",
        }, {
            label: "[EXT] Item #4 Frequency",
            type: "number",
            name: "item_4_frequency",
        }, {
            label: "Item #1 Cost",
            type: "number",
            name: "item_1_cost",
        }, {
            label: "Item #4 Cost",
            type: "number",
            name: "item_4_cost",
        }, {
            label: "[EXT] Item #2 Description",
            type: "text",
            name: "item_2_description",
        }, {
            label: "[EXT] Item #5 Description",
            type: "text",
            name: "item_5_description",
        }, {
            label: "[EXT] Item #2 Frequency",
            type: "number",
            name: "item_2_frequency",
        }, {
            label: "[EXT] Item #5 Frequency",
            type: "number",
            name: "item_5_frequency",
        }, {
            label: "Item #2 Cost",
            type: "number",
            name: "item_2_cost",
        }, {
            label: "Item #5 Cost",
            type: "number",
            name: "item_5_cost",
        }, {
            label: "[EXT] Item #3 Description",
            type: "text",
            name: "item_3_description",
        }, {
            label: "",
            type: "",
            variant: "secondary",
        },
        {
            label: "[EXT] Item #3 Frequency",
            type: "number",
            name: "item_3_frequency",
        },
        {
            label: "alert",
            type: "",
            name: "",

        },
        {
            label: "Item #3 Cost",
            type: "number",
            name: "item_3_cost",

        }
        ];
    }


    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else 
        { this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }

    onBlur = (e) => {
        this.setState({[e.target.name]:parseFloat(e.target.value).toFixed(2)});
    }
    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            currency_iso_code: e.id
        });
    }
    formValidation = () => {
        let is_valid = true;
        if (this.state.expense_title === "") {
            this.setState({
                error: {
                    expense_title: true
                }
            })
            is_valid = false;
        }
        return is_valid
    }
    onSave = (saveNew) => {
        if (this.formValidation()) {
            this.props.handleExpensesSave(this.state,saveNew);
        }
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={(e)=>this.onInputChange(e,field)}
                        onBlur={field.name === "estimated_cost" ? this.onBlur : ''}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleExpensesSave, viewEditFlag, handleExpensesUpdate } = this.props;
        const { trial_expense_name } = this.state;
        
        return (
            <div>
                 <Modal show={showHideModal} size="lg">
                 {this.state.isLoader=== true ?
        <Loader /> :  <div>   <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title>
                            {viewEditFlag === "edit" ?
                                `Edit Expenses ${trial_expense_name}` : "New Trial Expense"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                   <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                            viewEditFlag === "edit" ?
                                <Button variant="primary" onClick={() => handleExpensesUpdate(this.state)}>
                                    Save
                                </Button> : <>
                                <Button variant="primary" onClick={()=>this.onSave(false)}>
                                    Save
                                </Button>
                                <Button variant="primary" onClick={()=>this.onSave(true)}>
                                    Save & New
                                </Button>
                                </>
                        }

                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default ExpensesModal;