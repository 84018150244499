
import React, { Component } from 'react'
import { Button, Card, Col, Container, Form, Modal, Navbar, Row, Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';

import ReviewFiles from '../ReviewFiles';

import StickyNav from '../../common/sticky-nav/StickyNav';

import { dateChange, dateStringformat, dateTimeformat, getUserInfo, enableWFActions } from '../../../utility/helperFunctions';
import UserContext from '../../../context/UserContext';

import API from '../../../api/apis';
import InputField from '../../common/input-fields/InputField';
import BootstrapModal from '../../common/modal/BootstrapModal';
import FileComponent from '../../common/file-component/FileComponent';
import axios from 'axios';
import ReviewAmendmentModal from './ReviewAmendmentModal';
import { yyyymmddFormat } from '../../common/dateFormat';


class ReviewAmendmentDetail extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: true,
            edit: false,
            delete: false,
        },
        reviewNumber:"",
        reviewTitle:"",
        type:"Amendment",
        owner_id:this.context.userSystemId,
        viewEditData: {},
        showHideModal: false,
        viewEditFlag: null,
        fileList: [],
        selectedFile: null,
        successFile: false,
        errorFile: false,
        showLoader: false,
        AmendmentDetails: {},
        auditData:[],
        trailData:[],
        submission_id:"",
        listOfFiles:[],
        date_of_review:null
        
    }
    get inputFieldsArray() {
        return  [
            {
                label: "Review Number ",
                type: "text",
                disabled:true,
                name: "reviewNumber",
               
            },
            {
                label: "Review Title",
                type: "text",
                disabled:true,
                name: "reviewTitle",
               
            },
            {
                label: "Record Type",
                type: "text",
                disabled:true,
                name: "type",
               
            },
           
            {
                label: "Submission",
                type: "text",
                disabled:true,
                name: "submissionId",
               
            },
            
            {
                label: "Date of Review",
                type: "date",
                name: "date_of_review",
                disabled:true,
            },
            
            {
                label: "Protocol Version Number",
                type: "text",
                placeholder: "Enter Protocol Version Number",
                name: "protocol_version_number",
                disabled:true,
            },
            {
                label: "Name of Medical Reviewer or Committee",
                type: "text",
                disabled:true,
                name: "name_medical_reviewer_committee",
               
            },
          
            {
                label: "Recommendations for investigator?",
                type: "dropdown",
                disabled:true,
                name: "any_recommendations_for_investigator",
                options: ["Yes", "No"]
            },
            {
                label: "Provide your recommendations",
                type: "textarea",
                name: "provide_your_recommendations",
                disabled:true,
            },
            {
                label: "Mandatory changes required",
                type: "dropdown",
                disabled:true,
                name: "mandatory_changes_required",
                options: ["Yes", "No"],
                helptext:"Safety language missing,inconsistency noted in document"
            },
            {
                label: "List required changes",
                type: "textarea",
                name: "mandatory_changes_need_to_be_implemented",
                disabled:true,
            },
            {
                label: "Approved?",
                type: "dropdown",
                disabled:true,
                name: "support_for_amendment",
                options: ["Yes", "No"]
            },
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled:true,
        }
        ,{
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled:true,
        }
    
        ];
    }
    async componentDidMount() {
        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  true,
                edit: selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
        this.getReviewById(this.props.match.params.amendmentId);

    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:true,
                    edit:  selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getReviewById = async (id, name) => {
        const res = await API.get(`review/amendment/${id}`);
        const viewEditData = { ...res.data };
       console.log(res.data)
       res.data["date_of_review"] = res.data.date_of_review != "" && res.data.date_of_review != null ?
       dateStringformat(res.data.date_of_review) : null;
       res.data["support_for_amendment"]=res.data.support_for_amendment===true?"Yes":"No";
       res.data["any_recommendations_for_investigator"]=res.data.any_recommendations_for_investigator===true?"Yes":"No";
       res.data["mandatory_changes_required"]=res.data.mandatory_changes_required===true?"Yes":"No";
        res.data["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
        res.data["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt):res.data.createdby ;
        this.state.submission_id=res.data.submission_id;
        this.setState({
            ...res.data,
            reviewNumber:`R-${res.data.id}`,
            submissionId:"Submission-"+res.data.submission_id,
            reviewTitle:"Submission-"+res.data.submission_id+" Amendment-Review-"+res.data.id,
            viewEditData: viewEditData,
            showLoader: false
        })
    }
    handleAmendmentUpdate = async (state) => {
        state.submission_id = this.state.submission_id;
        state.updated_by = this.context.userSystemId;
        state["date_of_review"] = state.date_of_review != "" && state.date_of_review != null ?
        dateChange(state.date_of_review) : null;
        try {
            const res = API.post(`review/amendment/update/`, state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })

        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
        this.getReviewById(this.props.match.params.amendmentId);
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
    }
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else
        {this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>{this.onInputChange(e,field)}) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    getFiles = async () => {
        
        this.setState({
            listOfFiles: []
        })
        console.log('Download File!!!!');
        let relativePath = '/_api/web/GetFolderByServerRelativeUrl(\'/sites/';
        let expandUrl = '?$expand=Files&$select=Files/Name,Files/UniqueId';
        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '\')/' + expandUrl;
        //var fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFolderByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3\')/?$expand=Files&$select=Files/Name,Files/UniqueId';
        try {
            const resp = await axios.get(fileUrl, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose"
                },
            });
            console.log(resp);

            this.setState({ listOfFiles: resp.data.d.Files.results.map((e) => { return { key: e.Name } }) });

        } catch (err) {
            console.error(err);
            this.setState({ errorModal: true });

        }
    }
    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }

    uploadFile = async () => {
        console.log(' uploadFileSPO Click!!!!');
        
       
        
        if (this.state.selectedFile == null) {
            alert("Please Select a file to upload");
        }
        else {
            let fileName = this.state.selectedFile.name;
            let relativePath = '/_api/web/GetFolderByServerRelativeUrl(\'/sites/';
            let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '\')/Files/add(url=' + '\'' + fileName + '\'' + ',overwrite=true)';
            //let fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFolderByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3\')/Files/add(url=\'text.txt\',overwrite=true)';

            const reader = new FileReader();
            reader.readAsArrayBuffer(this.state.selectedFile);
            reader.onload = async (event) => {
                //console.log("reader data",event.target.result);
                try {
                    const resp = await axios.post(fileUrl,
                        {
                            data: event.target.result,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json;odata=verbose',
                                'Authorization': 'Bearer ' + this.state.access_token
                            },
                        });
                    console.log(resp);
                    
                    await this.getFiles();

                }
                catch (err) {
                    console.error(err);
                    this.setState({ errorModal: true });

                }
            }
        }
    }
    downloadFile = (inFileName) => {
        console.log('downloadFile!!!!');
        let fileName = inFileName;//'Sample.docx';    
        let relativePath = '/_api/web/GetFileByServerRelativeUrl(\'/sites/';

        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '/' + fileName + '\')/$value';
        //var fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFileByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3/text.txt\')/$value';

        fetch(fileUrl, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                else {
                    this.setState({ errorModal: true });

                    throw new Error('Error Encountered.Please try again.');
                }
            })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

    }

    deleteFile = async (inFileName) => {
        console.log('deleteFile Click!!!!');
        let fileName = inFileName;// 'text.txt';      
        let relativePath = '/_api/web/GetFileByServerRelativeUrl(\'/sites/';
        //let fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFileByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3/text.txt\')';
        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '/' + fileName + '\')';
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
        try {
            const resp = await axios.post(fileUrl, {}, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose",
                    "IF-MATCH": "*",
                    "X-HTTP-Method": "DELETE",
                },
            });
            console.log(resp);
            this.setState({
                success_body: "Deleted successfully!",
                successModal: true,
            })
            await this.getFiles();

        }
        catch (err) {
            console.error(err);
        }
    }
    });
    }
    getAuditList = async (id) => {
        try {
            const temp = [];
            const res = await API.get(`audit/amendment/${id}`)
            console.log(res)
            await this.getTrailList();

            this.state.trailData.forEach(x => {
                res.data.forEach(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        temp.push(y);
                    }

                })
            })
            this.setState({
                auditData: temp,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/amendment`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false
        });
        this.getReviewById(this.props.match.params.amendmentId);
    }
    onNavOut = () => {
        window.history.back()
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData,listOfFiles } = this.state
        return (
            <>
                <StickyNav>
                    <Navbar expand="sm" className="nav-secondary">
                        <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                            <i className="bi bi-arrow-left">
                            {" "}{this.state.submissionId}
                            </i>
                        </div>
                        <h4 className="text-center" style={{ "width": "66%" }}>Amendment Review</h4>
                        {this.state.access.edit && enableWFActions() && <div className="text-right" style={{ "width": "20%" }}>
                            <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                        </div>}
                    </Navbar>
                </StickyNav>
                <Row>
                    <Col md={6}>
                        <Card className="data-cards pt-3">
                            <Card.Body>
                                <Row>
                                    {this.renderInputFields()}
                                    {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} >
                                        Save
                                    </Button>
                                </div> */}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card className="data-cards">
                            <Card.Body>
                                <Tabs defaultActiveKey="files">
                                <Tab eventKey="files" title="Files">
                                {this.state.submission_id && this.state.id &&<ReviewFiles
                                       access = {this.state.access}
                                       type = "Amendment"
                                       submissionid ={this.state.submission_id}
                                       id = {this.state.id}
                                       ></ReviewFiles>}
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                      
                    </Col>
                </Row>
               
                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body="Updated Successfully!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.successFile &&
                    <BootstrapModal
                        show={this.state.successFile}
                        header="Success"
                        body={this.state.fileMessage}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorFile &&
                    <BootstrapModal
                        show={this.state.errorFile}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                {showHideModal &&
                    <ReviewAmendmentModal
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handlePublicationSave={this.handlePublicationSave}
                        handleAmendmentUpdate={this.handleAmendmentUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}
                        title={this.state.reviewTitle}
                        type={this.state.type}
                    />}
            </>
        )
    }
}


export default withRouter(ReviewAmendmentDetail);