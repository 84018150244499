import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import API from '../../../api/apis';
import {yyyymmddFormat} from  '../../common/dateFormat';
import { getUserInfo,dateTimeformat, dateStringformat } from '../../../utility/helperFunctions';
import Loader from '../../common/loader/Loader';

class ReviewProtocolModal extends React.Component {
    state = {
        compoundList: [],
        hasChanged: false,
        submission:"",
        submissionId: this.props.submissionId,
        reforcastTitle:"",
        approval:"Awaiting Review",
        title:this.props.title,
        type:this.props.type,
        reviewNumber:"",
        isLoader:true,
        date_of_review:null
    }
    async componentDidMount() {
        const { viewEditData, viewEditFlag } = this.props;
       
        if (viewEditFlag === "edit") {
            viewEditData["date_of_review"] = viewEditData.date_of_review != "" && viewEditData.date_of_review != null ?
       dateStringformat(viewEditData.date_of_review) : null;
            viewEditData["does_protocol_match_details"]=viewEditData.does_protocol_match_details===true?"Yes":"No";
       viewEditData["safety_changes_required"]=viewEditData.safety_changes_required===true?"Yes":"No";
       viewEditData["do_you_have_any_recommended_changes"]=viewEditData.do_you_have_any_recommended_changes===true?"Yes":"No";
       viewEditData["support_for_protocol"]=viewEditData.support_for_protocol===true?"Yes":"No";
            viewEditData["createdby"] = viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
            viewEditData["updatedby"] = viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
            this.setState({
               
                ...this.state,
                submission:`Submission- ${viewEditData.submission_id}`,
                reviewNumber:`R-${viewEditData.id}`,
                ...viewEditData,
                isLoader:false
            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
         
    }

    get inputFieldsArray() {
        return   [
            {
                label: "Review Number ",
                type: "text",
                disabled:true,
                name: "reviewNumber",
               
            }, {
            label: "Review Title",
            type: "text",
            disabled:true,
            name: "title",
           
        },
        {
            label: "Record Type",
            type: "text",
            disabled:true,
            name: "type",
           
        },
        
        {
            label: "Submission",
            type: "text",
            disabled:true,
            name: "submission",
           
        },
        {
            label: "Type Of Review",
            type: "text",
            disabled:true,
            name: "type",
           
        },
        {
            label: "Date of Review",
            type: "date",
            placeholder: "Enter Review Date",
            name: "date_of_review",
           
        },
            {
                label: "Name of Medical Reviewer or Committee",
                type: "text",
                name: "name_medical_reviewer_committee",
               
            },
            
            {
                label: "Does protocol match details in proposal?",
                type: "dropdown",
                name: "does_protocol_match_details",
                placeholder: "Select Publication Delayed",
                options: ["Yes", "No"]
            },
            {
                label: "State difference from the proposal",
                type: "textarea",
                name: "difference_from_the_proposal",
               
            },
            {
                label: "Safety/product related changes required?",
                type: "dropdown",
                name: "safety_changes_required",
                placeholder: "Select Result Objective Inclusion",
                options: ["Yes", "No"]
            },    
            {
                label: "What safety language is missing?",
                type: "textarea",
                name: "safety_language_missing",
               
            }, 
            {
                label: "Recommendations for protocol?",
                type: "dropdown",
                name: "do_you_have_any_recommended_changes",
                placeholder: "Select Publication Delayed",
                options: ["Yes", "No"]
            },
            {
                label: "Provide your recommendations",
                type: "textarea",
                name: "provide_recommendations",
               
            }, 
            {
                label: "Approved?",
                type: "dropdown",
                name: "support_for_protocol",
                placeholder: "Select Result Objective Inclusion",
                options: ["Yes", "NO"]
            },
            {
                label: "Comments",
                type: "textarea",
                placeholder: "write comments...",
                name: "comments",
               
            },
           
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
           disabled:true
        }
        ,{
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
           disabled:true
        }
           
        ];
    }


    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else
        { this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
        });
    }
    getHtmlFromEditorValue = (val) => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            lillyFeedback: {...this.state.lillyFeedback, [e]: this.getHtmlFromEditorValue(val)}
        });
    };

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={((e)=>{this.onInputChange(e,field)})}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleReforecastSave, viewEditFlag, handleProtocolUpdate} = this.props;
        const { submissionId } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg">
                {this.state.isLoader=== true ?
        <Loader /> :  <div> <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit Protocol Review` :  "New Reforecast"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                                viewEditFlag === "edit" ?
                                    <Button variant="primary" onClick={() => handleProtocolUpdate(this.state)}>
                                        Save
                     </Button> : <Button variant="primary" onClick={() => handleReforecastSave(this.state)}>
                                        Save
                    </Button>
                        }

                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default ReviewProtocolModal;