import React from "react";
import {  Modal, Row } from 'react-bootstrap';
import API from '../../api/apis';
import Button from "../common/Button";
import { showMessage } from "../ToReviewDetails/commonFunctions";

export default class SupportModal extends React.Component {

    handleSupportStatus = async (currentRowId) => {
        let settings={
           
            icon: "success",
            button: "Ok",
        };
        try {
            let formbody = {
                "id": currentRowId ? currentRowId : 0,
                "is_support": true
            }
            let res = await API.post(`/reforecastOptimization/changeSupportStatus`, formbody);
            settings.text= "Submitting Support Has Been Successfully Processed";
            
           
        }
        catch (error) {
            console.log("error", error);
            settings.text= "Failed!";
            settings.icon= "error";
        }

        showMessage(settings,(e)=>{this.props.handleSupportModalShowHide(false, null);});
    }

    render() {
        const { handleSupportModalShowHide, showHideSupportModal, currentRowId } = this.props;

        return (
            <div>
                <Modal show={showHideSupportModal} size="md" className="modal-mdx"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div >
                        <Modal.Header className="border-0 pt-5" >
                            <Modal.Title className="w-100 d-flex justify-content-center">
                                <h5  class='modal-title text-center w-100'>
                                    <b>Action to Support this Request</b>
                                </h5>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="pl-5 pr-5" >
                           <p>
                                When submitting support you are sharing your approval or ok for this reforecast item.
                           </p>
                           <p> Please click Submit Support to officially give your support for this reforecast.</p>
                        </Modal.Body>

                        <Modal.Footer className="w-100 d-flex justify-content-center border-0 pb-5">
                            <Button onClick={() => handleSupportModalShowHide(false, null)} className="mr-3 p-3" style="danger">
                                <div className='d-flex align-items-center text-dark'>
                                    <span>CANCEL</span>
                                </div>
                            </Button>
                            <Button onClick={() => this.handleSupportStatus(currentRowId)} className="p-3" style="success">
                                <div className='d-flex align-items-center text-dark'>
                                    <span class="material-icons mr-1">send</span>
                                    <span>SUBMIT SUPPORT</span>
                                </div>
                            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        )
    }
};
