import React, { Component } from 'react'
import { THERAPEUTIC_AREA, REQUEST_TYPE, INDICATIONS } from '../../../consts';
import { Button, Col, Row, Card, Form } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../../common/sticky-nav/StickyNav';
import InputField from '../../common/input-fields/InputField';
// import History from './History';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../../common/loader/Loader';
import { yyyymmddFormat } from '../../common/dateFormat';
import FileComponent from '../../common/file-component/FileComponent';
import UserContext from '../../../context/UserContext';
import swal from 'sweetalert';
import { getUserInfo, dateTimeformat, uploadFile, dateStringformat, dateChange, enableWFActions } from '../../../utility/helperFunctions';
import ExpensesModal from './ExpensesModal';
import HistoryFile from './HistoryFile';
import axios from 'axios';



class ExpensesDetails extends Component {
    static contextType = UserContext;
    state = {
        externalurl :  "http://localhost:1337",

        showLoader: false,
        showLoaderUpload: false,
        action: "",
        loadTable: true,
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        hasChanged: false,
        submissionId: "",
        expensesId:"",
        currencyList: [],
        fileList: [],
        selectedFile: null,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        auditData:[],
        usersList:[],
        trailData:[],
        localStorageData:[],
        date_received:null
    }
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        }

    ];
    async getExternalUrl() {
        let response ='';
        try {
             response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
        externalurl : (response && response.data) ? response.data.exturl : "http://localhost:1337",
    })
    }
  
    async componentDidMount() {
       await this.getExternalUrl();
        await this.getCurrencyList();
        await this.getExpenseByExpenseId(this.props.match.params.expensesId);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({            
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                delete: selectedRole.includes("administrator"),
            },
        });
        await this.getAuditList(this.props.match.params.expensesId)

    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getCurrencyList = async () => {
        try {
            const currencyList = await API.get(`seed/currency/data`);
            currencyList.data.data?.map(item => item["name"] = item.value + " - " + item.name);
            this.setState({
                currencyList: currencyList.data.data,
                // showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                // showLoader: false
            })
        }
    }
    getExpenseByExpenseId = async (id) => {
        try {
            const expenseDetails = await API.get(`trial/getbyid/${id}`);
            const viewEditData = {...expenseDetails.data};
            expenseDetails.data["date_received"] = expenseDetails.data.date_received != "" && expenseDetails.data.date_received != null ?
                dateStringformat(expenseDetails.data.date_received) : null;
            expenseDetails.data["probability"] = expenseDetails.data.probability != "" && expenseDetails.data.probability != null ?
                expenseDetails.data.probability + "%" : null;
            expenseDetails.data["currency"] = this.state.currencyList.filter(item => item.id === expenseDetails.data.currency_iso_code)[0];
            expenseDetails.data["submission_id"] = "Submission-" + expenseDetails.data.submissionId;
            expenseDetails.data["createdby"] = expenseDetails.data.created_by?.length ? await getUserInfo(expenseDetails.data.created_by) + "  " + dateTimeformat(expenseDetails.data.createdAt) : "";
            expenseDetails.data["updatedby"] = expenseDetails.data.updated_by?.length > 0 ? await getUserInfo(expenseDetails.data.updated_by) + "  " + dateTimeformat(expenseDetails.data.updatedAt):expenseDetails.data.createdby ;
            this.state.submissionId=expenseDetails.data.submissionId;
            expenseDetails.data["item_1_cost1"] =expenseDetails.data.currency !== null && expenseDetails.data.item_1_cost !== null ? expenseDetails.data.currency?.value + " " + expenseDetails.data.item_1_cost.replace(/\d(?=(\d{3})+\.)/g, '$&,'):"";
            expenseDetails.data["item_2_cost1"] =expenseDetails.data.currency !== null && expenseDetails.data.item_2_cost !== null ? expenseDetails.data.currency?.value + " " + expenseDetails.data.item_2_cost.replace(/\d(?=(\d{3})+\.)/g, '$&,'):"";
            expenseDetails.data["item_3_cost1"] =expenseDetails.data.currency !== null && expenseDetails.data.item_3_cost !== null ? expenseDetails.data.currency?.value + " " + expenseDetails.data.item_3_cost.replace(/\d(?=(\d{3})+\.)/g, '$&,'):"";
            expenseDetails.data["item_4_cost1"] =expenseDetails.data.currency !== null && expenseDetails.data.item_4_cost !== null ? expenseDetails.data.currency?.value + " " + expenseDetails.data.item_4_cost.replace(/\d(?=(\d{3})+\.)/g, '$&,'):"";
            expenseDetails.data["item_5_cost1"] =expenseDetails.data.currency !== null && expenseDetails.data.item_5_cost !== null ? expenseDetails.data.currency?.value + " " + expenseDetails.data.item_5_cost.replace(/\d(?=(\d{3})+\.)/g, '$&,'):"";
            expenseDetails.data["total_cost1"] =expenseDetails.data.currency !== null && expenseDetails.data.total_cost1 !== null ? expenseDetails.data.currency?.value + " " + expenseDetails.data.total_cost.replace(/\d(?=(\d{3})+\.)/g, '$&,'):"";
            expenseDetails.data["estimated_cost"] =expenseDetails.data.currency !== null && expenseDetails.data.estimated_cost !== null ? expenseDetails.data.currency?.value + " " + expenseDetails.data.estimated_cost.replace(/\d(?=(\d{3})+\.)/g, '$&,') : "";
            this.setState({
                ...expenseDetails.data,
                // showLoader: false,
                viewEditData:viewEditData
            });
            await this.getFilesList();
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                // showLoader: false
            })
        }
    }
    getFilesList = async () => {
        try{
            this.setState({
                showLoader: true,
                action: "fetching",
                loadTable: false
            })
            let res = await API.get(`/user/proposals3/getByid/Expense_${this.state.trial_expense_name}/${this.state.submissionId}`);
            this.setState({
                loadTable : true ,
                showLoader : false,
                fileList : res.data?res.data : []
            });
        }catch(e){
            console.log(e);
            this.setState({showLoader : false})

        }
        console.log("fileList",this.state.fileList)
    }

    get inputFieldsArray() {
        return [
            {
                label: "Expense Title *",
                type: "text",
                name: "expense_title",
                disabled: true,
            }, {
                label: "Total Cost",
                type: "text",
                name: "total_cost1",
                disabled: true,
                edit: true
            },
            {
                label: "Estimated Cost",
                type: "text",
                name: "estimated_cost",
                disabled: true,
            },
            {
                label: "Invoice #",
                type: "text",
                name: "invoice",
                disabled: true,
            },
            {
                label: "Probability",
                type: "text",
                name: "probability",
                disabled: true,
            },
            {
                label: "Date Received",
                name: "date_received",
                type: "date",
                disabled: true,
            },
            {
                label: "Vendor #",
                type: "text",
                name: "vendor",
                disabled: true
            },
            {
                label: "[EXT] Comments",
                name: "comments",
                type: "textarea",
                disabled: true
            }, {
                label: "Vendor Name",
                name: "vendor_name",
                type: "text",
                disabled: true
            }, {
                label: "Submission",
                type: "text",
                placeholder: "Select Submission",
                name: "submission_id",
                disabled: true
            }, {
                label: "Approval",
                type: "text",
                name: "approval",
                disabled: true,

            }, {
                label: "Converted Currency",
                name: "converted_curreny",
                type: "number",
                disabled: true
            }, {
                label: "Currency",
                type: "searchableDropdown",
                placeholder: "Select Currency",
                name: "currency",
                options: this.state.currencyList,
                onSelect: this.onSearchSelect,
                disabled: true
            }, {
                label: "Last Updated By",
                type: "text",
                name: "last_updated_by",
                disabled: true,
            }, {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled: true,
                edit: true
            }, {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
                disabled: true,
                edit: true
            },
            {
                type: "alert",
                label: "Expenses",
                variant: "secondary"
            }, {
                label: "[EXT] Item #1 Description",
                type: "text",
                name: "item_1_description",
                disabled: true
            },
            {
                label: "[EXT] Item #4 Description",
                type: "text",
                name: "item_4_description",
                disabled: true
            }, {
                label: "[EXT] Item #1 Frequency",
                type: "number",
                name: "item_1_frequency",
                disabled: true
            }, {
                label: "[EXT] Item #4 Frequency",
                type: "number",
                name: "item_4_frequency",
                disabled: true
            }, {
                label: "Item #1 Cost",
                type: "text",
                name: "item_1_cost1",
                disabled: true
            }, {
                label: "Item #4 Cost",
                type: "text",
                name: "item_4_cost1",
                disabled: true
            }, {
                label: "[EXT] Item #2 Description",
                type: "text",
                name: "item_2_description",
                disabled: true
            }, {
                label: "[EXT] Item #5 Description",
                type: "text",
                name: "item_5_description",
                disabled: true
            }, {
                label: "[EXT] Item #2 Frequency",
                type: "number",
                name: "item_2_frequency",
                disabled: true
            }, {
                label: "[EXT] Item #5 Frequency",
                type: "number",
                name: "item_5_frequency",
                disabled: true
            }, {
                label: "Item #2 Cost",
                type: "text",
                name: "item_2_cost1",
                disabled: true
            }, {
                label: "Item #5 Cost",
                type: "text",
                name: "item_5_cost1",
                disabled: true
            }, {
                label: "[EXT] Item #3 Description",
                type: "text",
                name: "item_3_description",
                disabled: true
            }, {
                label: "",
                type: "",
                variant: "secondary",
            },
            {
                label: "[EXT] Item #3 Frequency",
                type: "number",
                name: "item_3_frequency",
                disabled: true
            },
            {
                label: "alert",
                type: "",
                name: "",

            }, {
                label: "Item #3 Cost",
                type: "text",
                name: "item_3_cost1",
                disabled: true
            }
        ];
    }


    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else 
        {this.setState({ hasChanged: true })
        this.setState({ [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({ hasChanged: true })
        this.setState({
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={((e)=>this.onInputChange(e,field))}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.submission_id.split("-")[1]}`)
    }

   

    changeShowState = async() => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false,
        });
        await this.getExpenseByExpenseId(this.props.match.params.expensesId);
        this.getAuditList(this.props.match.params.expensesId)
    }

    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }

    uploadFileFunction = async () => {
        const formData = new FormData();
        formData.append(
            'file',
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        try {
            this.setState({
                showLoaderUpload : true
            })
            // const res = await API.post(`/user/multiple/upload/Expense/${this.state.submissionId}/${this.props.match.params.expensesId}`, formData);
            let fileUrl = `${this.state.externalurl}/fileupload/multiple/Expense/${this.state.submissionId}/${this.props.match.params.expensesId}`;
            let res = await axios.post(fileUrl, formData, {
                headers: {                   
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                },
            });
            this.setState({
                selectedFile: null,
                successFile: true,
                fileMessage: "File uploaded!",
                fileList:[],
                showLoaderUpload : false

            })
        } catch (e) {
            this.setState({
                selectedFile: null,
                errorFile: true,
                showLoaderUpload : false
            })
            console.log(e);
        }
    }
    handleDelete = async (fileName) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({
                            showLoader : true,
                            action :"delete"
                        })
                        const res = API.post(`/user/multiple/delete/Expense/${this.state.submissionId}/${this.props.match.params.expensesId}/${fileName}`, {updated_by : this.context.userSystemId});
                        this.setState({
                            successFile: true,
                            fileMessage: "File deleted!",                        
                            showLoader : false                        
                        })

                    }
                    catch (err) {
                        this.setState({ 
                            showLoader  : false,
                            errorModal: true });
                    }

                }
            });

    }

    handleDownload = async (fileName) => {
        try {
            this.setState({
                showLoader : true,
                action : "download"
            })
           /* const res = await API.get(`/user/multiple/download/getByid/Expense/${this.state.submissionId}/${this.props.match.params.expensesId}/${fileName}`,{
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', decodeURIComponent(fileName));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                showLoader : false,
                successFile: true,
                fileMessage:"File Downloaded Successfully!"
            })*/
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Expense/${this.state.submissionId}/${this.props.match.params.expensesId}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                        
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        showLoader : false,
                        successFile: true,
                        fileMessage:"File Downloaded Successfully!"
                    })
                });
        } catch (e) {
            this.setState({
                showLoader : false,
                errorFile: true
            })
            console.log(e);
        }
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
            this.setState({ showHideModal: flag, viewEditFlag });
    }
    handleExpensesUpdate = async (state) => {
        delete state.currencyList;
        delete state.showLoader;
        state["date_received"] = state.date_received != "" && state.date_received != null ?
            dateChange(state.date_received) : null;
        state["probability"] = state.probability != "" && state.probability != null ?
            parseInt(state.probability) : null;
        state.updated_by = this.context.userSystemId;
        state.submissionId = this.state.submissionId;
        try {
            const res = await API.post("trial/update", state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)
       
        this.setState({usersList: res.data});
    }
    getAuditList = async (id) => {
        try {
            const temp = [];
            const res = await API.get(`audit/trialexpense/${id}`)
            console.log(res)
            await this.getTrailList();

            await this.getUserList()
            this.state.trailData.map(x => {
                res.data.map(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        let fieldLabels = ["updated_by"]
                        if (fieldLabels.includes(y.field)) {
                            let newValueText = y.new_value != null ? this.state.usersList.filter(x => x.system_id === (y.new_value))[0] :"";
                            y.new_value =newValueText!=""? newValueText?.first_name + " " + newValueText?.last_name :"";
                            let oldValueText = y.old_value != null ?  this.state.usersList.filter(x => x.system_id === (y.old_value))[0] :"";
                            y.old_value = oldValueText!="" ? oldValueText?.first_name + " " + oldValueText?.last_name : "";
                            temp.push(y);
                        }
                        else if(y.field==="created_at"){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):"";
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                             temp.push(y);
                         }
                        else {
                            y.new_value = y.new_value != null ? (y.new_value) : "";
                            y.old_value =  y.old_value != null ? (y.old_value) :""
                            temp.push(y);
                        }
                    }

                })
            })
            let sortedReviews = temp.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            this.setState({
                auditData: sortedReviews,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/trialexpense`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }

    render() {
        const {showLoaderUpload  , loadTable , action ,  showLoader, showHideModal, viewEditFlag, viewEditData, success_body, errorModal } = this.state;
    if (!this.state.submission_id)
        return <Loader />
        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                            {""}{this.state.submission_id}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "60%" }}>Expenses - {this.props.match.params.expensesId} </h4>
                    {this.state.access.edit && enableWFActions()&& <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                                {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                                        Save
                                    </Button>
                                </div> */}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="data-cards">
                        <Card.Body>
                            <Tabs defaultActiveKey="files">
                                <Tab eventKey="files" title="Files">
                                {this.state.access.edit && enableWFActions()&&<Row>
                                        <Col sm={8}>
                                            <Form.File
                                                id="custom-file"
                                                className="custom-file-label"
                                                onChange={this.onFileChange}
                                                label={this.state.selectedFile?.name}
                                                custom
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            {showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </Button>}
                                            {!showLoaderUpload && <Button variant="secondary" onClick={()=> uploadFile(this.state.selectedFile,this.state.fileList,this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>}
                                        </Col>
                                    </Row>}
                                    <br />

                                   {this.state.access.edit&& enableWFActions() &&  <small className="form-text text-muted">*Maximum size for file upload is 2MB.</small>}
                                    {showLoader &&
                                        <Row>
                                            <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                                                <span className="visually-hidden">File  {action} in progress </span>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </div>

                                        </Row>}
                                    <br />
                                    {this.state.fileList.length > 0 && loadTable &&
                                        <FileComponent data={this.state.fileList}
                                            accessDelete = {this.state.access.delete}

                                            handleDelete={this.handleDelete}
                                            handleDownload={this.handleDownload} />
                                    }

                                </Tab>
                                {/* <Tab eventKey="history" title="History">
                                    <History historyData={this.historyData} historyTitle="Trial Expense History" />
                                </Tab> */}
                            </Tabs>
                        </Card.Body>
                    </Card>
                    <Card className="data-cards">
                        <Card.Body>
                            <Tabs defaultActiveKey="history">
                                <Tab eventKey="history" title="History">
                               
                           <HistoryFile historyData={this.state.auditData}  historyTitle="Expense History"/>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {
                        showHideModal &&
                        <ExpensesModal
                            showHideModal={showHideModal}
                            handleModalShowHide={this.handleModalShowHide}
                            handleExpensesSave={this.handleExpensesSave}
                            handleExpensesUpdate={this.handleExpensesUpdate}
                            viewEditFlag={viewEditFlag}
                            viewEditData={viewEditData}
                            submissionId={this.state.submissionId}
                        />
                    }
            {this.state.successModal &&
                <BootstrapModal
                    show={this.state.successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.successFile &&
                <BootstrapModal
                    show={this.state.successFile}
                    header="Success"
                    body={this.state.fileMessage}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorFile &&
                <BootstrapModal
                    show={this.state.errorFile}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }

        </>
    }
}

export default withRouter(ExpensesDetails);
