import React from "react";
import { Col, Row } from 'react-bootstrap';
import InputField from '../common/input-fields/InputField'
import API from '../../api/apis';
import { dateChange } from "../../utility/helperFunctions";
import {  yyyymmddFormat } from '../common/dateFormat';
import UserContext from '../../context/UserContext';
import BootstrapModal from "../common/modal/BootstrapModal";
import { isCoordinator } from '../ToReviewDetails/commonFunctions';
import Button from "../common/Button";
import { formatNumber } from "./FormatNumber";
import { iirDateFormatEx } from "../../utility/dateHelper";


export default class Overview extends React.Component {
    static contextType = UserContext
    state = {
        financeDetails: [],
        _isCoordinator: false,
        currencyDetails: [],
        patchObject: {}
    }
    get inputFieldsArray() {
        return [
            // {
            //     label: "Lilly Support ",
            //     type: "dropdown",
            //     placeholder: "Select Support",
            //     options: [
            //         "Grants Only",
            //         "Drug Only",
            //         "Drug & Grant"
            //     ],
            //     name: "lilly_support",
            // },
            {
                label: "Total Grant Amount",
                type: "text",
                placeholder: "Enter Total Grant",
                name: "total_grant",
                helptext: "Total budget for Trial",
                customerror: (!this.state.patchObject.total_grant) ? "" : (new RegExp(/^\d*\.?\d*$/).test(this.state.patchObject.total_grant + "") ? "" : "Only numbers and decimals are allowed"),
            },{
                label: "PO # or any Account ID that is used",
                type: "text",
                placeholder: "Enter PO #",
                name: "po_number",
            },
            
            {
                label: "Current Overall Spend",
                type: "text",
                name: "current_overall_spend",
                disabled: true,
                helptext: "Total spent on Trial up to today's date"

            },{
                label: "Additional PO#",
                type: "text",
                placeholder: "Enter Additional PO#",
                name: "additional_po_number",
            },
            
            {
                label: "Current Patient Spend",
                type: "text",
                name: "current_patient_spend",
                disabled: true,
                helptext: "Total spent on patient enrollment up to today's date"

            },{
                label: "PO # Delivery Date",
                type: "date",
                onChange: (e) => this.onDateChange(e, "po_delivery_date"),
                placeholder: "Enter PO# Delivery Date",
                name: "po_delivery_date",
            },
            
            {
                label: "Total Percent to Spend",
                type: "text",
                name: "total_percent_to_spend",
                helptext: "Current Spent compared to Total Grant",
                disabled: true
            },{
                label: "Cost Center",
                type: "text",
                placeholder: "Cost Center",
                name: "cost_center",
            },
            
            // {
            //     label: "",
            //     type: "text",
            //     disabled:true,
            //     name: "",
            //     hidden:true
            // },
            {
                label: "Local Currency",
                type: "searchableDropdown",
                placeholder: "Select Currency",
                options: this.state.currencyDetails,
                onSelect: this.onSearchSelect,
                name: "currency",
            },
            // {
            //     label: "",
            //     type: "text",
            //     disabled:true,
            //     name: "",
            //     hidden:true
            // },
            {
                label: "Plan Rate",
                type: "text",
                name: "planned_rate",
                helptext: "Local currency divided by plan rate of current year = performance dollar",
                disabled: true
            },
            {
                label: "Notes",
                type: "textarea",
                rows:5,
                name: "enrollment_notes",
            }
        ]
    }
    async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.getfinancedata();

        this.setState({
            _isCoordinator,

        });

    }

    getcurrencyDetails = async () => {
        let currency_id = this.state;
        let currency_code = this.state;


        let res = await API.get(`finance/currencyList`)
        res.data?.map(item => item["name"] = item.value + " - " + item.name);
        if (res && res.data) {
            let currency_id = this.state;

            let currency = res.data.find(item => item.value === this.state.currency_code);
            // let planned_rate = currency?.planned_rate
            let planned_rate=currency?.planned_rate != null  ? (currency?.planned_rate)  : "";

            this.setState({
                currencyDetails: res.data, currency, planned_rate

            })
        }


    }

    getfinancedata = async () => {
        let currency_id = null;
        let res = await API.get(`/finance/overviewDetail/${this.props.submissionId}`)
        if (res && res.data) {
            let currency_code = res.data.currency_code;
            let current_overall_spend=res.data?.current_overall_spend != null &&  currency_code!= null ? currency_code+" "+formatNumber(res.data.current_overall_spend)  : "";
            let current_patient_spend=res.data?.current_patient_spend != null &&  currency_code!= null ? currency_code+" "+formatNumber(res.data.current_patient_spend)  : "";
            let total_grant=res.data?.total_grant != null &&  currency_code!= null ? currency_code+" "+formatNumber(res.data.total_grant)  : "";
            let total_percent_to_spend = res.data?.total_percent_to_spend != null  ? (res.data.total_percent_to_spend)+" %"  : "";
            let po_delivery_date = res.data?.po_delivery_date != null  ? iirDateFormatEx(res.data?.po_delivery_date)  : "";
            this.setState({
                ...res.data, currency_code, current_overall_spend, current_patient_spend,total_grant, total_percent_to_spend, 
                po_delivery_date
            }, async () => { await this.getcurrencyDetails(); }
            )

        }


    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if(field.label==="Notes"){
                return(
                    <Col sm={12} style={{marginLeft:"-1rem"}} key={field.label}> <InputField
                    {...field}
                    disabled={field.disabled || !this.state._isCoordinator}
                    value={this.state[field.name]}
                    onChange={field.onChange || this.onInputChange}
                    onSelect={field.onSelect || this.onSelectChange}
                /></Col> 
                )
            }else{
            return (
                <Col sm={6} key={field.label}> <InputField
                    {...field}
                    disabled={field.disabled || !this.state._isCoordinator}
                    value={this.state[field.name]}
                    onChange={field.onChange || this.onInputChange}
                    onSelect={field.onSelect || this.onSelectChange}
                /></Col>)}
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
            value: e.value,
            patchObject: { ...this.state.patchObject, currency_id: e.id },
            planned_rate: this.state?.currencyDetails!=null ? (this.state?.currencyDetails.find(item => item.value === e.value).planned_rate ) : "",
        });
    }
    // onCurrencyChange = (e, name) => {
    onSelectChange = (e, eo) => {

        this.setState({
            [eo.name || [eo.target.name]]: e,
            patchObject: { ...this.state.patchObject, [eo.name || [eo.target.name]]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }


    onDateChange = (e, eo) => {

        this.setState({
            [eo]: e,
            patchObject: { ...this.state.patchObject, [eo]: (e) },
        });
    }
    onInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
        });
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });
    }


    onSave = async (state) => {
        let error = this.state.patchObject;

        let allFields = [...this.inputFieldsArray];
        let anyerror = false;

        for (let [key, value] of Object.entries(error)) {
            let exist = allFields.find(p => p.name === key);
            if (exist && exist.customerror) {
                anyerror = true;
            }
        }

        if (anyerror) {
            return false;
        }


        state = {
            submissionId: this.props.submissionId,
            ...state,
            last_modified_by:this.context.userSystemId
        }
        if("po_delivery_date" in state){
            state["po_delivery_date"] = yyyymmddFormat(state["po_delivery_date"]); 
        }


        try {
            const res = await API.post(`/enrollmentOptimization/enrollmentOverview/update`, state);
            this.setState({
                successModal: true,
                success_body: "Updated successfully"
            })
            this.getfinancedata();
            this.getcurrencyDetails();
        }
        catch (err) {
            this.setState({ errorModal: true })
        }




    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }
    render() {
        return (
            <div className="new-flow-modal" >
                <div className="d-flex flex-grow-1 justify-content-end align-items-center mr-4">
                    {this.state._isCoordinator && <Button style="success w-100px" onClick={() => this.onSave(this.state.patchObject)} className="d-flex align-items-center p-2 justify-content-center"><span>SAVE</span></Button>}</div>
                <Row>{this.renderInputFields()}</Row>

                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body={this.state.success_body}
                        changeShowState={this.changeShowState}
                        buttonText="Ok"
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An Error has Occurred!please try again"
                        changeShowState={this.changeShowState}
                    />}
            </div>
        )
    }

}