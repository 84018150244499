import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import InputField from '../common/input-fields/InputField'
import { Card } from 'react-bootstrap';
import ReviewConcept from './review-forms/ReviewConcept';
import ReviewProtocol from './review-forms/ReviewProtocol';
import ReviewAmendment from './review-forms/ReviewAmendment';
import ReviewPublication from './review-forms/ReviewPublication';
import ReviewApplicationProposal from './review-forms/ReviewApplicationProposal';

export default class ReviewDropdown extends Component {

    state = { reviewType: "" };

    reviewDropdown = {
        type: "dropdown",
        placeholder: "Select Review Type",
        name: "review-dropdown",
        options: ["Review Concept", "Review Application Proposal", "Review Protocol", "Review Amendment", "Review Publication"]
    }

    onDropdownChange = (e) => {
        this.setState({ reviewType: e });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.props.location.state.submissionId}`)
    }

    render() {
        const review = this.state.reviewType;
        return (
            <Card className="p-4">

                <div onClick={this.onNavOut} className="back-button">
                    <i className="bi bi-arrow-left">
                        {" "}Submission-4445
                            </i>
                </div>
                <h2 className="text-center">Review Forms</h2>
                <Row className="justify-content-md-center">
                    <Col sm={5}>
                        <InputField {...this.reviewDropdown} value={this.state.reviewType} onSelect={this.onDropdownChange} />
                    </Col>
                </Row>
                <Card.Body>
                    {review === "" && 
                    <div className="m-5 p-5">
                        <p className="text-center"><strong>Please select a review category</strong></p>
                    </div>
                    }
                    {review === "Review Concept" && <ReviewConcept />}
                    {review === "Review Application Proposal" && <ReviewApplicationProposal />}
                    {review === "Review Protocol" && <ReviewProtocol />}
                    {review === "Review Amendment" && <ReviewAmendment />}
                    {review === "Review Publication" && <ReviewPublication />}
                </Card.Body>
            </Card>
        )
    }
}
