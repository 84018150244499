import React, { Component } from 'react'

import { Button, Col, Row, Card } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../sticky-nav/StickyNav';
import InputField from '../input-fields/InputField';
import CompoundCountryModal from './CompoundCountryModal';
import API from '../../../api/apis';
import BootstrapModal from '../modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../loader/Loader';
import UserContext from '../../../context/UserContext';
import { yyyymmddFormat } from '../dateFormat';
import Country from './Country';
import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';
import CompoundModal from '../existing/CompoundModal'
import CountryNew from './CountryNew';


class CompoundExistDetail extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        hasChanged: false,
       id:0,
       name:"",
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        currencyList: [],
      userList:[]
    }
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        }

    ];


    get inputFieldsArray() {
        return [{
            label: "Information *",
            type: "alert",
            varient: "secondary"
        },
        {
            label: "Compound Name *",
            type: "text",
            name: "name",
            disabled:true

        },
        {
            label: "Therapeutic Area",
            type: "text",
            name: "therapeutic_area",
            disabled:true,
            

        },
        // {
        //     label: "Request Type *",
        //     type: "text",
        //     name: "request_type",
        //     disabled:true,
           
        // },
        {
            label: "Sub-Therapeutic Area",
            type: "text",
            name: "sub_therapeutic_area",
            disabled:true

        },
        {
            label: " Research Project Code",
            type: "text",
            name: "research_project_code",
            disabled:true

        },
        {
            label: "LY Number",
            type: "text",
            name: "ly_number",
            disabled:true

        },
        {
            label: "Active to External",
            type: "checkbox",
            name: "active",
            disabled:true

        },
        {
            label: "Retired",
            type: "checkbox",
            name: "is_retired",
            disabled:true

        },
        // {
        //     label: "Part Of Proposal",
        //     type: "checkbox",
        //     name: "is_part_of_proposal",
        //     disabled:true
           
        // },
        // {
        //     label: "LSN#",
        //     type: "text",
        //     name: "lsn",
        //     disabled:true
           
        // },
        // {
        //     label: "PTL",
        //     type: "text",
        //     name: "ptl",
        //     disabled:true
        // },
        // {
        //     label: "PPM",
        //     type: "text",
        //     name: "ppm",
        //     disabled:true
        // },
        {
            label: "Compound Description",
            type: "text",
            name: "compound_description",
            disabled:true
        },
        {
            label: "Lilly Compound?",
            type: "checkbox",
            name: "is_lilly_compound",
        },
        // {
        //     label: "Research Areas of Current Focus - CM",
        //     type: "textarea",
        //     name: "research_areas_of_current_focus_cm",
        //     disabled:true
        // },
        // {
        //     label: "Research Areas of Current Focus - NCM",
        //     type: "textarea",
        //     name: "research_areas_of_current_focus_ncm",
        //     disabled:true
        // },
        {
            label: "Created By",
            type: "text",
            name: "created_by",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updated_by",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }
        ];
    }
    async componentDidMount() {
        await this.getUserList();

        await this.getDrugDeliverSiteById(this.props.location.state.compoundId, this.props.location.state.countryName)
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator"),
                edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator"),
                    edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }


    getDrugDeliverSiteById = async (id) => {
        try {

            const expenseDetails = await API.get(`/seed/compound/getbyid/${id}`);
            console.log("details", expenseDetails)
            const viewEditData ={...expenseDetails.data};
            this.state.name=expenseDetails.data.name;
            this.state.id=expenseDetails.data.id;
            // expenseDetails.data["ppm"]= this.state.userList.find(user => user.system_id === expenseDetails.data.ppm)?.name;
            // expenseDetails.data["ptl"]= this.state.userList.find(user => user.system_id === expenseDetails.data.ptl)?.name ;
            expenseDetails.data["created_by"] =expenseDetails.data.created_by?.length ? await getUserInfo(expenseDetails.data.created_by)+"  "+ dateTimeformat(expenseDetails.data.createdAt) : "";

            expenseDetails.data["updated_by"] =expenseDetails.data.updated_by?.length > 0 ? await getUserInfo(expenseDetails.data.updated_by) + "  " + dateTimeformat(expenseDetails.data.updatedAt):expenseDetails.data.created_by ;
            
            this.setState({
                ...expenseDetails.data,
                showLoader: false,
                viewEditData:viewEditData
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false,
                
            })
        }
    }

    getUserList = async () => {
        const res = await API.get(`user/data`)
        const resultArray = res.data.map(elm => ({ 
            name: `${elm.first_name} ${elm.last_name} - ${elm.system_id}`, 
            value: elm.email,
            system_id:elm.system_id
        }));
        resultArray.unshift({name: " - None", value:""});
        // this.setState({usersList: resultArray});
        this.state.userList=resultArray;
    }
    onInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
        });
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.checked },

        })
    }
    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
        });
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            patchObject: { ...this.state.patchObject, [e]: this.getHtmlFromEditorValue(val) },
            hasChanged: true
        });
    };
    handleReforecastUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
          try {
              const res = await API.post("seed/compound/edit/update", state);
              this.setState({
                  success_body: "Updated successfully!",
                  successModal: true,
                  hasChanged: false,
                  showHideModal: false,
                  viewEditFlag: null,
                  viewEditData: {},
              });
              this.getReforcastDetailsBySubmissionId(this.props.submissionDetails.id)
  
          } catch (err) {
              this.setState({ errorModal: true });
          }
      }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
        window.history.back()
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag});
}


    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getDrugDeliverSiteById(this.props.location.state.compoundId)
    }

    render() {

        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal,deleteModal } = this.state;

        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                        {" "}{this.state.name}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Compound </h4>
                    {this.state.access.edit && <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                              
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="data-cards">
                      <CountryNew compoundName={this.state.name} compoundId={this.props.location.state.compoundId}/>  
                    </Card>
                </Col>
               
               
            </Row>
            {showHideModal && 
            <CompoundModal
            showHideModal={showHideModal}
            handleReforecastSave={this.handleReforecastSave}
            handleReforecastUpdate={this.handleReforecastUpdate}
            handleModalShowHide={this.handleModalShowHide}
            viewEditFlag={viewEditFlag}
            viewEditData={viewEditData}
            
           />}
            {this.state.successModal &&
                <BootstrapModal
                    show={this.state.successModal}
                    header="Success"
                    body="Compound has been Updated!"
                    changeShowState={this.changeShowState}
                />
            }
            {/* {this.state.errorModal &&
                <BootstrapModal
                    show={this.state.errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            } */}
        </>
    }
}

export default withRouter(CompoundExistDetail);
