
import React, { Component } from 'react'
import { Button, Col, Nav, Row } from 'react-bootstrap';
import { NavLink,withRouter } from 'react-router-dom';
import API from '../../../../api/apis';
import { dateTimeformat, getUserInfo ,dateStringformat} from '../../../../utility/helperFunctions';
import DatatableComponent from '../../../common/datatable/DatatableComponent';

class History extends Component {
    state = {
        auditData: [],
        trailData: [],
        Field: "",
        stageData:[],
        usersList:[],
        countryList:[],
        stateList:[],
        approvalData:[],
        refreshAuditRequired: false,
    }
    columns = [{   
        dataField: 'id',
        text: 'id',
        sort: false,
        hidden:true,
        csvExport: false,
    },{
        dataField: 'updatedAtEx',
        text: 'Date',
        sort: true,
        // formatter: (cell, row, rowIndex) => {
        //     return <>
        //         <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
        //         >{row.updatedAt?.length > 0 ? dateTimeformat(row.updatedAt) : ""}</p>

        //     </>
        // }
    }, {
        dataField: 'Field',
        text: 'Field',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.Field}</p>

            </>
        }
    }, {
        dataField: 'updaterEx',
        text: 'User',
        sort: true,
        // formatter: (cell, row, rowIndex) => {
        //     return <>
        //         <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
        //         >{row.updater?.first_name !=null && row.updater?.last_name!=null ?row.updater.first_name + " " + row.updater.last_name : row.creater.first_name + " " + row.creater.last_name}</p>

        //     </>
        // }


    },
    {
        dataField: 'old_valueEx',
        text: 'Original Value',
        sort: true,
        // formatter: (cell, row, rowIndex) => {
        //     return <>
        //         <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
        //         >{row.old_value !=null ? row.old_value:""}</p>

        //     </>
        // }
    }, {
        dataField: 'new_value',
        text: 'New Value',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.new_value !=null ? row.new_value:""}</p>

            </>
        }
    }];
    columnsApprovalData = [
         {
        dataField: 'step_name',
        text: 'Step Name',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <Nav.Link as={NavLink} to={{pathname:`/approvalHistory/detail/${row.id}`}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={(e) => this.handleApprovalDetails(row,e)}>{row.step_name}</Nav.Link>

            </>
        }
    },{
        dataField: 'updatedAt',
        text: 'Date',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.updatedAt?.length > 0 ? dateTimeformat(row.updatedAt) : ""}</p>

            </>
        }
    },{
        dataField: 'approval_status',
        text: 'Status',
        sort: true,

    }, {
        dataField: 'assignedTo',
        text: 'Assigned To',
        sort: true,
       
    }];
    componentDidMount() {
        this.getAuditList(this.props.submissionDetails.id);
        this.getSubmissionStageList(this.props.submissionDetails.id);
        this.getApprovalHistoryList(this.props.submissionDetails.id);
    }
    componentDidUpdate(prevProps) {
        if(this.props.refreshAuditRequired){
            this.getAuditList(this.props.submissionDetails.id);
            this.props.refreshAuditData(false);
        }
    }
    getSubmissionStageList = async (id) =>{
        try{
            const response = await API.get(`submission/stage/information/${id}`)
            // console.log(response.data[0])
            
            if(response&&response.data){
                console.log(response.data,'response.data')
                let stageData = response.data.map((item,index)=>{
                    let { days, hours, minutes, id, stage_name, start_date, submission_id} = item.dataValues;
                    start_date = start_date?.length > 0 ? dateTimeformat(start_date) : "";
                    const time = days +' Days '+ hours + ' Hours ' + minutes + ' Minutes';
                    return { days, hours, minutes, id, stage_name, start_date, submission_id, time};
                });
                this.setState({stageData: stageData || []})
            }else{
                this.setState({ stageData: []});
            }
        }
        catch(err){console.log(err)}
    }
    getApprovalHistoryList = async (id) =>{
        try{
            const response = await API.get(`audit/approvalhistory/getbysubmissionid/${id}`)
            await this.getUserList()
            response.data.map(x=>
               { if(x.assigned_to)
                {
                    let newValueText=x.assigned_to !== null ? this.state.usersList.filter(elm=> elm.system_id===(x.assigned_to))[0] : "" ;
                        x.assignedTo=newValueText!="" ? newValueText?.first_name+" "+newValueText?.last_name : "";
                }})
                
            this.setState({approvalData:response.data,
            })
        }
        catch(err){console.log(err)}
    }
    handleApprovalDetails=(e)=>{
        this.props.history.push(`/approvalHistory/detail/${e.id}`,{approvalId:e.id});
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)
       
        this.setState({usersList: res.data});
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/getall");
       
        this.setState({ countryList: res.data.data||[] });

    }
    getStateList = async () => {
      
            const res = await API.get(`seed/state/list`);
            this.setState({ 
                stateList: res.data,
               
            });
        
    }
    getAuditList = async (id) => {
        try {
            let temp = [];
            
            const res = await API.get(`audit/submission/${id}`)
            console.log(res)
            await this.getTrailList();
              await this.getUserList();
              await this.getCountryList();
              await this.getStateList();
            this.state.trailData.map(x => {
                res.data.map(y => {
                    if(!y.updater){
                        y.updater={};
                    }
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        let fieldLabels=["budget_complete_actual","budget_complete_estimate",
                        "fpet_actual","fpet_estimate","fpv_actual","fpv_estimate","fsr_actual",
                        "fsr_estimate","initial_ct_material_ship_actual","initial_ct_material_ship_estimate",
                        "initial_irb_approval_actual","initial_irb_approval_estimate","interim_report_2_actual",
                        "interim_report_2_estimate","interim_report_actual","interim_report_estimate","lpet_actual",
                        "lpet_estimate","lpetx_actual","lpetx_estimate","lpv_actual","lpv_estimate",
                        "protocol_approved_actual","protocol_approved_estimate","protocol_received_actual","protocol_received_estimate"]
                        if(y.field==="assign_to"){
                           let newValueText=y.new_value!=null ? this.state.usersList.filter(x=> x.system_id===(y.new_value))[0] : "" ;
                        y.new_value=newValueText!="" ? newValueText?.first_name+" "+newValueText?.last_name : "";
                      let  oldValueText=y.old_value!=null ? this.state.usersList.filter(x=> x.system_id===(y.old_value))[0] :"";
                        y.old_value=oldValueText!="" ? oldValueText?.first_name+" "+oldValueText?.last_name : "";
                            temp.push(y);
                        }
                        else if(y.field==="org_country")
                        {
                            let newValueText=y.new_value!=null ? this.state.countryList.filter(x=> x.id+""===(y.new_value))[0] :"" ;
                        y.new_value=newValueText!="" ? newValueText.name : "";
                      let  oldValueText=y.old_value!= null ? this.state.countryList.filter(x=> x.id+""===(y.old_value ))[0] :"";
                        y.old_value=oldValueText!="" ? oldValueText.name :"";
                            temp.push(y);
                        }
                        else if(y.field==="org_state")
                        {
                            let newValueText=y.new_value!=null ? this.state.stateList.filter(x=> x.id+""===(y.new_value))[0] :"" ;
                        y.new_value=newValueText!="" ? newValueText.name : "";
                      let  oldValueText=y.old_value!= null ? this.state.stateList.filter(x=> x.id+""===(y.old_value ))[0] :"";
                        y.old_value=oldValueText!="" ? oldValueText.name : "";
                            temp.push(y);
                        }
                         else if(y.field==="created_at"){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):"";
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                                 temp.push(y);
                             }
                             else if(fieldLabels.includes(y.field)){
                                y.new_value=y.new_value!== null ? dateStringformat(y.new_value):"";
                                y.old_value=y.old_value!== null ? dateStringformat(y.old_value):"";
                                     temp.push(y);
                                 }
                   else {
                    y.new_value=(y.new_value);
                    y.old_value=(y.old_value)
                       temp.push(y);}
            
                    }

                })
            })
            let sortedReviews = temp.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            sortedReviews= sortedReviews.map((item,index)=>{
                let updatedAtEx = item.updatedAt?.length > 0 ? dateTimeformat(item.updatedAt) : "";
                let updaterEx = item.updater?.first_name !=null && item.updater?.last_name!=null ?item.updater.first_name + " " + item.updater.last_name : item.creater.first_name + " " + item.creater.last_name;
                let old_valueEx = item.old_value !=null ? item.old_value:""
                return{updatedAtEx, updaterEx, old_valueEx, ...item};
            });
            this.setState({
                auditData: sortedReviews,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/submission`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    columnsStage = [{
        dataField: 'id',//id
        text: 'Stage Information',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.id != null ?`Stage Information-${row.id}`:""}</p>

            </>
        }
    }, {
        dataField: 'stage_name',
        text: 'Stage Name',
        sort: true
    }, {
        dataField: 'start_date',
        text: 'Start Date',
        sort: true,
        // formatter: (cell, row, rowIndex) => {
        //     return <>
        //         <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
        //         >{row.dataValues.start_date?.length > 0 ? dateTimeformat(row.dataValues.start_date) : ""}</p>

        //     </>
        // }

    },
    {
        dataField: 'time',//days,hours,minutes
        text: 'Time At This Stage',
        sort: true,
        // formatter: (cell, row, rowIndex) => {
        //     return <>
        //         <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
        //         >{`${row.dataValues.days} Days ${row.dataValues.hours} Hours ${row.dataValues.minutes} Minutes`}</p>

        //     </>
        // }
    }];
    data = [
        {
            'Stage Information': "Stage Information-6065",
            'Stage Name': "Concept",
            'Start Date': "5/21/2020 5:45 AM",
            "Time At This Stage": "0 Days 0 Hours 20 Mins"
        },
        {
            'Stage Information': "Stage Information-6066",
            'Stage Name': "Concept",
            'Start Date': "5/21/2020 5:45 AM",
            "Time At This Stage": "0 Days 0 Hours 20 Mins"
        },
        {
            'Stage Information': "Stage Information-6067",
            'Stage Name': "Concept",
            'Start Date': "5/21/2020 5:45 AM",
            "Time At This Stage": "0 Days 0 Hours 20 Mins"
        },
        {
            'Stage Information': "Stage Information-6068",
            'Stage Name': "Concept",
            'Start Date': "5/21/2020 5:45 AM",
            "Time At This Stage": "0 Days 0 Hours 20 Mins"
        }
    ];

  
    
    render() {
        let sTitle=`Submission Stage Information(${this.state.stageData.length})`;
        let shTitle=`Submission History(${this.state.auditData.length})`;
        let ahTitle=`Approval History(${this.state.approvalData.length})`;
        return <>
            <Row>
                <DatatableComponent title={sTitle} id="stageInformation" data={this.state.stageData} columns={this.columnsStage} rowEvents={this.rowEvents} pagination="true" />
            </Row>
            <Row className='mt-5'>
                <DatatableComponent  title={shTitle} id="submissionHistory" data={this.state.auditData} columns={this.columns} rowEvents={this.rowEvents} pagination="true" />
            </Row>
            <Row  className='mt-5'>
                <DatatableComponent  title={ahTitle} id="approvalHistory" data={this.state.approvalData} columns={this.columnsApprovalData} rowEvents={this.rowEvents} pagination="true" />
            </Row>
        </>
    }
}
export default withRouter(History)