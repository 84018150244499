import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import API from '../../../../api/apis';
import UserContext from '../../../../context/UserContext';
import { dateTimeformat, enableWFActions } from '../../../../utility/helperFunctions';
import DatatableComponent from '../../../common/datatable/DatatableComponent';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import ReviewAmendmentModal from '../ReviewAmendmentModal';
import ReviewConceptModal from '../ReviewConceptModal';
import ReviewProposalModal from '../ReviewProposalModal';
import ReviewProtocolModal from '../ReviewProtocolModal';
import ReviewPublicationModal from '../ReviewPublicationModal';

class ReviewFile extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: true,
            edit: false,
            delete: false,
        },
        reviewList: [],
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        protocolFlag: false,
        conceptFlag: false,
        amendmentFlag: false,
        proposalFlag: false,
        publicationFlag: false,
        title: "",
        type: ""
    }

    columns = [
        {
            dataField: 'title',
            text: 'Review Title ',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>
                    <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                        onClick={() => this.handlePublicationDetails(row)}>{row.title}</p>
                </>
            }
        }, {
            dataField: 'Type',
            text: 'Record Type',
            sort: true
        }, {
            dataField: 'name_medical_reviewer_committee',
            text: 'Name of Reviewer',
            sort: true
        }, {
            dataField: 'date_of_review',
            text: 'Date of Review',
            sort: true
        },
        {
            dataField: '',
            text: "Actions",
            headerClasses: "width-90",
            formatter: (cell, row, rowIndex) => {
                var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
                var visibilityDelete = this.state.access.delete ? "visible" : "hidden";

                return <>
                    {enableWFActions() && <i className="bi bi-trash-fill" onClick={() => this.handlePublicationDelete(row)}
                        style={{ visibility: visibilityDelete , fontSize: "1.2rem", color: "black", padding: ".1rem", float: "right" }}></i>}
                    {enableWFActions() && <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row)}
                        style={{ visibility: visibilityEdit ,  fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "right" }}></i>}
                </>
            }
        }];


    componentDidMount() {        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  true,
                edit: selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
        this.getSubmissionStageList(this.props.submissionId);

    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:true,
                    edit:  selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    
    }
    handleModalShowHide = async (flag, viewEditFlag, row) => {
        if (viewEditFlag === "edit" && row && row.id && row.Type === "Protocol") {
            await this.getPublicationById(row.id, row.Type);
            this.state.type = row.Type;
            this.state.title = row.title;
            this.setState({ showHideModal: flag, viewEditFlag, protocolFlag: true })
            return true;
        }
        else if (viewEditFlag === "edit" && row && row.id && row.Type === "Concept") {
            await this.getPublicationById(row.id, row.Type);
            this.state.type = row.Type;
            this.state.title = row.title;
            this.setState({ showHideModal: flag, viewEditFlag, conceptFlag: true })
            return true;
        }
        else if (viewEditFlag === "edit" && row && row.id && row.Type === "Publication") {
            await this.getPublicationById(row.id, row.Type);
            this.state.type = row.Type;
            this.state.title = row.title;
            this.setState({ showHideModal: flag, viewEditFlag, publicationFlag: true })
            return true;
        }
        else if (viewEditFlag === "edit" && row && row.id && row.Type === "Amendment") {
            await this.getPublicationById(row.id, row.Type);
            this.state.type = row.Type;
            this.state.title = row.title;
            this.setState({ showHideModal: flag, viewEditFlag, amendmentFlag: true })
            return true;
        }
        else if (viewEditFlag === "edit" && row && row.id && row.Type === "Proposal") {
            await this.getPublicationById(row.id, row.Type);
            this.state.type = row.Type;
            this.state.title = row.title;
            this.setState({ showHideModal: flag, viewEditFlag, proposalFlag: true })
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {}, publicationFlag: false, protocolFlag: false, conceptFlag: false, amendmentFlag: false, proposalFlag: false });
    }
    getPublicationById = async (id, type) => {
        if (type === "Protocol") {
            const res = await API.get(`review/protocol/${id}`)
            this.setState({ viewEditData: res.data })
            console.log(this.state.viewEditData)
        }
        else if (type === "Concept") {
            const res = await API.get(`review/concept/${id}`)
            this.setState({ viewEditData: res.data })
            console.log(this.state.viewEditData)
        }
        else if (type === "Publication") {
            const res = await API.get(`review/publication/${id}`)
            this.setState({ viewEditData: res.data })
            console.log(this.state.viewEditData)
        }
        else if (type === "Amendment") {
            const res = await API.get(`review/amendment/${id}`)
            this.setState({ viewEditData: res.data })
            console.log(this.state.viewEditData)
        }
        else if (type === "Proposal") {
            const res = await API.get(`review/proposal/${id}`)
            this.setState({ viewEditData: res.data })
            console.log(this.state.viewEditData)
        }
    }
    handleProtocolUpdate = async (state) => {
        state.submission_id = this.props.submissionId;
        state.updated_by = this.context.userSystemId;
        try {
            const res = API.post(`review/protocol/update/`, state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })

        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
         this.getSubmissionStageList(this.props.submissionId);
    }
    handleConceptUpdate = async (state) => {
        state.submission_id = this.props.submissionId;
        state.updated_by = this.context.userSystemId;
        try {
            const res = API.post(`review/concept/update/`, state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })

        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
        this.getSubmissionStageList(this.props.submissionId);
    }
    handleProposalUpdate = async (state) => {
        state.submission_id = this.props.submissionId;
        state.updated_by = this.context.userSystemId;
        try {
            const res = API.post(`review/proposal/update/`, state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })

        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
        this.getSubmissionStageList(this.props.submissionId);
    }
    handleAmendmentUpdate = async (state) => {
        state.submission_id = this.props.submissionId;
        state.updated_by = this.context.userSystemId;
        try {
            const res = API.post(`review/amendment/update/`, state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })

        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
        this.getSubmissionStageList(this.props.submissionId);
    }
    handlePublicationUpdate = async (state) => {
        state.submission_id = this.props.submissionId;
        state.updated_by = this.context.userSystemId;
        try {
            const res = API.post(`review/publication/update/`, state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })

        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
        this.getSubmissionStageList(this.props.submissionId);
    }
    handlePublicationDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete && row.Type === "Protocol") {
                    try {
                        const res = await API.post(`recycle/protocolreview/delete/${row.id}/${this.props.submissionId}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getSubmissionStageList(this.props.submissionId);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getSubmissionStageList(this.props.submissionId);
                }
                else if (willDelete && row.Type === "Proposal") {
                    try {
                        const res = await API.post(`recycle/proposalreview/delete/${row.id}/${this.props.submissionId}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getSubmissionStageList(this.props.submissionId);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getSubmissionStageList(this.props.submissionId);
                }
                else if (willDelete && row.Type === "Publication") {
                    try {
                        const res = await API.post(`recycle/publicationreview/delete/${row.id}/${this.props.submissionId}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getSubmissionStageList(this.props.submissionId);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getSubmissionStageList(this.props.submissionId);
                }
                else if (willDelete && row.Type === "Concept") {
                    try {
                        const res = await API.post(`recycle/conceptreview/delete/${row.id}/${this.props.submissionId}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });

                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getSubmissionStageList(this.props.submissionId);
                }
                else if (willDelete && row.Type === "Amendment") {
                    try {
                        const res = await API.post(`recycle/amendmentreview/delete/${row.id}/${this.props.submissionId}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getSubmissionStageList(this.props.submissionId);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getSubmissionStageList(this.props.submissionId);
                }
            });
            this.getSubmissionStageList(this.props.submissionId);
    }
    getSubmissionStageList = async (id) => {
        try {
            const response = await API.get(`review/submission/${id}`)
            console.log(response)
            let reviewList = [];
            if(this.props.Type==="Concept")
          {response.data.concept_reviews?.map((x) => { reviewList.push({ id: x.id, createdAt: x.createdAt, "title": "Submission-" + x.submissionId + " Concept-Review-" + x.id, "Type": "Concept", "name_medical_reviewer_committee": x.name_medical_reviewer_committee, "date_of_review": dateTimeformat(x.date_of_review) }); });}  
           else if(this.props.Type==="Proposal")
           { response.data.proposal_reviews?.map((x) => { reviewList.push({ id: x.id, createdAt: x.createdAt, "title": "Submission-" + x.submissionId + " Proposal- Review-" + x.id, "Type": "Proposal", "name_medical_reviewer_committee": x.name_medical_reviewer_committee, "date_of_review": dateTimeformat(x.date_of_review) }); });}
           else if(this.props.Type==="Protocol")
            { response.data.protocol_reviews?.map((x) => { reviewList.push({ id: x.id, createdAt: x.createdAt, "title": "Submission-" + x.submissionId + " Protocol- Review-" + x.id, "Type": "Protocol", "name_medical_reviewer_committee": x.name_medical_reviewer_committee, "date_of_review": dateTimeformat(x.date_of_review) }); });}
            else if(this.props.Type==="Amendment")
            {response.data.amendment_reviews?.map((x) => { reviewList.push({ id: x.id, createdAt: x.createdAt, "title": "Submission-" + x.submissionId + " Amendment- Review-" + x.id, "Type": "Amendment", "name_medical_reviewer_committee": x.name_medical_reviewer_committee, "date_of_review": dateTimeformat(x.date_of_review) }); });}
            else if(this.props.Type==="Publication")
            {response.data.publication_reviews?.map((x) => { reviewList.push({ id: x.id, createdAt: x.createdAt, "title": "Submission-" + x.submissionId + " Publication- Review-" + x.id, "Type": "Publication", "name_medical_reviewer_committee": x.name_medical_reviewer_committee, "date_of_review": dateTimeformat(x.date_of_review) }); });}
            console.log(JSON.stringify(reviewList))

            let sortedReviews = reviewList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            this.setState({ reviewList: sortedReviews })
        }
        catch (err) { console.log(err) }
    }
    handlePublicationDetails = async (row) => {

        if (row.Type === "Concept") {
            let res = await API.get(`review/concept/${row.id}`)
            console.log("res", res)
            this.props.history.push(`/conceptreview/details/${row.id}`, { reviewTitle: row.title, type: row.Type, conceptId: row.id, submissionId: this.props.submissionId });
        }
        if (row.Type === "Protocol") {
            let res = await API.get(`review/protocol/${row.id}`)
            console.log("res", res)
            this.props.history.push(`/protocolreview/details/${row.id}`, { reviewTitle: row.title, type: row.Type, protocolId: row.id, submissionId: this.props.submissionId });
        }
        if (row.Type === "Proposal") {
            let res = await API.get(`review/proposal/${row.id}`)
            console.log("res", res)
            this.props.history.push(`/proposalreview/details/${row.id}`, { reviewTitle: row.title, type: row.Type, proposalId: row.id, submissionId: this.props.submissionId });
        }
        if (row.Type === "Amendment") {
            let res = await API.get(`review/amendment/${row.id}`)
            console.log("res", res)
            this.props.history.push(`/amendmentreview/details/${row.id}`, { reviewTitle: row.title, type: row.Type, amendmentId: row.id, submissionId: this.props.submissionId });
        }
        if (row.Type === "Publication") {
            let res = await API.get(`review/publication/${row.id}`)
            console.log("res", res)
            this.props.history.push(`/publicationreview/details/${row.id}`, { reviewTitle: row.title, type: row.Type, publicationId: row.id, submissionId: this.props.submissionId });
        }
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getSubmissionStageList(this.props.submissionId);
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, successModal, errorModal, success_body, protocolFlag, conceptFlag, publicationFlag, amendmentFlag, proposalFlag } = this.state
        return (
            <div>
                <Row>
                    <Col md={7}>
                        <h6 class="table-top"> Reviews ({this.state.reviewList.length})</h6>
                    </Col>
                    <DatatableComponent id="reviewFiles" data={this.state.reviewList} columns={this.columns} rowEvents={this.rowEvents} pagination="true" />
                </Row>
                {showHideModal && protocolFlag &&
                    <ReviewProtocolModal
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handlePublicationSave={this.handlePublicationSave}
                        handleProtocolUpdate={this.handleProtocolUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}
                        title={this.state.title}
                        type={this.state.type}

                    />}
                {showHideModal && conceptFlag &&
                    <ReviewConceptModal
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handlePublicationSave={this.handlePublicationSave}
                        handleConceptUpdate={this.handleConceptUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}
                        title={this.state.title}
                        type={this.state.type}
                    />}
                {showHideModal && publicationFlag &&
                    <ReviewPublicationModal
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handlePublicationSave={this.handlePublicationSave}
                        handlePublicationUpdate={this.handlePublicationUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}
                        title={this.state.title}
                        type={this.state.type}

                    />}
                {showHideModal && amendmentFlag &&
                    <ReviewAmendmentModal
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handlePublicationSave={this.handlePublicationSave}
                        handleAmendmentUpdate={this.handleAmendmentUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}
                        title={this.state.title}
                        type={this.state.type}
                    />}
                {showHideModal && proposalFlag &&
                    <ReviewProposalModal
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handlePublicationSave={this.handlePublicationSave}
                        handleProposalUpdate={this.handleProposalUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}
                        title={this.state.title}
                        type={this.state.type}
                    />}
                     {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
            </div>
        )
    }
}
export default withRouter(ReviewFile);