import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DatatTableEx from '../DataTableEx';
import API from '../../../api/apis';
import DTAButton from '../Button/DTAButton';
import { isCoordinator } from '../../ToReviewDetails/commonFunctions';
import UserContext from '../../../context/UserContext';
import Alert from 'react-bootstrap/Alert';
import swal from 'sweetalert';
import StrengthModalEx from './StrengthModalEx';
import BootstrapModal from '../../common/modal/BootstrapModal';
import LoaderEx from '../loader/LoaderEx';

class StrengthNew extends Component {
    static contextType = UserContext;
    state = {
        StrengthList:[],
        is_Coordinator:false,
        access:false,
        filterSource:[],
        showLoader:false,
        showModal:false,
        editMode:null
    }
    columns = [
        {
            dataField: 'id',
            text: 'id',
            sort: true,
            excludeFilter:true,
            hidden:true,
            csvExport: false ,
            formatter:(cell,row,rowIndex)=>{
                return <p onClick={()=>this.handleModal(true,"edit",row,rowIndex)}></p>
            }
        },{
        dataField: 'name',
        text: 'Strength',
        sort: true,
    },
    {
        dataField: 'actions',
        text: "Actions",
        csvExport: false,
        excludeFilter:true,
        headerClasses: "width-100",
        formatter: (cell, row, rowIndex) => {
            return <>
            {this.state.is_Coordinator&&<DTAButton onClick={() => this.handleStrengthDelete(row,true)} icon="DELETE" text="DELETE"></DTAButton>}
            </>
        }

    }
    ]
    attachColumEvent=()=>{
        this.columns.map((column,index)=>{
            column.events=this.columnEvent;
            });
    }
    columnEvent = {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            if ( column.dataField != 'actions') {
                this.handleModal(true, "edit", row, rowIndex)
            }

        }
    }
   async componentDidMount() {
        this.attachColumEvent()
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator ,access:_isCoordinator});
      await  this.getStrengthList();
    }
    handleStrengthDelete = async (row,is_deleted) => {
        let payload={
            is_deleted:is_deleted,
            id:row.id,
            updated_by:this.context.userSystemId
        }
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    const res =await  API.post(`/seed/strength/update`,payload);
                    if(res.data.success){
                    swal("Deleted Successfully", {
                        icon: "success",
                      });
                    this.getStrengthList();
                }
                    else{
                        this.setState({ errorModal: true });  
                    }
                }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
                
            } 
          });
       
    }    
    getStrengthList=async()=>{
        this.setState({
            showLoader : true, 
        })
        const res = await API.get(`seed/strength/data`);
        if(res&&res.data&&res.data.success){
           this.setState({
            StrengthList:res.data.data||[],
            showLoader:false
           })
        }
        else{this.setState({StrengthList:[]})}

    }
    handleStrengthSave = async (state) => {
        state.updated_by = null;
        state.created_by = this.context.userSystemId;
        state.active=true;
        try {
            const res = await API.post("/seed/strength/addstrength", state);
            if(res&&res.data&&res.data.success){
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showModal: false,
                editMode: null,
            });
           this.getStrengthList();
        }else{
            this.setState({ errorModal: true });
        }

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleStrengthUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        try {
            const res = await API.post("/seed/strength/update", state);
            if(res&&res.data&&res.data.success){
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showModal: false,
                editMode: null,
            });
            this.getStrengthList();
        }else{
            this.setState({ errorModal: true }); 
        }

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleModal=async(flag,editMode,row)=>{
        this.setState({ showModal: flag, editMode,rowId:row.id });

    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getStrengthList();
    }
    render(){
        let {successModal,success_body,errorModal, showLoader}=this.state;
        return <><div>
            {showLoader && <LoaderEx />}
            <DatatTableEx 
            autoFilter={true}
            filterChange={()=>{}}
            csvName="strength_details"
            data={this.state.StrengthList} 
            title={<Alert.Heading>
            <span><i class="bi bi-people" style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
             Strengths</Alert.Heading>} columns={this.columns}
            pageIcons={this.state.is_Coordinator && <DTAButton onClick={() => this.handleModal(true,null,{})} icon="ADD" text="Add Strength"></DTAButton>}>
            </DatatTableEx>
            {this.state.showModal&&
            <StrengthModalEx
            rowId={this.state.rowId}
            handleStrengthSave={this.handleStrengthSave}
            handleStrengthUpdate={this.handleStrengthUpdate}
            errorModal={errorModal}
            editMode={this.state.editMode}
            showModal={this.state.showModal}
            handleModal={this.handleModal}/>}</div>
            {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            } </>
        
    }
}
export default withRouter(StrengthNew);