import React, { Component } from 'react'
import { Col, Row, Button, Nav } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import API from "../../../../api/apis";
import DatatableComponent from '../../../common/datatable/DatatableComponent';
import ExpensesModal from '../ExpensesModal';
import Loader from '../../../common/loader/Loader';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import UserContext from '../../../../context/UserContext';
import {convertISOStringMMDDYYYY} from '../../../common/dateFormat';
import swal from 'sweetalert';
import { dateStringformat,dateChange, enableWFActions } from '../../../../utility/helperFunctions';

class Expenses extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        expensesDetails: [],
        error: false,
        successModal: false,
        errorModal: false,
    }
    componentDidMount() {
        this.getExpensesDetailsBySubmissionId(this.props.submissionDetails.id)
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
       
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    getExpensesDetailsBySubmissionId = async (submissionId) => {
        try {
            const expensesDetails = await API.get(`trial/submission/${submissionId}`);
            this.setState({
                expensesDetails: expensesDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    handleModalShowHide = async (flag, viewEditFlag, row) => {
        if (row && row.trial_expense_name && viewEditFlag === "edit") {
            await this.getExpenseByExpenseId(row.trial_expense_name)
            this.setState({ showHideModal: flag, viewEditFlag });
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
    }

    getExpenseByExpenseId = async (id) => {
        try {
            const expenseDetails = await API.get(`trial/getbyid/${id}`);
            this.setState({
                viewEditData: expenseDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    handleExpensesDetails = async(row,e) => {
        // let data={submissionId: this.props.submissionDetails.id,expensesId: row.trial_expense_name}
        // localStorage.setItem('data',JSON.stringify(data) )
        this.props.history.push(`/expenses/details/${row.trial_expense_name}`, {expensesId: row.trial_expense_name,submissionId: this.props.submissionDetails.id});
        // window.open(`/expenses/details/${row.trial_expense_name}`,data)
    }
    
    columns = [
        {
            dataField: 'trial_expense_name',
            text: '',
            hidden: true,
            csvExport: false
        },
        {
        dataField: 'expense_title',
        text: 'Expense Title',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <Nav.Link as={NavLink} to={{pathname:`/expenses/details/${row.trial_expense_name}`,state:{submissionId: this.props.submissionDetails.id,expensesId: row.trial_expense_name}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={(e) => this.handleExpensesDetails(row,e)}>{row.expense_title}</Nav.Link>
            </>
        }
    }, {
        dataField: 'invoice',
        text: 'Invoice #',
        sort: true
    }, {
        dataField: 'date_received',
        text: 'Date Received',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
               {row.date_received?.length>0 ? dateStringformat(row.date_received):""}
            </>
        }
    }, {
        dataField: '',
        text: 'Total Cost',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>{row.currency_data!=null ? row?.currency_data.value + " " + row?.total_cost:""}</>
        }
    }, {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            
            return <>{enableWFActions()&&<>
                <i className="bi bi-trash-fill" onClick={() => this.handleExpensesDelete(row)}
                    style={{ visibility: visibilityDelete,  fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>
                <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row)}
                    style={{visibility: visibilityEdit, fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>
            </>} </>
        }
    }];


    handleExpensesSave = async (state, saveNew) => {
        delete state.currencyList;
        delete state.showLoader;
        state["date_received"] = state.date_received != "" && state.date_received != null ?
            dateChange(state.date_received) : null;
        state["probability"] = state.probability != "" && state.probability != null ?
            parseInt(state.probability) : null;
        state.created_by = this.context.userSystemId;
        state.submissionId = this.props.submissionDetails.id;
        try {
            const res = await API.post("trial/add", state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getExpensesDetailsBySubmissionId(this.props.submissionDetails.id);
            this.props.getSubmissionDetail(this.props.submissionDetails.id);

            if(saveNew){
                this.handleModalShowHide(true, null, null)
             }

        } catch (err) {
            this.setState({ errorModal: true });
        }

    }
    handleExpensesUpdate = async (state) => {
        delete state.currencyList;
        delete state.showLoader;
        state["date_received"] = state.date_received != "" && state.date_received != null ?
            dateChange(state.date_received) : null;
        state["probability"] = state.probability != "" && state.probability != null ?
            parseInt(state.probability) : null;
        state.updated_by = this.context.userSystemId;
        state.submissionId = this.props.submissionDetails.id;
        try {
            const res = await API.post("trial/update", state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getExpensesDetailsBySubmissionId(this.props.submissionDetails.id)
            this.props.getSubmissionDetail(this.props.submissionDetails.id);
            
        } catch (err) {
            this.setState({ errorModal: true });
        }
        
    }


    handleExpensesDelete = async (row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    const res = await API.post(`trial/delete/${row.trial_expense_name}`,{updated_by : this.context.userSystemId});
                    swal("Deleted Successfully", {
                        icon: "success",
                      });
                      this.getExpensesDetailsBySubmissionId(this.props.submissionDetails.id)

 this.props.getSubmissionDetail(this.props.submissionDetails.id);                    }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
              
            } 
          });
      

    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }



    render() {
        const { showHideModal, viewEditFlag, viewEditData, expensesDetails,
            successModal, showLoader, success_body, errorModal } = this.state;
        return (<> {
            showLoader ? <Loader /> :
                <>
                    <Row>
                        <Col md={6}>
                            <h6 className="table-top"> Trial Expenses ({expensesDetails.length})</h6>
                        </Col>
                        <Col md={2}>
                        {this.state.access.create&&enableWFActions() &&  <Link as={Button}>
                                <Button variant="outline-light" className="new-button" onClick={() => this.handleModalShowHide(true, null, null)}>New</Button>
                            </Link>}
                        </Col>
                        <DatatableComponent id="expense" data={expensesDetails} columns={this.columns} pagination="true" />
                    </Row>
                    {
                        showHideModal &&
                        <ExpensesModal
                            showHideModal={showHideModal}
                            handleModalShowHide={this.handleModalShowHide}
                            handleExpensesSave={this.handleExpensesSave}
                            handleExpensesUpdate={this.handleExpensesUpdate}
                            viewEditFlag={viewEditFlag}
                            viewEditData={viewEditData}
                            submissionId={this.props.submissionDetails.id}
                            currency_iso_code={this.props.submissionDetails.currency_id}
                        />
                    }
                    {this.state.successModal &&
                        <BootstrapModal
                            show={successModal}
                            header="Success"
                            body={success_body}
                            changeShowState={this.changeShowState}
                        />
                    }
                    {this.state.errorModal &&
                        <BootstrapModal
                            show={errorModal}
                            variant="danger"
                            header="Ooops!!"
                            body="An error occured! Please try again later!"
                            changeShowState={this.changeShowState}
                        />
                    }
                </>
        }
        </>
        )
    }
}


export default withRouter(Expenses);