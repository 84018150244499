
import React, { Component } from 'react'

import { Button, Col, Row, Card, Modal } from 'react-bootstrap';

import Navbar from 'react-bootstrap/Navbar'

import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import { dateStringformat, dateTimeformat, dateTimeStringformat, getUserInfo } from '../../../utility/helperFunctions';
// import { yyyymmddFormat } from '../dateFormat';
// import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';

import InputField from '../../common/input-fields/InputField';
import StickyNav from '../../common/sticky-nav/StickyNav';
import { yyyymmddFormat } from '../dateFormat';
import Loader from '../loader/Loader';

class DelegeeModal extends React.Component {
    static contextType = UserContext;
    state = {
        compoundList: [],
        hasChanged: false,
        countryList: [],
        stateList: [],
        email:"",
        submission_id:"",
        countrycode:"",
        active:false,
        currency:"",
        rolesList:[],
        owner_id:"",
        accept_privacy_statement:false,
        no_confidential_information:false,
        no_sensitive_personal_information:false,
        working_on_sponsor_behalf:false,
        submissionIdList:[],
        isLoader:true,
        accept_privacy_statement_attested_date:null,
        no_confidential_info_attested_date:null,
        working_on_sponsor_behalf_attested_date:null,
        no_sensitive_personal_info_attested_date:null
        
    }
 async componentDidMount() {
    await this.getSubmissionIdlist();
    await this.getCurrencyList();
    await this.getRoleslist();
    
        const { viewEditData, viewEditFlag } = this.props;
        
        console.log("viewEditData",viewEditData)
        this.setState({
            //set Your form state details here
            ownerid:await getUserInfo(this.context.userSystemId),
        })
        
      if (viewEditFlag === "edit") {

         viewEditData["accept_privacy_statement_attested_date"] = viewEditData.accept_privacy_statement_attested_date != "" && viewEditData.accept_privacy_statement_attested_date != null ?
                dateTimeStringformat(viewEditData.accept_privacy_statement_attested_date) : null;
                viewEditData["no_confidential_info_attested_date"] = viewEditData.no_confidential_info_attested_date != "" && viewEditData.no_confidential_info_attested_date != null ?
                dateTimeStringformat(viewEditData.no_confidential_info_attested_date) : null;
                viewEditData["working_on_sponsor_behalf_attested_date"] = viewEditData.working_on_sponsor_behalf_attested_date != "" && viewEditData.working_on_sponsor_behalf_attested_date != null ?
                dateTimeStringformat(viewEditData.working_on_sponsor_behalf_attested_date) : null;
                viewEditData["no_sensitive_personal_info_attested_date"] = viewEditData.no_sensitive_personal_info_attested_date != "" && viewEditData.no_sensitive_personal_info_attested_date != null ?
                dateTimeStringformat(viewEditData.no_sensitive_personal_info_attested_date) : null;
        viewEditData["created_by"] =viewEditData.created_by_id?.length ? await getUserInfo(viewEditData.created_by_id)+"  "+ dateTimeformat(viewEditData.createdAt) : "";
        viewEditData["ownerid"] =viewEditData.owner_id?.length ? await getUserInfo(viewEditData.owner_id): "";
        viewEditData["last_modified_by"] =viewEditData.last_modified_by_id?.length > 0 ? await getUserInfo(viewEditData.last_modified_by_id) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.created_by ;
        viewEditData["role"] = this.state.rolesList.filter(item => item.value+"" === viewEditData.role_id)[0];
        viewEditData["submission_id"] = this.state.submissionIdList.filter(item => item.value === viewEditData.submissionId)[0];
        this.setState({
                //set Your form state details here
                ...this.state,
                ...viewEditData,
                isLoader:false
            })
        }
        else {
            this.setState({
                isLoader:false,
               
            })
        }
         
    }

    get inputFieldsArray() {
        return [
            {
                type: "alert",
                label: "Information",
                variant: "secondary"
            },
            {
                label: "LLIR Delegee Name",
                type: "text", 
                name: "name",
                disabled: true
            },
            {
                label: "Owner",
                type: "text", 
                name: "ownerid",
                disabled :true,
            },
            {
                label: "[EXT] First Name",
                type: "text",
                placeholder: "Enter [EXT] First Name",
                name: "first_name",
               
            },
            {
                label: "Last Updated By",
                type: "text",
                name: "last_updated_by",
            },
            {
                label: "[EXT] Last Name",
                type: "text",
                placeholder: "Enter [EXT] Last Name",
                name: "last_name",
               
            },
            {
                label: "[EXT] Email",
                type: "text",
                placeholder: "Enter [EXT] Email",
                name: "email",
               
            },
            {
                label: "Accepted",
                type: "checkbox",
               
                name: "accepted",
            },
            {
                label: "Submission",
                type: "searchableDropdown",
                name: "submission_id",
                options: this.state.submissionIdList,
                onSelect: this.onSearchSelect1,
                // disabled :true,
            },
            {
                label: "[EXT] Roles",
                type: "searchableDropdown",
                placeholder: "Select [EXT] Roles",
                name: "role",
                options: this.state.rolesList,
                onSelect: this.onSearchSelect,
               
            },
            {
                label: "[EXT] Other Role",
                type: "text",
                placeholder: "Enter [EXT] Other Role",
                name: "other_role",
               
            },
            {
                type: "alert",
                label: "Delegee Attestations",
                variant: "secondary",
                
            },
            {
                label: "Accept Privacy Statement",
                type: "checkbox",
                placeholder: "Enter Accept Privacy Statement",
                name: "accept_privacy_statement",
                
            },
           
            {
                label: " Accept Privacy Statement Attested Date",
                type: "datetime-local",
                
                name: "accept_privacy_statement_attested_date",
                
            },
            {
                label: "No confidential information",
                type: "checkbox",
                placeholder: "Enter No confidential information",
                name: "no_confidential_information",
                 
            },
           
            {
                label: " No confidential info. Attested Date",
                type: "datetime-local",
               
                name: "no_confidential_info_attested_date",
                
            },
            {
                label: "No sensitive personal information",
                type: "checkbox",
                placeholder: "Enter No sensitive personal information",
                name: "no_sensitive_personal_information",
                
            },
            
            {
                label: "No sensitive personal info Attested Date",
                type: "datetime-local",
               
                name: "no_sensitive_personal_info_attested_date",
                
            },
            {
                label: "Working on Sponsor Behalf",
                type: "checkbox",
                placeholder: "Enter Working on Sponsor Behalf",
                name: "working_on_sponsor_behalf",
                
            },
            {
                label: "Working on Sponsor Behalf Attested Date",
                type: "datetime-local",
              
                name: "working_on_sponsor_behalf_attested_date",
                
            },
            {
                label: "Created By",
                type: "text",
                className:"createUpdateByfontsize",
                name: "created_by",
                disabled:true,
                edit:true
            },
            {
                label: "Last Modified By",
                type: "text",
                className:"createUpdateByfontsize",
                name: "last_modified_by",
                disabled: true,
                edit:true
            },
        ];
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/getall");
        const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ countryList: resultArray });

    }
    getRoleslist = async () =>{
        const res = await API.get("seed/roles/fetch");
        const resultArray = (res.data?.data || []).map(elm => ({ name: elm.name, value: elm.role_id}));
        console.log("result",resultArray)
        this.setState({
            rolesList: resultArray,
             
        });     
    }
    getSubmissionIdlist = async () =>{
        const res = await API.get("submission/getsubmissionids/idlist");
        const resultArray = res.data.map(elm => ({ name: `Submission-${elm.id}`, value: elm.id}));
        console.log("result",resultArray)
        this.setState({
            submissionIdList: resultArray,
             
        });     
    } 
    getCurrencyList = async () => {
        try {
            const currencyList = await API.get(`seed/currency/data`);
            if (currencyList.data.success) {
                this.setState({
                    currencyList: currencyList.data.data,
                    showLoader: false
                })
            }
            else {
                this.setState({
                    currencyList:[]
                })
            }
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    getStateList = async (country) => {
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ stateList: resultArray });
    }

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            country_id: e.value

        });
        if (name === "country_id")
            this.getStateList(e.name, "state_province_id")

    }

    onInputChange = (e,eo) => {
        if (eo.type === "datetime-local") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else 
        { this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
           role_id:e.value
            // stateName:e.name
        });
    }
    onSearchSelect1 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
           submissionId:e.value
            // stateName:e.name
        });
    }
    
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
             const   initialValues={
                   email:this.state.email,
                  submission_id:this.state.submission_id
                  
                  }
                  const errors = {};
                  
                  Object.keys(initialValues).forEach((key) => {
                    if (initialValues[key] === "" || initialValues[key] === null  ) {
                    
                  
                    errors[key] = true;
                    is_valid = false;
                   
                        
                    } 
                    this.setState({
                        error:  { ...errors}
                    });
                });
                  
        return is_valid;

    }
    onSave = () => {

        if(this.formValidation(this.state)){
          this.props.handleDelegeeSave(this.state);
        }
         
      }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox"? field.onChange ||((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleDelegeeSave, viewEditFlag, handleDelegeeUpdate} = this.props;
        const { record_Id } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg">
               {this.state.isLoader=== true ?
        <Loader /> :  <div> <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit Delegee ` : "New Delegee"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                                viewEditFlag === "edit" ?
                                    <Button variant="primary" onClick={() => handleDelegeeUpdate(this.state)}>
                                        Save
                     </Button> : <Button variant="primary" onClick={this.onSave}>
                                        Save
                    </Button>
                        }

                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default DelegeeModal;