import React from 'react'
import {Modal,Button, Row, Col} from 'react-bootstrap';
import BaseReview from './BaseReview'
export default class ProtocolReview extends BaseReview{
    constructor(props){
        super(props);
        this.state={
            reviewType:'',

            reviewForm:{               
            },
            errors:{}
        }
        this.state=this.INITIAL_STATE;
        
    }
    INITIAL_STATE={
        externalurl:'',
        investigatorName:  "",     
        formView:this.props.formView,            
        stageTitle:"",
        fileInfo:{
            selectedFileName:'',
            file:null
        },
        sharePointFileInfo:{
            selectedFileName:'',
            customName:'',
            file:null
        },
        downloadableDocuments:[],
        reviewType:'',
        errors:{},
        reviewForm:{
            "is_committee_review": null,
            "name_medical_reviewer_committee": "",
            "name_of_reviewer_or_committee": "",
            "date_of_review": new Date(),
            "does_protocol_match_details":false,
            "safety_changes_required":false,
            "do_you_have_any_recommended_changes":false,
            "difference_from_the_proposal":"",
            "safety_language_missing":"",
            "provide_recommendations":"",
            "is_approved":null,
          
            "comments": "",
            "decision_rationale":"",
            "is_all_review_complete":null,
            "is_review_cycle_complete":null,
            "submission_id": "",
            "reviewers_detail_id": "",
            "review_header_id": "",
            "status": "",
            "details_for_pi":"",
            "is_coordinator_entry": false,
            "committee_approval":"",
            "mandatory_changes_need_to_be_implemented":""
        }
    }
    get InputElements(){
        let inputArrays= [
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Is this a committee review?",
                name: "is_committee_review",
                options: ["Yes", "No"],
                formType:[""],
                committeOrder:1,
                reviewOrder:1
            },
            {
               
                type: "text",
                placeholder: "Name of Committee",
                label: "Name of Committee *",
                name: "name_medical_reviewer_committee",
                formType:["COMMITTEE"],
                committeOrder:2
            },
            {
               
                type: "text",
                placeholder: "Name of reviewer(s) or committee",
                label: "Name of reviewer(s) or committee *",
                name: "name_medical_reviewer_committee",
                formType:["COORDINATOR"],
                committeOrder:2
            },
            {
                label:"Name of reviewer(s) *",
                type: "text",
                placeholder: "Name of reviewer(s)",
                name: "name_of_reviewer_or_committee",
                formType:["REVIEWER"],                
                reviewOrder:1
            },
           
            {   label:"Review Date *",
                placeholder: "Review Date",
                type: "date",
                name: "date_of_review", 
                formType:["COMMITTEE","REVIEWER","COORDINATOR"] ,
                reviewOrder:2,
                col:6          
            },
            {
                label:"Final review decision *",
                type: "dropdown",
                placeholder: "Please Select",                
                name: "status",
                options: ["Approved", "Declined","On Hold","Contingent"],
                formType:["COORDINATOR"]
            },
            {   label:"Decision rationale",
                placeholder: "Decision rationale",
                type: "textarea",
                name: "decision_rationale",
                formType:["COORDINATOR"]               
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Does the protocol match the details in the proposal?",
                name: "does_protocol_match_details",
                options: ["Yes", "No"],
                formType:["COMMITTEE","REVIEWER"],
                reviewOrder:3,
            }, 
            
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Do you have recommendations for protocol?",
                name: "do_you_have_any_recommended_changes",
                options: ["Yes", "No"],
                formType:["REVIEWER"],
                reviewOrder:7,
            }, 
            {
                placeholder: "State difference from the proposal",
                label: "State difference from the proposal",
                type: "textarea",
                name: "difference_from_the_proposal",
                formType:["COMMITTEE","REVIEWER"]   ,
                reviewOrder:4,            
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are they any mandatory safety or product related changes required?",
                name: "safety_changes_required",
                options: ["Yes", "No"],
                formType:["REVIEWER","COMMITTEE"],
                reviewOrder:5,
            }, 
            {               
                type: "textarea",
                placeholder: "What safety / product related changes required?",
                label: "What safety / product related changes required?",
                name: "mandatory_changes_need_to_be_implemented",
                formType:["COMMITTEE","REVIEWER"],
                reviewOrder:6,
            }, 
            {
                placeholder: "Why Safety language is missing?",
                label: "Why Safety language is missing?",
                type: "textarea",
                name: "safety_language_missing",
                formType:[]               
            },
            {
                placeholder: "Provide your recommendations",
                label: "Provide your recommendations",
                type: "textarea",
                name: "provide_recommendations",
                formType:["REVIEWER"]     ,
                reviewOrder:8,          
            },
            {
                placeholder: "Please Select",
                label: "Approved? *",
                type: "dropdown",
                options: ["Yes", "No"],
                name: "is_approved",
                formType:["REVIEWER"] ,
                reviewOrder:9,              
            },
            {
                label:"Approved? *",
                type: "dropdown",
                placeholder: "Please Select",                
                name: "committee_approval",
                options: ["Approved", "Declined","On Hold","Contingent"],
                formType:["COMMITTEE"],
                col:6 
            },
            {
                placeholder: "Feedback for PI",
                label: "Feedback for PI",
                type: "textarea",
                name: "details_for_pi",
                formType:["COMMITTEE"]               
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are all reviews complete?",
                name: "is_all_review_complete",
                options: ["Yes", "No"],
                formType:["COORDINATOR"]
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are all review cycles complete?",
                name: "is_review_cycle_complete",
                options: ["Yes", "No"],
                formType:["COORDINATOR"]
            },          

            {
                placeholder: "Comments",
                label:"Comments",
                type: "textarea",
                name: "comments",
                formType:["COMMITTEE","REVIEWER","COORDINATOR"]  ,
                reviewOrder:10,              
            }
        ];
        return inputArrays;
    }    
    
    validateInput=()=>{
        let {reviewForm,errors}=this.state;
        errors={};
        let is_valid=true;
        let include=["is_committee_review","name_medical_reviewer_committee","name_of_reviewer_or_committee","date_of_review","status","committee_approval","is_approved",];
        this.getFields().forEach((item)=>{
           
            if(include.includes(item.name)){
                if(this.isUndefinedOrNull(reviewForm[item.name])){
                    errors[item.name]=true;
                    is_valid=false;
                }
                
            }
        });
        this.setState({errors:{...errors}})      
        return is_valid;
    }
   
    render(){       
        return <>
           {this.renderTopArea()}
            <Row>
                {this.renderInputs()}
                {this.renderFileArea()}
                {this.renderFileDownloadArea()}
            </Row></>
    }
}