import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import API from '../../../api/apis';
import {yyyymmddFormat} from  '../../common/dateFormat';
import { getUserInfo,dateTimeformat, dateStringformat } from '../../../utility/helperFunctions';
import Loader from '../../common/loader/Loader';

class ReviewAmendmentModal extends React.Component {
    state = {
        compoundList: [],
        hasChanged: false,
        submission:"",
        submissionId: this.props.submissionId,
        reforcastTitle:"",
        approval:"Awaiting Review",
        title:this.props.title,
        type:this.props.type,
        reviewNumber:"",
        isLoader:true,
        date_of_review:null
    }
    async componentDidMount() {
        const { viewEditData, viewEditFlag } = this.props;
       
        if (viewEditFlag === "edit") {
            viewEditData["date_of_review"] = viewEditData.date_of_review != "" && viewEditData.date_of_review != null ?
       dateStringformat(viewEditData.date_of_review) : null;
            viewEditData["support_for_amendment"]=viewEditData.support_for_amendment===true?"Yes":"No";
       viewEditData["any_recommendations_for_investigator"]=viewEditData.any_recommendations_for_investigator===true?"Yes":"No";
       viewEditData["mandatory_changes_required"]=viewEditData.mandatory_changes_required===true?"Yes":"No";
            viewEditData["createdby"] = viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
            viewEditData["updatedby"] = viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
            this.setState({
               
                ...this.state,
                submission:`Submission- ${viewEditData.submission_id}`,
                reviewNumber:`R-${viewEditData.id}`,
                ...viewEditData,
                isLoader:false
            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
         
    }

    get inputFieldsArray() {
        return  [
            {
                label: "Review Number ",
                type: "text",
                disabled:true,
                name: "reviewNumber",
               
            },
            {
                label: "Review Title",
                type: "text",
                disabled:true,
                name: "title",
               
            },
            {
                label: "Record Type",
                type: "text",
                disabled:true,
                name: "type",
               
            },
           
            {
                label: "Submission",
                type: "text",
                disabled:true,
                name: "submission",
               
            },
            
            {
                label: "Date of Review",
                type: "date",
                name: "date_of_review",
               
            },
            
            {
                label: "Protocol Version Number",
                type: "text",
                placeholder: "Enter Protocol Version Number",
                name: "protocol_version_number",
               
            },
            {
                label: "Name of Medical Reviewer or Committee",
                type: "text",
                placeholder: "Enter Name",
                name: "name_medical_reviewer_committee",
               
            },
          
            {
                label: "Recommendations for investigator?",
                type: "dropdown",
                placeholder: "Select Investigator Requirement",
                name: "any_recommendations_for_investigator",
                options: ["Yes", "No"]
            },
            {
                label: "Provide your recommendations",
                type: "textarea",
                name: "provide_your_recommendations",
               
            },
            {
                label: "Mandatory changes required",
                type: "dropdown",
                placeholder: "Select Change Requirement",
                name: "mandatory_changes_required",
                options: ["Yes", "No"],
                helptext:"Safety language missing,inconsistency noted in document"
            },
            {
                label: "List required changes",
                type: "textarea",
                name: "mandatory_changes_need_to_be_implemented",
               
            },
            {
                label: "Approved?",
                type: "dropdown",
                placeholder: "Select Support Requirement",
                name: "support_for_amendment",
                options: ["Yes", "No"]
            },
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
           disabled:true
        }
        ,{
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled:true
           
        }
    
        ];
    }


    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else
        { this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
        });
    }
    getHtmlFromEditorValue = (val) => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            lillyFeedback: {...this.state.lillyFeedback, [e]: this.getHtmlFromEditorValue(val)}
        });
    };

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={((e)=>{this.onInputChange(e,field)})}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleReforecastSave, viewEditFlag, handleAmendmentUpdate} = this.props;
        const { submissionId } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg">
                {this.state.isLoader=== true ?
        <Loader /> :  <div>  <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit Amendment Review` :  "New Reforecast"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                                viewEditFlag === "edit" ?
                                    <Button variant="primary" onClick={() => handleAmendmentUpdate(this.state)}>
                                        Save
                     </Button> : <Button variant="primary" onClick={() => handleReforecastSave(this.state)}>
                                        Save
                    </Button>
                        }

                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default ReviewAmendmentModal;