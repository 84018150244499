import React,{useState} from 'react'
import Button from '../../../../common/Button';
import styles from './index.module.css'
const CANCEL_FORM_CONTENT={
    title:"Request Reviewer to Edit Submission",
    content:"We ask you to please confirm in officially asking this reviewer to Edit their submission.",
    confirmation:"Confirming this Edit request a notification and email will be sent to the reviewer notifying them of this request to Edit. "
}
export default ({handleEvents,id})=>{
    const[formValues,setFormValues]=useState({comments:''})
    const [formError,setFormError]=useState({comments:false})
    // const handleValidation=()=>{
    //     let isValid=false;
    //     if(formValues.comments && formValues.comments.trim().length>0){           
    //         isValid=true;
    //     }
    //     setFormError({comments:!isValid});
    //     return isValid;
    // }
    const handleSave=()=>{
        // if(handleValidation()){
            console.warn("resubmitdata",id);
            handleEvents("SAVE-RESUBMIT",{id,comments:formValues.comments.trim()});
        // }
    }

    return <div className={styles.wrapper}>
            <div className={styles.message_view}>
                <div className={styles.title}>{CANCEL_FORM_CONTENT.title}</div>
                <div className={styles.body}>
                    <span>{CANCEL_FORM_CONTENT.content}</span>
                    <textarea value={formValues.comments} onChange={e=>{
                        formValues.comments=e.target.value||'';
                        setFormValues({...formValues});
                    }} rows={4} style={{width:'500px'}} className="form-control mt-2 mb-2" placeholder="Comments for Edits"></textarea>
                    {formError.comments&&<p className="text-danger ml-3">This field is required.</p>}
                    <span>{CANCEL_FORM_CONTENT.confirmation}</span>
                </div>
                <div className={styles.footer}>
                <Button className="p-3 mr-2" style="danger" onClick={e=>handleEvents("CANCEL-VIEW","")}>
                        <div className="d-flex align-items-center">
                         <span>CANCEL</span>
                         </div>
                    </Button>
                    <Button className="p-3" style="warning"  onClick={e=>handleSave()}>
                        <div className="d-flex align-items-center">
                            <span className="material-icons mr-1" style={{ fontSize: '1rem' }}>
                                send
                            </span>
                         <span>REQUEST SUBMISSION EDITS</span>
                         </div>
                    </Button>
                </div>
            </div>
    </div>;
}