import React, { Component } from 'react';

import { Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';


export default class Finaces extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },        patchObject:{},
        currencyList: [],
    };
   async componentDidMount() {
    try{
        const { submissionDetails } = this.props
        await this.getCurrencyList();
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        let currencyCode=this.state.currencyList.filter(item => item.id === submissionDetails.currency_id)[0]
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
           
            current_patient_spend:submissionDetails.current_patient_spend!= null &&  currencyCode.value!= null ? currencyCode.value+" "+(submissionDetails.current_patient_spend).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "",
            current_trial_expenses_spend:submissionDetails.current_trial_expenses_spend!= null &&  currencyCode.value!= null ? currencyCode.value+" "+ (submissionDetails.current_trial_expenses_spend).replace(/\d(?=(\d{3})+\.)/g, '$&,')  : "",
            current_overall_spend:submissionDetails.current_overall_spend != null &&  currencyCode.value!= null ? currencyCode.value+" "+(submissionDetails.current_overall_spend).replace(/\d(?=(\d{3})+\.)/g, '$&,')  : "",
            start_up: submissionDetails.start_up!= null &&  currencyCode.value!= null ? currencyCode.value+" "+(submissionDetails.start_up).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "",
            cost_per_patient: submissionDetails.cost_per_patient!= null &&  currencyCode.value!= null ? currencyCode.value+" "+(submissionDetails.cost_per_patient).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "",
            max_trial_expense:submissionDetails.max_trial_expense!= null &&  currencyCode.value!= null ? currencyCode.value+" "+(submissionDetails.max_trial_expense).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "",
            final_payment: submissionDetails.final_payment!= null &&  currencyCode.value!= null ? currencyCode.value+" "+(submissionDetails.final_payment).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "",
            current_percent_to_spend: submissionDetails.current_percent_to_spend != null ? (submissionDetails.current_percent_to_spend).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " "+"%" : "",
            total_grant: submissionDetails.total_grant!= null &&  currencyCode.value!= null ? currencyCode.value+" "+(submissionDetails.total_grant).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "",
            total_percent_to_spend:submissionDetails.total_percent_to_spend != null  ? (submissionDetails.total_percent_to_spend).replace(/\d(?=(\d{3})+\.)/g, '$&,') + " "+"%" : "",
            po_number: submissionDetails.po_number || "",
            additional_po_number: submissionDetails.additional_po_number || "",
            currency: submissionDetails.currency_id!= null ?this.state.currencyList.filter(item => item.id === submissionDetails.currency_id)[0] :{},
            lilly_support: submissionDetails.lilly_support || "",
            enrollment_notes: submissionDetails.enrollment_notes || ""
        })
    }
    catch(exp){

    }
       
    }
financesData = () => {
        const {submissionDetails} = this.props;
        const newObject = {};
        let obj = this.state.patchObject;
        let costField=["start_up","cost_per_patient","max_trial_expense","final_payment","total_grant"]
         Object.keys(obj).forEach((key) => {
            if (obj[key] === "" || obj[key] === null ) {
                return false;
            } 
            else {
                if((costField.includes(key))){
                newObject[key] = this.state[key].replace( /^\D+/g, '')
                console.log(newObject[key])
            }
            else{
                newObject[key] = obj[key];
            }}
        });
        delete newObject.error;
        // this.state.patchObject={};
        return newObject;
    }
    getCurrencyList = async () => {
        try {
            const currencyList = await API.get(`seed/currency/data`);
            currencyList.data?.data || [].map(item => item["name"] = item.value + " - " + item.name);
            this.setState({
                currencyList: currencyList.data.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    get inputFieldsArray() {
        return  [
        {
            label: "Start Up Cost",
            type: "text",
            placeholder: "Enter Start Up Cost",
            name:"start_up",
            helptext:"Total Start Up Cost for Trial",
            customerror : (!this.state.patchObject.start_up) ? "" : ( new RegExp(/^\d*\.?\d*$/).test(this.state.patchObject.start_up+"") ? "" :"Only decimal and numbers are allowed") ,

        },
        {
            label: "Current Patient Spend",
            type: "text",
            name:"current_patient_spend",
            disabled: true,
            helptext:"Total spent on patient enrollment up to today's date"
        },
        {
            label: "Cost Per Patient",
            type: "text",
            placeholder: "Enter Cost Per Patient",
            name:"cost_per_patient",
            helptext:"Cost Per Enrolled Patient",
            customerror : (!this.state.patchObject.cost_per_patient) ? "" : ( new RegExp(/^\d*\.?\d*$/).test(this.state.patchObject.cost_per_patient+"") ? "" :"Only decimal and numbers are allowed") ,

        },
        {
            label: "Current Trial Expenses Spend",
            type: "text",
            name:"current_trial_expenses_spend",
            disabled: true,
            helptext:"Total spent on Trial Expenses up to today's date"
        },
        {
            label: "Max Trial Expenses",
            type: "text",
            placeholder: "Enter Max Trial Expenses",
            name:"max_trial_expense",
            helptext:"Total Available to spend outside of patient enrollment",
            customerror : (!this.state.patchObject.max_trial_expense) ? "" : ( new RegExp(/^\d*\.?\d*$/).test(this.state.patchObject.max_trial_expense+"") ? "" :"Only decimal and numbers are allowed") ,

        },
        {
            label: "Current Overall Spend",
            type: "text",
            name:"current_overall_spend",
            disabled: true,
            helptext:"Total spent on Trial up to today's date"
        },
        {
            label: "Final Payment",
            type: "text",
            placeholder: "Enter Final Payment",
            name:"final_payment",
            helptext:"Payment upon receiving FSR",
            customerror : (!this.state.patchObject.final_payment) ? "" : ( new RegExp(/^\d*\.?\d*$/).test(this.state.patchObject.final_payment+"") ? "" :"Only decimal and numbers are allowed") ,

        },
        {
            label: "Current Percent to Spend",
            type: "text",
            name:"current_percent_to_spend",
            disabled: true,
            helptext:"Total Spend in comparison to current estimate"
        },
        {
            label: "Total Grant",
            type: "text",
            placeholder: "Enter Total Grant",
            name:"total_grant",
            helptext:"Total budget for Trial",
            customerror : (!this.state.patchObject.total_grant) ? "" : ( new RegExp(/^\d*\.?\d*$/).test(this.state.patchObject.total_grant+"") ? "" :"Only numbers and decimals are allowed") ,
        },
        {
            label: "Total Percent to Spend",
            type: "text",
            name:"total_percent_to_spend",
            helptext:"Current Spent compared to Total Grant",
            disabled: true
        },
        {
            label: "PO #",
            type: "text",
            placeholder: "Enter PO #",
            name:"po_number",
        },
        {
            label: "Additional PO#",
            type: "text",
            placeholder: "Enter Additional PO#",
            name:"additional_po_number",
        },
        {
            label: "Currency",
            type: "searchableDropdown",
            placeholder: "Select Currency",
            options: this.state.currencyList,
                onSelect: this.onSearchSelect,
            name:"currency",
        },
        {
            label: "Lilly Support provided as",
            type: "dropdown",
            placeholder: "Select Support",
            options: [
                "Grants Only",
                "Drug Only",
                "Drug & Grant"
            ],
            name:"lilly_support",
        },
        {
            label: "Notes",
            type: "textarea",
            name: "enrollment_notes",
        }
    ]
    }

    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
            value: e.value,
             patchObject:  {...this.state.patchObject, currency_id: e.id},
        });
    }
    onInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value ,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},});
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });
    }
    
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
        this.setState({
            selectedRole: selectedRole,
            access: {
           view: true,
           create:  selectedRole.includes("administrator"),
           edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
           delete: selectedRole.includes("administrator"),
       },
        })
    }
    }
    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e},
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }

    checkboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked ,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.checked},
        
    })}

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    disabled = {field.disabled ? field.disabled : !this.state.access.edit }
                    // disabled = {field.hidden ? (field.hidden && (!this.state.access.edit) ): (!this.state.access.edit) }
                    value={this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={this.onInputChange }
                />
            </Col>
            )
        });
    }

    render() {
        return (
            <Row>
                {this.renderInputFields()}
            </Row>
        )
    }
}
