import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { Col, Row, Dropdown } from 'react-bootstrap';

import DatatableComponent from '../../common/datatable/DatatableComponent';


import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import UserContext from '../../../context/UserContext';
import BootstrapModal from '../../common/modal/BootstrapModal';

import swal from 'sweetalert';
import API from '../../../api/apis';
import CountryModal from '../existing/countryModal';
import CountModal from './CountModal';
import Loader from '../loader/Loader';

class Count extends Component {
    static contextType = UserContext;

    state = {
        presentationList:[],
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access : {
            view : true,
            create : false,
            edit : false,
            delete : false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        isLoading:false,
    }
    columns = [{
        dataField: 'Name',
        text: 'Count Name',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                     onClick={() => this.handleCountDetails(row)}>{row.name}</p>
            </>
        }
        },
        {
            dataField: '',
            text: "Actions",
            csvExport : false,
            headerClasses: "width-90",
            formatter: (cell, row, rowIndex) => {
                var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
                var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
                return <>
                    <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row,rowIndex)}
                        style={{visibility: visibilityEdit , fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>
                        <i className="bi bi-trash-fill" onClick={() => this.handleCountDelete(row)}
                        style={{visibility: visibilityDelete , fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>
                </>
            }
    }];
  
    componentDidMount() {
        console.log(this.props);
        this.getCountList();
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access : {
                view : true,
                create : selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit : selectedRole.includes("global_coordinator") ||  selectedRole.includes("administrator")  ,
                delete : selectedRole.includes("administrator")  ||  selectedRole.includes("global_coordinator") ,
            },
            
        });
    }
    
        getCountList = async () => {
            this.setState({
                isLoading : true, 
            })
            const res = await API.get(`seed/count/data`)
            // const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
            if(res&&res.data && res.data.success){res.data.data.map((item, index) => item["Name"] = item.name + item.id);
            this.setState(
                { 
                    presentationList: res.data.data,
                   isLoading:false
                }
            );}
            else 
            { this.setState(
                { 
                    presentationList: [],
                   isLoading:false
                }
            );}
        }

    
    componentDidUpdate(previousProps, previousState) {      
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;

        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {   
            this.setState({
                selectedRole: selectedRole,
                access : {
                    view : true,
                    create : selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit : selectedRole.includes("global_coordinator") ||  selectedRole.includes("administrator")  ,
                    delete : selectedRole.includes("administrator")  ||  selectedRole.includes("global_coordinator") ,
                },
            },()=>{console.log("userRole",this.state.selectedRole)})            
        }
    }
    handleModalShowHide = async (flag, viewEditFlag, row) => {
        console.log(row)
          if (row && row.id && viewEditFlag === "edit") {
              await this.getCountById(row.id)
              this.setState({ showHideModal: flag, viewEditFlag });
              return true;
          }
          this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
      }
      getCountById = async (id) => {
        try {
            const res = await API.get(`/seed/count/getbyid/${id}`);
            if(res&&res.data&&res.data.success){
            this.setState({
                viewEditData: res.data.data,
                showLoader: false
            })}else{
                this.setState({errorModal:true})
            }
         
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    handleCountSave = async (state) => {
        state.updated_by = null;
        state.created_by = this.context.userSystemId;
        state.active=true;
        try {
            const res = await API.post("/seed/count/addcount", state);
            if(res&&res.data&&res.data.success){
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
           this.getCountList();}
           else{this.setState({
            errorModal:true
           })}

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleCountUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        try {
            const res = await API.post("/seed/count/update", state);
            if (res && res.data && res.data.success) {
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getCountList();}
            else{
                this.setState({
                    errorModal:true
                })
            }

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleCountDelete = async (row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try {
                    const res =  API.delete(`/seed/count/delete/${row.id}`);
                    if(res&&res.data&&res.data.success){
                    swal("Deleted Successfully", {
                        icon: "success",
                      });
                    this.getCountList();}
                    else{
                        this.setState({errorModal:true})
                    }
                }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
                this.getCountList();
            } 
          });
       
    }
    handleCountDetails = (row) => {
        this.props.history.push(`/counts/detail/${row.id}`, {countId:row.id});
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getCountList();
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal,deleteModal,isLoading } = this.state;
        return <>
            <div className="inpage-content">
            {isLoading && <Loader />}
                {!isLoading && <Alert className="alert-head" key="submission" variant="light">
                    <Row>
                        <Col sm={6}>
                        <Alert.Heading>
                            <span><i class="bi bi-people" style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                             Count</Alert.Heading>
                        </Col>
                        <Col sm="auto" className="ml-auto">
                            {this.state.access.create &&
                                <Link className="new-button-link" onClick={() => this.handleModalShowHide(true, null, {})}>
                                <span>
                                    <i 
                                    class="bi bi-plus-square-fill" 
                                    style={{ fontSize: "1.2rem", margin: ".1rem", padding: ".1rem" }} onClick={() => this.handleModalShowHide(true, null, {})}
                                    ></i></span>
                                New Count
                            </Link>}
                        </Col>
                    </Row>
                </Alert>}
                {!isLoading &&  <Row>
                    {/* <Col md={6}>
                        <Dropdown  className="record_view_dropdown">
                            <Dropdown.Toggle variant="default"  id="dropdown-basic">
                                Recently Viewed
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Recently Viewed</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col> */}

                    <DatatableComponent id="countList" data={this.state.presentationList} columns={this.columns} rowEvents={this.rowEvents} pagination="true"  />
                </Row>}
            </div>
            { showHideModal &&
                <CountModal
             showHideModal={showHideModal}
             handleModalShowHide={this.handleModalShowHide}
             handleCountSave={this.handleCountSave}
             handleCountUpdate={this.handleCountUpdate}
             viewEditFlag={viewEditFlag}
             viewEditData={viewEditData}
            />}
             {this.state.successModal &&
             <BootstrapModal
                 show={successModal}
                 header="Success"
                 body={success_body}
                 changeShowState={this.changeShowState}
             />
         }
        {this.state.errorModal &&
             <BootstrapModal
                 show={errorModal}
                 variant="danger"
                 header="Ooops!!"
                 body="An error occured! Please try again later!"
                 changeShowState={this.changeShowState}
             />
         } 
      </>
    }
}

export default withRouter(Count);