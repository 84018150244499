import React, { Component } from 'react';
import "./error-boundary.css";

class ErrorBoundary extends Component{
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: ''
        };
      }
    componentDidCatch = (error, info) => {
        this.setState({
            hasError:true,
            errorMessage: error
        })

        console.log(error);
        console.log(info);
    }

    render() {
        return this.state.hasError ? (
        <div className="unauthorized_Error">
        <h1>Something went wrong</h1>
            <h5>Please contact your Business Admin.</h5>
      </div>
      ) : this.props.children;
    } 
}

export default ErrorBoundary;