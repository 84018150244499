import React, { Component } from 'react';
import DatatTableEx from '../common/DataTableEx';
import API from '../../api/apis';
import { dateStringformat, dateTimeformat } from '../../utility/helperFunctions';
export default class History extends Component {
    state = {
        historyList: [],
        showLoader: false,
        filterSource:[]
    }
    columns = [
        {
            dataField: 'id',
            text: 'id',
            sort: true,
            hidden: true,
            csvExport: false,

        },
        {
            dataField: 'date',
            text: 'Created Date',
            sort: true,
            sortValue: (cell, row) => new Date(row["date"])
        },
        {
            dataField: 'field',
            text: 'Field',
            sort: true,
        },
        {
            dataField: 'user',
            text: 'User',
            sort: true,
        },
        {
            dataField: 'old_value',
            text: 'Original Value',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>
                    {row.old_value != null ? (row.old_value) : ""}
                </>
            }
        },
        {
            dataField: 'new_value',
            text: 'New Value',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>
                    {row.new_value != null ? (row.new_value) : ""}
                </>
            }
        },
        {
            dataField: 'row_type',
            text: 'Row Type',
            sort: true,
        },
    ]
    componentDidMount = () => {
        this.getHistoryListData();
    }
    getHistoryListData = async () => {
        this.setState({ showLoader: true });
        try {
            let res = await API.get(`/finance/history/${this.props.submissionId}`);
            let invoiceListRes = await API.get(`/finance/invoices/${this.props.submissionId}`);
            let fields = "";
            let date = "";
            let user = "";
            let milestoneExpensesFields = {
                "createdAt": "Created At", "approved_amount": "Amount Approved", "date_received": "Invoice Recieved Date", "estimated_cost": "Estimated Amount", "estimated_date": "Estimated Date",
                "expense_title": "Milestone", "invoice": "Invoice Number", "milestone": "Milestone", "trial_expense_name": "ID", "currency_iso_code": "Local Currency", "updated_at": "Updated At", "updated_by": "Updated By", "actual_amount": "Actual Amount", "invoice_item_new_entry": "New Invoice Item Entry", "total_cost": "Total Cost", "created_at": "Created At", "invoice_item_is_approved": "Invoice Item Approved", "invoice_item_is_delete": "Invoice Item Deleted", "is_deleted": "Milestone Deleted","comments": "Internal Comments", "po_number": "PO Number"
                // 
            };
            const customMilestoneFields = {
                "created_at": "New Milestone Created"
            }
            let enrollmentExpensesFields =
            {
                "start_up": "Start Up Cost",
                "cost_per_patient": "Cost Per Patient", "max_trial_expense": "Max Trial Expenses", "final_payment": "Final Payment", "created_at": "Created At"
            };

            let overviewFields = {
                "po_number": "PO # or any Account ID that is used", "lilly_support": "Lilly Support", "additional_po_number": "Additional PO#",
                "total_grant": "Total Grant Amount",
                "po_delivery_date": "PO # Delivery Date", "current_overall_spend": "Current Overall Spend", "cost_center": "Cost Center",
                "current_patient_spend": "Current Patient Spend", "notes": "enrollment_notes", "currency_id": "Local Currency", "created_at": "Created At", "total_percent_to_spend": "Total Percent To Spend"
            }

            let formatValues = res.data.map((item, index) => {
                let { id, action, old_value, new_value, created_at, created_by, updated_at, row_type, first_name, last_name, field } = item;
                //field: field from API res
                //fields: field label mapped according to API res
                if (row_type === "Enrollment Expense") {
                    fields = enrollmentExpensesFields[field]
                }
                if (row_type === "New Milestone Added") {
                    fields = customMilestoneFields[field]
                }
                if (row_type === "Milestone Expense") {
                    fields = milestoneExpensesFields[field];

                    if(field == 'invoice_item_new_entry') {
                        new_value = new_value.split(', ')[1];
                    }
                    if(field == 'invoice_item_is_approved' || field == 'invoice_item_is_delete') {
                        let id = parseInt(old_value.split(',')[0]);
                        let desc = invoiceListRes.data.find(p => p.id === id)?.description;
                        
                        old_value = desc.concat(', ', old_value.split(',')[1]);
                        new_value = desc.concat(', ', new_value.split(',')[1]);
                    }
                }
                if (row_type === "Overview") {
                    fields = overviewFields[field]
                }

                if (fields == "Updated At") {
                    old_value = old_value != null? dateTimeformat(old_value) : "";
                    new_value = new_value != null ? dateTimeformat(new_value) : ""
                }
                if (fields == "Created At") {
                    new_value = new_value != null ? dateTimeformat(new_value) : ""
                }

                if (action = "INSERT") {
                    date = created_at != null ? dateTimeformat(created_at) : "";
                }
                if (action = "UPDATE") {
                    date = updated_at != null ? dateTimeformat(updated_at) : "";
                }
                
                user = first_name!=null ? first_name+" "+last_name : ''; 
                return {
                    date: date,
                    field: fields || "",
                    row_type: row_type, old_value: old_value, new_value: new_value, user: user

                }
            })
            let filterSource = await this.getFilterSource({ data: formatValues ? formatValues : [] });
            this.setState({
                historyList: formatValues, 
                filterSource: [...(filterSource || [])]
            })





            // this.setState({
            //     historyList: res.data,
            // });
        } catch {

        } finally {
            this.setState({ showLoader: false });
        }


    }
    getFilterSource=async (res)=>{
        if(res&&res.data&&res.data.length>0){
            let filterCollection= this.columns.filter(
                p => (!p.hidden )).map((col, index) => {
                let columnValues = (res.data || []).map((item, key) => {
                    
                    
                    if(col.dataField.split(".").length>1){

                        return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
                        
                    }
                    
                    return item[col.dataField];
                });
                return {
                    
                    group:col.text,key:col.dataField,items:[...new Set(columnValues)]};
            });
            return filterCollection;
        }
        return null;
          
    } 
    render() {
        return <>
            <DatatTableEx
                filterSource={this.state.filterSource}
                filterChange={()=>{}}
                columns={this.columns}
                csvName="Finance_History"
                pagination="true"
                showLoader={this.state.showLoader}
                data={this.state.historyList} />
        </>
    }
}