import React, { Component } from 'react';

import { Button, Col, Container, Row } from 'react-bootstrap';

import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { SUBMISSION_HELP } from "../../../mapping";
import UserContext from '../../../context/UserContext';

export default class SubmissionHelp extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
         iIRSharepointSite: this.props.submissionDetails.iir_sharepoint_site || "",
        extExternalUserGuide: this.props.submissionDetails.external_user_guide  || "",
        internalUserGuide: this.props.submissionDetails.internal_user_guide || "",
        hasChanged: false,
        successModal: false,
        errorModal: false,
        patchObject: {"id": this.props.submissionDetails.id}
    };

    get inputFieldsArray() {
        return  [
        {
            label: "IIR Sharepoint Site",
            type: "text",
            name:"iIRSharepointSite",
        },
        {
            label: "[EXT] External User Guide",
            type: "text",
            name:"extExternalUserGuide",
        },
        {
            label: "Internal User Guide",
            type: "text",
            name:"internalUserGuide",
        }
    ]
    }

    onInputChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
            patchObject:  {...this.state.patchObject, [SUBMISSION_HELP[name]]: value},
            hasChanged: true
        });
    }
    submissionHelpData=()=>{
        const newObject={};
        const obj = {...this.state.patchObject};
        Object.keys(obj).forEach((key) => {
            if (obj[key] === "" || obj[key] === null ) {
                return false;
            } 
            else{
                newObject[key] = obj[key];
            }
        });
        delete newObject.error;
        // this.state.patchObject={};
        return newObject;
    }
    submitForm = async () =>  {
        try {
            const res = await API.patch("submission/update", this.state.patchObject);
            this.setState({
                successModal: true,
                patchObject: {"id": this.props.submissionDetails.id},
                hasChanged: false
            });
        }catch(err){
            this.setState({errorModal: true});
        }
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    disabled = {field.disabled ? field.disabled : !this.state.access.edit }

                    value={this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={this.onInputChange }
                />
            </Col>
            )
        });
    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }
    componentDidMount() {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        })
    }
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
        this.setState({
            selectedRole: selectedRole,
            access: {
           view: true,
           create:  selectedRole.includes("administrator"),
           edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
           delete: selectedRole.includes("administrator"),
       },
        })
    }
    }
    render() {
        return (
            <Container>
            <Row>
                {this.renderInputFields()}
                {/* <div className="w-100 d-flex justify-content-center">
                    <Button className="px-4" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                        Save
                    </Button>
                </div> */}
            </Row>
            {this.state.successModal &&
                <BootstrapModal 
                    show={this.state.successModal} 
                    header="Success"
                    body="Updated successfully!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal 
                    show={this.state.errorModal} 
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
            </Container>
        )
    }
}
