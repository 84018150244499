import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { Component } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import UserContext from '../context/UserContext';
import { dateStringformat, dateTimeformat, getUserInfo } from '../utility/helperFunctions';
import { yyyymmddFormat } from './common/dateFormat';
import InputField from './common/input-fields/InputField';
import Loader from './common/loader/Loader';

class AmendmentModal extends Component {
    static contextType=UserContext
    state = {
        title: "",
        // owner_id:"",
        approval:"Awaiting Review",
        decision_date :  null,
        submissionId:`Submission- ${this.props.submission_id}`,
        isLoader:true,
    }
    get inputFieldsArray() {
        return [
            {
                label: "Information",
                type: "alert",
                variant: "secondary"
            },
            {
                label: "Amendment Title",
                type: "text",
                name: "amendmentTitle",
                disabled: true,
                edit: true
            },
            {
                label: "Approval",
                type: "dropdown",
                placeholder: "Awaiting Review",
                name: "approval",
                options: [
                    "None",
                    "Awaiting Review",
                    "Approved",
                    "Declined",
                    "Under Review"
                ]
            },
            {
                label: `Decision Date ${this.state.approval === "Approved" || this.state.approval === "Declined"  ? "*" : ""}`,
                name:  "decision_date",
                type:  "date",
                disabled: this.state.approval === "Approved" || this.state.approval === "Declined"  ? false : true,
                maxDate:new Date()
            },
            {
                label: "[EXT] Investigator Rationale",
                type: "textarea",
                name: "investigator_rationale",
            },
            {
                label: "Submission",
                type: "text",
                placeholder: "Enter Submission",
                disabled:true,
                name: "submissionId",

            },
            {
                label: "Lilly Feedback",
                type: "text-editor",
                name: "feedback",
                onChange: this.onEditorChange,
            },
            // {
            //     label: "Owner",
            //     type: "text",
            //     name: "owner_id",

            // },
            {
                label: "System Information",
                type: "alert",
                variant: "secondary"
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled:true,
                edit:true
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
                disabled:true,
                edit:true
            },
            {
                label: "Last Updated By",
                type: "text",
                name: "last_updated_by",
            },
            

        ];
    }
   async componentDidMount() {
        const { viewEditFlag, viewEditData } = this.props;
        // this.state.owner_id= await getUserInfo(this.context.userSystemId)
        if (viewEditFlag === "edit") {
            viewEditData["submissionId"]="Submission-"+viewEditData.submission_id;
            viewEditData["feedback"]= viewEditData.feedback !=="" ? this.getEditorValueFromHtml(viewEditData.feedback) : "";
            viewEditData["decision_date"] = viewEditData.decision_date !== "" && viewEditData.decision_date !== null ?
            dateStringformat(viewEditData.decision_date) : null;
            viewEditData["investigator_rationale"]=viewEditData.investigator_rationale !== null ? viewEditData.investigator_rationale.replace(/<[^>]+>/g, '') : "";
            viewEditData["createdby"] = viewEditData.created_by?.length ?await  getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
        viewEditData["updatedby"] = viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
            viewEditData["amendmentTitle"] =`Submission- ${viewEditData.submission_id} :Amendment- ${this.props.name}`
            this.setState({
                //set Your form state details hererev
                ...this.state,
                isLoader:false,
                // owner_id: await getUserInfo(this.context.userSystemId),
                ...viewEditData
            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
    }
    
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
        });
    };
    getEditorValueFromHtml = (html="") => {
        if(html){
            const blocksFromHTML = convertFromHTML(html);        
            const state = ContentState.createFromBlockArray(blocksFromHTML);
            return EditorState.createWithContent(state);
        }
        return "";
    }
    onInputChange = async(e,eo) => {
        let { name, value,type } = eo;
        if (eo.type === "date" || eo.type==="text-editor") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
       
        else 
        { this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }
    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
            error:{}
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = this.state.approval === "Approved" || this.state.approval === "Declined"  ? 
        { decision_date: this.state.decision_date } : {}
        const errors = {}
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors }
            });
        });
        return is_valid;
    }
    onSave = () => {
        if (this.formValidation()) { this.props.handleAmendmentSave(this.state) }
    }
    onUpdate = () => {
        if (this.formValidation()) { this.props.handleAmendmentUpdate(this.state) }
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag !== "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={(e)=> this.onInputChange(e,field)}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, viewEditFlag  } = this.props;
        
        return (
            <div>
                 <Modal show={showHideModal} size="lg">
                 {this.state.isLoader=== true ?
        <Loader /> :   <div> <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                     <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit ${("000"+this.state.id).slice(-4)} Amendment ` : "New Amendment"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                   <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                   
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                            viewEditFlag === "edit" ?
                                <Button variant="primary" onClick={this.onUpdate}>
                                    Save
                                </Button> : <Button variant="primary" onClick={this.onSave}>
                                    Save
                                </Button>
                        }

                    </Modal.Footer>
                    </div>}
                </Modal>
            </div>
        )
    }
}


export default withRouter(AmendmentModal);