import React, { Component } from 'react'
import { Col, Row, Button } from 'react-bootstrap';
import DatatableComponent from '../../../common/datatable/DatatableComponent';
import { Link, withRouter } from 'react-router-dom';
import API from '../../../../api/apis';
import UserContext from '../../../../context/UserContext';
import { dateTimeformat } from '../../../../utility/helperFunctions';
 class Sae extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        dataSae:[],
        
        response:{},
    }
    componentDidMount() {
        this.getSae(this.props.submissionDetails.id);
        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator"),
                edit:  selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator"),
                    edit:  selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    handleModalShowHide = (flag, viewEditFlag, viewEditData) => {
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData });
    }
    rowEvents = {
        onClick: (e, row, rowIndex) => {
          console.log(row['Record Number']);
          this.props.history.push(`/sae/details/${row.response.sae_name}`, {sae_name: row.response.sae_name,submissionId: row.response.submissionId});
        }
    };
    columns = [{
        dataField: 'SAE Name',
        text: 'SAE Name',
        sort: true
       
    },
    {
        dataField: 'Date Submitted',
        text: 'Date Submitted',
        sort: true
       
    },
    {
        dataField: 'Submitter Name',
        text: 'Submitter Name',
        sort: true
       
    },
    
//    { dataField: '',
//     text: "Actions",
//     headerClasses: "width-90",
//     formatter: (cell, row, rowIndex) => {
        
//                     var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
        
//         return <>
//             {/* <i className="bi bi-trash-fill" onClick={() => this.handleReforcastDelete(row)}
//                 style={{ visibility: visibilityDelete,fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i> */}
//             </>
//     }
//    }
   ];
   
    getSae = async (submissionid) => {
        const res = await API.get(`sae/submission/${submissionid}`)
        
     
        if(Array.isArray(res.data)  && Object.keys(res.data).length>0)
        // res.data.forEach((e,index)=>{
        //     this.setState({
        //         response: res.data,
              
        //         dataSae: [{
        //             'SAE Name': res.data[index].sae_name,
                    
        //         }]
        //     })
        // })
        { const data =  res.data.map(submission => {
            return {
                'SAE Name': 'SAE-'+ ("000" + submission.sae_name).slice(-4),
                'Date Submitted':dateTimeformat(submission.createdAt),
                'Submitter Name':submission.submitter_name,
                response:submission,
            
            }

        });
        let sortedReviews = data.sort((a,b) => (b.response.sae_name) - (a.response.sae_name));
        this.setState({dataSae: sortedReviews});}
        else{this.setState({dataSae: []});}
         
    }
   
    // data = [
    //     {
    //         'saeName': "SAE-0028"
    //     },
    //     {
    //         'saeName': "SAE-0029"
    //     },
    //     {
    //         'saeName': "SAE-0030"
    //     }
    // ];

    handleSAEUpdate = (state) => {

    }
   
  
    render() {
        const { showHideModal, viewEditFlag, viewEditData } = this.state;
        // return <>
        //     <Row>
        //         <Col md={12}>                    
        //             <h6 className="table-top"> SAEs ({this.data.length})</h6>                    
        //         </Col>
        //         <DatatableComponent data={this.data} columns={this.columns} rowEvents={this.rowEvents} pagination="true"/>
        //     </Row>
        //      {
        //         showHideModal &&
        //         <SAEModal
        //             showHideModal={showHideModal}
        //             handleModalShowHide={this.handleModalShowHide}
        //             handleSAEUpdate={this.handleSAEUpdate}
        //             viewEditFlag={viewEditFlag}
        //             viewEditData={viewEditData}
        //         />
        //     }
        //
        // </>
        let title=`SAEs (${this.state.dataSae.length})`;
        return (
            <Row>
              
                <DatatableComponent id="sae" title={title} data={this.state.dataSae} columns={this.columns} rowEvents={this.rowEvents} pagination="true" />
            </Row>
        )
    }
}
export default withRouter(Sae);
