import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row, Dropdown,  } from 'react-bootstrap';
import Button from "../../common/Button";
import DatatTableEx from '../DataTableEx';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import BootstrapModal from '../modal/BootstrapModal';
import Loader from '../loader/Loader';
import { isCoordinator } from '../../ToReviewDetails/commonFunctions';
class Country extends Component {
    static contextType = UserContext;
    state = {
       
        selectedRows: [],

        isCheckedAll: false,
        investigators: [],
        countryList: [],
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        data: [],
        error: false,
        successModal: false,
        errorModal: false,
        isLoading: false,
        checked: false

    }
    columns =

        [{
            dataField: "id",
            text: "id",
            hidden: true,
            csvExport: false
        },
        {
            dataField: 'name',
            text: 'Country Name',
            sort: true,

        },
        {
            dataField: 'is_compound',
            text: "Actions",
            csvExport: false,
            headerClasses: "width-90",
            formatter: (cell, row, rowIndex) => {
              
                return <>

                    <input type='checkbox' disabled={!this.state._isCoordinator} checked={row.is_compound} onChange={() => this.handleCheckboxChange(row.id, row)}
                    ></input>
                </>
            }
        },
        {
            dataField: 'is_compound_org',
            text: "Actions",
            hidden:true
        }];


    componentDidMount() {
        console.log("test",this.state.isCheckedAll);

        this.getCountryList();
        let _isCoordinator = isCoordinator(this.context);
       
        this.setState({
            _isCoordinator,
           
            reloadTable: true,

        });
    }

    getCountryList = async () => {
        this.setState({
            isLoading: true,
        })
        const res = await API.get(`seed/compound/country/${this.props.compoundId}`);
        res.data.map((item) => item['is_compound_org']= item.is_compound);
        this.setState(
            {
                countryList: res.data,
                isLoading: false,
                isCheckedAll : res.data.every((row) => row.is_compound),
            }
        );
    }

    handleCheckboxChange = (rowId, row) => {
        const { countryList } = this.state;
        const updatedCheckboxes = countryList.map((row) => {
            if (row.id === rowId) {
                return {
                    ...row,
                    is_compound: !row.is_compound,
                };
            }
            return row;
        });

        const isCheckedAll = updatedCheckboxes.every((row) => row.is_compound);
        const selectedRows = [];
        updatedCheckboxes.map((item) => {
            if (item.is_compound) {
                selectedRows.push(item.id);
            }
        })
        this.setState({
            countryList: updatedCheckboxes,
            selectedRows: selectedRows,
            isCheckedAll,
        });

    }

    handleSelectAll = () => {
        // this.setState((prevState) => ({
        //     isCheckedAll: !prevState.isCheckedAll,
        //     countryList: prevState.countryList.map((row) => ({ ...row, is_compound: !prevState.isCheckedAll })),
        // }));
        const { countryList, isCheckedAll } = this.state;
    const updatedCheckboxes =countryList.map((row) => ({
      ...row,
      is_compound: !isCheckedAll,
    }));

    this.setState({
      countryList: updatedCheckboxes,
      isCheckedAll: !isCheckedAll,
    });
    }


    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getCountryList();
    }


    handleSave = async () => {
        const Object = {
            "compound_id": this.props.compoundId,
            "select_country_ids": this.state.selectedRows.join(","), // User check checkbox than pass comma seperated ids

            "is_select_all": this.state.isCheckedAll  //Note: two tye "check" and "uncheck" for select All checkbox functionality
        }
        try {
            const res = await API.post(`/seed/compound/country/add`, Object)
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });

        }
        catch (err) {
            this.setState({ errorModal: true });
        }

    }
    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal, deleteModal, isLoading } = this.state;
        return <>
            <div className="inpage-content">
                {isLoading && <Loader />}
                {!isLoading && <div className="alert-head" key="submission" >
                    <Row>
                        <Col sm={6}>

                            <span><i className="bi bi-globe" style={{ fontSize: "1.5rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                            Countries
                        </Col>
                        <Col sm="auto" className="ml-auto">

                            
                            {this.state._isCoordinator && <Button onClick={this.handleSave} style="success w-100px"  className="d-flex align-items-center p-2 justify-content-center mb-3" >
                                SAVE
                            </Button>}
                        </Col>
                    </Row>
                </div>}
                {!isLoading && <Row>


                    <DatatTableEx 
                        // pageIcons={<div className="d-flex align-item-center ml-2"><span>Select All</span>
                        // <input checked={this.state.isCheckedAll} disabled={!this.state._isCoordinator} onChange={this.handleSelectAll} className="d-flex align-items-center ml-2"

                        //     type="checkbox"></input></div>} 
                            id="countryList" csvName={`Countries_${this.props?.compoundName}`} data={this.state.countryList} columns={this.columns} rowEvents={this.rowEvents} pagination="true" />
                </Row>}
            </div>

            {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
        </>
    }
}

export default withRouter(Country);