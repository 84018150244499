import React from 'react'
import { Col, Row, Button, Table, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import InputField from '../../common/input-fields/InputField';
import '../../Finance/MilestoneExpenses/ExpenseDetails/index'
import UserContext from '../../../context/UserContext';
import { isCoordinator } from '../../ToReviewDetails/commonFunctions';
export default class StateEx extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            invoiceItems: this.props.CountryData.states || [],
            error: {
                name: null
            },
            formData: {
                name: null
            },
            states: this.props.States
        };
    }
    componentDidMount = () => {

        let is_coordinator = isCoordinator(this.context);
        this.setState({ is_coordinator });
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (
            (prevProps.CountryData != this.props.CountryData))
            this.setState({
                invoiceItems: this.props.CountryData.states || [],

            })

    }
    getInvoiceItems = () => {
        return this.state.invoiceItems || [];
    }
    handleEvents = (option, data, changeField, key) => {
        console.log("data",data)
        let { invoiceItems, formData, states } = this.state;
        let emptyFormData = {
            name: ''
        };
        switch (option) {
            case 'ADD':
                if (this.doValidate()) {
                    return false;
                }
                let temp_id = invoiceItems.length + 1;
                invoiceItems.push({
                    name: formData.name,
                    temp_id
                });

                states.push({
                    name: formData.name,
                })

                this.setState({
                    invoiceItems: [...invoiceItems],
                    states,
                    formData: emptyFormData
                });
                break;
            case "REMOVE":

                invoiceItems.splice(
                    invoiceItems.indexOf(data), 1
                );
                states.push({
                    id: data.id,
                    name: data.name,
                    is_deleted: true


                })
                this.setState({
                    invoiceItems: [...invoiceItems],
                    states,
                    formData: emptyFormData
                });

                break;
        }
    }
    onBeforeDelete = (callback) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: false,
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "Ok",
                    value: true,
                    visible: true,
                    className: "btn-danger",
                    closeModal: true
                }
            }
        })
            .then(async (replace) => {
                if (replace) {
                    if (callback) {
                        callback();
                    }
                }
            });
    }
    handleValueChange = (e) => {
        let { name, value } = e.target;
        let { formData } = this.state;
        formData[name] = value;
        this.setState({ formData: { ...formData } });
    }
    doValidate = () => {
        let { invoiceItems, formData } = this.state;
        let { name } = formData;
        let error = [];
        let hasError = false;
        let customText = "Required";
        if (!name || name.toString().trim().length <= 0) {
            error.name = customText;
            hasError = true;
        }
        this.setState({ error: { ...error } });
        return hasError;
    }
    renderFields = () => {
        let { formData, error, is_coordinator } = this.state;

        return <><Row>
            <Col md={10}>

                <InputField disabled={!is_coordinator} name="name" value={formData["name"]} onChange={this.handleValueChange} label="State Name*" type="text"></InputField>
            </Col>
            <Col md={2}>
                <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                    <Button disabled={!is_coordinator} onClick={e => this.handleEvents("ADD")} size="sm"
                        className="d-flex align-items-center mt-3"
                        variant="dark">
                        <span className="material-icons mr-1 m-0">add </span>
                        <span style={{ fontSize: ' 0.75rem' }}>ADD</span>
                    </Button>
                </div>

            </Col>
        </Row>
            <Row>
                <Col md={5}>
                </Col>
                <Col md={3}>
                    {error["name"] && <p style={{ marginTop: '-1rem' }} className="text-danger ">{error["name"]}</p>}
                </Col>
                <Col md={2}>
                </Col>
            </Row>
        </>
    }

    renderActionButton = (item, key) => {
        let { is_coordinator } = this.state;
        return <div classname="d-flex w-10 h-100 align-items-center justify-content-center">

            {is_coordinator && <span onClick={e => {
                this.onBeforeDelete(() => {
                    this.handleEvents("REMOVE", item, "is_deleted", key)
                });
            }
            } className="material-icons" style={{ cursor: 'pointer' }}>
                close
            </span>}
        </div>
    }
    renderTable = () => {
        let { invoiceItems } = this.state;
        return <div style={{ maxHeight: '400px', overflowY: 'auto', fontSize: '.75rem' }}><Table responsive="sm">
            <thead style={{ position: 'sticky' ,top: -2, backgroundColor: '#FFF', zIndex: 1, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                <tr>
                    <th className='bg-white w-75'>State</th>
                    <th className="text-center bg-white ">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    invoiceItems && invoiceItems.map((item, index) => {
                        return <tr>

                            <td>{item.name ?? ""}</td>

                            <td className="text-center">{this.renderActionButton(item, index)}</td>
                        </tr>
                    })
                }
            </tbody>
        </Table>
        </div>;
    }
    render() {
        let { formData } = this.state;
        return <div className='d-flex flex-column mt-2'>
            <h5 className="pl-1">States</h5>
            {this.renderFields()}
            {this.renderTable()}
        </div>
    }
}