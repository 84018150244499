
import React, { Component } from 'react'
import { Button, Card, Col, Container, Form, Modal, Navbar, Row, Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { enableWFActions } from '../../../utility/helperFunctions';



import StickyNav from '../../common/sticky-nav/StickyNav';

import { dateChange, dateStringformat, dateTimeformat, getUserInfo } from '../../../utility/helperFunctions';
import UserContext from '../../../context/UserContext';

import API from '../../../api/apis';
import InputField from '../../common/input-fields/InputField';
import BootstrapModal from '../../common/modal/BootstrapModal';
import ReviewProposalModal from './ReviewProposalModal';
import ReviewFiles from '../ReviewFiles';
import { yyyymmddFormat } from '../../common/dateFormat';

class ReviewProposalDetail extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: true,
            edit: false,
            delete: false,
        },
        reviewTitle:"",
        type:"Proposal",
        reviewNumber:"",
        submission_id:"",
        viewEditData: {},
        showHideModal: false,
        viewEditFlag: null,
        fileList: [],
        selectedFile: null,
        successFile: false,
        errorFile: false,
        showLoader: false,
        AmendmentDetails: {},
        auditData:[],
        trailData:[],
        date_of_review:null
    }
    get inputFieldsArray() {
        return  [
            {
                label: "Review Number ",
                type: "text",
                disabled:true,
                name: "reviewNumber",
               
            },
            {
                label: "Review Title",
                type: "text",
                disabled:true,
                name: "reviewTitle",
               
            },
            {
                label: "Record Type",
                type: "text",
                disabled:true,
                name: "type",
               
            },
            
            {
                label: "Submission",
                type: "text",
                disabled:true,
                name: "submissionId",
               
            },
            {
                label: "Type of Review",
                type: "text",
                disabled:true,
                name: "type",
               
            },
            {
                label: "Date of Review",
                type: "date",
                disabled:true,
                name: "date_of_review",
               
            },
            {
                label: "Name of Medical Reviewer or Committee",
                type: "text",
                disabled:true,
                name: "name_medical_reviewer_committee",
               
            },
           
            {
                label: "Does study address an important problem?",
                type: "dropdown",
                disabled:true,
                name: "does_study_address_an_important_problem",
                options: ["Yes", "No"]
            },
            {
                label: "Advance scientific or clinical care?",
                type: "dropdown",
                disabled:true,
                name: "advance_scientific_or_clinical_care",
                options: ["Yes", "No"]
            },
            {
                label: "Are framework,design,methods developed?",
                type: "dropdown",
                disabled:true,
                name: "is_framework_design_methods_developed",
                options: ["Yes", "No"]
            },
            {
                label: "Project approach,method & outcome novel?",
                type: "dropdown",
                disabled:true,
                name: "project_approach_method_outcome_novel",
                options: ["Yes", "No"]
            },
            {
                label: "Are aims of study original & innovative?",
                type: "dropdown",
                disabled:true,
                name: "are_aims_of_study_original_innovative",
                options: ["Yes", "No"]
            },
            {
                label: "Rank the importance of this proposal",
                type: "dropdown",
                disabled:true,
                name: "rank_the_importance",
                options: [ "1", "2", "3", "4","5"]
            },
            
            {
                label: "Investigator suited to carry out study?",
                type: "dropdown",
                disabled:true,
                name: "investigator_suited",
                options: ["Yes", "No"]
            },
            
            {
                label: "Enrollment & Milestones feasible?",
                type: "dropdown",
                disabled:true,
                name: "enrollment_milestones_achievable",
                options: ["Yes", "No"]
            },
            
            {
                label: "Is requested budget appropriate?",
                type: "dropdown",
                disabled:true,
                name: "requested_budget_appropriate",
                options: ["Yes", "No"]
            },
            
            {
                label: "Approved?",
                type: "dropdown",
                disabled:true,
                name: "support_for_proposal",
                options: ["Yes", "No"]
            },
            {
                label: "Comments",
                type: "textarea",
                name: "comments",
                disabled:true,
            },
               
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled:true,
        }
        ,{
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled:true,
        }
    
        ];
    }
    async componentDidMount() {
    
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  true,
                edit: selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
        this.getReviewById(this.props.match.params.proposalId)

    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:true,
                    edit:  selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getReviewById = async (id, name) => {
        const res = await API.get(`review/proposal/${id}`);
        const viewEditData = { ...res.data };
       console.log(res.data)
       res.data["date_of_review"] = res.data.date_of_review != "" && res.data.date_of_review != null ?
       dateStringformat(res.data.date_of_review) : null;
       res.data["does_study_address_an_important_problem"]=res.data.does_study_address_an_important_problem!== null ? (res.data.does_study_address_an_important_problem===true?"Yes":"No"):"";
       res.data["advance_scientific_or_clinical_care"]=res.data.advance_scientific_or_clinical_care!== null ? (res.data.advance_scientific_or_clinical_care===true?"Yes":"No"):"";
       res.data["is_framework_design_methods_developed"]=res.data.is_framework_design_methods_developed!== null ? (res.data.is_framework_design_methods_developed===true?"Yes":"No"):"";
       res.data["project_approach_method_outcome_novel"]=res.data.project_approach_method_outcome_novel !== null ? (res.data.project_approach_method_outcome_novel===true?"Yes":"No") :"";
       res.data["are_aims_of_study_original_innovative"]=res.data.are_aims_of_study_original_innovative !== null ? (res.data.are_aims_of_study_original_innovative===true?"Yes":"No"):"";
       res.data["investigator_suited"]=res.data.investigator_suited !== null ? (res.data.investigator_suited===true?"Yes":"No"):"";
       res.data["enrollment_milestones_achievable"]=res.data.enrollment_milestones_achievable !== null ? (res.data.enrollment_milestones_achievable===true?"Yes":"No"): "";
       res.data["requested_budget_appropriate"]=res.data.requested_budget_appropriate !== null ? (res.data.requested_budget_appropriate===true?"Yes":"No") : "";
       res.data["support_for_proposal"]=res.data.support_for_proposal !== null ? (res.data.support_for_proposal===true?"Yes":"No") : "";
        res.data["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
        res.data["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt):res.data.createdby ;
        this.state.submission_id=res.data.submission_id;
        this.setState({
            ...res.data,
            viewEditData: viewEditData,
            submissionId:"Submission-"+res.data.submission_id,
            reviewTitle:"Submission-"+res.data.submission_id+" Proposal-Review-"+res.data.id,
            reviewNumber: `R-${res.data.id}`,
            showLoader: false
        })
    }
    handleProposalUpdate = async (state) => {
        state.submission_id = this.state.submission_id;
        state.updated_by = this.context.userSystemId;
        state["date_of_review"] = state.date_of_review != "" && state.date_of_review != null ?
        dateChange(state.date_of_review) : null;
        try {
            const res = API.post(`review/proposal/update/`, state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })

        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
        this.getReviewById(this.props.match.params.proposalId)
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
    }
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else
        { this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>{this.onInputChange(e,field)}) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    getFilesList = async (submission_id) => {
        try {
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            this.setState({
                fileList: res.data?.filter(file => file.type === "Amendment")
            });
        } catch (e) {
            console.log(e)
        }
    }
    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }

    uploadFile = () => {
        const formData = new FormData();
        formData.append(
            'file',
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        try {
            const res = API.post(`/user/proposals3/upload/Amendment/${this.state.AmendmentDetails.submission_id}`, formData);
            this.setState({
                selectedFile: null,
                successFile: true,
                fileMessage: "File uploaded!"
            })
        } catch (e) {
            this.setState({
                selectedFile: null,
                errorFile: true
            })
            console.log(e);
        }
    }
    handleDelete = async (fileName) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    try {
                        const res = API.delete(`/user/proposals3/delete/Amendment//${this.state.AmendmentDetails.submission_id}/${fileName}`);
                        swal("Deleted Successfully", {
                            icon: "success",
                        });

                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }

                }
            });


    }

    handleDownload = async (fileName) => {
        try {
            const res = await API.get(`/user/proposals3/download/getByid/Amendment//${this.state.AmendmentDetails.submission_id}/${fileName}`);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                successFile: true,
                fileMessage: "File Downloaded Successfully!"
            })
        } catch (e) {
            this.setState({
                errorFile: true
            })
            console.log(e);
        }
    }
    getAuditList = async (id) => {
        try {
            const temp = [];
            const res = await API.get(`audit/amendment/${id}`)
            console.log(res)
            await this.getTrailList();

            this.state.trailData.forEach(x => {
                res.data.forEach(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        temp.push(y);
                    }

                })
            })
            this.setState({
                auditData: temp,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/amendment`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false
        });
        this.getReviewById(this.props.match.params.proposalId)
    }
    onNavOut = () => {
        window.history.back()
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData } = this.state
        return (
            <>
                <StickyNav>
                    <Navbar expand="sm" className="nav-secondary">
                        <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                            <i className="bi bi-arrow-left">
                            {" "}{this.state.submissionId}
                            </i>
                        </div>
                        <h4 className="text-center" style={{ "width": "66%" }}>Proposal Review</h4>
                        {this.state.access.edit && enableWFActions()
                        && <div className="text-right" style={{ "width": "20%" }}>
                            <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                        </div>}
                    </Navbar>
                </StickyNav>
                <Row>
                    <Col md={6}>
                        <Card className="data-cards pt-3">
                            <Card.Body>
                                <Row>
                                    {this.renderInputFields()}
                                    {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} >
                                        Save
                                    </Button>
                                </div> */}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card className="data-cards">
                            <Card.Body>
                                <Tabs defaultActiveKey="files">
                                    <Tab eventKey="files" title="Files">
                                    {this.state.submission_id && this.state.id && <ReviewFiles
                                       access = {this.state.access}
                                       type = "Proposal"
                                       submissionid ={this.state.submission_id}
                                       id = {this.state.id}
                                       ></ReviewFiles>}
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                      
                    </Col>
              
                </Row>
               
                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body="Updated Successfully!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.successFile &&
                    <BootstrapModal
                        show={this.state.successFile}
                        header="Success"
                        body={this.state.fileMessage}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorFile &&
                    <BootstrapModal
                        show={this.state.errorFile}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                {showHideModal &&
                    <ReviewProposalModal
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handlePublicationSave={this.handlePublicationSave}
                        handleProposalUpdate={this.handleProposalUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}
                        title={this.state.reviewTitle}
                        type={this.state.type}
                    />}
            </>
        )
    }
}


export default withRouter(ReviewProposalDetail);