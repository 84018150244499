import Alert from 'react-bootstrap/Alert'
import React, { Component } from 'react'
import { Button, Card, Col, Nav, Row, } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';


class Reports extends Component {
   
   

   
  
  
    render() {
       
       
        return (
            <div className="inpage-content" style={{ height: "450px" }}>
               
                 <Row className="dashboard-main">
                
                <Col md={12}>                    
                <h5 className="text-center">Please click on link below to open reports in a new tab:</h5>
                            {/* <p className="bi bi-journal-medical pt-3 pl-5 text-center"> Submissions</p> */}
                            <Nav>
                            <Nav.Link  as={NavLink} to="/reports"><h2 className="bi bi-graph-up text-center" > Reports</h2></Nav.Link>
                            </Nav>
                          
                </Col>
               
            </Row>
            </div>
           
        )
    }
}

export default withRouter(Reports);