import React from 'react'
import {ListGroup, Modal} from 'react-bootstrap';
import API from '../../../api/apis';
import Button from '../../common/Button';
import { showMessage } from '../../ToReviewDetails/commonFunctions';
import CsvUpload from './CsvUpload';
import Header from './Header';
import Preview from './Preview';
import UserContext from '../../../context/UserContext';
import moment from 'moment';
import { error_messages_parts } from './field_constants';
import { set } from 'date-fns';
import CustomError from './CustomError';
import { GLOBAL_DATE_FORMAT_NOTIME } from '../../../utility/dateHelper';
const BulkEnrollContext = React.createContext({});
export default class BulkEnrollment extends React.Component{
    static contextType=UserContext;
    constructor(props){
        super(props);
        this.state={
            navigation:this.navigationLink[0],
            formData:{
                handleEvents:this.handleStageEvents,
                data:{}
            }
        }
    }
    stage={
        "STAGE_1":"STAGE_1",
        "STAGE_2":"STAGE_2",
        "STAGE_3":"STAGE_3"
    }
    navigationLink=[
        {node:'STAGE_1',next:'STAGE_2',prev:null,caption:"Adding Milestone Expense by Import"},
        {node:'STAGE_2',next:'STAGE_3',prev:'STAGE_1',caption:"Please review the uploaded Milestone Expenses CSV"}      ,
        {node:'STAGE_3',next:null,prev:'STAGE_2',caption:"Confirm the Added Milestone Expenses"}    
    ]
    componentDidMount(){
       this.initData();
    }   
    initData=async()=>{

         /*Fetching Milestones data for the submission id */
        let url = `/finance/milestoneList/${this.props.submissionId}`;
        let milestoneResponses = await API.get(url);
        let milestones = [];
        let submissionInfo = {};
        let submissionFields = [];
        if (milestoneResponses && milestoneResponses.data) {
            milestones = milestoneResponses.data || [];
        }
        let milestoneWithSubmissionsData = milestones.filter(p => p.submission_fields)
        if (milestoneWithSubmissionsData) {
            submissionFields = milestoneWithSubmissionsData.map(p => p.submission_fields);
        }

        /*Fetching Submission Related data for the MIlestones */
        let payLoad = {
            "submissionId": +this.props.submissionId,
            "custom_fields": 'currency_id,' + submissionFields.join(",")
        };

        let submissionResponse = await API.post('/finance/submission/custom', payLoad);
        if (submissionResponse) {
            submissionInfo = submissionResponse.data ?? null;
        }

        /*Fetching Currency Information */
        let currencyData = null;
        const currencyList = await API.get(`seed/currency/data`);
        if (currencyList && currencyList.data && currencyList.data.success) {
            currencyData = currencyList.data.data.filter(item => item.id === submissionInfo.currency_id)[0];
        }

        this.setState({ milestones, submissionInfo, currencyData });
    }
    formatCsvDate=(item,field)=>{
        if(item[field]&&item[field].toString().trim().length>0){
            let mdate=moment(item[field].toString().trim(),GLOBAL_DATE_FORMAT_NOTIME,true);
            if(mdate.isValid()){
               return mdate.format(GLOBAL_DATE_FORMAT_NOTIME);
            }
        }
        return item[field]||null;
    }
    handleStageEvents=(e,data)=>{
       
        switch(data.option){
            case "CSVDATA":
            let {formData,currencyData,submissionInfo,milestones}=this.state;
            formData.csv=data.csv.data||[];  
            try{
                if(formData.csv.length>0){
                    formData.csv.forEach((item)=>{
                        item.currency_id=submissionInfo.currency_id;
                        item.milestone=item.milestone?item.milestone.toString():null;
                        item.user_name=`${this.context.userFirstName} ${this.context.userLastName}`
                        if(currencyData){
                            item.currency_name=  currencyData.name;
                        }
                        if(item["milestone"]&&!item["new_milestone"] || (item["new_milestone"] &&item["new_milestone"].toString()!="Y")){
                            let milestone= milestones.find(p=>p.name.toLowerCase()===item["milestone"].toLowerCase());
                            if(milestone&&milestone.submission_fields){
                                let data=submissionInfo[milestone.submission_fields];
                                if(data&&data.trim().length>0){
                                    item.estimated_date=moment(data,false).format(GLOBAL_DATE_FORMAT_NOTIME);
                                }
                            }
                        }                        
                        item["estimated_date"]=this.formatCsvDate(item,"estimated_date");
                        item["date_received"]=this.formatCsvDate(item,"date_received");
                    });
                }  
            }
            catch(exp){

            }           
            this.setState({formData},this.initData);
            break;
        }
    }
    handleBulkInsert=async ()=>{
        this.setState({isIUDOperation:true});
        try{
            let {formData,milestones}=this.state;
            let {csv}=formData;
            let processData=JSON.parse(JSON.stringify(csv));
            processData.forEach((item)=>{
                delete item.user_name;
                delete item.currency_name;
                delete item.currency_id;
               // item.created_by=this.context.userSystemId;
                //item.submissionId=this.props.submissionId;
                let milestone= milestones.find(p=>p.name.toLowerCase()===item["milestone"].toLowerCase());               
                if(item.new_milestone&&item.new_milestone.toUpperCase()==="Y"){
                    item.new_milestone=true;
                }
                else{
                    item.new_milestone=false;
                }
                if(milestone&&!item.new_milestone){
                    item.milestone=milestone.id;
                }
                if(item.estimated_date&&moment(item.estimated_date,GLOBAL_DATE_FORMAT_NOTIME,true).isValid()){
                    item.estimated_date=moment(item.estimated_date,GLOBAL_DATE_FORMAT_NOTIME,true).format("MM/DD/YYYY");
                }
                if(item.date_received&&moment(item.date_received,GLOBAL_DATE_FORMAT_NOTIME,true).isValid()){
                    item.date_received=moment(item.date_received,GLOBAL_DATE_FORMAT_NOTIME,true).format("MM/DD/YYYY");
                }
            });
            let data = {
                "common_details": {
                    "created_by": this.context.userSystemId,
                    "submissionId": this.props.submissionId
                },
                "trialexpenses_details": processData
            };
            let apiResponse=await API.post(`/finance/milestone/bulk/create`,data);
            if(apiResponse&&apiResponse.data){
                let response=apiResponse.data;
                if(response===true){
                    showMessage({
                        text: "Milestone Expenses Submitted!",
                        icon: "success",
                        button: "Ok",
    
                    }, (e) => {
                        if(this.props.onClose){
                            this.props.onClose(e);
                        }
                      });
                }
                else{
                    let message= "Oops! Something went wrong.";
                    if(response.error&&response.error.toLowerCase()=="Unique Constraint Error".toLowerCase())
                    {
                        message="Record(s) already exist!."
                    }
                    showMessage({
                        text: message,
                        icon: "error",
                        button: "Ok",
    
                    }, (e) => {
                       
                      });
                    console.log("Error Bulk Update",response);
                }

            }
            console.log("handleBulkInsert",apiResponse)
        }
        catch(ex){
            console.log("handleBulkInsert,error",ex)
        }
        finally{
            this.setState({isIUDOperation:false});
        }

    }
    handleEvents=async (e,option)=>{
        let { navigation,isIUDOperation } = this.state;
        if(isIUDOperation)return false;
        let filterMode=null;
        if(option==="NEXT"&&(!this.state.formData.csv||this.state.formData.csv.length<=0)){
            return false;
        }
       
         if(option==="NEXT"&&navigation.node&&navigation.next){        
           
            switch(navigation.node){
                case this.stage.STAGE_2:
                    let validationStatus=await this.doValidateData();
                  
                    if(validationStatus){
                        filterMode="next";
                    }
                    else{
                        filterMode=null;
                    }
                    break;
                 default:
                    filterMode="next";break;
            }
        }
        else if(option==="NEXT"&&navigation.node&&navigation.node===this.stage.STAGE_3){
            this.handleBulkInsert();
        }
        else  if(option==="BACK"&&navigation.node&&navigation.prev){
            if(navigation.node===this.stage.STAGE_2){
                let {formData}=this.state;
                formData.csv=null;
                this.setState({formData:{...formData}});
            }
            filterMode="prev";
        }
        else  if(option==="BACK"&&navigation.node&&!navigation.prev){
            if(this.props.onClose){
                this.props.onClose(e);
            }
        }
        if(filterMode){
           
            let nextView=this.navigationLink.find(p=>p.node===navigation[filterMode]);
            let data={navigation:nextView};
            this.setState({...data});
        }
    }
    getForm=()=>{
        let { navigation,formData } = this.state;
        let view=this.stage;
        let pageProps={
            formData,
            navigation
        };
        switch (navigation.node) {
            case view.STAGE_1:          
            return (<CsvUpload {...pageProps}></CsvUpload>);break;
            case view.STAGE_2:
            case view.STAGE_3:
           
            return (<Preview {...pageProps} isFinalStage={navigation.node===view.STAGE_3}></Preview>);break;
        }
    }
    renderErrorMessage=(validationErrors)=>{
        let uniqueErrors=[...new Set([...validationErrors])];
        return <>
            {validationErrors&&validationErrors.length>0&&<ul>
                {uniqueErrors && uniqueErrors.map((item, index) => {
                    return <li key={index}>{item}</li>
                })}
            </ul>}
        </>
    }
    doValidateData=async()=>{
        let {submissionId}=this.props;
      
        let { navigation,formData,milestones,submissionInfo } = this.state;
        this.setState({isIUDOperation:true});
       let validationErrors=[];
        try{
            if(formData&&formData.csv){
               let {csv}=formData;
               if(csv&&csv.length>0){

                let emptyMilestones=csv.filter(p=>!p['milestone']||p['milestone'].toString().trim().length<=0);
                let anyIvalidMilestones=false;
                let anyMilestoneEmptyDate=false;
                let anyInvalidDate=false;
                let anyInvalidAmount=false;
                if(emptyMilestones.length>0){
                    validationErrors.push(error_messages_parts["EMPTY_MILESTONE"]);    
                }
                csv.forEach((item,index)=>{                    
                   let milestone= milestones.filter(p=>p.name.toLowerCase()===(item["milestone"]||'').toLowerCase());
                   
                   if(milestone&&milestone.length<=0&&(!item["new_milestone"]||item["new_milestone"]&&item["new_milestone"]!="Y")){
                        anyIvalidMilestones=true;      
                        validationErrors.push(error_messages_parts["NEW_MILESTONE"]);             
                   }
                   else{
                    milestone.forEach(p=>{       
                        if(p.submission_fields && (!item["new_milestone"]||item["new_milestone"]&&item["new_milestone"]!="Y")){
                            let data=submissionInfo[ p.submission_fields];
                            if(!data||data.trim().length<=0){
                             anyMilestoneEmptyDate=true;
                             validationErrors.push(error_messages_parts[p.name.toUpperCase()]+` ${error_messages_parts.MILESTONE_PART}`);                                                        
                            }
                        }   
                    })
                   }
                   if(!anyMilestoneEmptyDate&&item["estimated_date"]&&item["estimated_date"].trim().length>0){
                   let obMoment= moment(item["estimated_date"].trim(),GLOBAL_DATE_FORMAT_NOTIME,true);;
                    if(!obMoment.isValid()){
                        anyInvalidDate=true;
                        validationErrors.push(error_messages_parts.DATE);      
                    }
                   }
                   if(item["date_received"]&&item["date_received"].trim().length>0){
                    let obMoment= moment(item["date_received"].trim(),GLOBAL_DATE_FORMAT_NOTIME,true);;
                     if(!obMoment.isValid()){
                         anyInvalidDate=true;
                         validationErrors.push(error_messages_parts.DATE);  
                     }
                    }
                    let validNUmericExperssion=/^[+]?(?=.)(?:\d+,)*\d*(?:\.\d+)?$/;

                    if(item["estimated_cost"]&&item["estimated_cost"].toString().trim().length>0){
                        if(!validNUmericExperssion.test(item["estimated_cost"].toString().trim())){
                            anyInvalidAmount=true;
                            validationErrors.push(error_messages_parts.ESTIMATED_AMOUNT);  
                        }
                    }
                    if(item["actual_amount"]&&item["actual_amount"].toString().trim().length>0){
                        if(!validNUmericExperssion.test(item["actual_amount"].toString().trim())){
                            anyInvalidAmount=true;
                            validationErrors.push(error_messages_parts.ACTUAL_AMOUNT);  
                        }
                    }
                });
               if(emptyMilestones.length>0||anyIvalidMilestones||anyMilestoneEmptyDate||anyInvalidDate||anyInvalidAmount)
               {
                this.setState({isIUDOperation:false,showError:true,validationErrors});
                // showMessage({
                //     text: this.renderErrorMessage(validationErrors),
                //     icon: "error",
                //     button: "Ok",
        
                // }, (e) => {
                    
                //   });
                  return false;
               }
               }

            }
        }
        catch(exp){

        }
        this.setState({isIUDOperation:false});
        return true;
    }
    render(){
        let { navigation,isIUDOperation,showError,validationErrors } = this.state;

        return <><Modal
        size={"xl"}
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body className="d-flex flex-column">
                <Header currentStage={navigation.node} onClose={e=>{
                    if(this.props.onClose){
                        this.props.onClose(e);
                    }
                }} caption={navigation.caption}></Header>
                {this.getForm()}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center position-relative">
        {isIUDOperation&&<div className="d-flex align-items-center justify-content-center p-2 position-absolute w-100 h-100">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>}
                <Button style='accent h-md' className="p-2 pr-3 pl-3 d-flex align-items-center mr-5" onClick={e => this.handleEvents(e, 'BACK')}>
                    <div className='d-flex align-items-center text-dark'>
                        <span>BACK</span>
                    </div>
                </Button>
                <Button style='success h-md' className="p-2 pr-3 pl-3 d-flex align-items-center" onClick={e => this.handleEvents(e, 'NEXT')}>
                    <div className='d-flex align-items-center text-dark'>
                        <span>{navigation.node===this.stage.STAGE_3?"SUBMIT":'NEXT'}</span>
                    </div>
                </Button>
        </Modal.Footer>
        </Modal>
            {showError && <CustomError onHide={e => {
                this.setState({ showError: false, validationErrors: [] })
            }} >
                {this.renderErrorMessage(validationErrors)}
            </CustomError>}
        </>

    }
}