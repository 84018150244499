import { convertFromRaw, convertToRaw,EditorState,ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React from 'react'
import { Col, Row} from 'react-bootstrap';
import API from '../../../../api/apis';
import InputField from '../../../common/input-fields/InputField';
import UserContext from '../../../../context/UserContext';
import { showMessage } from '../../commonFunctions';
import Button from '../../../common/Button';
export default class EditInstruction extends React.Component{
    static contextType=UserContext;
    constructor(props) {
        super(props);
        this.state={
            content:'',
            id:null,
            reviewer_name:"",
            showLoader:false
        }
    }
    componentDidMount=()=>{
        let {id,content,reviewer_name}=this.props;
        let editroData=this.getEditorValueFromHtml((content||''));//.replaceAll(/\s+/gi, " "));
        this.setState({
            id,content:editroData,reviewer_name
        })

    }
    onEditorChange=(val, e) => {
        this.setState({
            [e]: val
        });
    };
    getEditorValueFromHtml = (html="") => {
        if(html){
            const blocksFromHTML = convertFromHTML(html);        
            const state = ContentState.createFromBlockArray(blocksFromHTML);
            return EditorState.createWithContent(state);
        }
        return "";
    }
    getHtmlFromEditorValue = (val="") => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }
   
    handleCancel=()=>{
        if(this.props.handleEvents){
            this.props.handleEvents("CANCEL",{})
        }
    }
    handleSave=async ()=>{
        try{
            this.setState({showLoader:true});
            let formData={};
            formData= {
                "id": this.state.id,  
                "content":this.getHtmlFromEditorValue(this.state.content),            
                "updated_by": this.context.userSystemId           
            }
            const res = await API.post(`reviewConcept/instruction/update`,formData); 
            this.setState({showLoader:false});          
            if(res&&res.data&&!(res.data.error || res.data.errors)){               
                showMessage({                  
                    text: "Updated Successfully",
                    icon: "success",
                    button: "Ok",
                  
                  },(e)=>{
                    if(this.props.handleEvents){
                        this.props.handleEvents("SAVE",{})
                    }
                  });
            }
            else{
                this.showFailedMessage(res)
            }
        }
        catch(exp){
            this.showFailedMessage(exp)
        }        
        finally{
            this.setState({showLoader:false});
        }
    }
    showFailedMessage=(exp)=>{
        console.error("--save-ins--",exp);
            showMessage({                  
                text: "Failed to update the record!",
                icon: "error",
                button: "Ok",
              
              },(e)=>{});
    }
    render(){
        return <div className="w-100 d-flex flex-columns p-5 new-flow-modal">
            <Row>
                <Col sm={12}>
                    <InputField
                        label="Reviewer Name"
                        type="text"
                        name="reviewer_name"
                        value={this.state.reviewer_name}
                        disabled={true}
                    ></InputField>
                </Col>
                <Col sm={12}>
                    <InputField
                        label="Instruction"
                        type="text-editor"
                        name="content"
                        onChange={this.onEditorChange}
                        value={this.state.content}
                    >
                    </InputField>
                </Col>
                <Col sm={12}>
                        <div className="d-flex align-items-center justify-content-center pr-3 mr-5 w-100">
                            <Button  className="mr-2 p-2" style="danger w-100px" onClick={this.handleCancel}>Cancel</Button>
                            <Button  onClick={this.handleSave} className="mr-3 p-2 d-flex align-items-center justify-content-center" style="success w-100px"><span>Save</span></Button>
                        </div>
                </Col>           
            </Row>         
           
        </div>
    }
}