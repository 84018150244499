import React, { Component } from 'react'

import { Button, Col, Row, Card } from 'react-bootstrap';

import Navbar from 'react-bootstrap/Navbar'

import { withRouter } from 'react-router';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import { dateTimeformat, getUserInfo } from '../../../utility/helperFunctions';
// import { yyyymmddFormat } from '../dateFormat';
// import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';

import InputField from '../../common/input-fields/InputField';
import StickyNav from '../../common/sticky-nav/StickyNav';
import BootstrapModal from '../modal/BootstrapModal';
import PresentationModal from './PresentationModal';


class PresentationDetail extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access : {
            view : true,
            create : false,
            edit : false,
            delete : false,
        },
        successModal: false,
        errorModal: false,
        hasChanged: false,
        countryName:this.props.location.state.countryName,
        countryId:this.props.location.state.countryId,
        viewEditData:{},
        showHideModal: false,
        viewEditFlag: null,
        currencyList: [],
        currencyName:"",
        investigatorId:"INVID-"+ ("000000" + this.props.location.state.investigatorId).slice(-7)
      
    }
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        }

    ];


    get inputFieldsArray() {
        return [
            {
                label: "Presentation Name *",
                type: "text",
                name: "name",
               
               
            },
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }
       
        ];
    }
    async componentDidMount() {
        await this.getPresentationById(this.props.location.state.presentationId)
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create : selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit : selectedRole.includes("global_coordinator") ||  selectedRole.includes("administrator")  ,
                delete : selectedRole.includes("administrator")  ||  selectedRole.includes("global_coordinator") ,
            },
            reloadTable: true,

        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create : selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit : selectedRole.includes("global_coordinator") ||  selectedRole.includes("administrator")  ,
                    delete : selectedRole.includes("administrator")  ||  selectedRole.includes("global_coordinator") ,
                },
                reloadTable: true,

            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getPresentationById = async (id) => {
        try {

            const res = await API.get(`/seed/presentation/getbyid/${id}`);
            const viewEditData=res.data?.data?{...res.data.data}:{};
            // res.data["currency"] = this.state.currencyList.filter(item => item.id === res.data.currency_id)[0];
            
            res.data["createdby"] =res.data.created_by?.length ? await getUserInfo(res.data.created_by)+"  "+ dateTimeformat(res.data.createdAt) : "";

            res.data["updatedby"] =res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt):res.data.createdby ;
            
           

            this.setState({
                ...res.data,
               presentationName:res.data.name,
                viewEditData:viewEditData,
                showLoader: false
            })
         
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    onSearchSelect1 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            currency_id: e.id
        });
    }
    onInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
        });
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.checked },

        })
    }
    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
        });
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            patchObject: { ...this.state.patchObject, [e]: this.getHtmlFromEditorValue(val) },
            hasChanged: true
        });
    };
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }
    handlePresentationUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
       
        try {
            const res = await API.post("/seed/presentation/update", state);
            if(res.data.success){
                this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getPresentationById(this.props.location.state.presentationId)
            }else{
                this.setState({ errorModal: true });
            }

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    onNavOut = () => {
       window.history.back()
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
}


    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getPresentationById(this.props.location.state.presentationId)
    }

    render() {

        const { showHideModal, viewEditFlag, viewEditData, success_body, errorModal,successModal } = this.state;

        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                            {this.state.presentationName}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Presentation Detail </h4>
                   {this.state.access.edit && <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                                
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
               
            </Row>
           
            { showHideModal &&
                <PresentationModal
             showHideModal={showHideModal}
             handleModalShowHide={this.handleModalShowHide}
             handlePresentationSave={this.handlePresentationSave}
             handlePresentationUpdate={this.handlePresentationUpdate}
             viewEditFlag={viewEditFlag}
             viewEditData={viewEditData}
            />}
             {this.state.successModal &&
             <BootstrapModal
                 show={successModal}
                 header="Success"
                 body={success_body}
                 changeShowState={this.changeShowState}
             />
         }
        {this.state.errorModal &&
             <BootstrapModal
                 show={errorModal}
                 variant="danger"
                 header="Ooops!!"
                 body="An error occured! Please try again later!"
                 changeShowState={this.changeShowState}
             />
         } 
        </>
    }
}

export default withRouter(PresentationDetail);
