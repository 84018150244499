import React, { Component } from 'react'

import { Button, Col, Row, Card } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../sticky-nav/StickyNav';
import InputField from '../input-fields/InputField';

import API from '../../../api/apis';
import BootstrapModal from '../modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../loader/Loader';
import UserContext from '../../../context/UserContext';
import { yyyymmddFormat } from '../dateFormat';
import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';
import StateModal from './StateModal';



class StateDetail extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access : {
            view : true,
            create : false,
            edit : false,
            delete : false,
        },
        owner:"",
        successModal: false,
        errorModal: false,
        hasChanged: false,
        countryName:this.props.location.state.countryName,
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        currencyList: [],
      
    }
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        }

    ];


    get inputFieldsArray() {
        return [{
            label: "Information *",
            type: "alert",
           varient:"secondary"
        },
        {
            label: "State Name *",
            type: "text",
            name: "name",
            disabled:true
           
        },
        // {
        //     label: "Owner",
        //     type: "text",
        //     name: "owner",
        //    disabled:true
        // },
        {
            label: "Created By",
            type: "text",
            name: "created_by",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updated_by",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }
       
        ];
    }
    async componentDidMount() {
        this.state.owner=await getUserInfo(this.context.userSystemId)
        await this.getReforcastByExpenseId(this.props.location.state.countryId)
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator"),
                edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
            reloadTable: true,

        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator"),
                    edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
                reloadTable: true,

            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getReforcastByExpenseId = async (id,name) => {
        try {

            const expenseDetails = await API.get(`/seed/state/getbyid/${id}`);
            const viewEditData={...expenseDetails.data};
            expenseDetails.data["created_by"] =expenseDetails.data.created_by?.length ? await getUserInfo(expenseDetails.data.created_by)+"  "+ dateTimeformat(expenseDetails.data.createdAt) : "";
            expenseDetails.data["updated_by"] =expenseDetails.data.updated_by?.length > 0 ? await getUserInfo(expenseDetails.data.updated_by) + "  " + dateTimeformat(expenseDetails.data.updatedAt):expenseDetails.data.created_by ;
            
            this.state.countryName=expenseDetails.data.name

            this.setState({
                ...expenseDetails.data,
                countryName:expenseDetails.data.name,
                viewEditData:viewEditData,
                showLoader: false
            })
           console.log("name",this.state.countryName)
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }


    onInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
        });
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.checked },

        })
    }
    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
        });
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            patchObject: { ...this.state.patchObject, [e]: this.getHtmlFromEditorValue(val) },
            hasChanged: true
        });
    };
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
    window.history.back()
    }

    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
}
handleReforecastUpdate = async (state) => {
    state["countryname"]=this.props.countryName;
    state.updated_by = this.context.userSystemId;
    try {
        const res = await API.post("seed/state/edit/update", state);
        this.setState({
            success_body: "Updated successfully!",
            successModal: true,
            hasChanged: false,
            showHideModal: false,
            viewEditFlag: null,
            viewEditData: {},
        });
        this.getStateList();

    } catch (err) {
        this.setState({ errorModal: true });
    }
}

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getReforcastByExpenseId(this.props.location.state.countryId)
    }

    render() {

        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal,deleteModal } = this.state;

        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                        {" "}{this.state.countryName}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>State Detail </h4>
                   {this.state.access.edit && <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                                {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} >
                                        Save
                                    </Button>
                                </div> */}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
               
            </Row>
            { showHideModal &&
               <StateModal
            showHideModal={showHideModal}
            handleModalShowHide={this.handleModalShowHide}
            handleReforecastSave={this.handleReforecastSave}
            handleReforecastUpdate={this.handleReforecastUpdate}
            viewEditFlag={viewEditFlag}
            viewEditData={viewEditData}
            
           />}
            {this.state.successModal &&
                <BootstrapModal
                    show={this.state.successModal}
                    header="Success"
                    body="state has been Updated!"
                    changeShowState={this.changeShowState}
                />
            }
            {/* {this.state.errorModal &&
                <BootstrapModal
                    show={this.state.errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            } */}
        </>
    }
}

export default withRouter(StateDetail);
