import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import { yyyymmddFormat } from '../../common/dateFormat';

import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';
import Loader from '../../common/loader/Loader';

class StudyCompoundModal extends React.Component {
    state = {
        compoundList: [],
        hasChanged: false,
        submissionId: "Submission-" + this.props.submissionId,
        study_compounds_name: "",
        isLoader:true
    }
    async componentDidMount() {
        const { viewEditData, viewEditFlag } = this.props;
        await this.getCompoundList();
        if (viewEditFlag === "edit") {
            viewEditData["createdby"] = viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
            viewEditData["last_modifiedby"] = viewEditData.last_modified_by?.length ? await getUserInfo(viewEditData.last_modified_by) + "  " + dateTimeformat(viewEditData.updatedAt) :viewEditData["createdby"] ;
            this.setState({
                ...this.state,
                ...viewEditData,
                submissionId: "Submission-" + viewEditData.submissionId,
                patchObject : {id : viewEditData["id"]},
                isLoader:false

            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
    }
    getCompoundList = async () => {
        try {
            let compoundList = await API.get(`seed/compound/fulldata`);
            const resultArray = (compoundList.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id }));

            this.setState({
                compoundList: resultArray,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    get inputFieldsArray() {
        return [
            {
                label: "Study Compounds Name *",
                type: "text",
                name: "study_compounds_name",

            },
            {
                label: "Submission *",
                type: "text",
                placeholder: "Select Submission",
                name: "submissionId",
                disabled: true
            },
            {
                label: "[EXT] Compound",

                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                onSelect: this.onSearchSelect,
                name: "compound",
            }, {
                label: "Compound Role",
                type: "dropdown",
                placeholder: "None",

                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                name: "compound_role",
            }, {
                label: "Compound 1",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                onSelect: this.onSearchSelect,

                name: "compound_1",
            }, {
                label: "Compound 1 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                placeholder: "None",

                name: "compound_role_1",
            }, {
                label: "Compound 2",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                onSelect: this.onSearchSelect,

                name: "compound_2",
            }, {
                label: "Compound 2 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                placeholder: "None",

                name: "compound_role_2",
            }, {
                label: "Compound 3",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                onSelect: this.onSearchSelect,

                name: "compound_3",
            }, {
                label: "Compound 3 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                placeholder: "None",

                name: "compound_role_3",
            }, {
                label: "Compound 4",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                onSelect: this.onSearchSelect,

                name: "compound_4",
            }, {
                label: "Compound 4 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],

                placeholder: "None",
                name: "compound_role_4",
            }, {
                label: "Compound 5",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                onSelect: this.onSearchSelect,

                name: "compound_5",
            }, {
                label: "Compound 5 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                placeholder: "None",

                name: "compound_role_5",
            }, {
                label: "Compound 6",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: this.state.compoundList,
                onSelect: this.onSearchSelect,

                name: "compound_6",
            }, {
                label: "Compound 6 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                placeholder: "None",

                name: "compound_role_6",
            },

            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled:true,
                edit: true
            }, {
                label: "Last Modified By",
                type: "text",
                name: "last_modifiedby",
                className:"createUpdateByfontsize",
                disabled:true,
                edit: true
            },

        ];
    }

    onInputChange = (e) => {
        this.setState({ hasChanged: true, [e.target.name]: e.target.value,
            patchObject:  {...this.state.patchObject,[e.target.name]: e.target.type === "number" ? parseInt(e.target.value) : e.target.value}
        });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e},

        });
    }

    onSearchSelect = (e, name) => {
        console.log(e)
        console.log(name)
        this.setState({
            hasChanged: true,

            [name]: e.value,
            patchObject:  {...this.state.patchObject, [name]: e.value},

            // currency_iso_code: e.value
        });
    }
    handleStudyCompoundUpdateModal = () => {
        let patchObject = this.state.patchObject;
        this.props.handleStudyCompoundUpdate(patchObject);
    }
    formValidation = () => {
        let is_valid = true;
        if (this.state.study_compounds_name === "") {
            this.setState({
                error: {
                    study_compounds_name: true
                }
            })
            is_valid = false;
        }
        return is_valid
    }
    onSave = () => {
        if (this.formValidation()) {
            this.props.handleStudyCompoundSave(this.state);
        }
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleStudyCompoundSave, viewEditFlag, handleStudyCompoundUpdate } = this.props;
        const { study_compounds_name } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg">
                {this.state.isLoader=== true ?
        <Loader /> :  <div>  <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit  ${study_compounds_name}` : "New Study Compound"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                            viewEditFlag === "edit" ?
                                <Button variant="primary" onClick={this.handleStudyCompoundUpdateModal}>
                                    Save
                                </Button> : <Button variant="primary" onClick={this.onSave}>
                                    Save
                                </Button>
                        }

                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default StudyCompoundModal;