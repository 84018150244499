import React from "react";
import Tab from "../ToReviewDetails/Tab";
import { Modal } from 'react-bootstrap';
import Button from "../common/Button";
import BulkEnrollment from "./BulkEnrollment";
export default class EnrollmentModal extends React.Component{
    constructor(props){
        super(props);
    }
    state = {
        isOpen: false
      };
      openModal = () => this.setState({ isOpen: true });
      closeModal = () => this.setState({ isOpen: false });
    onTrigger = () => {
        // let manualEntryModal = true;
        this.props.parentCallback('manualEntryModal');
    }
    renderModalComponent=()=>{
        const { handleModalShowHide, showHideModal} = this.props;
        return (
            <>
            <Modal show={showHideModal}
            size="md"
            className="modal-mdx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Header style={{ border: 0 }} className="d-flex" >                
                <Modal.Title className="w-100 d-flex justify-content-center flex-grow-1 text-center  pt-5">
                            <h5 class='modal-title text-center w-100'>
                                Adding Enrollment</h5>
                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" onClick={e=>{
                                handleModalShowHide(false)
                            }} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                </Modal.Header>
                    <Modal.Body className='modal-body text-left w-100 p-5'>Please select the option that you would like to use to add enrollment or additional
                        enrollment</Modal.Body>
                    <Modal.Footer className="border-0 d-flex align-items-center justify-content-center pb-5">
                        <Button style='accent h-md'  className="p-2" onClick={this.onTrigger}>
                            <div className='d-flex align-items-center text-dark'>
                                <span class="material-icons mr-1" >
                                    calendar_today</span>
                                <span>  QUARTERLY ENTRY</span>
                            </div>
                            </Button>
                        <Button  style='accent h-md' className="p-2" onClick={e=>{
                            this.setState({showBulkModal:true});
                        }} >
                            <div className='d-flex align-items-center text-dark'>
                                <span class="material-icons mr-1">send </span>
                                <span>IMPORT ENROLLMENT CSV</span>
                            </div>
                        </Button>
                    </Modal.Footer>
              </Modal>
            </>
          );
    }
    
    render(){
        let {showBulkModal}=this.state;
        return <div className="mt-3">
            <div>
                {!showBulkModal&&this.renderModalComponent()}
                {showBulkModal&&<BulkEnrollment 
                currency_id={this.props.currency_id||""}
                submissionId={this.props.submissionId} 
                onClose={e=>{
                    this.setState({showBulkModal:false});
                }}></BulkEnrollment>}
            </div>
        </div>
    }
}