import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../../../common/input-fields/InputField';
import API from '../../../../../api/apis';
import dateFormat from "../../../../common/dateFormat";
import BootstrapModal from '../../../../common/modal/BootstrapModal';
import { isCoordinator } from '../../../../ToReviewDetails/commonFunctions';
import UserContext from '../../../../../context/UserContext';
import { getUserInfo, dateTimeformat, dateTimeStringformat, dateStringformat } from '../../../../../utility/helperFunctions';
import Loader from '../../../../common/loader/Loader';
import * as IIRBtn from '../../../../common/Button';
import LoaderEx from '../../../../common/loader/LoaderEx';
import SwitchView from '../../../../common/SwitchView';
import { switchEnum } from '../../../../common/SwitchView/switchEnum';
import SwitchHistory from '../../../../common/SwitchHistory';
import { OBJ_TYPES } from '../../../../common/SwitchHistory/historyConsts';
class DrugOrderModal extends React.Component {
    static contextType = UserContext;
    state = {
        drugDeliverySitesList: [],
        is_Coordinator:false,
        access: false,
        countryList: [],
        stateList: [],
        drugNameList: [],
        strength: [],
        presentation: [],
        count: [],
        master_strength: [],
        master_presentation: [],
        master_count: [],
        hasChanged: false,
        submissionId: this.props.submissionId,
        drugOrder: {
            submission: "Submission-" + this.props.submissionId,
            llir_status: "Draft",
            import_license: "None",
            date_needed:null,
        date_requested:null
        },
        isLoader:true,
        recordNo:"",
        viewDetails: true
    }
    async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator ,access:_isCoordinator});
        const { viewEditData, viewEditFlag, submissionId } = this.props;
        await this.getDrugDeliverySitesList(submissionId);
        await this.getCountryList();
        if (viewEditFlag === "edit") {
            if(viewEditData.broker_country_id){
                let countryName = this.state.countryList.filter(item => item.value === viewEditData.broker_country_id)
                await this.getStateList(countryName[0].name);
            }
            await this.getSitedrugOptionsBySiteId(viewEditData.order_site_id);
            let count = this.state.master_count;
            let strength = this.state.master_strength;
            let presentation = this.state.master_presentation;
            if (viewEditData.drug_compound_id) {
                count = count.filter((e) => (e.compound_id === viewEditData.drug_compound_id));
                strength = strength.filter((e) => (e.compound_id === viewEditData.drug_compound_id));
                presentation = presentation.filter((e) => (e.compound_id === viewEditData.drug_compound_id));
            }
            viewEditData["record_id"] = viewEditData.id;
            this.state.recordNo= "R-"+viewEditData.id.toString().padStart(4,'0');
            viewEditData["record"]="R-"+viewEditData.id.toString().padStart(4,'0');
            viewEditData["submission"]="Submission-"+viewEditData.submission_id
            viewEditData["created_by1"] = viewEditData.created_by !== null ?  await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
            viewEditData["updated_by1"] = viewEditData.updated_by !== null ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt) : viewEditData["created_by1"] ;
            viewEditData["date_requested"] = viewEditData.date_requested != "" && viewEditData.date_requested != null ?
            dateStringformat(viewEditData.date_requested) : null;
            viewEditData["estimated_delivery_date"] = viewEditData.estimated_delivery_date != "" && viewEditData.estimated_delivery_date != null ?
            dateStringformat(viewEditData.estimated_delivery_date) : null;
            viewEditData["date_needed"] = viewEditData.date_needed != "" && viewEditData.date_needed != null ?
                dateStringformat(viewEditData.date_needed) : null;
            this.setState({
                drugOrder: viewEditData,
                count,strength,presentation,
                isLoader:false
            })
        }
        else {
            this.setState({
                isLoader:false,
               
            })
        }
    }

    getDrugDeliverySitesList = async (submissionId) => {
        try {
            const res = await API.get(`sites/drugdeliverysites/all/${submissionId}`);
            const drugDeliverySitesList = res.data.map(elm => ({ name: `${elm.name}-${elm.city}-${elm.country.name}`, value: elm.id }));
            this.setState({
                drugDeliverySitesList: drugDeliverySitesList,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                showLoader: false
            })
        }
    }

    getCountryList = async () => {
        const res = await API.get("seed/country/data")
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ countryList: resultArray });
    }

    getStateList = async (country) => {
        if (country) {
            const res = await API.get(`seed/states/data/${country}`);
            const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
            this.setState({
                stateList: resultArray,
            });
        }
    }

    get inputFieldsArray() {
        return [{
            type: "alert-new",
            label: "Information",
            variant: "secondary"
        },
        {
            label: "Order Site *",
            name: "order_site_id",
            type: "searchableDropdown",
            placeholder: "Search Drug Delivery Sites...",
            options: this.state.drugDeliverySitesList,
            onSelect: this.onOrderSiteSearchSelect,
            helptext: "Complete this field."
        }, {
            label: "Submission *",
            type: "text",
            name: "submission",
            disabled: true
        },
        {
            label: "Ordered by",
            type: "text",
            name: "llir_ordered_by"
        },
        {
            label: "Status",
            name: "llir_status",
            type: "dropdown",
            options: [
                "Draft",
                "New",
                "Ordered",
                "Delivered",
                "Complete",
                "On Hold",
                "Cancelled"
            ],
            disabled:true
        },
        {
            label: "Record ID",
            type: "text",
            name: "record",
            disabled: true,
        },
        {
            label: "Date Requested",
            type: "text",
            name: "date_requested",
            disabled: true,
        },
        {
            label: "Request #",
            name: "llir_request",
            type: "text",
            disabled: true,
        },
        {
            label: "Estimated Delivery Date",
            type: "text",
            name: "estimated_delivery_date",
            disabled: true,
        },
        {
            label: "PO Number",
            name: "llir_po_number",
            type: "text",
            disabled: true,
        },
        {
            label: 'Broker Address ("For non-US orders only")',
            type: "alert-new",
            variant: "secondary"
        }, {
            label: "Attention to/Recipient Name",
            type: "text",
            name: "attention_to_recipient_name"
        }, {
            label: "Street Address",
            name: "broker_street_address",
            type: "text"
        }, {
            label: "Broker Contact Number",
            type: "number",
            name: "broker_contact_number",
        },
        {
            label: "City",
            type: "text",
            name: "broker_city",
        },
        {
            label: "Country",
            type: "searchableDropdown",
            placeholder: "Select Countries",
            name: "broker_country_id",
            options: this.state.countryList,
            onSelect: this.onCountryChange
        },
        {
            label: "Zip/Postal Code",
            type: "text",
            name: "broker_zip_postal_code",
        },
        {
            label: "State",
            type: "searchableDropdown",
            placeholder: "Select State",
            name: "broker_state_id",
            options: this.state.stateList,
            onSelect: this.onSearchSelect
        },
        {
            type: "alert-new",
            label: "Drug Order",
            variant: "secondary"
        },
        {
            label: "Drug Name *",
            type: "searchableDropdown",
            placeholder: "Search Compounds...",
            name: "drug_compound_id",
            options: this.state.drugNameList,
            onSelect: this.onCompoundsSearchSelect,
            helptext: "Complete this field."
        },
        {
            label: "Strength *",
            type: "searchableDropdown",
            placeholder: "Search Strengths...",
            name: "strength_id",
            options: this.state.strength,
            onSelect: this.onSearchSelect,
            helptext: "Complete this field."
        },
        {
            label: "Presentation *",
            type: "searchableDropdown",
            placeholder: "Search Presentations...",
            name: "presentation_id",
            options: this.state.presentation,
            onSelect: this.onSearchSelect,
            helptext: "Complete this field."
        },
        {
            label: "Count *",
            type: "searchableDropdown",
            placeholder: "Search Counts...",
            name: "count_id",
            options: this.state.count,
            onSelect: this.onSearchSelect,
            helptext: "Complete this field."
        },
        {
            label: "Quantity *",
            type: "number",
            min:"1",
            name: "llir_quantity_number",
            helptext: "Complete this field."
        },
        {
            type: "alert-new",
            label: "Shipping details",
            variant: "secondary"
        },
        {
            label: "Date Needed",
            type: "date",
            name: "date_needed",
        },
        {
            label: "Import License *",
            type: "dropdown",
            name: "import_license",
            options: [
                "None",
                "Yes",
                "No"
            ],
            helptext: "Complete this field."
        },
        {
            label: "Delivery Restrictions",
            type: "text",
            name: "delivery_restrictions",
        },
        {
            label: "Comments",
            type: "text",
            name: "comments",
        },
        {
            type: "alert-new",
            label: "Attestation Details",
            variant: "secondary"
        },
        {
            label: this.state.drugOrder.llir_status?.toLowerCase() === "Ordered".toLowerCase() ? 
            "Attest to the details of this order *" :"Attest to the details of this order",
            type: "checkbox",
            name: "is_attest_to_the_details_of_this_order",
        },
        {
            label: "Created By",
            type: "text",
            name: "created_by1",
            disabled: true,
            className:"createUpdateByfontsize",
            edit: true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updated_by1",
            disabled: true,
            className:"createUpdateByfontsize",
            edit: true
        }
        ];
    }

     removeDuplicatesBy(array) {
        var mySet = [];
        let filterArr = [];
          array.forEach((x) => {
             let isNew =mySet.includes(x.value);
            if(!isNew) 
            {
                mySet.push(x.value);
                filterArr.push(x);
            }
        });
        return filterArr;
    }
    getSitedrugOptionsBySiteId = async (siteId) => {
        try {
            let drugNameList = [],
                strength = [],
                presentation = [],
                count = [];
            const res = await API.get(`sites/sitedrugoptions/all/${siteId}`);
            res.data.map(item => {
                drugNameList.push({ sitedrugoption: item, name: item.compound.name, value: item.compound.id });
                presentation.push({ compound_id: item.compound_id, name: item.presentation.name, value: item.presentation.id });
                count.push({ compound_id: item.compound_id, name: item.count.name, value: item.count.id });
                strength.push({ compound_id: item.compound_id, name: item.strength.name, value: item.strength.id });
            })
            this.setState({
                drugNameList : this.removeDuplicatesBy( drugNameList),
                strength,
                presentation,
                count,
                master_count : count,
                master_strength :strength,
                master_presentation : presentation,
                showLoader: false
            })
        } catch (e) {
            this.setState({
                showLoader: false
            })
        }
    }

    onCountryChange = (e, name) => {
        const { drugOrder } = this.state;
        this.setState({
            drugOrder: {
                ...drugOrder,
                [name]: e,
                broker_state_id: ""
            }
        });
        this.getStateList(e.name);
    }

    onOrderSiteSearchSelect = (e, name) => {
        const { drugOrder } = this.state;
        this.setState({
            drugOrder: {
                ...drugOrder,
                [name]: e,
                drug_compound_id: "",
                strength_id: "",
                presentation_id: "",
                count_id: ""
            }
        }, () => {
            this.getSitedrugOptionsBySiteId(e.value);
        });
    }
    onCompoundsSearchSelect = (e, name) => {
        const { drugOrder } = this.state;
        this.setState({
            strength: this.state.master_strength.filter((x) => e.value === x.compound_id),
            presentation: this.state.master_presentation.filter((x) => e.value === x.compound_id),            
            count: this.state.master_count.filter((x) => e.value === x.compound_id),

            // presentation: [{ compound_id: e.sitedrugoption.compound_id, name: e.sitedrugoption.presentation.name, value: e.sitedrugoption.presentation.id }],
            // count: [{ compound_id: e.sitedrugoption.compound_id, name: e.sitedrugoption.count.name, value: e.sitedrugoption.count.id }],
        }, () => {
            this.setState({
                drugOrder: {
                    ...drugOrder,
                    [name]: e,
                    strength_id: null,
                    presentation_id:null,
                    count_id: null,
                }
            })
        });
    }
    
   
    onInputChange = (e,eo) => {
        const { drugOrder } = this.state;
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                drugOrder: {
                    ...drugOrder,
                    [eo.name]: e
                }
            });
        }
        else
        {this.setState({
            hasChanged: true,
            drugOrder: {
                ...drugOrder,
                [e.target.name]: e.target.value
            }
        });}
    }

    onSelectChange = (e, eo) => {
        const { drugOrder, error } = this.state;
        if(eo.target.name === "llir_status" && error){
            delete error.is_attest_to_the_details_of_this_order ;
        }
        this.setState({
            error,
            hasChanged: true,
            drugOrder: {
                ...drugOrder,
                [eo.name || eo.target.name]: e,
            }
        });
    }

    checkboxChange = (e) => {
        const { drugOrder } = this.state;
        this.setState({
            drugOrder: {
                ...drugOrder,
                [e.target.name]: e.target.checked,
            }
        });
    }

    onSearchSelect = (e, name) => {
        const { drugOrder } = this.state;
        this.setState({
            hasChanged: true,
            drugOrder: {
                ...drugOrder,
                [name]: e.value
            }
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert-new")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state.drugOrder[field.name]}
                        error={this.state.error?.[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        disabled={!this.state.is_Coordinator||field.disabled}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>{this.onInputChange(e,field)}) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    formValidation = () => {
        let is_valid = true;
        const {drugOrder} = this.state;
        delete this.state.error;
        const initialValues = {
           order_site_id: drugOrder.order_site_id,
           drug_compound_id: drugOrder.drug_compound_id,
           strength_id:  drugOrder.strength_id,
           presentation_id:  drugOrder.presentation_id,
           count_id:  drugOrder.count_id,
           llir_quantity_number: drugOrder.llir_quantity_number,
           import_license: drugOrder.import_license,
           is_attest_to_the_details_of_this_order: drugOrder.llir_status?.toLowerCase() === "Ordered".toLowerCase() ?
            drugOrder.is_attest_to_the_details_of_this_order : true
        }
        const errors = {};
        let customError={};
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null ||
             initialValues[key] === undefined || initialValues[key] === "None" ) {
                errors[key] = true;
                is_valid = false;
            }
            
            if( (key ==="llir_quantity_number")  && initialValues[key] < 1)
            {
            customError[key]="Zero/Negative Number is not allowed"
            is_valid = false;

            }
          else
            { this.setState({
                error: { ...errors },
                customerror:{...customError}
            });}
        });
        return is_valid;
    }
    onSave = (drugOrder) => {
        if (this.formValidation()) {
          
            this.setState({isLoader:true},()=>{
                delete drugOrder.llir_status;
                this.props.handleDrugOrderSave(drugOrder);
            })
        }
    }
    onUpdate = (drugOrder) => {
        if (this.formValidation()) {
            this.setState({isLoader:true},()=>{
                delete drugOrder.llir_status;
                this.props.handleDrugOrderUpdate(drugOrder);
            })
          
        }
    }
    render() {
        const { handleModalShowHide, showHideModal, viewEditFlag } = this.props;
        const { drugOrder,isLoader, viewDetails } = this.state;
        const drugOrderId = this.props.viewEditData?.id;
        return (
            <div>
                {isLoader&&<LoaderEx></LoaderEx>}
                <Modal  className="new-flow-modal" show={showHideModal} size="lg">
                  <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title>
                          <h5 className="font-weight-bold">  {viewEditFlag === "edit" ?
                                `Edit Drug Order ${this.state.recordNo}` : "New Drug Order"}</h5>
                               
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { drugOrderId && 
                            <SwitchView
                                viewMode={(value) => {
                                    if (value === switchEnum.DETAILS) {
                                        this.setState({ viewDetails: true });
                                    } else {
                                        this.setState({ viewDetails: false });
                                    }
                                }}
                            />
                        }
                            
                        { viewDetails ? 
                            <Row>
                                {this.renderInputFields()}
                            </Row>
                        : <SwitchHistory 
                            object_type={OBJ_TYPES.DRUGORDER} 
                            object_id={drugOrderId} 
                          /> 
                        }
                    </Modal.Body>
                    { viewDetails && <Modal.Footer className="d-flex align-items-center justify-content-center">
                        <IIRBtn.default style="danger w-100px" className="p-2 mr-2" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </IIRBtn.default>
                        {
                            viewEditFlag === "edit" ?
                                this.state.is_Coordinator&&<IIRBtn.default disabled={isLoader} className="p-2" style="success w-100px" onClick={() => this.onUpdate(drugOrder)}>
                                   Save
                                </IIRBtn.default> : <IIRBtn.default disabled={isLoader} style="success w-100px" className="p-2 d-flex align-items-center justify-content-center" onClick={() => this.onSave(drugOrder)}>
                                <i className="bi bi-plus" ></i><span>Add</span>
                                </IIRBtn.default>
                        }
                    </Modal.Footer> }
                </Modal>
            
            </div>
        )
    }

}

export default DrugOrderModal;