import React,{useEffect} from 'react';

const StickyNav=({children}) => {
  const [scrolled,setScrolled]=React.useState(false);

  const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 51 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }
  useEffect(() => {
    window.addEventListener('scroll',handleScroll)
  })

  let x=['sticky-nav'];
  if(scrolled){
    x.push('scrolled');
  }
  return (
    <div className={x.join(" ")}>
        {children}
    </div>
  )
};

export default StickyNav;