import axios from 'axios';
import React,{useState} from 'react'
import {Modal,Button, Row, Col,Dropdown,Form} from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import API from '../../../../api/apis';
import UserContext from '../../../../context/UserContext';
import InputField from '../../../common/input-fields/InputField';
import './custom-css.css'
import { showMessage } from '../../commonFunctions';
import * as IIRBtn from '../../../common/Button';
const TemplateSelection= (props)=>{
    let { label,showLoader,onAdd,remoteValidation, helptext, options, onSelect, onChange,customerror, error, ...inputProps  } = props; //...inputProps is used to copy props object
    let [templateName,setTemplateName]=useState("");
    let [showValidation,setShowValidation]=useState(false);
    const handleNewTemplate=async()=>{
        setShowValidation(false)
        if(onAdd&&templateName&&templateName.length>0){
            let isExist=  await remoteValidation(templateName);
            if(!isExist){
              onAdd(templateName);
              document.body.click();
            }       
            else{
                setShowValidation(true)
            }                
          }
          else{
            onAdd("");
            setTemplateName("");
          }        
         
    }
    return  <Form.Group>
    <Form.Label className="bold-label">
        {label}{" "}
        { helptext &&
        <span>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                <i class="bi bi-info-circle-fill text-primary"></i>
            </OverlayTrigger>
        </span>    }
    </Form.Label>
    <Dropdown className="dropdown-input" onSelect={onSelect} {...inputProps}>
        <Dropdown.Toggle variant="default" className="shadow-none" name={inputProps.name} disabled={inputProps.disabled}>
            {inputProps.value || inputProps.placeholder}
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <div className='d-flex flex-column pl-1 pr-1 border-bottom pb-1 m-1 position-relative'>           
                    <div className='d-flex position-relative'>
                        {showLoader && <div className="d-flex  align-items-center justify-content-center p-2 position-absolute" style={{ left: 0, top: 0, height: "100%" }}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </div>}
                        <input type="text" onChange={e => {
                            setTemplateName(e.target.value || '');
                            setShowValidation(false);
                        }} placeholder='Enter new template name' className="form-control form-control-sm template-name-input" style={{ backgroundColor: "#FFF" }}></input>
                        <Button variant="success" size={'sm'} onClick={handleNewTemplate}>Add</Button>
                    </div>
                    {showValidation && <span className="text-danger pl-2 pr-2" style={{fontSize:'0.75rem'}}>Template Name Already Exist</span>}               
            </div>
           <div className="p-1 m-1 font-weight-bold">
                Existing Templates
           </div>
            <ul style={{listStyle:"none",maxHeight:'300px',overflowY:'auto'}} className="m-0 p-2 pt-0 template-list">
                {
                    options&&options.map((item,key)=>{
                            return <li key={key} onClick={e=>{
                                onSelect(item,inputProps.name);
                                document.body.click();
                            }                              
                            } className="p-1">{item.name}</li>
                    })
                }
            </ul>
            {/* {Array.isArray(options) ? options.map(option => <Dropdown.Item eventKey={option} key={option} name={inputProps.name}>{option}</Dropdown.Item>) : ''} */}
        </Dropdown.Menu>
    </Dropdown>
    {error && <p className="text-danger ml-3">This field is required.</p>}
    {customerror && <p className="text-danger ml-3">{customerror}</p>}

</Form.Group>
}
export default class NewInstruction extends React.Component{
    static contextType = UserContext;
    INITIAL_STATE={
        isLoading:false,
        modalProps:{show:false},
        isIUDOperation:false,
        usersList:[],
        templates:[],
        requiredInstructions:[
            
        ],        
        formData:{
            fields:{
                required_instruction:"",
                instructions_to:"",
                title:"",
                content:"",
                template_name:""
            },
           
            errors:{
                
            }

        }
    }
    SERVICE_URL={
        ADD_INSTRUCTION:'reviewConcept/instruction/add',
        TEMPLATE_LIST:'/reviewConcept/instruction/templates',
        USR_LST:'user/data',
        TEMPLATE_VALIDATION:'/reviewConcept/instruction/isTemplateExist',
        TEMPLATE_DETAIL:'/reviewConcept/instructions/templateDetail'
    };
    DBErrors={
        DUPLICATE:'SequelizeUniqueConstraintError'
    };
    constructor(props) {
        super(props);
        this.state={
            ...this.INITIAL_STATE
        }
    }
    componentDidMount(){
        this.getUserList();
        this.getTemplateList();
        
        if(this.props.users&&this.props.users.length>0){
            //SPrint 16 we are handling single user only
            let {formData}=this.state;
            // formData.fields["instructions_to"]=`${this.props.users[0].reviewer_name} | ${this.props.users[0].reviewer_id}`;
            
            this.setState({formData:{...formData}});
        }
    }
    getInstructionTypes=()=>{
        let types=["Yes","No"];
        const resultArray = types.map(elm => ({
            name: elm,
            value: elm
        }));
        return resultArray;
       
    }
    onNewTemplateAdd=(param)=>{
       
        let {templates,formData}=this.state;
        if(param&&param.length>0){         

            let x=templates.findIndex(p=>p.status==="NEW");
            if(x>=0){
                templates.splice(x,1);               
            }
            templates.push({name:param,value:param,status:"NEW"});
            formData.fields["template_name"]=param;
            this.setState({templates:[...templates],formData:{...formData}});
        }
        else{
            let x=templates.findIndex(p=>p.status==="NEW");
            if(x>=0){
                templates.splice(x,1);               
            }
            formData.fields["template_name"]="";
            this.setState({templates:[...templates],formData:{...formData}});
        }
    }
    isTemplateExist=async(param)=>{
        this.setState({ isLoading: true })
        try {
            const res = await API.post(this.SERVICE_URL.TEMPLATE_VALIDATION, {
                "template_name": param
            });
            this.setState({ isLoading: false })
            if (res && res.data) {
                return res.data.success;
            }
          

        }
        catch (exp) {

        }
        finally {
            this.setState({ isLoading: false })
        }
        return true;
    }
    getTemplateList=async()=>{
        const res = await API.get(this.SERVICE_URL.TEMPLATE_LIST)
        const resultArray = res.data.map(elm => ({
            name: elm.template_name,
            value: elm.template_name,
            id:elm.id
        }));
        this.setState({ templates: resultArray });
    }
    getUserList = async () => {
      
        const res = this.props.users[0].reviewers_details||[];
        const resultArray = res.map(elm => ({
            name: `${elm.reviewer_name} | ${elm.reviewer_system_id}`,
            value: elm.id
        }));
        let dataList=this.getInstructionTypes();
        this.setState({ usersList: resultArray,requiredInstructions: dataList });
    }
    componentDidUpdate(prevProps, prevState){
        
        if (prevState.modalProps.show !== this.props.show) {
            // this.setState({modalProps:{show:this.props.show}});
          }
    }
    clearFormData=(callBack,isSuccess)=>{
        this.setState({
            ...this.INITIAL_STATE
        },()=>{callBack(isSuccess);});
    }
    onHide=()=>{
        let {onClose}=this.props;
        if(onClose){
            
            this.clearFormData(onClose);
            
        }
    }
    handleChangeEvent=(e,name)=>{
      
       let {formData}=this.state;
     
        if(!name){
            const { name, value} = e.target;
            formData.fields[name]=value;           
        }
        else{
            formData.fields[name]=e.value;
        }
       
        this.setState({formData:{...formData}},()=>{
            if(name==="template_name"){
                this.loadTemplateDetails(e.value);
            }
        });
    }
    loadTemplateDetails=async(val)=>{
        this.setLoader(true);
        try {
            let {templates}=this.state;
            let currentItem=templates.find(p=>p.name===val);
            if(currentItem){
                const res = await API.get(`${this.SERVICE_URL.TEMPLATE_DETAIL}/${currentItem.id}`);   
                this.setLoader(false);           
                if (res && res.data) {
                   
                   let {template_data}=res.data;
                   let {content,required_instruction,title,instructions_to}=template_data;
                   let {formData}=this.state;
                   formData.fields["content"]=content;
                   formData.fields["required_instruction"]=(required_instruction?"Yes":"No");
                   formData.fields["title"]=title;
                   //formData.fields["instructions_to"]=this.props.users[0].reviewer_id;//Need Change for Next sprint
                //    formData.fields["instructions_to"]=`${this.props.users[0].reviewer_name} | ${this.props.users[0].reviewer_id}`;
            
                   this.setState({formData:{...formData}});
                }
            }
                

        }
        catch (exp) {

        }
        finally {
            this.setLoader(false);
        }
        
    }
    setLoader=(show)=>{
        this.setState({isIUDOperation:show});
    }
    handleSave=async ()=>{
        this.setLoader(true);
        
            if(this.validateInputs()){
                let {formData,templates}=this.state;
                let reviewerInfo=(this.props.users[0].reviewers_details||[]).find(p=>p.id===formData.fields["instructions_to"]);
                let formBody={
                    "required_instruction":formData.fields["required_instruction"]==="Yes",
                    "instructions_to": reviewerInfo.reviewer_system_id,
                    "title": formData.fields["title"],
                    "content":formData.fields["content"],
                    "created_by": this.context.userSystemId,
                    "parent_id": this.props.reviewerId,
                    "reviewers_detail_id": formData.fields["instructions_to"]                
                }

                let template_name=formData.fields["template_name"];
                let new_template=templates.find(p=>p.name===template_name&&p.status==="NEW");
                if(new_template){
                    formBody["template_name"]=template_name;
                }
                const res = await API.post(this.SERVICE_URL.ADD_INSTRUCTION, formBody);
                if(res.data.error || res.data.errors){
                    this.setLoader(false);
                    let isDuplicate=(res.data.name===this.DBErrors.DUPLICATE);
                    if(isDuplicate){
                        showMessage({                  
                            text: "Record already exist",
                            icon: "warning",
                            button: "Ok",
                          
                          },(e)=>{});
                    }
                    else{
                        showMessage({                  
                            text: "Failed to update the record!",
                            icon: "error",
                            button: "Ok",
                          
                          },(e)=>{this.clearFormData(this.props.onClose,false)});
                    }
                }
                else{
                   
                    this.clearFormData(this.props.onClose,true);
                }
            }
            this.setLoader(false);
    }
    validateInputs=()=>{
        let {formData}=this.state;
        let {errors}=formData;
        errors={};
        let isValid=true;
        if(!formData.fields["required_instruction"]){
            errors.required_instruction=true;
            isValid=false;
        }
        if(!formData.fields["instructions_to"]){
            errors.instructions_to=true;
            isValid=false;
        }
        if(!formData.fields["content"]){
            errors.content=true;
            isValid=false;
        }
        formData.errors=errors;
        this.setState({formData});
        return isValid;
    }
    
    render(){
        let {modalProps,formData,fileInfo,isIUDOperation}=this.state;
        let loaderConfig={};
        if(isIUDOperation){
             loaderConfig={disabled:"disabled"};
        }
        return <div>
            
    <Modal
      show   
      size="md"
      dialogClassName="md-xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

    <Modal.Header style={{border:0}}>
        <Modal.Title id="contained-modal-title-vcenter">
         <h5 className="font-weight-bold"> Add a  Instruction</h5>
         <div style={{fontSize:'1rem'}}>
            <span>Please fill out this form to add a new instruction for all reviewers or just a specific reviewer</span>
         </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="new-flow-modal">
            <Row>
                <Col sm={6}>
                    <InputField
                           label= "Required Instruction *"
                           type= "searchableDropdown"
                           name= "required_instruction"
                           value={formData.fields["required_instruction"]}
                           placeholder="Select"
                           onSelect={(e,name)=>this.handleChangeEvent(e,name)}
                           options={this.state.requiredInstructions}
                           error={formData.errors["required_instruction"]}
                        />
                </Col>
                <Col sm={6}>
                    {/* <InputField
                           label= "Reviewers Needing Instruction"
                           type= "text"
                           placeholder="Select Reviewer"
                           name= "instructions_to"
                           disabled={this.props.users&&this.props.users.length>0}
                           value={formData.fields["instructions_to"]+'|'+this.props.reviewerId}
                           options= {this.state.usersList}
                           onChange={(e,name)=>this.handleChangeEvent(e,name)}
                           error={formData.errors["instructions_to"]}
                        /> */}
                        <InputField
                           label= "Reviewers Needing Instruction *"
                           type= "searchableDropdown"
                           placeholder="Select Reviewer"
                           error={formData.errors["instructions_to"]}
                           name= "instructions_to"
                           value={formData.fields["instructions_to"]}
                           options= {this.state.usersList}
                           onSelect={(e,name)=>this.handleChangeEvent(e,name)}
                        />
                </Col>
                <Col sm={6}>
                    <TemplateSelection
                    label= "Use / Create Template"
                    type= "searchableDropdown"
                    name= "template_name"
                    value={formData.fields["template_name"]}
                    placeholder="Select"
                    remoteValidation={this.isTemplateExist}
                    onAdd={this.onNewTemplateAdd}
                    onSelect={(e,name)=>this.handleChangeEvent(e,name)}
                    showLoader={this.state.isLoading}
                    options={this.state.templates}
                    error={formData.errors["template_name"]}
                    ></TemplateSelection>
                    {/* <InputField
                           label= "Use / Create Template"
                           type= "searchableDropdown"
                        //    placeholder="Select Reviewer"
                        //    name= "review_to"
                        //    value={formData.fields["review_to"]}
                        //    options= {this.state.usersList}
                        //    onSelect={(e,name)=>this.handleChangeEvent(e,name)}
                           
                        /> */}
                </Col>
                <Col sm={12}>
                    <InputField
                           label= "Task Title"
                           type= "text"
                           name= "title"
                           value={formData.fields["title"]}
                           onChange={(e)=>this.handleChangeEvent(e)}
                           rows={3}
                        />
                </Col>
                <Col sm={12}>
                    <InputField
                           label= "Instruction for selected reviwers *"
                           type= "textarea"
                           error={formData.errors["content"]}
                           name= "content"
                           value={formData.fields["content"]}
                           onChange={(e)=>this.handleChangeEvent(e)}
                           rows={3}
                        />
                </Col>
               
            </Row>
      </Modal.Body>
      <Modal.Footer>
                    {isIUDOperation&&<div className="d-flex align-items-center justify-content-center p-2">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>}
                    <div className="d-flex align-items-center justify-content-center w-100 p-2">
                        <IIRBtn.default {...loaderConfig} onClick={this.onHide} className="mr-2 p-2" style="danger w-100px">Cancel</IIRBtn.default>
                        <IIRBtn.default {...loaderConfig} onClick={this.handleSave} className="d-flex align-items-center justify-content-center p-2" style="success w-100px"><i className="bi bi-plus" ></i><span>ADD</span></IIRBtn.default>
                    </div>
      </Modal.Footer>
    </Modal>
        </div>;
    }
}