import React, { Component } from 'react';
import { Button, Col, Row ,Nav} from 'react-bootstrap';
import { Link, withRouter,NavLink } from 'react-router-dom';
import DatatableComponent from '../../../common/datatable/DatatableComponent';
import DrugDeliverySiteModal from '../DrugDeliverySiteModal';
import UserContext from '../../../../context/UserContext';
import API from '../../../../api/apis';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import dateFormat from '../../../common/dateFormat';
import swal from 'sweetalert';
import { dateChange, dateStringformat, enableWFActions, renderTableIcons } from '../../../../utility/helperFunctions';
import DrugDeliverySiteModalEx from '../DrugDeliverySiteModalEx';
import DTAButton from '../../../common/Button/DTAButton';
import Papa from 'papaparse'
import moment from 'moment';
import { GLOBAL_DATE_FORMAT, GLOBAL_DATE_FORMAT_NOTIME } from '../../../../utility/dateHelper';
class DrugDeliverySite extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        data: [],
        error: false,
        successModal: false,
        errorModal: false,
    }
    componentDidMount() {
        
        this.getDrugDeliveryBySubmissionId(this.props.submissionDetails.id);

        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => {  })
        }
    }
    getDrugDeliveryBySubmissionId = async (submissionid) => {
        try {
            const res = await API.get(`sites/drugdeliverysites/all/${submissionid}`)
            let sortedReviews = res.data.sort((a,b) => (a.id) - (b.id));
            this.setState({ data: sortedReviews });
        }
        catch (error) {
            console.log("error", error);
        }
    }
    handleModalShowHide =  async (flag, viewEditFlag, row) => {
        if (row && row.id && viewEditFlag === "edit") {
            //await this.getDrugDeliverSiteById(row.id)
            this.setState({ showHideModal: flag, viewEditFlag,viewEditData: {id:row.id} });
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} },()=>{
            this.getDrugDeliveryBySubmissionId(this.props.submissionDetails.id)
        });
    }
    getDrugDeliverSiteById = async (id) => {
        try {
            const expenseDetails = await API.get(`sites/drugdeliverysites/getbyid/${id}`);
            this.setState({
                viewEditData: expenseDetails.data,
                showLoader: false
            })
        } catch (e) {
           
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
   
    handleDrugDeliverSiteDelete = async (row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this imaginary file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try {
                    const res =  API.delete(`sites/drugdeliverysites/remove/${row.id}`);
                    swal("Deleted Successfully", {
                        icon: "success",
                      });
                      this.getStudyContactDetailsBySubmissionId(this.props.submissionDetails.id)
                }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
              
            } else {
              swal("Your imaginary file is safe!");
            }
          });
    
    }
    columns = [{
        dataField: 'name',
        text: '[EXT] Site Name',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return row.name
        }
    }, {
        dataField: 'utilizing_central_irb_erb',
        text: '[EXT] Utilizing ethics approval?',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <input type="checkbox" checked={row.utilizing_central_irb_erb} disabled
                    value={row.accepted} />
            </>
        }
    }, {
        dataField: 'irb_erb_expiration_date',
        text: '[EXT] IRB/ERB Expiration Date',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem" }}
                   >{row.irb_erb_expiration_date?.length>0 ?dateStringformat(row.irb_erb_expiration_date):""}</p>
            </>
        }
    },{
        dataField:'',
        text: "Actions",
        headerClasses:"width-90",
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";


            return <>{enableWFActions()&&<>
               {visibilityDelete&&<i className="bi bi-trash-fill" onClick={() => this.handleDrugDeliveryDelete(row)}
                    style={{ visibility: visibilityDelete ,fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>}
                {visibilityEdit&&<i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row)}
                    style={{  visibility: visibilityEdit,fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>}
            </>}</>
        }
    }
];
handleDrugDeliveryDelete = async (row) => {
    swal({
        text: "Are you sure, you want to delete this record?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async(willDelete) => {
        if (willDelete) {
            try {
                const res =  await API.post(`recycle/drugdeliverysite/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                swal("Deleted Successfully", {
                    icon: "success",
                  });
                  this.getDrugDeliveryBySubmissionId(this.props.submissionDetails.id)
            }
             catch (err) {
                this.setState({ errorModal: true });
            }
          
        } 
      });
  
}
handleDrugDeliverySave = async(state) => {
    state.created_by = this.context.userSystemId;
    state["irb_erb_expiration_date"] = state.irb_erb_expiration_date != "" && state.irb_erb_expiration_date != null ?
    dateChange(state.irb_erb_expiration_date) : null;
    state["irb_erb_start_date"] = state.irb_erb_start_date != "" && state.irb_erb_start_date != null ?
    dateChange(state.irb_erb_start_date) : null;
    try {
        const res = await API.post("sites/drugdeliverysites/upsert",{"drugDeliverySitesData": {...state}});
        this.setState({
            success_body: "Created successfully!",
            successModal: true,
            hasChanged: false,
            showHideModal: false,
            viewEditFlag: null,
            viewEditData: {},
        });
        this.getDrugDeliveryBySubmissionId(this.props.submissionDetails.id)

    } catch (err) {
        this.setState({ errorModal: true });
    }
}
handleDrugDeliveryUpdate =async (state) => {
    state.updated_by = this.context.userSystemId;
    state.last_modified_by_id=this.context.userSystemId;
    state.created_by = this.context.userSystemId;
    state["irb_erb_expiration_date"] = state.irb_erb_expiration_date != "" && state.irb_erb_expiration_date != null ?
    dateChange(state.irb_erb_expiration_date) : null;
    state["irb_erb_start_date"] = state.irb_erb_start_date != "" && state.irb_erb_start_date != null ?
    dateChange(state.irb_erb_start_date) : null;
    try {
        const res = await API.post("sites/drugdeliverysites/upsert", {"drugDeliverySitesData": {...state}}
        );
        this.setState({
            success_body: "Updated successfully!",
            successModal: true,
            hasChanged: false,
            showHideModal: false,
            viewEditFlag: null,
            viewEditData: {},
        });
        this.getDrugDeliveryBySubmissionId(this.props.submissionDetails.id)

    } catch (err) {
        this.setState({ errorModal: true });
    }
}
//     get data(){
//         return this.props.submissionDetails.concepts.map(concept => {
//             return {
//                 '[EXT] Concept Title': concept.title,
//                 '[EXT] Indication': concept.indication,
//                 'Request Type': concept.request_type
//             }
//         })
// }
    // rowEvents = {
    //     onClick: (e, row, rowIndex) => {
    //         //TODO 
    //         //MAKE SURE ROW VALUE REMAIN SAMES WHEN WE SEARCH FOR PARTICULAR RECORDS
    //       this.props.history.push(`/drugdeliverysite/details`, {conceptId: this.props.submissionDetails.concepts[rowIndex].id});
    //     }
    // };
    handleDrugDeliverySiteDetails = (row) => {
        this.props.history.push(`/drugdeliverysite/details/${row.id}`, { drugdeliverysiteId: row.id});
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }
    handleDownloadAll=async()=>{
        try {
            let mapping =
                [{
                    label: "Submission",
                    name: "submission_id"
                },
                {
                    label: "[EXT] Site Name",
                    name: "name"
                },
                {
                    label: "Site #",
                    name: "impact_site"
                },
                {
                    label: "[EXT] Contact Name",
                    name: "contact_name"
                },
                {
                    label: "[EXT] Address Prefix",
                    name: "ext_address_prefix"
                },
                {
                    label: "[EXT] Street Address ",
                    name: "street_address"
                },
                {
                    label: "[EXT] Street Address 2",
                    name: "ext_street_address_2"
                },
                {
                    label: "[EXT] City",
                    name: "city"
                },
                {
                    label: "[EXT] State/Province *",
                    name: "state_name"
                },
                {
                    label: "[EXT] Zip/Postal Code ",
                    name: "zip_postal_code",
                },
                {
                    label: "[EXT] Country",
                    name: "country_name"
                },
                {
                    label: "[EXT] Phone Number ",
                    name: "phone_number"
                },
                
                {
                    label: "[EXT] Email Address",
                    name: "email_address"
                },
                {
                    label: "Type of Material Provided",
                    name: "type_of_material_provided"
                },
                
                {
                    label: "[EXT] Utilizing ethics approval?",
                    name: "utilizing_central_irb_erb"
                },
                {
                    label: "[EXT] IRB/ERB Start Date",
                    name: "irb_erb_start_date"
                },
                {
                    label: "[EXT] IRB/ERB Expiration Date",
                    name: "irb_erb_expiration_date"
                },
                {
                    label: "Address Type",
                    name: "address_type"
                },
                {
                    label: "Updated By",
                    name: "updated_by_name",
                },
                {
                    label: "Updated At",
                    name: "updatedAt",
                    
                },
                {
                    label: "Created By",
                    name: "created_by_name"
                },
                {
                    label: "Created At",
                    name: "created_at"
                },
                ];
            let submission_id=this.props.submissionDetails.id;
            let drugDelSiteInfo = await API.get(`sites/drugdeliverysites/download/all/${submission_id}`);
            if (drugDelSiteInfo.data && drugDelSiteInfo.data.success) {

                let validator = (drugDelSiteInfo.data.data || []).map((item) => {
                    let row = {};
                    mapping.forEach(meta => {
                        if(!["created_at","updatedAt","irb_erb_expiration_date","irb_erb_start_date"].includes(meta.name)){
                            row[meta.label] ='\t'+ item[meta.name]||'';
                        }
                        else{
                            if(item[meta.name]){
                                let format=GLOBAL_DATE_FORMAT;
                                if(["irb_erb_expiration_date","irb_erb_start_date"].includes(meta.name)){
                                    format=GLOBAL_DATE_FORMAT_NOTIME;
                                }
                                row[meta.label] ='\t'+ moment.utc(item[meta.name]).local().format(format);
                            }
                            else{
                                row[meta.label]=item[meta.name]||'';
                            }
                        }
                      
                    })
                    return row;
                })
                var csv = Papa.unparse([...validator]);
                var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                var csvURL = null;
                if (navigator.msSaveBlob) {
                    csvURL = navigator.msSaveBlob(csvData, 'download.csv');
                }
                else {
                    csvURL = window.URL.createObjectURL(csvData);
                }

                var tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', `DrugDeliverySites-${submission_id}.csv`);
                tempLink.click();
            }
        }
        catch (exp) {
            console.log(exp);
        }
       
        
    }
    renderPageIcons=()=>{
            
       
        return <><div className="d-flex align-items-center justify-content-center">
            <DTAButton  icon="DOWNLOAD" onClick={e=>{   
                this.handleDownloadAll();            
            }} text={"DOWNLOAD ALL"}></DTAButton>
        </div>
        {renderTableIcons("ADD Drug Delivery Site",() => this.handleModalShowHide(true, null, {}),"add",this.state.access.create&& enableWFActions() )}
        </>
    }
    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal } = this.state;
            let title=` Drug Delivery Sites (${this.state.data.length})`;
        return <>
            <Row>
                {/* <Col md={4}>
                    <h6 className="table-top"> Drug Delivery Sites ({this.state.data.length})</h6>
                </Col>
                <Col md={3}>
            {this.state.access.create&& enableWFActions() && <Button variant="outline-light" className="new-button" onClick={() => this.handleModalShowHide(true, null, {})}>New</Button>}
                </Col> */}
                <DatatableComponent  
                 title={title}
                 rowEvents={
                    {
                     onClick: (e, row, rowIndex) => {
                        if(enableWFActions()){
                            this.handleModalShowHide(true, "edit", row)  ;
                        } 
                        else{
                            this.handleDrugDeliverySiteDetails(row);
                        }                                            
                       }
                    }
                 }   
                pageIcons={this.renderPageIcons()} keyField="record_id" data={this.state.data} columns={this.columns} pagination="true" id="drugSite"/>
            </Row>
            {
                showHideModal &&
                <DrugDeliverySiteModalEx

                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    id={viewEditData.id}
                    submissionId={this.props.submissionDetails.id}
                    type_of_material_provided={this.props.submissionDetails.type_of_material_provided}
                />
            }
              {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
        </>
    }
}

export default withRouter(DrugDeliverySite); 

