import React, { Component } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import DatatableComponent from '../../../common/datatable/DatatableComponent';
import axios from 'axios';
import FileComponent from '../../../common/file-component/FileComponent';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import API from '../../../../api/apis';
import swal from 'sweetalert';
import logo from '../../../../static/images/sharepoint.png';
import UserContext from '../../../../context/UserContext';
import { uploadFile, enableWFActions } from '../../../../utility/helperFunctions';
import { sharepointAudit } from '../../../ToReviewDetails/SharepointAudit';


export default class FilesInternal extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },        selectedFile: null,
        selectedFiles:[],
        exist_flag: false,
        siteURL: '',
        siteEnv: '',
        fileName: '',
        folderName: '',
        libraryName: '',
        access_token: '',
        listOfFiles: [],
        hideFileSection: true,
        hideCreateFolderSection: true,
        successModal: false,
        errorModal: false,
        action : "",
        showLoaderUpload :  false
    }
    columns = [{
        dataField: 'File Name',
        text: 'File Name',
        sort: true
    }, {
        dataField: 'Actions',
        text: ''
    }];

   
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
        this.setState({
            selectedRole: selectedRole,
            access: {
           view: true,
           create:  selectedRole.includes("administrator"),
           edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
           delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
       },
        })
    }
    }
   async componentDidMount() {
    let response = await API.get(`user/accesstoken`);
    let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;

    console.log(response.data.access)
        this.setState({
            selectedRole: selectedRole,
            showLoader :  true,
            action : "fetching" , 

            access: {
                view: true,
                create:  selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
            siteURL: 'https://collab.lilly.com/sites/',
            siteEnv: response && response.data ? response.data.site_name : " ",
            libraryName: response && response.data ? response.data.library_name : " ",
            rootFolderName: 'Submission',
            folderName: 'Submission-' + (""+this.props.submissionDetails?.id).padStart(4,0),
            access_token: response && response.data ? response.data.access : " "
        },
            () =>
            { 
                 this.isFolderExist();
                this.getFiles();
            });

    }
    data = [
        {
            'File Name': "MSP.docx",
            'Actions': <>
                <i className="bi bi-trash-fill" style={{ fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "right" }}></i>
                <i className="bi bi-cloud-download-fill" style={{ fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "right" }}></i>
            </>
        }
    ];
   
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }
    isFolderExist = async () => {
        console.log('isFolderExist!!!!');
        let relativePath = '/_api/web/GetFolderByServerRelativeUrl(\'/sites/';
        let expandUrl = 'Exists';

        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '\')/' + expandUrl;
        //var fileUrl = 'https://collab.lilly.com/sites/ERMSDev/_api/web/GetFolderByServerRelativeUrl(\'/sites/LLIRDev/Root/Submission1\')/Exists';
        try {
            const resp = await axios.get(fileUrl, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose"
                },


            });
            console.log(resp);
            this.setState({exist_flag : resp.data.d.Exists });
            return resp.data.d.Exists;
        } catch (err) {
            console.error(err);
            this.setState({ errorModal: true });

        }
    }


    //Retrieve the list of files from the folder
    getFiles = async () => {
        
        this.setState({
            listOfFiles: []
        })
        console.log('Download File!!!!');
        let relativePath = '/_api/web/GetFolderByServerRelativeUrl(\'/sites/';
        let expandUrl = '?$expand=Files&$select=Files/Name,Files/UniqueId';
        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '\')/' + expandUrl;
        //var fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFolderByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3\')/?$expand=Files&$select=Files/Name,Files/UniqueId';
        try {
            const resp = await axios.get(fileUrl, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose"
                },
            });
            console.log(resp);

            this.setState({ 
                showLoader :  false , 
                listOfFiles: resp.data.d.Files.results.map((e) => { return { key: e.Name } }) });

        } catch (err) {
            console.error(err);
            this.setState({
                showLoader :  false , 
                errorModal: true });

        }
    }

    downloadFile = (inFileName) => {
        console.log('downloadFile!!!!');
        let fileName = inFileName;//'Sample.docx';    
        let relativePath = '/_api/web/GetFileByServerRelativeUrl(\'/sites/';

        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '/' + fileName + '\')/$value';
        //var fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFileByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3/text.txt\')/$value';
        this.setState({
            showLoader :  true , 
            action : "download"
        })
        fetch(fileUrl, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    this.setState({ 
                        showLoader : false
                    })
                    return response.blob();
                }
                else {
                    this.setState({ 
                        showLoader : false,
                        errorModal: true });

                    throw new Error('Error Encountered.Please try again.');
                }
            })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', decodeURIComponent(fileName));

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

    }

    deleteFile = async (inFileName, fileType, fileNameActual) => {
        console.log('deleteFile Click!!!!');
        let fileName = inFileName;// 'text.txt';      
        let relativePath = '/_api/web/GetFileByServerRelativeUrl(\'/sites/';
        //let fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFileByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3/text.txt\')';
        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '/' + fileName + '\')';
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
        try {
            this.setState({showLoader :  true , action : "deletion"})

            const resp = await axios.post(fileUrl, {}, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose",
                    "IF-MATCH": "*",
                    "X-HTTP-Method": "DELETE",
                },
            });
            console.log(resp);
            this.setState({
                success_body: "Deleted successfully!",
                successModal: true,
                showLoader : false
            }, async ()=> {
                const payload = {
                    "action": "DELETE",
                    "submission_id": this.props.submissionDetails?.id,
                    "location_type": "submission",
                    "created_by": this.context.userSystemId,
                
                    "files":[
                        {  
                            "file_name": fileNameActual,
                            "sharepoint_path": this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '/' + fileName,
                        }
                    ]

                }
                await sharepointAudit(payload, this.props.refreshAuditData);
            })
            await this.getFiles();
            

        }
        catch (err) {
            this.setState({
                showLoader : false
            })
            console.error(err);
        }
    }
    });
    }
    createFolder = () => {
        console.log('createFolder Click!!!!');
        let relativePath = '/_api/web/folders';
        let apiUrl = this.state.siteURL + this.state.siteEnv + relativePath;
        //let apiUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/folders';
        let folderMetadata = '{\'__metadata\': { \'type\': \'SP.Folder\' },\'ServerRelativeUrl\': \' ' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + ' \'  }\r\n';
        fetch(apiUrl, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Content-Type': 'application/json;odata=verbose'
            },
            body: folderMetadata
        })
            .then((response) => {
                if (response.ok) {
                    console.log("Folder Created Successfully");
                    this.setState({exist_flag :true });
                }
                else {
                    this.setState({ errorModal: true });

                    throw new Error('Error Encountered.Please try again.');
                }
            });
    }


    // uploadFileSPO 
    //Get the selected file and update the state evey time it changes
    onFileChange = event => {
        // Update the state
        //event.target.files[0]
        let activeFile= event.target.files;//[0];    
        let selectedFileName=[];
            if (activeFile.length > 0) {
                for (let i = 0; i < activeFile.length; i++) {
                    selectedFileName.push(activeFile[i].name);
                }
            }
        this.setState({ selectedFile: {name:selectedFileName.join(",")},selectedFiles:activeFile });
    };
    uploadMultipleFiles=async(replaceFile)=>{
        try{
            this.setState({
                showLoaderUpload :  true,
            });
            let {selectedFiles}=this.state;
            let parallelCalls=[];
            let records=[];
            let parallelCallsData = [];
            if(selectedFiles&&selectedFiles.length>0){
                for(let i=0;i<selectedFiles.length;i++){
                    parallelCalls.push(this.uploadFileEx(selectedFiles[i]));
                }
                await Promise.all(parallelCalls)
                    .then((data) => {
                        parallelCallsData = data?.map( e => { return e?.respData });
                        records = data?.map( e => { return e?.status ? true : false });
                        // records = [...data];
                     });
            }
            let status=records&&records.filter(p=>p===true).length>0;
            if (status) {
                this.setState({
                    success_body: "Uploaded successfully!",
                    successModal: true,
                    selectedFile: null,
                    fileMessage: "File uploaded!",
                    showLoaderUpload: false
                }, async ()=> {
                    const filesList = [];
                    for(let i=0;i<parallelCallsData.length;i++){
                        filesList.push({
                            "file_name": parallelCallsData[i].Name,
                            "sharepoint_path": parallelCallsData[i].ServerRelativeUrl,
                            "created_at": replaceFile ? 
                                parallelCallsData[i].TimeLastModified : parallelCallsData[i].TimeCreated
                        });
                    }
                    const payload = {
                        "action": "INSERT",
                        "submission_id": this.props.submissionDetails?.id,
                        "location_type": "submission",
                        "created_by": this.context.userSystemId,
                        "files": filesList
    
                    }
                    await sharepointAudit(payload, this.props.refreshAuditData);
                })
                this.getFiles();
            }
            else{
                this.setState({ errorModal: true ,
                    // fileMessage: "File uploaded!",
                    showLoaderUpload : false});
            }
        }
        catch(exp){
            console.error("File upload Error",exp)
        }
        finally{
            this.setState({
                showLoaderUpload :  false,
            })
        }
      
    }
    readFileAsync= (blob)=>{
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onload = (event) => resolve(event.target.result)
            //reader.onloadend = () => resolve(reader.result)
            reader.readAsArrayBuffer(blob)
          })
    } 
    uploadFileEx = async (selectedFile) => {
        // let response = false;
        let response = {status: false, respData: []};
        let exist_flag = await this.isFolderExist();
        if (!exist_flag) {
            this.createFolder();
        }
        if (selectedFile == null) {
            return false;
        }
        let fileName = encodeURIComponent(selectedFile.name.replace(/#/g, "_"));
        let relativePath = '/_api/web/GetFolderByServerRelativeUrl(\'/sites/';
        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '\')/Files/add(url=' + '\'' + fileName + '\'' + ',overwrite=true)';
        //let fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFolderByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3\')/Files/add(url=\'text.txt\',overwrite=true)';

        let fileBinary=await this.readFileAsync(selectedFile);
        await  axios(fileUrl, {
            method: "POST",
            headers: {
                     'Content-Type': 'application/json;odata=verbose',
                     'Authorization': 'Bearer ' + this.state.access_token 
                     }, 
                     data: fileBinary,
             })    
             .then((resp) =>
             {
                //  if(resp.status === 200)
                //  {                              
                //     response=true;
                //  }
                response={
                    status: resp.status, 
                    respData: resp.data
                }
            });
        return response;
    }
    //Used to Upload a file to the SharepPoint Library
    uploadFileFunction = async () => {
        console.log(' uploadFileSPO Click!!!!');
        let exist_flag = await this.isFolderExist();
        console.log(exist_flag)
        if (!exist_flag) {
            this.createFolder();
        }
        if (this.state.selectedFile == null) {
            alert("Please Select a file to upload");
        }
        else {
            this.setState({
                showLoaderUpload :  true,
            })
            let fileName = encodeURIComponent(this.state.selectedFile.name.replace(/#/g,"_"));
            let relativePath = '/_api/web/GetFolderByServerRelativeUrl(\'/sites/';
            let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '\')/Files/add(url=' + '\'' + fileName + '\'' + ',overwrite=true)';
            //let fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFolderByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3\')/Files/add(url=\'text.txt\',overwrite=true)';

            const reader = new FileReader();
            reader.readAsArrayBuffer(this.state.selectedFile);
            
            reader.onload = async (event) => {
                //console.log("reader data",event.target.result);
                axios(fileUrl, {
                    method: "POST",
                    headers: {
                             'Content-Type': 'application/json;odata=verbose',
                             'Authorization': 'Bearer ' + this.state.access_token 
                             }, 
                             data: event.target.result,
                     })    
                     .then((response) =>
                     {
                         if(response.status === 200)
                         {
                            console.log("FileUploaded Successfully.");
                            this.setState({
                                        success_body: "Uploaded successfully!",
                                        successModal: true,
                                        selectedFile : null,
                                        fileMessage: "File uploaded!",
                                        showLoaderUpload : false
                                    })
                                    this.getFiles();
                                }
                         else
                         {
                            // console.error(err);
                                this.setState({ errorModal: true ,
                                    // fileMessage: "File uploaded!",
                                    showLoaderUpload : false});
            
                         }
                        
                    })   
                    // await           
                };
        }
    }
    openSharepoint  = async() => {
        let exist_flag = await this.isFolderExist();
        console.log(exist_flag)
        if (!exist_flag) {
            this.createFolder();
        }
        window.open(`https://collab.lilly.com/:f:/r/sites/${this.state.siteEnv}/${this.state.libraryName}/Submission/Submission-${(""+this.props.submissionDetails?.id).padStart(4,0)}?csf=1&web=1&e=7OE383`);
    }
    render() {
        let { listOfFiles,submissionDetails, successModal, errorModal ,success_body,exist_flag,showLoaderUpload  , action ,  showLoader } = this.state;

        
        return (
            <>
            <Row className = "pb-4">
                <Col sm = {1}>
                    <img
                        src={logo}
                        width="40"
                        height="40"
                        className="d-inline-block align-top"
                        alt="logo"
                    />
                </Col>
                <Col className = "navbar-brand" sm={11}>
                    <div>Sharepoint Files</div>
                </Col>    
            </Row>
                <Row>
                  {exist_flag && this.state.access.edit && enableWFActions() && <Col sm={6}>
                        <Form.File
                            id="custom-file"
                            className="custom-file-label"
                            onChange={this.onFileChange}
                            label={this.state.selectedFile?.name}
                            multiple
                            custom
                        />
                    </Col>}
                    {exist_flag && this.state.access.edit && enableWFActions() && <Col sm={6}>
                        {showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </Button>}
                        {!showLoaderUpload && <Button onClick={() => uploadFile(this.state.selectedFile,this.state.listOfFiles,this.uploadMultipleFiles)} variant="secondary">Upload Attachment</Button>
                        }

                    </Col>}
                    <Col sm={6}>
                        {exist_flag && <Button onClick={this.openSharepoint}>Go To SharePoint Library </Button> }
                        {!exist_flag && enableWFActions() && this.state.access.edit &&  <Button onClick={this.createFolder}>Create Folder </Button> }
                        {/* <Button onClick={this.uploadFileSPO} variant="secondary">Upload Attachment</Button> */}
                    </Col>
                </Row>
                
                {showLoader && 
                <Row>
                    <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                    <span className="visually-hidden">File  {action} in progress </span>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
                
                </Row>}
                
                <br />
               {exist_flag && <Row>
                    {/* <Col md={7}>                    
                    <h6 className="table-top"> Files({listOfFiles})</h6>
                </Col> */}
                    {/* <DatatableComponent data={this.data} columns={this.columns} rowEvents={this.rowEvents} pagination="true" /> */}
                    <Col md={12}> 
                    {this.state.listOfFiles.length > 0 &&
                        <FileComponent data={listOfFiles}
                            accessDelete = {this.state.access.delete}
                            handleDelete={this.deleteFile}
                            type={"sharepoint"}
                            handleDownload={this.downloadFile} />}
                      </Col>
                </Row>
                }{this.state.successModal &&
                    <BootstrapModal
                        show={successModal}
                        header="Success"
                        body={success_body}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </>
        )
    }
}
