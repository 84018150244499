import React from 'react';
import swal from 'sweetalert'
import API from '../../api/apis';
const getSubmissionText=(submission_id)=>{
    return submission_id?`Submission-${(submission_id + "").padStart(4, '0')}`:"";
}
const userPermissions=(contextInfo)=>{
    let selectedRole=Array.isArray(contextInfo.selectedRole) ? 'administrator' : contextInfo.selectedRole;
   let access={
        view: true,
        create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
        edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
        delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator")
    };
    return access;
}
const isCoordinator=(contextInfo)=>{
    let selectedRole=Array.isArray(contextInfo.selectedRole) ? 'administrator' : contextInfo.selectedRole;
    return selectedRole.includes("coordinator") || selectedRole.includes("administrator");
}
const showMessage=(props,callBack)=>{
    swal({...props}).then((value)=>{
        if(callBack){
            callBack(value);
        }        
      });
}
const updateNotification=async(userSystemId,review_header_id,asrraySystemIds)=>{
    try{
       

        const res = await API.post(`reviewConcept/notification/document/add `, {
            "system_id":asrraySystemIds?asrraySystemIds.split(","):[userSystemId],
            "updated_by": userSystemId,
            "review_header_id": review_header_id
        });   
    }
    catch(exp){

    }      
}
const currentWorkflow = async (userSystemId) => {
    try{
      const res = await API.get(`enrollmentOptimization/userSettings/fetch/${userSystemId}`);
      if(res?.data){
       return res?.data?.new_workflow_toggle;
      }
    }
    catch(e) {
      console.log(e);
    }
    return false;
  }
const getWorkflowUrl=(isNewWorkFlow,params)=>{
    //submissions
    let url="/submissions/";
    if(isNewWorkFlow)
        url="/toreview/details/";
   return window.location.origin + `${url}${params}`;
}
export {getSubmissionText,userPermissions,showMessage,isCoordinator,updateNotification,currentWorkflow,getWorkflowUrl};