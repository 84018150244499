import React, { Component } from 'react'
import { THERAPEUTIC_AREA, REQUEST_TYPE, INDICATIONS } from '../../../consts';
import { Button, Col, Row, Card } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../../common/sticky-nav/StickyNav';
import InputField from '../../common/input-fields/InputField';
// import History from './History';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../../common/loader/Loader';
import { getUserInfo,dateTimeformat, enableWFActions } from '../../../utility/helperFunctions';
import UserContext from '../../../context/UserContext';
import ContactsModal from './ContactsModal';

class ContactDetails extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        hasChanged: false,
        submissionId:"",
        patchObject:{"id":this.props.match.params.contactsId},
        countryObj:{},
        name: "",
       
        type: "",
        institutiion: "",
        viewEditData:{},
        showHideModal: false,
        viewEditFlag: null,

    }
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        }

    ];


    get inputFieldsArray() {
        return [ {
                label: "Submission",
                type: "text",
                name: "submission",
                disabled: true
            },
            {
                label: "Street Address",
                type: "text",
                name: "street_address",
                disabled: true
            }, {
                label: "Type *",
                type: "text",
                placeholder: "Select Type",
                name: "type",
                disabled:true
                // options: [
                //     "None",
                //     "Additional Investigator",
                //     "Budget",
                //     "Contracting",
                //     "Legal",
                //     "Pharmacy Coordinator",
                //     "Regulatory",
                //     "Study Coordinator"
                // ]
            },
            {
                label: "City",
                type: "text",
                name: "city",
                disabled: true
            },
            {
                label: "Name",
                type: "text",
                name: "name",
                disabled: true
            },
            {
                label: "Country",
                type: "searchableDropdown",
                name: "country_id",
                disabled:true,
                options: this.state.countryList,
                onSelect: this.onCountryChange,
            },
            {
                label: "Institution *",
                name: "institutiion",
                type: "text",
                disabled:true
            },
            {
                label: "State",
                type: "searchableDropdown",
                name: "state_id",
                disabled:true,
                options: this.state.stateList,
                onSelect: this.onSearchSelect,
               
            },
            {
                label: "Phone Number",
                type: "text",
                name: "phone_number",
                disabled: true
            },
            {
                label: "Zip/Postal Code",
                name: "zip_postal_code__c",
                type: "text",
                disabled: true
            }, {
                label: "Email",
                name: "email",
                type: "text",
                disabled: true
            },  {
                label: "Last Modified By",
                type: "text",
                name: "last_modifiedby",
                className:"createUpdateByfontsize",
                disabled: true
            },
            {
                label: "Created By",
                name: "createdby",
                className:"createUpdateByfontsize",
                type: "text",
                disabled: true
            }
        ];
    }

    async componentDidMount() {
        await this.getCountryList();
        await this.getStateList();
        await this.getStudyContactByContactId(this.props.match.params.contactsId);
    
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    getStudyContactByContactId = async (id) => {
        try {
            await this.getCountryList();
            const reforcastDetails = await API.get(`contact/getbyid/${id}`);
            const viewEditData = {...reforcastDetails.data};
            reforcastDetails.data["country_id"] =reforcastDetails.data.country_id!=null ? this.state.countryList.filter(item => item.value === reforcastDetails.data.country_id)[0] : "";
            // reforcastDetails.data["countryid"]= reforcastDetails.data["country_id"]?.name;
            await this.getStateList(reforcastDetails.data.country_id.name);
            reforcastDetails.data["state_id"] =reforcastDetails.data.state_id!=null ? this.state.stateList.filter(item => item.value === reforcastDetails.data.state_id)[0] :"";
            // reforcastDetails.data["stateid"]=reforcastDetails.data["state_id"]?.name;
            reforcastDetails.data["createdby"]=reforcastDetails.data.created_by?.length ? await getUserInfo(reforcastDetails.data.created_by)+"  "+ dateTimeformat(reforcastDetails.data.createdAt) : "";
            reforcastDetails.data["last_modifiedby"]=reforcastDetails.data.last_modified_by_id?.length ? await getUserInfo(reforcastDetails.data.last_modified_by_id)+"  "+ dateTimeformat(reforcastDetails.data.updatedAt) : reforcastDetails.data["createdby"];
            reforcastDetails.data["submission"]=`Submission- ${reforcastDetails.data.submissionId}`
             reforcastDetails.data["updatedby"]=reforcastDetails.data.updated_by?.length > 0 ? await getUserInfo(reforcastDetails.data.updated_by) + "  " + dateTimeformat(reforcastDetails.data.updatedAt):reforcastDetails.data.createdby ;
            this.setState({
                ...reforcastDetails.data,
                viewEditData:viewEditData,
                submissionId:reforcastDetails.data.submissionId,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/data");
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({countryList: resultArray});
            
    }

    getStateList = async (country) => {
         if (country) {
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({stateList: resultArray});
    }}

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            countryId:e.name,
            patchObject:  {...this.state.patchObject, [name]: e.value},
           
        });
       
            this.getStateList(e.name)
       
    }
    onInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value ,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},});
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e},
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }

    checkboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked ,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.checked},
        
    })}
    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
            state_id:e.name
            // patchObject:  {...this.state.patchObject,state_id:e.value},
            
        });
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            patchObject: {...this.state.patchObject, [e]: this.getHtmlFromEditorValue(val)},
            hasChanged: true
        });
    };

   

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        error={this.state.error?.[field.name]}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.submissionId}`)
    }
    handleContactsUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        state.last_modified_by_id=this.context.userSystemId;
        try {
            const res = await API.post("contact/update", state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getStudyContactByContactId(this.props.match.params.contactsId);
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
}
    formValidation = () => {
        let is_valid = true;
        const statecopy = { ...this.state };
                delete statecopy.compoundList;
                delete statecopy.stateList;
                delete statecopy.countryList;
                delete statecopy.hasChanged;
                delete statecopy.error;
             const   initialValues={
                   name:this.state.name,
                   type:this.state.type,
                   institutiion:this.state.institutiion,
                  
                  }
                  const errors = {}
                  Object.keys(initialValues).forEach((key) => {
                    if (initialValues[key] === "" || initialValues[key] === null ) {
                        errors[key] = true;
                        is_valid = false;
                        
                    } 
                    this.setState({
                        error:  { ...errors}
                    });
                });
                  
        return is_valid;

    }
    submitForm = async () => {
        if(this.formValidation()){
        const contactObj = this.state.patchObject;
        contactObj["updated_by"] = this.context.userSystemId;
        contactObj["last_modified_by_id"]=this.context.userSystemId;
        contactObj["created_by"] = this.context.userSystemId;
        contactObj["submissionId"]=this.state.submissionId;
        try {
            const res = await API.post("contact/update", contactObj);
            this.setState({ successModal: true });
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    }


    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getStudyContactByContactId(this.props.match.params.contactsId);
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, success_body, errorModal,successModal } = this.state;
        if(!this.state.submissionId)
            return <Loader />
        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                            {" Submission "}{this.state.submissionId}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Additional Study Contact </h4>
                    {this.state.access.edit && enableWFActions()&& <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                                {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} >
                                        Save
                                    </Button>
                                </div> */}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                {/* <Col md={6}>
                    <Card className="data-cards">
                        <Card.Body>
                            <Tabs defaultActiveKey="history">
                                <Tab eventKey="history" title="History">
                                    <History historyData={this.historyData} historyTitle="Additional Study Contact History" />
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col> */}
            </Row>

             {
                showHideModal &&
                <ContactsModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleContactsSave={this.handleContactsSave}
                    handleContactsUpdate={this.handleContactsUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    submissionId={this.state.submissionId}
                />
            }
             {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
        </>
    }
}

export default withRouter(ContactDetails);
