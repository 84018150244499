import React, { Component } from 'react'
import { Col, Row, Button, Nav } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import API from "../../../../../api/apis";
import Dropdown from 'react-bootstrap/Dropdown';
import DatatableComponent from '../../../../common/datatable/DatatableComponent';
import DatatTableEx from '../../../../common/DataTableEx';
import DrugOrderModal from './DrugOrderModalEx';
import Loader from '../../../../common/loader/Loader';
import BootstrapModal from '../../../../common/modal/BootstrapModal';
import swal from 'sweetalert'
import UserContext from '../../../../../context/UserContext';
import { convertISOStringMMDDYYYY } from '../../../../common/dateFormat';
import { dateChange, dateStringformat } from '../../../../../utility/helperFunctions';
import { isCoordinator } from '../../../../ToReviewDetails/commonFunctions';
import DTAButton from '../../../../common/Button/DTAButton';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </div>
));
class DrugOrder extends Component {
    static contextType = UserContext;
    state = {
        is_Coordinator:false,
        access: false,
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        drugOrderDetails: [],
          filterSource:[],
        error: false,
        successModal: false,
        errorModal: false,
    }

    componentDidMount() {
        this.attachColumEvent()
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator ,access:_isCoordinator});
        this.getDrugOrderDetailsBySubmissionId(this.props.submissionId)
    }
   
    attachColumEvent=()=>{
        this.columns.map((column,index)=>{
            column.events=this.columnEvent;
            });
    }
    columnEvent = {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            if ( column.dataField != 'actions') {
                this.handleModalShowHide(true, "edit", row, rowIndex)
            }

        }
    }

    getDrugOrderDetailsBySubmissionId = async (submissionId) => {
        try {
            const res = await API.get(`sites/drugorders/all/${submissionId}?search=submission_id`);
            console.log("res",res)
            if(res&&res.data){
                let drugOrderDetailss=res.data.map((item,index)=>{
                let {compound,drug_delivery_site,id,llir_status,date_requested,order_site}=item;
                return {llir_status,order_site,date_requested:date_requested?dateStringformat(date_requested):"",id:"R-0"+id}
            });console.log("drugorderdetails",drugOrderDetailss)
            let filterSource=await this.getFilterSource({data:drugOrderDetailss}); 
            this.setState({
                drugOrderDetails: drugOrderDetailss,filterSource:[...filterSource],
                showLoader: false
            })}
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    getFilterSource=async (res)=>{
        if(res&&res.data&&res.data.length>0){
            let filterCollection= this.columns.filter(
                p => (!p.hidden && (p.dataField != "actions"))).map((col, index) => {
                let columnValues = (res.data || []).map((item, key) => {
                    if(col.dataField.split(".").length>1){
                        return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
                    }
                    return item[col.dataField];
                });
                return {group:col.text,key:col.dataField,items:[...new Set(columnValues)]};
            });
            return filterCollection;
        }
        return null;
          
    }

    handleModalShowHide = async (flag, viewEditFlag, row) => {
        if (row && row.id && viewEditFlag === "edit") {
            await this.getdrugOrderById((row.id).replace("R-0",""))
            this.setState({ showHideModal: flag, viewEditFlag });
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
    }

    getdrugOrderById = async (id) => {
        try {
            const drugOrderDetails = await API.get(`sites/drugorders/getbyid/${id}`);
            this.setState({
                viewEditData: drugOrderDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    handleDrugOrderDetails = (row) => {
        this.props.history.push(`/drugOrder/details/${row.id}`, { id: row.id, submissionId: this.props.submissionId });
    }

    columns = [
        {
            dataField: 'id',
            text: 'id',
            sort: true,
            hidden:true,
            csvExport: false ,
            formatter:(cell,row,rowIndex)=>{
                return <p onClick={()=>this.handleModalShowHide(true,"edit",row)}></p>
            }
        },
   {
        dataField: 'id',
        text: 'Record ID',
        sort: true,
        
    }, {
        dataField: 'date_requested',
        text: 'Date Requested',
        sort: true,

    },
    {
        dataField: 'order_site',
        text: 'Order Site',
        sort: true,
        
    }, {
        dataField: 'llir_status',
        text: 'Status',
        sort: true
    }, {
        dataField: 'actions',
        text: "Actions",
        headerClasses: "width-90",
        csvExport: false,
        formatter: (cell, row, rowIndex) => {

            var visibilityEdit = this.state.is_Coordinator ? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            return <>
              { <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        <span className="material-icons mr-2" >
                            more_vert
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-dark text-white" >
                       {this.state.is_Coordinator? <Dropdown.Item onClick={() => this.handleModalShowHide(true, "edit", row)} className="text-white">Edit</Dropdown.Item>:
 <Dropdown.Item onClick={() => this.handleModalShowHide(true, "edit", row)} className="text-white">View</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>}

            </>
        }
    }];

    handleDrugOrderSave = async (state) => {
        state.created_by = this.context.userSystemId;
        state.submission_id = this.props.submissionId;
        state.broker_country_id = state.broker_country_id?.value;
        state.broker_state_id = state.broker_state_id?.value || state.broker_state_id;
        state.order_site_id = state.order_site_id?.value;
        state.site_drug_order_id = state.drug_compound_id?.sitedrugoption?.id;
        state.drug_compound_id = state.drug_compound_id?.value;
        state.has_the_drug_delivery_shipment_address = "No";
        if (state.is_attest_to_the_details_of_this_order === true) { state.date_requested = new Date() }
        state.date_needed = state.date_needed != "" && state.date_needed != null ?
            dateChange(state.date_needed) : null;
        try {
            const res = await API.post(`sites/drugorders/${state.order_site_id}/upsert`, { drugOrder: state });
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getDrugOrderDetailsBySubmissionId(this.props.submissionId)

        } catch (err) {
            this.setState({ errorModal: true });
        }

    }
    handleDrugOrderUpdate = async (state) => {
        delete state.created_by1;
        delete state.updated_by1;
        delete state.createdAt;
        delete state.created_by;
        delete state.created_at;
        state.updated_by = this.context.userSystemId;
        state.submission_id = this.props.submissionId;
        state.id = state.record_id;
        state.broker_country_id = state.broker_country_id?.value || state.broker_country_id;
        state.broker_state_id = state.broker_state_id?.value || state.broker_state_id;
        state.order_site_id = state.order_site_id?.value || state.order_site_id;
        state.drug_compound_id = state.drug_compound_id?.value || state.drug_compound_id;
        state.date_needed = state.date_needed != "" && state.date_needed != null ?
            dateChange(state.date_needed) : null;
        state.has_the_drug_delivery_shipment_address = "No";
        if (state.is_attest_to_the_details_of_this_order === true) { state.date_requested = new Date() }
        try {
            const res = await API.post(`sites/drugorders/${state.order_site_id}/upsert`, { drugOrder: state });
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getDrugOrderDetailsBySubmissionId(this.props.submissionId);
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }


    handleDrugOrderDelete = async (row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`sites/drugorders/${row.id}`);
                        this.setState({
                            success_body: "Deleted successfully!",
                            successModal: true,
                            hasChanged: false,
                            drugOrderDetails: []
                        });
                        this.getDrugOrderDetailsBySubmissionId(this.props.submissionId)
                    } catch (err) {
                        this.setState({ errorModal: true });
                    }
                }
            });

    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, drugOrderDetails,
            successModal, showLoader, success_body, errorModal } = this.state;
        return (<> {

            showLoader ? <Loader /> :
                <><div style={{ paddingTop: '1rem' }}>
                    <Row>

                        <DatatTableEx id="drugOrder" 
                        showLoader={this.state.showLoader}
            
                         filterSource={this.state.filterSource}
                         filterChange={()=>{}} csvName="drug_Order_details" title={" Drug Orders"} pageIcons={this.state.is_Coordinator&&[<DTAButton onClick={() => this.handleModalShowHide(true, null, null)} icon="ADD" text="Add drug order"></DTAButton>
                         ]} data={drugOrderDetails} columns={this.columns} pagination="true" />
                    </Row>
                    {
                        showHideModal &&
                        <DrugOrderModal
                            showHideModal={showHideModal}
                            handleModalShowHide={this.handleModalShowHide}
                            handleDrugOrderSave={this.handleDrugOrderSave}
                            handleDrugOrderUpdate={this.handleDrugOrderUpdate}
                            viewEditFlag={viewEditFlag}
                            viewEditData={viewEditData}
                            submissionId={this.props.submissionId}
                        />
                    }
                    {this.state.successModal &&
                        <BootstrapModal
                            show={successModal}
                            header="Success"
                            body={success_body}
                            changeShowState={this.changeShowState}
                        />
                    }
                    {this.state.errorModal &&
                        <BootstrapModal
                            show={errorModal}
                            variant="danger"
                            header="Ooops!!"
                            body="An error occured! Please try again later!"
                            changeShowState={this.changeShowState}
                        />
                    }
                </div> </>
        }
        </>
        )
    }
}

export default withRouter(DrugOrder);

