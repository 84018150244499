
import React, { Component } from 'react'
import { Button, Card, Col, Container, Form, Modal, Navbar, Row, Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { enableWFActions } from '../../../utility/helperFunctions';



import StickyNav from '../../common/sticky-nav/StickyNav';

import { dateChange, dateStringformat, dateTimeformat, getUserInfo } from '../../../utility/helperFunctions';
import UserContext from '../../../context/UserContext';

import API from '../../../api/apis';
import InputField from '../../common/input-fields/InputField';
import BootstrapModal from '../../common/modal/BootstrapModal';
import ReviewConceptModal from './ReviewConceptModal';
import ReviewFiles from '../ReviewFiles';
import { yyyymmddFormat } from '../../common/dateFormat';

class ReviewConceptDetail extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: true,
            edit: false,
            delete: false,
        },
        reviewNumber:"",
        reviewTitle: "",
        type: "Concept",
        owner_id: this.context.userSystemId,
        submission_id: "",
        viewEditData: {},
        showHideModal: false,
        viewEditFlag: null,
        fileList: [],
        selectedFile: null,
        successFile: false,
        errorFile: false,
        showLoader: false,
        AmendmentDetails: {},
        auditData: [],
        trailData: [],
        date_of_review:null

    }
    get inputFieldsArray() {
        return [
            {
                label: "Review Number",
                type: "text",
                disabled: true,
                name: "reviewNumber",

            },
            {
                label: "Review Title",
                type: "text",
                disabled: true,
                name: "reviewTitle",

            },
            {
                label: "Record Type",
                type: "text",
                disabled: true,
                name: "type",

            },
            {
                label: "Submission",
                type: "text",
                disabled: true,
                name: "submissionId",

            },
            {
                label: "Name of Reviewer or Committee",
                type: "text",
                placeholder: "Enter Name",
                name: "name_medical_reviewer_committee",
                disabled: true,
            },
            {
                label: "Date of Review",
                type: "date",
                placeholder: "Enter Review Date",
                name: "date_of_review",
                disabled: true,
            },
            {
                label: "Approved?",
                type: "dropdown",
                placeholder: "Select Invite Proposal Requirement",
                name: "proposal_for_proposed_study",
                options: ["Yes", "No"],
                disabled: true,
            },
            {
                label: "Comments",
                type: "textarea",
                placeholder: "write comments...",
                name: "comments",
                disabled: true,
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                disabled: true,
                className:"createUpdateByfontsize",
            }
            , {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                disabled: true,
                className:"createUpdateByfontsize",
            }
        ];
    }
    async componentDidMount() {
    
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  true,
                edit: selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
        this.getReviewById(this.props.match.params.conceptId)

    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:true,
                    edit:  selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getReviewById = async (id, name) => {
        const res = await API.get(`review/concept/${id}`);
        const viewEditData = { ...res.data };
        console.log(res.data)
        res.data["date_of_review"] = res.data.date_of_review != "" && res.data.date_of_review != null ?
       dateStringformat(res.data.date_of_review) : null;
       
        res.data["proposal_for_proposed_study"] = res.data.proposal_for_proposed_study === true ? "Yes" : "No";
        res.data["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
        res.data["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt):res.data.createdby ;
        this.state.submission_id = res.data.submission_id;
        this.setState({
            ...res.data,
            reviewNumber:`R-${res.data.id}`,
            reviewTitle:"Submission-" + res.data.submission_id+" Concept-Review-"+res.data.id,
            submissionId: "Submission-" + res.data.submission_id,
            viewEditData: viewEditData,
            showLoader: false
        })
    }
    handleConceptUpdate = async (state) => {
        state.submission_id = this.state.submission_id;
        state.updated_by = this.context.userSystemId;
        state["date_of_review"] = state.date_of_review != "" && state.date_of_review != null ?
        dateChange(state.date_of_review) : null;
        try {
            const res = API.post(`review/concept/update/`, state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })

        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
        this.getReviewById(this.props.match.params.conceptId)
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
    }
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else
        { this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>{this.onInputChange(e,field)}) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false
        });
        this.getReviewById(this.props.match.params.conceptId)
    }
    onNavOut = () => {
        window.history.back()
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData } = this.state
        return (
            <>
                <StickyNav>
                    <Navbar expand="sm" className="nav-secondary">
                        <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                            <i className="bi bi-arrow-left">
                            {" "}{this.state.submissionId}
                            </i>
                        </div>
                        <h4 className="text-center" style={{ "width": "66%" }}>Concept Review</h4>
                        {this.state.access.edit && enableWFActions() && <div className="text-right" style={{ "width": "20%" }}>
                            <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                        </div>}
                    </Navbar>
                </StickyNav>
                <Row>
                    <Col md={6}>
                        <Card className="data-cards pt-3">
                            <Card.Body>
                                <Row>
                                    {this.renderInputFields()}
                                    {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} >
                                        Save
                                    </Button>
                                </div> */}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card className="data-cards">
                            <Card.Body>
                                <Tabs defaultActiveKey="files">
                                    <Tab eventKey="files" title="Files">
                                    {this.state.submission_id && this.state.id &&  <ReviewFiles
                                       access = {this.state.access}
                                       type = "Concept"
                                       submissionid ={this.state.submission_id}
                                       id = {this.state.id}
                                       ></ReviewFiles>}
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                      
                    </Col>
              
                </Row>

                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body="Updated Successfully!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.successFile &&
                    <BootstrapModal
                        show={this.state.successFile}
                        header="Success"
                        body={this.state.fileMessage}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorFile &&
                    <BootstrapModal
                        show={this.state.errorFile}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                {showHideModal &&
                    <ReviewConceptModal
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handlePublicationSave={this.handlePublicationSave}
                        handleConceptUpdate={this.handleConceptUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}
                        title={this.state.reviewTitle}
                        type={this.state.type}
                    />}
            </>
        )
    }
}


export default withRouter(ReviewConceptDetail);