import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import { yyyymmddFormat } from '../../common/dateFormat';

import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';
import Loader from '../../common/loader/Loader';

class EnrollmentModal extends React.Component {
    state = {
        currencyList: [],
        isLoader: true,
        hasChanged: false,
        submissionId_str: "Submission-" + this.props.submissionId,
        year: "",
        quarter: "",
        submissionList: [],
        currency_iso_code: this.props.currency_iso_code

    }
    async componentDidMount() {
        const { viewEditData, viewEditFlag } = this.props;
        await this.getCurrencyList();
        if (viewEditFlag === "edit") {
            viewEditData["currency"] = this.state.currencyList.filter(item => item.id === viewEditData.currency_iso_code)[0];
            viewEditData["baseline_budget"] = viewEditData.baseline_budget !== null ? viewEditData["currency"].value + " " + viewEditData.baseline_budget.replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""
            viewEditData["estimated_budget"] = viewEditData.estimated_budget !== null ? viewEditData["currency"].value + " " + viewEditData.estimated_budget.replace(/\d(?=(\d{3})+\.)/g, '$&,') : "";
            viewEditData["actual_budget"] = viewEditData.actual_budget !== null ? viewEditData["currency"].value + " " + viewEditData.actual_budget.replace(/\d(?=(\d{3})+\.)/g, '$&,') : "";
            viewEditData["createdby"] = viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
            viewEditData["updatedby"] = viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt) : viewEditData.createdby;
            this.setState({
                ...this.state,
                ...viewEditData,
                isLoader: false,
                submissionId: "Submission-" + viewEditData.submissionId,
                patchObject: { id: viewEditData["id"] }
            })
        }
        else {
            this.setState({
                isLoader: false,
                currency: this.state.currencyList.filter(item => item.id === this.state.currency_iso_code)[0]
            })
        }
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            currency_iso_code: e.id,
            patchObject: { ...this.state.patchObject, currency_iso_code: e.id },

        });
    }
    getCurrencyList = async () => {
        try {
            const currencyList = await API.get(`seed/currency/data`);
            if(currencyList.data&&currencyList.data.success){
            this.setState({
                currencyList: currencyList.data.data,
                showLoader: false
            })}
            else{
                this.setState({
                    currencyList:[]
                })
            }
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    get inputFieldsArray() {
        return [{
            type: "alert",
            label: "Information",
            variant: "secondary"
        },
        {
            label: "Baseline Enrollment",
            type: "number",
            helptext: "Expected Enrollment for quarter based on initial projection",

            name: "baseline_enrollment",
        },
        {
            label: "* [EXT] Year",
            type: "dropdown",
            placeholder: "Select Year",
            name: "year",
            options: [2040, 2039, 2038, 2037, 2036, 2035, 2034, 2033, 2032, 2031,
                2030, 2029, 2028, 2027, 2026, 2025, 2024, 2023, 2022, 2021
                , 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011
                , 2010].sort()

        },
        {
            label: "Estimated Enrollment",
            type: "number",
            name: "estimated_enrollment",
            helptext: "Expected Enrollment for quarter based on current projections"

        },
        {
            label: "* [EXT] Quarter",
            type: "dropdown",
            placeholder: "Select [EXT] Quarter",
            name: "quarter",
            options: ["Q1", "Q2", "Q3", "Q4"],
            helptext: "Select quarter for enrollment update"

        },
        {
            label: "Actual Enrollment",
            type: "number",
            name: "actual_enrollment",
            disabled: true,
            helptext: "Enrollment for quarter"

        },
        {
            label: "Baseline Budget",
            type: "text",
            name: "baseline_budget",
            disabled: true,
            edit: true,
            helptext: "Expected cost of patient enrollment based on initial projections"

        },
        {
            label: "Estimated Budget",
            type: "text",
            name: "estimated_budget",
            disabled: true,
            edit: true,
            helptext: "Expected cost of patient enrollment based on current projections"

        },
        {
            label: "Actual Budget",
            type: "text",
            name: "actual_budget",
            disabled: true,
            edit: true,
            helptext: "Cost of enrollment for the quarter"

        },
        {
            label: "Currency",
            type: "searchableDropdown",
            placeholder: "Select Currency",
            name: "currency",
            options: this.state.currencyList,
            onSelect: this.onSearchSelect
        },
        {
            label: "[EXT] Patients Currently on Treatment",
            type: "number",
            name: "patients_currently_on_treatment",
        },
        {
            label: "[EXT] Comments",
            type: "textarea",
            name: "comments",
        },
        {
            label: "[EXT] Patients Currently on Followup",
            type: "number",
            name: "patients_currently_on_followup",
        },
        {
            label: "Submission",
            type: "text",
            placeholder: "Select Submission",
            name: "submissionId_str",
            disabled: true
        },
        {
            label: "[EXT] Patients Completed Trial",
            type: "number",
            name: "patients_completed_trial",
        }, {
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
        }, {
            label: "[EXT] Pts Disc. from Treatment",
            type: "number",
            name: "patients_discontinued_from_treatment",
        }, {
            label: "[EXT] Total Patients Entered Treatment",
            type: "number",
            name: "total_patients_entered_treatment",
        },
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className: "createUpdateByfontsize",
            disabled: true,
            edit: true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className: "createUpdateByfontsize",
            disabled: true,
            edit: true
        },
        ];
    }


    onInputChange = (e) => {
        console.log(e)
        this.setState({
            hasChanged: true,
            patchObject: {
                ...this.state.patchObject, [e.target.name]: e.target.type === "number" ? parseInt(e.target.value) : e.target.value
            },

            [e.target.name]: e.target.type === "number" ? parseInt(e.target.value) : e.target.value
        });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },

        });
    }

    // onSearchSelect = (e, name) => {
    //     this.setState({
    //         hasChanged: true,
    //         [name]: e,
    //     });
    // }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }
    async handleSave(obj, saveNew) {
        let errors = {};
        let customerror = {};
        if ((this.props.viewEditData.total_patients_entered_treatment !== obj.total_patients_entered_treatment) && (obj.total_patients_entered_treatment && (obj.total_patients_entered_treatment < this.props.max_actual))) {
            customerror["total_patients_entered_treatment"] = "'Total Patients Entered Treatment' should be greater than the 'Current Enrollment (Actual)'";


            this.setState({
                error: { ...errors },
                customerror: { ...customerror }
            });
            return false;
        }
        else if (obj.year === "" || obj.year === null || obj.quarter === "" || obj.quarter === null) {
            errors["year"] = (obj.year === "" || obj.year === null) ? true : false;
            errors["quarter"] = (obj.quarter === "" || obj.quarter === null) ? true : false;;
            this.setState({
                error: { ...errors },
            });
            return false;
        }
        // else if (obj.quarter === "" || obj.quarter === null) {
        //     errors["quarter"] = true;
        //     this.setState({
        //         error: { ...errors },
        //     });
        //     return false;

        // }
        else {
            if (this.props.viewEditFlag === "edit") {
                let patchObj = this.state.patchObject;
                let res = await this.props.handleEnrollmentsUpdate(patchObj);
                console.log("saveres", res)
                if (res.error && res.error.includes("Unique")) {
                    customerror["year"] = "Duplicate value found: Quarter - Year should be unique";
                    customerror["quarter"] = "Duplicate value found: Quarter - Year should be unique";
                    if (res.error.includes("Recycle Bin")) {
                        customerror["year"] = "Duplicate value found in Recycle Bin: Quarter - Year should be unique";
                        customerror["quarter"] = "Duplicate value found in Recycle Bin: Quarter - Year should be unique";
                    }
                    this.setState({
                        customerror: { ...customerror }
                    });
                }
            } else {
                let res = await this.props.handleEnrollmentsSave(obj, saveNew);
                console.log("saveres", res)
                if (res.error && res.error.includes("Unique")) {
                    customerror["year"] = "Duplicate value found: Quarter - Year should be unique";
                    customerror["quarter"] = "Duplicate value found: Quarter - Year should be unique";
                    if (res.error.includes("Recycle Bin")) {
                        customerror["year"] = "Duplicate value found in Recycle Bin: Quarter - Year should be unique";
                        customerror["quarter"] = "Duplicate value found in Recycle Bin: Quarter - Year should be unique";
                    }
                    this.setState({
                        customerror: { ...customerror }
                    });
                }

            }
        }
    }
    render() {
        const { handleModalShowHide, showHideModal, handleEnrollmentsSave, viewEditFlag, handleEnrollmentsUpdate } = this.props;
        const { id } = this.state;

        return (
            <div>
                <Modal show={showHideModal} size="lg">
                    {this.state.isLoader === true ?
                        <Loader /> : <div><Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                            <Modal.Title>
                                {viewEditFlag === "edit" ?
                                    `Edit Enrollment  - ${("000" + id).slice(-4)}` : "New Enrollment"}
                            </Modal.Title>
                        </Modal.Header>
                            <Modal.Body >
                                <Row>
                                    {this.renderInputFields()}
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                                    Cancel
                                </Button>
                                {
                                    viewEditFlag === "edit" ?
                                        <Button variant="primary" onClick={() => this.handleSave(this.state, false)}>
                                            Save
                                        </Button> : <> <Button variant="primary" onClick={() => this.handleSave(this.state, false)}>
                                            Save
                                        </Button>
                                            <Button variant="primary" onClick={() => this.handleSave(this.state, true)}>
                                                Save & New
                                            </Button></>
                                }

                            </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default EnrollmentModal;