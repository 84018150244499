import React from 'react'
import { Col, Row,Button, Table, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import InputField from '../../../common/input-fields/InputField';
import UserContext from '../../../../context/UserContext';
import { isCoordinator } from '../../../ToReviewDetails/commonFunctions';
import API from '../../../../api/apis';
import ToolTipEx from '../../../common/ToolTipEx';
import { DEFAULT } from '../../../../TooltipInfo';
export default class DrugOptionsEx extends React.Component{
    static contextType=UserContext;
    constructor(props){
        super(props);
        this.state={
            drugOptions: this.props.source||[],
            name:this.props.name||'',
            error:{
                amount:null,
                frequency:null
            }
        };
    }
    componentDidMount=()=>{
        let is_coordinator=isCoordinator(this.context);
        this.setState({is_coordinator});
        this.getFieldData();
    }
    componentDidUpdate=(prevProps,prevState)=>{
        if(prevProps.name!=this.props.name){
           this.setState({name:this.props.name});
          
        }
        if(prevProps.source!=this.props.source){
            this.setState({drugOptions:this.props.source});
           
         }
    }
    getdrugOptions=()=>{
        let data=[];
        let user= this.context.userSystemId;
        (this.state.drugOptions||[]).forEach(p=>{
            if((p.id&&p.is_deleted)||!p.is_deleted){
                let {name,compound,count,strength,presentation,is_deleted,id,site_id}=p;
                let drugOptionData={
                    name,
                    compound_id:compound?.value,
                    count_id:count?.value,
                    strength_id:strength?.value,
                    presentation_id:presentation?.value,
                    is_deleted:is_deleted||false
                };
                if(p.id){
                    drugOptionData.updated_by=user;
                    drugOptionData.id=id;
                    drugOptionData.site_id=site_id;
                }
                else{
                    drugOptionData.created_by=user;
                    if(this.props.site_id){
                        drugOptionData.site_id=this.props.site_id;
                    }
                }
                data.push(drugOptionData);
            }
        })
        return data||[];
    }
    handleNewDrugOptions=()=>{
        let fields = this.inputFieldsArray;
        let data = {};
        fields.forEach((item) => {
            data[item.name] = this.state[item.name];
        })
        let { drugOptions } = this.state;
        drugOptions = [...drugOptions, data];
        this.setState({drugOptions},this.handleClearSelections);
    }
    handleClearSelections=()=>{
        let fields = this.inputFieldsArray;
        let data = {};
        fields.forEach((item) => {
            data[item.name]=null;
        });
        data["name"]=this.props.name||"";
        this.setState({...data,error:{}});

    }
    handleEvents=(option,data,changeField)=>{
        let {drugOptions}=this.state;
        
        switch(option){
            case 'ADD':     
            if(this.doValidate()){
                return false;
            }  
            this.handleNewDrugOptions();
            break;
            case "REMOVE":
             data.is_deleted=true;   
              this.setState({
                drugOptions:[...drugOptions]
            });
            break;
        }
    }
    onBeforeDelete=(callback)=>{
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: false,
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "Ok",
                    value: true,
                    visible: true,
                    className: "btn-danger",
                    closeModal: true
                }
            }
        })
            .then(async (replace) => {
                if (replace) {
                  if(callback){
                    callback();
                  }
                }
            });
    }
    
    doValidate=()=>{
       let {error}=this.state;
       let fields=this.inputFieldsArray;
       let hasAnyError=false;
       fields.forEach(item=>{
        if(!this.state[item.name]){
            error[item.name]="Required";
            hasAnyError=true;
        }
       });
       this.setState({error})
       return hasAnyError;
    }
    getFieldData=async()=>{
        let taksArray=[API.get(`seed/compound/list`),API.get(`seed/strength/data`),API.get(`seed/presentation/data`),API.get(`seed/count/data`)];
        const [compoundList, strengthList,presentationList,countList] = await Promise.all(taksArray);
        let {compoundData,strengthData,presentationData,countData}=this.state;
        compoundData=(compoundList.data||[]).map(elm => ({ name: elm.name, value: elm.id}));
        strengthData=(strengthList.data?.data||[]).map(elm => ({ name: elm.name, value: elm.id}));
        presentationData=(presentationList.data?.data||[]).map(elm => ({ name: elm.name, value: elm.id}));
        countData=(countList.data?.data||[]).map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({compoundData,strengthData,presentationData,countData},()=>{
          
        })
    }
    get inputFieldsArray() {
      
        return [
        {
            label: "Site *",
            type: "text",
            name: "name",
            disabled:true 
        },
        {
            label: "Compound *",
            type: "searchableDropdown",
            name: "compound"
        },
        {
            label: "Count *",
            type: "searchableDropdown",
            name: "count"
           
        },
        {
            label: "Strength *",
            type: "searchableDropdown",
            name: "strength"
            
        },
        {
            label: "Presentation *",
            type: "searchableDropdown",
            name: "presentation"
        }    
        ];
    }
    handleOnSelect=(e,name)=>{
        this.setState({
            [name]:e
        })
    }
    handleInputChange=(e)=>{       
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    renderFields=()=>{
        let {error,is_coordinator}=this.state;
        //disabled={!is_coordinator} 
        return <><Row className='pl-3'> {
            this.inputFieldsArray.map(field => {
                let optionConfig={};
                if(field.type==="searchableDropdown"){
                    optionConfig={
                        options:this.state[`${field.name}Data`]||[],
                        onSelect:this.handleOnSelect,
                        placeholder:'Select'
                    }
                }
                else{
                    optionConfig={
                        onChange:this.handleInputChange
                    };
                }
                return (
                    <Col   key={field.label} className='p-0'>
                        <InputField
                            {...field}
                            value={this.state[field.name]}
                            {...optionConfig}
                        />
                        {error[field.name] && <p style={{marginTop:'-1rem'}} className="text-danger ">{error[field.name]}</p>} 
                    </Col>
                )
            })
           }
            <Col>
                <div style={{display:'flex',height:'100%',alignItems:'flex-start'}} className='mt-3'>
                    <Button disabled={!is_coordinator} onClick={e=>this.handleEvents("ADD")} size="sm"
                        className="d-flex align-items-center mt-3"
                        variant="dark">
                        <span className="material-icons mr-1 m-0">add </span>
                        <span style={{ fontSize: ' 0.75rem' }}>ADD</span>
                    </Button>
                </div>

            </Col>
        </Row>
        </>
    }

   
    renderActionButton=(item,key)=>{
      
       let {is_coordinator}=this.state;
        return <div className={`d-flex align-items-center  w-10 h-100 justify-content-end`}>
                
                   {is_coordinator&&<ToolTipEx tooltip={DEFAULT.DELETE}><span onClick={e=>
                        {
                            if(item.id){
                                this.onBeforeDelete(()=>{
                                    this.handleEvents("REMOVE",item,"is_deleted")
                                });
                            }
                            else{
                                this.handleEvents("REMOVE",item,"is_deleted")
                            }
                           
                        }
                       } className="material-icons" style={{cursor:'pointer',}}>
                        close
                    </span></ToolTipEx>}
        </div>
    }
    renderTable=()=>{
        let {drugOptions}=this.state;
        return <div  style={{maxHeight:'400px',overflowY:'auto',fontSize:'.75rem'}}><Table responsive="sm">
            <thead style={{position:'sticky',top:-2,backgroundColor:'#FFF',zIndex:1,boxShadow:"rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                <tr>
                    <th className='bg-white'>Site</th>
                    <th className="text-left bg-white">Compound</th>
                    <th className="text-left bg-white">Count</th>
                    <th  className="text-left bg-white">Strength</th>
                    <th  className="text-left bg-white">Presentation</th>
                    <th  className="text-right bg-white">Remove</th>
                </tr>
            </thead>
            <tbody>
            {
                (drugOptions||[]).filter(p=>!p.is_deleted).map((item,index)=>{
                    return <tr>
                        <td>{item.name??""}</td>
                        <td className="text-left">{item.compound?.name??""}</td>
                        <td  className="text-left">{item.count?.name??""}</td>
                        <td  className="text-left">{item.strength?.name??""}</td>
                        <td  className="text-left">{item.presentation?.name??""}</td>
                        <td >{this.renderActionButton(item,index)}</td>
                    </tr>
                })
            }
            {
                 (drugOptions||[]).filter(p=>!p.is_deleted).length<=0&&<tr>
                    <td colSpan={6}>No records</td>
                 </tr>
            }
            </tbody>
            </Table>
            </div> ;
    }
    render(){
        let {formData}=this.state;
        return <div className='d-flex flex-column mt-2'>
            <h5 className="pl-1 font-weight-bold">Drug Options for Site</h5>
            {this.renderFields()}             
            {this.renderTable()}
        </div>
    }
}