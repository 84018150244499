import React, { Component } from 'react'

import { Button, Col, Row, Card } from 'react-bootstrap';

import Navbar from 'react-bootstrap/Navbar'

import { withRouter } from 'react-router';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import { dateTimeStringformat, dateTimeformat, getUserInfo } from '../../../utility/helperFunctions';
// import { yyyymmddFormat } from '../dateFormat';
// import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';

import InputField from '../../common/input-fields/InputField';
import StickyNav from '../../common/sticky-nav/StickyNav';
import { yyyymmddFormat } from '../dateFormat';
import BootstrapModal from '../modal/BootstrapModal';
import DelegeeModal from './DelegeeModal';


class DelegeeDetail extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access : {
            view : true,
            create : false,
            edit : false,
            delete : false,
        },
        successModal: false,
        errorModal: false,
        hasChanged: false,
        countryName:this.props.location.state.countryName,
        countryId:this.props.location.state.countryId,
        viewEditData:{},
        showHideModal: false,
        viewEditFlag: null,
        rolesList:[],
        submissionIdList:[],
        updateId:"",
        accept_privacy_statement_attested_date:null,
        no_confidential_info_attested_date:null,
        working_on_sponsor_behalf_attested_date:null,
        no_sensitive_personal_info_attested_date:null
      
    }
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        }

    ];


    get inputFieldsArray() {
        return [
            {
                type: "alert",
                label: "Information",
                variant: "secondary"
            },
            {
                label: "LLIR Delegee Name",
                type: "text", 
                name: "name",
                disabled: true
            },
            {
                label: "Owner",
                type: "text", 
                name: "owner_id",
                disabled : true,
            },
            {
                label: "[EXT] First Name",
                type: "text",
                placeholder: "Enter [EXT] First Name",
                name: "first_name",
                disabled : true,
            },
            {
                label: "Last Updated By",
                type: "text",
                name: "last_updated_by",
                disabled : true,
            },
            {
                label: "[EXT] Last Name",
                type: "text",
                placeholder: "Enter [EXT] Last Name",
                name: "last_name",
                disabled : true,
            },
            {
                label: "[EXT] Email",
                type: "text",
                placeholder: "Enter [EXT] Email",
                name: "email",
                disabled : true,
            },
            {
                label: "Accepted",
                type: "checkbox",
                disabled : true,
                name: "accepted",
            },
            {
                label: "Submission",
                type: "searchableDropdown",
                name: "submissionId",
                options: this.state.submissionIdList,
                onSelect: this.onSearchSelect1,
                disabled :true,
            },
            {
                label: "[EXT] Roles",
                type: "searchableDropdown",
                placeholder: "Select [EXT] Roles",
                name: "role",
                options: this.state.rolesList,
                onSelect: this.onSearchSelect,
                disabled : true,
            },
            {
                label: "[EXT] Other Role",
                type: "text",
                placeholder: "Enter [EXT] Other Role",
                name: "other_role",
                disabled : true,
            },
            {
                type: "alert",
                label: "Delegee Attestations",
                variant: "secondary",
                disabled : true,
            },
            {
                label: "Accept Privacy Statement",
                type: "checkbox",
                placeholder: "Enter Accept Privacy Statement",
                name: "accept_privacy_statement",
                disabled : true,
                
            },
           
            {
                label: " Accept Privacy Statement Attested Date",
                type: "datetime-local",
                
                name: "accept_privacy_statement_attested_date",
                disabled : true,
                
            },
            {
                label: "No confidential information",
                type: "checkbox",
                placeholder: "Enter No confidential information",
                name: "no_confidential_information",
                disabled : true,
                 
            },
           
            {
                label: " No confidential info. Attested Date",
                type: "datetime-local",
               
                name: "no_confidential_info_attested_date",
                disabled : true,
                
            },
            {
                label: "No sensitive personal information",
                type: "checkbox",
                placeholder: "Enter No sensitive personal information",
                name: "no_sensitive_personal_information",
                disabled : true,
            },
            
            {
                label: "No sensitive personal info Attested Date",
                type: "datetime-local",
                disabled : true,
                name: "no_sensitive_personal_info_attested_date",
                
            },
            {
                label: "Working on Sponsor Behalf",
                type: "checkbox",
                placeholder: "Enter Working on Sponsor Behalf",
                name: "working_on_sponsor_behalf",
                disabled : true,
                
            },
            {
                label: "Working on Sponsor Behalf Attested Date",
                type: "datetime-local",
                disabled : true,
                name: "working_on_sponsor_behalf_attested_date",
                
            },
            {
                label: "Created By",
                type: "text",
                placeholder: "Enter Created By",
                className:"createUpdateByfontsize",
                name: "created_by_id",
                disabled:true
            },
            {
                label: "Last Modified By",
                type: "text",
                className:"createUpdateByfontsize",
                name: "last_modified_by_id",
                disabled: true
            },
        ];
    }
    async componentDidMount() {
        await this.getDelegeeById(this.props.location.state.delegeeId)
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
            },
            reloadTable: true,

        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                },
                reloadTable: true,

            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getRoleslist = async () =>{
        const res = await API.get("seed/roles/fetch");
        const resultArray = (res.data?.data || []).map(elm => ({ name: elm.name, value: elm.role_id}));
        console.log("result",resultArray)
        this.setState({
            rolesList: resultArray,
             
        });     
    }
    getSubmissionIdlist = async () =>{
        const res = await API.get("submission/getsubmissionids/idlist");
        const resultArray = (res.data?.data || []).map(elm => ({ name: `Submission-${elm.id}`, value: elm.id}));
        console.log("result",resultArray)
        this.setState({
            submissionIdList: resultArray,
             
        });     
    } 
    getDelegeeById = async (id) => {
        await this.getRoleslist();
        await this.getSubmissionIdlist()
        try {

            const res = await API.get(`/delegee/${id}`);
            const viewEditData={...res.data.data};
            res.data = res.data.data;
            res.data["accept_privacy_statement_attested_date"] = res.data.accept_privacy_statement_attested_date != "" && res.data.accept_privacy_statement_attested_date != null ?
            dateTimeStringformat(res.data.accept_privacy_statement_attested_date) : null;
            res.data["no_confidential_info_attested_date"] = res.data.no_confidential_info_attested_date != "" && res.data.no_confidential_info_attested_date != null ?
            dateTimeStringformat(res.data.no_confidential_info_attested_date) : null;
            res.data["working_on_sponsor_behalf_attested_date"] = res.data.working_on_sponsor_behalf_attested_date != "" && res.data.working_on_sponsor_behalf_attested_date != null ?
            dateTimeStringformat(res.data.working_on_sponsor_behalf_attested_date) : null;
            res.data["no_sensitive_personal_info_attested_date"] = res.data.no_sensitive_personal_info_attested_date != "" && res.data.no_sensitive_personal_info_attested_date != null ?
            dateTimeStringformat(res.data.no_sensitive_personal_info_attested_date) : null;
            this.state.updateId=res.data.id;
            res.data["created_by_id"] =res.data.created_by_id?.length ? await getUserInfo(res.data.created_by_id)+"  "+ dateTimeformat(res.data.createdAt) : "";
            res.data["owner_id"] =res.data.owner_id?.length ? await getUserInfo(res.data.owner_id): "";
            res.data["last_modified_by_id"] =res.data.last_modified_by_id?.length > 0 ? await getUserInfo(res.data.last_modified_by_id) + "  " + dateTimeformat(res.data.updatedAt):res.data.created_by ;
            res.data["role"] = this.state.rolesList.filter(item => item.value+"" === res.data.role_id)[0];
            res.data["submissionId"] = this.state.submissionIdList.filter(item => item.value === res.data.submissionId)[0];

            this.setState({
                ...res.data,
               DelegeeName:res.data.name,
                viewEditData:viewEditData,
                showLoader: false
            })
         
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            role_id: e.value
        });
    }
    onSearchSelect1 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
           submissionId:e.value
            // stateName:e.name
        });
    }
    onInputChange = (e,eo) => {
        if (eo.type === "datetime-local") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else 
        { let { name, value } = e.target;
        this.setState({
            [name]: value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
        });
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.checked },

        })
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            patchObject: { ...this.state.patchObject, [e]: this.getHtmlFromEditorValue(val) },
            hasChanged: true
        });
    };
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={((e)=>this.onInputChange(e,field))}
                    />
                </Col>
            )
        });
    }
    handleDelegeeUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        try {
            const res = await API.post(`delegee/update`, state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getDelegeeById(this.props.location.state.delegeeId)

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    onNavOut = () => {
       window.history.back()
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
}


    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getDelegeeById(this.props.location.state.delegeeId)
    }

    render() {

        const { showHideModal, viewEditFlag, viewEditData, success_body, errorModal,successModal } = this.state;

        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                            {this.state.DelegeeName}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Delegee Detail </h4>
                   {this.state.access.edit && <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                                
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
               
            </Row>
           
            { showHideModal &&
                <DelegeeModal
             showHideModal={showHideModal}
             handleModalShowHide={this.handleModalShowHide}
             handleDelegeeSave={this.handleDelegeeSave}
             handleDelegeeUpdate={this.handleDelegeeUpdate}
             viewEditFlag={viewEditFlag}
             viewEditData={viewEditData}
            />}
             {this.state.successModal &&
             <BootstrapModal
                 show={successModal}
                 header="Success"
                 body={success_body}
                 changeShowState={this.changeShowState}
             />
         }
        {this.state.errorModal &&
             <BootstrapModal
                 show={errorModal}
                 variant="danger"
                 header="Ooops!!"
                 body="An error occured! Please try again later!"
                 changeShowState={this.changeShowState}
             />
         } 
        </>
    }
}

export default withRouter(DelegeeDetail);
