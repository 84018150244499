import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row, Dropdown } from 'react-bootstrap';
import jsonData from '../../mock/investigators.json';
import Alert from 'react-bootstrap/Alert';
import UserContext from '../../context/UserContext';
import API from '../../api/apis';
import DatatTableEx from '../common/DataTableEx';
import ToolTipEx from '../common/ToolTipEx';
import { INVESTIGATORS } from '../../TooltipInfo';
import { isCoordinator, showMessage } from './../ToReviewDetails/commonFunctions';
import LoaderEx from '../common/loader/LoaderEx';
import DTAButton from "../common/Button/DTAButton";
import InvestigatorModalEx from './InvestigatorModalEx';

class InvestigatorsEx extends Component {
    static contextType = UserContext;

    state = {
        investigatorList: [],
        loading: true,
        showHideModal: false,
        viewEditFlag: null,
    }
    columns = [{
        dataField: 'ID',
        text: 'Investigator Id',
        sort: true,
    },
    {
        dataField: 'investigator_first_name',
        text: 'Investigator First Name',
        sort: true,

    },
    {
        dataField: 'investigator_last_name',
        text: 'Investigator Last Name',
        sort: true,
    },
    {
        dataField: 'email',
        text: 'Investigator Email',
        sort: true,

    },
    ];

    async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.setState({
            is_Coordinator: _isCoordinator,
            loading: false
        }, async () => await this.getInvestigatorList());
    }
    SERVICES = {
        'GET-INVESTIGATORS-LIST': 'submission/investigator/list'
    }
    getInvestigatorList = async () => {
        this.setState({
            tableLoading: true,
        })
        try {
            const res = await API.get(`${this.SERVICES['GET-INVESTIGATORS-LIST']}`);
            if (res && res.data && res.data.success) {
                (res.data?.data || []).map(investigator => {
                    investigator["ID"] = "INVID-" + (investigator.id + "").padStart(7, '0')

                });
                this.setState({
                    investigatorList: res.data?.data || [],
                    tableLoading: false
                });

            } else {
                this.setState({
                    investigatorList: [],
                    tableLoading: false
                });
                showMessage({ text: "Failed to get data", icon: "error", button: "Ok" });
            }
        }
        catch (e) {
            console.log(e);
            showMessage({ text: "Failed to get data", icon: "error", button: "Ok" });
        }
    }
    rowEvents = {
        onClick: (e, row, rowIndex) => {
            this.handleModalShowHide(true, row.id)
        }
    };
    handleModalShowHide = async (flag, id, reload) => {
        if (id) {
            this.setState({ showHideModal: flag, investigatorId: id });
        } else {
            this.setState({ showHideModal: flag, investigatorId: null }, async () => {
                if (reload) {
                    await this.getInvestigatorList();
                }
            });
        }
    }
    renderPageIcons = () => {
        return this.state.is_Coordinator ?
            <ToolTipEx tooltip={INVESTIGATORS.ADD}>
                <DTAButton onClick={() => this.handleModalShowHide(true)} icon="ADD" text={INVESTIGATORS.ADD}></DTAButton>
            </ToolTipEx> : <></>
    }
    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, loading, success_body, errorModal, deleteModal, error_body, investigatorList, tableLoading, investigatorId } = this.state;
        return <>
            <div className="inpage-content">
                {loading && <LoaderEx />}
                {!loading && <Row>
                    <DatatTableEx
                        id="investigator"
                        title={
                            <Alert.Heading>
                                <span><i class="bi bi-people" style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                                Investigators
                            </Alert.Heading>
                        }
                        showLoader={tableLoading}
                        csvName='Investigators'
                        data={investigatorList}
                        columns={this.columns}
                        rowEvents={this.rowEvents}
                        filterChange={() => { }}
                        autoFilter={true}
                        pageIcons={this.renderPageIcons()}
                    />
                </Row>}
            </div>
            {showHideModal ?
                <InvestigatorModalEx
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    investigatorId={investigatorId}
                /> : <></>}
        </>
    }
}

export default withRouter(InvestigatorsEx);