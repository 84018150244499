import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
/** import $ from 'jquery';
import jqueryui from 'jquery-ui';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/selectable.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/selectable';
import 'jquery-ui/themes/base/resizable.css';
import 'jquery-ui/themes/base/draggable.css';
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/plugin';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/draggable'; **/
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import DatatTableEx from '../DataTableEx';


export default class DatatableComponent extends Component {

	/** componentDidMount(){
		$("th" ).resizable({
			minWidth: 28,
			handles:"e",
			resize: function (event, ui) {
				var sizerID = "#" + $(event.target).attr("id") + "-sizer";
				$(sizerID).width(ui.size.width);
			  }
		   });		  
	} **/
	customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			&nbsp; Showing {from} to {to} of {size} Results
		</span>
	);
	emptyDataMessage = () => { return 'No data available '; }
	selectRow = {

		clickToSelect: true,
		onSelect: (row, isSelect, rowIndex, e) => {
			console.log(row, isSelect, rowIndex)
		}
	};
	onSizePerPageChange = (sizePerPage, page) => {
		if (!!this.props.submissionSizePerPage) {
			localStorage.setItem("submissionSizePerPage", sizePerPage);
		}
	}
	options = {
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: 'First',
		prePageText: '<',
		nextPageText: '>',
		lastPageText: 'Last',
		nextPageTitle: 'First page',
		prePageTitle: 'Pre page',
		firstPageTitle: 'Next page',
		lastPageTitle: 'Last page',
		showTotal: true,
		hidePageListOnlyOnePage: true,
		alwaysShowAllBtns: true,
		paginationTotalRenderer: this.customTotal,
		disablePageTitle: true,
		sizePerPageList: [{
			text: '5', value: 5
		}, {
			text: '10', value: 10
		},
		{
			text: '25', value: 25
		},
		{
			text: '50', value: 50
		},
		{
			text: '100', value: 100
		},],
		sizePerPage: this.props.submissionSizePerPage || 5,
		onSizePerPageChange: this.onSizePerPageChange
	};

	render() {
		return <DatatTableEx  {...this.props}></DatatTableEx>
		const { ExportCSVButton } = CSVExport;
		const { csvName, data, columns, pagination, rowEvents, selectRow, handleClearAllFilter } = this.props;
		let searchedText = localStorage.getItem("filter_" + this.props.id) || ""
		const MySearch = (props) => {
			let input;
			const handleClick = () => {

				props.onSearch(input.value);

				if (this.props.id !== undefined) { localStorage.setItem("filter_" + this.props.id, input.value); }

				searchedText = input.value


			};
			return (
				<div className="margin-bottom">
					<input
						className="form-control"
						style={{ backgroundColor: 'white' }}
						ref={n => input = n}
						type="text"
						value={searchedText}
						onChange={handleClick}
						placeholder="Search"
					/>

				</div>
			);
		};
		return (

			<ToolkitProvider
				wrapperClasses="table-responsive"
				bootstrap4
				keyField={columns[0]?.dataField}
				data={data}
				columns={columns}
				search={{ defaultSearch: searchedText }}
				exportCSV={
					{
						fileName: `${csvName}.csv`,
						blobType: 'text/csv;charset=ansi',
						onlyExportFiltered: true,
						exportAll: false
					}
				}
			>
				{
					props => (
						<>
							<Col sm="4" className="ml-auto">
								<MySearch {...props.searchProps} />
							</Col>
							<Col sm={8} className="text-right">
								{handleClearAllFilter &&
									<button type="button" class="react-bs-table-csv-btn btn btn-default mr-2" onClick={() => handleClearAllFilter()}>
										<i class="bi bi-funnel-fill"></i> Clear All Filter </button>
								}
								{
									csvName ?
										<ExportCSVButton {...props.csvProps} ><i class="bi bi-cloud-arrow-down"></i> Export </ExportCSVButton>
										: null
								}
							</Col>
							<Col md={12}>
								{pagination ? !!selectRow ?
									(<BootstrapTable {...props.baseProps} wrapperClasses="table-responsive"
										rowEvents={rowEvents} pagination={paginationFactory(this.options)}
										bordered={false} noDataIndication={this.emptyDataMessage}
										filter={filterFactory()} selectRow={selectRow} />) :
									(<BootstrapTable {...props.baseProps} wrapperClasses="table-responsive"
										rowEvents={rowEvents} pagination={paginationFactory(this.options)}
										bordered={false} noDataIndication={this.emptyDataMessage}
										filter={filterFactory()} />) :
									(<BootstrapTable {...props.baseProps} wrapperClasses="table-responsive"
										rowEvents={rowEvents} bordered={false} filter={filterFactory()}
										noDataIndication={this.emptyDataMessage} />)
								}
							</Col>
						</>
					)
				}
			</ToolkitProvider>
		)
	}
}


