import React, { Component } from 'react'
import { Col, Row, Modal } from 'react-bootstrap';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import { dateTimeformat, dateTimeStringformat, getUserInfo } from '../../../utility/helperFunctions';
import InputField from '../../common/input-fields/InputField';
import LoaderEx from '../loader/LoaderEx';
import { isCoordinator, showMessage } from './../../ToReviewDetails/commonFunctions';
import Button from "../Button";
import SwitchView from '../SwitchView';
import { switchEnum } from '../SwitchView/switchEnum';
import SwitchHistory from '../SwitchHistory';
import { OBJ_TYPES } from '../SwitchHistory/historyConsts';

class DelegeeModalEx extends React.Component {
    static contextType = UserContext;
    state = {
        name: '',
        ownerid: '',
        first_name: '',
        last_updated_by: '',
        last_name: '',
        other_role: '',
        email: "",
        submission_id: "",
        active: false,
        rolesList: [],
        owner_id: "",
        accept_privacy_statement: false,
        no_confidential_information: false,
        no_sensitive_personal_information: false,
        working_on_sponsor_behalf: false,
        submissionIdList: [],
        submissionId: '',
        accept_privacy_statement_attested_date: null,
        no_confidential_info_attested_date: null,
        working_on_sponsor_behalf_attested_date: null,
        no_sensitive_personal_info_attested_date: null,
        loading: true,
        patchObject: {},
        role_id: '',
        accepted: false,
        viewDetails: true,
    }
    SERVICES = {
        "CREATE-DELEGEE": 'delegee/add',
        "GET-DELEGEE": 'delegee',
        "UPDATE-DELEGEE": 'delegee/update',
        'GET-ROLES-LIST': 'seed/roles/fetch',
        'GET-SUBMISSIONID-LIST': 'submission/getsubmissionids/idlist'
    }
    async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator });

        try {
            await Promise.all([this.getSubmissionIdlist(), this.getRoleslist()])
        } catch (e) {
            console.error(e);
        }
        // await this.getSubmissionIdlist();
        // await this.getRoleslist();
        const { delegeeId } = this.props;
        
        if (delegeeId) {
            let delegeeData = await this.getDelegeeById(delegeeId);

            if (delegeeData) {
                delegeeData["accept_privacy_statement_attested_date"] = (delegeeData.accept_privacy_statement_attested_date != "" && delegeeData.accept_privacy_statement_attested_date != null) ? dateTimeStringformat(delegeeData.accept_privacy_statement_attested_date) : null;

                delegeeData["no_confidential_info_attested_date"] = (delegeeData.no_confidential_info_attested_date != "" && delegeeData.no_confidential_info_attested_date != null) ? dateTimeStringformat(delegeeData.no_confidential_info_attested_date) : null;

                delegeeData["working_on_sponsor_behalf_attested_date"] = (delegeeData.working_on_sponsor_behalf_attested_date != "" && delegeeData.working_on_sponsor_behalf_attested_date != null) ? dateTimeStringformat(delegeeData.working_on_sponsor_behalf_attested_date) : null;

                delegeeData["no_sensitive_personal_info_attested_date"] = (delegeeData.no_sensitive_personal_info_attested_date != "" && delegeeData.no_sensitive_personal_info_attested_date != null) ? dateTimeStringformat(delegeeData.no_sensitive_personal_info_attested_date) : null;

                delegeeData["created_by"] = delegeeData.created_by_id?.length ?
                    delegeeData.created_name + "  " + dateTimeformat(delegeeData.createdAt) : "";
                delegeeData["ownerid"] = delegeeData.owner_id?.length ? delegeeData.owner_name : "";
                delegeeData["last_modified_by"] = delegeeData.last_modified_by_id?.length > 0 ? delegeeData.updated_name + "  " + dateTimeformat(delegeeData.updatedAt) : delegeeData.created_by;
                delegeeData["role"] = this.state.rolesList.filter(item => item.value === delegeeData.role_id)[0];
                delegeeData["submission_id"] = this.state.submissionIdList.filter(item => item.value === delegeeData.submissionId)[0];
                this.setState({
                    ...this.state,
                    ...delegeeData,
                    loading: false
                })
            } else {
                showMessage({ text: "Failed to get data", icon: "error", button: "Ok" },
                    () => this.props.handleModalShowHide(false));
            }
        }
        else {
            this.setState({
                loading: false,
                ownerid: await getUserInfo(this.context.userSystemId),
            })
        }
    }
    getDelegeeById = async (delegeeId) => {
        try {
            const res = await API.get(`${this.SERVICES['GET-DELEGEE']}/${delegeeId}`);
            if (res.data.success) {
                return res.data.data || null;
            } else {
                showMessage({ text: "Failed", icon: "error", button: "Ok" },
                    () => this.props.handleModalShowHide(false));
            }

        } catch (e) {
            console.error(e);
            showMessage({ text: "Failed", icon: "error", button: "Ok" },
                () => this.props.handleModalShowHide(false));
        }
        return null;
    }

    get inputFieldsArray() {
        return [{
            type: "alert",
            label: "Details",
        },
        {
            label: "LLIR Delegee Name",
            type: "text",
            name: "name",
            disabled: true
        },
        {
            label: "Owner",
            type: "text",
            name: "ownerid",
            disabled: true,
        },
        {
            label: "[EXT] First Name",
            type: "text",
            placeholder: "Enter [EXT] First Name",
            name: "first_name",

        },
        {
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
        },
        {
            label: "[EXT] Last Name",
            type: "text",
            placeholder: "Enter [EXT] Last Name",
            name: "last_name",

        },
        {
            label: "[EXT] Email *",
            type: "text",
            placeholder: "Enter [EXT] Email",
            name: "email",

        },
        {
            label: "Accepted",
            type: "checkbox",

            name: "accepted",
        },
        {
            label: "Submission *",
            type: "searchableDropdown",
            name: "submission_id",
            options: this.state.submissionIdList,
            onSelect: this.onSearchSelect1,
            disabled :this.props.submissionId,
        },
        {
            label: "[EXT] Roles",
            type: "searchableDropdown",
            placeholder: "Select [EXT] Roles",
            name: "role",
            options: this.state.rolesList,
            onSelect: this.onSearchSelect,

        },
        {
            label: "[EXT] Other Role",
            type: "text",
            placeholder: "Enter [EXT] Other Role",
            name: "other_role",

        },
        {
            type: "alert",
            label: "Delegee Attestations",

        },
        {
            label: "Accept Privacy Statement",
            type: "checkbox",
            placeholder: "Enter Accept Privacy Statement",
            name: "accept_privacy_statement",

        },

        {
            label: " Accept Privacy Statement Attested Date",
            type: "datetime-local",

            name: "accept_privacy_statement_attested_date",

        },
        {
            label: "No confidential information",
            type: "checkbox",
            placeholder: "Enter No confidential information",
            name: "no_confidential_information",

        },

        {
            label: " No confidential info. Attested Date",
            type: "datetime-local",

            name: "no_confidential_info_attested_date",

        },
        {
            label: "No sensitive personal information",
            type: "checkbox",
            placeholder: "Enter No sensitive personal information",
            name: "no_sensitive_personal_information",

        },

        {
            label: "No sensitive personal info Attested Date",
            type: "datetime-local",

            name: "no_sensitive_personal_info_attested_date",

        },
        {
            label: "Working on Sponsor Behalf",
            type: "checkbox",
            placeholder: "Enter Working on Sponsor Behalf",
            name: "working_on_sponsor_behalf",

        },
        {
            label: "Working on Sponsor Behalf Attested Date",
            type: "datetime-local",

            name: "working_on_sponsor_behalf_attested_date",

        },
        {
            label: "Created By",
            type: "text",
            // className: "createUpdateByfontsize",
            name: "created_by",
            disabled: true,
            edit: true
        },
        {
            label: "Last Modified By",
            type: "text",
            // className: "createUpdateByfontsize",
            name: "last_modified_by",
            disabled: true,
            edit: true
        },
        ];
    }
    setLoader = (status) => {
        this.setState({ loading: status });
    }
    handleSave = async (state) => {
        try {
            this.setLoader(true);

            if (await this.formValidation()) {
                let { delegeeId } = this.props;
                //trimming text fields
                const textFields = ['name',
                    'ownerid',
                    'first_name',
                    'last_updated_by',
                    'last_name',
                    'email',
                    'other_role'];

                const { patchObject } = state;
                
                let patchObjectKeys = Object.keys(patchObject);
                patchObjectKeys.forEach((key) => {
                    if (textFields.includes(key)) {
                        patchObject[key] = patchObject[key].trim();
                    }
                }
                )
                //
                let payload = {};

                if (delegeeId) {

                    let updateObj = {
                        ...patchObject,
                        id: delegeeId,
                        last_modified_by_id: this.context.userSystemId,
                        // created_by_id: this.context.userSystemId,
                    }
                    payload = { ...updateObj }

                } else {
                    // let stateObjKeys = Object.keys(state);
                    // stateObjKeys.forEach((key) => {
                    //     if (textFields.includes(key)) {
                    //         state[key] = state[key].trim();
                    //     }
                    // }
                    // )
                    let addObj = {
                        accepted: state.accepted,
                        ...patchObject,

                        // first_name: state.first_name?.trim(),
                        // last_updated_by: state.last_updated_by,
                        // last_name: state.last_name,
                        // other_role: state.other_role,
                        // email: state.email,
                        // role_id:state.role_id,
                        // submissionId: state.submissionId,

                        // accept_privacy_statement: state.accept_privacy_statement,
                        // no_confidential_information: state.no_confidential_information,
                        // no_sensitive_personal_information: state.no_sensitive_personal_information,
                        // working_on_sponsor_behalf: state.working_on_sponsor_behalf,
                        // accept_privacy_statement_attested_date: state.accept_privacy_statement_attested_date,
                        // no_confidential_info_attested_date: state.no_confidential_info_attested_date,
                        // working_on_sponsor_behalf_attested_date: state.working_on_sponsor_behalf_attested_date,
                        // no_sensitive_personal_info_attested_date: state.no_sensitive_personal_info_attested_date,

                        // ...state,
                        owner_id: this.context.userSystemId,
                        last_modified_by_id: null,
                        created_by_id: this.context.userSystemId,
                    };
                    payload = { ...addObj }
                }
                if(this.props.submissionId){
                    payload.submission_id=this.props.submissionId;
                    payload.submissionId=this.props.submissionId;
                }
                let url = delegeeId ? this.SERVICES['UPDATE-DELEGEE'] : this.SERVICES['CREATE-DELEGEE'];
                let delegeeResponse = await API.post(url, payload);
                if (delegeeResponse && delegeeResponse.data.success) {
                    showMessage({ text: "Saved Successfully", icon: "success", button: "Ok" }, () => {
                        this.props.handleModalShowHide(false, null, true);
                    });
                }
                else if (!delegeeResponse.data.success && delegeeResponse.data?.errors?.errors && delegeeResponse.data?.errors?.errors[0].validatorKey === 'isEmail') {
                    showMessage({ text: "Failed, Invalid Email", icon: "error", button: "Ok" });
                } else {
                    showMessage({ text: "Failed", icon: "error", button: "Ok" });
                }
            }
        }
        catch (exp) {
            console.error(exp);
        }
        finally {
            this.setLoader(false);
        }

    }
    getRoleslist = async () => {
        const res = await API.get(`${this.SERVICES['GET-ROLES-LIST']}`);
        if (res.data && res.data.success) {
            const resultArray = (res.data?.data || []).map(elm => ({ name: elm.name, value: elm.role_id }));
            this.setState({
                rolesList: resultArray,
            });
        } else {
            this.setState({
                rolesList: [],
            });
        }
    }
    getSubmissionIdlist = async () => {
        const res = await API.get(`${this.SERVICES['GET-SUBMISSIONID-LIST']}`);
        if (res.data && res.data.success) {
            const resultArray = (res.data.data || []).map(elm => ({ name: `Submission-${elm.id}`, value: elm.id }));
            let {submissionId}=this.props;
            let submissinInfo={};
            if(submissionId){
                let hasItem=resultArray.find(p=>p.value==this.props.submissionId);
                if(hasItem){
                    submissinInfo={
                        submission_id:hasItem
                    }
                } 
            }            

            this.setState({
                submissionIdList: resultArray,
                ...submissinInfo
            });
        } else {
            this.setState({
                submissionIdList: [],
            });
        }
    }
    onInputChange = (e, eo) => {
        if (eo.type === "datetime-local") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else {
            this.setState({
                hasChanged: true,
                patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
                [e.target.name]: e.target.value
            });
        }
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: e },

            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            role_id: e.value,
            patchObject: { ...this.state.patchObject, role_id: e.value },
        });
    }
    onSearchSelect1 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            submissionId: e.value,
            patchObject: { ...this.state.patchObject, submissionId: e.value },
        });
    }

    checkboxChange = (e) => {
        this.setState({
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.checked },
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = {
            email: this.state.email,
            submission_id: this.state.submission_id

        }
        const errors = {};

        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null || initialValues[key].toString().trim().length <= 0) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors }
            }, () => {
                try {
                    this.modalWindow._modal.dialog.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
                catch (exp) {
                    console.log(exp)
                }
            });
        });

        return is_valid;

    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && !this.props.delegeeId)
                return false;

            if (field.name !== "createdBy" || field.name !== "updatedBy") {
                if (!this.state.is_Coordinator) {
                    field.disabled = true;
                }
            }
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e) => this.onInputChange(e, field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, delegeeId } = this.props;
        const { is_Coordinator, loading, first_name, last_name, viewDetails } = this.state;
        return (
            <div>
                <Modal
                    ref={ref => this.modalWindow = ref}
                    show={showHideModal}
                    size="lg"
                    className="modal-mdx new-flow-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    {loading&&<LoaderEx />}
                    <Modal.Header className="d-flex border-0">
                        <Modal.Title className="w-100 d-flex justify-content-center flex-grow-1 text-left">
                            <h5 className='modal-title text-left w-100 font-weight-bold'>
                                {delegeeId ?
                                    `View and Edit Delegee ${first_name?.length ? first_name + ', ' : ''}${last_name?.length ? last_name : ''}` : "Add a New Delegee"}
                            </h5>
                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" onClick={() => handleModalShowHide(false)} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="position-relative">
                        {delegeeId && <SwitchView viewMode={(value) => {
                            if (value === switchEnum.DETAILS) {
                                this.setState({
                                    viewDetails: true
                                })
                            } else {
                                this.setState({
                                    viewDetails: false
                                })
                            }
                            // console.log("value>>:",value)
                        }}>

                        </SwitchView>}
                        {viewDetails ? <Row>
                                {this.renderInputFields()}
                            </Row>

                        : <SwitchHistory object_type={OBJ_TYPES.DELEGEE} object_id={this.props.delegeeId} />}

                    </Modal.Body>
                    {(!loading && viewDetails) ? <Modal.Footer className="d-flex border-0 justify-content-center">
                        <Button style="danger w-100px" onClick={() => handleModalShowHide(false)} className="d-flex align-items-center p-2 justify-content-center"><span>Cancel</span></Button>
                        {is_Coordinator ?
                            <Button style="success w-100px" onClick={() => this.handleSave(this.state)} className="d-flex align-items-center p-2 justify-content-center"><span>{delegeeId ? 'SAVE' : 'ADD'}</span></Button>
                            : <></>}
                    </Modal.Footer> : <></>}
                </Modal>
            </div >
        )
    }

}

export default DelegeeModalEx;