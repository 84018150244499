import API from '../../api/apis';

const sharepointAudit= async (payloadData, callbackMethod)=>{
    try {
        const res = await API.post('user/sharepoint/upload/delete/audit',payloadData);
        if(callbackMethod){
            callbackMethod(true);
        }
    } catch (e) {
        console.error("Error in Sharepoint Audit API: ", e);
    }
}

export { sharepointAudit };