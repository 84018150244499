import React from 'react'
import InputField from '../../common/input-fields/InputField';

export default class FileUploader extends React.Component{

    constructor(props){
        super(props);
        this.filePicker=React.createRef(null);
    }
    render(){
        let {onChange,fileInfo}=this.props;
        let fileProps=this.props.fileProps||{};
        return <div className="position-relative">
            <InputField
                type="text"
                readOnly={true}
                title={fileInfo?.selectedFileName}
                placeholder={this.props.placeholder||"Upload File"}
                style={this.props.style||{}}
                value={fileInfo?.selectedFileName}
            />
            <span className="position-absolute" style={{ fontSize: '2rem', right: '1rem', top: '-.5rem' }}>
                <i onClick={e => {
                    if (this.filePicker.current) {
                        this.filePicker.current.click();
                    }
                }} className="bi bi-cloud-arrow-up-fill"></i>
            </span>
            <input type="file" {...fileProps} onClick={e=>{e.target.value=null}} onChange={e => {
                if(onChange){onChange(e);}
            }} ref={this.filePicker} style={{ width: 0, position: 'absolute', marginBottom: "-100px" }}></input>
        </div>
    }
}