import React, { Component } from 'react'
import { Col, Row, Button, Nav } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import API from "../../../../api/apis";
import DatatableComponent from '../../../common/datatable/DatatableComponent';
import EnrollmentModal from '../EnrollmentModal';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import UserContext from '../../../../context/UserContext';
import swal from 'sweetalert';
import { enableWFActions } from '../../../../utility/helperFunctions';

class Enrollments extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        enrollmentsDetails: [],
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        max_actual: 0,
    }
    handleModalShowHide = (flag, viewEditFlag, viewEditData) => {
        this.setState({ showHideModal: flag, max_actual: this.props.submissionDetails.current_enrollment_actual ? this.props.submissionDetails.current_enrollment_actual : 0, viewEditFlag, viewEditData });
    }
    handleEnrollmentsDetails = (row) => {
        this.props.history.push(`/enrollment/details/${row.id}`, { enrollmentId: row.id, submissionId: this.props.submissionDetails.id });
    }
    columns = [{
        dataField: 'year_quarter',
        text: 'Year Quarter',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
             <Nav.Link as={NavLink} to={{pathname:`/enrollment/details/${row.id}`,state:{submissionId: this.props.submissionDetails.id, enrollmentId: row.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={(e) => this.handleEnrollmentsDetails(row)}>{row.year_quarter}</Nav.Link>
                
            </>
        }
    }, {
        dataField: 'baseline_enrollment',
        text: 'Baseline Enrollment',
        sort: true
    }, {
        dataField: 'estimated_enrollment',
        text: 'Estimated Enrollment',
        sort: true
    }, {
        dataField: 'actual_enrollment',
        text: 'Actual Enrollment',
        sort: true
    }, {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";


            return <>{enableWFActions()&&<>
                <i className="bi bi-trash-fill" onClick={() => this.handleEnrollDelete(row)}
                    style={{visibility: visibilityDelete, fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>
                <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row)}
                    style={{visibility: visibilityEdit, fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>
                    </>}
            </>
        }
    }];

    data = [];
    handleEnrollDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    const res = await API.post(`enroll/delete/${row.id}`,{updated_by : this.context.userSystemId});
                    this.setState({
                        success_body: "Deleted successfully!",
                        successModal: true,
                        hasChanged: false
                    });
                    this.getEnrollmentsDetailsBySubmissionId(this.props.submissionDetails.id)
                    this.props.getSubmissionDetail(this.props.submissionDetails.id);

                } catch (err) {
                    this.setState({ errorModal: true });
                }
              
            }
          });
    }
    componentDidMount() {
        this.getEnrollmentsDetailsBySubmissionId(this.props.submissionDetails.id)
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
        if(localStorage.getItem("saveNew")){
            this.setState({
                successModal: true,
                success_body: "Created successfully!",
            })
            this.handleModalShowHide(true, null, {})
            localStorage.removeItem('saveNew');
            
        }
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:  selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    getEnrollmentsDetailsBySubmissionId = async (submissionId) => {
        try {
            const mydata = await API.get(`/enroll/submission/${submissionId}`);
            if(Array.isArray(mydata.data)  && Object.keys(mydata.data).length>0){this.setState({
                enrollmentsDetails: mydata.data,
                showLoader: false
            })}
            else
            {this.setState({enrollmentsDetails: [],showLoader: false});}
        } catch (e) {
            console.log(e);
            // this.setState({
            //     error: true,
            //     showLoader: false
            // })
        }
    }
    handleEnrollmentsSave = async (state , saveNew) => {
        delete state.currencyList;
        delete state.showLoader;
        let res;
        state.created_by = this.context.userSystemId;
        state.submissionId = this.props.submissionDetails.id;
        try {
            res = await API.post("enroll/add", state);
            if (res.data.error) {
                res = res.data

            }
            else {
                this.setState({
                    success_body: "Created successfully!",
                    successModal: true,
                    hasChanged: false,
                    showHideModal: false,
                    viewEditFlag: null,
                    viewEditData: {},
                });
                this.getEnrollmentsDetailsBySubmissionId(this.props.submissionDetails.id)
                this.props.getSubmissionDetail(this.props.submissionDetails.id);
                if(saveNew){
                    localStorage.setItem("saveNew", true);
                }
            }
        } catch (err) {
            res = err;
            this.setState({ errorModal: true });
        }
        return res;
    }
    handleEnrollmentsUpdate = async (state) => {
        delete state.currencyList;
        delete state.showLoader;
        let res;
        delete state.created_by;
        state.updated_by = this.context.userSystemId;
        state.submissionId = this.props.submissionDetails.id;
        try {
             res = await API.post(`enroll/update/${state.id}`, state);
            if (res.data.error) {
                res = res.data

            }
            else {
                this.setState({
                    success_body: "Updated successfully!",
                    successModal: true,
                    hasChanged: false,
                    showHideModal: false,
                    viewEditFlag: null,
                    viewEditData: {},
                });
                this.getEnrollmentsDetailsBySubmissionId(this.props.submissionDetails.id)
                this.props.getSubmissionDetail(this.props.submissionDetails.id);

            }
        } catch (err) {
            res = err;

            this.setState({ errorModal: true });
        }
        return res
    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }



    render() {
        const { showHideModal, errorModal , successModal ,success_body, viewEditFlag, max_actual, viewEditData, enrollmentsDetails } = this.state;
        return <>
            <Row>
                <Col md={6}>
                    <h6 className="table-top"> Enrollments ({enrollmentsDetails.length})</h6>
                </Col>
                <Col md={2}>
                    {this.state.access.create&&enableWFActions() && <Link as={Button}>
                        <Button variant="outline-light" className="new-button" onClick={() => this.handleModalShowHide(true, null, {})}>New</Button>
                    </Link>}
                </Col>
                <DatatableComponent data={enrollmentsDetails} columns={this.columns} pagination="true" id="enrollment" />
            </Row>
            {
                showHideModal &&
                <EnrollmentModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleEnrollmentsSave={this.handleEnrollmentsSave}
                    handleEnrollmentsUpdate={this.handleEnrollmentsUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    submissionId={this.props.submissionDetails.id}
                    max_actual={max_actual}
                    currency_iso_code={this.props.submissionDetails.currency_id}

                />
            }
            {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
        </>
    }
}


export default withRouter(Enrollments);