import React from "react";
import { Row, Col,  Dropdown, Modal,Button as ButtonEx } from 'react-bootstrap';
import Steps from '../common/Steps/Steps.js';
import Charts from '../common/Charts/Charts.js';
import API from '../../api/apis';
import InputField from '../common/input-fields/InputField';
import UserContext from '../../context/UserContext';
import { dateTimeformat, getUserInfo } from "../../utility/helperFunctions.js";
import { isCoordinator } from "../ToReviewDetails/commonFunctions.js";
import Button from "../common/Button/index.js";
import LoaderEx from '../common/loader/LoaderEx';
// import Loader from "../common/loader/Loader.js";


export default class ManualEntryModal extends React.Component{
    static contextType = UserContext;
    
      constructor(props){
        super(props);
        this.state= {
            chartData:null,
            showLoader:false,
            currencyList: [],
            hasChanged: false,
            submissionId_str: "Submission-" + this.props.submissionId,
            year: new Date().getFullYear().toString(),
            quarter: "",
            submissionList: [],
            currency_iso_code: '',
            is_edit_toggle:(!this.props.id||this.props.id<=0)
          };
          
      }
    async componentDidMount() {
        this.getChartData();
        await this.getSubmissionDetails();
        await this.fetchEnrollmentOverview();
        this.getEnrollmentdetails();
    }
    getEnrollmentdetails=async()=>{
        let {id}=this.props;
        if(id&&id>0){
            try {
                const res = await API.get(`enrollmentOptimization/getbyid/${id}`);  

                let currency_id= res.data.currency_iso_code ; 
               
                let currency;
                if(this.state.currencyList)
                     currency= this.state.currencyList.filter(item => item.id ===currency_id)[0];
                                    
                this.setState({ ...res.data,currency_id,currency },this.updateCurrencyDepData);             
        
            }
            catch (error) {
                console.log("error", error);
            }
        }
    }
    updateCurrencyDepData=async()=>{
        if(this.state.currencyList&&this.state.currency){
            let res={};
            res.data=this.state;
            let fixJson={};
            try{
                fixJson["baseline_budget"] = res.data.baseline_budget !== null ? res.data["currency"].value + " " + res.data.baseline_budget.replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""
                fixJson["estimated_budget"] = res.data.estimated_budget !== null ? res.data["currency"].value + " " + res.data.estimated_budget.replace(/\d(?=(\d{3})+\.)/g, '$&,') : "";
                fixJson["actual_budget"] = res.data.actual_budget !== null ? res.data["currency"].value + " " + res.data.actual_budget.replace(/\d(?=(\d{3})+\.)/g, '$&,') : "";
                fixJson["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
                fixJson["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt) : res.data.createdby;
                fixJson["updated_by_system"] = res.data.updated_by_system?.length > 0 ?  dateTimeformat(res.data.updated_by_system) : "";
                fixJson["total_patients_entered_treatment_org"]=res.data.total_patients_entered_treatment??0;
                this.setState({ ...fixJson });   
            }
            catch(exp){

            }
          
        }
       

    }
    getSubmissionDetails=async()=>{
        let currency_id=null;
        try{
            let submissionDetails = await API.get(`submission/getbyid/${this.props.submissionId}`);
            if(submissionDetails&&submissionDetails.data&&submissionDetails.data.success){
                currency_id=submissionDetails.data.data.currency_id;
                
            }
          
        }
        catch(exp){

        }
        this.setState({currency_id},async()=>{
            await this.getCurrencyList();
        });
       
    }
    getCurrencyList = async () => {
        this.setState({
            showLoader: true
        })
        try {
            const currencyList = await API.get(`seed/currency/data`);
            let {currency_id}=this.state;
            
            //test Data test
            if(currencyList&&currencyList.data&&currencyList.data.success){ 
               let currency= currencyList.data.data.filter(item => item.id ===currency_id)[0];
                this.setState({
                    currencyList: currencyList.data.data,
                    showLoader: false,
                    currency
                },this.updateCurrencyDepData)
            }
           
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    getChartData = async () => {
        this.setState({showLoader:true});
        try{
            const res = await API.post(`/enrollmentOptimization/graph/details`,{
                "submission_id":this.props.submissionId
            });
            this.setState({ chartData: res.data , });
        }
        catch(exp){

        }
        finally{
            this.setState({showLoader:false});
        }
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.edit&&!this.state.id)
                return false;
            return (
                <Col sm={(field.type==="textarea"?8:4)} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            currency_iso_code: e.id,
            patchObject: { ...this.state.patchObject, currency_iso_code: e.id },

        });
    }
    onInputChange = (e) => {
        console.log(e)
        this.setState({
            hasChanged: true,
            patchObject: {
                ...this.state.patchObject, [e.target.name]: e.target.type === "number" ? parseInt(e.target.value) : e.target.value
            },

            [e.target.name]: e.target.type === "number" ? parseInt(e.target.value) : e.target.value
        });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },

        });
    }
    get inputFieldsArray() {
        return [
        {
            label: "Baseline Enrollment",
            type: "number",
            helptext: "Expected Enrollment for quarter based on initial projection",
            disabled:!this.state.is_edit_toggle,
            name: "baseline_enrollment",
        },
        {
            label: "Estimated Enrollment",
            type: "number",
            name: "estimated_enrollment",
            disabled:!this.state.is_edit_toggle,
            helptext: "Expected Enrollment for quarter based on current projections"

        },
        {
            label: "Actual Enrollment",
            type: "number",
            name: "actual_enrollment",
            disabled: true,
            helptext: "Enrollment for quarter"

        },
        {
            label: "* [EXT] Quarter",
            type: "dropdown",
            placeholder: "Select [EXT] Quarter",
            name: "quarter",
            options: ["Q1", "Q2", "Q3", "Q4"],
            disabled:!this.state.is_edit_toggle,
            helptext: "Select quarter for enrollment update"

        },
        {
            label: "* [EXT] Year",
            type: "dropdown",
            disabled:!this.state.is_edit_toggle,
            name: "year",
            options: [2040, 2039, 2038, 2037, 2036, 2035, 2034, 2033, 2032, 2031,
                2030, 2029, 2028, 2027, 2026, 2025, 2024, 2023, 2022, 2021
                , 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011
                , 2010].sort()

        },
        {
            label: "Baseline Budget",
            type: "text",
            name: "baseline_budget",
            disabled: true,
            edit: true,
            helptext: "Expected cost of patient enrollment based on initial projections"

        },
        {
            label: "Estimated Budget",
            type: "text",
            name: "estimated_budget",
            disabled: true,
            edit: true,
            helptext: "Expected cost of patient enrollment based on current projections"

        },
        {
            label: "Actual Budget",
            type: "text",
            name: "actual_budget",
            disabled: true,
            edit: true,
            helptext: "Cost of enrollment for the quarter"

        },
        {
            label: "Currency",
            type: "searchableDropdown",
            placeholder: "Select Currency",
            name: "currency",
            disabled:!this.state.is_edit_toggle,
            options: this.state.currencyList,
            onSelect: this.onSearchSelect
        },
        {
            label: "[EXT] Patients Currently on Treatment",
            type: "number",
            name: "patients_currently_on_treatment",
            disabled:!this.state.is_edit_toggle,
        },
        {
            label: "[EXT] Patients Currently on Followup",
            type: "number",
            name: "patients_currently_on_followup",
            disabled:!this.state.is_edit_toggle,
        },
        {
            label: "Submission",
            type: "text",
            placeholder: "Select Submission",
            name: "submissionId_str",
            disabled: true,
            disabled:!this.state.is_edit_toggle,
        },
        {
            label: "[EXT] Patients Completed Trial",
            type: "number",
            name: "patients_completed_trial",
            disabled:!this.state.is_edit_toggle,
        },  {
            label: "[EXT] Pts Disc. from Treatment",
            type: "number",
            disabled:!this.state.is_edit_toggle,
            name: "patients_discontinued_from_treatment",
        }, {
            label: "[EXT] Total Patients Entered Treatment",
            type: "number",
            disabled:!this.state.is_edit_toggle,
            name: "total_patients_entered_treatment",
            min: 0,
        },
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className: "createUpdateByfontsize",
            disabled: true,
            edit: true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className: "createUpdateByfontsize",
            disabled: true,
            edit: true
        },
        {
            label: "System Updated At",
            type: "text",
            name: "updated_by_system",
            className: "createUpdateByfontsize",
            disabled: true,
            edit: true
        },
        {
            label: "[EXT] Comments",
            type: "textarea",
            name: "comments",
            disabled:!this.state.is_edit_toggle,
        },
        ];
    }
    
    async fetchEnrollmentOverview(){
        try{
            let res = await API.get(`/enrollmentOptimization/enrollmentOverview/${this.props.submissionId}`);
            let actualEnrollment = res?.data?.current_enrollment_actual;
            this.setState({actualEnrollment: actualEnrollment});
        }
        catch(e){
            console.log('Error in fetching Enrollment detials: ',e);
        }
    }
    async handleSubmit(obj,saveNew) {
        let errors = {};
        let customerror = {};
        this.setState({showLoader: true, customerror});
        let total_patients_entered_treatment_org=this.state.total_patients_entered_treatment_org??0;
        
    
        let {total_patients_entered_next, total_patients_entered_prev} = this.state;

        if(this.props.id ){
            if((total_patients_entered_prev&&total_patients_entered_next) && total_patients_entered_prev  > total_patients_entered_next){
                customerror["total_patients_entered_treatment"] = "Invalid previous and Next Quarter's Total Patients Entered Treatment";
                this.setState({
                    showLoader: false,
                    error: { ...errors },
                    customerror: { ...customerror }
                });
                return false;
            }
            
            if (obj.total_patients_entered_treatment && total_patients_entered_next && (obj.total_patients_entered_treatment > total_patients_entered_next)){
                customerror["total_patients_entered_treatment"] = `'Total Patients Entered Treatment' should be lesser than or equal  to the next quarter's value (${total_patients_entered_next})`;
                this.setState({
                    showLoader: false,
                    error: { ...errors },
                    customerror: { ...customerror }
                });
                return false;
            }
            if (obj.total_patients_entered_treatment && total_patients_entered_prev && (obj.total_patients_entered_treatment < total_patients_entered_prev)){
                customerror["total_patients_entered_treatment"] = `'Total Patients Entered Treatment' should be greater than or equal  to the previous quarter's value (${total_patients_entered_prev})`;
                this.setState({
                    showLoader: false,
                    error: { ...errors },
                    customerror: { ...customerror }
                });
                return false;
            }
            
        }else{
            if ( (total_patients_entered_treatment_org !== obj.total_patients_entered_treatment) && (obj.total_patients_entered_treatment && (obj.total_patients_entered_treatment < this.state.actualEnrollment)) ) {

                customerror["total_patients_entered_treatment"] = "'Total Patients Entered Treatment' should be greater than the 'Current Enrollment (Actual)'";
                this.setState({
                    showLoader: false,
                    error: { ...errors },
                    customerror: { ...customerror }
                });
                return false;
                }
        }

        if (obj.year === "" || obj.year === null || obj.quarter === "" || obj.quarter === null) {
            errors["year"] = (obj.year === "" || obj.year === null) ? true : false;
            errors["quarter"] = (obj.quarter === "" || obj.quarter === null) ? true : false;;
            this.setState({
                showLoader: false,
                error: { ...errors },
            });
            return false;
        }
        else {
            let res=null;
            if(this.state.id){
                res = await this.handleEnrollmentsUpdate(this.state.patchObject);
            }
            else
             res = await this.handleEnrollmentsSave(obj,saveNew);
            if (res.error && res.error.includes("Unique")) {
                    customerror["year"] = "Duplicate value found: Quarter - Year should be unique";
                    customerror["quarter"] = "Duplicate value found: Quarter - Year should be unique";
                    if (res.error.includes("Recycle Bin")) {
                        customerror["year"] = "Duplicate value found in Recycle Bin: Quarter - Year should be unique";
                        customerror["quarter"] = "Duplicate value found in Recycle Bin: Quarter - Year should be unique";
                    }
                    this.setState({
                        showLoader: false,
                        customerror: { ...customerror }
                    });
                }

        }
    }
    handleEnrollmentsUpdate = async (state) => {
        let res={};
       if(state){
        state.updated_by = this.context.userSystemId;
        state.submissionId = this.state.submissionId;
        state.id=this.state.id;
        try {
             res = await API.post(`enroll/update/${state.id}`, state);
            if (res.data.error) {
                res = res.data
                // this.onTrigger('errorModal');
            }
            else {               
                this.onTrigger('successModal');
            }
        } catch (err) {
            res = err;
               this.onTrigger('errorModal');
        }
       }
       else{
        this.onTrigger('successModal');
       }
       
        return res
    }
    handleEnrollmentsSave = async (state,saveNew ) => {
        delete state.currencyList;
        delete state.showLoader;
        delete state.chartData;
        let res={};
        state.created_by = this.context.userSystemId;
        state.submissionId = this.props.submissionId;
        state['currency_iso_code']=state.currency.id;
        
        try {
            res = await API.post("/enrollmentOptimization/add", state);

            if (res.data.error) {
                res = res.data
            }
            else {
                this.setState({
                    hasChanged: false,
                });

                if(res && res?.data.id){
                    this.onTrigger('successModal',saveNew);
                    

                }
                else{
                    this.onTrigger('errorModal');
                }

            }
        } catch (err) {
            res = err;
            this.onTrigger('errorModal');
        }
        return res;
    }
    onTrigger = (callbackString,saveNew) => {
        this.props.parentCallback(callbackString,saveNew);
    }
    renderModalComponent=()=>{
        const { handleManualEntryModal, showManualEntryModal, } = this.props;
        let chartData = this.state.chartData;
        let {showLoader} = this.state;
        let loaderConfig={};
        if(showLoader){
             loaderConfig={disabled:"disabled"};
        }
        return (
            <>
            <Modal show={showManualEntryModal}
            size="xl"
            dialogClassName="lg-xl"
            className="modal-mdx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Header style={{border:0 }}  >
                       <Modal.Title className="w-100 d-flex justify-content-center">
                            <h3  class='modal-title text-left w-100'>
                                {(this.props.id&&this.props.id>0)?'Quarter Enrollment Details':''}</h3>
                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" onClick={e => {
                                handleManualEntryModal(false);
                            }} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                </Modal.Header>
                
                <Modal.Body   className={['new-flow-modal modal-body text-left w-100',this.state.is_edit_toggle?"":"disabled_view"].join(" ")} >
                    {showLoader && <LoaderEx />}
                    
                    {(!this.props.id||this.props.id<=0)&&<div style={{border:0, paddingLeft:'19.5rem', fontSize:'0.7rem' }} class='d-flex justify-content-center text-center '>
                        <Steps source={[{text:"Add option"},{text:"Quarter  entry",active:true}]}></Steps>
                    </div>}
                
                    {(!this.props.id||this.props.id<=0)&&<h4   class='text-center w-100 mt-5'>Please Enter Enrollment for a Quarter</h4>}

                    {chartData?<div className="position-relative" >
                  
                    <h4   class='text-center w-100 position-absolute' style={{top:'0.50rem',zIndex:'100'}}>
                    Enrollment Over Active Trial Lifetime</h4>
                        <Charts  source={this.state.chartData} /><br/></div>:null}
                    {(this.props.id&&this.props.id>0)&&isCoordinator(this.context)?<Row>
                        <Col sm={12} className="d-flex align-items-center justify-content-end pt-3 pb-3">
                                <ButtonEx size="sm" className="d-flex align-items-center text-right mr-1"
                                    variant="outline-dark"
                                    onClick={() => {
                                        let {is_edit_toggle}=this.state;
                                      
                                        if(is_edit_toggle){
                                            this.handleSubmit(this.state)
                                        }
                                        else{
                                            this.setState({is_edit_toggle:!is_edit_toggle});
                                        }
                                       
                                    }}>
                                        <span className="material-icons mr-1 m-0">{this.state.is_edit_toggle?'save':'edit'}</span>
                                        <span style={{ fontSize: ' 0.75rem', textTransform: 'uppercase' }}>{this.state.is_edit_toggle?'Save':'Edit'} Quarter Details</span>
                                    </ButtonEx>
                        </Col>
                    </Row>:null}
                        <Row>
                            {this.renderInputFields()}
                        </Row>
                </Modal.Body>
                <Modal.Footer className="border-0" >
                {(!this.props.id||this.props.id<=0)&&isCoordinator(this.context)&&<div className="d-flex align-items-center justify-content-center w-100 p-2 ">                    
                        <Button {...loaderConfig} style="accent h-md"  className='d-flex align-items-center   mr-3 pt-2 pb-2 pr-3 pl-3' onClick={()=>{this.onTrigger('newEnrollmentModal')}}>
                            <span >BACK</span>
                            </Button>                        
                        <Button {...loaderConfig} style="success h-md" className='d-flex  align-items-center mr-3 pt-2 pb-2 pr-3 pl-3' onClick={() => this.setState({showLoader: true, },()=>this.handleSubmit(this.state,false) )} > 
                            <span class="material-icons mr-1 p-0"  >send</span>
                        <span >SUBMIT</span>
                        </Button>
                        <Button {...loaderConfig} style="success h-md" className='d-flex  align-items-center pt-2 pb-2 pr-3 pl-3' onClick={() => this.setState({showLoader: true, },()=>this.handleSubmit(this.state,true) )} > 
                            <span class="material-icons mr-1 p-0"  >send</span>
                        <span >SUBMIT & NEW</span>
                        </Button>
                    </div>}
                </Modal.Footer>
              </Modal>
            </>
          );
    }
    
    render(){
        
        return <div className="mt-3">
            <div>
                {this.renderModalComponent()}
            </div>
        </div>
    }
}