import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap';
import { dateTimeformat } from '../../../utility/helperFunctions';
import DatatableComponent from '../../common/datatable/DatatableComponent';

export default class HistoryFile extends Component {

    columns = [{
        dataField: 'created_at',
        text: 'Date',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.updatedAt?.length > 0 ? dateTimeformat(row.updatedAt) : ""}</p>

            </>
        }
    }, {
        dataField: 'Field',
        text: 'Field',
        sort: true
    },{
        dataField: 'updater.first_name',
        text: 'User',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.updater?.first_name !=null && row.updater?.last_name!=null ?row.updater.first_name + " " + row.updater.last_name : row.creater.first_name + " " + row.creater.last_name}</p>

            </>
        }


    },
    {
        dataField: 'old_value',
        text: 'Original Value',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.old_value !=null ? (row.old_value):""}</p>

            </>
        }
    }, {
        dataField: 'new_value',
        text: 'New Value',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.new_value !=null ? (row.new_value):""}</p>

            </>
        }
    }];

    


    render() {
         const {historyTitle, historyData,csvName}= this.props;
        return (
            <Row>
                {historyTitle&&<Col md={7}>
                    <h6 class="table-top">
                    {historyTitle} ({historyData.length})</h6>
                </Col>}
                <DatatableComponent csvName={historyTitle||csvName||"History"} id="historyFiles" data={historyData} columns={this.columns}  pagination="true" />
            </Row>
        )
    }
}
