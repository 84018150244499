export const SUBMISSION_HELP = {
    "iIRSharepointSite": "iir_sharepoint_site" ,
    "extExternalUserGuide": "external_user_guide",
    "internalUserGuide": "internal_user_guide"
}

export const ATTESTATION = {
        "extNoConfidentialInformation":  "no_confidential_information",
        "no_confidential_info_attested_date":  "no_confidential_info_attested_date",
        "extNoSensitivePersonalInformation":  "no_sensitive_personal_information",
        "no_sensitive_pi_attested_date":  "no_sensitive_pi_attested_date",
        "extIntellectualPropertyRights":  "intellectual_property_rights",
        "intellectual_property_attested_date":  "intellectual_property_attested_date",
        "extAcceptPrivacyStatement":  "accept_privacy_statement",
        "accept_privacy_statement_attested_date":  "accept_privacy_statement_attested_date",
        "extelegeesawareofconfidentiality":  "delegees_aware_of_confidentiality",
        "delegees_aware_of_conf_attested_date":  "delegees_aware_of_conf_attested_date",
        "extDelegeesknowrequirements":  "delegees_instructed_of_requirements",
        "delegees_instructed_of_req_attested_date":  "delegees_instructed_of_req_attested_date",
        "extIattesttodevelopingthisstudy":  "attest_to_develop_study",
        "attest_to_develop_study_date":  "attest_to_develop_study_date",
        "extIwillproperlyandsafelydestroy":  "properly_safely_destroy",
        "properly_safely_destroy_date":  "properly_safely_destroy_date",
        "extNoPatentApplication":  "no_patent_application",
        "no_patent_application_date":  "no_patent_application_date",
        "extInformLillyofPatent":  "inform_lilly_of_patent",
        "inform_lilly_of_patent_date":  "inform_lilly_of_patent_date",
        "extGiveLillyLicense":  "give_lilly_license",
        "give_lilly_license_date":  "give_lilly_license_date",
}


export const COORDINATOR_CONFIRMATIONS = {
    "verifyCommunicationofPublication": "verify_comunication_of_publication",
    "isTaskComplete": "debarment_disqual_task",
    "disclaimer": "disclaiemer",
    "externalResearchGuidelines": "external_research_guideline",
    "lillyExpectationsOfHCPSent": "lilly_expectation_hcp_sent",
    "compoundSafetyLanguageSent": "compound_safety_language_sent"
}

export const PRIMARY_INVESTIGATOR = {
    "extFirstName": "investigator_first_name",
    "extInvestigatorStreetAddress": "investigator_street_address",
    "extMiddleName": "investigator_middle_name",
    "extInvestigatorCity": "investigator_city",
    "extLastName": "investigator_last_name",
    "extInvestigatorCountry": "investigator_country",
    "extInvestigatorPrimaryInstitution": "investigator_primary_institution",
    "extInvestigatorState": "investigator_state",
    "extInvestigatorSecondaryInstitution": "secondary_institution_name",
    "extInvestigatorAreaCode": "investigator_zipcode",
    "extInvestigatorEmail": "email",
    "extInvestigatorPhoneNumber": "phone_number",
    "extEmailAddressforSafetyMailings": "investigator_email_for_safety",
    "extInvestigatorAltPhone": "investigator_alt_phoneno",
    "extInvestigatorSpeciality": "investigator_speciality",
    "extInvestigatorFaxNumber": "investigator_fax_no",
    "extIsPhysician": "are_you_physician",
    "extMedicalLicenseNumber": "medical_no",
    "extIsanotherOrganizationinvolved": "another_org_involved" ,
    "impactInvestigatorId":"impactInvestigatorId",
    "customer_id":"customer_id"
}

export const INITIAL_SUBMISSION = {
    "investigator": "investigatorname",
    "stage": "stage",
    "submissionStatus": "status",
    "extCountry": "country",
    // "extUniqueSubmissionIdentifier": "unique_submission_identifier",
    "shorthandTitle": "shorthand_title",
    "country1":"country_1",
    "country2": "country_2",
    "extTrialCategory": "trial_category",
    "country3": "country_3",
    // "SOSLHelpField": "sosl_help_field",
    "country4": "country_4",
    "requestType": "request_type",
    "secondary_request_type":"secondary_request_type",
    "extTherapeuticArea": "therapeutic_area",
    "extCompound": "compound_id",
    "legacyTherapeuticArea": "legacy_therapeutic_area",
    "compound1": "compound_1",
    "extTumorIndication": "indication",
    "compound2": "compound_2",
    "addntlCompounds": "addntl_compounds",
    "extOtherIndication": "other_indication",
    "investigatorID": "investigator_id",
    "extIndication": "concept_indication",
    "protocolNumber": "protocol_no",
    "extClinicalTrialPhase": "clinical_trail_phase",
    "lillyMedicalMonitor": "lilly_medical_monitor",
    "lastModifiedBy": "last_modified_by",
    "studyPriority": "study_priority",
    "interventional_study":"interventional_study",
    "impactNumber": "impact_no",
    "trialAlias": "trial_alias",
    "localRegulatoryApprovalNumber": "local_regulatory_no",
    "createdBy": "created_by",
    "agreementNumber": "agreement_no",
    "extPrimaryMSLEmail": "primary_msl_email",
    "extLineofTherapy": "line_of_therapy",
    "additionalMSLEmail": "additional_msl_email",
    "studyTitle": "study_title",
    "is_feasibility_assessment_sent":"is_feasibility_assessment_sent",
    "doNotSendAutomatedEmails": "doNotSendAutomatedEmails",
    "assignedTo": "assign_to",
    "additionalCoordinator": "additional_cordinator",
    "extPrimaryMSL": "primary_msl",
    "additionalMSL": "additional_msl",
    "notes":"notes",
"interventionalStudy":"interventionalStudy",
"is_sub_study":"is_sub_study",
"parent_study_submission_number": "parent_study_submission_number",
"requesting_research_funding": "requesting_research_funding",
"nctNumber": "nct_study",
    "type_of_material_provided": "type_of_material_provided",
    "lilly_support": "lilly_support"
}


export const ORGANIZATION = {
    "extOrganizationMedicalContactName": "org_medical_name",
    "orgaddressasPI": "org_address_same",
    "extOrganizationName": "org_name",
    "extTypeofOrganization": "org_type",
    "extOrganizationRole": "org_role",
    "extOrganizationStreetAddress": "org_address",
    "extOtherOrganizationRole": "org_role_other",
    "extOrganizationCity": "org_city",
    "extOrganizationPhoneNumber": "org_contact_no",
    "extOrganizationCountry": "org_country",
    "extOrgAlternatePhone": "org_alt_contact_no",
    "extOrganizationState": "org_state",
    "extOrganizationEmail": "org_email",
    "extOrganizationZipPostalCode": "org_zipcode",
    "organizationistheregulatorysponsor": "regulatory_sponsor",
    "organizationFaxNumber": "org_fax_no"
}
export const PROTOCOL_UPDATE ={
    "protocolSubmitted":"protocolSubmitted",
"submissionId": "submissionId",
"createdByID": "createdByID",
    "lastModifiedBy": "lastModifiedBy" ,
    "submissionId": "submissionId" ,
    "investigatorName": "investigatorName" ,
    "last_updated_by": "last_updated_by",
"protocolTitle": "protocolTitle" ,
"protocolNotes":"protocolNotes",
"iACUCDocumentationAttached": "iACUCDocumentationAttached",
"descriptionOfAnimalUsed": "descriptionOfAnimalUsed",
"dosesConcentrations": "dosesConcentrations",
"aCAndUCommitteeApproval": "aCAndUCommitteeApproval",
"endpoints": "endpoints",
"modelMethodology": "modelMethodology",
"targetStartDate": "targetStartDate",
"totalStudyLength":"totalStudyLength",
"enrollment":"enrollment",
"statisticalAnalysis": "statisticalAnalysis",
"reportSubmissionDate": "reportSubmissionDate",
"humanSamplesInvolved": "humanSamplesInvolved",
"publicationDateAnticipated": "publicationDateAnticipated",
"typeOfHumanSamples": "typeOfHumanSamples",
"projectedMilestoneTiming": "projectedMilestoneTiming",
"uniquePersonalClinicalSamples": "uniquePersonalClinicalSamples",
"biobankOwner": "biobankOwner",
"sampleSize": "sampleSize",
"dateOfFirstPatientVisit": "dateOfFirstPatientVisit",
"numberOfTherapyGroups": "numberOfTherapyGroups",
"dateFirstPtEntersTreatment": "dateFirstPtEntersTreatment",
"anticipatedQuarterlyEnrollment": "anticipatedQuarterlyEnrollment",
"dateLastPatientEntersTrial":"dateLastPatientEntersTrial",
"numberOfinvestigativeSite":"numberOfinvestigativeSite",
"dateLastPatientEntersTreatment":"dateLastPatientEntersTreatment",
"potentialCountries": "potentialCountries",
"dateOfLastPatientVisit":"dateOfLastPatientVisit",
"multiCountryTrial":"multiCountryTrial",
"dateOfReportSubmission":"dateOfReportSubmission",
"clinicalTrialPhase": "clinicalTrialPhase",
"includePreClinicalPortion": "includePreClinicalPortion",
"dateOfPublicationSubmission":"dateOfPublicationSubmission",
"assignmentToTreatment":"assignmentToTreatment",
"leadInPeriod": "leadInPeriod",
"design": "design",
"blinding": "blinding",
"controls": "controls",
"blindingDoneBy": "blindingDoneBy",
"extension": "extension",
"patientType": "patientType",
"extensionLength": "extensionLength",
"patientGender": "patientGender",
"ageRangeStart": "ageRangeStart",
"patientTreatmentPeriod": "patientTreatmentPeriod",
"ageRangeEnd": "ageRangeEnd",
// "studyOnOffLabel": "studyOnOffLabel" ,
// "LineOfTherapy": "LineOfTherapy",
"lillyToSupplyStudyCompound": "lillyToSupplyStudyCompound",
"ifNoWhy": "ifNoWhy",
"lillyStudyCompound1": "lillyStudyCompound1",
"lillyStudyCompound1Other": "lillyStudyCompound1Other",
"compound1AvailableMaterial": "compound1AvailableMaterial",
"compound1AvailableMaterialOther": "compound1AvailableMaterialOther",
"compound1Formulation": "compound1Formulation",
"compound1DosingSchedule": "compound1DosingSchedule",
"compound1DosingSchedulOther": "compound1DosingSchedulOther",
"compound1AdministeredDose": "compound1AdministeredDose",
// "compound1AmountQuantity": "compound1AmountQuantity",
"requestedAmtOfComp1PreClin": "requestedAmtOfComp1PreClin",
"requireSecondLillyCompound": "requireSecondLillyCompound",
"requestedAmtOfComp5PreClin": "requestedAmtOfComp5PreClin",
"lillyStudyCompound2": "lillyStudyCompound2",
"requireSixthLillyCompound": "requireSixthLillyCompound",
"lillyStudyCompound2Other": "lillyStudyCompound2Other",
"compound2AvailableMaterial": "compound2AvailableMaterial",
"compound2AvailableMaterialOther": "compound2AvailableMaterialOther",
"compound2Formulation": "compound2Formulation",
"compound2DosingSchedule": "compound2DosingSchedule",
"compound2DosingScheduleOther": "compound2DosingScheduleOther",
"compound2AdministeredDose": "compound2AdministeredDose",
// "compound2AmountQuantity": "compound2AmountQuantity",
"requestedAmtOfComp2PreClin": "requestedAmtOfComp2PreClin",
"requireThirdLillyCompound": "requireThirdLillyCompound",
"requestedAmtOfComp6PreClin": "requestedAmtOfComp6PreClin",
"lillyStudyCompound3": "lillyStudyCompound3",
"requireSeventhLillyCompound": "requireSeventhLillyCompound",
"lillyStudyCompound3Other": "lillyStudyCompound3Other",
"compound3AvailableMaterial": "compound3AvailableMaterial",
"compound3AvailableMaterialOther": "compound3AvailableMaterialOther",
"compound3Formulation": "compound3Formulation",
"compound3DosingSchedule": "compound3DosingSchedule",
"compound3DosingScheduleOther": "compound3DosingScheduleOther",
"compound3AdministeredDose": "compound3AdministeredDose",
// "compound3AmountQuantity": "compound3AmountQuantity",
"requestedAmtOfComp3PreClin": "requestedAmtOfComp3PreClin",
"requireFourthLillyCompound": "requireFourthLillyCompound",
"lillyStudyCompound4": "lillyStudyCompound4",
"lillyStudyCompound4Other": "lillyStudyCompound4Other",
"compound4AvailableMaterial": "compound4AvailableMaterial",
"compound4AvailableMaterialOther": "compound4AvailableMaterialOther",
"compound4Formulation": "compound4Formulation",
"compound4DosingSchedule": "compound4DosingSchedule",
"compound4DosingScheduleOther": "compound4DosingScheduleOther",
"compound4AdministeredDose": "compound4AdministeredDose",
// "compound4AmountQuantity": "compound4AmountQuantity",
"requestedAmtOfComp4PreClin": "requestedAmtOfComp4PreClin",
"requestingFunding": "requestingFunding",
"nonLillySupport": "nonLillySupport",
"amountOfFunds": "amountOfFunds",
"nameOfCompany": "nameOfCompany",
"currency": "currency",
"typeOfSupport": "typeOfSupport",
"hcpProtocolTitle":"hcpProtocolTitle",
"feedback":"feedback",
"protocolComments":"protocolComments",
"budgetOfficeContactName": "budgetOfficeContactName",
"contractContactName": "contractContactName",
"budgetOfficeStreetAddress": "budgetOfficeStreetAddress",
"contractContactStreetAddress": "contractContactStreetAddress",
"budgetOfficeCity": "budgetOfficeCity",
"contractContactCity": "contractContactCity",
"budgetOfficeEmail":"budgetOfficeEmail",
"contractContactEmail": "contractContactEmail",
"budgetOfficePhoneNumber": "budgetOfficePhoneNumber",
"contractContactPhoneNumber": "contractContactPhoneNumber",
"pharmacyContactName": "pharmacyContactName",
"pharmacyContactCity": "pharmacyContactCity",
"pharmacyContactEmail": "pharmacyContactEmail",
"pharmacyContactPhoneNumber": "pharmacyContactPhoneNumber",
"pharmacyContactStreetAddress": "pharmacyContactStreetAddress",
}
export const DELEGEE_UPDATE ={
    "owner_id":"owner_id",
    "first_name":"first_name",
    "last_updated_by":"last_updated_by",
    "last_name":"last_name",
    "email":"email",
    "accepted":"accepted",
    "last_modified_by_id":"last_modified_by_id",
    "submissionId":"submissionId",
    "role_id":"role_id",
    "other_role":"other_role",
    "accept_privacy_statement":"accept_privacy_statement",
    "accept_privacy_statement_attested_date":"accept_privacy_statement_attested_date",
    "no_confidential_information":"no_confidential_information",
    "no_confidential_info_attested_date":"no_confidential_info_attested_date",
    "no_sensitive_personal_information":"no_sensitive_personal_information",
    "no_sensitive_personal_info_attested_date":"no_sensitive_personal_info_attested_date",
    "working_on_sponsor_behalf":"working_on_sponsor_behalf",
    "working_on_sponsor_behalf_attested_date":"working_on_sponsor_behalf_attested_date",
}
export const PROPOSAL_UPDATE = {
    "proposalSubmitted": "proposalSubmitted" ,
    "createdBy": "createdBy" ,
    "lastModifiedBy": "lastModifiedBy" ,
    "submissionId": "submissionId" ,
    "investigatorName": "investigatorName" ,
    "last_updated_by": "last_updated_by",
    "proposalTitle": "proposalTitle" ,
    "shippingImportLicenseRequired": "shippingImportLicenseRequired" ,
    "iNDApplication": "iNDApplication" ,
    "uSDepartmentOfVetAffairs": "uSDepartmentOfVetAffairs" ,
    "scientificRationaleForStudy": "scientificRationaleForStudy",
    "studyType": "studyType" ,
    "primaryAndSecondaryObjectives": "primaryAndSecondaryObjectives",
    "ifOtherStudyType": "ifOtherStudyType" ,
    "lengthOfWashout": "lengthOfWashout" ,
    "studyToUseAnimals": "studyToUseAnimals" ,
    "iACUCDocumentationAttached": "iACUCDocumentationAttached" ,
    "descriptionOfAnimalUsed": "descriptionOfAnimalUsed" ,
    "dosesConcentrations": "dosesConcentrations" ,
    "aCAndUCommitteeApproval": "aCAndUCommitteeApproval" ,
    "endpoints": "endpoints" ,
    "modelMethodology": "modelMethodology" ,
    "targetStartDate": "targetStartDate" ,
    "statisticalAnalysis": "statisticalAnalysis" ,
    "reportSubmissionDate": "reportSubmissionDate" ,
    "humanSamplesInvolved": "humanSamplesInvolved" ,
    "publicationDateAnticipated": "publicationDateAnticipated" ,
    "typeOfHumanSamples": "typeOfHumanSamples" ,
    "projectedMilestoneTiming": "projectedMilestoneTiming" ,
    "uniquePersonalClinicalSamples": "uniquePersonalClinicalSamples" ,
    "biobankOwner": "biobankOwner" ,
    "sampleSize": "sampleSize" ,
    "dateOfFirstPatientVisit": "dateOfFirstPatientVisit" ,
    "numberOfTherapyGroups": "numberOfTherapyGroups" ,
    "dateFirstPtEntersTreatment": "dateFirstPtEntersTreatment" ,
    "anticipatedQuarterlyEnrollment": "anticipatedQuarterlyEnrollment" ,
    "dateLastPatientEntersTrial": "dateLastPatientEntersTrial" ,
    "numberOfInvestigativeSite": "numberOfInvestigativeSite" ,
    "dateLastPatientEntersTreatment": "dateLastPatientEntersTreatment" ,
    "potentialCountries": "potentialCountries" ,
    "dateOfLastPatientVisit": "dateOfLastPatientVisit" ,
    "multiCountryTrial": "multiCountryTrial" ,
    "dateOfReportSubmission": "dateOfReportSubmission" ,
    "clinicalTrialPhase": "clinicalTrialPhase",
    "includePreClinicalPortion": "includePreClinicalPortion" ,
    "dateOfPublicationSubmission": "dateOfPublicationSubmission" ,
    "assignmentToTreatment": "assignmentToTreatment",
    "leadInPeriod": "leadInPeriod" ,
    "design": "design" ,
    "blinding": "blinding" ,
    "controls": "controls" ,
    "blindingDoneBy": "blindingDoneBy" ,
    "extension": "extension" ,
    "patientType": "patientType" ,
    "extensionLength": "extensionLength",
    "patientGender": "patientGender" ,
    "ageRangeStart": "ageRangeStart" ,
    "patientTreatmentPeriod": "patientTreatmentPeriod" ,
    "ageRangeEnd": "ageRangeEnd" ,
    "studyOnOffLabel": "studyOnOffLabel" ,
    "lineOfTherapy": "lineOfTherapy" ,
    "lillyToSupplyStudyCompound": "lillyToSupplyStudyCompound",
    "ifNoWhy": "ifNoWhy",
    "lillyStudyCompound1":  "lillyStudyCompound1",
    "requireFifthLillyCompound": "requireFifthLillyCompound" ,
    "lillyStudyCompound1Other": "lillyStudyCompound1Other",
    "lillyStudyCompound5": "lillyStudyCompound5",
    "compound1AvailableMaterial": "compound1AvailableMaterial",
    "lillyStudyCompound5Other": "lillyStudyCompound5Other",
    "compound1AvailableMaterialOther": "compound1AvailableMaterialOther",
    "compound5AvailableMaterial": "compound5AvailableMaterial",
    "compound1Formulation": "compound1Formulation",
    "compound5AvailableMaterialOther": "compound5AvailableMaterialOther",
    "compound1DosingSchedule": "compound1DosingSchedule" ,
    "compound5Formulation": "compound5Formulation",
    "compound1DosingSchedulOther": "compound1DosingSchedulOther",
    "compound5DosingSchedule": "compound5DosingSchedule" ,
    "compound1AdministeredDose": "compound1AdministeredDose",
    "compound5DosingScheduleOther": "compound5DosingScheduleOther",
    "compound1AmountQuantity": "compound1AmountQuantity",
    "compound5AdministeredDose": "compound5AdministeredDose",
    "requestedAmtOfComp1PreClin": "requestedAmtOfComp1PreClin",
    "compound5AmountQuantity": "compound5AmountQuantity",
    "requireSecondLillyCompound": "requireSecondLillyCompound" ,
    "requestedAmtOfComp5PreClin": "requestedAmtOfComp5PreClin",
    "lillyStudyCompound2": "lillyStudyCompound2",
    "requireSixthLillyCompound": "requireSixthLillyCompound" ,
    "lillyStudyCompound2Other": "lillyStudyCompound2Other",
    "lillyStudyCompound6": "lillyStudyCompound6",
    "compound2AvailableMaterial": "compound2AvailableMaterial",
    "lillyStudyCompound6Other": "lillyStudyCompound6Other",
    "compound2AvailableMaterialOther": "compound2AvailableMaterialOther",
    "compound6AvailableMaterial": "compound6AvailableMaterial",
    "compound2Formulation": "compound2Formulation",
    "compound6AvailableMaterialOther": "compound6AvailableMaterialOther",
    "compound2DosingSchedule": "compound2DosingSchedule" ,
    "compound6Formulation": "compound6Formulation",
    "compound2DosingScheduleOther": "compound2DosingScheduleOther",
    "compound6DosingSchedule": "compound6DosingSchedule" ,
    "compound2AdministeredDose": "compound2AdministeredDose",
    "compound6DosingScheduleOther": "compound6DosingScheduleOther",
    "compound2AmountQuantity": "compound2AmountQuantity",
    "compound6AdministeredDose": "compound6AdministeredDose",
    "requestedAmtOfComp2PreClin": "requestedAmtOfComp2PreClin",
    "compound6AmountQuantity": "compound6AmountQuantity",
    "requireThirdLillyCompound": "requireThirdLillyCompound" ,
    "requestedAmtOfComp6PreClin": "requestedAmtOfComp6PreClin",
    "lillyStudyCompound3": "lillyStudyCompound3",
    "requireSeventhLillyCompound": "requireSeventhLillyCompound" ,
    "lillyStudyCompound3Other": "lillyStudyCompound3Other",
    "lillyStudyCompound7": "lillyStudyCompound7",
    "compound3AvailableMaterial": "compound3AvailableMaterial",
    "lillyStudyCompound7Other": "lillyStudyCompound7Other",
    "compound3AvailableMaterialOther": "compound3AvailableMaterialOther",
    "compound7AvailableMaterial": "compound7AvailableMaterial",
    "compound3Formulation": "compound3Formulation",
    "compound7Formulation": "compound7Formulation",
    "compound3DosingSchedule": "compound3DosingSchedule" ,
    "compound7AvailableMaterialOther": "compound7AvailableMaterialOther",
    "compound3DosingScheduleOther": "compound3DosingScheduleOther",
    "compound7DosingSchedule": "compound7DosingSchedule" ,
    "compound3AdministeredDose": "compound3AdministeredDose",
    "compound7DosingScheduleOther": "compound7DosingScheduleOther",
    "compound3AmountQuantity": "compound3AmountQuantity",
    "compound7AdministeredDose": "compound7AdministeredDose",
    "requestedAmtOfComp3PreClin": "requestedAmtOfComp3PreClin",
    "compound7AmountQuantity": "compound7AmountQuantity",
    "requireFourthLillyCompound": "requireFourthLillyCompound" ,
    "requestedAmtOfComp7PreClin": "requestedAmtOfComp7PreClin",
    "lillyStudyCompound4": "lillyStudyCompound4",
    "lillyStudyCompound4Other": "lillyStudyCompound4Other",
    "compound4AvailableMaterial": "compound4AvailableMaterial",
    "compound4AvailableMaterialOther": "compound4AvailableMaterialOther",
    "compound4Formulation": "compound4Formulation",
    "compound4DosingSchedule": "compound4DosingSchedule" ,
    "compound4DosingScheduleOther": "compound4DosingScheduleOther",
    "compound4AdministeredDose": "compound4AdministeredDose",
    "compound4AmountQuantity": "compound4AmountQuantity",
    "requestedAmtOfComp4PreClin": "requestedAmtOfComp4PreClin",
    "requestingFunding": "requestingFunding" ,
    "nonLillySupport": "nonLillySupport" ,
    "amountOfFunds": "amountOfFunds",
    "nameOfCompany": "nameOfCompany",
    "currency": "currency",
    "typeOfSupport": "typeOfSupport",
    "references": "references",
    "hCPProposalComments": "hCPProposalComments",
    "feedback": "feedback"
}


export const DIAGNOSTIC_PROPOSAL = {
    "recordID": "recordID",
    "diagnosticSubmitted": "diagnosticSubmitted",
    "submissionId": "submissionId",
    "deleted": "deleted",
    "diagnostic": "diagnostic",
    "currencyISOCode": "currencyISOCode",
    "createdDate": "createdDate",
    "createdByID": "createdByID",
    "lastModifiedDate": "lastModifiedDate",
    "LastModifiedByID": "LastModifiedByID",
    "systemModstamp": "systemModstamp",
    "lastActivityDate": "lastActivityDate",
    "submission": "submission",
    "assayMethod": "assayMethod",
    "assayName": "assayName",
    "assayRegulatoryStatus": "assayRegulatoryStatus",
    "iSLabCLIAISOcertified": "iSLabCLIAISOcertified",
    "cityCoinvestigator": "cityCoinvestigator",
    "coInvestigatorState": "coInvestigatorState",
    "diagnosticSubmitted": "diagnosticSubmitted",
    "emailAddressCoInvestigator": "emailAddressCoInvestigator",
    "emailAddressLegalRepresentative": "emailAddressLegalRepresentative",
    "emailAddressforSafetyMailNotification": "emailAddressforSafetyMailNotification",
    "firstNamecoinvestigator": "firstNamecoinvestigator",
    "firstNamelegalreprresentative": "firstNamelegalreprresentative",
    "uniquePersonalClinicalsamples": "uniquePersonalClinicalsamples",
    "institution": "institution",
    "isAnotherentitythesponsorofthisst": "isAnotherentitythesponsorofthisst",
    "labExternalQualityAssessmentProgram": "labExternalQualityAssessmentProgram",
    "biobankowner": "biobankowner",
    "lastNameCoInvestigator": "lastNameCoInvestigator",
    "lastNamelegal": "lastNamelegal",
    "cityLegalRepresentative": "cityLegalRepresentative",
    "stateRepresentativeState": "stateRepresentativeState",
    "methods": "methods",
    "middleInitiallcoinvestigator": "middleInitiallcoinvestigator",
    "middleInitialLegal": "middleInitialLegal",
    "otherHelpfulProjectTimelineInformation": "otherHelpfulProjectTimelineInformation",
    "phoneNumberCoInvestigator": "phoneNumberCoInvestigator",
    "phoneNumberLegalRepresentative": "phoneNumberLegalRepresentative",
    "platform": "platform",
    "primaryObjectiveHypothesis": "primaryObjectiveHypothesis",
    "projectedStudyCompleteDate": "projectedStudyCompleteDate",
    "projectedStudyStartDate": "projectedStudyStartDate",
    "projectedDateForPublication": "projectedDateForPublication",
    "projectedDateForStudyReportSubmission": "projectedDateForStudyReportSubmission",
    "projectedStudyLength": "projectedStudyLength",
    "projectedTimingForKeyStudyMilestone": "projectedTimingForKeyStudyMilestone",
    "publicationStrategy": "publicationStrategy",
    "references": "references",
    "regulatoryApproved": "regulatoryApproved",
    "sampleDescription": "sampleDescription",
    "pleaseDescribeIfOther1": "pleaseDescribeIfOther1",
    "pleaseDescribeIfOther2": "pleaseDescribeIfOther2",
    "pleaseDescribeIfOther3": "pleaseDescribeIfOther3",
    "scientificRationale": "scientificRationale",
    "secondaryObjective": "secondaryObjective",
    "sourceOfSamples": "sourceOfSamples",
    "specialty": "specialty",
    "streetAddressCoInvestigator": "streetAddressCoInvestigator",
    "streetAddressLegalRepresentative": "streetAddressLegalRepresentative",
    "studyReportRequestExplain": "studyReportRequestExplain",
    "studyTitle": "studyTitle",
    "studyType": "studyType",
    "targetCompletingDate": "targetCompletingDate",
    "targetStartingDate": "targetStartingDate",
    "specificTimingRequirements": "specificTimingRequirements",
    "testingTiming": "testingTiming",
    "tumorType": "tumorType",
    "zipCodeCoInvestigator": "zipCodeCoInvestigator",
    "zipCodeLegalRepresentative": "zipCodeLegalRepresentative",
    "evidenceOfAssayValidation": "evidenceOfAssayValidation",
    "finalStudyReport": "finalStudyReport",
    "ifAnyCoInvestigators": "ifAnyCoInvestigators",
    "laboratoryCertificationsDescription": "laboratoryCertificationsDescription",
    "medicalLicenseNumber": "medicalLicenseNumber",
    "rawDataIfRequestedExplain": "rawDataIfRequestedExplain",
    "rawDataRequested": "rawDataRequested",
    "requestingResearchFunding": "requestingResearchFunding"
}
export const USER_ROLE = {
    "Administrator" : "administrator", 
    "Global Coordinator - Biomeds" : "global_coordinator_biomeds", 
    "Global Coordinator - Diabetes" : "global_coordinator_diabetes",
    "Global Coordinator - Oncology" : "global_coordinator_oncology",
    "Industry Sponsored" : "global_coordinator_industry_sponsored", 
    "Operational Coordinator":"operational_coordinator",
    "Management User" : "read_only_management_user",
    "Read Only - Biomeds" : "read_only_biomeds",
    "Read Only - Diabetes":"read_only_diabetes",
    "Read Only - Oncology" : "read_only_oncology"
}
export const  mapping = {
    "submission"  :  "Submission",
    "reforecast"  :  "Reforecast",
    "recycle"  :  "Recycle",
    "delegee"  :  "Delegee",
    "contact"  :  "Study Contact",
    "enrollment"  :  "Enrollment",
    "publication"  :  "Publication",
    "studycompound"  :  "Study Compound",
    "proposal"  :  "Proposal",
    "protocol"  :  "Protocol",
    "amendment"  :  "Amendment",
    "diagnostic"  :  "Diagnostic Proposal",
    "translational"  :  "Translational Proposal",
    "expense"  :  "Trial Expense",
    "drugdeliverysite"  :  "Drug Delivery Site",
    "sitedrugoption" : "Site Drug Options",
    "reviewer" : "Reviewer",
    "proposalreview" : "Proposal Review",
    "conceptreview" : "Concept Review",
    "protocolreview" : "Protocol Review",
    "amendmentreview" : "Amendment Review",
    "publicationreview" : "Publication Review",
    "attachment" : "Attachment",
}