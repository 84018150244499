import React, { Component } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import axios from 'axios';

import swal from 'sweetalert';
import FileComponent from '../common/file-component/FileComponent';
import BootstrapModal from '../common/modal/BootstrapModal';
import API from '../../api/apis';
import logo from '../../static/images/sharepoint.png';
import UserContext from '../../context/UserContext';
import { uploadFile, enableWFActions } from '../../utility/helperFunctions';


export default class ReviewFiles extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },        selectedFile: null,
        exist_flag: false,
        siteURL: '',
        siteEnv: '',
        fileName: '',
        folderName: '',
        libraryName: '',
        access_token: '',
        listOfFiles: [],
        hideFileSection: true,
        hideCreateFolderSection: true,
        successModal: false,
        errorModal: false,
        action : "",
        showLoaderUpload :  false,
        submissionFolder : '',
            type : '',
            id : '',
    }
    columns = [{
        dataField: 'File Name',
        text: 'File Name',
        sort: true
    }, {
        dataField: 'Actions',
        text: ''
    }];

    componentDidUpdate(previousProps, previousState) {
        if (this.props.id && (this.state.id !== this.props.id) || (this.state.access.edit !== this.props.access.edit)) {
        this.setState({
            submissionFolder : `Submission-${(""+this.props.submissionid).padStart(4,0)}`,
            type : this.props.type,            
            id : this.props.id,
            folderName: `Submission-${this.props.submissionid}/Review/${this.props.type}/${this.props.id}`,
            access : this.props.access

        },async () =>
        { 
            await  this.isFolderExist(this.state.rootFolderName , this.state.folderName);
            await this.getFiles();
        })
    }
    }
    
   async componentDidMount() {
    let response = await API.get(`user/accesstoken`);
    
    console.log(response.data.access)
        this.setState({
            access : this.props.access,
            showLoader :  true,
            action : "fetching" , 
            siteURL: 'https://collab.lilly.com/sites/',
            siteEnv: response && response.data ? response.data.site_name : " ",
            libraryName: response && response.data ? response.data.library_name : " ",
            rootFolderName: 'Submission',
            submissionFolder : `Submission-${(""+this.props.submissionid).padStart(4,0)}`,
            type : this.props.type,
            id : this.props.id,
            folderName: `Submission-${(""+this.props.submissionid).padStart(4,0)}/Review/${this.props.type}/${this.props.id}`,
            access_token: response && response.data ? response.data.access : " "
        },
           async () =>
            { 
               await  this.isFolderExist(this.state.rootFolderName , this.state.folderName);
               await this.getFiles();
            });

    }
    data = [
        {
            'File Name': "MSP.docx",
            'Actions': <>
                <i className="bi bi-trash-fill" style={{ fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "right" }}></i>
                <i className="bi bi-cloud-download-fill" style={{ fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "right" }}></i>
            </>
        }
    ];
   
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }
    isFolderExist = async (rootFolderName , folderName) => {
        console.log('isFolderExist!!!!');
        let relativePath = '/_api/web/GetFolderByServerRelativeUrl(\'/sites/';
        let expandUrl = 'Exists';

        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + rootFolderName + '/' + folderName + '\')/' + expandUrl;
        //var fileUrl = 'https://collab.lilly.com/sites/ERMSDev/_api/web/GetFolderByServerRelativeUrl(\'/sites/LLIRDev/Root/Submission1\')/Exists';
        try {
            const resp = await axios.get(fileUrl, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose",
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },


            });
            console.log(resp);
            this.setState({exist_flag : resp.data.d.Exists });
            return resp.data.d.Exists;
        } catch (err) {
            console.error(err);
            // this.setState({ errorModal: true });

        }
    }


    //Retrieve the list of files from the folder
    getFiles = async () => {
        
        this.setState({
            listOfFiles: []
        })
        console.log('Download File!!!!');
        let relativePath = '/_api/web/GetFolderByServerRelativeUrl(\'/sites/';
        let expandUrl = '?$expand=Files&$select=Files/Name,Files/UniqueId';
        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '\')/' + expandUrl;
        //var fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFolderByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3\')/?$expand=Files&$select=Files/Name,Files/UniqueId';
        try {
            const resp = await axios.get(fileUrl, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose",
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
            });
            console.log(resp);

            this.setState({ 
                showLoader :  false , 
                listOfFiles: resp.data.d.Files.results.map((e) => { return { key: e.Name } }) });

        } catch (err) {
            console.error(err);
            this.setState({                 
                showLoader :  false 
            });

        }
    }

    downloadFile = (inFileName) => {
        console.log('downloadFile!!!!');
        let fileName = inFileName;//'Sample.docx';    
        let relativePath = '/_api/web/GetFileByServerRelativeUrl(\'/sites/';

        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '/' + fileName + '\')/$value';
        //var fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFileByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3/text.txt\')/$value';
        this.setState({
            showLoader :  true , 
            action : "download"
        })
        fetch(fileUrl, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    this.setState({ 
                        showLoader : false
                    })
                    return response.blob();
                }
                else {
                    this.setState({ 
                        showLoader : false,
                        errorModal: true });

                    throw new Error('Error Encountered.Please try again.');
                }
            })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', decodeURIComponent(fileName));

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

    }

    deleteFile = async (inFileName) => {
        console.log('deleteFile Click!!!!');
        let fileName = inFileName;// 'text.txt';      
        let relativePath = '/_api/web/GetFileByServerRelativeUrl(\'/sites/';
        //let fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFileByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3/text.txt\')';
        let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '/' + fileName + '\')';
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this file!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
        try {
            this.setState({showLoader :  true , action : "deletion"})

            const resp = await axios.post(fileUrl, {}, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose",
                    "IF-MATCH": "*",
                    "X-HTTP-Method": "DELETE",
                },
            });
            console.log(resp);
            this.setState({
                success_body: "Deleted successfully!",
                showLoader : false,
                successModal: true,
            })
            await this.getFiles();

        }
        catch (err) {
            this.setState({
                showLoader : false
            })
            console.error(err);
        }
    }
    });
    }
    createFolder = (rootFolderName , folderName) => {
        console.log('createFolder Click!!!!');
        let relativePath = '/_api/web/folders';
        let apiUrl = this.state.siteURL + this.state.siteEnv + relativePath;
        //let apiUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/folders';
        let folderMetadata = '{\'__metadata\': { \'type\': \'SP.Folder\' },\'ServerRelativeUrl\': \' ' + this.state.libraryName + '/' + rootFolderName + '/' + folderName + ' \'  }\r\n';
        fetch(apiUrl, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Content-Type': 'application/json;odata=verbose'
            },
            body: folderMetadata
        })
            .then((response) => {
                if (response.ok) {
                    console.log("Folder Created Successfully");
                    this.setState({exist_flag :true });
                }
                else {
                    this.setState({ errorModal: true });

                    throw new Error('Error Encountered.Please try again.');
                }
            });
    }
     createAllFolders   = async() => {
        let exist_flag = await this.isFolderExist(this.state.rootFolderName , this.state.submissionFolder);
        console.log(exist_flag)
        if (!exist_flag) {
           await this.createFolder(this.state.rootFolderName , this.state.submissionFolder);
        }

        exist_flag = await this.isFolderExist(this.state.rootFolderName +"/"+ this.state.submissionFolder, 'Review');
        console.log(exist_flag)
        if (!exist_flag) {
           await this.createFolder(this.state.rootFolderName +"/"+ this.state.submissionFolder, 'Review');

        }
        let curr_folder = this.state.rootFolderName + "/" + this.state.submissionFolder + "/" + 'Review';
        exist_flag = await this.isFolderExist(curr_folder, this.state.type);
        console.log(exist_flag)
        if (!exist_flag) {
           await this.createFolder(curr_folder, this.state.type);

        }
        curr_folder = curr_folder+"/"+this.state.type;
        exist_flag = await this.isFolderExist(curr_folder, this.state.id);
        console.log(exist_flag)
        if (!exist_flag) {
           await this.createFolder(curr_folder, this.state.id);

        }
        return;
    }

    // uploadFileSPO 
    //Get the selected file and update the state evey time it changes
    onFileChange = event => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
    };
    
    //Used to Upload a file to the SharepPoint Library
    uploadFileFunction = async () => {
        console.log(' uploadFileSPO Click!!!!');
        let exist_flag = await this.isFolderExist(this.state.rootFolderName , this.state.folderName);
        console.log(exist_flag)
        if (!exist_flag) {
            await this.createAllFolders();
        }
        if (this.state.selectedFile == null) {
            alert("Please Select a file to upload");
        }
        else {
            this.setState({
                showLoaderUpload :  true,
            })
            let fileName = encodeURIComponent(this.state.selectedFile.name.replace(/#/g,"_"));
            let relativePath = '/_api/web/GetFolderByServerRelativeUrl(\'/sites/';
            let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '\')/Files/add(url=' + '\'' + fileName + '\'' + ',overwrite=true)';
            //let fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFolderByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3\')/Files/add(url=\'text.txt\',overwrite=true)';

            const reader = new FileReader();
            reader.readAsArrayBuffer(this.state.selectedFile);
            
            reader.onload = async (event) => {
                //console.log("reader data",event.target.result);
                axios(fileUrl, {
                    method: "POST",
                    headers: {
                             'Content-Type': 'application/json;odata=verbose',
                             'Authorization': 'Bearer ' + this.state.access_token 
                             }, 
                             data: event.target.result,
                     })    
                     .then((response) =>
                     {
                         if(response.status === 200)
                         {
                            console.log("FileUploaded Successfully.");
                            this.setState({
                                        success_body: "Uploaded successfully!",
                                        successModal: true,
                                        selectedFile : null,
                                        fileMessage: "File uploaded!",
                                        showLoaderUpload : false
                                    })
                                    this.getFiles();
                                }
                         else
                         {
                            // console.error(err);
                                this.setState({ 
                                    showLoaderUpload : false,
                                    errorModal: true });
            
                         }
                        
                    })   
                    // await           
                };
        }
    }
    openSharepoint  = async() => {
        let exist_flag = await this.isFolderExist(this.state.rootFolderName , this.state.folderName);
        console.log(exist_flag)
        if (!exist_flag) {
          await this.createAllFolders();
        }
        window.open(`https://collab.lilly.com/:f:/r/sites/${this.state.siteEnv}/${this.state.libraryName}/Submission/${this.state.folderName}?csf=1&web=1&e=7OE383`);
    }
    render() {
        let { listOfFiles,submissionDetails, successModal, errorModal ,success_body,exist_flag,showLoaderUpload  , action ,  showLoader } = this.state;

        
        return (
            <>
            <Row className = "pb-4">
                <Col sm = {1}>
                    <img
                        src={logo}
                        width="40"
                        height="40"
                        className="d-inline-block align-top"
                        alt="logo"
                    />
                </Col>
                <Col className = "navbar-brand" sm={11}>
                    <div>Sharepoint Files</div>
                </Col>    
            </Row>
                <Row>
                  {/* {( this.props.access.edit) && <Col sm={6}> */}
                  {enableWFActions() && <Col sm={6}>

                        <Form.File
                            id="custom-file"
                            className="custom-file-label"
                            onChange={this.onFileChange}
                            label={this.state.selectedFile?.name}
                            custom
                        />
                    </Col>}
                    {/* {(this.props.access.edit) && <Col sm={6}> */}
                    { enableWFActions() && <Col sm={6}>

                        {showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </Button>}
                        {!showLoaderUpload  && <Button onClick={() => uploadFile(this.state.selectedFile,this.state.listOfFiles,this.uploadFileFunction)} variant="secondary">Upload Attachment</Button>

                        }
                    </Col>}
                    <Col sm={6}>
                        {<Button onClick={this.openSharepoint}>Go To SharePoint Library </Button> }
                        {/* {!exist_flag && this.state.access.edit &&  <Button onClick={this.createAllFolders}>Create Folder </Button> } */}
                        {/* <Button onClick={this.uploadFileSPO} variant="secondary">Upload Attachment</Button> */}
                    </Col>
                </Row>

                {showLoader &&
                    <Row>
                        <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                            <span className="visually-hidden">File  {action} in progress </span>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </div>

                    </Row>}
               
                
                <br />
                <Row>
                  {this.state.listOfFiles.length > 0 &&
                        <FileComponent data={listOfFiles}
                            accessDelete = {this.props.access.create}
                            handleDelete={this.deleteFile}
                            type={"sharepoint"}
                            handleDownload={this.downloadFile} />}
                </Row>
                {this.state.successModal &&
                    <BootstrapModal
                        show={successModal}
                        header="Success"
                        body={success_body}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </>
        )
    }
}
