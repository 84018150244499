import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';

export default class Enrollment extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },        // oldProps:{},
        expected_enrollment: this.props.expected_enrollment || "",
        current_enrollment_baseline: this.props.current_enrollment_baseline || "",
        current_enrollment_delta: this.props.current_enrollment_delta || "",
        current_enrollment_estimated: this.props.current_enrollment_estimated || "",
        total_percent_to_plan: this.props.total_percent_to_plan || "",
        current_enrollment_actual: this.props.current_enrollment_actual || "",
        patchObject:{}
    };
    enrollData = () => {
        const { submissionDetails } = this.props;
        const newObject = {};
        let obj = this.state.patchObject;
        Object.keys(obj).forEach((key) => {
            if (obj[key] === "" || obj[key] === null) {
                return false;
            }
            else {
                newObject[key] = obj[key];
            }
        });
        delete newObject.error;
        if(this.state.expected_enrollment !== this.props.submissionDetails.expected_enrollment){
            newObject["total_percent_to_plan"] = this.state["expected_enrollment"] && this.state["current_enrollment_actual"] ? ((this.state["current_enrollment_actual"] / this.state["expected_enrollment"]) * 100) : null;

        }
        this.state.patchObject={};
        return newObject;
    }
    componentDidMount() {
        const { submissionDetails } = this.props
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
    this.setState({
        selectedRole: selectedRole,
        access: {
            view: true,
            create:  selectedRole.includes("administrator"),
            edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
            delete: selectedRole.includes("administrator"),
        },
            // oldProps : submissionDetails,
            expected_enrollment: submissionDetails.expected_enrollment || "",
            current_enrollment_baseline: submissionDetails.current_enrollment_baseline || "",
            current_enrollment_delta: submissionDetails.current_enrollment_delta || "",
            current_enrollment_estimated: submissionDetails.current_enrollment_estimated || "",
            total_percent_to_plan: submissionDetails.total_percent_to_plan || "",
            current_enrollment_actual: submissionDetails.current_enrollment_actual || "",
        })
    }

 
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
        this.setState({
            selectedRole: selectedRole,
            access: {
           view: true,
           create:  selectedRole.includes("administrator"),
           edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
           delete: selectedRole.includes("administrator"),
       },
        })
    }
    }
    get inputFieldsArray() {
        return [
            {
                label: "Expected Enrollment",
                type: "number",
                placeholder: "Enter Expected Enrollment",
                name: "expected_enrollment",
                helptext:"Number of patients expected to enroll in Trial "
            },
            {
                label: "Current Enrollment (Baseline)",
                type: "text",
                name: "current_enrollment_baseline",
                disabled: true,
                helptext:"Expected current enrollment based on initial projections"
            },
            {
                label: "Current Enrollment Delta",
                type: "text",
                name: "current_enrollment_delta",
                disabled: true,
                helptext:"Percentage of current enrollment compared to estimated current enrollment"
            },
            {
                label: "[EXT] Current Enrollment (Estimated)",
                type: "text",
                name: "current_enrollment_estimated",
                disabled: true,
                helptext:"Expected current enrollment based on current projections"
            },
            {
                label: "Total Percent to Plan",
                type: "text",
                name: "total_percent_to_plan",
                disabled: true,
                helptext:"Percentage of patients enrolled compared to expected enrollment"
            },
            {
                label: "Current Enrollment (Actual)",
                type: "text",
                name: "current_enrollment_actual",
                disabled: true,
                helptext:"Current enrollment"
            }
        ]
    }

    static getDerivedStateFromProps(props, current_state) {
        let vals = [ "current_enrollment_baseline", "current_enrollment_delta", "current_enrollment_estimated", "total_percent_to_plan", "current_enrollment_actual"]
        let obj = {}
        for (const ele of vals) {
            if (current_state[ele] !== props.submissionDetails[ele]) {
                obj[ele] = props.submissionDetails[ele]
            }
        }
        console.log(obj)
        if (Object.keys(obj).length !== 0) {
            return {
                ...obj
                            // computed_prop: heavy_computation(props.value)
            }
        }
        return null
    }

    onInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value ,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},});
        if (value !== "")
            this.setState({ error: { ...this.state.error, [name]: false } });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e},
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }

    checkboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked ,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.checked},
        
    })}

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            else if ((field.name === "current_enrollment_delta") || (field.name === "total_percent_to_plan")) {
                return (
                    <Col sm={6} key={field.label}>
                        <InputField
                            {...field}
                            disabled = {field.disabled ? field.disabled : !this.state.access.edit }
                            value={this.props.submissionDetails[field.name] ? this.props.submissionDetails[field.name] + '%' : this.props.submissionDetails[field.name]}
                            onSelect={field.onSelect || this.onSelectChange}
                            onChange={this.onInputChange}
                        />
                    </Col>
                )
            }
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }

    render() {

        return (
            <Row>
                {this.renderInputFields()}
            </Row>
        )
    }
}
