import axios from 'axios';
import React from 'react'
import API from '../../api/apis';
import { sharepointAudit } from '../ToReviewDetails/SharepointAudit';

export default class SharepointConnect{
    state={
        site: 'https://collab.lilly.com/sites',
        rootFolder:"Submission",
        submissionId:'',
        review_type:'',
        review_header_id:'',
        reviewers_detail_id:'',
    }
    constructor(props={}) {
       this.state =Object.assign({...this.state},{...props});       
    }
    loadConfigInformation=async()=>{
        let response = await API.get(`user/accesstoken`);
        let location= response?.data?.site_name??"" ;
        let access_token=response?.data?.access??"";
        let library_name=response?.data?.library_name??"";
        this.state =Object.assign({...this.state},{location,access_token,library_name});   
        return this.state ;
    }
    isFolderExist = async (folderName) => {      
        let expandUrl = 'Exists';
        let {site,location,access_token,library_name,rootFolder}=this.state;
        let fileUrl=`${site}/${location}/_api/web/GetFolderByServerRelativeUrl('/sites/${location}/${library_name}/${rootFolder}/${folderName}')/${expandUrl}`;
        
      //https://collab.lilly.com/sites/LLIRDev/_api/web/GetFolderByServerRelativeUrl('/sites/LLIRDev/Root/Submission/Submission-75231/Review/Protocol/57/')/Exists

        try {
            const resp = await axios.get(fileUrl, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose"
                },


            });            
            return  resp?.data?.d?.Exists??false;
        } catch (err) {
          

        }
        return false;
    }
    createFolder=async(folderName)=>{
        //https://collab.lilly.com/sites/LLIRDev/_api/web/folders/add('Root/Submission/Submission-75230/Review/Amendment')
        let {site,location,access_token,library_name,rootFolder}=this.state;
        let responseData=false;
        let folderUrl=`${site}/${location}/_api/web/folders/add('${library_name}/${rootFolder}${folderName}')`;
        try {
            // const resp = await axios.post(folderUrl, {
            //     headers: {
            //         'Authorization': 'Bearer ' + this.state.access_token,
            //         'Content-Type': 'application/json',
            //         "Accept": "application/json;odata=verbose"
            //     },


            // });
            // return resp?.data?.d?.Exists??false;

            await fetch(folderUrl, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json;odata=verbose'
                }
            })
                .then((response) => {
                    if (response.ok) {
                        responseData= true;
                    }
                });
        } catch (err) {
         console.log(err);
        }
        return responseData;
    }
    downloadFile = async (ServerRelativeUrl,Name) => {
      
        let fileName = encodeURIComponent(ServerRelativeUrl);    
               //var fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFileByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3/text.txt\')/$value';
        let {site,location,access_token,library_name,rootFolder}=this.state;
        let fileUrl=`${site}/${location}/_api/web/GetFileByServerRelativeUrl('${fileName}')/$value`;
        let blobData=null;
        await fetch(fileUrl, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                else {
                }
            })
            .then((blob) => {
             
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', Name);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
            return true;
    }
    deleteFile = async (ServerRelativeUrl) => {
        let response =false;
        let fileName = encodeURIComponent(ServerRelativeUrl);
        //var fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFileByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3/text.txt\')/$value';
        let { site, location, access_token, library_name, rootFolder, userSystemId, submissionId, review_header_id } = this.state;
        let fileUrl = `${site}/${location}/_api/web/GetFileByServerRelativeUrl('${fileName}')`;
         try {
            const resp = await axios.post(fileUrl, {}, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose",
                    "IF-MATCH": "*",
                    "X-HTTP-Method": "DELETE",
                },
            });
            response=true;

            const payload = {
                "action": "DELETE",
                "submission_id": submissionId,
                "location_type": "review",
                "created_by": userSystemId,
                "review_header_id": review_header_id,
            
                "files":[
                    {  
                        "file_name": ServerRelativeUrl?.split('/')?.pop(),
                        "sharepoint_path": ServerRelativeUrl,
                    }
                ]

            }
            await sharepointAudit(payload);

        }
        catch (err) {
        
        }
        return response;
    }
    fetchFiles=async()=>{
        /*
        Url Format
        https://collab.lilly.com/sites/LLIRDev/
        _api/web/GetFolderByServerRelativeUrl
        ('/sites/LLIRDev/Root/Submission/Submission-75230/Reviews/Concept/574/414/')
        /Files
        */
      
        let {submissionId,review_type,review_header_id,reviewers_detail_id,isOldReview,review_id}=this.state;
       let fileResponse=[];
        let paths=[`Submission-${submissionId}`,"Reviews",review_type,review_header_id,reviewers_detail_id];
        if(isOldReview){
            paths=[`Submission-${submissionId}`,"Review",review_type,review_id];
        }
        let filePath=paths.join("/");
        let {site,location,access_token,library_name,rootFolder}=this.state;
        let fileUrl=`${site}/${location}/_api/web/GetFolderByServerRelativeUrl('/sites/${location}/${library_name}/${rootFolder}/${filePath}')/Files`;
        try {
            const resp = await axios.get(fileUrl, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.access_token,
                    'Content-Type': 'application/json',
                    "Accept": "application/json;odata=verbose"
                },
            });
            console.log(resp);
            let files=resp?.data?.d?.results??[];
            fileResponse=files.map((item,index)=>{
                let {LinkingUrl,Name,TimeCreated,TimeLastModified,UniqueId,ServerRelativeUrl}=item;
                return {LinkingUrl,Name,TimeCreated,TimeLastModified,UniqueId,reviewers_detail_id,ServerRelativeUrl};
            });

        } catch (err) {
            console.error(err);            
        }
        return fileResponse;
    }
    readFileAsync= (blob)=>{
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onload = (event) => resolve(event.target.result)
            //reader.onloadend = () => resolve(reader.result)
            reader.readAsArrayBuffer(blob)
          })
    } 
    uploadMultipleFiles=async(selectedFiles)=>{
        let {userSystemId, review_id, review_header_id, submissionId} = this.state;
        let parallelCalls=[];
        let records=[];
        let parallelCallsData = [];
        if(selectedFiles&&selectedFiles.length>0){
            for(let i=0;i<selectedFiles.length;i++){
                parallelCalls.push(this.uploadFile(selectedFiles[i], true ));
            }
            await Promise.all(parallelCalls)
                .then(async(data) => {
                    parallelCallsData = data?.map( e => { return e?.respData });
                    records = data?.map( e => { return e?.status ? true : false });
                    // records = [...data];

                    const filesList = [];
                    for(let i=0;i<parallelCallsData.length;i++){
                        filesList.push({
                            "file_name": parallelCallsData[i]?.Name,
                            "sharepoint_path": parallelCallsData[i]?.ServerRelativeUrl,
                            "created_at": parallelCallsData[i]?.TimeCreated
                        });
                    }
                    const payload = {
                        "action": "INSERT",
                        "submission_id": submissionId,
                        "location_type": "review",
                        "created_by": userSystemId,
                        "review_header_id": review_header_id,
                        "review_id": review_id,
                        "files": filesList
    
                    }
                    await sharepointAudit(payload);
                 });
        }
        let status=records&&records.filter(p=>p===true).length>0;
        return status;
    }
    uploadFile=async (selectedFile, multipleFiles)=>{
        let {userSystemId, review_id} = this.state;
        let response=false;
     
        if(selectedFile){
            let fileName = encodeURIComponent(selectedFile.name.replace(/#/g,"_"));
            let {submissionId,review_type,review_header_id,reviewers_detail_id}=this.state;
            let paths=[`Submission-${submissionId}`,"Reviews",review_type,review_header_id,reviewers_detail_id];
            let filePath=paths.join("/");
            let pathExist=await this.isFolderExist(filePath);
            let subPath="";
            if(!pathExist){
              
                for(let i=0;i<paths.length;i++){
                    let splits=paths[i].toString().split("/");/*if path containg subpath liks demo/demo123 */                   
                    for(let j=0;j<splits.length;j++){
                        subPath=`${subPath}/${splits[j]}`;
                        pathExist=await this.isFolderExist(subPath);
                        if(!pathExist){
                            pathExist= await this.createFolder(subPath);
                        }
                    }
                   
                }
            }
            pathExist=await this.isFolderExist(filePath);
            
            if(pathExist){
             
                let {site,location,access_token,library_name,rootFolder}=this.state;
                let fileUrl=`${site}/${location}/_api/web/GetFolderByServerRelativeUrl('/sites/${location}/${library_name}/${rootFolder}/${filePath}')/Files/add(url='${fileName}',overwrite=true)`;
                let fileBinary=await this.readFileAsync(selectedFile);
                await  axios(fileUrl, {
                    method: "POST",
                    headers: {
                             'Content-Type': 'application/json;odata=verbose',
                             'Authorization': 'Bearer ' + access_token 
                             }, 
                             data: fileBinary,
                     })    
                     .then(async(resp) =>
                     {
                         if(resp.status === 200)
                         {                              
                            response=true;
                            
                            if( multipleFiles === true ){
                                response = {status: true, respData: resp.data}
                                return response
                            }else{
                                const filesList = [];
                                filesList.push({
                                    "file_name": resp?.data?.Name,
                                    "sharepoint_path": resp?.data?.ServerRelativeUrl,
                                    "created_at": resp?.data?.TimeCreated
                                });
                                const payload = {
                                    "action": "INSERT",
                                    "submission_id": submissionId,
                                    "location_type": "review",
                                    "created_by": userSystemId,
                                    "review_header_id": review_header_id,
                                    "files": filesList
                
                                }
                                await sharepointAudit(payload);
                            }
                         }
                       
                    });
                /*
                const reader = new FileReader();              
                reader.onload = async (event) => {
                    debugger;
                    console.log("")
                   await  axios(fileUrl, {
                        method: "POST",
                        headers: {
                                 'Content-Type': 'application/json;odata=verbose',
                                 'Authorization': 'Bearer ' + access_token 
                                 }, 
                                 data: event.target.result,
                         })    
                         .then((response) =>
                         {
                             if(response.status === 200)
                             {                              
                                response=true;
                             }
                           
                        })   
                    };
                    reader.onloadend=async(e)=>{
                        console.log("completed")
                    }
                    reader.onerror=async(e)=>{
                        console.log("error")
                    }
                    reader.readAsArrayBuffer(selectedFile);
                    */
            }
          
        }
        return response;
        
    }
    getFields=async(list_id)=>{
        let {site,location,access_token,library_name,rootFolder}=this.state;
        //https://collab.lilly.com/sites/LLIRDev/_api/Web/Lists(guid'8cdb04a9-9733-4c36-929f-fda45f10c47d')/Fields
        let fileUrl=`${site}/${location}/_api/web/lists(guid'${list_id}')/Fields`;
        const resp = await axios.get(fileUrl, {
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json',
                "Accept": "application/json"
            },
        });
        let data = resp?.data ?? {};
        if (data && data.value) {
            let availableFields = data.value.filter(p => p.Hidden === false && p["FromBaseType"] === false);
            console.log("availableFields:", availableFields);
            return availableFields;
        }
    }
    getListsDetails=async(list_id)=>{
        let {site,location,access_token,library_name,rootFolder}=this.state;
        //https://collab.lilly.com/sites/LLIRDev/_api/Web/Lists(guid'8cdb04a9-9733-4c36-929f-fda45f10c47d')/Fields
        let fileUrl=`${site}/${location}/_api/web/lists(guid'${list_id}')`;
        const resp = await axios.get(fileUrl, {
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json',
                "Accept": "application/json"
            },
        });
        let data = resp?.data ?? {};
        return data;
    }
    getLists=async()=>{
       
        let {site,location,access_token,library_name,rootFolder}=this.state;
        let fileUrl=`${site}/${location}/_api/web/lists`;
        const resp = await axios.get(fileUrl, {
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json',
                "Accept": "application/json"
            },
        });

        let data=resp?.data??{};
        if(data&&data.value){
            let availableLists=data.value.filter(p=>p.Hidden===false&&p.BaseType===0);
            console.log("availableLists:",availableLists);
            return availableLists;
        }
        return [];
       
    }
}