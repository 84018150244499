import Alert from 'react-bootstrap/Alert'
import React, { Component } from 'react'
import { Button, Card, Col, Nav, Row, } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';


class DashboardFile extends Component {
   
   

   
  
  
    render() {
       
       
        return (
            <div className="inpage-content">
               
                 <Row className="dashboard-main">
                
                <Col md={6}>                    
                <Card className="data-cards">
                        <Card.Body>
                            {/* <p className="bi bi-journal-medical pt-3 pl-5 text-center"> Submissions</p> */}
                            <Nav>
                            <Nav.Link  as={NavLink} to="/submissions"><p className="bi bi-journal-medical" > Submissions</p></Nav.Link>
                            </Nav>
                            
                        </Card.Body>
                    </Card>
                </Col>
               
                <Col md={6}>                    
                <Card className="data-cards">
                        <Card.Body>
                            {/* <p className="bi bi-people"> Investigator</p> */}
                            <Nav>
                            <Nav.Link as={NavLink}  to="/investigators"><p className="bi bi-people" > Investigators</p></Nav.Link>
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>                    
                <Card className="data-cards">
                        <Card.Body>
                            {/* <p className="bi bi-graph-up"> Reports</p> */}
                            <Nav>
                            <Nav.Link as={NavLink}  to="/reports"><p className="bi bi-graph-up" > Reports</p></Nav.Link>
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
                 <Col md={6}>                    
                <Card className="data-cards">
                        <Card.Body>
                            {/* <p className="bi bi-globe"> Country</p> */}
                            <Nav>
                            <Nav.Link as={NavLink}  to="/country"><p className="bi bi-globe" > Countries</p></Nav.Link>
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </div>
           
        )
    }
}

export default withRouter(DashboardFile);