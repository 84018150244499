import React,{useState,useEffect} from 'react'
import DatatTableEx from '../../common/DataTableEx'
import { isCoordinator } from '../commonFunctions';
import UserContext from '../../../context/UserContext';
import API from '../../../api/apis';
import moment from 'moment';
import DTAButton from '../../common/Button/DTAButton';
import AmendmentModalEx2 from '../../AmendmentModalEx2';
import Button from '../../common/Button';
import { iirDateFormat, iirDateFormatEx } from '../../../utility/dateHelper';
import Dropdown from 'react-bootstrap/Dropdown';
import './index.css'
import { OverlayTrigger } from "react-bootstrap";
import swal from 'sweetalert';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div     
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
export default class AmendmentList extends React.Component{
    static contextType = UserContext;
    constructor(props){
        super(props);
        this.state={
            source:[],
            showLoader:false,
            showAmendmentForm:false
        }
    }
    versionOptions= ['None','Current','Outdated','Under Review','Declined']
    columns = [
        {   
            dataField: 'id',
            text: 'Protocol ID',
            sort: true,
        },
        {
            dataField: 'version_date',
            text: 'Protocol Version Date',
            sort: true,
            sortValue: (cell, row) => moment(row["version_date"],false).toDate()
        },
        {
            dataField: 'protocol_version_number',
            text: 'Protocol Version',
            sort: true,
        },
        {
            dataField: 'supported',
            text: 'Supported',
            sort: true,
            csvExport:false
        },
        {
            dataField: 'supportedCsv',
            text: 'Supported',
            hidden:true,
            excludeFilter: true
        },
        {
            dataField: 'version_state',
            text: 'Version State',
            sort: true,
            editable:false,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
            formatter: (cell, row, rowIndex) => {
                let is_coordinator = isCoordinator(this.context);
                return <div className="d-flex" style={{userSelect:'none'}}>
                    {is_coordinator&&<div>
                        <Dropdown style={{ cursor: 'pointer' }}>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                            <span className='flex-grow-1'>{row.version_state}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="bg-dark text-white dd-menu-dark" rootCloseEvent='mousedown' >
                                {this.versionOptions.map(option=>{
                                    return <Dropdown.Item eventKey="1" onClick={e=>{
                                        e.preventDefault();
                                        this.updateAmendment(row.version_state, option, row.id);
                                        }} className="text-white">{option}</Dropdown.Item>
                                    }
                                )}
                                
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>}
                    {
                       !is_coordinator&& row.version_state
                    }
                </div>
            }
        },{
            dataField: '',
            text: 'Actions',
            sort: false,
            csvExport: false,
            excludeFilter: true,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
            formatter: (cell, row, rowIndex) => {
                let data=row;
                return <div className="d-flex" style={{userSelect:'none'}}>
                    {this.state.is_coordinator && <div>
                        <OverlayTrigger trigger="click" rootClose={true} rootCloseEvent={"click"}
                            placement="bottom" delay={{ show: 700, hide: 0 }}
                            overlay={<ul className="bg-white text-white dropdown-menu show">
                                <li onClick={e => {
                                    e.preventDefault(); e.stopPropagation();
                                    this.handleAmendmentDelete(data.id, this.props.submissionId);
                                }}
                                    className="text-danger font-weight-bold dropdown-item">DELETE</li>
                            </ul>}><span className="material-icons">more_vert</span></OverlayTrigger>
                    </div>}
                </div>
            }
        }
    ];
     APIUrls={
        "LIST":'reviewsTab/protocolAmendment/amendmentList/'
     }  
     componentDidMount(){
        this.setState({ is_coordinator: isCoordinator(this.context) })
        this.getAmendmentList();
     }
     handleAmendmentDelete = async (amendmentId, submissionId) => {
        swal({
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res =  await API.post(`recycle/amendment/delete/${amendmentId}/${submissionId}`,{system_id : this.context.userSystemId});
                        swal({
                            text: "Deleted Successfully",
                            icon: "success",
                        });
                    }
                    catch (err) {
                        swal({
                            text: "Failed to delete!",
                            icon: "error",
                        });
                        console.error('Failed to delete Amendment: ', err);
                    }
                    this.getAmendmentList();
                }
            });
    }
     getAmendmentList=async()=>{
            let {submissionId}=this.props;
            let {source}=this.state;
            source=[];
            this.setState({showLoader:true});
            let response=await API.get(`${this.APIUrls.LIST}${submissionId}`);
            if(response&&response.data){
                let tem_data=response.data;
                source= tem_data.map((item,index)=>{
                    let {reviewers,id,protocol_version_number,protocol_version_date,version_state,decision_date}=item;
                    let version_date=protocol_version_date?iirDateFormatEx(protocol_version_date):"";
                    let reviewersList=[];
                    reviewers.forEach(p=>{
                        if(p.reviewers_details){
                            reviewersList=[...reviewersList,...p.reviewers_details];
                        }
                       
                    })
                    let submittedList = reviewersList.filter(p => p.review_status === "SUBMITTED").length;
                    let supported = `${submittedList} / ${reviewersList.length}`;
                    let supportedCsv = `${submittedList} out of ${reviewersList.length}`;
                    return {id,version_date,protocol_version_number,supported,supportedCsv,version_state};
                });

            }
            this.setState({source:[...source],showLoader:false});
     } 
    renderPageIcons=()=>{
        let is_coordinator=isCoordinator(this.context);       
       
        return is_coordinator&&<div className="d-flex align-items-center justify-content-center">
            <DTAButton  icon="ADD" onClick={e=>{
                this.setState({showAmendmentForm:true});
            }} text={"ADD AMENDMENT"}></DTAButton>
        </div>
    }
    handleEvents=(e)=>{
        switch(e.option)
        {
            case 'ONCLOSE':this.setState({showAmendmentForm:false,amendment_id:null}, this.getAmendmentList);break;
            case 'SHOWAMENDMENT':this.setState({showAmendmentForm:true,amendment_id:e.data.id});break;
        }
    }
    updateAmendment = async (oldValue, newValue, id) => {
        if(oldValue !== newValue){
            this.setState({showLoader:true});
            try{
                const res = await API.put('amendment/update', {
                    updated_by:this.context.userSystemId,
                    id,
                    version_state: newValue,
                });
                if(res && res.data){
                    this.getAmendmentList();
                }
            }
            catch(exp){console.error(exp);}
            finally{
                this.setState({showLoader:false});
            }
        }
    }
    render(){
        let {source,showLoader,showAmendmentForm,amendment_id}=this.state;
        return <div  className="amendment_table"><DatatTableEx
        id="amendment_list"
        data={source}
        columns={this.columns}
        pagination="true"
        titleClass="title-lg"
       
        autoFilter={true}
        showLoader={showLoader}
        pageIcons={ this.renderPageIcons()}
        rowEvents={
           {
            onClick: (e, row, rowIndex) => {
                this.handleEvents({option:'SHOWAMENDMENT',data:{id:row["id"]}});
              }
           }
        }   
        csvName="amendment_list"
        />
        {showAmendmentForm&&<AmendmentModalEx2 amendment_id={amendment_id} onClose={e=>this.handleEvents({option:'ONCLOSE',data:e})} submissionId={this.props.submissionId}></AmendmentModalEx2>}
        </div>
    }
}
