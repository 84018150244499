import React from "react";
import DatatTableEx from './../../common/DataTableEx';
import ToolTipEx from './../../common/ToolTipEx';
import { FINANCE } from './../../../TooltipInfo';
import { isCoordinator, showMessage} from './../../ToReviewDetails/commonFunctions';
import API from './../../../api/apis';
import UserContext from './../../../context/UserContext';
import Dropdown from 'react-bootstrap/Dropdown';
import swal from 'sweetalert';
import { dateTimeformat } from './../../../utility/helperFunctions';
import ExpenseDetails from "./ExpenseDetails";
import {eventType,titleType} from './ExpenseDetails/constTypes'
import { yyyymmddFormat,formatDate } from "../../common/dateFormat";
import { OverlayTrigger } from "react-bootstrap";
import DTAButton from "../../common/Button/DTAButton";
import { formatNumber } from "../FormatNumber";
import ExpenseEntrySelectModal from "../ExpenseEntrySelectModal";
import { GLOBAL_DATE_FORMAT_NOTIME } from "../../../utility/dateHelper";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div     
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
export default class MilestoneExpenses extends React.Component{
    constructor(props){
        super(props);
    }
    static contextType = UserContext;
    state = {
        filterSource:[],
        milestoneExpensesData:[],
        showLoader: false,
        showWindow:{
            key:null,
            show:false
        },
        showOptionWindow:false
    }
    columns = [
        
        {
            dataField: 'trial_expense_name',
            text: 'id',
            sort: false,
            hidden:true,
            csvExport: false,
            excludeFilter:true
        },
        {
            dataField: 'milestone',
            text: 'Milestone',
            sort: true,
        },
        {
            dataField: 'estimated_date',
            text: 'Estimated Date',
            sort: true,
            sortValue: (cell, row) =>row.estimated_date? new Date(row["estimated_date"]):""
        },
        {
            dataField: 'estimated_cost',
            text: 'Estimated Amount',
            sort: true,
            sortValue: (cell, row) =>+(row.estimated_cost_ext??"0"),            
        },
        {
            dataField: 'date_received',
            text: 'Invoice Received Date',
            sort: true,
            sortValue: (cell, row) =>row.date_received? new Date(row["date_received"]):""
        
        },
        {
            dataField: 'invoice',
            text: 'Invoice Number',
            sort: false,
        },
        // {
        //     dataField: 'actual_amount',
        //     text: 'Actual Amount',
        //     sort: false,
        // },
        { 
            dataField: 'local_currency',
            text: 'Local Currency',
            sort: false,

        },     
        {
            dataField: 'approved_amount',
            text: 'Amount Approved',
            sort: false,
            sortValue: (cell, row) =>+(row.approved_amount??"0")
        },{
            // dataField: '',
            text: 'Actions',
            sort: false,
            csvExport: false,
            excludeFilter: true,

            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.preventDefault();
                    e.stopPropagation();
                }

            },
            formatter: (cell, row, rowIndex) => {
                let data=row;
                return <div className="d-flex" style={{userSelect:'none'}}>
                    {this.state.is_coordinator && <div>
                        <OverlayTrigger trigger="click" rootClose={true} rootCloseEvent={"click"}
                            placement="bottom" delay={{ show: 700, hide: 0 }}
                            overlay={<ul className="bg-white text-white dropdown-menu show">
                                <li onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.handleEdit(data.trial_expense_name);
                                }} className="text-body font-weight-bold dropdown-item">EDIT</li>
                                <li onClick={e => {
                                    e.preventDefault(); e.stopPropagation();
                                    this.handleDelete(data.trial_expense_name);
                                }}
                                    className="text-danger font-weight-bold dropdown-item">DELETE</li>
                            </ul>}><span className="material-icons">more_vert</span></OverlayTrigger>
                        {/* <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                            <span className="material-icons">
                                more_vert
                            </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="bg-white text-white" >
                                <Dropdown.Item eventKey="1" onClick={e=>{
                                   e.preventDefault(); e.stopPropagation();
                                    this.handleEdit(data.trial_expense_name);}} className="text-body font-weight-bold">EDIT</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={e=>{
                                   e.preventDefault(); e.stopPropagation();
                                    this.handleDelete(data.trial_expense_name);}} className="text-danger font-weight-bold">DELETE</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        
                            
                    </div>}
                </div>
            }
        }
    ];
    componentDidMount () {
        let is_coordinator=isCoordinator(this.context);
        this.setState({ is_coordinator },async ()=>{
            await this.getMilestoneExpensesData();
        });
    }
    // componentDidUpdate () {
    //     if(localStorage.getItem("saveNew")){
    //         this.setState({
    //             showWindow:{
    //                 key:null,
    //                 show:true
    //             }
    //         });
    //         localStorage.removeItem('saveNew');
            
    //     }
    // }
    handleEdit = async (id) => {
        if(!id){
            this.setState({showOptionWindow:true});
        }
        else{
           this.showDetailWindow(id,true);
        }
       
    }
    showDetailWindow=(id,status)=>{
        this.setState({showWindow:{
            key:id||null,
            show:status,           
        }, showOptionWindow:false});
    }
    handleDelete = async (trial_expense_name) => {
        this.setState({showLoader:true});
        
        swal({
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async(willDelete) => {
            if (willDelete) {
                try{

                    const res = await API.post(`finance/trialExpense/delete/${trial_expense_name}`, { updated_by: this.context.userSystemId });
                    await this.showResponseMessages(res);

                }
                catch(exp){
                    console.log('error caught while deleting milestone expense: ',exp);
                    await this.showResponseMessages();
                }
            }
        });
        this.setState({showLoader:false});
    
    }
    showResponseMessages=async (res)=>{
        if (res && res.data?.success) {
            showMessage({ 
                text: "Deleted Successfully",
                icon: "success",
                button: "Ok"
            });
            await this.getMilestoneExpensesData();
        }else{   
            showMessage({ 
                text: "Failed to delete the record!",
                icon: "error",
                button: "Ok"
        });
        }
    }
    getMilestoneExpensesData =  async () => {
        this.setState({showLoader:true});
        try{

            const res = await API.get(`/finance/milestoneExpenses/${this.props.submissionId}`);

            if(res&&res.data){
                let milestoneExpensesData= res.data.map((item,index)=>{
                    let { trial_expense_name,
                    estimated_date,
                    estimated_cost,
                    date_received,
                    approved_amount,
                    local_currency,
                    milestone,
                    invoice,
                    expense_title } = item;
                    
                    estimated_date = estimated_date? formatDate(estimated_date,"YYYY-MM-DD",GLOBAL_DATE_FORMAT_NOTIME):estimated_date;
                    let estimated_cost_ext=estimated_cost??"0";
                    estimated_cost=estimated_cost?formatNumber(estimated_cost,local_currency):estimated_cost;
                    date_received = date_received? formatDate(date_received,"YYYY-MM-DD",GLOBAL_DATE_FORMAT_NOTIME):date_received;
                    milestone = milestone?milestone:expense_title;
                    approved_amount=approved_amount?formatNumber(approved_amount,local_currency):approved_amount
                    return{
                        trial_expense_name,
                        estimated_date,
                        estimated_cost,
                        date_received,
                        approved_amount,
                        local_currency,
                        milestone,
                        invoice,
                        expense_title,
                        estimated_cost_ext
                    }
                });
                this.setState({ milestoneExpensesData: milestoneExpensesData || [], showLoader:false, });
            }
            else{
                this.setState({ milestoneExpensesData: [], showLoader:false, });
            }
        }catch (e){
            console.log(e);
        }
        finally{
            this.setState({showLoader:false});
        }
    }
    handleExternalEvents=(type,data)=>{
        switch(type){
            case eventType['MODAL-CLOSE']:
                this.setState({showWindow:{
                    key:null,
                    show:false
                },showOptionWindow:false},()=>{
                    if(data){
                        this.getMilestoneExpensesData();
                    }
                });
            break;

            case eventType['manualEntryModal']:
                this.showDetailWindow(null,true);
                break;

            // case eventType['SUBMIT-&-NEW']:
            //     this.setState({showWindow:{
            //         key:null,
            //         show:false
            //     }}
            //     ,()=>{
            //         // if(saveNew){
            //         localStorage.setItem("saveNew", true);
                    
        
            //         // }
            //         if(data){
            //             this.getMilestoneExpensesData();
            //         }
            //     }
            //     );
            // break;

        }
    }
    render(){
        let {is_coordinator,milestoneExpensesData,showLoader,showWindow,showOptionWindow}=this.state;
        let {submissionId}=this.props;
        return <>
            <DatatTableEx
                id="milestone_expenses"
                data={milestoneExpensesData}
                columns={this.columns}
                showLoader={showLoader}
                rowEvents={
                    {
                     onClick: (e, row, rowIndex) => {
                        this.handleEdit(row["trial_expense_name"]||null);                           
                       }
                    }
                 }   
                filterChange={()=>{}}
                pageIcons={is_coordinator && <ToolTipEx tooltip={FINANCE.ADD}>
                    <DTAButton onClick={e => {
                        this.handleEdit();
                    }} icon="ADD" text={FINANCE.ADD}></DTAButton>
                   </ToolTipEx>}
                autoFilter={true}
                csvName="Milestone_Expenses"
            />
            
          
            {showOptionWindow&&<ExpenseEntrySelectModal submissionId={submissionId} parentCallback={this.handleExternalEvents} showHideModal={true}></ExpenseEntrySelectModal>}
            {(showWindow.show)&&<ExpenseDetails handleEvents={this.handleExternalEvents} submissionId={submissionId} trial_expense_name={showWindow.key}></ExpenseDetails>}
        </>
    }
}