import React from 'react'
import Tab from '../Tab';
import ProtocolDetailsEx from '../../submission/detailSubComponents/ProtocolDetailsEx';
import ReviewList from '../ReviewList';
import AmendmentList from './AmendmentList';
import ProtocolAmndmentHistory from './ProtocolAmndmentHistory';
export default class Protocol extends React.Component{

    constructor(props){
        super(props);
        this.state={
            tabSource:[
                {key:'Protocol',text:'Protocol Details',isActive:false},
                {key:'Reviews',text:'Reviews',isActive:true},
                {key:'Amendments',text:'Amendments',isActive:false},
                {key:'History',text:'History',isActive:false},
            ]
        }
    }
    formTypes={       
        'Protocol':'Protocol',
        'Reviews':'Reviews',
        'Amendments':'Amendments',
        'History':'History',
    }
    handleEvents=(event,data)=>{
        switch(event){
            case 'TAB-CHANGE':this.handleTabChange(data);break;
        }
    }
    handleTabChange=(data,callback)=>{
       
        let { tabSource } = this.state;
        let activeTab = tabSource.filter(p => p.isActive);
        if (activeTab.length > 0) {
            activeTab[0].isActive = false;
        }
        let current = tabSource.find(p => p.key == data.key);
        current.isActive = true;
        this.setState({ tabSource:[...tabSource] }, () => {
            if (callback) {
                callback();
            }
        });
    }
    
    renderTabComponent=()=>{
        let {tabSource}=this.state;
        let {submissionId}=this.props;
        let activeTab=tabSource.find(p=>p.isActive)||{};       
      
        switch(activeTab.key){
            case this.formTypes.Protocol: return  <ProtocolDetailsEx submissionId={this.props.submissionId} />  ; break;
            case  this.formTypes.Reviews:return  <ReviewList review_type="Protocol" ref={ref=>this.childReviewList=ref} submissionId={submissionId} />
            case  this.formTypes.Amendments:return  <AmendmentList  submissionId={submissionId} />;
            case  this.formTypes.History:return  <ProtocolAmndmentHistory  submissionId={submissionId} />;
            default :return  <div className="p-5 font-weight-bold d-flex align-items-center justify-content-center"><span>Loading...</span></div>;break;
        }
    }
    render(){
        let {tabSource}=this.state;
        return <div className="mt-3">
         <Tab source={tabSource} onChange={this.handleEvents}>           
         </Tab>
         <div>
                {this.renderTabComponent()}  
            </div>
        </div>;
    }


}