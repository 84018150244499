import React from 'react'
import {  Modal, Col, Row } from 'react-bootstrap';
import API from '../../../../api/apis';
import { getUserInfo, dateTimeformat, dateStringformat, dateChange } from '../../../../utility/helperFunctions';
import InputField from '../../../common/input-fields/InputField';
import { yyyymmddFormat } from '../../../common/dateFormat';
import Loader from '../../../common/loader/Loader';
import DrugOptionsEx from './DrugOptionsEx';
import UserContext from '../../../../context/UserContext';
import { isCoordinator, showMessage } from '../../../ToReviewDetails/commonFunctions';
import LoaderEx from '../../../common/loader/LoaderEx';
import DDFiles from './DDFiles';
import HistoryFile from '../HistoryFile';
import HistoryList from './HistoryList';
import Button from '../../../common/Button';

class DrugDeliverySiteModalEx extends React.Component {
    static contextType=UserContext;
    state = {
        compoundList: [],
        hasChanged: false,
        countryList: [],
        stateList: [],
        submission: `Submission- ${this.props.submissionId}`,
        submission_id: this.props.submissionId,
        name:"",
        contact_name:"",
        phone_number:"",
        street_address:"",
        email_address:"",
        city:"",
        country_id:"",
        stateid:"",
        zip_postal_code:"",
        address_type:"DRGSUP",
        type_of_material_provided:this.props.type_of_material_provided,
        isLoader:true,
        irb_erb_start_date:null,
        irb_erb_expiration_date:null,
        impact_site:null
    }
 async componentDidMount() {
    await this.getCountryList();
        const { viewEditData, viewEditFlag } = this.props;
        let state=await this.getSites();
       
         if (this.props.id) {
            this.setState({
                //set Your form state details here
                ...this.state,
                ...state,
                submission: state.submission?.id ? `Submission- ${state.submission?.id}` : this.state.submission,
                isLoader:false

            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
    }
    getSites=async()=>{
        if(this.props.id){
            const res = await API.get("sites/newWorkFlow/drugdeliverysites/getbyid/"+this.props.id);
            let sourceData=res.data;
            if(sourceData&&sourceData.success){
                let source=sourceData.data.drugDeliverySitesData||{};
                source["irb_erb_start_date"] = source.irb_erb_start_date != "" && source.irb_erb_start_date != null ?
                dateStringformat(source.irb_erb_start_date) : null;
                source["irb_erb_expiration_date"] = source.irb_erb_expiration_date != "" && source.irb_erb_expiration_date != null ?
                dateStringformat(source.irb_erb_expiration_date) : null;
                source["createdby"] =source.created_by?.length ?  await getUserInfo(source.created_by) + "  " + dateTimeformat(source.createdAt): "";
                source["updatedby"] =source.updated_by?.length > 0 ? await getUserInfo(source.updated_by) + "  " + dateTimeformat(source.updatedAt):source.createdby ;
                source["countryId"] = this.state.countryList.filter(item => item.value === source.country_id)[0];
                source["countryid"]= source["countryId"]?.name;
                await this.getStateList(source["countryid"]);
                source["stateid"] =this.state.stateList.filter(item => item.value === source.state_province_id)[0];
                let siteDrugOptionsData=sourceData.data.siteDrugOptionsData||[];
                let siteAuditData=sourceData.data.siteAuditData||[];
                if(siteDrugOptionsData){
                    let siteDrugOptionsDataSource=  siteDrugOptionsData.map((item)=>{
                        let {id,name,site_id,compound,strength,presentation,count,is_deleted}=item;
                        return {id,name,site_id,compound,strength,presentation,count,is_deleted};
                    });
                    source.siteDrugOptionsDataSource=siteDrugOptionsDataSource;
                }
                source.siteAuditData=siteAuditData;
                return source;
            }
        }
    }
    get inputFieldsArray() {
        return [{
            label: "Submission *",
            type: "text",
            name: "submission",
            disabled: true
        },
        {
            label: "[EXT] Contact Name *",
            type: "text",
            name: "contact_name",
            helptext:"Please provide the name of the main contact at this site"
        },
        {
            label: "[EXT] Site Name *",
            type: "text",
            name: "name"
        },
        {
            label: "[EXT] Phone Number *",
            type: "text",
            name: "phone_number",
            helptext:"Please provide a phone number to contact this site"
        },
        {
            label: "[EXT] Street Address *",
            type: "text",
            name: "street_address"
        },
        {
            label: "[EXT] Email Address",
            type: "text",
            name: "email_address",
            helptext:"Please provide an email to contact this site"
        },
        {
            label: "[EXT] Street Address 2",
            type: "text",
            name: "ext_street_address_2"
        },
        {
            label: "[EXT] Utilizing ethics approval?",
            type: "checkbox",
            name: "utilizing_central_irb_erb",
            helptext:"Please check if this site does not need its own IRB and will be utilizing a central IRB document"
        },
       {
            label: "[EXT] City *",
            type: "text",
            name: "city"
        },
        {
            label: "[EXT] IRB/ERB Start Date",
            type: "date",
            name: "irb_erb_start_date"
        },
        {
            label: "[EXT] Address Prefix",
            type: "text",
            name: "ext_address_prefix"
        },
        {
            label: "[EXT] IRB/ERB Expiration Date",
            type: "date",
            name: "irb_erb_expiration_date"
        },
        {
            label: "[EXT] Country *",
            type: "searchableDropdown",
            name: "country_id",
            options: this.state.countryList,
            onSelect: this.onCountryChange
        },
        {
            label: "Type of Material Provided",
            type: "text",
            name: "type_of_material_provided",
            disabled: true
        },
        {
            label: "[EXT] State/Province *",
            type: "searchableDropdown",
            name: "stateid",
            options: this.state.stateList,
            onSelect: this.onSearchSelect
        },
        {
            label: "Site # *",
            type: "text",
            name: "impact_site",
            maxlength:5,   
            helptext:'Please enter up to 5 integers only.',
            onDrop:(e)=>{
                e.preventDefault();
                return false;
            } ,   
            onPaste:(e)=>{
                e.preventDefault();
                return false;
            },     
            onBlur:async()=>{
                await this.isValidImpactSite();
            },
            onKeyPress:(e)=>{
                /*Allowing only Digits */
                var regex = new RegExp("^[0-9]+$");
                var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                if (regex.test(str)) {
                    return true;
                }            
                e.preventDefault();
                return false;
            }
        },
       
        {
            label: "[EXT] Zip/Postal Code *",
            type: "text",
            name: "zip_postal_code",
        }, {
            label: "Address Type",
            type: "text",
            name: "address_type",
            disabled:true,
           
        },
        {
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
        },

        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        },
        {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        }
        ];
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/getall");
        const resultArray = (res.data?.data||[]).map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ countryList: resultArray });

    }
    isValidImpactSite=async(returnError)=>{
        let is_valid=true;
        let {submissionId,viewEditData}=this.props;
        let {impact_site,country_id,customError,error}=this.state;
        if(!impact_site||impact_site.toString().trim().length<=0){
            if(returnError){
                return {is_valid:true}
            }
            return true;
        }
        if(!customError){
            customError={};
        }
        if(!error){
            error={};
        }
       
        let inputData={
            submission_id: submissionId,
            country_id,
            impact_site:impact_site.toString().trim(),
            id:this.state.id||null
        };
        const res = await API.post("/sites/drugdeliverysites/impactSite/validation",inputData);
        if(res&&res.data){
            if(res.data.status===true){
                customError={};
            }
            else{
                is_valid=false;
                customError={impact_site:"Site # is already exist."};
                if(error["impact_site"]){
                    delete error["impact_site"];
                }
            }
        }
        if(isNaN(impact_site.trim())||impact_site.trim().length>5){
            customError={impact_site:"Please enter up to 5 integers only."};
            is_valid=false;
        }
        if(returnError){
            return {is_valid,customError:{...customError},error:{...error}}
        }
        else{
            this.setState({customError:{...customError},error:{...error}});
        }
        return is_valid;
    }
    getStateList = async (country) => {
         if (country) {
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ stateList: resultArray });
    }}

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            country_id: e.value,
            stateid: ''
        },()=>{
            this.isValidImpactSite();
        });
       
            this.getStateList(e.name)

    }

    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
                hasChanged: true
            });
        }
        else 
        {const { name, value} = e.target;
    this.setState({
        [name]: value,
        patchObject:  {...this.state.patchObject, [name]: value},
        hasChanged: true
    });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = async() => {
        let is_valid = true;
        const statecopy = { ...this.state };
                delete statecopy.compoundList;
                delete statecopy.stateList;
                delete statecopy.countryList;
                delete statecopy.hasChanged;
                delete statecopy.error;
             const   initialValues={
                   name:this.state.name,
                   contact_name:this.state.contact_name,
                   phone_number:this.state.phone_number,
                   street_address:this.state.street_address,
                //    email_address:this.state.email_address,
                   city:this.state.city,
                   country_id:this.state.country_id,
                   stateid:this.state.stateid,
                   zip_postal_code:this.state.zip_postal_code,
                   impact_site:this.state.impact_site
                  }
                  const errors = {}
                  Object.keys(initialValues).forEach((key) => {
                    if (initialValues[key] === "" || initialValues[key] === null ) {
                        errors[key] = true;
                        is_valid = false;
                        
                    } 
                   
                });
               let customError={};
                let isValidSite = await this.isValidImpactSite(true);
                if (!isValidSite.is_valid) {
                    is_valid = false;
                    errors={...errors,...isValidSite.error};
                    customError={...isValidSite.customError};
                }

                this.setState({
                    error: { ...errors },
                    customError
                });
                return is_valid;

    }
    showLoader=(value)=>{
        this.setState({isLoader:value});
    }
    onSave = async() => {
        try{
            this.showLoader(true);
            let status=await this.formValidation(this.state);
            if(status){
               
                let payload={...JSON.parse(JSON.stringify(this.state))}
              
                delete payload.compoundList;
                delete  payload.stateList;
                delete  payload.countryList;
                delete payload.hasChanged;
                delete payload.patchObject;
                delete payload.stateid;
                delete payload.submission;
                delete payload.error;
                delete payload.customError;
                delete payload.siteDrugOptionsDataSource;
                delete payload.country
                delete payload.countryId
                delete payload.createdAt
                delete payload.created_at
                delete payload.createdby
                delete payload.isLoader
                delete payload.last_modified_by_id
                delete payload.last_modified_date
                // delete payload.last_updated_by
                delete payload.last_activity_date
                delete payload.state
                delete payload.active
                delete payload.createdAt
                delete payload.updatedAt
                delete payload.updatedby
                delete payload.user
                delete payload.created_by;
                delete payload.siteAuditData
                if(this.props.id){
                    payload.updated_by = this.context.userSystemId;
                }
                else{
                    payload.created_by = this.context.userSystemId;
                }
                payload["irb_erb_expiration_date"] = payload.irb_erb_expiration_date != "" && payload.irb_erb_expiration_date != null ?
                dateChange(payload.irb_erb_expiration_date) : null;
                payload["irb_erb_start_date"] = payload.irb_erb_start_date != "" && payload.irb_erb_start_date != null ?
                dateChange(payload.irb_erb_start_date) : null;
                
                let drugOptions=this.drugOptionModal.getdrugOptions();
                let apiPayload={
                    drugDeliverySitesData:payload
                }
                if(drugOptions&&drugOptions.length>0){
                    apiPayload.drugOptionsData=drugOptions;
                }
                try {
                    const res = await API.post("sites/newWorkFlow/drugdeliverysites/upsert",apiPayload);
                    if(res&&res.data.success){
                        let dataSource=res.data.data||{};
                        if(dataSource.drugDeliverySitesData){
                            await this.ddFileItem.uploadFiles(dataSource.drugDeliverySitesData.id||this.props.id);
                        }
                        showMessage({ text: "Saved Successfully", icon: "success", button: "Ok" },()=>{
                            this.props.handleModalShowHide(false);
                        });
    
                    }
                    else{
                        showMessage({ text: "Failed", icon: "danger", button: "Ok" },()=>{});
                    }
                    
                } catch (err) {
                    showMessage({ text: "Failed", icon: "danger", button: "Ok" },()=>{});
                }
            }
        }
        catch(ex){

        }
        finally{
            this.showLoader(false);
        }
        
         
      }
      
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return null;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        customerror={this.state.customError?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox"? field.onChange ||((e)=>this.onInputChange(e,field)): this.checkboxChange}
                    />
                   
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal,  viewEditFlag,id} = this.props;
        const { record_Id,isLoader ,siteAuditData} = this.state;
        return (
            <div>
               
                <Modal show={showHideModal} className='new-flow-modal' size="lg">
                {isLoader&&<LoaderEx></LoaderEx>}
                <Modal.Header className='border-0' >
                        <Modal.Title className="w-100 d-flex justify-content-start font-weight-bold">
                            {id ?
                                `Drug Delivery Site ` : "Add a Drug Delivery Site"}
                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" onClick={e => {
                                handleModalShowHide(false);
                            }} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.renderInputFields()}
                           
                        </Row>
                        <DDFiles ref={ref=>this.ddFileItem=ref} site_id={this.props.id} submission_id={this.props.submissionId}></DDFiles>
                        <Row>
                            <Col md={12}>
                                <DrugOptionsEx site_id={this.props.id||null} source={this.state.siteDrugOptionsDataSource} name={this.state.name} ref={ref=>this.drugOptionModal=ref}></DrugOptionsEx>
                            </Col>
                        </Row>
                        {this.props.id&&<HistoryList historyData={siteAuditData||[]}  historyTitle="Drug Delivery Site History"/>}
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center border-0">
                        <Button style="danger w-100px" className="p-2"  onClick={() => handleModalShowHide(false)}>
                            Cancel
                        </Button>
                        {isCoordinator(this.context)&& <Button style="success w-100px" className="p-2"  onClick={this.onSave}>
                                        {this.props.id?"Save":"Add"}
                     </Button>}

                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

}

export default DrugDeliverySiteModalEx;