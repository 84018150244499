import React, { Component } from 'react'
import { THERAPEUTIC_AREA, REQUEST_TYPE, INDICATIONS } from '../../../consts';
import { Button, Col, Row, Card } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../../common/sticky-nav/StickyNav';
import InputField from '../../common/input-fields/InputField';
// import History from './History';
import DrugDeliverySiteFiles from './DrugDeliverySiteFiles';
import DrugOptionsForSite from './DrugOptionsForSite';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../../common/loader/Loader';
import { getUserInfo, dateTimeformat, enableWFActions } from '../../../utility/helperFunctions';
import { yyyymmddFormat } from '../../common/dateFormat';
import DrugOptionsForSiteModal from './DrugOptionsForSiteModal';
import UserContext from '../../../context/UserContext';
class DrugOptionsDetails extends Component {
    static contextType=UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        countryList: [],
        stateList: [],
        hasChanged: false,
        submissionId: 1,
        compoundList: [],
        strengthList: [],
        presentationList: [],
        countList: [],
        site_Id: this.props.location.state.drugdeliverysiteId
    }
    
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value": "0 Days 0 Hours 20 Mins"
        }

    ];



    get inputFieldsArray() {
        return [{
            type: "alert",
            label: "Information",
            variant: "secondary"
        },
        {
            label: "Compound",
            type: "text",
            name: "compound_name",
            disabled: true,
            // options: this.state.compoundList,
            // onSelect: this.onSearchSelect,

        },
        {
            label: "Site",
            type: "text",
            name: "name",
            disabled: true,
            // options: this.state.siteList,
            // onSelect: this.onSearchSelect,

        },
        {
            label: "Count",
            type: "text",
            name: "count_name",
            disabled: true,
            // options: this.state.countList,
            // onSelect: this.onSearchSelect3,

        },
        {
            label: "Strength",
            type: "text",
            name: "strength_name",
            disabled: true,
            // options: this.state.strengthList,
            // onSelect: this.onSearchSelect1,

        },
        {
            label: "Presentation",
            type: "text",
            name: "presentation_name",
            disabled: true,
            // options: this.state.presentationList,
            // onSelect: this.onSearchSelect2,

        },
        {
            label: "Record ID",
            type: "text",
            name: "id",
            disabled: true
        },
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled: true,
            viewEdit: true
        },
        {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled: true,
            viewEdit: true
        }
        ];
    }

    async componentDidMount() {

        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:   selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
            },
        });
       await this.getCompoundList();
        await this.getStrengthList();
        await this.getPresentationList();
        await this.getCountList();
        await this.getDrugDeliverSiteById(this.props.location.state.drugdeliverysiteId);
         
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:   selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                    },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    getDrugDeliverSiteById = async (id) => {
        try {

            const expenseDetails = await API.get(`/sites/sitedrugoptions/getbyid/${id}`);
            const viewEditData = { ...expenseDetails.data };
            expenseDetails.data["compound"] = this.state.compoundList.filter(item => item.value === expenseDetails.data.compound_id)[0];
            expenseDetails.data["compound_name"] = expenseDetails.data["compound"].name;
            expenseDetails.data["strength"] = this.state.strengthList.filter(item => item.value === expenseDetails.data.strength_id)[0];
            expenseDetails.data["strength_name"] = expenseDetails.data["strength"].name;
            expenseDetails.data["presentation"] = this.state.presentationList.filter(item => item.value === expenseDetails.data.presentation_id)[0];
            expenseDetails.data["presentation_name"] = expenseDetails.data["presentation"].name;
            expenseDetails.data["count"] = this.state.countList.filter(item => item.value === expenseDetails.data.count_id)[0];
            expenseDetails.data["count_name"] = expenseDetails.data["count"].name;
            expenseDetails.data["createdby"] = expenseDetails.data.created_by?.length ? await getUserInfo(expenseDetails.data.created_by) + "  " + dateTimeformat(expenseDetails.data.createdAt) : "";
            expenseDetails.data["updatedby"] = expenseDetails.data.updated_by?.length > 0 ? await getUserInfo(expenseDetails.data.updated_by) + "  " + dateTimeformat(expenseDetails.data.updatedAt):expenseDetails.data.createdby ;
            expenseDetails.data["id"] = "Site-Drug-" + expenseDetails.data.id;
            this.setState({
                ...expenseDetails.data,
                showLoader: false,
                viewEditData: viewEditData
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    getCompoundList = async () => {
        const res = await API.get(`seed/compound/list`)
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState(
            {
                compoundList: resultArray,

            }
        );
    }
    getStrengthList = async () => {
        const res = await API.get(`seed/strength/data`)
        const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id }));
        console.log("strength", resultArray)
        this.setState(
            {
                strengthList: resultArray,

            }
        );
    }
    getPresentationList = async () => {
        const res = await API.get(`seed/presentation/data`)
        const resultArray = (res.data?.data || []).map(elm => ({ name: elm.name, value: elm.id }));

        this.setState(
            {
                presentationList: resultArray,

            }
        );
    }
    getCountList = async () => {
        const res = await API.get(`seed/count/data`)
        const resultArray =(res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id }));
        console.log("count", resultArray)
        this.setState(
            {
                countList: resultArray,

            }
        );
    }

    onInputChange = (e) => {
        this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            compound_id: e.value,

        });
    }
    onSearchSelect1 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            strength_id: e.value,

        });
    }
    onSearchSelect2 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            presentation_id: e.value,

        });
    }
    onSearchSelect3 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            count_id: e.value,

        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
        this.props.history.push(`/drugdeliverysite/details/${this.props.location.state.siteHistoryId}`,{drugdeliverysiteId:this.props.location.state.siteHistoryId})

    }

    submitForm = async () => {
        const drugDeliverySiteObj = {}
        try {
            const res = await API.patch("drugDeliverySite/update", drugDeliverySiteObj);
            this.setState({ successModal: true });
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleDrugOptionsForSiteUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        state.last_modified_by_id = this.context.userSystemId;
        state.site_id = this.props.siteId;


        try {
            const res = await API.post(`/sites/sitedrugoptions/${this.props.siteId}/upsert`, { "siteDrugOptions": { ...state } });

            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getDrugDeliverSiteById(this.props.location.state.drugdeliverysiteId);

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    render() {
        const { siteId, name, showHideModal, viewEditFlag, viewEditData, success_body, errorModal, successModal } = this.state;
        if (!this.state.id)
        return <Loader />
        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                            {" "}{this.state.id}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Drug Options for Site </h4>
                    {this.state.access.edit && enableWFActions()&& <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                {/* <Col md={6}>
                    <Card className="data-cards">
                        <Card.Body>
                            <Tabs defaultActiveKey="files">
                                <Tab eventKey="files" title="Files">
                                    <DrugDeliverySiteFiles />
                                    <hr/>
                                   
                                </Tab>
                                <Tab eventKey="history" title="History">
                                    <History historyData={this.historyData} />
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col> */}
            </Row>
            {
                showHideModal &&
                <DrugOptionsForSiteModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleDrugOptionsForSiteSave={this.handleDrugOptionsForSiteSave}
                    handleDrugOptionsForSiteUpdate={this.handleDrugOptionsForSiteUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                // name={this.props.name}
                />
            }
            {this.state.successModal &&
                <BootstrapModal
                    show={this.state.successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={this.state.errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
        </>
    }
}

export default withRouter(DrugOptionsDetails);
