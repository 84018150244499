import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { Col, Row, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import UserContext from '../../../context/UserContext';
import BootstrapModal from '../../common/modal/BootstrapModal';
import swal from 'sweetalert';
import API from '../../../api/apis';
import PresentationModalEx from './PresentationModalEx';
import Loader from '../loader/Loader';
import ToolTipEx from '../ToolTipEx';
import { PRESENTATION, DEFAULT } from '../../../TooltipInfo';
import DTAButton from '../Button/DTAButton';
import {isCoordinator } from '../../ToReviewDetails/commonFunctions';
import DatatTableEx from '../../common/DataTableEx';

class Presentation extends Component {
    static contextType = UserContext;

    state = {
        presentationList:[],
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access : {
            view : true,
            create : false,
            edit : false,
            delete : false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        isLoading:false,
    }
    columns = [
        {   
            dataField: 'id',
            text: 'id',
            sort: false,
            hidden:true,
            excludeFilter:true,
            csvExport: false,
        },{
        dataField: 'name',
        text: 'Presentation',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    >
                        {row.name}</p>
            </>
        }
        },
        {
            dataField: '',
            text: "Actions",
            csvExport : false,
            excludeFilter:true,
            headerClasses: "width-90",
            headerAlign:'center',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.preventDefault();
                    e.stopPropagation();
                }

            },
            formatter: (cell, row, rowIndex) => {
                return this.state.is_Coordinator? <div className='ml-auto' >
                    <ToolTipEx tooltip={DEFAULT.DELETE} >
                        <DTAButton onClick={() => this.handlePresentationDelete(row)} icon="DELETE" text='DELETE' ></DTAButton>
                    </ToolTipEx>
                    </div> : <></>
            }
    }];
  
    async componentDidMount() {
        let _isCoordinator=isCoordinator(this.context);

        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            is_Coordinator: _isCoordinator,
            access : {
                view : true,
                create : selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit : selectedRole.includes("global_coordinator") ||  selectedRole.includes("administrator")  ,
                delete : selectedRole.includes("administrator")  ||  selectedRole.includes("global_coordinator") ,
            },
            
        },async()=>await this.getPresentationList());
    }
    
        getPresentationList = async () => {
            this.setState({
                isLoading : true, 
            })
            try{
                const res = await API.get(`seed/presentation/data`)
                if(res && res.data && res.data.success)  {   

                    this.setState({ 
                        presentationList: res.data?.data || [], 
                    } );
                }else{ 
                    this.setState({ 
                        presentationList: [], 
                        // errorModal: true
                    });
                }
                
            }
            catch(exp){
                console.log('error: ',exp);
                this.setState({
                    errorModal: true
                });
            }
            finally{
                this.setState({isLoading:false});
            }
        }

    componentDidUpdate(previousProps, previousState) {      
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;

        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {   
            this.setState({
                selectedRole: selectedRole,
                access : {
                    view : true,
                    create : selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit : selectedRole.includes("global_coordinator") ||  selectedRole.includes("administrator")  ,
                    delete : selectedRole.includes("administrator")  ||  selectedRole.includes("global_coordinator") ,
                },
            },()=>{console.log("userRole",this.state.selectedRole)})            
        }
    }
    handleModalShowHide = async (flag, viewEditFlag, row) => {
          if (row && row.id && viewEditFlag === "edit") {
              this.setState({ showHideModal: flag, viewEditFlag, rowId:row.id });
              return true;
          }
          this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {}, rowId: null });
      }
    
    handleExternalEvents=(type,success)=>{
        switch(type){
            case 'ADD':
                if(success){
                    this.setState({
                        success_body: "Created successfully!",
                        successModal: true,
                        showHideModal: false,
                    });
                }else{
                this.setState({ errorModal: true });
            }
            break;
            case 'UPDATE':
                if(success){
                    this.setState({
                        success_body: "Updated successfully!",
                        successModal: true,
                        showHideModal: false,
                    });
                }else{
                this.setState({ errorModal: true });
            }
            break;
        }
    }
    handlePresentationDelete = async (row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    const res = await API.delete(`/seed/presentation/delete/${row.id}`);
                    console.log('deleteres',res)
                    if(res.data.success){
                        swal("Deleted Successfully", {
                            icon: "success",
                          });
                        this.getPresentationList();
                    }else{
                        this.setState({ errorModal: true });
                    }
                }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
            } 
          });
       
    }
    renderPageIcons=()=>{
        return this.state.is_Coordinator &&
                <ToolTipEx tooltip={PRESENTATION.ADD}>
                    <DTAButton onClick={() => this.handleModalShowHide(true, null, {})} icon="ADD" text={PRESENTATION.ADD}></DTAButton>
                </ToolTipEx>
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getPresentationList();
    }
    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal,deleteModal,isLoading } = this.state;
        return <>
            <div className="inpage-content">
            {isLoading && <Loader />}
                {!isLoading &&  <Row>
                    <DatatTableEx 
                    title={<Alert.Heading>
                        <span><i class="bi bi-people" style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                         Presentations</Alert.Heading>}
                         rowEvents={{
                                onClick: (e, row, rowIndex) => {this.handleModalShowHide(true, "edit", row,rowIndex)}
                        }}   
                    id="presentationList" 
                    data={this.state.presentationList} 
                    columns={this.columns}
                    pageIcons={ this.renderPageIcons()}
                    autoFilter={true}
                    filterChange={()=>{}}
                    pagination="true"
                    csvName="Presentation"/>
                </Row>}
            </div>
            
            { showHideModal &&
                <PresentationModalEx
             showHideModal={showHideModal}
             handleModalShowHide={this.handleModalShowHide}
             handlePresentationSave={this.handlePresentationSave}
             handlePresentationUpdate={this.handlePresentationUpdate}
             viewEditFlag={viewEditFlag}
             viewEditData={viewEditData}
            is_Coordinator={this.state.is_Coordinator}
             rowId={this.state.rowId}
            handleEvents={this.handleExternalEvents}
            />}
             {this.state.successModal &&
             <BootstrapModal
                 show={successModal}
                 header="Success"
                 body={success_body}
                 changeShowState={this.changeShowState}
             />
         }
        {this.state.errorModal &&
             <BootstrapModal
                 show={errorModal}
                 variant="danger"
                 header="Ooops!!"
                 body="An error occured! Please try again later!"
                 changeShowState={this.changeShowState}
             />
         } 
      </>
    }
}

export default withRouter(Presentation);