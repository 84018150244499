import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <p className="text-center m-0">
                    Investigator Initiated Research &copy; 
                    {new Date().getFullYear()} Eli Lilly and Company, All Rights Reserved. 
                </p>
            </footer>
        )
    }
}
