import React, { Component } from 'react'
import { THERAPEUTIC_AREA, REQUEST_TYPE, INDICATIONS } from '../../../consts';
import { Button, Col, Row, Card,Form } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../../common/sticky-nav/StickyNav';
import InputField from '../../common/input-fields/InputField';
// import History from './History';
import DrugDeliverySiteFiles from './DrugDeliverySiteFiles';
import DrugOptionsForSite from './DrugOptionsForSite';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../../common/loader/Loader';
import { getUserInfo,dateTimeformat, uploadFile, dateStringformat, dateChange,getSubmissionText, enableWFActions } from '../../../utility/helperFunctions';
import { yyyymmddFormat } from '../../common/dateFormat';
import swal from 'sweetalert';
import FileComponent from '../../common/file-component/FileComponent';
import DrugDeliverySiteModal from './DrugDeliverySiteModal';
import UserContext from '../../../context/UserContext';
import HistoryFile from './HistoryFile';
import axios from 'axios';

class DrugDeliverySiteDetail extends Component {
    static contextType=UserContext
    state = {
        externalurl :  "http://localhost:1337",
        showLoader: false,
        showLoaderUpload: false,
        action: "",
        loadTable: true,
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        countryList: [],
        stateList: [],
        hasChanged: false,
        submissionId: "",
        fileList:[],
        selectedFile: null,
        successFile: false,
        errorFile: false,
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        auditData:[],
        trailData:[],
        siteId:"",
        site_Id:"",
        usersList:[],
        countryAllList:[],
        stateAllList:[],
        irb_erb_start_date:null,
        irb_erb_expiration_date:null
    }
    
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value":"0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value":"0 Days 0 Hours 20 Mins"
        }
       
    ];


   
    get inputFieldsArray() {
        return [{
            label: "Submission *",
            type: "text",
            name: "submission",
            disabled: true
        },
        {
            label: "[EXT] Contact Name *",
            type: "text",
            name: "contact_name",
            helptext:"Please provide the name of the main contact at this site",
            disabled: true
        },
        {
            label: "[EXT] Site Name *",
            type: "text",
            name: "name",
            disabled: true
        },
        {
            label: "[EXT] Phone Number *",
            type: "text",
            name: "phone_number",
            helptext:"Please provide a phone number to contact this site",
            disabled: true
        },
        {
            label: "[EXT] Street Address *",
            type: "text",
            name: "street_address",
            disabled: true
        },
        {
            label: "[EXT] Email Address *",
            type: "text",
            name: "email_address",
            helptext:"Please provide an email to contact this site",
            disabled: true
        },
        {
            label: "[EXT] Street Address 2",
            type: "text",
            name: "ext_street_address_2",
            disabled: true
        },
        {
            label: "[EXT] Utilizing ethics approval?",
            type: "checkbox",
            name: "utilizing_central_irb_erb",
            helptext:"Please check if this site does not need its own IRB and will be utilizing a central IRB document",
            disabled: true
        },
       {
            label: "[EXT] City *",
            type: "text",
            name: "city",
            disabled: true
        },
        {
            label: "[EXT] IRB/ERB Start Date",
            type: "date",
            name: "irb_erb_start_date",
            disabled: true
        },
        {
            label: "[EXT] Address Prefix",
            type: "text",
            name: "ext_address_prefix",
            disabled: true
        },
        {
            label: "[EXT] IRB/ERB Expiration Date",
            type: "date",
            name: "irb_erb_expiration_date",
            disabled: true
        },
        {
            
                label: "[EXT] Country *",
                type: "searchableDropdown",
                name: "country_id",
                options: this.state.countryList,
                onSelect: this.onCountryChange,
            disabled: true
        },
        {
            label: "Type of Material Provided",
            type: "text",
            name: "type_of_material_provided",
            disabled: true
        },
        {
            label: "[EXT] State/Province *",
            type: "searchableDropdown",
            name: "stateid",
            options: this.state.stateList,
            onSelect: this.onSearchSelect,
            disabled: true
        },
        {
            label: "Site #",
            type: "text",
            name: "impact_site",
            disabled: true
        },
        {
            label: "[EXT] Zip/Postal Code *",
            type: "text",
            name: "zip_postal_code",
            disabled: true
        },
        {
            label: "Address Type",
            type: "text",
            name: "address_type",
            disabled: true
        },
       
        {
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
            disabled: true,
        },

        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        },
        {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        }
        ];
    }
    async getExternalUrl() {
        let response ='';
        try {
             response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
        externalurl : (response && response.data) ? response.data.exturl : "http://localhost:1337",
    })
    }
  
    async componentDidMount() {
       await this.getExternalUrl();
       let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            showLoader : true,
            loadTable : true ,
            action : "fetching",
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
        await this.getCountryList();
        await this.getStateList();
        await this.getDrugDeliverSiteById(this.props.match.params.drugdeliverysiteId);     
        await this.getAuditList(this.props.match.params.drugdeliverysiteId);
        await this.fetchWorkflowToggle();
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole !== this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    getDrugDeliverSiteById = async (id) => {
        try {
           
            const expenseDetails = await API.get(`sites/drugdeliverysites/getbyid/${id}`);
            const viewEditData={...expenseDetails.data};
        
            expenseDetails.data["irb_erb_start_date"] = expenseDetails.data.irb_erb_start_date !== "" && expenseDetails.data.irb_erb_start_date !== null ?
            dateStringformat(expenseDetails.data.irb_erb_start_date) : null;
            expenseDetails.data["irb_erb_expiration_date"] = expenseDetails.data.irb_erb_expiration_date !== "" && expenseDetails.data.irb_erb_expiration_date !== null ?
            dateStringformat(expenseDetails.data.irb_erb_expiration_date) : null;
            expenseDetails.data["createdby"] =expenseDetails.data.created_by?.length ?  await getUserInfo(expenseDetails.data.created_by) + "  " + dateTimeformat(expenseDetails.data.createdAt): "";
            expenseDetails.data["updatedby"] =expenseDetails.data.updated_by?.length > 0 ? await getUserInfo(expenseDetails.data.updated_by) + "  " + dateTimeformat(expenseDetails.data.updatedAt):expenseDetails.data.createdby ;
            // expenseDetails.data["last_modified_by_id"] =expenseDetails.data.last_modified_by_id?.length ?  await getUserInfo(expenseDetails.data.last_modified_by_id) + "  " + dateTimeformat(expenseDetails.data.updatedAt) :"";
            
            // expenseDetails.data["countryId"] = this.state.countryList.filter(item => item.value === expenseDetails.data.country_id)[0];
            // expenseDetails.data["countryid"]= expenseDetails.data["countryId"]?.name;
            // await this.getStateList(expenseDetails.data["countryid"]);
            // expenseDetails.data["stateid"] =this.state.stateList.filter(item => item.value === expenseDetails.data.state_province_id)[0];
            // expenseDetails.data["state_name"]= expenseDetails.data["stateid"]?.name;
            expenseDetails.data["countryId"] = this.state.countryList.filter(item => item.value === expenseDetails.data.country_id)[0];
        expenseDetails.data["countryid"]= expenseDetails.data["countryId"]?.name;
        await this.getStateList(expenseDetails.data["countryid"]);
        expenseDetails.data["stateid"] =this.state.stateList.filter(item => item.value === expenseDetails.data.state_province_id)[0];
        
            expenseDetails.data["submission"]=getSubmissionText(expenseDetails.data.submission_id);
            this.state.submissionId=expenseDetails.data.submission_id;
            await this.getFilesList(expenseDetails.data.submission_id);
            this.setState({
                ...expenseDetails.data,
                showLoader: false,
                site_Id:expenseDetails.data.id,
                viewEditData:viewEditData
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/getall");
        const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ countryList: resultArray });

    }

    getStateList = async (country) => {
         if (country) {
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ stateList: resultArray });
    }}

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            country_id: e.value

        });
        
            this.getStateList(e.name)

    }

    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else 
        { this.setState({ hasChanged: true })
        this.setState({ [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({ hasChanged: true })
        this.setState({
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e.name,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    getFilesList = async (submissionId) => {
        try{
            this.setState({
                showLoader: true,
                action: "fetching",
                loadTable: false
            })
            const res = await API.get(`/user/proposals3/getByid/${submissionId}`);
            this.setState({
                loadTable : true ,
                showLoader : false,
                fileList: res.data?.filter(file => file.type === `Site_${this.props.match.params.drugdeliverysiteId}`)
            });
        }catch(e){
            console.log(e);
            this.setState({showLoader : false})

        }
        console.log("fileList",this.state.fileList)
    }
    onFileChange = (e) => {
        this.setState({selectedFile: e.target.files[0]})
    }

    uploadFileFunction = async () => {
        const formData = new FormData();
        formData.append(        
        'file',
        this.state.selectedFile,
        this.state.selectedFile.name
        );
        try{
            this.setState({
                showLoaderUpload : true
            })
            // const res =await API.post(`/user/multiple/upload/Site/${this.state.submissionId}/${this.props.match.params.drugdeliverysiteId}`, formData);
            let fileUrl = `${this.state.externalurl}/fileupload/multiple/Site/${this.state.submissionId}/${this.props.match.params.drugdeliverysiteId}`;
            let res = await axios.post(fileUrl, formData, {
                headers: {                   
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                },
            });
            this.setState({
                selectedFile: null,
                successFile: true,
                fileMessage:"File uploaded!",
                showLoaderUpload : false

            })
          
        }catch(e){
            this.setState({
                selectedFile: null,
                errorFile: true,
                showLoaderUpload : false

            })
            console.log(e);
        }
       
    }
    handleDelete = async (fileName) =>{
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try {
                    this.setState({
                        showLoader : true,
                        action :"delete"
                    })
                    const res = API.post(`/user/multiple/delete/Site/${this.state.submissionId}/${this.props.match.params.drugdeliverysiteId}/${fileName}`, {updated_by : this.context.userSystemId});
                    this.setState({
                        successFile: true,
                        fileMessage: "File deleted!",                        
                        showLoader : false                        
                    })    
                }
                 catch (err) {
                    this.setState({ 
                        showLoader : false,
                        errorModal: true });
                }
              
            } 
          });
           
       
     }

     handleDownload = async (fileName) =>{
        try{
            this.setState({
                showLoader : true,
                action : "download"
            })
            /*const res = await API.get(`/user/multiple/download/getByid/Site/${this.state.submissionId}/${this.props.match.params.drugdeliverysiteId}/${fileName}`,{
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute('download', decodeURIComponent(fileName));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            this.setState({
                showLoader: false,
                successFile: true,
                fileMessage: "File Downloaded Successfully!"
            })*/
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Site/${this.state.submissionId}/${this.props.match.params.drugdeliverysiteId}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                        
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorFile: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        showLoader : false,
                        successFile: true,
                        fileMessage:"File Downloaded Successfully!"
                    })
                });
        }catch(e){
            this.setState({
                showLoader : false,
                errorFile: true
            })
            console.log(e);
        }
     }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={((e)=>this.onInputChange(e,field))}
                    />
                </Col>
            )
        });
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)
       
        this.setState({usersList: res.data});
    }
    getAllCountryList = async () => {
        const res = await API.get("seed/country/getall");
       
        this.setState({ countryAllList: res.data });

    }
    getAllStateList = async () => {
      
            const res = await API.get(`seed/state/list`);
            this.setState({ 
                stateAllList: res.data,
               
            });
        
    }
    getAuditList = async (id) => {
        try {
            let temp = [];
            
            const res = await API.get(`audit/site/${id}`)
            console.log(res)
            await this.getTrailList();
              await this.getUserList();
              await this.getAllCountryList();
              await this.getAllStateList();
            this.state.trailData.map(x => {
                res.data.map(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        if(y.field==="updated_by"){
                           let newValueText=y.new_value!== null ? this.state.usersList.filter(x=> x.system_id===(y.new_value))[0] : "" ;
                        y.new_value=newValueText!=="" ? newValueText?.first_name+" "+newValueText?.last_name : "";
                      let  oldValueText=y.old_value!==null ? this.state.usersList.filter(x=> x.system_id===(y.old_value))[0] :"";
                        y.old_value=oldValueText!=="" ? oldValueText?.first_name+" "+oldValueText?.last_name : "";
                            temp.push(y);
                        }
                        else if(y.field==="country_id")
                        {
                            let newValueText=y.new_value!==null ? this.state.countryAllList.filter(x=> x.id+""===y.new_value)[0] :"" ;
                        y.new_value=newValueText!=="" ? newValueText.name : "";
                      let  oldValueText=y.old_value!== null ? this.state.countryAllList.filter(x=> x.id+""===y.old_value )[0] :"";
                        y.old_value=oldValueText!=="" ? oldValueText.name : "";
                            temp.push(y);
                        }
                        else if(y.field==="state_province_id")
                        {
                            let newValueText=y.new_value!==null ? this.state.stateAllList.filter(x=> x.id+""===y.new_value)[0] :"" ;
                        y.new_value=newValueText!=="" ? newValueText.name : "";
                      let  oldValueText=y.old_value!== null ? this.state.stateAllList.filter(x=> x.id+""===y.old_value )[0] :"";
                        y.old_value=oldValueText!=="" ? oldValueText.name : "";
                            temp.push(y);
                        }
                        else if(y.field==="created_at" ){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):""
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                             temp.push(y);
                         }
                         else if( y.field==="irb_erb_expiration_date" || y.field==="irb_erb_start_date"){
                            y.new_value=y.new_value!== null ? dateStringformat(y.new_value):"";
                            y.old_value=y.old_value!== null ? dateStringformat(y.old_value):"";
                             temp.push(y);
                         }
                   else {
                    y.new_value=(y.new_value);
                    y.old_value=(y.old_value)
                       temp.push(y);}
            
                    }

                })
            })
 
            let sortedReviews = temp.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            this.setState({
                auditData: sortedReviews,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/site`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    onNavOut = () => {
       
        let {is_new_workflow}=this.state;
        let url=`/submissions/${this.state.submission_id}`;
        if(is_new_workflow){
            url=`/toreview/details/${this.state.submission_id}`;
        }
        this.props.history.push(url)
    }

    submitForm = async () => {
        const drugDeliverySiteObj = {}
        try {
            const res = await API.patch("drugDeliverySite/update", drugDeliverySiteObj);
            this.setState({ successModal: true });
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }

fetchWorkflowToggle = async () => {
    try{
      const res = await API.get(`enrollmentOptimization/userSettings/fetch/${this.context.userSystemId}`);
      if(res?.data){
        // localStorage.setItem("is_new_workflow", res?.data?.new_workflow_toggle);
        this.setState({is_new_workflow:res?.data?.new_workflow_toggle});
      }
    }
    catch(e) {
      console.log(e);
    }
  }
    changeShowState = async() => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile:false,
            errorFile:false
        });
       
      await  this.getDrugDeliverSiteById(this.props.match.params.drugdeliverysiteId); 
    this.getAuditList(this.props.match.params.drugdeliverysiteId);
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
}
handleDrugDeliveryUpdate =async (state) => {
    state.updated_by = this.context.userSystemId;
    
    state["irb_erb_expiration_date"] = state.irb_erb_expiration_date !== "" && state.irb_erb_expiration_date !== null ?
    dateChange(state.irb_erb_expiration_date) : null;
    state["irb_erb_start_date"] = state.irb_erb_start_date !== "" && state.irb_erb_start_date !== null ?
    dateChange(state.irb_erb_start_date) : null;
    try {
        const res = await API.post("sites/drugdeliverysites/upsert", {"drugDeliverySitesData": {...state}}
        );
        this.setState({
            success_body: "Updated successfully!",
            successModal: true,
            hasChanged: false,
            showHideModal: false,
            viewEditFlag: null,
            viewEditData: {},
        });
        this.getDrugDeliverSiteById(this.props.match.params.drugdeliverysiteId);     

    } catch (err) {
        this.setState({ errorModal: true });
    }
}
    render() {
        const{showLoaderUpload  , loadTable , action ,  showLoader,name,showHideModal, viewEditFlag, viewEditData, success_body, errorModal,successModal}=this.state;
        if(!this.state.submission)
        return <Loader />
        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                        <i className="bi bi-arrow-left">
                            {" "}{this.state.submission}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Drug Delivery Site </h4>
                    {this.state.access.edit &&enableWFActions()&& <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                               
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6}>
                <Card className="data-cards">
                        <Card.Body>
                            <Tabs defaultActiveKey="files">
                                <Tab eventKey="files" title="File">
                                   {this.state.access.edit&&enableWFActions() && <Row>
                                        <Col sm={8}>
                                            <Form.File
                                                id="custom-file"
                                                className="custom-file-label"
                                                onChange={this.onFileChange}
                                                label={this.state.selectedFile?.name}
                                                custom
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            {showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </Button>}
                                            {!showLoaderUpload && <Button variant="secondary" onClick={()=> uploadFile(this.state.selectedFile,this.state.fileList,this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>}
                                        </Col>
                                    </Row>}
                                    {showLoader &&
                                        <Row>
                                            <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                                                <span className="visually-hidden">File  {action} in progress </span>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </div>

                                        </Row>}
                                    <br />

                                    {this.state.access.edit&&enableWFActions() && <small className="form-text text-muted">*Maximum size for file upload is 2MB.</small>}
                                    <br />
                                    {this.state.fileList.length > 0 && loadTable &&
                                        <FileComponent data={this.state.fileList}
                                         handleDelete={this.handleDelete}
                                         accessDelete = {this.state.access.delete}

                                         handleDownload={this.handleDownload} />
                                    }

                                </Tab>
                                {/* <Tab eventKey="history" title="History">
                                    <History historyData={this.historyData} historyTitle="Trial Expense History" />
                                </Tab> */}
                            </Tabs>
                            <HistoryFile historyData={this.state.auditData}  historyTitle="Drug Delivery Site History"/>

                            

                            {!(this.state.selectedRole.includes("read_only_biomeds") ||this.state.selectedRole.includes("read_only_diabetes") || this.state.selectedRole.includes("read_only_oncology")) && <DrugOptionsForSite submission_id = {this.state.submission_id} submissionId={this.state.submission} siteId={this.state.site_Id} name={this.state.name} />}

                        </Card.Body>
                    </Card>
                    
                    </Col>
                   
                   
            </Row>
            {
                showHideModal &&
                <DrugDeliverySiteModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleDrugDeliverySave={this.handleDrugDeliverySave}
                    handleDrugDeliveryUpdate={this.handleDrugDeliveryUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    submissionId={this.state.submission_id}
                    type_of_material_provided={this.state.type_of_material_provided}
                />
            }
            {this.state.successModal &&
                <BootstrapModal
                    show={this.state.successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={this.state.errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
             {this.state.successFile &&
                    <BootstrapModal 
                        show={this.state.successFile} 
                        header="Success"
                        body={this.state.fileMessage}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorFile &&
                    <BootstrapModal 
                        show={this.state.errorFile} 
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
        </>
    }
}

export default withRouter(DrugDeliverySiteDetail);
