import React,{useState,useEffect} from 'react'
import { Dropdown,Accordion } from 'react-bootstrap';
import { DEFAULT } from '../../../TooltipInfo';
import ToolTipEx from '../ToolTipEx';
import styles from './DataFilter.module.css'
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (    
    <ToolTipEx tooltip={DEFAULT.FILTER}><i ref={ref} onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
     className="bi bi-filter mr-3" style={{ fontSize: '1.5rem',cursor:"pointer" }}></i></ToolTipEx>
  ));
export default({filterSource,filterCallback,activeFilterSelection})=>{
    const [showDropDown,setShowDropDown]=useState({viewItem:''});
    const [selection,setSelection]=useState(activeFilterSelection||[]);
    const [source,setSource]=useState(filterSource||[]);
    useEffect(()=>{
        if(filterCallback){
            filterCallback(selection);
        }
    },[selection]);
    useEffect(()=>{
        if(filterSource){
            setSource(filterSource);
        }
    },[filterSource]);
    const handleSelection=(item,keyField)=>{
        let selectedData=selection.find(p=>p.key===keyField);
        if(selectedData){
          let exist= selectedData.items.findIndex(p=>p===item);        
          if(exist>=0){
            selectedData.items.splice(exist,1);
            if(selectedData.items.length<=0){
                selection.splice(selection.findIndex(p=>p.key===keyField),1);
            }
          }
          else{
            selectedData.items.push(item);
          }
        }
        else{
            let obj=source.find(p=>p.key===keyField);
            selection.push({group:obj.group,key:obj.key,items:[item]});
        }
       
       setSelection([...selection]);
    }   
    const isSelected=(item,keyField)=>{
        let selectedData=selection.find(p=>p.key===keyField);
        if(selectedData){
            let exist= selectedData.items.findIndex(p=>p===item);      
            return exist>=0;
        }
        return false;
    }
    const handleExpand=(item)=>{
        let view=showDropDown.viewItem===item.group?"":item.group;
        setShowDropDown({viewItem:view});
    }
    const handleFilterClear=()=>{
        setSelection([]);
    }
    return <Dropdown>
    <Dropdown.Toggle  as={CustomToggle} id="dropdown-basic">       
    </Dropdown.Toggle>
    <Dropdown.Menu>
        <div className='pl-3 pr-3'>
            <ul className={styles.dropdown_menu} >
                {
                    source && source.map((item,index)=>{
                        return <li key={item.key} className="mb-1" style={{cursor:"pointer",width:'max-content'}}>
                            <div className="d-flex align-items-center">
                            <i onClick={e=>handleExpand(item)} className={(`mr-2 bi bi-chevron-${showDropDown.viewItem==item.group?"up":"down"}`)}></i>
                            <span style={{fontSize:'1rem',fontWeight:'400'}} onClick={e=>handleExpand(item)}>{item.group}
                            </span>

                            </div>

                            {showDropDown.viewItem==item.group&& <ul className={[styles.dropdown_menu_sub].join(" ")} >                                    
                                    {
                                        item.items&&item.items.map((subitem,idx)=>{
                                            return <li  style={{}} className={["pl-4",isSelected(subitem,item.key)?styles.selected:""].join(" ")} onClick={e=>handleSelection(subitem,item.key)} key={subitem+idx}>{subitem}</li>;
                                        })
                                    }
                                </ul>}
                        </li>
                    })
                }

            </ul>
            <div onClick={handleFilterClear} className={`d-flex align-items-center justify-content-center p-1 ${styles.clear_filter}`}>
                    <span >Clear All Filter</span>
            </div>
        </div>
    </Dropdown.Menu>
  </Dropdown>
}