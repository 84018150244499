import React, { Component } from 'react'
import { Button, Card, Col, Container, Form, Modal, Navbar, Row, Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import API from '../api/apis';
import UserContext from '../context/UserContext';
import { dateChange, dateStringformat, dateTimeformat, getUserInfo, uploadFile, enableWFActions } from '../utility/helperFunctions';
import { yyyymmddFormat } from './common/dateFormat';
import FileComponent from './common/file-component/FileComponent';
import InputField from './common/input-fields/InputField';
import Loader from './common/loader/Loader';
import BootstrapModal from './common/modal/BootstrapModal';
import StickyNav from './common/sticky-nav/StickyNav';
import NewPublication from './NewPublication';
import HistoryFile from './submission/detailSubComponents/HistoryFile';
import ReviewFile from './submission/detailSubComponents/section-1/ReviewFile';
import axios from 'axios';

class PublicationDetail extends Component {
    static contextType = UserContext
    state = {
        showLoader: false,
        showLoaderUpload: false,
        action: "",
        externalurl :  "http://localhost:1337",
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        submissionId: "",
        submission_id: "",
        viewEditData: {},
        showHideModal: false,
        viewEditFlag: null,
        fileList: [],
        selectedFile: null,
        successFile: false,
        errorFile: false,
        showLoader: false,
        publicationDetails: {},
        auditData: [],
        trailData: [],
        showTable: false,
        planned_date_of_submission:null,
        date_of_publication:null
    }
    get inputFieldsArray() {
        return [
            {

                label: "Title *",
                type: "textarea",
                name: "title",
                helptext: "Title of Publication",
                disabled: true
            },
            {
                label: "Format",
                type: "dropdown",
                placeholder: "None",
                name: "format",
                disabled: true,
                options: ["None", "Manuscript for Journal", "Abstract", "Poster", "Presentation", "Publication", "Other"],
                helptext: "Format of Publication (Journal Article, Presentation, Interim Report, Abstract/Poster, Final Study Report/Draft Manuscript, etc.)"
            },
            {
                label: "Primary Author",
                type: "text",
                placeholder: "Enter Primary Author",
                name: "primary_author",
                helptext: "Primary Author of Publication",
                disabled: true

            },
            {
                label: "Venue",
                type: "text",
                placeholder: "Enter Venue",
                name: "venue",
                helptext: "Where was the publication presented (New England Journal of Medicine, Conference, etc.)",
                disabled: true

            },
            {
                label: "Review",
                type: "dropdown",
                placeholder: "Select Review",
                name: "review",
                options: ["Not started", "Under Review", "Completed"],
                helptext: "Current review status of publication",
                disabled: true
            },
            {
                label: "Comments",
                type: "textarea",
                placeholder: "Enter Comments",
                name: "comments",
                disabled: true

            },
            {
                label: "Link to Publication",
                type: "textarea",
                placeholder: "Enter Link to Publication",
                name: "link_to_publication",
                disabled: true

            },
            {
                label: "Date of Publication",
                type: "date",
                placeholder: "Enter Date of Publication",
                name: "date_of_publication",
                disabled: true

            },
            {
                label: "Planned Date of Submission",
                type: "date",
                placeholder: "Enter Planned Date of Submission",
                name: "planned_date_of_submission",
                disabled: true

            },
            {
                label: "Submission",
                type: "text",
                placeholder: "Enter Submission",
                name: "submissionId",
                disabled: true

            },
            {
                label: "Lilly Feedback",
                type: "textarea",
                name: "lilly_feedback",
                disabled: true
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
                disabled: true
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled: true
            },
        ];
    }
    async getExternalUrl() {
        let response ='';
        try {
             response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
        externalurl : (response && response.data) ? response.data.exturl : "http://localhost:1337",
    })
    }
  
    async componentDidMount() {
       await this.getExternalUrl();
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            showLoader : true,
            action : "fetching",
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                delete: selectedRole.includes("administrator"),
            },
        });
        await this.getPublicationById(this.props.match.params.publicationId);
        await this.getAuditList(this.props.match.params.publicationId)
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
                    delete: selectedRole.includes("administrator"),
                },
            },
                () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    getPublicationById = async (id) => {
        const res = await API.get(`publication/getbyid/${id}`);
        const viewEditData = { ...res.data };
        res.data["lilly_feedback"]=res.data.lilly_feedback !==null ? res.data.lilly_feedback.replace(/<[^>]+>/g, ''):"";
         res.data["date_of_publication"] = res.data.date_of_publication != "" && res.data.date_of_publication != null ?
            dateStringformat(res.data.date_of_publication) : null;
            res.data["planned_date_of_submission"] = res.data.planned_date_of_submission != "" && res.data.planned_date_of_submission != null ?
            dateStringformat(res.data.planned_date_of_submission) : null;
        res.data["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
        res.data["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt) : res.data.createdby;
        await this.getFilesList(res.data.submission_id);

        console.log(viewEditData)
        this.setState({
            ...res.data,
            publicationDetails: res.data,
            submissionId: "Submission-" + res.data.submission_id,
            submission_id: res.data.submission_id,
            viewEditData: viewEditData,
            showLoader: false
        })
    }
    handlePublicationUpdate = async (state) => {
        delete state.submissionId;
        delete state.created_by;
        state.updated_by = this.context.userSystemId;
        state["date_of_publication"] = state.date_of_publication != "" && state.date_of_publication != null ?
dateChange(state.date_of_publication): null;
        state["planned_date_of_submission"] = state.planned_date_of_submission != "" && state.planned_date_of_submission != null ?
        dateChange(state.planned_date_of_submission) : null;
        try {
            const res = API.put(`publication/update/`, state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })
            this.getPublicationById(this.props.match.params.publicationId)
        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
    }
    getAuditList = async (id) => {
        try {
            const temp = [];
            const res = await API.get(`audit/publication/${id}`)
            console.log(res)
            await this.getTrailList();

            this.state.trailData.forEach(x => {
                res.data.forEach(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        if(y.field==="created_at"){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):""
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                             temp.push(y);
                         }
                         else{
                            y.new_value = (y.new_value);
                            y.old_value = (y.old_value)
                            temp.push(y);
                         }
                        
                    }
                    
                })
            })
            let sortedReviews = temp.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            this.setState({
                auditData: sortedReviews,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/publication`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
    }
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else 
        { this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    getFilesList = async (submission_id) => {
        this.setState({
            showTable: false , 
            action : "fetching",
            showLoader : true
        })
        try {
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            this.setState({
                fileList: res.data?.filter(file => file.type === `Publication_${this.props.match.params.publicationId}`),
                showTable: true,
                showLoader : false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                showLoader : false
            })
        }
    }
    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }

    uploadFileFunction = async () => {
        const formData = new FormData();
        formData.append(
            'file',
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        try {
            this.setState({
                showLoaderUpload :true})
            // const res = await API.post(`/user/multiple/upload/Publication/${this.state.publicationDetails.submission_id}/${this.props.match.params.publicationId}`, formData);
            let fileUrl = `${this.state.externalurl}/fileupload/multiple/Publication/${this.state.publicationDetails.submission_id}/${this.props.match.params.publicationId}`;
            let res = await axios.post(fileUrl, formData, {
                headers: {                   
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                },
            });
            this.setState({
                selectedFile: null,
                successFile: true,
                fileMessage: "File uploaded!",
                showLoaderUpload :false
            })
        } catch (e) {
            this.setState({
                selectedFile: null,
                errorFile: true,
                showLoaderUpload :false

            })
            console.log(e);
        }

    }
    handleDelete = async (fileName) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({
                            showLoader: true,
                            action: "delete"
                        })
                        const res = await API.post(`/user/multiple/delete/Publication/${this.state.publicationDetails.submission_id}/${this.props.match.params.publicationId}/${fileName}`, {updated_by : this.context.userSystemId});
                        
                        this.setState({
                            successFile: true,
                            fileMessage: "File deleted!",                            
                            showLoader : false,
                            
                        })
                    }
                    catch (err) {
                        this.setState({
                            showLoader :false,
                             errorModal: true });
                    }

                }
            });


    }

    handleDownload = async (fileName) => {
        try {
            this.setState({
                showLoader : true , 
                action : "download"
            })
           /* const res = await API.get(`/user/multiple/download/getByid/Publication/${this.state.publicationDetails.submission_id}/${this.props.match.params.publicationId}/${fileName}`,{
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', decodeURIComponent(fileName));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                showLoader :false,
                successFile: true,
                fileMessage: "File Downloaded Successfully!"
            })*/
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Publication/${this.state.publicationDetails.submission_id}/${this.props.match.params.publicationId}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                        
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        showLoader : false,
                        successFile: true,
                        fileMessage:"File Downloaded Successfully!"
                    })
                });
        } catch (e) {
            this.setState({
                showLoader : false,
                errorFile: true
            })
            console.log(e);
        }
    }
    changeShowState = async () => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false
        });
        this.getPublicationById(this.props.match.params.publicationId)
        this.getAuditList(this.props.match.params.publicationId)
    }
    onNavOut = () => {
        window.history.back()
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, showTable ,showLoaderUpload  ,  action ,  showLoader} = this.state;
        if (!this.state.submissionId)
            return <Loader />
        return (
            <>
                <StickyNav>
                    <Navbar expand="sm" className="nav-secondary">
                        <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                            <i className="bi bi-arrow-left">
                            {" "}{this.state.submissionId}
                            </i>
                        </div>
                        <h4 className="text-center" style={{ "width": "66%" }}>Publication Detail </h4>
                        {this.state.access.edit && enableWFActions() && <div className="text-right" style={{ "width": "20%" }}>
                            <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                        </div>}
                    </Navbar>
                </StickyNav>
                <Row>
                    <Col md={6}>
                        <Card className="data-cards pt-3">
                            <Card.Body>
                                <Row>
                                    {this.renderInputFields()}
                                    {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} >
                                        Save
                                    </Button>
                                </div> */}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card className="data-cards">
                            <Card.Body>
                                <Tabs defaultActiveKey="files">
                                    <Tab eventKey="files" title="Files">
                                        {this.state.access.edit && enableWFActions() && <Row>
                                            <Col sm={8}>
                                                <Form.File
                                                    id="custom-file"
                                                    className="custom-file-label"
                                                    onChange={this.onFileChange}
                                                    label={this.state.selectedFile?.name}
                                                    custom
                                                />
                                            </Col>
                                            <Col sm={4}>{showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </Button>}
                                                {!showLoaderUpload  && this.state.access.edit &&
                                                    <Button variant="secondary" onClick={() => uploadFile(this.state.selectedFile,this.state.fileList,this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>
                                                }
                                            </Col>
                                        </Row>}
                                        <br />

                                        {enableWFActions() && <small className="form-text text-muted">*Maximum size for file upload is 2MB.</small>}
                                        {showLoader &&
                                            <Row>
                                                <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                                                    <span className="visually-hidden">File {action} in progress </span>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </div>
                                            </Row>}
 <br />
                                        {this.state.fileList.length > 0 && showTable &&
                                            <FileComponent data={this.state.fileList}
                                                accessDelete={this.state.access.delete}

                                                handleDelete={this.handleDelete}
                                                handleDownload={this.handleDownload} />
                                        }

                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                        <Card className="data-cards">
                            <Card.Body>
                                <Tabs defaultActiveKey="history">
                                    <Tab eventKey="history" title="History">

                                        <HistoryFile historyData={this.state.auditData} historyTitle="Publication History" />
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                        <Card className="data-cards">
                            <Card.Body>
                                <Tabs defaultActiveKey="history">
                                    <Tab eventKey="history" title="Review">

                                        <ReviewFile submissionId={this.state.submission_id} Type="Publication" />
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {showHideModal &&
                    <NewPublication
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handlePublicationSave={this.handlePublicationSave}
                        handlePublicationUpdate={this.handlePublicationUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}


                    />}
                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body="Publication has been updated!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.successFile &&
                    <BootstrapModal
                        show={this.state.successFile}
                        header="Success"
                        body={this.state.fileMessage}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorFile &&
                    <BootstrapModal
                        show={this.state.errorFile}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </>
        )
    }
}


export default withRouter(PublicationDetail);