import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import API from '../../../api/apis';
import InputField from '../input-fields/InputField';
import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';
import Loader from '../loader/Loader';

class CompoundModal extends React.Component {
    state = {
        compoundList: [],
        hasChanged: false,
        countryList: [],
        stateList: [],
        name:"",
        therapeutic_area:"",
        request_type:"",
        userList:[],
        ppm:"",
        ptl:"",
        isLoader:true,
        active:false,
        is_retired:false
    }
 async componentDidMount() {
    await this.getCountryList();
    await this.getUserList();
        const { viewEditData, viewEditFlag } = this.props;
      if (viewEditFlag === "edit") {
        viewEditData["createdby"] =viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by)+"  "+ dateTimeformat(viewEditData.createdAt) : "";

        viewEditData["updatedby"] =viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
        // viewEditData["ptlName"] =this.state.userList.filter(elm=>elm.system_id===viewEditData.ptl)[0];
        // viewEditData["ppmName"] =this.state.userList.filter(elm=>elm.system_id===viewEditData.ppm)[0];
            this.setState({
                //set Your form state details here
                ...this.state,
                ...viewEditData,
                isLoader:false
            })
        }
        else {
            this.setState({
                isLoader:false,
               
            })
        }
    }

    get inputFieldsArray() {
        return [{
            label: "Information ",
            type: "alert",
           varient:"secondary"
        },
        {
            label: "Compound Name *",
            type: "text",
            name: "name",
           
        },
        {
            label: "Therapeutic Area *",
            type: "dropdown",
            name: "therapeutic_area",
            options: [
                
                "None",
                "Oncology",
                "Diabetes",
                "Immunology",
                "Neurodegenerative Diseases",
                "Headache & Pain"
               
            ]
           
        },
        // {
        //     label: "Request Type *",
        //     type: "dropdown",
        //     name: "request_type",
        //     options: [
        //         "None",
        //         "Consortium Collaboration",
        //         "Diagnostic Research",
        //         "Translational Research",
        //         "ESCR",
        //         "ExIST",
        //         "IIT",
        //         "Industry Sponsored",
        //         "Pre-clinical"
        //     ]
        // },
        {
            label: "Sub-Therapeutic Area",
            type: "dropdown",
            name: "sub_therapeutic_area",
            options:[
                "None",
                "Cell Signaling",
                "Diabetes & Metabolic Disease",
                "Generalized Pain & Headache",
                "Immunology Compounds",
                "Immuno-Oncology",
                "Microenvironment",
                "Neurodegenerative Disease & Alzheimer’s"]
           
        },
        {
            label: " Research Project Code",
            type: "text",
            name: "research_project_code",
           
        },
        {
            label: "LY Number",
            type: "text",
            name: "ly_number",
           
        },
        {
            label: "Active to External",
            type: "checkbox",
            name: "active",
           
        },
        {
            label: "Retired",
            type: "checkbox",
            name: "is_retired",
           disabled:true
        },
        // {
        //     label: "Part Of Proposal",
        //     type: "checkbox",
        //     name: "is_part_of_proposal",
           
        // },
        // 
        // {
        //     label: "LSN#",
        //     type: "text",
        //     name: "lsn",
           
        // },
        // {
        //     label: "PTL",
        //     type: "searchableDropdown",
        //     name: "ptlName",
        //     options: this.state.userList,
        //     onSelect: this.onSearchSelect
           
        // },
        // {
        //     label: "PPM",
        //     type: "searchableDropdown",
        //     name: "ppmName",
        //     options: this.state.userList,
        //     onSelect: this.onSearchSelect1
        // },
        {
            label: "Compound Description",
            type: "text",
            name: "compound_description",
           
        },
        {
            label: "Lilly Compound?",
            type: "checkbox",
            name: "is_lilly_compound",
        },
        // {
        //     label: "Research Areas of Current Focus - CM",
        //     type: "textarea",
        //     name: "research_areas_of_current_focus_cm",
           
        // },
        // {
        //     label: "Research Areas of Current Focus - NCM",
        //     type: "textarea",
        //     name: "research_areas_of_current_focus_ncm",
           
        // },
        {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit:true
        }
        ];
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/data");
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ countryList: resultArray });

    }

    getStateList = async (country) => {
        if (country) {
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ stateList: resultArray });
    }}
    getUserList = async () => {
        const res = await API.get(`user/data`)
        const resultArray = res.data.map(elm => ({ 
            name: `${elm.first_name} ${elm.last_name} - ${elm.system_id}`, 
            value: elm.email,
            system_id:elm.system_id
        }));
        resultArray.unshift({name: " - None", value:""});
        // this.setState({usersList: resultArray});
        this.state.userList=resultArray;
    }


    // setUser =async () => {
    //     const {assign_to=""} = this.props.submissionDetails;
    //     this.setState({
    //         assignedTo : this.searchUser(assign_to),
    //         })
    // }

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            country_id: e.value

        });
       
            this.getStateList(e.name)

    }

    onInputChange = (e) => {
        this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
           ptl:e.system_id,
            // stateName:e.name
        });
    }
    onSearchSelect1 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
           ppm:e.system_id,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
             const   initialValues={
                   name:this.state.name,
                   therapeutic_area:this.state.therapeutic_area,
                  //request_type:this.state.request_type,
                    
                  }
                  const errors = {}
                  Object.keys(initialValues).forEach((key) => {
                    if (initialValues[key] === "" || initialValues[key] === null ) {
                        errors[key] = true;
                        is_valid = false;
                        
                    } 
                    this.setState({
                        error:  { ...errors}
                    });
                });
                  
        return is_valid;

    }
    onSave = () => {

        if(this.formValidation(this.state)){
          this.props.handleReforecastSave(this.state);
        }
         
      }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox"? field.onChange ||this.onInputChange: this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleReforecastSave, viewEditFlag, handleReforecastUpdate} = this.props;
        const { record_Id } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg">
                {this.state.isLoader=== true ?
        <Loader /> :  <div> <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit Compound ` : "New Compound"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        
                        {
                                viewEditFlag === "edit" ?
                                    <Button variant="primary" onClick={() => handleReforecastUpdate(this.state)}>
                                        Save
                     </Button> : <Button variant="primary" onClick={this.onSave}>
                                        Save
                    </Button>
    }
                    </Modal.Footer> </div>}
                </Modal>
            </div>
        )
    }

}

export default CompoundModal;