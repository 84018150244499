import React, { Component } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import InputField from '../../../common/input-fields/InputField';
import API from '../../../../api/apis';
import UserContext from '../../../../context/UserContext';

import { getUserInfo, getDateDifferenceMonth, dateTimeformat,dateformat, timeformat } from '../../../../utility/helperFunctions';
import dateFormat from '../../../common/dateFormat';
export default class ApprovalHistoryDetail extends Component {
    static contextType=UserContext
    
    state = {
      
       approvalDetails:[],
       selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
       access: {
           view: true,
           create: false,
           edit: false,
           delete: false,
       },
    }
   async componentDidMount() {
       
       await this.getApprovalHistory(this.props.match.params.approvalId);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator"),
                edit: selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator"),
                    edit:  selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getApprovalHistory = async (id) => {
       
        const res = await API.get(`/audit/approvalhistory/getbyid/${id}`)
        let response=res.data[0];
        response["lastUpdatedAt"]= dateTimeformat(response.updatedAt)
        response["dateSubmitted"]=response.date_submitted?.length ?  dateFormat(response.date_submitted) : "";
        response["submissionId"]="Submission-"+(response.submission_id +"").padStart(4,'0');
        response["assignedTo"]= await getUserInfo(response.assigned_to);
        response["approver"]=await getUserInfo(response.actual_approver);
        response["submitterName"]=await getUserInfo(response.submitter);
        this.setState({
            ...response,
            approvalDetails:response
        }
        );
      
         
    }
   


    get inputFieldsArray() {
       
        return [{
            type: "alert",
            label: "Approval Details",
            variant: "secondary",
            disabled: true
        },
        {
            label: "Status",
            type: "text",
            name: "approval_status",
            disabled: true
        },
         {
            label: "Submitter",
            type: "text",
            name: "submitterName",
            disabled: true
        },
        {
            label: "Date Submitted",
            type: "text",
            name: "dateSubmitted",
            disabled: true
        },
        {
            label: "Actual Approver",
            type: "text",
            name: "approver",
            disabled: true
        },
        {
            label: "Assigned To",
            name: "assignedTo",
            type:"text",
            disabled: true,
        },{
            label: "Record Number",
            name: "submissionId",
            type: "text",
            disabled: true,
            
        },
        {
            label: "Comments",
            type: "textarea",
            name: "comments",
            disabled: true,
        },
        {
            label: "Last Updated At",
            type: "text",
            name: "lastUpdatedAt",
            disabled: true,
        }
        ];
    }

getCompoundList = async () => {
    const res = await API.get(`seed/compound/fulldata`)
    const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id}));
    this.setState(
        { 
            compoundList: resultArray,
           
        }
    );
}
    onInputChange = (e) => {
        this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || this.onInputChange : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.approvalDetails.submission_id}`)
    }

    render() {
        const { handleModalShowHide, showHideModal,viewEditFlag, handleSAEUpdate} = this.props;
        const { sae_Id } = this.state;
      return (
        <Container className="form-page">
        <div onClick={this.onNavOut} className="back-button">
            <i className="bi bi-arrow-left">
            {" Submission-"}{this.state.approvalDetails.submission_id}
                    </i>
        </div>
        <h2 className="text-center">Approval History Details</h2>
        <Row>
                {this.renderInputFields()}
                {/* <TextEditor /> */}
                {/* <div className="w-100 d-flex justify-content-center">
                <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm}>
                    Save
                </Button>
                </div> */}
        </Row>
       
    </Container>
)
    }

}

