import React from 'react'
import Header from './Header';
import UserContext from '../../context/UserContext';
import ReviewList from './ReviewList';
import ConceptList from './ConceptList';
import { getSubmissionText } from './commonFunctions';
import Detail from '../submission/Details'
import styles from './review.module.css'
import API from '../../api/apis';
import TabDetails from './TabDetails';
import Enrollment from '../Enrollment';
import DrugOrder from '../submission/detailSubComponents/section-1/DrugOrder/DrugOrderEx';
import Finance from '../Finance';
export default class ToReviewDetails extends React.Component{
    static contextType = UserContext;
    constructor(props){
        
        super(props);
        
        this.state={
            tabSource:[
                {key:'Details',text:'Details',isActive:false},
                {key:'Finance',text:'Finance',isActive:false},
                {key:'Reviews',text:'Reviews',isActive:false},
                {key:'Concept',text:'Concept',isActive:false},
                {key:'Proposal',text:'Proposal',isActive:false},
                {key:'Protocol',text:'Protocol',isActive:false},                
                {key:'Publication',text:'Publication',isActive:false},
                {key:'Enrollment',text:'Enrollment',isActive:false},
                {key:'Drug Order' ,text:'Drug Order',isActive:false}
            ],
            submissionId:this.props.match.params.submission_id,           
            openInitiateReview:false,
            isLoading:true,
            submissionDetails:null
        };
      
    }
    componentDidMount(){
        this.removeMainTabHighlight();
        this.getSubmissionDetail();
       
        
    }
    getDefaultTabfromUrl=()=>{
        try{
            var params = new URLSearchParams(window.location.search);
            if(params.has("tab")){
               let defaultTab= params.get("tab");
               if(defaultTab&&defaultTab.trim().length>0){
                let {tabSource}=this.state;
                let hasItem=tabSource.find(p=>p.key.toLowerCase()===defaultTab.toLowerCase());
                return hasItem.key;
               }
            }
            
        }
        catch(exp){

        }
        return null;
    }
    removeMainTabHighlight=()=>{
      try{
        let li_nav_toreview=document.getElementById("li_nav_toreview");
        li_nav_toreview.classList.remove("active")
      }
      catch(exp){

      }
    }
    handleEvents=(event,data)=>{
        switch(event){
            case 'TAB-CHANGE':this.handleTabChange(data);break;
        }
    }
    handleTabChange=(data,callback)=>{
        let { tabSource } = this.state;
        let activeTab = tabSource.filter(p => p.isActive);
        if (activeTab.length > 0) {
            activeTab[0].isActive = false;
        }
        let current = tabSource.find(p => p.key == data.key);
        current.isActive = true;
        this.setState({ tabSource }, () => {
            if (callback) {
                callback();
            }
        });
    }
    triggerExternalInitiateReview=(requiredCallBack=true)=>{
        let tabChangeCallBack=()=>{
            window.setTimeout(() => {
                this.childReviewList.handleFormEvents(this.childReviewList.formType.INITIATE_REVIEW);
            }, 200);   
        };

        this.handleTabChange({ key: "Reviews" },requiredCallBack?tabChangeCallBack:null);
        
    }
    handleExternalEvents=(data)=>{
        switch(data.option){
            case "IR":this.triggerExternalInitiateReview();break;
            case "SR":this.triggerExternalInitiateReview(false);break;
        }
    }
    
    getSubmissionDetail = async () => {
        let {submissionId,source_ref}=this.state;
        let  selectedRole= Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            submissionDetails: {},
            isLoading:true
        })
        try {
            let submissionDetails = {};
            if (selectedRole.includes("read_only_oncology")) {
                submissionDetails = await API.post(`submission/getbyarea/${submissionId}`, {
                    area: ["Oncology"]
                });
            }
            else if (selectedRole.includes("read_only_diabetes")) {
                submissionDetails = await API.post(`submission/getbyarea/${submissionId}`, {
                    area: ["Diabetes & Obesity"]
                });
            }
            else if (selectedRole.includes("read_only_biomeds")) {
                submissionDetails = await API.post(`submission/getbyarea/${submissionId}`, {
                    area: [
                        "None",
                        "Immunology",
                        "Neurodegenerative Diseases",
                        "Headache & Pain"
                    ]
                });
            }
            else{
                const res = await API.get(`submission/getbyid/${submissionId}`);
                submissionDetails = (res && res.data && res.data.success) ? res.data : false;
            }
            this.setState({
                //
                submissionDetails: submissionDetails && submissionDetails.data ? submissionDetails.data : "No access",
                isLoading:false
            }, () => {                
                let source_ref=sessionStorage.getItem("flow-req-from");  
                let key="";            
                switch(source_ref||''){
                    case "submission":key="Details";break;
                    case "toreview":key="Reviews";break;
                    default:key="Details";break;

                }
                let defaultTabFromUrl=this.getDefaultTabfromUrl();
                if(defaultTabFromUrl){
                    key=defaultTabFromUrl;
                }
                this.handleTabChange({key});
                   
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                isLoading:false
            })
        }
    }
    EmptyForm=()=>{
        return  <div className="p-5 font-weight-bold d-flex align-items-center justify-content-center"><span className="material-icons mr-2">
        warning
        </span><span>Not Implemented</span></div>;
    }
    renderTabComponent=()=>{
        let {tabSource,submissionId,openInitiateReview}=this.state;
        let currency_id = this.state.submissionDetails.currency_id;
        let activeTab=tabSource.find(p=>p.isActive)||{};        
        switch(activeTab.key){
            case "Details":return <Detail submissionId={submissionId}></Detail>;break;
            case "Finance":return <Finance submissionId={submissionId}></Finance>;break;
            case "Reviews":return <ReviewList  ref={ref=>this.childReviewList=ref} submissionId={submissionId} />;break;
            case "Concept":
            case "Proposal":
            case "Protocol":
            case "Amendment":
            case "Publication":return <TabDetails review_type={activeTab.key} handleExternalEvents={this.handleExternalEvents} submissionId={submissionId}/>;break;
            case "Enrollment":return <Enrollment  submissionId={submissionId} currency_id={currency_id}/>;break;
            case "Drug Order":return  <DrugOrder submissionId={submissionId}/>;break;
            default :return  <div className="p-5 font-weight-bold d-flex align-items-center justify-content-center"><span>Loading...</span></div>;break;
        }
    }
    render(){
        let {tabSource,submissionId,submissionDetails}=this.state;
        let pathInfo={ 
            path:"Submissions",
            hrefPath:"/submissions"
        };
        let flowFrom=sessionStorage.getItem("flow-req-from");
        if(flowFrom&&flowFrom.toLowerCase()==="toreview"){
            pathInfo={
                path:"To Review",
                hrefPath:"/toreview"
            }
        }
        return <div className={styles.review_module}>
            {submissionDetails!=null&&submissionDetails!="No access"&&<>
                <Header history={this.props.history} {...pathInfo} handleEvents={this.handleEvents} tabs={tabSource} title={getSubmissionText(submissionId)}></Header>
                <hr className='m-0 d-print-none'></hr>
                <div>
                    {this.renderTabComponent()}
                </div>
            </>}
            {submissionDetails&&submissionDetails=="No access"&&<>
            <div className='d-flex flex-column p-5 align-items-center shadow justify-content-center'>

                    <span className="material-icons" style={{ fontSize: "20vH", color: "#cb3128" }}>
                        lock_person
                    </span>
                    <h2>ACCESS DENIED</h2>
                    <h4>You are not allowed to access this page.</h4>
                    <h4>Please contact your Business Admin.</h4>
            </div>
            </>}
        </div>
    }
}