import React,{useState,useEffect} from "react";
import Steps from "../../common/Steps/Steps";

export default ({caption,currentStage,onClose})=>{
    const [stageSource,setStageSource]=useState([
        {text:"Add option",key:''},{text:"Import",active:false,key:'STAGE_1'},{text:"Review Csv",key:'STAGE_2'},{text:"Confirm & Add",key:'STAGE_3'}
    ]);
    useEffect(()=>{
        handleStageView();
    },[currentStage])
    const handleStageView=()=>{
        stageSource.forEach(p=>{
            p.active=false;
            if(p.key===currentStage){
                p.active=true; 
            }
        });
        setStageSource([...stageSource]);
    }
    return <div className="d-flex flex-column align-items-center justify-content-center mb-3" >  
     <div className='d-flex justify-content-end align-items-center w-100' >                   
                    <span className="material-icons" onClick={e=>{if(onClose){onClose('BULKMODAL');}}} style={{cursor:'pointer'}}>
                        close
                    </span>
                </div>    
        <Steps gap={"100px"} source={stageSource}></Steps>
        <h5 className="mt-3 font-weight-bold">{caption??""}</h5>
    </div>;
}