import React from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../../../common/input-fields/InputField';
import API from '../../../../../api/apis';
import { yyyymmddFormat } from '../../../../common/dateFormat';

import { getUserInfo, dateTimeformat } from '../../../../../utility/helperFunctions';
import UserContext from '../../../../../context/UserContext';
import Loader from '../../../../common/loader/Loader';

import { dateChange, dateStringformat } from '../../../../../utility/helperFunctions';

class ReviewerModal extends React.Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);

        this.state = {
            review_type: "",
            reviewer_system_id: '',
            usersList: [],
            hasChanged: false,
            submissionId: "Submission-" + this.props.submissionId,
            id: "",
            isLoader: true,
            due_date: new Date()
        }
    }
    async componentDidMount() {
        const { viewEditData, viewEditFlag } = this.props;
        await this.getUserList();
        if (viewEditFlag === "edit") {
            viewEditData["header"] = viewEditData.reviewer_system_id?.length ? await getUserInfo(viewEditData.reviewer_system_id) : "";
            viewEditData["created_by"] = viewEditData.created_by?.length ? await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
            viewEditData["updated_by"] = viewEditData.updated_by?.length ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt) : viewEditData["created_by"];
            viewEditData["due_date"] = viewEditData["due_date"] ? dateStringformat(viewEditData["due_date"]): "";
            this.setState({
                ...this.state,
                ...viewEditData,
                submissionId: "Submission-" + viewEditData.submission_id,
                patchObject: { id: viewEditData["id"] },
                isLoader: false
            })
        }
        else {
            this.setState({
                isLoader: false
            })
        }
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)
        const resultArray = res.data.map(elm => ({
            name: `${elm.first_name} ${elm.last_name} | ${elm.system_id}`,
            value: elm.system_id
        }));
        this.setState({ usersList: resultArray });
    }

    get inputFieldsArray() {
        return [
            {
                label: "Review Type*",
                type: "dropdown",
                placeholder: "Select Review Type",
                name: "review_type",
                options: ["Concept", "Proposal", "Protocol", "Amendment", "Publication"],
            },
            {
                label: "Reviewer*",
                type: "searchableDropdown",
                placeholder: "Select Reviewer",
                name: "reviewer_system_id",
                // customerror:this.state.customerror?.[field.name],
                options: this.state.usersList,
                onSelect: this.onSearchSelect,
                disabled: this.state.id
            },

            {
                label: "Comments",
                type: "textarea",
                name: "comments",
                // disabled :  !(this.context.userSystemId === this.state.reviewer_system_id && this.state.id)
            },
            {
                label: "Due Date *",
                type: "date",
                name: "due_date",
                value: "",
                minDate: new Date()
            },
            {
                label: "Submission *",
                type: "text",
                placeholder: "Select Submission",
                name: "submissionId",
                disabled: true
            },
            {
                label: "Assigned By",
                type: "text",
                name: "created_by",
                className: "createUpdateByfontsize",
                disabled: true,
                edit: true
            }, {
                label: "Last Modified By",
                type: "text",
                name: "updated_by",
                className: "createUpdateByfontsize",
                disabled: true,
                edit: true
            },

        ];
    }

    onInputChange = (e, eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: dateChange(e) },
            });
            return false;
        }
        this.setState({
            hasChanged: true,
             [e.target.name]: e.target.value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.type === "number" ? parseInt(e.target.value) : e.target.value }
        });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },

        });
    }

    onSearchSelect = (e, name) => {
        console.log(e)
        console.log(name)
        this.setState({
            hasChanged: true,

            [name]: e.value,
            patchObject: { ...this.state.patchObject, [name]: e.value },
        });
    }
    handleReviewerUpdateModal = () => {
        if (this.formValidation()) {
        let patchObject = this.state.patchObject;
        this.props.handleReviewerUpdate(patchObject);
        }
    }
    formValidation = () => {
        let is_valid = true;
        if (!this.state.reviewer_system_id) {
            this.setState({
                error: {
                    reviewer_system_id: true
                }
            })
            is_valid = false;
        }
        if (!this.state.review_type) {
            this.setState({
                error: {
                    review_type: true
                }
            })
            is_valid = false;
        }
        if (!this.state.due_date) {
            this.setState({
                error: {
                    due_date: true
                }
            })
            is_valid = false;
        }
        return is_valid
    }
    onSave = async () => {
        let customerror = {};
        if (this.formValidation()) {
            let res = await this.props.handleReviewerSave(this.state);
            console.log("saveres", res)
            if (res.data.error && res.data.error.name.includes("Unique")) {
                customerror["reviewer_system_id"] = "Reviewer should be unique";
                this.setState({
                    customerror: { ...customerror }
                });
            }
        }
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={(e) => this.onInputChange(e,field)}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handleReviewerSave, viewEditFlag, handleReviewerUpdate } = this.props;
        const { id } = this.state;
        return (
            <div>
                <Modal onHide={() => { }} show={showHideModal} size="lg">
                    {this.state.isLoader === true ?
                        <Loader /> : <div>   <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                            <Modal.Title className="w-100 d-flex justify-content-center">
                                {viewEditFlag === "edit" && id ?
                                    `Edit Review - ${this.state.header}` : "New Reviewer"}
                            </Modal.Title>
                        </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    {this.renderInputFields()}
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
                                    Cancel
                                </Button>
                                {
                                    viewEditFlag === "edit" ?
                                        <Button variant="primary" onClick={this.handleReviewerUpdateModal}>
                                            Save
                                        </Button> : <Button variant="primary" onClick={(e) => this.onSave()}>
                                            Save
                                        </Button>
                                }

                            </Modal.Footer> 
                            </div>}
                </Modal>
            </div>
        )
    }

}

export default ReviewerModal;