import React, { Component } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { CLINICAL_TRIAL_PHASE, DOSING_DSCHEDULE } from '../../../consts';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Loader from '../../common/loader/Loader';
import { PROPOSAL_UPDATE } from '../../../mapping';
import UserContext from '../../../context/UserContext';

import History from './section-2/History';
import FileComponent from '../../common/file-component/FileComponent';
import { getUserInfo, getDateDifferenceMonth, dateTimeformat,dateformat,dateStringformat } from '../../../utility/helperFunctions';
export default class SaeDetail extends Component {
    static contextType=UserContext
    state = {
      submissionId:this.props.location.state.submissionId,
       saeDetails:[],
       compoundList:[],
       createdAt:"",
       selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
       access: {
           view: true,
           create: false,
           edit: false,
           delete: false,
       },
    }
    componentDidMount() {
        this.getSae(this.props.location.state.sae_name);
        this.getCompoundList();
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator"),
                edit: selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator"),
                    edit:  selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getSae = async (id) => {
       
        const res = await API.get(`sae/getbyid/${id}`)
        console.log("responsedelegee",res)
        this.setState({
            saeDetails: res.data
        }, () => {this.setProposalFields()}
        );
      
         
    }
    setProposalFields = async () => 
{ await this.getCompoundList()
    let compoundData=this.state.compoundList.filter(x=> x.value===this.state.saeDetails.compound_id)[0]
    this.setState({
   
    // last_updated_by:this.state.delegeeDetails.last_modified_by_id?.length>0 ? await getUserInfo(this.state.delegeeDetails.last_modified_by_id) +"  "+ dateTimeformat(this.state.delegeeDetails.updatedAt) : "",
    sae_name:'SAE-'+ ("000" + this.state.saeDetails.sae_name).slice(-4),
    compound:compoundData.name,
    submitter_name:this.state.saeDetails.submitter_name,
    submissionId:"Submission-" +this.state.saeDetails.submissionId,
    created_by:this.state.saeDetails.created_by?.length>0 ? await getUserInfo(this.state.saeDetails.created_by) +"  "+ dateTimeformat(this.state.saeDetails.createdAt) : "",
    createdAt : dateStringformat(this.state.saeDetails.createdAt),

    update_by:this.state.saeDetails.updated_by?.length>0 ? await getUserInfo(this.state.saeDetails.updated_by) +"  "+ dateTimeformat(this.state.saeDetails.updatedAt) : await getUserInfo(this.state.saeDetails.created_by) +"  "+ dateTimeformat(this.state.saeDetails.createdAt),
    
})

}

    get inputFieldsArray() {
        const { viewEditFlag } = this.props;
        return [{
            type: "alert",
            label: "Information",
            variant: "secondary",
            disabled: true
        }, {
            label: "SAE Name",
            type: "text",
            name: "sae_name",
            disabled: true
        },
        {
            label: "Compound",
            type: "text",
            name: "compound",
            disabled: true
        },
        {
            label: "SAE Reported Date",
            type: "text",
            name: "createdAt",
            disabled: true
        },
        {
            label: "Submitter Name",
            type: "text",
            name: "submitter_name",
            disabled: true
        },
        {
            label: "Submission",
            name: "submissionId",
            type:"text",
            disabled: true,
        },{
            label: "Created By",
            name: "created_by",
            type: "text",
            disabled: true,
            className:"createUpdateByfontsize",
        },{
            label: "Last Modified By",
            type: "text",
            name: "update_by",
            disabled: true,
            className:"createUpdateByfontsize",
        }
        ];
    }

getSaeDetails =()=>{

}
getCompoundList = async () => {
    const res = await API.get(`seed/compound/fulldata`)
    const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id}));
    this.setState(
        { 
            compoundList: resultArray,
           
        }
    );
}
    onInputChange = (e) => {
        this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }
    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.saeDetails.submissionId}`)
    }

    render() {
        const { handleModalShowHide, showHideModal,viewEditFlag, handleSAEUpdate} = this.props;
        const { sae_Id } = this.state;
      return (
        //     <div>
        //         <Modal show={showHideModal} size="lg">
        //             <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
        //                 <Modal.Title>
        //                     {viewEditFlag === "view" ? `Details ${sae_Id}` : `Edit SAE ${sae_Id}`}
        //                 </Modal.Title>
        //             </Modal.Header>
        //             <Modal.Body>
        //                 <Row>
        //                     {this.renderInputFields()}
        //                 </Row>
        //             </Modal.Body>
        //             <Modal.Footer>
        //                 <Button variant="primary" onClick={() => handleModalShowHide(false, null, {})}>
        //                     Cancel
        //                 </Button>
        //                 <Button variant="primary" onClick={() => handleSAEUpdate(this.state)}>
        //                                 Save
        //              </Button>
        //             </Modal.Footer>
        //         </Modal>
        //     </div>
        // )
        
        <Container className="form-page">
        <div onClick={this.onNavOut} className="back-button">
            <i className="bi bi-arrow-left">
            {" "}{this.state.submissionId}
                    </i>
        </div>
        <h2 className="text-center">SAE Details</h2>
        <Row>
                {this.renderInputFields()}
                {/* <TextEditor /> */}
                {/* <div className="w-100 d-flex justify-content-center">
                <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm}>
                    Save
                </Button>
                </div> */}
        </Row>
        {this.state.successModal &&
            <BootstrapModal 
                show={this.state.successModal} 
                header="Success"
                body="Delegee has been created!"
                changeShowState={this.changeShowState}
            />
        }
        {this.state.errorModal &&
            <BootstrapModal 
                show={this.state.errorModal} 
                variant="danger"
                header="Ooops!!"
                body="An error occured! Please try again later!"
                changeShowState={this.changeShowState}
            />
        }
    </Container>
)
    }

}

