import React from 'react'
import { Button,Modal } from 'react-bootstrap'

class BootstrapModal extends React.Component{
    
    state = {
        showHide : true
    }

    handleModalShowHide = () => {
        this.setState({ showHide: !this.state.showHide });
        this.props.changeShowState()
    }

    render(){
        return(
            <div>
                <Modal show={this.state.showHide} style={{zIndex:"9999"}}>
                    <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                    <Modal.Title>
                        {this.props.header}
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.body}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant={this.props.variant} onClick={() => this.handleModalShowHide()}>
                        {this.props.buttonText || "Close"}
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
    
}

export default BootstrapModal;