import React, { Component } from 'react';

import { Button, Col, Nav, Row } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import DatatableComponent from '../../../common/datatable/DatatableComponent';
// import InputField from '../../common/input-fields/InputField';
import API from '../../../../api/apis';
import { dateTimeformat, getUserInfo, enableWFActions } from '../../../../utility/helperFunctions';
import UserContext from '../../../../context/UserContext';
import swal from 'sweetalert';
import BootstrapModal from '../../../common/modal/BootstrapModal';
// import { CLINICAL_TRIAL_PHASE, INDICATIONS, LINE_OF_THERAPY, REQUEST_TYPE, STUDY_PRIORITY, THERAPEUTIC_AREA, TRIAL_CATEGORY_2_STAGE_2_SUBMISSION_STATUS } from '../../../consts';

 class Proposal extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        data: [],
        response: {},
        diagnosticData: [],
        diagnosticResponse: {},
        translationalData:[],
        usersList : []
    }

    async componentDidMount() {
        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator")  ||selectedRole.includes("administrator"),
            },
        });
        await this.getusersList();
        this.getProposal(this.props.submissionDetails.id);
        this.getDiagnosticProposal(this.props.submissionDetails.id);
        this.getTranslationalProposal(this.props.submissionDetails.id);
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:  selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator")  ||selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }


    getProposal = async (submissionid) => {
        const res = await API.get(`proposal/submission/${submissionid}`)
        console.log(res)
        if(res.data && Object.keys(res.data).length>0)
            this.setState({
                response: res.data,
                data: [{
                    response:res.data,
                    '[EXT] Proposal Title': res.data.proposalTitle,
                    'Last Modified Date': dateTimeformat(res.data.updatedAt),
                    'Last Modified By':res.data.last_modified_by!=null ? await this.getUserInfo(res.data.last_modified_by) : await this.getUserInfo(res.data.createdBy),
                    id: res.data.id
                }]
            })
        else {
            this.setState({
                data: []
            })
        }
    }

    getDiagnosticProposal = async (submissionid) => {
        const res = await API.get(`diagnosticProposal/submission/${submissionid}`)
        if(res.data && Object.keys(res.data).length>0)
            this.setState({
                diagnosticResponse: res.data,
                diagnosticData: [{
                    'Diagnostic': `Diagnostic-${res.data.id}`,
                    'Last Modified Date': dateTimeformat(res.data.updatedAt),
                    'Last Modified By':res.data["LastModifiedByID"]!=null ? await this.getUserInfo(res.data["LastModifiedByID"]) : await this.getUserInfo(res.data["createdByID"]),
                    id: res.data.id
                }]
            })
        else {
            this.setState({
                diagnosticData: []
            })
        }
    }
    getusersList = async () => {
        const res = await API.get(`user/data`);       
        this.setState({
          usersList: res.data
        });
      }
      getUserInfo(id){
        const resultArray = this.state.usersList.filter(elm => elm.system_id ===id);
           return resultArray.length > 0 ?  `${resultArray[0].first_name} ${resultArray[0].last_name}` : "";
            
      }
    
    getTranslationalProposal = async (submissionid) => {
        const res = await API.get(`/translationalProposal/submission/${submissionid}`)
       console.log(res)
        if (res.data && Object.keys(res.data).length > 0)
            this.setState({
                translationalData: [{
                    'id': res.data.id,
                    'Last Modified Date': dateTimeformat(res.data.updatedAt),
                    'Last Modified By':res.data.updated_by!=null ? await this.getUserInfo(res.data.updated_by) : await this.getUserInfo(res.data["created_by"]),                    

                }]
            })
        else {
            this.setState({
                translationalData : []
            })
            }
    }

    
    columns = [{
        dataField: '[EXT] Proposal Title',
        text: '[EXT] Proposal Title',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <Nav.Link as={NavLink} to={{pathname:`/proposal/details/${this.state.response.id}`,state: {proposalId: this.state.response.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={(e) => this.handleProposalDetails(row,e)}>{row.response.proposalTitle}</Nav.Link>

            </>
        }
    }, {
        dataField: 'Last Modified Date',
        text: 'Last Modified Date',
        sort: true
    }, {
        dataField: 'Last Modified By',
        text: 'Last Modified By',
        sort: true
    },
    {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
                var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            return <>
                {enableWFActions() && <i className="bi bi-trash-fill" onClick={() => this.handleProposalDelete(row)}
                    style={{visibility: visibilityDelete, fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>}
                
            </>
        }
    }];

    columnsDiagnostic = [{
        dataField: 'Diagnostic',
        text: 'Diagnostic',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <Nav.Link as={NavLink} to={{pathname:`/diagnosticProposal/details/${this.state.diagnosticResponse.id}`,state: {diagnosticId: this.state.diagnosticResponse.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}

                    onClick={(e) => this.handleDiagnosticDetails(row,e)}>{`Diagnostic-${this.state.diagnosticResponse.id}`}</Nav.Link>

            </>
        }
    },
     {
        dataField: 'Last Modified Date',
        text: 'Last Modified Date',
        sort: true
    }, {
        dataField: 'Last Modified By',
        text: 'Last Modified By',
        sort: true
    },
    {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
                var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            return <>
                {enableWFActions() && <i className="bi bi-trash-fill" onClick={() => this.handleDiagnosticDelete(row)}
                    style={{visibility: visibilityDelete, fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>}
                
            </>
        }
    }];

    columnsTranslation = [{
        dataField: 'id',
        text: 'Translational',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <Nav.Link as={NavLink} to={{pathname:`/translation/details/${row.id}`, state: { submissionId: this.props.submissionDetails.id,therapeuticArea:this.props.submissionDetails.concepts[0] && this.props.submissionDetails.concepts[0].therapeutic_area }}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={(e) => this.handleTranslationDetails(row,e)}>{`Translational-${row.id}`}</Nav.Link>

            </>
        }
    },
        {
            dataField: 'Last Modified Date',
            text: 'Last Modified Date',
            sort: true
        }, {
            dataField: 'Last Modified By',
            text: 'Last Modified By',
            sort: true
        },
        {
            dataField: '',
            text: "Actions",
            align: 'center',
            headerClasses: "width-90",
            formatter: (cell, row, rowIndex) => {
                    var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
                return <>
                    {enableWFActions() && <i className="bi bi-trash-fill" onClick={() => this.handleTranslationalDelete(row)}
                        style={{visibility: visibilityDelete, fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>}
                    
                </>
            }
        }
    ];
    handleProposalDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`recycle/proposal/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getProposal(this.props.submissionDetails.id);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getProposal(this.props.submissionDetails.id)
                }
            });
    }
    handleDiagnosticDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`recycle/diagnostic/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getDiagnosticProposal(this.props.submissionDetails.id);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getDiagnosticProposal(this.props.submissionDetails.id)
                }
            });
    }
    handleTranslationalDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`recycle/translational/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getTranslationalProposal(this.props.submissionDetails.id);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getTranslationalProposal(this.props.submissionDetails.id)
                }
            });
    }
    handleProposalDetails =(row) => {
        //TODO 
        //MAKE SURE ROW VALUE REMAIN SAMES WHEN WE SEARCH FOR PARTICULAR RECORDS
        this.props.history.push(`/proposal/details/${this.state.response.id}`, {proposalId: this.state.response.id});
    }
    handleDiagnosticDetails =(row) => {
        //TODO 
        //MAKE SURE ROW VALUE REMAIN SAMES WHEN WE SEARCH FOR PARTICULAR RECORDS
        this.props.history.push(`/diagnosticProposal/details/${this.state.diagnosticResponse.id}`, {diagnosticId: this.state.diagnosticResponse.id});
    }
    handleTranslationDetails =(row) => {
        //TODO 
        //MAKE SURE ROW VALUE REMAIN SAMES WHEN WE SEARCH FOR PARTICULAR RECORDS
        this.props.history.push(`/translation/details/${row.id}`, {translationId: row.id,therapeuticArea: this.props.submissionDetails.concepts[0] && this.props.submissionDetails.concepts[0].therapeutic_area });
    }
    render() {
        const { successModal, errorModal, success_body} = this.state;
        return (
            <div>
                <Row>
                   <Col md={6}>
                        <h6 className="table-top"> Proposal ({this.state.data.length})</h6>  
                    </Col>
                    <Col md={2}>                    
                    {enableWFActions() && <Link as={Button} to={{pathname: "/proposal/new", state: { submissionId: this.props.submissionDetails.id }}}>
                        {this.state.access.create && <Button 
                        variant="outline-light" 
                        className="new-button"
                        disabled={this.state.data?.length > 0}
                        >New</Button> }   
                    </Link>}                    
                    </Col>
                    <DatatableComponent id="proposal" data={this.state.data} columns={this.columns} rowEvents={this.rowEvents} />
                   <Col md={6}>
                        <h6 className="table-top"> Diagnostic ({this.state.diagnosticData.length})</h6>  
                    </Col>
                    <Col md={2}>                    
                    {enableWFActions() && <Link as={Button} to={{pathname: "/diagnostic/new", state: { submissionId: this.props.submissionDetails.id }}}>
                       {this.state.access.create && <Button 
                        variant="outline-light" 
                        className="new-button"
                        disabled={this.state.diagnosticData?.length > 0}
                        >New</Button>  }  
                    </Link>}                    
                    </Col>
                    <DatatableComponent id="diagnostic" data={this.state.diagnosticData} columns={this.columnsDiagnostic} rowEvents={this.rowEventsDiagnostic} />
                   
                     <Col md={6}>
                        <h6 className="table-top"> Translational Research ({this.state.translationalData.length})</h6>  
                    </Col>
                    <Col md={2}>                    
                    {enableWFActions() && <Link as={Button} to={{pathname: "/translational/new", state: { submissionId: this.props.submissionDetails.id,therapeuticArea:this.props.submissionDetails.concepts[0] && this.props.submissionDetails.concepts[0].therapeutic_area }}}>
                       {this.state.access.create && <Button 
                        variant="outline-light" 
                        className="new-button"
                        disabled={this.state.translationalData?.length > 0}
                        >New</Button>  }  
                    </Link>}                    
                    </Col>
                    <DatatableComponent data={this.state.translationalData} columns={this.columnsTranslation}/>
                    
                    
                </Row>
                {this.state.successModal &&
                    <BootstrapModal
                        show={successModal}
                        header="Success"
                        body={success_body}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </div>
        )
    }
}


export default withRouter(Proposal)