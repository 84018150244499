import React, { Component } from 'react'
import { THERAPEUTIC_AREA, REQUEST_TYPE, INDICATIONS } from '../../../consts';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import History from './section-2/History';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../../common/loader/Loader';
import ReviewFile from './section-1/ReviewFile';
import UserContext from '../../../context/UserContext';
import { dateTimeformat } from '../../../utility/helperFunctions';
import HistoryFile from './HistoryFile';
import { enableWFActions } from '../../../utility/helperFunctions';

class ConceptDetail extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        countryList: [],
        compoundList: [],
        conceptDetails: null,
        hasChanged: false,
        organisationList:[],
        primaryOrganisation:{},
        submission_id:"",
        auditData: [],
        trailData: [],
        usersList: [],
    }


    get inputFieldsArray() {
        return  [
        {
            type: "alert",
            label: "Investigator Information",
            variant: "secondary"
        },
        {
            label: "Investigator Name",
            type: "text",
            name:"investigatorName",
        },
        {
            label: "[EXT] Country",
            type: "searchableDropdown",
            name:"extInvestigatorCountry",
            options: this.state.countryList,
            onSelect: (e,eo)=>{this.onCountryChange(e,eo)}
        },
        {
            label: "[EXT] Primary Institution",
            type: "creatable",
            name:"primaryOrganisation",
            options: this.state.organisationList,
            onSelect: this.onSearchSelect
        },
        {
            type: "alert",
            label: "Concept Information",
            variant: "secondary"
        },
        {
            label: "Request Type",
            type: "dropdown",
            placeholder: "Select Request Type",
            name: "requestType",
            options: REQUEST_TYPE
        },
        {
            label: "[EXT] Therapeutic Area",
            type: "dropdown",
            name: "therapeuticArea",
            options: THERAPEUTIC_AREA
        },
        {
            label: "[EXT] Compound",
            type: "searchableDropdown",
            name: "compound",
            options: this.state.compoundList,
            onSelect: this.onSearchSelect
        },
        {
            label: "[EXT] Indication",
            type: "multi-select",
            placeholder: "Select Indication",
            name: "indication",
            options: INDICATIONS,
            disabled: this.state.therapeuticArea !== "Oncology"
        },
        {
            label: "[EXT] Other Indication",
            type: "text",
            placeholder: "Enter Other Indication",
            name: "otherIndication",
        },
        {
            label: "[EXT] Concept Title",
            type: "textarea",
            placeholder: "Concept Title",
            name: "conceptTitle",
        },
        {
            label: "[EXT] Concept Description *",
            type: "textarea",
            maxLength:1000,
            placeholder: "Enter Concept Description",
            name: "conceptDescription",
            helptext:"Maximum length allowed upto 1000 characters"
        },
        {
            label: "Submission",
            type: "text",
            placeholder: "Enter Submission",
            name: "submissionId",
            disabled: true
        }
        ];
    }

    componentDidMount() {
        
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:   selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
   this.getCountryList();   
        this.getOrganizationlist();
        this.getAuditList(this.props.match.params.conceptId)
     }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:   selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getOrganizationlist=async ()=>{
        
        const res = await API.get("/seed/organisation/data")
        const resultArray = (res.data?.data || [] ).map(elm => ({ label: elm.name,value:elm.name}));
        this.setState({ organisationList: resultArray});
    }
    getConceptDetails = async () => {
        const conceptId = this.props.match.params.conceptId;
        if(conceptId){
            const res = await API.get(`concept/getbyid/${conceptId}`);
            this.setState({
                conceptDetails: res.data,
                investigatorName: res.data.investigatorname || "",
                extInvestigatorCountry: this.state.countryList.find(country => country.value === res.data.country) || "",
                extPrimaryInstitution: res.data.primary_institution || "",
                requestType: res.data.request_type || "",
                therapeuticArea: res.data.therapeutic_area || "",
                compound: res.data.compound_id,
                indication: res.data.indication?.trim().split(';').join()?.split(',').map(obj => { return {value: obj,label: obj}})  || "",
                otherIndication: res.data.other_indication || "",
                conceptTitle: res.data.title || "",
                conceptDescription: res.data.description || "",
                submissionId: `Submission-${res.data.submissionId}` || "",
                submission_id:res.data.submissionId,
                primaryOrganisation:{label:res.data.primary_institution,value:res.data.primary_institution}
            }, ()=>this.getCompoundList(this.state.extInvestigatorCountry.value));
        }
    }

    getCountryList = async () => {
        const res = await API.get("seed/country/getall")
        const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({ countryList: resultArray}, this.getConceptDetails);
    }

    getCompoundList = async (e,eo) => {
        const res = await API.get(`seed/getCompoundbyCountry/${e}`)
        const arr = res.data.compounds.filter(elm => elm.therapeutic_area===this.state.therapeuticArea );
        const resultArray = arr.map(elm => ({ name: elm.name, value: elm.Country_Compound.compoundId})) || [];
        this.setState({
            compoundList: resultArray,
            compound: resultArray.find(compound => compound.value == this.state.conceptDetails.compound_id) || "",

       })
        
    }

    onCountryChange = (e,name) => {
        this.setState({hasChanged: true})
        this.setState({[name]: e});
        this.setState({
            compound: "",
            indication: "",
            otherIndication: "",
            extPrimaryInstitution:e.name
        });
        this.getCompoundList(e.value);
    }

    onInputChange = (e) => {
        this.setState({hasChanged: true})
        this.setState({[e.target.name]: e.target.value});
    }

    onSelectChange = (e, eo) => {
        if((eo.name || eo.target.name)==="therapeuticArea")
        {this.setState({[eo.target.name]: e,
            hasChanged: true})
            this.getCompoundList(this.state.extInvestigatorCountry.value);
        }
        else
        {this.setState({hasChanged : true})
        this.setState({
            [eo.name || eo.target.name]: e
        });   }     
    }

    onSearchSelect = (e, name) => {
        this.setState({hasChanged: true})
        this.setState({
            [name]: e,
        }); 
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)

        this.setState({ usersList: res.data });
    }
    getAuditList = async (id) => {
        try {
            const temp = [];
            const res = await API.get(`audit/concept/${id}`)
            console.log(res)
            await this.getTrailList();
            await this.getUserList();
            await this.getCountryList();
            this.state.trailData.forEach(x => {
                res.data.forEach(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        let fieldLabels = ["updated_by"]
                        if (fieldLabels.includes(y.field)) {
                            let newValueText =y.new_value!=null ? this.state.usersList.filter(x => x.system_id === (y.new_value))[0] : "";
                            y.new_value = newValueText!="" ? newValueText?.first_name + " " + newValueText?.last_name : "";
                            let oldValueText =y.old_value!=null ? this.state.usersList.filter(x => x.system_id === (y.old_value))[0] : "";
                            y.old_value =oldValueText!="" ? oldValueText?.first_name + " " + oldValueText?.last_name : "";
                            temp.push(y);
                        }
                        else if(y.field==="country")
                        {
                            let newValueText=y.new_value!=null ? this.state.countryList.filter(x=> x.value+""===(y.new_value))[0] :"" ;
                        y.new_value=newValueText!="" ? newValueText.name : "";
                      let  oldValueText=y.old_value!= null ? this.state.countryList.filter(x=> x.value+""===(y.old_value ))[0] :"";
                        y.old_value=oldValueText!="" ? oldValueText.name :"";
                            temp.push(y);
                        }
                        else if(y.field==="created_at"){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):""
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                             temp.push(y);
                         }
                        else {
                            y.new_value = (y.new_value);
                            y.old_value = (y.old_value)
                            temp.push(y);
                        }

                    }


                })
            })
            let sortedReviews = temp.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            this.setState({
                auditData: sortedReviews,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/concept`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    disabled = {field.disabled ? field.disabled : !this.state.access.edit }
                    value={this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={this.onInputChange }
                />
            </Col>
            )
        });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.submissionId.split("-")[1]}`)
    }

    submitForm = async () =>  {
        const conceptObj = {

            "investigatorname": this.state.investigatorName,
            "country": this.state.extInvestigatorCountry.value,
            "primary_institution": this.state.primaryOrganisation.value,
            "request_type": this.state.requestType,
            "therapeutic_area": this.state.therapeuticArea,
            "compound_id": this.state.compound.value,
            "indication": this.state.therapeuticArea==="Oncology"? (this.state.indication.length > 0 ? this.state.indication.map(obj => obj.value).join(";") : "") : null,
            "other_indication": this.state.otherIndication,
            "title": this.state.conceptTitle,
            "description": this.state.conceptDescription,
            "id": this.props.match.params.conceptId,
            "updated_by": this.context.userSystemId
            
        }
        try {
            const res = await API.patch("concept/update", conceptObj);
            this.setState({successModal: true});
        }catch(err){
            this.setState({errorModal: true});
        }
    }

    
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getAuditList(this.props.match.params.conceptId)
    }
    
    render() {
        if(!this.state.submissionId)
            return <Loader />
        return (
            <Container className="form-page">
                <div onClick={this.onNavOut} className="back-button">
                    <i className="bi bi-arrow-left">
                        {" "}{this.state.submissionId}
                            </i>
                </div>
                <h2 className="text-center">Concept Details</h2>
                <Row>
                        {this.renderInputFields()}
                        <div className="w-100 d-flex justify-content-center">
                        {this.state.access.edit && enableWFActions() && <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                            Save
                        </Button>}
                        </div>
                </Row>
                <Row className="mt-4">
                    <Col sm={12}>
                        <Card body className="p-2">
                            <h3 className="mb-5 text-center">History</h3>

                            <HistoryFile historyData={this.state.auditData} historyTitle="Concept History" />
                            {/* <DatatableComponent data={this.state.auditData} columns={this.columns} rowEvents={this.rowEvents} pagination="true" />   */}
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                <Col sm={12}>
                        <Card body className="p-2">
                            <h3 className="mb-5 text-center">Review</h3>
                           
                           <ReviewFile submissionId={this.state.submission_id}  Type="Concept"/>
                            {/* <DatatableComponent data={this.state.auditData} columns={this.columns} rowEvents={this.rowEvents} pagination="true" />   */}
                        </Card>                     
                    </Col>
                </Row>
               
                
                {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Concept has been Updated!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </Container>
        )
    }
}

export default withRouter(ConceptDetail);
