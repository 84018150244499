import React,{useState,useEffect} from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../../../common/Button';
import InputField from '../../../common/input-fields/InputField'
import { eventType } from './constTypes';

export default({className,events})=>{
    const [milestoneText,setMilestoneText]=useState("");
    const [error,setError]=useState(false);
    const handleEvents=(option,data)=>{
        switch(option){
            case eventType['MODAL-CLOSE']:

                break;
            case eventType['NEW-MILESTONE']:
                if(!milestoneText||milestoneText.trim().length<=0){
                    setError(true);
                    return false;
                }
                break;
        }
        if(events){
            events(option,data);
        }
    }
    return <div className={['p-3 bg-white w-75',className||''].join(" ")}>
        <Row>
            <Col md={12}>
                <InputField error={error} label="Milestone Name" type="text" onChange={e=>{
                    setMilestoneText(e.target.value);
                }} value={milestoneText} maxLength="255"></InputField>                
            </Col>
        </Row>
        <div className='d-flex align-items-center justify-content-center p-2'>
        <Button style='accent' onClick={e=>handleEvents(eventType['MODAL-CLOSE'],null)} className="p-2 pl-3 pr-3 m-1">
                <div className='d-flex align-items-center text-dark'>
                    <span>  CANCEL</span>
                </div>
            </Button>
            <Button style='accent' className="p-2 pl-3 pr-3 m-1" onClick={e=>handleEvents(eventType['NEW-MILESTONE'],milestoneText)} >
                <div className='d-flex align-items-center text-dark'>
                    <span>  SAVE</span>
                </div>
            </Button>
        </div>
    </div>
}
