import React, { Component } from 'react'
import { THERAPEUTIC_AREA, REQUEST_TYPE, INDICATIONS } from '../../../consts';
import { Button, Col, Row, Card } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../../common/sticky-nav/StickyNav';
import InputField from '../../common/input-fields/InputField';
// import History from './History';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { withRouter } from 'react-router';
import Loader from '../../common/loader/Loader';
import UserContext from '../../../context/UserContext';
import { getUserInfo, dateTimeformat, enableWFActions } from '../../../utility/helperFunctions';
import EnrollmentModal from './EnrollmentModal';
import DatatableComponent from '../../common/datatable/DatatableComponent';
import HistoryFile from './HistoryFile';

class EnrollmentDetails extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        hasChanged: false,
        submissionId: "",
        enrollmentId:"",
        // submission: "Submission- "+ this.props.location.state.submissionId,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        enrollmentsDetails: [],        
        max_actual: 0,
        auditData:[],
        trailData:[],
        usersList:[]
    }
   
    historyData = [
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value":"0 Days 0 Hours 20 Mins"
        },
        {
            'date': "5/21/2020 5:45 AM",
            'field': "Concept",
            'user': "User",
            "original_value": "0 Days 0 Hours 20 Mins",
            "new_value":"0 Days 0 Hours 20 Mins"
        }
       
    ];
  

    get inputFieldsArray() {
        return [{
            label: "Baseline Enrollment",
            type: "text",
            name: "baseline_enrollment",
            helptext :"Expected Enrollment for quarter based on initial projection"
        },
        {
            label: "[EXT] Year",
            type: "text",
            name: "year",
            disabled: true,

            options: []
        },
        {
            label: "Estimated Enrollment",
            type: "text",
            name: "estimated_enrollment",
            disabled: true,
            helptext :"Expected Enrollment for quarter based on current projections"

           
        },
        {
            label: "[EXT] Quarter",
            type: "text",
            name: "quarter",
            disabled: true,
            helptext :"Select quarter for enrollment update",

            options: []
        },
        {
            label: "Actual Enrollment",
            type: "text",
            name: "actual_enrollment",
            disabled: true,
            helptext :"Enrollment for quarter"

        },
        {
            label: "Baseline Budget",
            type: "text",
            name: "baseline_budget1",
            disabled: true,
            helptext :"Expected cost of patient enrollment based on initial projections",

            edit:true
        },
        {
            label: "[EXT] Patients Currently on Treatment",
            type: "text",
            name: "patients_currently_on_treatment",
        },
        {
            label: "Estimated Budget",
            type: "text",
            name: "estimated_budget1",
            disabled: true,
            helptext :"Expected cost of patient enrollment based on current projections",
            edit:true
        },
        {
            label: "[EXT] Patients Currently on Followup",
            type: "text",
            name: "patients_currently_on_followup",
        },
        {
            label: "Actual Budget",
            type: "text",
            helptext :"Cost of enrollment for the quarter",
            name: "actual_budget1",
            disabled: true,
            edit:true
        },
        {
            label: "[EXT] Patients Completed Trial",
            type: "text",
            name: "patients_completed_trial",
        },
        {
            label: "Currency",
            type: "text",
            name: "currency",
            disabled: true
        },
        {
            label: "[EXT] Pts Disc. from Treatment",
            type: "text",
            name: "patients_discontinued_from_treatment",
        },
        {
            label: "[EXT] Comments",
            type: "textarea",
            name: "comments",
        },
        {
            label: "[EXT] Total Patients Entered Treatment",
            type: "text",
            name: "total_patients_entered_treatment",
        }, {
            label: "Submission",
            type: "text",
            name: "submission",
            disabled:true
        },  {
            label: "Created By",
            type: "text",
            name: "createdby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        }, {
            label: "Last Modified By",
            type: "text",
            name: "updatedby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        },{
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
            disabled: true,
        },
       
        ];
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false,
        });
    this.getEnrollById(this.props.match.params.enrollmentId);
    this.getAuditList(this.props.match.params.enrollmentId);
    }
    async componentDidMount() {
     
        await this.getCurrencyList();

        await this.getEnrollById(this.props.match.params.enrollmentId);
        // currency= this.state.currencyList.filter(item => item.id === this.state.currency_iso_code)[0];

        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            // currency,
            access: {
                view: true,
                create:  selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        });
        this.getAuditList(this.props.match.params.enrollmentId);

    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
}
getUserList = async () => {
    const res = await API.get(`user/data`)
   
    this.setState({usersList: res.data});
}
getAuditList = async (id) => {
    try {
            const temp = [];
            const res = await API.get(`audit/enrollment/${id}`)
            console.log(res)
            await this.getTrailList();
await this.getUserList();
            this.state.trailData.forEach(x => {
                res.data.forEach(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        let fieldLabels=["updated_by"]
                        if(fieldLabels.includes(y.field)){
                           let newValueText=y.new_value!=null ? this.state.usersList.filter(x=> x.system_id===(y.new_value))[0] : "" ;
                        y.new_value=newValueText!="" ? newValueText?.first_name+" "+newValueText?.last_name : "";
                      let  oldValueText=y.old_value!=null ? this.state.usersList.filter(x=> x.system_id===(y.old_value))[0] : "" ;
                        y.old_value=oldValueText!="" ? oldValueText?.first_name+" "+oldValueText?.last_name : "";
                            temp.push(y);
                        }
                        else if(y.field==="created_at"){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):"";
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                             temp.push(y);
                         }
                     else   {y.new_value=(y.new_value);
                        y.old_value=(y.old_value)
                           temp.push(y);}
                    }

                })
            })
            let sortedReviews = temp.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            this.setState({
                auditData: sortedReviews,
            })

        }
    catch (err) {
        console.log(err)
    }
}
getTrailList = async (id) => {
    try {
        const res = await API.get(`/user/fieldlabels/enrollment`)
        console.log(res)
        this.setState({ trailData: res.data })
    }
    catch (err) {
        console.log(err)
    }
}
    getEnrollById = async (id) => {
        try {
            const enrollDetails = await API.get(`enroll/getbyid/${id}`);
            enrollDetails.data["currency"] = this.state.currencyList.filter(item => item.id === enrollDetails.data.currency_iso_code)[0].value;
            enrollDetails.data["submission"] = "Submission-" + enrollDetails.data.submissionId;
            enrollDetails.data["baseline_budget1"]=enrollDetails.data.baseline_budget !== null ? enrollDetails.data["currency"] +" "+enrollDetails.data.baseline_budget.replace(/\d(?=(\d{3})+\.)/g, '$&,'):""
            enrollDetails.data["estimated_budget1"]=enrollDetails.data.estimated_budget !== null ? enrollDetails.data["currency"] +" "+enrollDetails.data.estimated_budget.replace(/\d(?=(\d{3})+\.)/g, '$&,'):"";
            enrollDetails.data["actual_budget1"]=enrollDetails.data.actual_budget !== null ? enrollDetails.data["currency"] +" "+enrollDetails.data.actual_budget.replace(/\d(?=(\d{3})+\.)/g, '$&,'):"";
            enrollDetails.data["createdby"] = enrollDetails.data.created_by?.length  ? await getUserInfo(enrollDetails.data.created_by) + "  " + dateTimeformat(enrollDetails.data.createdAt) : "";
            enrollDetails.data["updatedby"] =enrollDetails.data.updated_by?.length > 0 ? await getUserInfo(enrollDetails.data.updated_by) + "  " + dateTimeformat(enrollDetails.data.updatedAt):enrollDetails.data.created_by ;
            this.setState({
                ...enrollDetails.data,
                showLoader: false,
                 viewEditData:{ ...enrollDetails.data}
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    getCurrencyList = async () => {
        try {
            const currencyList = await API.get(`seed/currency/data`);
            if(currencyList.data&&currencyList.data.success){
            this.setState({
                currencyList: currencyList.data.data,
                showLoader: false
            })}
            else{
                this.setState({
                    currencyList:[]
                })
            }
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    onCountryChange = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({ [name]: e });
        this.setState({
            compound: "",
            indication: "",
            otherIndication: "",
        });
        this.getCompoundList(e.value);
    }

    onInputChange = (e) => {
        this.setState({ hasChanged: true })
        this.setState({ [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({ hasChanged: true })
        this.setState({
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        disabled= {true}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.state.submission.split("-")[1]}`)
    }

    submitForm = async () => {
        const enrollmentObj = {}
        try {
            const res = await API.patch("enrollment/update", enrollmentObj);
            this.setState({ successModal: true });
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleEnrollmentsUpdate = async (state) => {
        delete state.currencyList;
        delete state.showLoader;
        let res;
        state.updated_by = this.context.userSystemId;
        state.submissionId = this.state.submissionId;
        try {
             res = await API.post(`enroll/update/${state.id}`, state);
            if (res.data.error) {
                res = res.data

            }
            else {
                this.setState({
                    success_body: "Updated successfully!",
                    successModal: true,
                    hasChanged: false,
                    showHideModal: false,
                    viewEditFlag: null,
                    viewEditData: {},
                });
                this.getEnrollById(state.id);
                // this.props.getSubmissionDetail(this.props.submissionDetails.id);

            }
        } catch (err) {
            res = err;

            this.setState({ errorModal: true });
        }
        return res
    }
    getEnrollmentsDetailsBySubmissionId = async (submissionId) => {
        try {
            const mydata = await API.get(`/enroll/submission/${submissionId}`);
            this.setState({
                ...mydata.data,
                enrollmentsDetails: mydata.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
   
    render() {
        const { showHideModal, errorModal , successModal ,success_body, viewEditFlag, max_actual, viewEditData, enrollmentsDetails} = this.state;
        if (!this.state.submission)
            return <Loader />
        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button">
                        <i className="bi bi-arrow-left">
                            {" "}{this.state.submission}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Enrollment - {("000"+this.state.id).slice(-4)} </h4>
                   {this.state.access.edit &&enableWFActions()&& <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav> 
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                                <div className="w-100 d-flex justify-content-center">
                                   
                                </div>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="data-cards">
                        <Card.Body>
                            <Tabs defaultActiveKey="history">
                                <Tab eventKey="history" title="History">
                               
                           <HistoryFile historyData={this.state.auditData}  historyTitle="Enrollment History"/>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {
                showHideModal &&
                <EnrollmentModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleEnrollmentsSave={this.handleEnrollmentsUpdate}
                    handleEnrollmentsUpdate={this.handleEnrollmentsUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    submissionId={this.state.submissionId}
                    max_actual={max_actual}
                    currency_iso_code={viewEditData.currency_iso_code}
                />
            }
            {this.state.successModal &&
                <BootstrapModal
                    show={this.state.successModal}
                    header="Success"
                    body="Enrollment has been Updated!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={this.state.errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
        </>
    }
}

export default withRouter(EnrollmentDetails);
