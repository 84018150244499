import React from "react";
import { isCoordinator, showMessage } from "../../../ToReviewDetails/commonFunctions";
import UserContext from "../../../../context/UserContext";
import API from "../../../../api/apis";
import FileUploader from "../../../ToReviewDetails/FileUploader";
import { Button,Col,Row,Table } from "react-bootstrap";
import swal from "sweetalert";
import ToolTipEx from "../../../common/ToolTipEx";
import { DEFAULT } from "../../../../TooltipInfo";
import axios from "axios";

export default class DDFiles extends React.Component{
    static contextType=UserContext;
    constructor(props){
        super(props);
        this.state={
            uploadedFileList:[],
            s3FileInfo:{}
        }
    }
    componentDidMount(){
        this.getExternalUrl();
    }
    async getExternalUrl() {
        let response = '';
        try {
            response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
            externalurl: (response && response.data) ? response.data.exturl : "http://localhost:1337",
        },()=>{
            this.getFilesList();
        })
    }
    getFilesList = async () => {
        const {submission_id,site_id}=this.props;
        try{
            this.setState({
                showLoader: true
            })
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            let files=res.data?.filter(file => file.type === `Site_${site_id}`);
            
            this.setState({
               
                showLoader : false,
                S3Files: files||[]
            });
        }catch(e){
            console.log(e);
            this.setState({showLoader : false})
        }
       
    }
    uploadFiles=async(id)=>{
        
        let {uploadedFileList}=this.state;
        let {submission_id,site_id}=this.props;
        if(uploadedFileList&&uploadedFileList.length>0){
            let formData = new FormData();
            uploadedFileList.forEach((item,index)=>{
                formData.append(
                    'file',
                    item.fileInfo,
                    item.fileInfo.name
                );
            })
            
            try {
                this.setState({
                    showLoader: true
                })
                
                let fileUrl = `${this.state.externalurl}/fileupload/multiple/Site/${submission_id}/${id||site_id}`;
                let res = await axios.post(fileUrl, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    },
                });
               
            } catch (e) {
              
                console.log(e);
            }
            this.setState({
                showLoader: false
            })
        }
        
    }
    handleRemoveFile=(item)=>{
        let {uploadedFileList}=this.state;
        uploadedFileList.splice(uploadedFileList.indexOf(item),1);
       this.setState({uploadedFileList:[...uploadedFileList]});
    }
    handleDownload = async (item) => {
        try {
            this.setState({
                showLoader: true
            })
            let fileName=(item.key||'').split('/').pop();
            let { submission_id,site_id } = this.props;
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Site/${submission_id}/${site_id}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response.ok) {
                        return response.blob();
                    }
                    else {                      
                        showMessage({                  
                            text: 'Error Encountered.Please try again.',
                            icon: "error",
                            button: "Ok",                          
                          },()=>{});
                        
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    showMessage({                  
                        text: "File Downloaded Successfully!",
                        icon: "success",
                        button: "Ok",
                      
                      },()=>{});
                });
        } catch (e) {          
        }
        finally{
            this.setState({
                showLoader: false
            });
        }
    }
    handleDelete = async (item) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({
                            showLoader: true
                        })
                        let fileName=(item.key||'').split('/').pop();
                        let { submission_id,site_id } = this.props;
                        const res = await API.post(`/user/multiple/delete/Site/${submission_id}/${site_id}/${fileName}`, { updated_by: this.context.userSystemId });

                        showMessage({                  
                            text: "File Deleted!",
                            icon: "success",
                            button: "Ok",
                          
                          },()=>{});
                    }
                    catch (err) {
                        showMessage({                  
                            text: "Failed!",
                            icon: "error",
                            button: "Ok",
                          
                          },()=>{});
                    }
                    finally{
                        this.getFilesList();
                    }

                }
            });


    }
    renderFileUploadArea=()=>{
        let {s3FileInfo,uploadedFileList,S3Files}=this.state;
        return <Row><Col md={12} >
        
                    <div className='d-flex flex-column mt-4 ml-1' >
                    {isCoordinator(this.context)&&<><h5 className='font-weight-bold'>Add a File</h5>
                        <span style={{fontWeight:500}}>Select a file from your computer</span>
                        <div className='d-flex' >
                        <div style={{width:'50%'}}> <FileUploader style={{marginLeft:0}} placeholder="Input to select a file locally" fileInfo={s3FileInfo} onChange={e=>{
                        let activeFile= e.target.files[0];    
                        /*for blob url var path = (window.URL || window.webkitURL).createObjectURL(activeFile);   */
                        let path=(e.target.value||'').replace("fakepath","...");            
                        this.setState({s3FileInfo:{selectedFileName:path,file:activeFile}},()=>{                         
                        });
                    }}></FileUploader></div>
                 <Button size="sm"
                        style={{width:'75px', height:'37px'}}
                        className="d-flex align-items-center ml-3"
                        onClick={e=>{
                            if(s3FileInfo&&s3FileInfo.file){
                                let maxId=0;
                                 if((uploadedFileList||[]).length>0){
                                    let maxIdObj= (uploadedFileList||[]).reduce((p, c) => p.id > c.id ? p : c);
                                    maxId=maxIdObj.id+1;
                                 }                                
                                let isFileExists=(uploadedFileList||[]).find(p=>p.fileInfo.name.toLowerCase()===s3FileInfo.file.name.toLowerCase());
                                let isS3FileExist=(S3Files||[]).find(p=>(p.key||'').split('/').pop().toLowerCase()===s3FileInfo.file.name.toLowerCase());
                                if(!isFileExists&&!isS3FileExist){
                                    uploadedFileList.push({id:maxId,fileInfo:s3FileInfo.file});
                                    this.setState({uploadedFileList:[...uploadedFileList],hasChanged:true,s3FileInfo:{selectedFileName:'',file:null}});
                                }
                                else{
                                    showMessage({                  
                                        text: "File is already exist with the same name!",
                                        icon: "warning",
                                        button: "Ok",
                                      
                                      },()=>{});
                                }
                              
                            }
                          
                        }}
                        variant="dark">
                        <span className="material-icons mr-1 m-0">add </span>
                        <span style={{ fontSize: ' 0.75rem' }}>ADD</span>
                    </Button>
                        </div></>}
                    { ((uploadedFileList&&uploadedFileList.length>0) || (S3Files && S3Files.length>0)) &&<div style={{ maxHeight: '400px', overflowY: 'auto', fontSize: '.75rem' }}><Table responsive="sm">
                    <thead style={{ position: 'sticky', top: -2, backgroundColor: '#FFF', zIndex: 1, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <tr>
                            <th className='bg-white'>File Name</th>
                            <th className="text-right bg-white">Remove</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {
                             S3Files&&S3Files.map((item, index) => {
                                return <tr key={item.id}>
                                    <td>{(item.key||'').split('/').pop() ?? "File Name Missing"}</td>
                                    <td className="text-right">

                                        <div className='d-flex justify-content-end align-items-center'>
                                        <ToolTipEx tooltip={DEFAULT.DOWNLOAD}><span class="material-icons mr-2" style={{cursor:'pointer'}} onClick={e => {
                                              this.handleDownload(item);
                                            }} >
                                                cloud_download
                                            </span></ToolTipEx>
                                            {isCoordinator(this.context)&&<ToolTipEx tooltip={DEFAULT.DELETE}><span className="material-icons" onClick={e => {
                                               this.handleDelete(item);

                                            }} style={{ cursor: 'pointer' }}>
                                                close
                                            </span></ToolTipEx>}
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                        {
                            
                            uploadedFileList&&uploadedFileList.map((item, index) => {
                                return <tr key={item.id}>
                                    <td>{item.fileInfo.name ?? ""}</td>
                                    <td className="text-right">
                                        <div className='d-flex justify-content-end align-items-center'>
                                        <ToolTipEx tooltip={DEFAULT.DELETE}><span className="material-icons" onClick={e => {
                                                this.handleRemoveFile(item);

                                            }} style={{ cursor: 'pointer' }}>
                                                close
                                            </span></ToolTipEx>
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
                </div>}
                </div>
                    </Col></Row>
       
    }
    render(){
        return this.renderFileUploadArea();
    }
}