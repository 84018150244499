import React, { useState, useEffect, useContext } from 'react'
import { Modal, Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import { getUserInfo } from '../../../utility/helperFunctions';//
import { iirDateFormat } from '../../../utility/dateHelper';
import LoaderEx from '../../common/loader/LoaderEx';
import Button from "../../common/Button";
import UserContext from '../../../context/UserContext';
import { isCoordinator, showMessage } from './../../ToReviewDetails/commonFunctions';
import SwitchView from '../../common/SwitchView';
import { switchEnum } from '../../common/SwitchView/switchEnum';
import SwitchHistory from '../../common/SwitchHistory';
import { OBJ_TYPES } from '../../common/SwitchHistory/historyConsts';

const StudyCompoundModalEx = (props) => {
    const [loader, setLoader] = useState(true);
    const [compoundList, setCompoundList] = useState([]);
    const [studyCompound, setStudyCompound] = useState({ study_compounds_name: '', });
    const [patchObject, setPatchObject] = useState({});
    const [viewDetails, setView] = useState(true);

    const myContext = useContext(UserContext);
    const _isCoordinator = isCoordinator(myContext);
    useEffect(async () => {
        setStudyCompound({
            ...studyCompound,
            submissionId: "Submission-" + props.submissionId
        })

        const { studyCompoundId } = props;
        await getCompoundList();

        if (studyCompoundId) {
            let studyCompoundData = await getStudyCompoundById(studyCompoundId);

            if (studyCompoundData) {
                studyCompoundData["createdby"] = studyCompoundData.created_by?.length ? await getUserInfo(studyCompoundData.created_by) + "  " + iirDateFormat(studyCompoundData.createdAt, true) : "";
                studyCompoundData["last_modifiedby"] = studyCompoundData.last_modified_by?.length ? await getUserInfo(studyCompoundData.last_modified_by) + "  " + iirDateFormat(studyCompoundData.updatedAt, true) : studyCompoundData["createdby"];
                setPatchObject({ id: studyCompoundData["id"] })
                setStudyCompound({ ...studyCompound, ...studyCompoundData, submissionId: "Submission-" + studyCompoundData.submissionId })
            } else {
                showMessage({ text: "An error occured! Please try again later!", icon: "error", button: "Ok" },
                    () => props.handleModalShowHide(false));
            }
        }
        else {
            setLoader(false);
        }

    }, []);
    const getStudyCompoundById = async (studyCompoundId) => {
        try {
            const studyCompoundDetails = await API.get(`study/getbyid/${studyCompoundId}`);
            if (studyCompoundDetails && studyCompoundDetails.data) {
                return studyCompoundDetails.data || null;
            } else {
                showMessage({ text: "An error occured! Please try again later!", icon: "error", button: "Ok" },
                    () => props.handleModalShowHide(false));
            }
        } catch (e) {
            console.error(e);
            showMessage({ text: "An error occured! Please try again later!", icon: "error", button: "Ok" },
                () => props.handleModalShowHide(false));
        }
        return null;
    }

    const getCompoundList = async () => {
        try {
            let compoundList = await API.get(`seed/compound/fulldata`);
            const resultArray = (compoundList.data?.data || []).map(elm => ({ name: elm.name, value: elm.id }));
            setCompoundList(resultArray);
        } catch (e) {
            console.error(e);
            setStudyCompound({ ...studyCompound, error: true })
        } finally {
            setLoader(false);
        }
    }

    const getInputFieldsArray = () => {
        return [
            {
                label: "Study Compounds Name *",
                type: "text",
                name: "study_compounds_name",

            },
            {
                label: "Submission *",
                type: "text",
                placeholder: "Select Submission",
                name: "submissionId",
                disabled: true
            },
            {
                label: "[EXT] Compound",

                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: compoundList,
                onSelect: onSearchSelect,
                name: "compound",
            }, {
                label: "Compound Role",
                type: "dropdown",
                placeholder: "None",

                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                name: "compound_role",
            }, {
                label: "Compound 1",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: compoundList,
                onSelect: onSearchSelect,

                name: "compound_1",
            }, {
                label: "Compound 1 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                placeholder: "None",

                name: "compound_role_1",
            }, {
                label: "Compound 2",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: compoundList,
                onSelect: onSearchSelect,

                name: "compound_2",
            }, {
                label: "Compound 2 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                placeholder: "None",

                name: "compound_role_2",
            }, {
                label: "Compound 3",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: compoundList,
                onSelect: onSearchSelect,

                name: "compound_3",
            }, {
                label: "Compound 3 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                placeholder: "None",

                name: "compound_role_3",
            }, {
                label: "Compound 4",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: compoundList,
                onSelect: onSearchSelect,

                name: "compound_4",
            }, {
                label: "Compound 4 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],

                placeholder: "None",
                name: "compound_role_4",
            }, {
                label: "Compound 5",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: compoundList,
                onSelect: onSearchSelect,

                name: "compound_5",
            }, {
                label: "Compound 5 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                placeholder: "None",

                name: "compound_role_5",
            }, {
                label: "Compound 6",
                type: "searchableDropdown",
                placeholder: "Search Compound ...",
                options: compoundList,
                onSelect: onSearchSelect,

                name: "compound_6",
            }, {
                label: "Compound 6 Role",
                type: "dropdown",
                options: ["None", "Lead Agent", "Device", "Placebo", "Comparator", "Concomitant"],
                placeholder: "None",

                name: "compound_role_6",
            },

            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className: "createUpdateByfontsize",
                disabled: true,
                edit: true
            }, {
                label: "Last Modified By",
                type: "text",
                name: "last_modifiedby",
                className: "createUpdateByfontsize",
                disabled: true,
                edit: true
            },

        ]
    }

    const onInputChange = (e) => {
        setStudyCompound({ ...studyCompound, [e.target.name]: e.target.value });
        setPatchObject({ ...patchObject, [e.target.name]: e.target.type === "number" ? parseInt(e.target.value) : e.target.value })
    }

    const onSelectChange = (e, eo) => {
        setStudyCompound({ ...studyCompound, [eo.name || eo.target.name]: e });
        setPatchObject({ ...patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e })

    }

    const onSearchSelect = (e, name) => {
        setStudyCompound({ ...studyCompound, [name]: e.value })
        setPatchObject({ ...patchObject, [name]: e.value })
    }
    const formValidation = () => {
        let is_valid = true;
        if (studyCompound.study_compounds_name === "") {
            setStudyCompound({
                ...studyCompound,
                error: {
                    study_compounds_name: true
                }
            }
                // , () => {
                //     try {
                //         modalWindow._modal.dialog.scrollTo({
                //             top: 0,
                //             behavior: "smooth"
                //         });
                //     }
                //     catch (exp) {
                //         console.log(exp)
                //     }
                // }
            );
            is_valid = false;
        }
        return is_valid
    }
    const handleSave = async () => {
        try {
            setLoader(true);

            if (formValidation()) {
                let { studyCompoundId } = props;
                let payload = {};
                if (studyCompoundId) {
                    let patchObjectEdited = patchObject;

                    if ("study_compounds_name" in patchObjectEdited) {
                        patchObjectEdited.study_compounds_name = patchObjectEdited.study_compounds_name.trim()
                    }
                    payload = {
                        ...patchObjectEdited,
                        id: studyCompoundId,
                        last_modified_by: myContext.userSystemId,
                        submissionId: props.submissionId,
                    }

                } else {
                    let studyCompoundEdited = studyCompound;
                    if ("study_compounds_name" in studyCompoundEdited) {
                        studyCompoundEdited.study_compounds_name = studyCompoundEdited.study_compounds_name.trim()
                    }
                    payload = {
                        ...studyCompoundEdited,
                        created_by: myContext.userSystemId,
                        submissionId: props.submissionId,
                    }
                }

                let studyCompoundResponse = await API.post(studyCompoundId ? 'study/update' : 'study/add', payload);
                if (studyCompoundResponse && studyCompoundResponse.data) {
                    showMessage({ text: "Saved Successfully", icon: "success", button: "Ok" }, () => {
                        props.handleModalShowHide(false, null, true);
                    });
                } else {
                    showMessage({ text: "Failed", icon: "error", button: "Ok" });
                }
            }
        }
        catch (exp) {
            console.error(exp);
        }
        finally {
            setLoader(false);
        }

    }
    const renderInputFields = () => {
        return getInputFieldsArray().map(field => {
            if (field.edit && !props.studyCompoundId)
                return false;
            if (field.name !== "createdby" || field.name !== "last_modifiedby") {
                if (!_isCoordinator) {
                    field.disabled = true;
                }
            }
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={studyCompound[field.name]}
                        error={studyCompound.error?.[field.name]}
                        onSelect={field.onSelect || onSelectChange}
                        onChange={onInputChange}
                    />
                </Col>
            )
        });
    }

    const { handleModalShowHide, showHideModal, handleStudyCompoundSave, handleStudyCompoundUpdate, studyCompoundId } = props;
    return (
        <div>
            <Modal
                show={showHideModal} size="lg"
                // ref={ref => modalWindow = ref}
                className="modal-mdx new-flow-modal"
                aria-labelledby="contained-modal-title-vcenter"
            >
                {loader === true ? <LoaderEx /> : <></>}
                <div>  <Modal.Header className="d-flex border-0" >
                    <Modal.Title className="w-100 d-flex justify-content-center flex-grow-1 text-left">
                        <h5 className='modal-title text-left w-100 font-weight-bold'>
                            {studyCompoundId ?
                                `Edit  ${studyCompound.study_compounds_name}` : "New Study Compound"}
                        </h5>
                    </Modal.Title>
                    <div className='d-flex justify-content-end align-items-center'>
                        <span className="material-icons" onClick={() => handleModalShowHide(false)} style={{ cursor: 'pointer' }}>
                            close
                        </span>
                    </div>
                </Modal.Header>
                    <Modal.Body className="position-relative">
                        { studyCompoundId && 
                            <SwitchView
                                viewMode={(value) => {
                                    if (value === switchEnum.DETAILS) {
                                        setView( true );
                                    } else {
                                        setView( false );
                                    }
                                }}
                            />
                        }

                        { viewDetails ? 
                            <Row>
                                {renderInputFields()}
                            </Row>
                        : <SwitchHistory 
                            object_type={OBJ_TYPES.STUDY_COMPOUND} 
                            object_id={studyCompoundId} 
                          /> 
                        }
                    </Modal.Body>
                    { (!loader && viewDetails) ? <Modal.Footer className="d-flex border-0 justify-content-center">
                        <Button style="danger w-100px" onClick={() => handleModalShowHide(false)} className="d-flex align-items-center p-2 justify-content-center"><span>Cancel</span></Button>
                        {
                            _isCoordinator === true ?
                                <Button style="success w-100px" onClick={() => handleSave()} className="d-flex align-items-center p-2 justify-content-center"><span>{studyCompoundId ? 'SAVE' : 'ADD'}</span></Button> : null
                        }
                    </Modal.Footer> : <></>} </div>
            </Modal>
        </div>
    )
};
export default StudyCompoundModalEx;