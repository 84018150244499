import React from 'react';
import { Button } from 'react-bootstrap';

const getIcon=(icon)=>{
    switch(icon){
        case "ADD":return <span className="material-icons mr-1 m-0">add </span>;
        case "DOWNLOAD":return <span className="material-icons mr-1 m-0">file_download </span>;
        case "DELETE":return <span className="material-icons mr-1 m-0">delete </span>;
        default:return null;
    }
}
export default(props)=>{
    return <Button size="sm"
        {...props}
        className="d-flex align-items-center ml-2"
        variant="outline-dark">
        {getIcon(props.icon)}
        <span style={{ fontSize: ' 0.75rem',textTransform:'uppercase' }}>{props.text}</span>
</Button>
}