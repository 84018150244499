import React from 'react'
import { Col, Modal, Row} from 'react-bootstrap';
import API from '../../../../api/apis';
import Button from '../../../common/Button';
import { convertISOStringMMDDYYYY, formatDate, yyyymmddFormat } from '../../../common/dateFormat';
import InputField from '../../../common/input-fields/InputField';
import LoaderEx from '../../../common/loader/LoaderEx';
import {eventType,titleType} from './constTypes'
import InvoiceLists from './InvoiceLists';
import Milestone from './Milestone';
import UserContext from '../../../../context/UserContext';
import { isCoordinator, showMessage } from '../../../ToReviewDetails/commonFunctions';
import { GLOBAL_DATE_FORMAT_NOTIME } from '../../../../utility/dateHelper';

export default class ExpenseDetails extends React.Component{
    static contextType=UserContext;
    constructor(props){
        super(props);
        this.state={
            date_received:null,
            estimated_date:null,
            loading:false,
            currencyList:[],
            milestoneList:[],
            disable_estimated_date:false,
            submitNew: false
        };
    }
    MESSAGES={
        MILESTONE_ESTIMATED_DATE:'Milestone Estimated Date Empty. Please update the Milestone section to provide a valid date for this milestone.'
    };
    SERVICES={
        "EXPENSE-DETAIL":'/finance/milestoneExpenses/detail/',
        "CURENCY-LIST":'/finance/currencyList',
        "MILESTONES":"/finance/milestoneList",
        "NEW-MILESTONE":"/finance/new/milestone/add",
        "CUSTOM-SUBMISSION":"/finance/submission/custom",
        "UPDATE-EXPENSE":"/finance/milestone/expense/update",
        "CREATE-EXPENSE":"/finance/milestone/create"
    }
    submissionFields="po_number,currency_id";
   fields=[
       [{
           label: "Milestone *",
           type: "dropdown-ex",
           name: "milestone",
           placeholder:"Please Select Milestone",
           onSelect:(e) => {
           
            if(e.target.value.isCustom){                
                this.showCreateMileStone();
                return false;
            }
            this.setState({
                hasChanged: true,
                [e.name || e.target.name]: e.target.value,
            },this.handleMilestoneSelection);
        }
       },
       {
        label: "PO Number",
        type: "text",
        name: "po_number",
        placeholder:"Enter PO #",
        
        }],
       [
        {
            label: "Estimated Date",
            type: "date",
            name: "estimated_date",
            placeholder:"Select Estimated Date"
        },
        {
            label: "Invoice Number",
            type: "text",
            name: "invoice",
            placeholder:"Enter Invoice #"
        }
       ],
       [
        {
            label: "Estimated Amount",
            type: "number",
            name: "estimated_cost",
            placeholder:"Enter Estimated Amount"
        },
        {
            label: "Local Currency",
            type: "searchableDropdown",
            name: "currency_iso_code",
           
        }
       ],
       [
        {
            label: "Invoice Received Date",
            type: "date",
            name: "date_received",
        }
       ],
       [
        {
            label: "Actual Amount",
            type: "number",
            name: "actual_amount",
            placeholder:"Enter Actual Amount"
        }
       ],
       [
        {
            label: "Internal Comments",
            type: "textarea",
            name: "comments",
            placeholder:"Enter Comments",
            full_column:true,
            rows:3
        }
       ]
   ];
  componentDidMount=()=>{
    let is_coordinator=isCoordinator(this.context);
    this.setState({loading:true,is_coordinator},this.initServices);
  }
   
   initServices=async ()=>{
    
    let basicSubmissionInfo=await this.getBasicSubmissionInfo();
    let expenseResponse= await this.getExpnesedetails();   
    let currencyResponse= await this.getCurrencyList();
    let milestoneResponses= await this.getMilestoneList();
    if(expenseResponse){
        expenseResponse.estimated_date=expenseResponse.estimated_date?formatDate(expenseResponse.estimated_date,"YYYY-MM-DD",GLOBAL_DATE_FORMAT_NOTIME):'';
        expenseResponse.date_received=expenseResponse.date_received?formatDate(expenseResponse.date_received,"YYYY-MM-DD",GLOBAL_DATE_FORMAT_NOTIME):'';
        let {milestone_id,expense_title}=expenseResponse;
        if(milestone_id){
            let milestone=milestoneResponses.find(p=>p.id===milestone_id);;
            expenseResponse.milestone=milestone;

            //Always taking Milestone data from submissions for those pre defaulted milestone items
            if(milestone&&milestone.submission_fields){
                expenseResponse.disable_estimated_date=true;
                let submissionData = await this.getSubmissionData(milestone);
                expenseResponse.estimated_date= submissionData ? formatDate(submissionData,"YYYY-MM-DD",GLOBAL_DATE_FORMAT_NOTIME) : '';
            }
        }
        else{
            expenseResponse.milestone={id:null,name:expense_title};
        }
       
    }
    
    let {local_currency}=expenseResponse;
    let currency_iso_code={};
    if(local_currency){
        let currency_data=currencyResponse.find(p=>p.value===local_currency);
        currency_iso_code=currency_data||{};       
    }
    let payLoad={
        currencyList:currencyResponse,
        milestoneList:milestoneResponses,
        currency_iso_code,
        ...(expenseResponse??{}),
        basicSubmissionInfo,
        loading:false,
        submitNew: false
    };
 
    if(!this.props.trial_expense_name){
        // New Mode 
        // Defaulting PO Number, Currency
        if(basicSubmissionInfo){
            let {po_number,currency_id}=basicSubmissionInfo;
            payLoad.po_number=po_number??null;
            let currency_data=currencyResponse.find(p=>p.id===currency_id); 
            payLoad.currency_iso_code=currency_data||{};           
            
        }
       
    }
    this.setState(payLoad);

   }
   getMilestoneList = async () => {
    let url=`${this.SERVICES.MILESTONES}/${this.props.submissionId}`;   
    let milestoneResponses = await API.get(url);
    if (milestoneResponses && milestoneResponses.data) {
        return milestoneResponses.data || [];
    }
    return [];
}
customMilestoneList=()=>{
    let {milestoneList}=this.state;
    let newMilestoneItem={id:'NWMI',isCustom:true,customRender:()=>{
        return <div className="d-flex">
            <span className="flex-grow-1">Add a New Milestone</span>
            <span className="material-icons">
                add
            </span>
         </div>;
    }};
    return [...milestoneList,newMilestoneItem];
}
setLoader = (status) => {
        this.setState({ loading: status });
    }
handleMilestoneSelection=async ()=>{
    let { milestone } = this.state;
    if (milestone && milestone.name && milestone.submission_fields) {
        this.setLoader(true); 
        try {
            let submissionData = await this.getSubmissionData(milestone);
            this.setState({ estimated_date: submissionData ? formatDate(submissionData,"YYYY-MM-DD",GLOBAL_DATE_FORMAT_NOTIME) : '',disable_estimated_date:true })
            if(!submissionData){
                showMessage({ text: this.MESSAGES.MILESTONE_ESTIMATED_DATE , icon: "warning", button: "Ok" });
            }
        }
        catch (ex) {

        }
        finally {
            this.setLoader(false);
        }
    }
    else{
        this.setState({disable_estimated_date:false});
    }
}
    getSubmissionData=async(milestone)=>{
        let submission_fields=milestone.submission_fields;
        if(milestone&&submission_fields){
            let payLoad={
                "submissionId": +this.props.submissionId,
                "custom_fields": submission_fields
            };

            let submissionResponse =await this.processSubmissionData(payLoad);
            if(submissionResponse){
                return submissionResponse[submission_fields]??null;;
            }           
        }
        return null;
    }
    getBasicSubmissionInfo=async()=>{
        try{
            
            let payLoad={
                "submissionId": +this.props.submissionId,
                "custom_fields": this.submissionFields
            };    
            let submissionResponse =await this.processSubmissionData(payLoad);
            return submissionResponse;
        }
        catch(exp){

        }
        finally{

        }
        return null;
    }
    processSubmissionData=async(payLoad)=>{
        let submissionResponse = await API.post(this.SERVICES['CUSTOM-SUBMISSION'],payLoad);
        if(submissionResponse){
            return submissionResponse.data??null;
        }
    }
    getCurrencyList = async () => {
        let currencyResponse = await API.get(this.SERVICES['CURENCY-LIST']);
        if (currencyResponse && currencyResponse.data) {
            (currencyResponse.data||[]).forEach(p=>{
                p.name= `${p.value}-${p.name}`;
            })
            return currencyResponse.data || [];
        }
        return [];
    }
   getExpnesedetails=async()=>{
       let {trial_expense_name}=this.props;
       if(trial_expense_name){
        let expenseResponse=await API.get(`${this.SERVICES['EXPENSE-DETAIL']}${trial_expense_name}`);
        if(expenseResponse&&expenseResponse.data){
         return expenseResponse.data||[];
        }
       }       
       return {};
   }
    handleEVents=(type,data)=>{
        let {handleEvents}=this.props;
        switch(type){
            case eventType['MODAL-CLOSE']:
            if(handleEvents){
                handleEvents(eventType['MODAL-CLOSE'],data);
            }
            break;
            // case eventType['SUBMIT-&-NEW']:
            // if(handleEvents){
            //     handleEvents(eventType['SUBMIT-&-NEW'],data);
            // }
            // break;
        }
    }
    onInputChange = (e, eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                hasChanged: true
            });
        }
        else {
            this.setState({
                [e.target.name]: e.target.value,
                hasChanged: true
            });
        }
    }
    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e
        });
    }
    onSelectChange = (e, eo) => {
      
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        },console.log(this.state));

    }
    showCreateMileStone=()=>{
       this.setState({showNewMilestone:true});
    }
    renderInputs=()=>{ 
        let {is_coordinator,disable_estimated_date}=this.state;
        return this.fields.map((fieldArray,index)=>{
            let isFullCOlumn=fieldArray.length===1&&fieldArray[0].full_column;
            return <Row  key={index} >
                    {
                        fieldArray.map((field,index)=>{
                            field.disabled=!is_coordinator;
                            if(field.name==="currency_iso_code" || field.name==="po_number"){
                                field.disabled= true;
                            }
                            if(field.name==="estimated_date"&&disable_estimated_date){
                                field.disabled=disable_estimated_date;
                            }
                            if(field.name==="currency_iso_code"){
                                field.options=this.state.currencyList;
                                field.onSelect=this.onSearchSelect;
                            }
                            if(field.name==="milestone"){
                                field.options=this.customMilestoneList();
                            }
                            
                            return <Col key={field.name} md={isFullCOlumn?12:6}>
                                <InputField
                                    {...field}
                                    value={this.state[field.name]}
                                    error={this.state.errors?.[field.name]}
                                    onSelect={field.onSelect || this.onSelectChange}
                                    onChange={field.onChange || ((e) => this.onInputChange(e, field))}
                                />
                            </Col>
                        })
                    }
            </Row>;
          
        });
    }
    doValidate=()=>{
        let {trial_expense_name}=this.props;
        let {milestone,expense_title,errors}=this.state;
        let is_valid=true;
        errors={};
        if(trial_expense_name){
            //handling old data validation, ie old data contains only expense title
            if((!milestone||!milestone.id)&&(!expense_title||expense_title.toString().trim().length<=0)){
                errors["milestone"]=true;
                is_valid=false;
            }
        }
        else{
            if((!milestone||!milestone.id)){
                errors["milestone"]=true;
                is_valid=false;
            }
        }
        this.setState({errors},()=>{
           try{
            this.modalWindow._modal.dialog.scrollTo({
                top:0,
                behavior: "smooth"
              });
           }
           catch(exp){

           }
           
        });
        return is_valid;
    }
    handleSave=async(submitNew)=>{
        try{
            this.setLoader(true);
            if (this.doValidate()) {
                let { trial_expense_name } = this.state;
                let payLoad = this.formattedInput();
                let url = trial_expense_name ? this.SERVICES['UPDATE-EXPENSE'] : this.SERVICES['CREATE-EXPENSE'];
                let expenseResponse = await API.post(url, payLoad);
                if (expenseResponse && expenseResponse.data && expenseResponse.data.trial_expense_name) {
                    showMessage({ text: "Saved Successfully", icon: "success", button: "Ok" },()=>{
                        if(submitNew){
                            // this.handleEVents(eventType['SUBMIT-&-NEW'],true);
                            this.setState({
                                date_received:null,
                                estimated_date:null,
                                loading:false,
                                // currencyList:[],
                                // milestoneList:[],
                                disable_estimated_date:false,
                                invoice: "",
                                estimated_cost: "",
                                actual_amount: "",
                                comments: "",
                                expense_details:[],
                                submitNew: true
                            }, ()=>{
                                this.initServices();
                                }
                            );

                        }else{
                            this.handleEVents(eventType['MODAL-CLOSE'],true);
                        }
                    });
                }
                else {
                    showMessage({ text: "Failed", icon: "danger", button: "Ok" });
                }
            }
        }
        catch(exp){

        }
        finally{
            this.setLoader(false);
        }
       
    }
    formattedInput=()=>{
        let {
            milestone, date_received, estimated_date,
            actual_amount,
            currency_iso_code,
            comments,
            po_number,
            invoice,
            estimated_cost
        }=this.state;
        let inputs={            
            milestone: milestone ? milestone.id : "",
            date_received:date_received?formatDate(new Date(date_received)):null,
            estimated_date:estimated_date?formatDate(new Date(estimated_date)):null,
            actual_amount:actual_amount?+actual_amount:null,
            currency_iso_code:currency_iso_code.id,
            comments:comments,
            po_number,
            invoice,
            estimated_cost:estimated_cost?+estimated_cost:null,
            submissionId:+this.props.submissionId
          
        };
        let {trial_expense_name}=this.props;
        if((!milestone||!milestone.id) && trial_expense_name){
            delete inputs.milestone ;
        }
        else{
            inputs.milestone=milestone.id;
        }
        let currentUser=this.context.userSystemId;
        if(trial_expense_name){
            inputs.trial_expense_name=trial_expense_name;
            inputs.updated_by=currentUser;
        }
        else{
            inputs.created_by=currentUser;
        }
        let invoiceItems=this.compInvoiceList.getInvoiceItems();
        inputs.invoice_items= [];
        if(trial_expense_name){
            invoiceItems.forEach((item,index)=>{
                let {id,description,amount,frequency}=item;
                if(item.id&&item.hasChange&&item.hasChange.length>0){
                    let return_payload={id};
                    item.hasChange.forEach((d,i)=>{
                        return_payload[d]=item[d];
                    })
                    inputs.invoice_items.push(return_payload);
                }
                else if(!item.id&&!item.is_deleted){
                    inputs.invoice_items.push({description,amount,frequency,is_approved:item.is_approved}); 
                }
            })
        }
        else{
            invoiceItems.filter(p=>!p.is_deleted).map(p=>{
                let {description,amount,frequency}=p;
                inputs.invoice_items.push({description,amount,frequency}); 
            });
        }
        
        return inputs;
    }
    handleExtenrnalEvents=(evntType,data)=>{
        switch (evntType) {
            case eventType['MODAL-CLOSE']:
                this.setState({ showNewMilestone: false });
                break;
            case eventType['NEW-MILESTONE']:
                this.setState({ loading: true }, ()=>this.handleSaveMilestone(data))
                break;
        }
    }
    handleSaveMilestone=async(data)=>{
        let newMilestone=null;
        let {submissionId}=this.props;
        try{
            let response= await API.post(this.SERVICES['NEW-MILESTONE'],{
                submission_id:submissionId,
                name:data,
                created_by:this.context.userSystemId
              });
              if(response&&response.data&&response.data.id){
                let res=response.data;
                newMilestone= {
                    id:res.id,
                    name:res.name,
                    submission_fields:res.submission_fields
                };
               
              }
        }
        catch{

        }
        finally{
            let payload={loading:false,showNewMilestone:false};
            if(newMilestone){              
                let milestoneResponse=await this.getMilestoneList();
                payload.milestoneList=[...milestoneResponse];
                payload.milestone=newMilestone;
            }
            this.setState({...payload});          
        }
     
    }
    renderNewMilestone=()=>{
        return <div style={{ left: 0, top: 0,backgroundColor:'#DEDEDE50',zIndex:100 }} className="w-100 h-100 position-absolute d-flex align-items-center justify-content-center">
            <Milestone events={this.handleExtenrnalEvents} className={"shadow-sm"}></Milestone>
        </div>
    }
    render(){
        let {trial_expense_name}=this.props;
        let {showNewMilestone,loading,expense_details,is_coordinator}=this.state;
        return <>
           
        <Modal ref={ref=>this.modalWindow=ref} show={true}
         size="md"
         className="modal-mdx new-flow-modal"
         aria-labelledby="contained-modal-title-vcenter"
         centered
        >
            <Modal.Header className="d-flex border-0">
                    <Modal.Title className="w-100 d-flex justify-content-center flex-grow-1 text-left ">
                        <h5 className='modal-title text-left w-100'>
                            {trial_expense_name ? titleType.EDIT : titleType.NEW}
                        </h5>
                    </Modal.Title>
                    <div className='d-flex justify-content-end align-items-center'>
                        <span className="material-icons" onClick={e => {
                            this.handleEVents(eventType['MODAL-CLOSE'],true)
                        }} style={{ cursor: 'pointer' }}>
                            close
                        </span>
                    </div>
            </Modal.Header>
            <Modal.Body className="position-relative">
                {loading&&<LoaderEx></LoaderEx>}
                {this.renderInputs()}
                {showNewMilestone && this.renderNewMilestone() }
               <InvoiceLists trial_expense_name={trial_expense_name} ref={ref=>this.compInvoiceList=ref} source={expense_details||[]} submitNew={this.state.submitNew}></InvoiceLists>
            </Modal.Body>
            {is_coordinator&&<Modal.Footer className="d-flex border-0 justify-content-center">
                        <Button style='success' className="p-2 pl-3 pr-3" onClick={e=>this.handleSave()}>
                            <div className='d-flex align-items-center text-dark'>
                                <span>  {trial_expense_name?"SAVE":"SUBMIT"}</span>
                            </div>
                        </Button>
                        {trial_expense_name?<></>:
                            <Button style='success' className="p-2 pl-3 pr-3" onClick={e=>this.handleSave(true)} >
                                <div className='d-flex align-items-center text-dark'>
                                    <span> SUBMIT & NEW </span>
                                </div>
                            </Button>
                        }
            </Modal.Footer>}
        </Modal>
        </>
    }
}