import React from 'react'
import { Col, Row, Button } from 'react-bootstrap';
import { dateStringformat, dateStringformatEx } from '../../../utility/helperFunctions';
import InputField from '../../common/input-fields/InputField';
import FileUploader from '../FileUploader';
import SharepointConnect from '../SharepointConnect';
import { downloadSharepointFile } from './commonReviewFn';

export default class AdditionalReview extends React.Component{

    constructor(props) {
    super(props);
    this.state={       
        errors:null,
        sharePointFileInfo:{
            selectedFileName:'',
            customName:'',
            file:null
        }
    }
    }
    imageFields = [];
    fieldMappingInfo = {};
    async componentDidMount(){
        await this.getFields();
    }
    componentDidUpdate(previousProps, previousState) {      
        if (!this.state.customFields) {           
            let data = sessionStorage.getItem("customFields");
            let data1 = sessionStorage.getItem("listDetails");
            if (data && data1) {
                let parsedData1 = JSON.parse(data);
                let parsedData2 = JSON.parse(data1);
                this.setFieldData(parsedData1,parsedData2);
            }
        }
    }
    getFields=async ()=>{
      
        let sc=new SharepointConnect();
        let stateInfo={};
        await sc.loadConfigInformation().then((data)=>{    
            stateInfo=data;
        });
        let customFields=await sc.getFields(this.props.addtnl_review_form);
        let listDetails=await sc.getListsDetails(this.props.addtnl_review_form);
        sessionStorage.removeItem("customFields");
        sessionStorage.removeItem("listDetails");
        sessionStorage.setItem("customFields",JSON.stringify(customFields));
        sessionStorage.setItem("listDetails",JSON.stringify(listDetails));
        this.setFieldData(customFields,listDetails);
       
    }
    setFieldData=(customFields,listDetails)=>{
        let state={};
        if(customFields&&customFields.length>0){           
            customFields.forEach((item) => {
                let fieldType=this.getInputField(item.TypeAsString);
                state[item["InternalName"]] =["text","number","textarea","date","dropdown"].includes(fieldType)?(this.getDefaultValue(item)||""):"";
            })
        }
        let {source,errors,files}=this.props;
        let newStateValues={...state,...source};
        if(files){
            newStateValues.sharePointFileInfo=files;
        }
        this.setState({customFields,...newStateValues, originalFields:newStateValues,errors,listDetails});
    }
    renderFormFields=()=>{
        let {customFields,errors}=this.state;

       /*
            MaxLength
            InternalName
            Id
            ReadOnlyField
            Required
            ClientValidationMessage
            DefaultValue
            Title
            TyoeAsString
            TypeDisplayName
            TypeShoortDescription
            ValidationMessage       
       */
        let permissionProps = {};
        if (this.props.formView === 'READONLY') {
            permissionProps = { disabled: 'disabled' }
        }
        if(customFields&&customFields.length>0){
            let availableFields= customFields.filter(p=>p.TypeAsString!="Thumbnail");
            this.fieldMappingInfo={};
            let fieldsArray=availableFields.map((item,index)=>{
                return this.generateField(item,index,errors,permissionProps);
            });
            let hasImageFields=customFields.filter(p=>p.TypeAsString=="Thumbnail");
            if(hasImageFields.length>0 && this.props.formView!='READONLY'){
                fieldsArray.push(this.generateFileField());
                if ((this.imageFields.indexOf(hasImageFields[0].InternalName)==-1 )){
                    this.imageFields.push(hasImageFields[0]?.InternalName);
                }
            }
            return fieldsArray;
        }
        return <></>;
    }
    getFormData=()=>{
        let errors=this.doValidation();
        let files=this.state.sharePointFileInfo;
        let formValues=this.state;
        delete formValues.customFields;
        delete formValues.sharePointFileInfo;
        delete formValues.listDetails;
        return {formValues,errors,files};
    }
    setFormData=()=>{

    }
    
    getInputField=(type)=>{
       
        switch(type){
            case "Text":return "text";
            case "Choice":return "dropdown";
            case "DateTime":return "date";
            case "Note":return "textarea";
            case "Number":return "number";
            case "Thumbnail":return "";
            default:return "text";
        }
    }
    onInputChange = (e,eo) => {
        if (eo.TypeAsString === "DateTime") {           
           let d= dateStringformatEx(e);
            this.setState({[eo.InternalName]: d });
        }
        else{
            this.setState({[e.target.name]: e.target.value });
        }
        
    }

    onSelectChange = (e, eo) => {
        this.setState({          
            [eo.name || eo.target.name]: e,
        });
    }
    getDefaultValue=(field)=>{
        let fieldType=this.getInputField(field.TypeAsString);
        let val=field.DefaultValue;
        console.log("fieldtype:",fieldType)
        if(fieldType=="date"){           
            if(val==="[today]"){
                return new Date();
            }
            else if(val&&val.length>0){
              return dateStringformat(val);
            }          
        }
        return val;
    }
    doValidation=()=>{
        let {customFields,errors}=this.state;
        errors={};
       /*
            MaxLength
            InternalName
            Id
            ReadOnlyField
            Required
            ClientValidationMessage
            DefaultValue
            Title
            TyoeAsString
            TypeDisplayName
            TypeShoortDescription
            ValidationMessage       
       */
        if(customFields&&customFields.length>0){
            let fieldsArray=customFields.filter(p=>p.Required);
            fieldsArray.forEach((item,index)=>{
                if(!this.state[item.InternalName]||this.state[item.InternalName].toString().trim().length<=0){
                    errors[item.InternalName]=true;
                }
            });
            return errors;
        }
    }
    generateFileField=()=>{
        let {sharePointFileInfo}=this.state;
        return <Col xs={12} sm={12}>
            <div style={{marginTop:'1rem'}}>
                <FileUploader placeholder="Sharepoint File" fileInfo={sharePointFileInfo} onChange={e => {
                    let activeFile = e.target.files[0];
                    this.setState({ sharePointFileInfo: { selectedFileName: activeFile.name, file: activeFile } },this.handleRefresh);
                }}></FileUploader>

            </div>
        </Col>
    }
    handleRefresh=()=>{
        let {refreshHandler}=this.props;
        if(refreshHandler){
            refreshHandler();
        }
    }
    generateField=(field,index,errors,permissionProps)=>{
      
        let {
            MaxLength,
            InternalName,
            Id,
            ReadOnlyField,
            Required,
            ClientValidationMessage,
            DefaultValue,
            Title,
            TypeAsString,
            TypeDisplayName,
            TypeShoortDescription,
            ValidationMessage,Choices }=field;

            let fieldType=this.getInputField(TypeAsString);
            this.fieldMappingInfo[InternalName] = (Title || TypeDisplayName);
        return <Col sm={12} key={field.Id}>
            <InputField
                type={fieldType}
                {...permissionProps}
                error={errors?.[field.name]}
                name={InternalName}
                label={(Title || TypeDisplayName)}
                value={this.state[InternalName]}
                options={Choices||[]}
                onSelect={field.onSelect || this.onSelectChange}
                onChange={e=>this.onInputChange(e,field)}
            /></Col>;

    }
    handleCopyUrl=(e,data)=>{
        e.preventDefault();
        e.stopPropagation();
        if(data.LinkingUrl){
            navigator.clipboard.writeText(data.LinkingUrl);
            this.setState({showCopyInfo:true},this.clearCopyInfo);
        }
            
    }
    clearCopyInfo=()=>{
        setTimeout(()=>{
            this.setState({showCopyInfo:false}) 
        },500);
    }
    handleSharepointDownload=async(data)=>{
        await downloadSharepointFile(data);
 
     }
    renderFileDownloadArea=()=>{
        let {sharepointFiles}=this.props;
        let {showCopyInfo,customFields}=this.state;
        return <>{customFields&&customFields.length>0&&<Col xs={12}>
        <div className='d-flex '>
            <ul className='d-flex m-0 p-0 flex-column'>            
            {sharepointFiles&&sharepointFiles.map((item,index)=>{
                return <li key={index} onClick={e=>this.handleSharepointDownload(item)} className='d-flex align-items-center position-relative p-2' style={{cursor:'pointer'}}>
                            <span>{item.Name}</span>
                            
                            <span  className="material-icons ml-3" style={{cursor:'pointer'}}>
                                file_download
                            </span>
                    <span class="material-icons ml-3" onClick={e=>this.handleCopyUrl(e,item)}>
                        content_copy
                    </span>
                  {showCopyInfo&&<div style={{right:-30,top:0,zIndex:100}} className="shadow-sm p-1 rounded-sm position-absolute bg-white">
                        Copied !
                    </div>}
                </li>
            })}
            </ul>
        </div>
      </Col>}</>
    }
    render(){
        let {listDetails}=this.state;

        return <>
            {/* <Button onClick={this.downloadFormData}>Download</Button> */}
             <Col>
            <div className='mb-3 mt-3 pl-3' style={{ fontWeight: "500", fontSize: '1.3rem' }}>{(listDetails?.Title??"")}</div>
            <div className='mb-3 mt-3 pl-3' >{(listDetails?.Description??"")}</div>
            </Col>
            {this.renderFormFields()}
            {this.renderFileDownloadArea()}
        </>;
    }
}