import React,  {memo} from 'react';
import { Form, Dropdown,Button, InputGroup, FormCheck } from 'react-bootstrap';
import Select from 'react-select'
import Alert from 'react-bootstrap/Alert'
import TextEditor from '../text-editor/TextEditor';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import SelectSearch from 'react-select-search';
import fuzzySearch from './fuzzySearch';
import DatePicker  from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';

const RFCComplaintDate=(inputProps)=>{
    let selectedDateEx=new Date();
    if (typeof inputProps.value !== 'object') {
        let momentObj = moment(inputProps.value, 'DD/MMM/YYYY LT',false);
        if (momentObj.isValid()) {
            selectedDateEx = momentObj.toDate();
        }
        else {
            momentObj = moment(inputProps.value);
            if (momentObj.isValid()) {
                selectedDateEx = momentObj.toDate();
            }
        }
    }
    else {
        selectedDateEx = inputProps.value;
    }
    return selectedDateEx;
}
const InputField = (props) => {
    const { label, helptext, options, onSelect, onChange,customerror, error, display=true,...inputProps  } = props; //...inputProps is used to copy props object
if(!display) return null;
// const years = range(getYear(new Date()) - 10, getYear(new Date()) + 10, 1);
const years = range(2000, 2041, 1);

        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

        const CustomInput = ((props) => {
            //console.info(props);
           return  <button className="form-control bottom-border-input shadow-none btn btn-outline" style={{ textAlignLast: "left", backgroundColor: "white", color: "black", width: "20%" }} onClick={props.onClick} >
                {(props.value||props.placeholder )}<span>{" "}</span><b style={{ color: "black", float: "right", marginRight: "20px" }} class="bi bi-calendar"></b>
            </button>
          });

    if (["ly_num"].indexOf(inputProps?.type?.trim()) >= 0) {
        return (
            <Form.Group>
                {label&&<Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>}
                <InputGroup className="mb-2">
                    <InputGroup.Text>LY</InputGroup.Text>
                <Form.Control className=" shadow-none" autoComplete="none" onChange={onChange} {...inputProps} />
                </InputGroup>
                {/* <Form.Control className="bottom-border-input shadow-none" autoComplete="none" onChange={onChange} {...inputProps} /> */}
                {error && <p className="text-danger ml-3">This field is required.</p>}
                {customerror && <p className="text-danger ml-3">{customerror}</p>}

            </Form.Group>
        )
    }
    if (["text", "email", "password", "number"].indexOf(inputProps?.type?.trim()) >= 0) {
        return (
            <Form.Group>
                {label&&<Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>}
                <Form.Control className="bottom-border-input shadow-none" autoComplete="none" onChange={onChange} {...inputProps} />
                {error && <p className="text-danger ml-3">This field is required.</p>}
                {customerror && <p className="text-danger ml-3">{customerror}</p>}

            </Form.Group>
        )
    }
    if (inputProps.type === "date") {

          let date=inputProps.value
          let selectedValue=date!== undefined ? false: true;
          let selectedDateEx = RFCComplaintDate(inputProps);
        return (
            <Form.Group>
                {label&&<Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>}
                {/* <Form.Control className="bottom-border-input shadow-none" autoComplete="none" dateFormat="DD-MM-YYYY" onChange={onChange} {...inputProps} /> */}
                <DatePicker                
                className="calendarApp form-control bottom-border-input shadow-none"
                {...inputProps}
                
                popperClassName="some-custom-class"
      popperPlacement="bottom-end"
      popperModifiers={[
        {
          name: "offset",
          options: {
            offset: [5, 10],
          },
        },
        {
          name: "preventOverflow",
          options: {
            rootBoundary: "viewport",
            tether: false,
            altAxis: true,
          },
        },
      ]}
                isClearable={inputProps.value && inputProps.disabled===false}
                selected={selectedDateEx}
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                }) => (
                    <div
                        style={{
                            margin: 10,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {'<'}
                        </button>
                        <select
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
            
                        <select
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
            
                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {'>'}
                        </button>
                    </div>
                )}
              onChange={ onChange }
              placeholderText={inputProps.placeholder||'Enter date'}
              dateFormat="dd/MMM/yyyy"
              customInput={ <CustomInput />}
            //   dateFormat="dd-MMM-yyyy h:mm aa"
            //   showTimeInput
          />
                {error && <p className="text-danger ml-3">This field is required.</p>}
                {customerror && <p className="text-danger ml-3">{customerror}</p>}

            </Form.Group>
        )
    }
    if (inputProps.type === "datetime-local") {
          
          let date=inputProps.value;
          let selectedDateEx = RFCComplaintDate(inputProps);
        return (
            <Form.Group>
                <Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>
                
              
                <DatePicker
                className="  form-control bottom-border-input shadow-none"
                selected={selectedDateEx}
             {...inputProps}
             popperClassName="some-custom-class"
      popperPlacement="bottom-end"
      popperModifiers={[
        {
          name: "offset",
          options: {
            offset: [5, 10],
          },
        },
        {
          name: "preventOverflow",
          options: {
            rootBoundary: "viewport",
            tether: false,
            altAxis: true,
          },
        },
      ]}
              onChange={ onChange }
              isClearable={inputProps.value && inputProps.disabled===false}
              placeholderText='Enter date'
              dateFormat="dd/MMM/yyyy h:mm a"
            //   dateFormat="dd-MMM-yyyy h:mm aa"
              showTimeInput
              customInput={ <CustomInput/>}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
            }) => (
                <div
                    style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {'<'}
                    </button>
                    <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                    >
                        {years.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
        
                    <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
        
                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {'>'}
                    </button>
                </div>
            )}
          />
         
                {error && <p className="text-danger ml-3">This field is required.</p>}
                {customerror && <p className="text-danger ml-3">{customerror}</p>}

            </Form.Group>
        )
    }
    if (inputProps.type === "checkbox") {
        return (
            <Form.Group className="mr-4" >
                <Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>
                <Form.Check
                    className="ml-2"
                    {...inputProps}
                    checked={inputProps.value === 'true' || inputProps.value === true}
                    onChange={onChange} 
                />
                {error && <p className="text-danger ml-3">This field is required.</p>}
            </Form.Group>
        )
    }
    if (inputProps.type === "radio") {
        return (
            <Form.Group className="mr-4" >
                <Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>
                <Form.Check
                    className="ml-2"
                    {...inputProps}
                    checked={inputProps.checked ===inputProps.id}
                    onChange={onChange} 
                    
                />
                {error && <p className="text-danger ml-3">This field is required.</p>}
            </Form.Group>
        )
    }
    if (inputProps.type === "radiobutton") {
        return (
            <div >
                <label>{label}</label>
                <div>
                {options.map((item,index)=>{
                    return <Form.Check  value={item} onChange={onChange}  inline  label={item}  type={"radio"}  />
                })}
                </div>
               
             </div> 

            
        );
    }
   
    if (inputProps.type === "file") {
        return (
            <Form.Group className="mr-4" >
                <Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>
                <Form.File
                    id="custom-file"
                    label={inputProps.placeholder}
                    custom
                />
                {error && <p className="text-danger ml-3">This field is required.</p>}
            </Form.Group>
        )
    }

    if (inputProps.type === "textarea") {
        return (
            <Form.Group>
                {label&&<Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>}
                <Form.Control className="textarea-input shadow-none" as="textarea" onChange={onChange} {...inputProps} />
                <div className="textarea-input shadow-none showPrint">{inputProps.value}</div>
                {error && <p className="text-danger ml-3">This field is required.</p>}
            </Form.Group>
        )
    }
    if (inputProps.type === "dropdown") {
        return (
            <Form.Group>
                {label&&<Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>}
                <Dropdown className="dropdown-input" onSelect={onSelect} {...inputProps}>
                    <Dropdown.Toggle variant="default" className="shadow-none" name={inputProps.name} disabled={inputProps.disabled}>
                        {inputProps.value || inputProps.placeholder}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                         <div className='dropdown-menu-ex'>
                            {Array.isArray(options) ? options.map(option => <Dropdown.Item eventKey={option} key={option} name={inputProps.name}>{option}</Dropdown.Item>) : ''}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                {error && <p className="text-danger ml-3">This field is required.</p>}
                {customerror && <p className="text-danger ml-3">{customerror}</p>}

            </Form.Group>
        )
    }
    if (inputProps.type === "dropdown-ex") {
        return (
            <Form.Group>
                {label&&<Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>}
                <Dropdown className="dropdown-input" {...inputProps}>
                    <Dropdown.Toggle variant="default" className="shadow-none" name={inputProps.name} disabled={inputProps.disabled}>
                        {inputProps.value?.name || inputProps.placeholder}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                         <div className='dropdown-menu-ex'>
                            
                            {Array.isArray(options) ? options.map(option =>{
                                if (!option.customRender) { return <Dropdown.Item onClick={e=>onSelect({target:{name:inputProps.name,value:option}})} eventKey={option} key={option.text} name={inputProps.name}>{option.name}</Dropdown.Item>; }
                                else return <Dropdown.Item eventKey={option.text} key={option.text} onClick={e=>onSelect({target:{name:inputProps.name,value:option}})} name={inputProps.name}>{option.customRender()}</Dropdown.Item>
                            } ) : ''}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                {error && <p className="text-danger ml-3">This field is required.</p>}
                {customerror && <p className="text-danger ml-3">{customerror}</p>}

            </Form.Group>
        )
    }
    if(inputProps.type === "creatable"){
        return (
            <Form.Group>
            <Form.Label className="bold-label">
                {label}{" "}
                { helptext &&
                <span>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                        <i class="bi bi-info-circle-fill text-primary"></i>
                    </OverlayTrigger>
                </span>    }
            </Form.Label>
                <CreatableSelect

                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    onChange={(e2) => onSelect(e2, inputProps.name)}
                    value={inputProps.value}
                    defaultValue = {inputProps.value || inputProps.placeholder}
                    options={options}
                    isDisabled={inputProps.disabled}
                />
                {error && <p className="text-danger ml-3">This field is required.</p>}
                {customerror && <p className="text-danger ml-3">{customerror}</p>}

            </Form.Group>
        ) 
    }
    if (inputProps.type === "searchableDropdown") {
        return (
            <Form.Group hidden={inputProps.hidden}>
                {label && <Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>}
                <SelectSearch options={options} value={inputProps.value || inputProps.placeholder} onChange={(e1,e2) => onSelect(e2,inputProps.name)} {...inputProps} filterOptions={fuzzySearch} search  />
                {error && <p className="text-danger ml-3">This field is required.</p>}
                {customerror && <p className="text-danger ml-3">{customerror}</p>}

            </Form.Group>
        )
    }


    if (inputProps.type === "multi-select") {
        return (
            <Form.Group>
                <Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>
                <Select
                    className="dropdown-input"
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={onSelect}
                    options={options}
                    name={inputProps.name}
                    isDisabled = {inputProps.disabled}
                    value={inputProps.value}
                />
                {error && <p className="text-danger ml-3">This field is required.</p>}
                {customerror && <p className="text-danger ml-3">{customerror}</p>}
            </Form.Group>
        )
    }

    if (inputProps.type === "alert") {
        return (
            <Alert className="form-alert" variant={inputProps.variant}>
                <h5>{label}</h5>
            </Alert>
        )
    }
    if (inputProps.type === "alert-new") {
        return (
            <Alert className="form-alert" >
           
                <div className="flex-grow-1 font-weight-bold mb-3">{label}</div>
                <hr/>
            
        </Alert>
           
        )
    }
    if (inputProps.type === "text-editor") {
        return (
            <Form.Group>
                <Form.Label className="bold-label">
                    {label}{" "}
                    { helptext &&
                    <span>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{helptext}</Tooltip>}>
                            <i class="bi bi-info-circle-fill text-primary"></i>
                        </OverlayTrigger>
                    </span>    }
                </Form.Label>
                <TextEditor {...inputProps} onChange={onChange} />
                {error && <p className="text-danger ml-3">This field is required.</p>}
            </Form.Group>
        )
    }
    return <></>
}

export default memo(InputField);