
import React, { Component } from 'react'
import { Button, Card, Col, Form, Navbar, Row, Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import API from '../api/apis';
import FileComponent from './common/file-component/FileComponent';
import InputField from './common/input-fields/InputField';
import BootstrapModal from './common/modal/BootstrapModal';
import StickyNav from './common/sticky-nav/StickyNav';
import AmendmentModal from './AmendmentModal';
import { dateChange, dateStringformat, dateTimeformat, getUserInfo, uploadFile, enableWFActions } from '../utility/helperFunctions';
import UserContext from '../context/UserContext';
import { yyyymmddFormat } from './common/dateFormat';
import HistoryFile from './submission/detailSubComponents/HistoryFile';
import ReviewFile from './submission/detailSubComponents/section-1/ReviewFile';
import Loader from './common/loader/Loader';
import { APPROVED, DECLINED } from '../consts';
import { stateToHTML } from 'draft-js-export-html';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import axios from 'axios';

class AmendmentDetail extends Component {
    static contextType=UserContext
    state = {
        externalurl :  "http://localhost:1337",
        // owner_id:this.context.userSystemId,
        showLoader : false,
        showLoaderUpload : false ,
        action : "",
        loadTable : true ,

        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        
        viewEditData: {},
        showHideModal: false,
        viewEditFlag: null,
        fileList: [],
        selectedFile: null,
        successFile: false,
        errorFile: false,
        showLoader: false,
        AmendmentDetails: {},
        auditData:[],
        trailData:[]
        
    }
    get inputFieldsArray() {
        return [
            {
                label: "Information",
                type: "alert",
                variant: "secondary",
                disabled:true
            },
            {
                label: "Amendment Title",
                type: "text",
                name: "amendmentTitle",
                disabled: true,
                
            },
            {
                label: "Approval",
                type: "dropdown",
                placeholder: "Awaiting Review",
                name: "approval",
                options: [
                    "None",
                    "Awaiting Review",
                    "Approved",
                    "Declined",
                    "Under Review"
                ],
                disabled:true
            },
            {
                label: "Decision Date",
                name: "decision_date",
                type: "date",
                disabled:true
            },
            {
                label: "[EXT] Investigator Rationale",
                type: "textarea",
                name: "investigator_rationale",
                disabled:true

            },


            {
                label: "Lilly Feedback",
                type: "text-editor",
                name: "feedback",
                isReadOnly:true,
                onChange: this.onEditorChange,
            },
            {
                label: "Submission",
                type: "text",
                name: "submissionId",
                disabled:true

            },
            // {
            //     label: "Owner",
            //     type: "text",
            //     name: "owner_id",
            //     disabled:true

            // },
            {
                label: "System Information",
                type: "alert",
                variant: "secondary",
                disabled:true
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled:true
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
                disabled:true
            },
            {
                label: "Last Updated By",
                type: "text",
                name: "last_updated_by",
                disabled: true,
            },


        ];
    }
     async getExternalUrl() {
        let response = '';
        try {
            response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
            externalurl: (response && response.data) ? response.data.exturl : "http://localhost:1337",
        })
    }

    async componentDidMount() {
        await this.getExternalUrl();
   let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
   this.setState({
       selectedRole: selectedRole,
       access: {
           showLoader : true,
           action : "fetching",
           showLoader : true,
           view: true,
           create:  selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator")  ,
           edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
           delete: selectedRole.includes("administrator"),
       },
   });
        await this.getAmendmentById(this.props.match.params.amendmentId, this.props.match.params.name, this.props.match.params.rowIndex)
        await this.getAuditList(this.props.match.params.amendmentId)
    }
componentDidUpdate(previousProps, previousState) {
   console.log(this.context.selectedRole);
   console.log(previousState.selectedRole);
   let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
   if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole !== this.context.selectedRole)) {
       this.setState({
           selectedRole: selectedRole,
           access: {
               view: true,
               create:  selectedRole.includes("global_coordinator")  || selectedRole.includes("administrator"),
               edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator")||selectedRole.includes("read"),
               delete: selectedRole.includes("administrator"),
           },
       }, () => { console.log("userRole", this.state.selectedRole) })
   }
   
}

    getAmendmentById = async (id, name) => {
        const res = await API.get(`amendment/getbyid/${id}`);
        const viewEditData = { ...res.data };
        await this.getFilesList(res.data.submission_id);
        res.data["feedback"]= res.data.feedback !=="" ? this.getEditorValueFromHtml(res.data.feedback) : "";
        res.data["investigator_rationale"]=res.data.investigator_rationale !== null ? res.data.investigator_rationale.replace(/<[^>]+>/g, '') : "";
        res.data["amendmentTitle"] = "Submission-"+res.data.submission_id+" :Amendment-"+this.props.match.params.rowIndex;
        res.data["decision_date"] = res.data.decision_date !== "" && res.data.decision_date !== null ?
        dateStringformat(res.data.decision_date) : null;
        res.data["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
        res.data["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt):res.data.createdby ;
        console.log(viewEditData)
        this.setState({
            ...res.data,
            AmendmentDetails: res.data,
            submissionId:"Submission-"+res.data.submission_id,
            viewEditData: viewEditData,
            showLoader: false
        })
    }
    handleAmendmentUpdate = async (state) => {
        delete state.submissionId;
        delete state.created_by;
        state.updated_by = this.context.userSystemId;
        state.feedback=state.feedback ? this.getHtmlFromEditorValue(state.feedback):"";
       /*  if(state.approval.toLowerCase()===APPROVED.toLowerCase() || state.approval.toLowerCase()===DECLINED.toLowerCase())
        {
            state.decision_date=new Date();
        } */
        state["decision_date"] = state.decision_date !== "" && state.decision_date !== null ?
        dateChange(state.decision_date) : null;
        try {
            const res = API.put(`amendment/update/`, state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })
            this.getAmendmentById(this.props.match.params.amendmentId, this.props.match.params.rowIndex)
        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
    }
    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
    }
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else 
        {this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }
    getHtmlFromEditorValue = (val="") => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }
    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            hasChanged: true,
        });
    };
    getEditorValueFromHtml = (html="") => {
        if(html){
            const blocksFromHTML = convertFromHTML(html);        
            const state = ContentState.createFromBlockArray(blocksFromHTML);
            return EditorState.createWithContent(state);
        }
        return "";
    }
    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag !== "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    getFilesList = async (submission_id) => {
        try {
            this.setState({
                showLoader: true,
                action: "fetching",
                loadTable: false
            })
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            this.setState({
                showLoader : false,
                loadTable : true,

                fileList: res.data?.filter(file => file.type === `Amendment_${this.props.match.params.amendmentId}`)
            });
        } catch (e) {
            this.setState({
                showLoader : false,
                loadTable : true
            })
            console.log(e)
        }
        console.log("fileList",this.state.fileList)
    }
    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }

    uploadFileFunction = async () => {
        const formData = new FormData();
        formData.append(
           
            'file',
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        try {
            this.setState({
                showLoaderUpload :true
            })
            
            let fileUrl = `${this.state.externalurl}/fileupload/multiple/Amendment/${this.state.AmendmentDetails.submission_id}/${this.props.match.params.amendmentId}`;
            let res = await axios.post(fileUrl, formData, {
                headers: {                   
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                },
            });
            this.setState({
                selectedFile: null,
                successFile: true,
                fileMessage: "File uploaded!",
                showLoaderUpload : false
            });

        } catch (e) {
            this.setState({
                selectedFile: null,
                errorFile: true,
                showLoaderUpload : false
            })
            console.log(e);
        }
        this.getAmendmentById(this.props.match.params.amendmentId, this.props.match.params.name, this.props.match.params.rowIndex);
    }
    handleDelete = async (fileName) => {
        swal({
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        this.setState({
                            showLoader : true,
                            action : "delete"
                        })
                        const res =await API.post(`/user/multiple/delete/Amendment/${this.state.AmendmentDetails.submission_id}/${this.props.match.params.amendmentId}/${fileName}` , {updated_by : this.context.userSystemId});
                        this.setState({
                            successFile: true,
                            fileMessage: "File deleted!",
                            
                            showLoader : false
                        })

                    }
                    catch (err) {
                        this.setState({ 
                            showLoader : false,
                            errorModal: true });
                    }

                }
            });
            this.getAmendmentById(this.props.match.params.amendmentId, this.props.match.params.name, this.props.match.params.rowIndex);

    }

    handleDownload = async (fileName) => {
        try {
            this.setState({
                showLoader : true,
                action : "download"
            })
            /*const res = await API.get(`/user/multiple/download/getByid/Amendment/${this.state.AmendmentDetails.submission_id}/${this.props.match.params.amendmentId}/${fileName}`,{
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', decodeURIComponent(fileName));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                successFile: true,
                showLoader : false,
                fileMessage: "File Downloaded Successfully!"
            })*/
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Amendment/${this.state.AmendmentDetails.submission_id}/${this.props.match.params.amendmentId}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                        
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        showLoader : false,
                        successFile: true,
                        fileMessage:"File Downloaded Successfully!"
                    })
                });
        } catch (e) {
            this.setState({
                errorFile: true,
                showLoader : false
            })
            console.log(e);
        }
    }
    getAuditList = async (id) => {
        try {
            const temp = [];
            const res = await API.get(`audit/amendment/${id}`)
            console.log(res)
            await this.getTrailList();

            this.state.trailData.forEach(x => {
                res.data.forEach(y => {
                    if (x.key.toLowerCase() === y.field.toLowerCase()) {
                        y["Field"] = x.label;
                        if(y.field==="created_at"){
                            y.new_value=y.new_value!== null ? dateTimeformat(y.new_value):"";
                            y.old_value=y.old_value!== null ? dateTimeformat(y.old_value):"";
                             temp.push(y);
                         }
                         else{
                            y.new_value = (y.new_value);
                            y.old_value = (y.old_value)
                            temp.push(y);
                         }
                        
                    }
                    
                })
            })
            let sortedReviews = temp.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            this.setState({
                auditData: sortedReviews,
            })

        }
        catch (err) {
            console.log(err)
        }
    }
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/amendment`)
            console.log(res)
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    changeShowState = async() => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false
        });
        this.getAmendmentById(this.props.match.params.amendmentId, this.props.match.params.name, this.props.match.params.rowIndex);
        this.getAuditList(this.props.match.params.amendmentId);
        
    }
    onNavOut = () => {
        window.history.back()
    }

    render() {
        const { showHideModal, viewEditFlag,showLoaderUpload  ,loadTable,  action ,  showLoader, viewEditData } = this.state
        if(!this.state.AmendmentDetails.submission_id)
            return <Loader />
        return (
            <>
                <StickyNav>
                    <Navbar expand="sm" className="nav-secondary">
                        <div onClick={this.onNavOut} className="back-button" style={{ "width": "20%" }}>
                            <i className="bi bi-arrow-left">
                            {" "}{this.state.submissionId}
                            </i>
                        </div>
                        <h4 className="text-center" style={{ "width": "66%" }}>Amendment-{("000"+this.state.id).slice(-4)} Detail </h4>
                      {this.state.access.edit && enableWFActions() &&  <div className="text-right" style={{ "width": "20%" }}>
                            <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                        </div>}
                    </Navbar>
                </StickyNav>
                <Row>
                    <Col md={6}>
                        <Card className="data-cards pt-3">
                            <Card.Body>
                                <Row>
                                    {this.renderInputFields()}
                                    {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} >
                                        Save
                                    </Button>
                                </div> */}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card className="data-cards">
                            <Card.Body>
                                <Tabs defaultActiveKey="files">
                                    <Tab eventKey="files" title="Files">
                                       {this.state.access.edit && enableWFActions() &&  <Row>
                                            <Col sm={8}>
                                                <Form.File
                                                    id="custom-file"
                                                    className="custom-file-label"
                                                    onChange={this.onFileChange}
                                                    label={this.state.selectedFile?.name}
                                                    custom
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                {showLoaderUpload && <Button className="btn btn-primary" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </Button>}
                                                {!showLoaderUpload && <Button variant="secondary"  onClick={()=> uploadFile(this.state.selectedFile,this.state.fileList,this.uploadFileFunction)} disabled={this.state.selectedFile === null} >Upload</Button>}
                                            </Col>
                                        </Row>}
                                        <br />

                                        {/* <small className="form-text text-muted">*Maximum size for file upload is 2MB.</small> */}
                                        {showLoader &&
                                            <Row>
                                            <div className="mt-3 btn btn-muted fs-1 bg-transparent text-primary" disabled>
                                                    <span className="visually-hidden">File {action} in progress </span>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </div>
                                            </Row>}

                                        <br />
                                        {this.state.fileList.length > 0 && loadTable &&
                                            <FileComponent data={this.state.fileList}
                                                accessDelete = {this.state.access.delete}
                                                handleDelete={this.handleDelete}
                                                handleDownload={this.handleDownload} />
                                        }

                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                        <Card className="data-cards">
                        <Card.Body>
                            <Tabs defaultActiveKey="history">
                                <Tab eventKey="history" title="History">
                               
                           <HistoryFile historyData={this.state.auditData}  historyTitle="Amendment History"/>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                    <Card className="data-cards">
                        <Card.Body>
                            <Tabs defaultActiveKey="history">
                                <Tab eventKey="history" title="Review ">
                               
                                <ReviewFile submissionId={this.state.AmendmentDetails.submission_id}  Type="Amendment"/>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>
                {showHideModal &&
                    <AmendmentModal
                        showHideModal={showHideModal}
                        handleModalShowHide={this.handleModalShowHide}
                        handleAmendmentSave={this.handleAmendmentSave}
                        handleAmendmentUpdate={this.handleAmendmentUpdate}
                        viewEditFlag={viewEditFlag}
                        viewEditData={viewEditData}
                        submission_id={this.state.AmendmentDetails.submission_id}
                        name={this.props.match.params.rowIndex}

                    />}
                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body="Amendment has been updated!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.successFile &&
                    <BootstrapModal
                        show={this.state.successFile}
                        header="Success"
                        body={this.state.fileMessage}
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorFile &&
                    <BootstrapModal
                        show={this.state.errorFile}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </>
        )
    }
}


export default withRouter(AmendmentDetail);