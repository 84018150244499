import React from 'react'
import {Modal,Button, Row, Col} from 'react-bootstrap';
import BaseReview from './BaseReview'
export default class ProposalReview extends BaseReview{
    constructor(props){
        super(props);
        this.state={
            reviewType:'',

            reviewForm:{               
            },
            errors:{}
        }
        this.state=this.INITIAL_STATE;
        
    }
    INITIAL_STATE={
        externalurl:'',
        investigatorName:  "",     
        formView:this.props.formView,            
        stageTitle:"",
        fileInfo:{
            selectedFileName:'',
            file:null
        },
        sharePointFileInfo:{
            selectedFileName:'',
            customName:'',
            file:null
        },
        downloadableDocuments:[],
        reviewType:'',
        errors:{},
        reviewForm:{
            "is_committee_review": null,
            "name_medical_reviewer_committee": "",
            "name_of_reviewer_or_committee": "",
            "date_of_review": new Date(),
            "does_study_address_an_important_problem":false,
            "advance_scientific_or_clinical_care":false,
            "is_framework_design_methods_developed":false,
            "project_approach_method_outcome_novel":false,
            "are_aims_of_study_original_innovative":false,
            "rank_the_importance":"",
            "investigator_suited":false,
            "enrollment_milestones_achievable":false,   
            "requested_budget_appropriate":false,
            "support_for_proposal":null,
            "comments": "",
            "decision_rationale":"",
            "is_all_review_complete":null,
            "is_review_cycle_complete":null,
            "submission_id": "",
            "reviewers_detail_id": "",
            "review_header_id": "",
            "status": "",
            "details_for_pi":"",
            "is_coordinator_entry": false,
            "committee_approval":""
        }
    }
    get InputElements(){
        let inputArrays= [
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Is this a committee review?",
                name: "is_committee_review",
                options: ["Yes", "No"],
                formType:[""],
                committeOrder:1,
                reviewOrder:1
            },
            {
               
                type: "text",
                placeholder: "Name of Committee",
                label: "Name of Committee *",
                name: "name_medical_reviewer_committee",
                formType:["COMMITTEE"],
                committeOrder:2
            },
            {
               
                type: "text",
                placeholder: "Name of reviewer(s) or committee",
                label: "Name of reviewer(s) or committee *",
                name: "name_medical_reviewer_committee",
                formType:["COORDINATOR"],
                committeOrder:2
            },
            {
                label:"Name of reviewer(s) *",
                type: "text",
                placeholder: "Name of reviewer(s)",
                name: "name_of_reviewer_or_committee",
                formType:["REVIEWER"],                
                reviewOrder:2
            },
           
            {   label:"Review Date *",
                placeholder: "Review Date",
                type: "date",
                name: "date_of_review",  
                formType:["COMMITTEE","REVIEWER","COORDINATOR"] ,
                reviewOrder:4,
                col:6          
            },
            {
                label:"Approved? *",
                type: "dropdown",
                placeholder: "Please Select",                
                name: "committee_approval",
                options: ["Approved", "Declined","On Hold","Contingent"],
                formType:["COMMITTEE"],
                col:6 
            },
            {
                label:"Final review decision *",
                type: "dropdown",
                placeholder: "Please Select",                
                name: "status",
                options: ["Approved", "Declined","On Hold","Contingent"],
                formType:["COORDINATOR"]
            },
            {   label:"Decision rationale",
                placeholder: "Decision rationale",
                type: "textarea",
                name: "decision_rationale",
                formType:["COORDINATOR"]               
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Does the study address an important problem?",
                name: "does_study_address_an_important_problem",
                options: ["Yes", "No"],
                formType:[""]
            }, 
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Advance Scientific or clinical care?",
                name: "advance_scientific_or_clinical_care",
                options: ["Yes", "No"],
                formType:[""]
            }, 
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are framework, design, methods developed?",
                name: "is_framework_design_methods_developed",
                options: ["Yes", "No"],
                formType:[""]
            }, 
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Project approach,method,& outlcome novel?",
                name: "project_approach_method_outcome_novel",
                options: ["Yes", "No"],
                formType:[""]
            }, 
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are aims of study original & innovative?",
                name: "are_aims_of_study_original_innovative",
                options: ["Yes", "No"],
                formType:[""]
            }, 
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Rank the importance of this proposal on a scale of 1 to 5 (1 is least important and 5 is most important)",
                name: "rank_the_importance",
                options: ["1", "2", "3", "4", "5"],
                formType:["REVIEWER"]
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Investigator suited to carry out study?",
                name: "investigator_suited",
                options: ["Yes", "No"],
                formType:[""]
            },  
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Enrollment & Milestones feasible?",
                name: "enrollment_milestones_achievable",
                options: ["Yes", "No"],
                formType:[""]
            },  
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Is requested budget appropriate?",
                name: "requested_budget_appropriate",
                options: ["Yes", "No"],
                formType:[""]
            },  
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Do you support this proposal? *",
                name: "support_for_proposal",
                options: ["Yes", "No"],
                formType:["REVIEWER"]
            },  
           
            {
                placeholder: "Feedback for PI",
                label: "Feedback for PI",
                type: "textarea",
                name: "details_for_pi",
                formType:["COMMITTEE"]               
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are all reviews complete?",
                name: "is_all_review_complete",
                options: ["Yes", "No"],
                formType:["COORDINATOR"]
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are all review cycles complete?",
                name: "is_review_cycle_complete",
                options: ["Yes", "No"],
                formType:["COORDINATOR"]
            },          

            {
                placeholder: "Comments",
                label:"Comments",
                type: "textarea",
                name: "comments",
                formType:["COMMITTEE","REVIEWER","COORDINATOR"]  ,
                helpAvailability:["REVIEWER"],
                helptext:'Please include your rationale for not supporting a proposal, if applicable. Other comments may be included as well.',
                reviewOrder:6,              
            }
        ];
        return inputArrays;
    }    
    
    validateInput=()=>{
        let {reviewForm,errors}=this.state;
        errors={};
        let is_valid=true;
        let include=["is_committee_review","name_medical_reviewer_committee","name_of_reviewer_or_committee","date_of_review","status","support_for_proposal","committee_approval"];
        this.getFields().forEach((item)=>{
           
            if(include.includes(item.name)){
                if(this.isUndefinedOrNull(reviewForm[item.name])){
                    errors[item.name]=true;
                    is_valid=false;
                }
                
            }
        });
        this.setState({errors:{...errors}})      
        return is_valid;
    }
   
    render(){       
        return <>
           {this.renderTopArea()}
            <Row>
                {this.renderInputs()}
                {this.renderFileArea()}
                {this.renderFileDownloadArea()}
            </Row></>
    }
}