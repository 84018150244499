import React from 'react'
import {Modal,Button, Row, Col} from 'react-bootstrap';
import BaseReview from './BaseReview'
export default class PublicationReview extends BaseReview{
    constructor(props){
        super(props);
        this.state={
            reviewType:'',

            reviewForm:{               
            },
            errors:{}
        }
        this.state=this.INITIAL_STATE;
        console.log(this.props);
    }
    INITIAL_STATE={
        externalurl:'',
        investigatorName:  "",     
        formView:this.props.formView,            
        stageTitle:"",
        fileInfo:{
            selectedFileName:'',
            file:null
        },
        sharePointFileInfo:{
            selectedFileName:'',
            customName:'',
            file:null
        },
        downloadableDocuments:[],
        reviewType:'',
        errors:{},
        reviewForm:{
            "is_committee_review": null,
            "name_medical_reviewer_committee": "",
            "name_of_reviewer_or_committee": "",
            "date_of_review": new Date(),
            "title":"",
            "is_delay_due_to_ip_patent":false,
            "are_results_of_all_objectives_included":false,
            "concern_course_of_action":"",
           
            "is_approved":null,
          
            "comments": "",
            "decision_rationale":"",
            "is_all_review_complete":null,
            "is_review_cycle_complete":null,
            "submission_id": "",
            "reviewers_detail_id": "",
            "review_header_id": "",
            "status": "",
            "details_for_pi":"",
            "is_coordinator_entry": false,
            "committee_approval":"",
            "format":""
        }
    }
    get InputElements(){
        let inputArrays= [
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Is this a committee review?",
                name: "is_committee_review",
                options: ["Yes", "No"],
                formType:[],
                committeOrder:1,
                reviewOrder:1
            },
            {
               
                type: "text",
                placeholder: "Name of Committee",
                label: "Name of Committee *",
                name: "name_medical_reviewer_committee",
                formType:["COMMITTEE"],
                committeOrder:2
            },
            {
               
                type: "text",
                placeholder: "Name of reviewer(s) or committee",
                label: "Name of reviewer(s) or committee *",
                name: "name_medical_reviewer_committee",
                formType:["COORDINATOR"],
                committeOrder:2
            },
            {
                label:"Name of reviewer(s) *",
                type: "text",
                placeholder: "Name of reviewer(s)",
                name: "name_of_reviewer_or_committee",
                formType:["REVIEWER"],                
                reviewOrder:2
            },
           
            {   label:"Review Date *",
                placeholder: "Review Date",
                type: "date",
                name: "date_of_review",               
               
                formType:["COMMITTEE","REVIEWER","COORDINATOR"] ,
                reviewOrder:4,
                col:6          
            },
            {
                label:"Final review decision *",
                type: "dropdown",
                placeholder: "Please Select",                
                name: "status",
                options: ["Reviewed with Comments", "Reviewed without Comments"],
                formType:["COORDINATOR"]
            },
            {   label:"Decision rationale",
                placeholder: "Decision rationale",
                type: "textarea",
                name: "decision_rationale",
                formType:["COORDINATOR"]               
            },
            {               
                type: "text",
                placeholder: "Publication Title",
                label: "Publication Title",
                name: "title",
                formType:["REVIEWER","COMMITTEE"]
            }, 
            {               
                type: "text",
                placeholder: "Format",
                label: "Format",
                name: "format",
                formType:["REVIEWER","COMMITTEE"]
            }, 
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Primary & secondary results included?",
                name: "are_results_of_all_objectives_included",
                options: ["Yes", "No"],
                formType:["REVIEWER","COMMITTEE"]
            }, 
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Delay due to IP / patent?",
                name: "is_delay_due_to_ip_patent",
                options: ["Yes", "No"],
                formType:["REVIEWER","COMMITTEE"]
            }, 
            
            {
                placeholder: "What is the concern and course of action?",
                label: "What is the concern and course of action?",
                type: "textarea",
                name: "concern_course_of_action",
                formType:["REVIEWER","COMMITTEE"]               
            },
            
            {
                placeholder: "Please Select",
                label: "Approved? *",
                type: "dropdown",
                name: "is_approved",
                options: ["Yes", "No"],
                formType:["REVIEWER"]               
            },
            {
                label:"Approved? *",
                type: "dropdown",
                placeholder: "Please Select",                
                name: "committee_approval",
                options: ["Approved", "Declined","On Hold","Contingent"],
                formType:["COMMITTEE"],
                col:6 
            },
            {
                placeholder: "Feedback for PI",
                label: "Feedback for PI",
                type: "textarea",
                name: "details_for_pi",
                formType:["COMMITTEE"]               
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are all reviews complete?",
                name: "is_all_review_complete",
                options: ["Yes", "No"],
                formType:["COORDINATOR"]
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are all review cycles complete?",
                name: "is_review_cycle_complete",
                options: ["Yes", "No"],
                formType:["COORDINATOR"]
            },          

            {
                placeholder: "Comments",
                label:"Comments",
                type: "textarea",
                name: "comments",
                formType:["COMMITTEE","REVIEWER","COORDINATOR"]  ,
                reviewOrder:6,              
            }
        ];
        return inputArrays;
    }    
    
    validateInput=()=>{
        let {reviewForm,errors}=this.state;
        errors={};
        let is_valid=true;
        let include=["is_committee_review","name_medical_reviewer_committee","name_of_reviewer_or_committee","date_of_review","status","is_approved","committee_approval"];
        this.getFields().forEach((item)=>{
           
            if(include.includes(item.name)){
                if(this.isUndefinedOrNull(reviewForm[item.name])){
                    errors[item.name]=true;
                    is_valid=false;
                }
                
            }
        });
        this.setState({errors:{...errors}})      
        return is_valid;
    }
   
    render(){       
        return <>
           {this.renderTopArea()}
            <Row>
                {this.renderInputs()}
                {this.renderFileArea()}
                {this.renderFileDownloadArea()}
            </Row></>
    }
}