import React, { Component } from 'react';
import API from '../../../api/apis';
import { Col, Row, Container, Button, Form, Card } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import UserContext from '../../../context/UserContext';
import BootstrapModal from '../../common/modal/BootstrapModal';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import UploadReviewFiles from '../../submission/UploadReviewFiles';
import { dateChange } from '../../../utility/helperFunctions';

class ReviewProtocol extends Component {
    static contextType = UserContext
    state = {
        date_of_review: null,
        name_medical_reviewer_committee: "",
        does_protocol_match_details: "",
        safety_changes_required: "",
        do_you_have_any_recommended_changes: "",
        support_for_protocol: "",
        difference_from_the_proposal: "",
        provide_recommendations: "",
        safety_language_missing: "",
        selectedFile: null,
        patchObject: { "submission_id": this.props.submissionId }
    }
    get inputFieldsArray() {
        return [
            {
                label: "Name of Reviewer or Committee *",
                type: "text",
                placeholder: "Enter Name",
                name: "name_medical_reviewer_committee",

            },
            {
                label: "Date of Review *",
                type: "date",
                placeholder: "Enter Review Date",
                name: "date_of_review",

            },
            {
                label: "Does the protocol match the details in the proposal? *",
                type: "dropdown",
                name: "does_protocol_match_details",
                placeholder: "Please Select An Option",
                options: ["Yes", "No"]
            },
            {
                label: "Please state what is different from the proposal *",
                type: "textarea",
                name: "difference_from_the_proposal",
                display: this.state.does_protocol_match_details != "Yes" && this.state.does_protocol_match_details !== ""

            },
            {
                label: "Are there any mandatory safety or product related changes required? *",
                type: "dropdown",
                name: "safety_changes_required",
                placeholder: "Please Select An Option",
                options: ["Yes", "No"]
            },
            {
                label: "What safety language is missing? *",
                type: "textarea",
                name: "safety_language_missing",
                display: this.state.safety_changes_required != "No" && this.state.safety_changes_required !== ""

            },
            {
                label: "Do you have any recommended changes for the protocol? *",
                type: "dropdown",
                name: "do_you_have_any_recommended_changes",
                placeholder: "Please Select An Option",
                options: ["Yes", "No"]
            },
            {
                label: "Please state your recommendations *",
                type: "textarea",
                name: "provide_recommendations",
                display: this.state.do_you_have_any_recommended_changes != "No" && this.state.do_you_have_any_recommended_changes !== ""

            },
            {
                label: "Do you recommend support for this protocol? *",
                type: "dropdown",
                name: "support_for_protocol",
                placeholder: "Please Select An Option",
                options: ["Yes", "No"]
            },
            {
                label: "Comments",
                type: "textarea",
                placeholder: "write comments...",
                name: "comments",

            },

        ];
    }
    async componentDidMount() {
        let response = await API.get(`user/accesstoken`);
        console.log(response.data.access)
        this.setState({
            siteURL: 'https://collab.lilly.com/sites/',
            siteEnv: response && response.data ? response.data.site_name : " ",
            libraryName: 'Root',
            rootFolderName: 'Submission',
            folderName: 'Submission-' + this.props.submissionId,
            access_token: response && response.data ? response.data.access : " "
        })

    }
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else 
        {this.setState({ [e.target.name]: e.target.value });
        if (e.target.value !== "")
            this.setState({
                error: { ...this.state.error, [e.target.name]: false },
                patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
            });
}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: e === "Yes" ? true : false },
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
        });
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = {
            date_of_review: this.state.date_of_review,
            name_medical_reviewer_committee: this.state.name_medical_reviewer_committee,
            does_protocol_match_details: this.state.does_protocol_match_details,
            safety_changes_required: this.state.safety_changes_required,
            do_you_have_any_recommended_changes: this.state.do_you_have_any_recommended_changes,
            support_for_protocol: this.state.support_for_protocol,
            // difference_from_the_proposal: this.state.difference_from_the_proposal,
            // provide_recommendations: this.state.provide_recommendations,
            // safety_language_missing: this.statesafety_language_missing,
        }
        const errors = {}
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {
                errors[key] = true;
                is_valid = false;
            }
            if ((key === "does_protocol_match_details" && initialValues[key] === "No" && this.state.difference_from_the_proposal === "")) {
                errors["difference_from_the_proposal"] = true
                is_valid = false;

            }
            if (key === "safety_changes_required" && (initialValues[key] === "Yes" && this.state.safety_language_missing === "")) {
                errors["safety_language_missing"] = true
                is_valid = false;

            }
            if (key === "do_you_have_any_recommended_changes" && (initialValues[key] === "Yes" && this.state.provide_recommendations === "")) {
                errors["provide_recommendations"] = true
                is_valid = false;

            }
            this.setState({
                error: { ...errors }
            });
        });

        return is_valid;
    }
    submitForm = async () => {
        if (this.formValidation()) {
            console.log(this.state)
            this.state.patchObject["created_by"] = this.context.userSystemId;
            this.state.patchObject["date_of_review"] = this.state.patchObject.date_of_review != "" && this.state.patchObject.date_of_review != null ?
            dateChange(this.state.patchObject.date_of_review) : null;

            try {
                const res = await API.post("/review/protocol/add", this.state.patchObject);
                this.setState({ id: res.data.id }, () =>
                    this.setState({ successModal: true }));
            } catch (err) {
                this.setState({ errorModal: true });
            }
        }

    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.props.history.push(`/submissions/${this.props.submissionId}`)
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.props.submissionId}`)
    }
    onFileChange = (e) => {
        this.setState({ selectedFile: e.target.files[0] })
    }
    uploadFile = async () => {
        console.log(' uploadFileSPO Click!!!!');



        if (this.state.selectedFile == null) {
            alert("Please Select a file to upload");
        }
        else {
            let fileName = this.state.selectedFile.name;
            let relativePath = '/_api/web/GetFolderByServerRelativeUrl(\'/sites/';
            let fileUrl = this.state.siteURL + this.state.siteEnv + relativePath + this.state.siteEnv + '/' + this.state.libraryName + '/' + this.state.rootFolderName + '/' + this.state.folderName + '\')/Files/add(url=' + '\'' + fileName + '\'' + ',overwrite=true)';
            //let fileUrl = 'https://collab.lilly.com/sites/LLIRDev/_api/web/GetFolderByServerRelativeUrl(\'/sites/LLIRDev/Root/Demo3\')/Files/add(url=\'text.txt\',overwrite=true)';

            const reader = new FileReader();
            reader.readAsArrayBuffer(this.state.selectedFile);
            reader.onload = async (event) => {
                //console.log("reader data",event.target.result);
                try {
                    const resp = await axios.post(fileUrl,
                        {
                            data: event.target.result,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json;odata=verbose',
                                'Authorization': 'Bearer ' + this.state.access_token
                            },
                        });
                    console.log(resp);

                    await this.getFiles();

                }
                catch (err) {
                    console.error(err);
                    // this.setState({ errorModal: true });

                }
            }
        }
    }

    render() {

        return (
            <Container fluid className="form-page">
                <h4 className="text-center mb-4">Protocol Review</h4><hr />
                <Row>
                    {this.renderInputFields()}
                    <Col sm={6}>
                        <Card body className="p-2">
                            <UploadReviewFiles
                                access={{ edit: true, create: true, delete: true }}
                                type="Protocol"
                                submissionid={this.props.submissionId}
                                id={this.state.id}
                            ></UploadReviewFiles>


                        </Card>
                    </Col>
                    <div className="w-100 d-flex justify-content-center">
                        <Button className="ml-1 mt-4 px-3" variant="primary" type="submit" onClick={this.submitForm}>
                            Save
                        </Button>
                    </div>
                </Row>
                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body="Created Successfully!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </Container>
        )
    }
}
export default withRouter(ReviewProtocol)