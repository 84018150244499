import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { Col, Row, Dropdown } from 'react-bootstrap';

import DatatableComponent from '../../common/datatable/DatatableComponent';
import {mapping} from '../../../mapping'

import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import UserContext from '../../../context/UserContext';
import BootstrapModal from '../../common/modal/BootstrapModal';

import swal from 'sweetalert';
import API from '../../../api/apis';
import Loader from '../loader/Loader';
import { dateTimeStringformat } from '../../../utility/helperFunctions';

class RecycleBin extends Component {
    static contextType = UserContext;
   
    state = {
        presentationList:[],
        arr:[],
        tempArr:[],
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access : {
            view : true,
            create : false,
            edit : false,
            delete : false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        isLoading:false
    }
    columns = [
        {
            dataField: 'id',
            text: ' Recycle Id',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>
                    <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                         >{row.id}</p>
                </>
            }
            },
        {
        dataField: 'object',
        text: 'Record Type',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                     >{mapping[row.object_type] + " - "+row.object_id}</p>
            </>
        },
        csvExport:false
        },
        {
            dataField: 'record_type_csv',
            text: 'Record Type',
            sort: true,
            hidden:true,
            csvExport:true
            },
        {
            dataField: 'submission_id',
            text: 'Submission Id',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <>
                    <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                         >{row.submission_id}</p>
                </>
            }
            },
            {
                dataField: 'created_by',
                text: 'Deleted By',
                sort: true,
                
                formatter: (cell, row, rowIndex) => {
                    return <>
                        <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                        >{(row.user && row.user.first_name) ? (row.user.first_name + " " + row.user.last_name) : ""}</p>
                    </>
                }
                },
                {
                    dataField: 'created_at',
                    text: 'Deleted At',
                    sort: true,
                    formatter: (cell, row, rowIndex) => {
                        return <>
                            <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                                 >{dateTimeStringformat(row.created_at)}</p>
                        </>
                    }
                    },
        {
            dataField: '',
            text: "Actions",
            // headerClasses:"align",
            csvExport : false,
            formatter: (cell, row, rowIndex) => {
                var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
                var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
                return <>
            
                    <i className="bi bi-arrow-counterclockwise" onClick={() => this.handleRestore(row)}
                        style={{visibility: visibilityEdit , fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem" }}></i>
                         {/* <i className="bi bi-trash-fill" onClick={() => this.handleModalShowHide(true, "edit", row,rowIndex)}
                        style={{visibility: visibilityEdit , fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem" }}></i> */}
     
 
    </>
            }
    }];
  
    componentDidMount() {
        console.log(this.props);
        this.getPresentationList();
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access : {
                view : true,
                create : selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit : selectedRole.includes("global_coordinator") ||  selectedRole.includes("administrator")  ,
                delete : selectedRole.includes("administrator")  ||  selectedRole.includes("global_coordinator") ,
            },
            
        });
    }
    
        getPresentationList = async () => {
            this.setState({
                isLoading : true, 
            })
            const res = await API.get(`/recycle/list`)
            // const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
            
            if(res.data && Object.keys(res.data).length>0)  {
                (res.data||[]).forEach((item,index)=>{
                    item.record_type_csv=`${mapping[item.object_type]}-${item.object_id}`;
                    item.created_by=`${item.user?.first_name}-${item.user?.last_name}`;
                });
                this.setState(
                { 
                    presentationList: res.data,
                   isLoading:false
                }
            );}
            else 
            { this.setState(
                { 
                    presentationList: [],
                   isLoading:false
                }
            );}
        }
        handleRestore = async(row)=>{
            swal({
                text: "Are you sure, you want to restore this data?",
                icon: "warning",
                buttons: true,
            })
                .then(async(willRestore) => {
                    if (willRestore) {
                        try {
                            const res = await API.post(`/recycle/restore/${row.id}`,{ system_id :this.context.userSystemId});
                            let showError=false;
                            if(res&&res.data&&res.data.msg&&res.data.msg.toString().toLowerCase()==="Unable to create data to the database:".toLowerCase()){
                                showError=true;
                            }
                            this.setState({
                                success_body: "Restored successfully!",
                                successModal: !showError,
                                errorModal:showError,
                                hasChanged: false,
                                showHideModal: false,
                                viewEditFlag: null,
                                viewEditData: {},
                                tempArr : [],
                                arr : []
                            });
                         
                        } catch (e){
                            console.error("Error in Restoring Data: ", e);
                            this.setState({
                                errorModal:true,
                            })
                        }
                    }
                });

            
        }
        handleMultipleRestore = async()=>{
            try {
                const res = await API.post(`/recycle/multiple/restore`,{ system_id :this.context.userSystemId,ids:this.state.tempArr});
                let showError=false;
                if(res&&res.data&&res.data&&res.data.msg&&res.data.msg.toString().toLowerCase()==="Unable to create data to the database:".toLowerCase()){
                    showError=true;
                }
                this.setState({
                    success_body: "Restored successfully!",
                    successModal:!showError,
                    errorModal:showError,
                    hasChanged: false,
                    showHideModal: false,
                    viewEditFlag: null,
                    viewEditData: {},
                    tempArr : [],
                    arr : []
                });
             
            } catch (e){

            }
        }
    handleModalShowHide = async (flag, viewEditFlag, row) => {
        console.log(row)
          if (row && row.id && viewEditFlag === "edit") {
              await this.getPresentationById(row.id)
              this.setState({ showHideModal: flag, viewEditFlag });
              return true;
          }
          this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
      }
    
   
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getPresentationList();
    }
    selectRow = {
		mode: 'checkbox',
  clickToSelect: true,
  onSelect: (row, isSelect, rowIndex, e) => {
    console.log(row,isSelect,rowIndex)
    if (isSelect) {
        let arr = this.state.arr;
        arr.push(row.id);
        arr = [...new Set(this.state.arr)]
        console.log(arr)
        this.setState({
            tempArr: arr,
            arr: arr
        })

      }

      else{
        let arr = this.state.tempArr;
        arr = arr.splice(arr.indexOf(row.id),1)
        arr = [...new Set(this.state.arr)]
        console.log(arr)
        this.setState({
            tempArr: arr,
            arr: arr
        })
      }
  }
	  };
    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal,deleteModal,isLoading } = this.state;
        
        return <>
            <div className="inpage-content">
            {isLoading && <Loader />}
                {!isLoading &&  <Alert className="alert-head" key="submission" variant="light">
                    <Row>
                        <Col sm={6}>
                        <Alert.Heading>
                            <span><i class="bi bi-trash" style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                             Recycle Bin</Alert.Heading>
                        </Col>
                        {/* <Col sm="auto" className="ml-auto">
                            {this.state.access.create &&
                                <Link className={ this.state.tempArr.length > 0 ? "new-button-link" : "disabled-link"} onClick={() => this.handleMultipleRestore()} >
                                <span>
                                    <i 
                                    class="bi bi-arrow-counterclockwise" 
                                    style={{ fontSize: "1.2rem", margin: ".1rem", padding: ".1rem" }} onClick={() => this.handleMultipleRestore()}
                                    ></i></span>
                                Restore
                            </Link>}
                            
                        </Col> */}
                    </Row>
                </Alert>}
                {!isLoading &&  <Row>
                    {/* <Col md={6}>
                        <Dropdown  className="record_view_dropdown">
                            <Dropdown.Toggle variant="default"  id="dropdown-basic">
                                Recently Viewed
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Recently Viewed</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col> */}

                    <DatatableComponent id="presentationList" data={this.state.presentationList} columns={this.columns} rowEvents={this.rowEvents} pagination="true"  />
                </Row>}
            </div>
            {/* { showHideModal &&
                <PresentationModal
             showHideModal={showHideModal}
             handleModalShowHide={this.handleModalShowHide}
             handlePresentationSave={this.handlePresentationSave}
             handlePresentationUpdate={this.handlePresentationUpdate}
             viewEditFlag={viewEditFlag}
             viewEditData={viewEditData}
            />} */}
             {this.state.successModal &&
             <BootstrapModal
                 show={successModal}
                 header="Success"
                 body={success_body}
                 changeShowState={this.changeShowState}
             />
         }
        {this.state.errorModal &&
             <BootstrapModal
                 show={errorModal}
                 variant="danger"
                 header="Ooops!!"
                 body="An error occured! Please try again later!"
                 changeShowState={this.changeShowState}
             />
         } 
      </>
    }
}

export default withRouter(RecycleBin);