
import React, { Component } from 'react'
import { Button, Card, Col, Form, Navbar, Row, Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import API from '../api/apis';
import FileComponent from './common/file-component/FileComponent';
import InputField from './common/input-fields/InputField';
import BootstrapModal from './common/modal/BootstrapModal';
import StickyNav from './common/sticky-nav/StickyNav';
import AmendmentModal from './AmendmentModal';
import { dateChange, dateStringformat, dateTimeformat, getUserInfo, uploadFile } from '../utility/helperFunctions';
import UserContext from '../context/UserContext';
import { yyyymmddFormat } from './common/dateFormat';
import HistoryFile from './submission/detailSubComponents/HistoryFile';
import ReviewFile from './submission/detailSubComponents/section-1/ReviewFile';
import Loader from './common/loader/Loader';
import { APPROVED, DECLINED } from '../consts';
import { stateToHTML } from 'draft-js-export-html';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}&nbsp;
      &#x25bc;
    </a>
  ));
export default class AmendmentDetailView extends Component {
    static contextType=UserContext
    state = {
        externalurl :  "http://localhost:1337",
        // owner_id:this.context.userSystemId,
        showLoader : false,
        showLoaderUpload : false ,
        action : "",
        loadTable : true ,        
        viewEditData: {},
        showHideModal: false,
        viewEditFlag: null,
        fileList: [],
        selectedFile: null,
        successFile: false,
        errorFile: false,
        showLoader: false,
        AmendmentDetails: {},
        auditData:[],
        trailData:[],
        activeAmendment:null,
        amendmentData:null
        
    }
    get inputFieldsArray() {
        return [
           
            {
                label: "Amendment Title",
                type: "text",
                name: "amendmentTitle",
                disabled: true,
                
            },
            {
                label: "Protocol Version Number",
                type: "text",
                name: "protocol_version_number",
                disabled:true,
            },
            {
                label: "Approval",
                type: "dropdown",
                placeholder: "Awaiting Review",
                name: "approval",
                options: [
                    "None",
                    "Awaiting Review",
                    "Approved",
                    "Declined",
                    "Under Review"
                ],
                disabled:true
            },
            {
                label: "Decision Date",
                name: "decision_date",
                type: "date",
                disabled:true
            },
            {
                label: "[EXT] Investigator Rationale",
                type: "textarea",
                name: "investigator_rationale",
                disabled:true

            },


            {
                label: "Lilly Feedback",
                type: "text-editor",
                name: "feedback",
                isReadOnly:true,
              
            },
            {
                label: "Submission",
                type: "text",
                name: "submissionId",
                disabled:true

            },
            // {
            //     label: "Owner",
            //     type: "text",
            //     name: "owner_id",
            //     disabled:true

            // },
            
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled:true
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
                disabled:true
            },
           


        ];
    }
     async getExternalUrl() {
        let response = '';
        try {
            response = await API.get(`user/externalurl`);
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
            externalurl: (response && response.data) ? response.data.exturl : "http://localhost:1337",
        })
    }

    async componentDidMount() {
        await this.getExternalUrl();
        // await this.getAmendmends();
        if(this.props.review_for){
            await this.getAmendmends();//if reviewfor then only call
        }
      
    }
    getAmendmends=async()=>{
        try {
            let {submission_id, review_for}=this.props;
            const res = await API.get(`amendment/submission/${submission_id}`)
            let activeAmendment=null;
            if(res.data&&res.data.length>0){
                res.data.map((item,index)=>{
                    item["amendmentTitle"] = item.title;
                });               
                // activeAmendment=res.data[0];
                activeAmendment=res.data.find(p=>p.id==review_for);
            }
            this.setState({ amendmentData: res.data,activeAmendment },()=>{  
                if(this.state.activeAmendment){
                    this.getAmendmentById();
                }    
            });
          
        }
        catch (error) {
           
        }
   }
    renderFiles=()=>{
        let downloadableDocuments=this.state.fileList||[];
        return <div className='d-flex '>
                <ul className='d-flex flex-wrap m-0 p-0'>
                {downloadableDocuments&&downloadableDocuments.map((item,index)=>{
                    let fileName=item.key.split("\/").pop();
                    return <li key={index} onClick={e=>this.handleDownload(fileName)} className='d-flex align-items-center p-2' style={{cursor:'pointer'}}>
                                <span>{fileName}</span>
                                <span className="material-icons ml-3">
                                    file_download
                                </span>
                    </li>
                })}
                </ul>
            </div>
    }
    getEditorValueFromHtml = (html="") => {

        if(html){

            const blocksFromHTML = convertFromHTML(html);        

            const state = ContentState.createFromBlockArray(blocksFromHTML);

            return EditorState.createWithContent(state);

        }

        return "";

    }
    getAmendmentById = async () => {
        let {id}=this.state.activeAmendment;
        let rowIndex=this.state.amendmentData.findIndex(p=>p.id===id);
        const res = await API.get(`amendment/getbyid/${id}`);       
        await this.getFilesList(res.data.submission_id);
        res.data["protocol_version_number"]=res.data.protocol_version_number;
        res.data["feedback"]= res.data.feedback !=="" ? this.getEditorValueFromHtml(res.data.feedback) : "";
        res.data["investigator_rationale"]=res.data.investigator_rationale !== null ? res.data.investigator_rationale.replace(/<[^>]+>/g, '') : "";
        res.data["amendmentTitle"] = res.data?.title;
        res.data["decision_date"] = res.data.decision_date !== "" && res.data.decision_date !== null ?
        dateStringformat(res.data.decision_date) : null;
        res.data["createdby"] = res.data.created_by?.length ? await getUserInfo(res.data.created_by) + "  " + dateTimeformat(res.data.createdAt) : "";
        res.data["updatedby"] = res.data.updated_by?.length > 0 ? await getUserInfo(res.data.updated_by) + "  " + dateTimeformat(res.data.updatedAt):res.data.createdby ;
      
        this.setState({
            AmendmentDetails: res.data,
            showLoader: false
        });
    }
   
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
           
            return (
                <Col sm={12} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state.AmendmentDetails[field.name]}                       
                    />
                </Col>
            )
        });
    }
    getFilesList = async (submission_id) => {
        try {
            this.setState({
                showLoader: true,
                action: "fetching",
                loadTable: false
            })
            let {id}=this.state.activeAmendment;
            const res = await API.get(`/user/proposals3/getByid/${submission_id}`);
            this.setState({
                showLoader : false,
                loadTable : true,

                fileList: res.data?.filter(file => file.type === `Amendment_${id}`)
            });
        } catch (e) {
            this.setState({
                showLoader : false,
                loadTable : true
            })
            console.log(e)
        }
        console.log("fileList",this.state.fileList)
    }
   

    handleDownload = async (fileName) => {
        try {
            this.setState({
                showLoader : true,
                action : "download"
            })
           let {id}=this.state.activeAmendment;
           let {submission_id}=this.props;
            let fileUrl = `${this.state.externalurl}/multiple/filedownload/Amendment/${submission_id}/${id}?name=${fileName}`

            fetch(fileUrl, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            })
                .then(async(response) => {
                    if (response.ok) {
                        
                        return response.blob();
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            errorModal: true
                        });

                        throw new Error('Error Encountered.Please try again.');
                    }
                })
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', decodeURIComponent(fileName));

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    this.setState({
                        showLoader : false,
                        successFile: true,
                        fileMessage:"File Downloaded Successfully!"
                    })
                });
        } catch (e) {
            this.setState({
                errorFile: true,
                showLoader : false
            })
            console.log(e);
        }
    }
   
    renderFiles=()=>{
        let downloadableDocuments=this.state.fileList||[];
        return <div className='d-flex '>
                <ul className='d-flex flex-wrap m-0 p-0'>
                {downloadableDocuments&&downloadableDocuments.map((item,index)=>{
                    let fileName=item.key.split("\/").pop();
                    return <li key={index} onClick={e=>this.handleDownload(fileName)} className='d-flex align-items-center p-2' style={{cursor:'pointer'}}>
                                <span>{fileName}</span>
                                <span className="material-icons ml-3">
                                    file_download
                                </span>
                    </li>
                })}
                </ul>
            </div>
    }
    render() {
        const { amendmentData,activeAmendment,showLoader,AmendmentDetails} = this.state;
        if(!amendmentData||!activeAmendment )
        return <Loader></Loader>;
        let style={
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
          } 
        return (
            <>
                <div className='d-flex align-items-center p-2' style={{fontSize:'1rem'}}>
                    <span className="mr-2 font-weight-bold">Amendment Details for {activeAmendment.id}</span> 
                    {/* <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                          <span>{activeAmendment.amendmentTitle||''}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                amendmentData&&amendmentData.map((item,index)=>{
                                    return <Dropdown.Item onClick={(e)=>{
                                        this.setState({
                                            activeAmendment:item,
                                            AmendmentDetails:null,
                                            fileList:[]
                                        },()=>{
                                            this.getAmendmentById();
                                        })
                                    }  } key={item.id} eventKey={index}>{item.amendmentTitle}</Dropdown.Item>;
                                })
                            }                          
                        </Dropdown.Menu>
                    </Dropdown> */}
               </div>           
                <Row>
                    <Col md={12}>
                        <Card className=" pt-3" style={style}>
                            <Card.Body>
                                <Row>
                                    {AmendmentDetails&&this.renderInputFields()}
                                </Row>
                                <Row>
                                    <Col>
                                         {this.renderFiles()}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                   
                </Row>
              
            </>
        )
    }
}


