import React from 'react'
import { Button } from 'react-bootstrap';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import Review from '../Review';
import {isCoordinator, getSubmissionText, userPermissions ,showMessage} from '../commonFunctions'
import swal from 'sweetalert'
import DatatTableEx from '../../common/DataTableEx';

import { yyyymmddFormat } from '../../common/dateFormat';
import ToolTipEx from '../../common/ToolTipEx';
import { iirDateFormat } from '../../../utility/dateHelper';
export default class ReviewListTable extends React.Component{
    static contextType = UserContext;
    constructor(props) {
        super(props);   
        this.state={
            toReviewList:[],
            filterSource:[],
            activeFormData:{},
            activeForm:'',
            showLoader:false,
            userPermissions:{view:true},
            is_Coordinator:false
        }     
    }
    formType={
        REVIEW:'REVIEW'
    }
    seviceURLS={
        "Concept":'reviewConcept/submission/conceptList',
        "Proposal":'submissionReview/submission/proposalList',
        "Protocol":'submissionReview/submission/protocolList',
        "Publication":'submissionReview/submission/publicationList',
        "Amendment":'submissionReview/submission/amendmentList',
        "noUrl":'/'
    }
    columns = [
        {
            dataField: 'key',
            text: 'key',
            sort: true,
            hidden:true,
            csvExport: false
        },
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
        },
        {
            dataField: 'review_type',
            text: 'Review Type',
            sort: true,
        },
        {
            dataField: 'created_at',
            text: 'Assigned Date',
            sort: true,
           
            // formatter:(cell, row, rowIndex)=>{
            //     return row.created_at?yyyymmddFormat(row.created_at):'';
            // }
        },
        {
            dataField: 'assigned_by',
            text: 'Assigned By',
            sort: true
            
        },
        {
            dataField: 'reviewers',
            text: 'Reviewers',
            sort: true,
            formatter: (cell, row, rowIndex) =>{
                return <ToolTipEx tooltip={row.reviewers}><div  style={{maxWidth:'280px',whiteSpace:"nowrap", overflow:'hidden',textOverflow:'ellipsis'}}>{row.reviewers}</div></ToolTipEx>
            }
        },
        {
            dataField: 'date_review_completed',
            
            text: 'Date Review Completed',
            sort: true,
            formatter:(cell, row, rowIndex)=>{
                return row.date_review_completed?yyyymmddFormat(row.date_review_completed):'';
            }
        },
        {
            dataField: 'PI_lastname',
            text: 'PI Last Name',
            sort: true,
        },
        // {
        //     dataField: 'invite_proposal',
        //     text: 'Invite Proposal',
        //     sort: true
        // },
        {
            dataField: 'supported',
            text: 'Supported',
            sort: true,
            csvExport: false    
            // formatter: (cell, row, rowIndex) => row.supported ? `0 / ${row.supported}` : ""
        },
        {
            dataField: 'supportedCsv',
            text: 'Supported',
            sort: true,
            hidden:true
        },
        {
            dataField: 'status',
            text: 'Status Health',
            sort: true,
            formatter: (cell, row, rowIndex,) => {
                return <div className='d-flex align-items-center pl-2 pr-2'>
                    <div className='flex-grow-1 pr-3' style={{whiteSpace:'nowrap'}}>{row.status}</div><div className='d-flex align-items-center justify-content-left' style={{maxWidth:'175px',minWidth:'150px'}}>{ this.generateActionButton(row)}</div>
                </div>
            }
        }
        ];
        componentDidMount() {
            this.getMilestoneData();
           
           
        }
        getMilestoneData=async()=>{
            try {
                let res = await API.get(`reviewsTab/reviewType/list/${this.props.submissionId}`);
                let reviewbutton =
                    res?.data[this.props.review_type.toLowerCase()]?.length ?? 0;
                this.setState({
                    milestoneData: res?.data ?? [],
                    reviewButton: reviewbutton > 0

                }, this.handleInitialData)
            }
            catch (exp) {
                console.error(exp)

            }
        }
        handleInitialData=()=>{
            let permissionSet=userPermissions(this.context);
            let _isCoordinator=isCoordinator(this.context);
            this.setState({userPermissions:permissionSet,is_Coordinator:_isCoordinator, toReviewList:[],
                filterSource:[]},()=>{
                this.getToReviewList(this.context.userSystemId);
            });
        }
        componentDidUpdate(prevProps,prevState){
            if(prevProps.review_type!=this.props.review_type){
                this.getMilestoneData();
            }
        }
        getToReviewList = async (id) => {
            this.setState({showLoader:true});
           
            try{
                let formBody={
                    "submissionid": this.props.submissionId,
                    "review_type": this.props.review_type||''
                
                }                
                const res = await API.post(this.seviceURLS[this.props.review_type||'noUrl'], formBody);                
                this.setState({showLoader:false});
                if(res&&res.data){
                    let reviewList= res.data.map((item,index)=>{    
                             
                        let {id,submission_id,created_at,date_review_completed,investigator_last_name,stage,review_type,assigned_by,reviewer_name,reviewer_id,reviewers_details,status,review_status}=item;
                        let title=getSubmissionText(submission_id);                      
                        let reviewers=reviewers_details.map((r,i)=>r.reviewer_name).join(",");
                        let submittedList=reviewers_details.filter(p=>p.status==="SUBMITTED").length;
                        let supported=`${submittedList} / ${reviewers_details.length}`;
                        let supportedCsv = `${submittedList} out of ${reviewers_details.length}`;
                        created_at = created_at?iirDateFormat(created_at):created_at;
                        return {
                            key : id,
                            title,created_at,
                            stage,
                            due_date:"",
                            assigned_by,
                            reviewers,
                            reviewers_details,
                            date_review_completed,
                            "PI_lastname":investigator_last_name,
                            // "invite_proposal":invite_proposal,
                            supported,
                            supportedCsv,
                            "status":review_status||"In Progress",
                            reviewer_id,
                            review_type,
                            review_status
                           
                        }
                     
                    });
                    let filterSource=await this.getFilterSource({data:reviewList});                   
                    this.setState({ toReviewList: reviewList || [] ,filterSource:[...filterSource]});
                 }
                 else{
                    this.setState({ toReviewList: [] ,filterSource:[]});
                }
            }
            catch(exp){

            }
            finally{
                this.setState({showLoader:false}); 
            }
        }
        handleCoordinatorReview=(data)=>{
            let {submissionId}=this.props;
             let {activeForm,activeFormData}=this.state;
             activeForm=this.formType.REVIEW;
            
             activeFormData={
                 "submission_id": submissionId,
                 review_header_id:data.key,
                 isCoordinatorApproval:true,
                 review_type:data.review_type,
                 formType:"COORDINATOR"
             }
             this.setState({activeForm,activeFormData});
         }
         handleNonCoordinatorReview=(data)=>{
            let {submissionId}=this.props;
             let {activeForm,activeFormData}=this.state;
             activeForm=this.formType.REVIEW;
             let myInfo=data.reviewers_details.find(p=>p.reviewer_system_id===this.context.userSystemId);
             activeFormData={
                "submission_id": submissionId,
                "reviewers_detail_id": myInfo.id,
                "review_header_id":  data.key,
                review_type:data.review_type,
                "isCommiteeReview": myInfo["is_committee"]
             }
             this.setState({activeForm,activeFormData});
         }
         handleEvents=(event,data)=>{
             switch(event){
                 case "CANCEL-VIEW": this.setState({activeForm:"",activeFormData:{}});break;
             }
         }
         handleOnModalclose=(e)=>{
             this.setState({activeForm:"",activeFormData:{}},()=>{
                 this.getToReviewList();
             });
         }
        generateActionButton=(row)=>{
            let {is_Coordinator,reviewButton}=this.state;
           
            let pendingReview=row.reviewers_details.find(p=>p.status!="SUBMITTED"&&p.reviewer_system_id===this.context.userSystemId);
            let anyPendingReview=row.reviewers_details.filter(p=>p.status!="SUBMITTED").length>0;
            let isReviwerExist=row.reviewers_details.length>0;
            let reviewed=row.reviewers_details.filter(p=>p.status==="SUBMITTED");
            let imReviewd=reviewed.find(p=>p.reviewer_system_id===this.context.userSystemId);
            let isPrevSeqPending=false;
            /* condition for sequential order availability for the review*/
            if(!is_Coordinator ){                
               let SequentialList= row.reviewers_details.filter(p=>p.approval_order_type==="Sequential");
               let mySequential=SequentialList.find(p=>p.reviewer_system_id===this.context.userSystemId);
               if(mySequential){               
                    let previousOrder=SequentialList.find(p=>p.approval_order===(mySequential["approval_order"]-1));
                    if(previousOrder&&(!previousOrder.status&&!previousOrder.status!="SUBMITTED")){
                        isPrevSeqPending=true;    
                    }
               }
            }
            /* EOC */
            if(row.review_status){
                return <></>;
            }
            else if(is_Coordinator){
                if(!isReviwerExist&&this.state.reviewButton===true) return <Button size="sm" className="d-flex align-items-center" variant="outline-danger" onClick={e=>{
                    if(this.props.handleExternalEvents){
                        this.props.handleExternalEvents({option:'IR'})
                    }
                }}> <span className="material-icons mr-1 m-0">
                pageview
                </span><span style={{fontSize:' 0.75rem'}}>Initiate Review</span></Button>
                else if(pendingReview||anyPendingReview) return <Button style={{marginRight:"100px"}}   className="d-flex align-items-center" size="sm" variant="outline-success" onClick={e=>{
                    if(this.props.handleExternalEvents){
                        this.props.handleExternalEvents({option:'SR'})
                    }
                }}> <span className="material-icons mr-1 m-0">
                pageview
                </span><span style={{fontSize:' 0.75rem'}}>Review</span></Button>
                else if(isReviwerExist&&reviewed.length===row.reviewers_details.length) return <Button size="sm"  className="text-nowrap d-flex align-items-center"   onClick={e=>this.handleCoordinatorReview(row)} variant="outline-success"><span className="material-icons mr-1 m-0">
                pageview
                </span><span style={{fontSize:' 0.75rem'}}>Coordinator Documentation</span></Button>
                else <Button size="sm"  className="d-flex align-items-center" variant="outline-success" onClick={e=>{
                    if(this.props.handleExternalEvents){
                        this.props.handleExternalEvents({option:'SR'})
                    }
                }}>View All</Button>
            }
            else{
                if (imReviewd || isPrevSeqPending) return <Button size="sm" className="d-flex align-items-center" variant="outline-success" disabled={true}> <span className="material-icons mr-1 m-0">
                    pageview
                </span><span style={{ fontSize: ' 0.75rem' }}>Review</span></Button>
                else if (pendingReview) return <Button size="sm" className="d-flex align-items-center" onClick={e => this.handleNonCoordinatorReview(row)} variant="outline-success"> <span className="material-icons mr-1 m-0">
                    pageview
                </span><span style={{ fontSize: ' 0.75rem' }}>Review</span></Button>
                else <Button size="sm" className="d-flex align-items-center" variant="outline-success" >View All</Button>
            }
        }  
        getFilterSource=async (res)=>{
            if(res&&res.data&&res.data.length>0){
                let filterCollection= this.columns.filter(k=>!k.hidden).map((col,index)=>{
                    let columnValues=(res.data || []).map((item,key)=>{
                        if(col.dataField.split(".").length>1){
                            return item[col.dataField.split(".")[0]][col.dataField.split(".")[1]];
                        }
                        return item[col.dataField];
                    });
                    return {group:col.text,key:col.dataField,items:[...new Set(columnValues)]};
                });
                return filterCollection;
            }
            return null;
              
        }
        handleRowDetails=(row)=>{   
               
            //return this.conceptDetails(row)
        }
        handleFormEvents=(option)=>{
            this.setState({activeForm:option});
        }
        showReviewForm=(open)=>{
            if(open){

            }
        }
        handleModalCallBack=(response)=>{
            if(response===true){
                let callBack=(val)=>{
                    this.setState({activeForm:''},()=>{
                        this.getToReviewList();
                    });
                  }
                  showMessage({                  
                    text: "Saved Successfully",
                    icon: "success",
                    button: "Ok",
                  
                  },callBack);
            } 
            else{
                this.setState({activeForm:''});
            }           
        } 
    render(){
        let {userPermissions,toReviewList}=this.state;
        let csv_name=`${this.props.review_type||''}_list_details`;
        let title=`${this.props.review_type||''} Reviews`;
        let pagination={};
        if(toReviewList&&toReviewList.length>5){
            pagination={pagination:"true"};
        }
        return <><DatatTableEx
        id="review_list_details"
        title={title}
        data={this.state.toReviewList}        
        columns={this.columns}
        {...pagination}
        showLoader={this.state.showLoader}
        onClick={e=>{e.preventDefault();}}
        filterSource={this.state.filterSource}
        filterChange={()=>{}}        
        handleRowDetails={this.handleRowDetails}
        csvName={csv_name} />
            {this.state.activeForm === this.formType.REVIEW && <Review
                onClose={this.handleOnModalclose}
                data={this.state.activeFormData}
                modalInfo={{ title: "", titleDescription: "", show: true }}
                handleEvents={this.handleEvents} >
            </Review>}
            
        </>
    }
}