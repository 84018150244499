import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ReviewConcept from './review-forms/ReviewConcept';
import ReviewProtocol from './review-forms/ReviewProtocol';
import ReviewAmendment from './review-forms/ReviewAmendment';
import ReviewPublication from './review-forms/ReviewPublication';
import ReviewApplicationProposal from './review-forms/ReviewApplicationProposal';

export default class NewReview extends Component {
    
    componentDidMount(){
        console.log(this.props)
    }
    onNavOut = () => {
        this.props.history.push(`/submissions/${this.props.match.params.submissionId}`)
    }
    render() {
        return (
            <div>
                <Card className="data-cards">
                <div onClick={this.onNavOut} className="back-button mt-3 ml-2 mb-0">
                    <i className="bi bi-arrow-left">
                        {" Submission-"}{this.props.match.params.submissionId}
                            </i>
                </div>
                        <h2 className="text-center">Review Forms</h2> <hr/>
                        <Card.Body>
                            <Tabs className="f-flex justify-content-between pr-3 pl-2"  >
                                <Tab eventKey="Concept" title="Review Concept">
                                    <ReviewConcept submissionId={this.props.match.params.submissionId} />
                                </Tab>
                                <Tab eventKey="Proposal" title="Review Application Proposal">
                                    <ReviewApplicationProposal submissionId={this.props.match.params.submissionId} />
                                </Tab>
                                <Tab eventKey="Protocol" title="Review Protocol">
                                    <ReviewProtocol submissionId={this.props.match.params.submissionId}/>
                                </Tab>
                                <Tab eventKey="Amendment" title="Review Amendment">
                                    <ReviewAmendment submissionId={this.props.match.params.submissionId}/>
                                </Tab>
                                <Tab eventKey="Publication" title="Review Publication">
                                    <ReviewPublication submissionId={this.props.match.params.submissionId}/>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
            </div>
        )
    }
}
