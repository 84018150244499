import React, { Component } from 'react'
import { Button, Col, Row, Card, Form } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar'
import StickyNav from '../../../../common/sticky-nav/StickyNav';
import InputField from '../../../../common/input-fields/InputField';
// import History from './History';
import API from '../../../../../api/apis';
import { withRouter } from 'react-router';
import Loader from '../../../../common/loader/Loader';
import BootstrapModal from '../../../../common/modal/BootstrapModal';
import swal from 'sweetalert'
import UserContext from '../../../../../context/UserContext';

import { yyyymmddFormat } from '../../../../common/dateFormat';

import { getUserInfo, dateTimeformat, dateStringformat, enableWFActions } from '../../../../../utility/helperFunctions';
import ReviewerModal from './ReviewerModal';



class ReviewerDetails extends Component {
    static contextType = UserContext;

    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        successModal: false,
        errorModal: false,
        hasChanged: false,
        submissionId: "Submission-" + this.props.location.state.submissionId,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
    }


    async componentDidMount() {
        await this.getReviewerById(this.props.location.state.reviewerId);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            submissionId: "Submission-" + this.props.location.state.submissionId,

            access: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    getReviewerById = async (id) => {
        try {
            const reviewerDetails = await API.get(`review/reviewer/getbyid/${id}`);
            const viewEditData = { ...reviewerDetails.data };
            // this.context.userSystemId === this.state.reviewer_system_id && 
            reviewerDetails.data["review_type"] = reviewerDetails.data["review_type"];
            reviewerDetails.data["due_date"] = reviewerDetails.data["due_date"] ? dateStringformat(reviewerDetails.data["due_date"]): "";
            reviewerDetails.data["reviewer_id"] = reviewerDetails.data["reviewer_system_id"];
            reviewerDetails.data["reviewer_system_id"] = reviewerDetails.data.reviewer_system_id?.length ? await getUserInfo(reviewerDetails.data.reviewer_system_id) : "";
            reviewerDetails.data["submissionId"] = "Submission-" + reviewerDetails.data.submission_id;
            reviewerDetails.data["created_by"] = reviewerDetails.data.created_by?.length ? await getUserInfo(reviewerDetails.data.created_by) + "  " + dateTimeformat(reviewerDetails.data.createdAt) : "";
            reviewerDetails.data["updated_by"] = reviewerDetails.data.updated_by?.length ? await getUserInfo(reviewerDetails.data.updated_by) + "  " + dateTimeformat(reviewerDetails.data.updatedAt) : reviewerDetails.data["created_by"];

            this.setState({
                ...reviewerDetails.data,
                showLoader: false,
                viewEditData: viewEditData

            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }


    get inputFieldsArray() {
        return [
            {
                label: "Review Type*",
                type: "text",
                name: "review_type",
                disabled: true
            },
            {
                label: "Reviewer*",
                type: "text",
                name: "reviewer_system_id",
                disabled: true
            },
            {
                label: "Comments*",
                type: "textarea",
                name: "comments",
                disabled: true
            }, 
            {
                label: "Due Date *",
                type: "date",
                name: "due_date",
                disabled: true,
                minDate: new Date()
            },
            {
                label: "Submission",
                type: "text",
                placeholder: "Select Submission",
                name: "submissionId",
                disabled: true
            },
            {
                label: "Assigned By",
                type: "text",
                name: "created_by",
                className: "createUpdateByfontsize",
                disabled: true,
            }, {
                label: "Last Modified By",
                type: "text",
                name: "updated_by",
                className: "createUpdateByfontsize",
                disabled: true,
                edit: true
            },

        ];
    }


    onInputChange = (e) => {
        this.setState({ hasChanged: true })
        this.setState({ [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({ hasChanged: true })
        this.setState({
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({ hasChanged: true })
        this.setState({
            [name]: e,
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {

            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.props.location.state.submissionId}`)
    }


    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false,
            successFile: false,
            errorFile: false,
        });
        this.getReviewerById(this.props.location.state.reviewerId);
    }


    handleModalShowHide = async (flag, viewEditFlag) => {
        this.setState({ showHideModal: flag, viewEditFlag });
    }


    handleReviewerUpdate = async (state) => {
        delete state.showLoader;
        delete state.created_by;
        state.updated_by = this.context.userSystemId;
        state.submissionId = this.props.location.state.submissionId;
        // state.created_by = this.context.userSystemId;
        try {
            const res = await API.post("review/reviewer/update", state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getReviewerById(this.props.location.state.reviewerId)

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }


    handleDelete = async (fileName) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    try {
                        const res = API.delete(`/review/reviewer/delete/expense/${this.props.location.state.submissionId}/${this.props.location.state.expensesId}`);
                        swal("Deleted Successfully", {
                            icon: "success",
                        });

                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }

                }
            });
        // try {
        //     const res = await API.delete(`/study/delete/expense/${this.props.location.state.submissionId}/${this.props.location.state.expensesId}`);
        //     this.setState({
        //         successFile: true,
        //         fileMessage: "File Deleted!"
        //     })
        // } catch (e) {
        //     this.setState({
        //         errorFile: true
        //     })
        //     console.log(e);
        // }
    }


    render() {
        const { showHideModal, viewEditFlag, viewEditData, success_body, errorModal } = this.state;

        if (!this.state.reviewer_system_id)
            return <Loader />
        return <>
            <StickyNav>
                <Navbar expand="sm" className="nav-secondary">
                    <div onClick={this.onNavOut} className="back-button">
                        <i className="bi bi-arrow-left">
                            {" "}{this.state.submissionId}
                        </i>
                    </div>
                    <h4 className="text-center" style={{ "width": "66%" }}>Reviewer- {this.state.reviewer_system_id} </h4>
                    {enableWFActions() && (this.state.access.edit || (this.context.userSystemId === this.state.reviewer_id)) && <div className="text-right" style={{ "width": "20%" }}>
                        <Button onClick={() => this.handleModalShowHide(true, "edit")}> Edit </Button>
                    </div>}
                </Navbar>
            </StickyNav>
            <Row>
                <Col md={6}>
                    <Card className="data-cards pt-3">
                        <Card.Body>
                            <Row>
                                {this.renderInputFields()}
                                {/* <div className="w-100 d-flex justify-content-center">
                                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                                        Save
                                    </Button>
                                </div> */}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>

                </Col>
            </Row>
            {
                showHideModal &&
                <ReviewerModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleReviewerSave={this.handleReviewerSave}
                    handleReviewerUpdate={this.handleReviewerUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    submissionId={this.props.location.state.submissionId}
                />
            }
            {this.state.successModal &&
                <BootstrapModal
                    show={this.state.successModal}
                    header="Success"
                    body="Review has been Updated!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={this.state.errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.successFile &&
                <BootstrapModal
                    show={this.state.successFile}
                    header="Success"
                    body={this.state.fileMessage}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorFile &&
                <BootstrapModal
                    show={this.state.errorFile}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }

        </>
    }
}

export default withRouter(ReviewerDetails);
