import React, { Component } from 'react'
import {  Col, Container, Modal, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { dateStringformat, dateTimeformat, getUserInfo } from '../utility/helperFunctions';
import { yyyymmddFormat } from './common/dateFormat';
import InputField from './common/input-fields/InputField';
import Loader from './common/loader/Loader';
import Button from './common/Button'
class NewPublicationEx extends Component {
    state = {
        title: "",
        submissionId:`Submission- ${this.props.submission_id}`,
        isLoader:true,
        review:"Not Started",
        date_of_publication:null,
        planned_date_of_submission:null
    }
    get inputFieldsArray() {
        return [
            {
                label: "Title *",
                type: "textarea",
                name: "title",
                helptext: "Title of Publication"
            },
            {
                label: "Format",
                type: "dropdown",
                placeholder: "None",
                name: "format",
                options: ["None","Manuscript for Journal", "Abstract", "Poster", "Presentation", "Publication", "Other"],
                helptext: "Format of Publication (Journal Article, Presentation, Interim Report, Abstract/Poster, Final Study Report/Draft Manuscript, etc.)"
            },
            {
                label: "Primary Author",
                type: "text",
                placeholder: "Enter Primary Author",
                name: "primary_author",
                helptext:"Primary Author of Publication"

            },
            {
                label: "Venue",
                type: "text",
                placeholder: "Enter Venue",
                name: "venue",
                helptext:"Where was the publication presented (New England Journal of Medicine, Conference, etc.)"

            },
            {
                label: "Review",
                type: "dropdown",
                placeholder: "Not started",
                name: "review",
                options: ["None","Review Complete","Under Review","Not started"],
                helptext:"Current review status of publication"
            },
            {
                label: "Comments",
                type: "textarea",
                placeholder: "Enter Comments",
                name: "comments",
                rows: "1",

            },
            {
                label: "Link to Publication",
                type: "textarea",
                placeholder: "Enter Link to Publication",
                name: "link_to_publication",
                rows: "1",

            },
            {
                label: "Date of Publication",
                type: "date",
                placeholder: "Enter Date of Publication",
                name: "date_of_publication",

            },
            {
                label: "Planned Date of Submission",
                type: "date",
                placeholder: "Enter Planned Date of Submission",
                name: "planned_date_of_submission",

            },
            {
                label: "Submission",
                type: "text",
                placeholder: "Enter Submission",
                name: "submissionId",
                disabled:true

            },
            {
                label: "Lilly Feedback",
                type: "textarea",
                name: "lilly_feedback",
            },
            {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                className:"createUpdateByfontsize",
                disabled:true,
                edit:true
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                className:"createUpdateByfontsize",
                disabled:true,
                edit:true
            },

        ];
    }
    async componentDidMount() {
        const { viewEditFlag, viewEditData } = this.props;
        if (viewEditFlag === "edit") {
            viewEditData["date_of_publication"] = viewEditData.date_of_publication != "" && viewEditData.date_of_publication != null ?
            dateStringformat(viewEditData.date_of_publication) : null;
            viewEditData["planned_date_of_submission"] = viewEditData.planned_date_of_submission != "" && viewEditData.planned_date_of_submission != null ?
            dateStringformat(viewEditData.planned_date_of_submission) : null;
            viewEditData["lilly_feedback"]=viewEditData.lilly_feedback !== null ? viewEditData.lilly_feedback.replace(/<[^>]+>/g, '') : "";
            viewEditData["submissionId"]="Submission-"+viewEditData.submission_id;
            viewEditData["createdby"] = viewEditData.created_by?.length ?await  getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt) : "";
            viewEditData["updatedby"] = viewEditData.updated_by?.length > 0 ? await getUserInfo(viewEditData.updated_by) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
            this.setState({
                //set Your form state details here
                ...this.state,
                isLoader:false,
                ...viewEditData,
                
            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
    }
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else 
        { this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_province_id: e.value,
            // stateName:e.name
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = { title: this.state.title, }
        const errors = {}
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors }
            });
        });

        return is_valid;
    }
    onSave = () => {
        if (this.formValidation()) 
        { this.props.handlePublicationSave(this.state) }

    }
    onUpdate = () => {
        if (this.formValidation()) 
        { this.props.handlePublicationUpdate(this.state) }

    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, handlePublicationSave, viewEditFlag, handlePublicationUpdate } = this.props;
        
        return (
            <div>
                <Modal show={showHideModal} size="lg" >
                {this.state.isLoader=== true ?
        <Loader /> :   <div> <Modal.Header closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                            {viewEditFlag === "edit" ?
                                `Edit Publication ` : "New Publication"}
                        </Modal.Title>
                    </Modal.Header>
                     <Modal.Body className="new-flow-modal">
                   <Row>
                            {this.renderInputFields()}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="w-100 d-flex justify-content-center">
                        <Button style="danger w-100px" className="p-2" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        <Button style="success w-100px" className="p-2 d-flex align-items-center justify-content-center" onClick={e => {
                            if (viewEditFlag === "edit") { this.onUpdate(); }
                            else {
                                this.onSave();
                            }
                        }}>
                            <i className="bi bi-plus" ></i>  <span>Add</span>
                        </Button>

                    </Modal.Footer>  </div>}
                </Modal>
            </div>
        )
    }
}


export default NewPublicationEx;