import React,{useState,useEffect} from 'react'
import styles from './index.module.css'

export default ({className,style,children,onClick,tag,disabled})=>{
    const[classes,setClasses]=useState([]);
    const[attributes,setAttributes]=useState({});
    useEffect(()=>{
       if(style&&style.length>0){
        style.split(' ').forEach((item)=>{
            classes.push(styles["btn_"+item]);
        });
        setClasses([...classes]);

       }
       handleAttributes();
    },[])
    useEffect(()=>{
        handleAttributes();
     },[disabled])
     const handleAttributes=()=>{
        if(disabled){
            attributes.disabled="disabled";
            setAttributes({...attributes});
        }
        else{
            setAttributes({});
        }
     }
    return <a {...attributes} onClick={e=>{
        e.preventDefault();
        if(onClick){
            onClick(e,tag);
        }
        return false;
    }} className={[styles.btn,...classes,className].join(" ")}>{children}</a>
}