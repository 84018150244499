import React, { Component } from 'react';
import { Accordion, Button, Card, Col, Container, Row } from 'react-bootstrap';
import InputField from './common/input-fields/InputField';
import API from '../api/apis';
import BootstrapModal from './common/modal/BootstrapModal';
import { DOSING_DSCHEDULE } from '../consts';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import UserContext from '../context/UserContext';
import { dateChange } from '../utility/helperFunctions';

export default class NewProtocol extends Component {
    static contextType = UserContext;
    titleRef = React.createRef();
    state = {
        createdByID: "",
        protocolSubmitted: false,
        submissionId: `Submission-${this.props.location.state.submissionId}`,
        protocolTitle: "",
        last_updated_by: "",
        iACUCDocumentationAttached: "None",
        descriptionOfAnimalUsed: "",
        dosesConcentrations: "",
        aCAndUCommitteeApproval: "None",
        endpoints: "",
        modelMethodology: "",
        targetStartDate: null,
        statisticalAnalysis: "",
        reportSubmissionDate: null,
        humanSamplesInvolved: "None",
        publicationDateAnticipated: null,
        typeOfHumanSamples: "None",
        projectedMilestoneTiming: "",
        uniquePersonalClinicalSamples: "None",
        biobankOwner: "None",
        sampleSize: "",
        dateOfFirstPatientVisit: null,
        numberOfTherapyGroups: "",
        dateFirstPtEntersTreatment: null,
        anticipatedQuarterlyEnrollment: "",
        dateLastPatientEntersTrial: null,
        numberOfinvestigativeSite: "",
        dateLastPatientEntersTreatment: null,
        potentialCountries: "",
        dateOfLastPatientVisit: null,
        multiCountryTrial: false,
        dateOfReportSubmission: null,
        clinicalTrialPhase: [],
        includePreClinicalPortion: false,
        dateOfPublicationSubmission: null,
        assignmentToTreatment: [],
        leadInPeriod: [],
        design: [],
        blinding: [],
        controls: [],
        blindingDoneBy: "None",
        extension: [],
        patientType: [],
        extensionLength: "",
        patientGender: [],
        ageRangeStart: "",
        patientTreatmentPeriod: "",
        ageRangeEnd: "",
        // LineOfTherapy:"None",
        // studyOnOffLabel:"None",
        lillyToSupplyStudyCompound: "None",
        ifNoWhy: "None",
        lillyStudyCompound1: "",
        lillyStudyCompound1Other: "",
        compound1AvailableMaterial: "",
        compound1AvailableMaterialOther: "",
        compound1Formulation: "",
        compound1DosingSchedule: "",
        compound1DosingSchedulOther: "",
        compound1AdministeredDose: "",
        // compound1AmountQuantity: "",
        requestedAmtOfComp1PreClin: "",
        requireSecondLillyCompound: "",
        requestedAmtOfComp5PreClin: "",
        lillyStudyCompound2: "",
        requireSixthLillyCompound: "None",
        lillyStudyCompound2Other: "",
        compound2AvailableMaterial: "",
        compound2AvailableMaterialOther: "",
        compound2Formulation: "",
        compound2DosingSchedule: "None",
        compound2DosingScheduleOther: "",
        compound2AdministeredDose: "",
        // compound2AmountQuantity: "",
        requestedAmtOfComp2PreClin: "",
        requireThirdLillyCompound: "",
        lillyStudyCompound3: "",
        requireSeventhLillyCompound: "None",
        lillyStudyCompound3Other: "",
        compound3AvailableMaterial: "",
        compound3AvailableMaterialOther: "",
        compound3Formulation: "",
        compound3DosingSchedule: "None",
        compound3DosingScheduleOther: "",
        compound3AdministeredDose: "",
        // compound3AmountQuantity: "",
        requestedAmtOfComp3PreClin: "",
        requireFourthLillyCompound: "None",
        lillyStudyCompound4: "",
        lillyStudyCompound4Other: "",
        compound4AvailableMaterial: "",
        compound4AvailableMaterialOther: "",
        compound4Formulation: "",
        compound4DosingSchedule: "None",
        compound4DosingScheduleOther: "",
        compound4AdministeredDose: "",
        // compound4AmountQuantity: "",
        requestedAmtOfComp4PreClin: "",
        requestingFunding: "None",
        nonLillySupport: "None",
        amountOfFunds: "",
        nameOfCompany: "",
        typeOfSupport: "",
        hcpProtocolTitle: "",
        feedback: "",
        protocolComments: "",
        budgetOfficeContactName: "",
        contractContactName: "",
        budgetOfficeStreetAddress: "",
        contractContactStreetAddress: "",
        budgetOfficeCity: "",
        contractContactCity: "",
        budgetOfficeEmail: "",
        contractContactEmail: "",
        budgetOfficePhoneNumber: "",
        contractContactPhoneNumber: "",
        pharmacyContactName: "",
        pharmacyContactCity: "",
        pharmacyContactEmail: "",
        pharmacyContactPhoneNumber: "",
        pharmacyContactStreetAddress: "",
        enrollmentPeriod: "",
        totalStudyLength: "",
        protocolNotes: "",
        iirUrl: "",
        compoundList: [],
        materialList: [],
        lillyStudyCompound1MaterialList: [],
        lillyStudyCompound2MaterialList: [],
        lillyStudyCompound3MaterialList: [],
        lillyStudyCompound4MaterialList: [],
        successModal: false,
        errorModal: false,
    }
    // get inputFieldsArray() {
    //     return [

    //         /*********************************************/

    //         /*********************************************/

    //         /*********************************************/
    //         {
    //             label: "Information",
    //             type: "alert",
    //             variant: "secondary"
    //         },
    //         {
    //             label: "Protocol Submitted",
    //             type: "checkbox",
    //             name: "protocolSubmitted"
    //         },
    //         {
    //             label: "Submission",
    //             type: "text",
    //             placeholder: "Select Submission",
    //             name: "submissionId",
    //             disabled: true
    //         },
    //         {
    //             label: "Protocol Notes",
    //             type: "text",

    //             name: "protocolNotes"
    //         },
    //         {
    //             label: "Last Updated By",
    //             type: "text",
    //             name: "last_updated_by"
    //         },
    //         /*********************************************/
    //         {
    //             label: "Protocol Information",
    //             type: "alert",
    //             variant: "secondary"
    //         },
    //         {
    //             label: "[EXT] HCP Protocol Title",
    //             type: "textarea",
    //             name: "protocolTitle"
    //         },
    //         {
    //             label: "Protocol Comments",
    //             type: "textarea",
    //             name: "protocolComments",
                
    //         },
    //         {
    //             label: "Non Clinical Research Info",
    //             type: "alert",
    //             variant: "secondary"
    //         },
    //         {
    //             label: "[EXT] IACUC Documentation attached?",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "iACUCDocumentationAttached",
    //             options: [
    //                 "None",
    //                 "Yes",
    //                 "No"
    //             ]
    //         },
    //         {
    //             label: "[EXT] Description of Animal Used",
    //             type: "textarea",
    //             name: "descriptionOfAnimalUsed"
    //         },
    //         {
    //             label: "[EXT] Doses/Concentrations",
    //             type: "textarea",
    //             name: "dosesConcentrations"
    //         },
    //         {
    //             label: "[EXT] AC&U Committee approval?",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "aCAndUCommitteeApproval",
    //             options: [
    //                 "None",
    //                 "Yes",
    //                 "No"
    //             ]
    //         },
    //         {
    //             label: "[EXT] Endpoints",
    //             type: "text",
    //             placeholder: "Enter Endpoints",
    //             name: "endpoints"
    //         },
    //         {
    //             label: "[EXT] Model/Methodology",
    //             type: "textarea",
    //             name: "modelMethodology"
    //         },
    //         {
    //             label: "[EXT] Target Start Date",
    //             type: "date",
    //             name: "targetStartDate"
    //         },
    //         {
    //             label: "[EXT] Statistical Analysis",
    //             type: "textarea",
    //             name: "statisticalAnalysis"
    //         },
    //         {
    //             label: "[EXT] Report Submission Date",
    //             type: "date",
    //             name: "reportSubmissionDate"
    //         },
    //         {
    //             label: "[EXT] Human samples involved",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "humanSamplesInvolved",
    //             options: [
    //                 "None",
    //                 "Yes",
    //                 "No"
    //             ]
    //         },
    //         {
    //             label: "[EXT] Publication Date Anticipated",
    //             type: "date",
    //             name: "publicationDateAnticipated"
    //         },
    //         {
    //             label: "[EXT] Type of Human Samples",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "typeOfHumanSamples",
    //             options: [
    //                 "None",
    //                 "Cells From Biobank",
    //                 "Study Specific"
    //             ]
    //         },
    //         {
    //             label: "[EXT] Projected Milestone Timing",
    //             type: "text",
    //             placeholder: "Enter Projected Milestone Timing",
    //             name: "projectedMilestoneTiming"
    //         },
    //         {
    //             label: "[EXT] Unique, Personal, Clinical samples",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "uniquePersonalClinicalSamples",
    //             options: [
    //                 "None",
    //                 "Yes",
    //                 "No"
    //             ]
    //         },
    //         {
    //             label: "[EXT] Biobank owner",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "biobankOwner",
    //             options: [
    //                 "None",
    //                 "Institution",
    //                 "Third Party"
    //             ]
    //         },
    //         /*********************************************/
    //         {
    //             label: "Clinical Research Info",
    //             type: "alert",
    //             variant: "secondary"
    //         },
    //         {
    //             label: "[EXT] Sample Size",
    //             type: "text",
    //             placeholder: "Enter Sample Size",
    //             name: "sampleSize",
    //             helptext:"Screen failures are not included in enrollment estimations",
    //         },
    //         {
    //             label: "[EXT] Date of First Patient Visit",
    //             type: "date",
    //             name: "dateOfFirstPatientVisit",
    //             helptext:"Anticipated date",
    //         },
    //         {
    //             label: "[EXT] Number of Therapy Groups",
    //             type: "text",
    //             placeholder: "Enter Number of Therapy Groups",
    //             name: "numberOfTherapyGroups"
    //         },
    //         {
    //             label: "[EXT] Date First Pt Enters Treatment",
    //             type: "date",
    //             name: "dateFirstPtEntersTreatment",
    //             helptext:"Anticipated date of the first pateint entering treatment"
    //         },
    //         {
    //             label: "[EXT] Anticipated quarterly enrollment",
    //             type: "text",
    //             placeholder: "Enter Anticipated quarterly enrollment",
    //             name: "anticipatedQuarterlyEnrollment",
    //             helptext:"Screen failures are not included in enrollment estimations "
    //         },
    //         {
    //             label: "[EXT] Date Last Patient Enters Trial",
    //             type: "date",
    //             name: "dateLastPatientEntersTrial",
    //             helptext:"Anticipated date the last patient enters the trial",
    //         },
    //         // ********
    //         {
    //             label: "[EXT] Number of Investigative Sites",
    //             type: "text",
    //             placeholder: "Enter Number of Investigative Sites",
    //             name: "numberOfinvestigativeSite"
    //         },
    //         {
    //             label: "[EXT] Date Last Patient Enters Treatment",
    //             type: "date",
    //             name: "dateLastPatientEntersTreatment",
    //             helptext:"Anticipated date the last patient enters the treatment"
    //         },
    //         {
    //             label: "[EXT] Potential Countries",
    //             type: "text",
    //             name: "potentialCountries"
    //         },
    //         {
    //             label: "[EXT] Date of Last Patient Visit",
    //             type: "date",
    //             name: "dateOfLastPatientVisit",
    //             helptext:"Anticipated date of the last patient visit"
    //         },
    //         // multi
    //         {
    //             label: "Multi-Country Trial",
    //             type: "checkbox",
    //             name: "multiCountryTrial"
    //         },
    //         {
    //             label: "[EXT] Date of Report Submission",
    //             type: "date",
    //             name: "dateOfReportSubmission",
    //             helptext:"Anticipated date the final report will be submitted to Lilly "
    //         },
    //         {
    //             label: "[EXT] Clinical Trial Phase",
    //             type: "multi-select",
    //             placeholder: "Select Clinical Trial Phase",
    //             name: "clinicalTrialPhase",
    //             options: [
    //                 { value: "l", label: "l" },
    //                 { value: "ll", label: "ll" },
    //                 { value: "lll", label: "lll" },
    //                 { value: "lV", label: "lV" },
    //             ],
    //         },
    //         {
    //             label: "[EXT] Date of Publication Submission",
    //             type: "date",
    //             name: "dateOfPublicationSubmission"
    //         },
    //         {
    //             label: "[EXT] Include PreClinical Portion?",
    //             type: "checkbox",
    //             name: "includePreClinicalPortion"
    //         },
    //         {
    //             label: "[EXT] Lead-in Period",
    //             type: "multi-select",
    //             placeholder: "Select Lead-in Period",
    //             name: "leadInPeriod",
    //             options: [
    //                 { value: "None", label: "None" },
    //                 { value: "Placebo", label: "Placebo" },
    //                 { value: "Previous Therapy", label: "Previous Therapy" },
    //                 { value: "Previous Therapy Failure", label: "Previous Therapy Failure" }
    //             ],
    //             helptext:"None,Placebo,Previous Therapy,Previous Therapy Failure"
    //         },
    //         {
    //             label: "Total Study Length (In Months)",
    //             type: "text",
    //             name: "totalStudyLength",
    //             disabled: true
    //         },
    //         {
    //             label: "[EXT] Blinding",
    //             type: "multi-select",
    //             placeholder: "Select Blinding",
    //             name: "blinding",
    //             options: [
    //                 { value: "Open Label", label: "Open Label" },
    //                 { value: "Single-blind", label: "Single-blind" },
    //                 { value: "Double-blind", label: "Double-blind" },
    //             ],
    //             helptext:"Open Label,Single-blind,Double-blind"
    //         },
    //         {
    //             label: "Enrollment Period (In Months)",
    //             type: "text",
    //             name: "enrollmentPeriod",
    //             disabled: true
    //         },
    //         {
    //             label: "[EXT] Blinding Done By",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "blindingDoneBy",
    //             options: [
    //                 "None",
    //                 "Lilly",
    //                 "Site",
    //                 "Not Applicable"
    //             ],
    //             helptext:"Lilly,Site,Not Applicable"
    //         },
    //         {
    //             label: "[EXT] Assignment to Treatment",
    //             type: "multi-select",
    //             placeholder: "Select Assignment to Treatment",
    //             name: "assignmentToTreatment",
    //             options: [
    //                 { value: "Randomized", label: "Randomized" },
    //                 { value: "Non-Randomized", label: "Non-Randomized" },
    //                 { value: "Stratified", label: "Stratified" }
    //             ],
    //             helptext:"Randomized,Non-Randomized,Stratified"
    //         },
           
    //         {
    //             label: "[EXT] Patient Type",
    //             type: "multi-select",
    //             placeholder: "Select Patient Type",
    //             name: "patientType",
    //             options: [
    //                 { value: "Inpatients", label: "Inpatients" },
    //                 { value: "Outpatients", label: "Outpatients" },
    //                 { value: "Normal Volunteer", label: "Normal Volunteer" }
    //             ],
    //             helptext:"Inpatients,Outpatients,Normal Volunteer"
    //         },
    //         ///////////////////
    //         {
    //             label: "[EXT] Design",
    //             type: "multi-select",
    //             placeholder: "Select Design",
    //             name: "design",
    //             options: [
    //                 { value: "Uncontrolled", label: "Uncontrolled" },
    //                 { value: "Parallel", label: "Parallel" },
    //                 { value: "Crossover", label: "Crossover" },
    //                 { value: "Length of Washouts", label: "Length of Washout" }
    //             ],
    //             helptext:"Uncontrolled,Parallel,Crossover,Length of Washouts"
    //         },
          
    //         {
    //             label: "[EXT] Patient gender",
    //             type: "multi-select",
    //             placeholder: "Select Patient gender",
    //             name: "patientGender",
    //             options: [
    //                 { value: "Male", label: "Male" },
    //                 { value: "Female", label: "Female" },
    //                 { value: "Both", label: "Both" }
    //             ],
    //         },
    //         {
    //             label: "[EXT] Controls",
    //             type: "multi-select",
    //             placeholder: "Select Controls",
    //             name: "controls",
    //             options: [
    //                 { value: "Active", label: "Active" },
    //                 { value: "Placebo", label: "Placebo" },
    //                 { value: "Both", label: "Both" },
    //                 { value: "None", label: "None" },
    //             ],
    //             helptext:"Active,Placebo,Both,None"
    //         },
    //         {
    //             label: "[EXT] Patient Treatment Period",
    //             type: "text",
    //             placeholder: "Enter Patient Treatment Period",
    //             name: "patientTreatmentPeriod",
    //             helptext:"Please define the treatment period of the average patient in months, please indicate if it will not be a full month (e.g. 2.5)"
    //         },
    //         {
    //             label: "[EXT] Extension",
    //             type: "multi-select",
    //             placeholder: "Select Extension",
    //             name: "extension",
    //             options: [
    //                 { value: "Open Label", label: "Open Label" },
    //                 { value: "Double Blind", label: "Double Blind" },
    //                 { value: "None", label: "None" },
    //                 { value: "length", label: "length" }
    //             ],
    //         },
          
    //         {
    //             label: "[EXT] Extension Length",
    //             type: "text",
    //             placeholder: "Enter Extension Length",
    //             name: "extensionLength"
    //         },
           
    //         {
    //             label: "[EXT] Age Range Start",
    //             type: "text",
    //             placeholder: "Enter Age Range Start",
    //             name: "ageRangeStart"
    //         },
           
    //         // {
    //         //     label: "[EXT] Study On/Off Label",
    //         //     type: "dropdown",
    //         //     placeholder: "Select any Option",
    //         //     name: "studyOnOffLabel",
    //         //     options: [
    //         //         "None",
    //         //         "Off Label",
    //         //         "On Label"
    //         //     ]
    //         // },
    //         {
    //             label: "[EXT] Age Range End",
    //             type: "text",
    //             placeholder: "Enter Age Range End",
    //             name: "ageRangeEnd"
    //         },
    //         // {
    //         //     label: "[EXT] Study On/Off Label",
    //         //     type: "dropdown",
    //         //     placeholder: "Select any Option",
    //         //     name: "studyOnOffLabel",
    //         //     options: [
    //         //         "None",
    //         //         "Off Label",
    //         //         "On Label"
    //         //     ]
    //         // },
    //         // {
    //         //     label: "[EXT] Line of Therapy",
    //         //     type: "dropdown",
    //         //     placeholder: "Select any Option",
    //         //     name: "LineOfTherapy",
    //         //     options: [
    //         //         "None",
    //         //         "1st Line",
    //         //         "2nd Line",
    //         //         "3rd Line",
    //         //         "Adjuvant",
    //         //         "Mainteinance",
    //         //         "Metastatic",
    //         //         "Neoadjuvant",
    //         //         "Other",
    //         //         "Perioperative"
    //         //     ]
    //         // },
    //         /*************************************************************************/
    //         {
    //             label: "Study Compound Support",
    //             type: "alert",
    //             variant: "secondary"
    //         },
    //         //[EXT] Lilly to Supply Study Compound
    //         {
    //             label: "[EXT] Lilly to Supply Study Compound",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "lillyToSupplyStudyCompound",
    //             options: [
    //                 "None",
    //                 "Yes",
    //                 "No"
    //             ]
    //         },
    //         //[EXT] If No, why?
    //         {
    //             label: "[EXT] If No, why?",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "ifNoWhy",
    //             options: [
    //                 "None",
    //                 "The Study doesn't involve an intervention",
    //                 "Local laws/regulations prohibit it",
    //                 "Third-party support is being provided"
    //             ]
    //         },

    //         //[EXT] Lilly Study Compound 1	
    //         {
    //             label: "[EXT] Lilly Study Compound 1",
    //             type: "searchableDropdown",
    //             placeholder: "Select Lilly Study Compound 1",
    //             name: "lillyStudyCompound1",
    //             options: this.state.compoundList,
    //             onSelect: this.onCompoundSelect
    //         },
    //         //[EXT] require fifth Lilly compound?

    //         //[EXT] Lilly Study Compound 1 Other
    //         {
    //             label: "[EXT] Lilly Study Compound 1 Other",
    //             type: "text",
    //             placeholder: "Enter Lilly Study Compound 1 Other",
    //             name: "lillyStudyCompound1Other"
    //         },
    //         //[EXT] Lilly Study Compound 5

    //         //[EXT] Compound 1 Available Material
    //         {
    //             label: "[EXT] Compound 1 Available Material",
    //             type: "searchableDropdown",
    //             placeholder: "Select Compound 1 Available Material",
    //             name: "compound1AvailableMaterial",
    //             options: this.state.lillyStudyCompound1MaterialList,
    //             onSelect: this.onSearchSelect
    //         },
    //         //[EXT] Lilly Study Compound 5 Other

    //         //[EXT]Compound 1 Available Material Other
    //         {
    //             label: "[EXT]Compound 1 Available Material Other",
    //             type: "text",
    //             placeholder: "Enter Compound 1 Available Material Other",
    //             name: "compound1AvailableMaterialOther"
    //         },
    //         //[EXT] Compound 5 Available Material	

    //         //[EXT] Compound 1 Formulation	
    //         {
    //             label: "[EXT] Compound 1 Formulation",
    //             type: "text",
    //             placeholder: "Enter Compound 1 Formulation",
    //             name: "compound1Formulation"
    //         },
    //         //[EXT]Compound 5 Available Material Other	

    //         //[EXT] Compound 1 dosing Schedule
    //         {
    //             label: "[EXT] Compound 1 dosing Schedule",
    //             type: "dropdown",
    //             placeholder: "Select Compound 1 dosing Schedule",
    //             name: "compound1DosingSchedule",
    //             options: DOSING_DSCHEDULE
    //         },
    //         //[EXT] Compound 5 Formulation	

    //         //[EXT] Compound 1 dosing Schedule Other
    //         {
    //             label: "[EXT] Compound 1 dosing Schedule Other",
    //             type: "text",
    //             placeholder: "Enter Compound 1 dosing Schedule Other",
    //             name: "compound1DosingSchedulOther"
    //         },
    //         //[EXT] Compound 5 dosing Schedule

    //         //[EXT] Compound 1 Administered Dose
    //         {
    //             label: "[EXT] Compound 1 Administered Dose",
    //             type: "text",
    //             placeholder: "Enter Compound 1 Administered Dose",
    //             name: "compound1AdministeredDose"
    //         },
    //         //[EXT] Compound 5 dosing Schedule Other

    //         //[EXT] Compound 1 Amount (Quantity)
    //         // {
    //         //     label: "[EXT] Compound 1 Amount (Quantity)",
    //         //     type: "text",
    //         //     placeholder: "Enter Compound 1 Amount (Quantity)",
    //         //     name: "compound1AmountQuantity"
    //         // },
    //         //[EXT] Compound 5 Administered Dose	

    //         //[EXT] Requested amt of comp 1 (PreClin)	
    //         {
    //             label: "[EXT] Requested amt of comp 1 (PreClin)",
    //             type: "text",
    //             placeholder: "Enter Requested amt of comp 1 (PreClin)",
    //             name: "requestedAmtOfComp1PreClin",
    //             helptext:"Please designate the amount of compound that you need for this study",
    //         },
    //         //[EXT] Compound 5 Amount (Quantity)

    //         //[EXT] require second Lilly compound?	
    //         {
    //             label: "[EXT] require second Lilly compound?",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "requireSecondLillyCompound",
    //             options: [
    //                 "None",
    //                 "Yes",
    //                 "No"
    //             ]
    //         },
    //         // //[EXT] Requested amt of comp 5 (PreClin)
    //         // {
    //         //     label: "[EXT] Requested amt of comp 5 (PreClin)",
    //         //     type: "text",
    //         //     placeholder: "Enter Requested amt of comp 5 (PreClin)",
    //         //     name: "requestedAmtOfComp5PreClin"
    //         // },
    //         //[EXT] Lilly Study Compound 2	
    //         {
    //             label: "[EXT] Lilly Study Compound 2",
    //             type: "searchableDropdown",
    //             placeholder: "Select Lilly Study Compound 2",
    //             name: "lillyStudyCompound2",
    //             options: this.state.compoundList,
    //         onSelect: this.onCompoundSelect
    //         },
    //         //[EXT] require sixth Lilly compound?	

    //         //[EXT] Lilly Study Compound 2 Other
    //         {
    //             label: "[EXT] Lilly Study Compound 2 Other",
    //             type: "text",
    //             placeholder: "Enter Lilly Study Compound 2 Other",
    //             name: "lillyStudyCompound2Other"
    //         },
    //         //[EXT] Lilly Study Compound 6

    //         //[EXT] Compound 2 Available Material	
    //         {
    //             label: "[EXT] Compound 2 Available Material",
    //             type: "searchableDropdown",
    //             placeholder: "Select Compound 2 Available Material",
    //             name: "compound2AvailableMaterial",
    //             options: this.state.lillyStudyCompound2MaterialList,
    //             onSelect: this.onSearchSelect
    //         },
    //         //[EXT] Lilly Study Compound 6 Other

    //         //[EXT]Compound 2 Available Material Other
    //         {
    //             label: "[EXT]Compound 2 Available Material Other",
    //             type: "text",
    //             placeholder: "Enter Compound 2 Available Material Other",
    //             name: "compound2AvailableMaterialOther"
    //         },
    //         //[EXT] Compound 6 Available Material

    //         //[EXT] Compound 2 Formulation	
    //         {
    //             label: "[EXT] Compound 2 Formulation",
    //             type: "text",
    //             placeholder: "Enter Compound 2 Formulation",
    //             name: "compound2Formulation"
    //         },
    //         //[EXT]Compound 6 Available Material Other

    //         //[EXT] Compound 2 dosing Schedule
    //         {
    //             label: "[EXT] Compound 2 dosing Schedule",
    //             type: "dropdown",
    //             placeholder: "Select Compound 2 dosing Schedule",
    //             name: "compound2DosingSchedule",
    //             options: DOSING_DSCHEDULE
    //         },
    //         //[EXT] Compound 6 Formulation

    //         //[EXT] Compound 2 dosing Schedule Other
    //         {
    //             label: "[EXT] Compound 2 dosing Schedule Other",
    //             type: "text",
    //             placeholder: "Enter Compound 2 dosing Schedule Other",
    //             name: "compound2DosingScheduleOther"
    //         },
    //         //[EXT] Compound 6 dosing Schedule

    //         //[EXT] Compound 2 Administered Dose
    //         {
    //             label: "[EXT] Compound 2 Administered Dose",
    //             type: "text",
    //             placeholder: "Enter Compound 2 Administered Dose",
    //             name: "compound2AdministeredDose"
    //         },
    //         //[EXT] Compound 6 dosing Schedule Other

    //         //[EXT] Compound 2 Amount (Quantity)
    //         // {
    //         //     label: "[EXT] Compound 2 Amount (Quantity)",
    //         //     type: "text",
    //         //     placeholder: "Enter Compound 2 Amount (Quantity)",
    //         //     name: "compound2AmountQuantity"
    //         // },
    //         //[EXT] Compound 6 Administered Dose

    //         //[EXT] Requested amt of comp 2 (PreClin)
    //         {
    //             label: "[EXT] Requested amt of comp 2 (PreClin)",
    //             type: "text",
    //             placeholder: "Enter Requested amt of comp 2 (PreClin)",
    //             name: "requestedAmtOfComp2PreClin",
    //             helptext:"Please designate the amount of compound that you need for this study",
    //         },
    //         //[EXT] Compound 6 Amount (Quantity)

    //         //[EXT] require third Lilly compound?
    //         {
    //             label: "[EXT] require third Lilly compound?",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "requireThirdLillyCompound",
    //             options: [
    //                 "None",
    //                 "Yes",
    //                 "No"
    //             ]
    //         },
    //         //[EXT] Requested amt of comp 6 (PreClin)	

    //         //[EXT] Lilly Study Compound 3	
    //         {
    //             label: "[EXT] Lilly Study Compound 3",
    //             type: "searchableDropdown",
    //             placeholder: "Select Lilly Study Compound 3",
    //             name: "lillyStudyCompound3",
    //             options: this.state.compoundList,
    //         onSelect: this.onCompoundSelect
    //         },
    //         //[EXT] require seventh Lilly compound?	

    //         //[EXT] Lilly Study Compound 3 Other
    //         {
    //             label: "[EXT] Lilly Study Compound 3 Other",
    //             type: "text",
    //             placeholder: "Enter Lilly Study Compound 3 Other",
    //             name: "lillyStudyCompound3Other"
    //         },
    //         //[EXT] Lilly Study Compound 7	

    //         //[EXT] Compound 3 Available Material
    //         {
    //             label: "[EXT] Compound 3 Available Material",
    //             type: "searchableDropdown",
    //             placeholder: "Select Compound 3 Available Material",
    //             name: "compound3AvailableMaterial",
    //             options: this.state.lillyStudyCompound3MaterialList,
    //             onSelect: this.onSearchSelect
                
    //         },
    //         //[EXT] Lilly Study Compound 7 Other

    //         //[EXT]Compound 3 Available Material Other	
    //         {
    //             label: "[EXT]Compound 3 Available Material Other",
    //             type: "text",
    //             placeholder: "Enter Compound 3 Available Material Other",
    //             name: "compound3AvailableMaterialOther"
    //         },
    //         //[EXT] Compound 7 Available Material

    //         //[EXT] Compound 3 Formulation
    //         {
    //             label: "[EXT] Compound 3 Formulation",
    //             type: "text",
    //             placeholder: "Enter Compound 3 Formulation",
    //             name: "compound3Formulation"
    //         },
    //         //[EXT] Compound 7 Formulation	

    //         //[EXT] Compound 3 dosing Schedule
    //         {
    //             label: "[EXT] Compound 3 dosing Schedule",
    //             type: "dropdown",
    //             placeholder: "Select Compound 3 dosing Schedule",
    //             name: "compound3DosingSchedule",
    //             options: DOSING_DSCHEDULE
    //         },
    //         //[EXT]Compound 7 Available Material Other

    //         //[EXT] Compound 3 dosing Schedule Other
    //         {
    //             label: "[EXT] Compound 3 dosing Schedule Other",
    //             type: "text",
    //             placeholder: "Enter Compound 3 dosing Schedule Other",
    //             name: "compound3DosingScheduleOther"
    //         },
    //         //[EXT] Compound 7 dosing Schedule

    //         //[EXT] Compound 3 Administered Dose
    //         {
    //             label: "[EXT] Compound 3 Administered Dose",
    //             type: "text",
    //             placeholder: "Enter Compound 3 Administered Dose",
    //             name: "compound3AdministeredDose"
    //         },
    //         //[EXT] Compound 7 dosing Schedule Other

    //         //[EXT] Requested amt of comp 3 (PreClin)
           
    //         {
    //             label: "[EXT] Requested amt of comp 3 (PreClin)",
    //             type: "text",
    //             placeholder: "Enter Requested amt of comp 3 (PreClin)",
    //             name: "requestedAmtOfComp3PreClin",
    //             helptext:"Please designate the amount of compound that you need for this study",
    //         },
    //         //[EXT] Compound 7 Amount (Quantity)

    //         //[EXT] require fourth Lilly compound?
    //         {
    //             label: "[EXT] require fourth Lilly compound?",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "requireFourthLillyCompound",
    //             options: [
    //                 "None",
    //                 "Yes",
    //                 "No"
    //             ]
    //         },
    //         //[EXT] Requested amt of comp 7 (PreClin)

    //         //[EXT] Lilly Study Compound 4
    //         {
    //             label: "[EXT] Lilly Study Compound 4",
    //             type: "searchableDropdown",
    //             placeholder: "Select Lilly Study Compound 4",
    //             name: "lillyStudyCompound4",
    //             options: this.state.compoundList,
    //         onSelect: this.onCompoundSelect
    //         },
    //         //[EXT] Lilly Study Compound 4 Other
    //         {
    //             label: "[EXT] Lilly Study Compound 4 Other",
    //             type: "text",
    //             placeholder: "Enter Lilly Study Compound 4 Other",
    //             name: "lillyStudyCompound4Other"
    //         },
    //         //[EXT] Compound 4 Available Material
    //         {
    //             label: "[EXT] Compound 4 Available Material",
    //             type: "searchableDropdown",
    //             placeholder: "Select Compound 4 Available Material",
    //             name: "compound4AvailableMaterial",
    //             options: this.state.lillyStudyCompound4MaterialList,
    //             onSelect: this.onSearchSelect
               
    //         },
    //         //[EXT]Compound 4 Available Material Other
    //         {
    //             label: "[EXT]Compound 4 Available Material Other",
    //             type: "text",
    //             placeholder: "Enter Compound 4 Available Material Other",
    //             name: "compound4AvailableMaterialOther"
    //         },
    //         //[EXT] Compound 4 Formulation	
    //         {
    //             label: "[EXT] Compound 4 Formulation",
    //             type: "text",
    //             placeholder: "Enter Compound 4 Formulation",
    //             name: "compound4Formulation"
    //         },
    //         //[EXT] Compound 4 dosing Schedule
    //         {
    //             label: "[EXT] Compound 4 dosing Schedule",
    //             type: "dropdown",
    //             placeholder: "Select Compound 4 dosing Schedule",
    //             name: "compound4DosingSchedule",
    //             options: DOSING_DSCHEDULE
    //         },
    //         //[EXT] Compound 4 dosing Schedule Other
    //         {
    //             label: "[EXT] Compound 4 dosing Schedule Other",
    //             type: "text",
    //             placeholder: "Enter Compound 4 dosing Schedule Other",
    //             name: "compound4DosingScheduleOther"
    //         },
    //         //[EXT] Compound 4 Administered Dose
    //         {
    //             label: "[EXT] Compound 4 Administered Dose",
    //             type: "text",
    //             placeholder: "Enter Compound 4 Administered Dose",
    //             name: "compound4AdministeredDose"
    //         },
    //         //[EXT] Compound 4 Amount (Quantity)

    //         //[EXT] Requested amt of comp 4 (PreClin)
           
    //         {
    //             label: "[EXT] Requested amt of comp 4 (PreClin)",
    //             type: "text",
    //             placeholder: "Enter Requested amt of comp 4 (PreClin)",
    //             name: "requestedAmtOfComp4PreClin",
    //             helptext:"Please designate the amount of compound that you need for this study",
    //         },
    //         /*****************************************************************************/
    //         {
    //             label: "Financial Support",
    //             type: "alert",
    //             variant: "secondary"
    //         },

    //         {
    //             label: "[EXT] Non-Lilly Support?",
    //             type: "dropdown",
    //             placeholder: "Select any Option",
    //             name: "nonLillySupport",
    //             options: [
    //                 "None",
    //                 "Yes",
    //                 "No"
    //             ],
    //             helptext:"Is support being provided by another company?"
    //         },

    //         {
    //             label: "[EXT] Name of Company",
    //             type: "text",
    //             placeholder: "Enter Name of Company",
    //             name: "nameOfCompany"
    //         },

    //         {
    //             label: "[EXT] Type of Support",
    //             type: "text",
    //             placeholder: "Enter Type of Support",
    //             name: "typeOfSupport",
    //             helptext:"Grant,Drug,Both"
    //         },
    //         // ************************* 
    //         // {
    //         //     label: "Protocol Information",
    //         //     type: "alert",
    //         //     variant: "secondary"
    //         // },
    //         // {
    //         //     label: "[EXT] HCP Protocol Title",
    //         //     type: "textarea",
    //         //     name: "hcpProtocolTitle"
    //         // },
    //         // {
    //         //     label: "[EXT] Protocol Comments",
    //         //     type: "text-editor",
    //         //     name: "protocolComments",
    //         //     onChange: this.onEditorChange
    //         // },
    //         // ****************
    //         {
    //             label: "Feedback",
    //             type: "alert",
    //             variant: "secondary"
    //         },
    //         {
    //             label: "Lilly Feedback (Investigator Visible)",
    //             type: "textarea",
    //             name: "feedback",
                
    //         },

    //         // ****************************14
    //         {
    //             label: "Contact Information",
    //             type: "alert",
    //             variant: "secondary"
    //         },
    //         {
    //             label: "Budget Office Contact Name",
    //             type: "text",
    //             placeholder: "Enter Budget Office Contact Name",
    //             name: "budgetOfficeContactName"
    //         },
    //         {
    //             label: "Contract Contact Name",
    //             type: "text",
    //             placeholder: "Enter Contract Contact Name",
    //             name: "contractContactName"
    //         },

    //         {
    //             label: "Budget Office Street Address",
    //             type: "text",
    //             placeholder: "Enter Budget Office Street Address",
    //             name: "budgetOfficeStreetAddress"
    //         },
    //         {
    //             label: "Contract Contact Street Address",
    //             type: "text",
    //             placeholder: "Enter Contract Contact Street Address",
    //             name: "contractContactStreetAddress"
    //         },

    //         {
    //             label: "Budget Office City",
    //             type: "text",
    //             placeholder: "Enter Budget Office City",
    //             name: "budgetOfficeCity"
    //         },
    //         {
    //             label: "Contract Contact City",
    //             type: "text",
    //             placeholder: "Enter Contract Contact City",
    //             name: "contractContactCity"
    //         },
    //         {
    //             label: "Budget Office Email",
    //             type: "text",
    //             placeholder: "Enter Budget Office Email",
    //             name: "budgetOfficeEmail"
    //         },
    //         {
    //             label: "Contract Contact Email",
    //             type: "text",
    //             placeholder: "Enter Contract Contact Email",
    //             name: "contractContactEmail"
    //         },
    //         {
    //             label: "Budget Office Phone Number",
    //             type: "text",
    //             placeholder: "Enter Budget Office Phone Number",
    //             name: "budgetOfficePhoneNumber"
    //         },
    //         {
    //             label: "Contract Contact Phone Number",
    //             type: "text",
    //             placeholder: "Enter Contract Contact Phone Number",
    //             name: "contractContactPhoneNumber"
    //         },
    //         {
    //             label: "Pharmacy Contact Name",
    //             type: "text",
    //             placeholder: "Enter Pharmacy Contact Name",
    //             name: "pharmacyContactName"
    //         },
    //         {
    //             label: "Pharmacy Contact City",
    //             type: "text",
    //             placeholder: "Enter Pharmacy Contact City",
    //             name: "pharmacyContactCity"
    //         },
    //         {
    //             label: "Pharmacy Contact Email",
    //             type: "text",
    //             placeholder: "Enter Pharmacy Contact Email",
    //             name: "pharmacyContactEmail"
    //         },
    //         {
    //             label: "Pharmacy Contact Phone Number",
    //             type: "text",
    //             placeholder: "Enter Pharmacy Contact Phone Number",
    //             name: "pharmacyContactPhoneNumber"
    //         },
    //         {
    //             label: "Pharmacy Contact Street Address",
    //             type: "text",
    //             placeholder: "Enter Pharmacy Contact Street Address",
    //             name: "pharmacyContactStreetAddress"
    //         },

    //     ]
    // }
    get inputFieldsArrayInformation() {
        return[
            {
                label: "Protocol Submitted",
                type: "checkbox",
                name: "protocolSubmitted"
            },
            {
                label: "Submission",
                type: "text",
                placeholder: "Select Submission",
                name: "submissionId",
                disabled: true
            },
            {
                label: "Protocol Notes",
                type: "text",
               
                name: "protocolNotes"
            },
           
            {
                label: "Last Updated By",
                type: "text",
                name: "last_updated_by",
            },]
    }
    get inputFieldsArrayProposalIIR() {
        return [
           
            {
                label: "[EXT] HCP Protocol Title",
                type: "textarea",
                name: "protocolTitle"
            },
            {
                label: "Protocol Comments",
                type: "textarea",
                name: "protocolComments",
                
            },
        ]}
    
    get inputFieldsArrayNCRI() { 
        return[
            {
                label: "[EXT] IACUC Documentation attached?",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "iACUCDocumentationAttached",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
            },
            {
                label: "[EXT] Description of Animal Used",
                type: "textarea",
                name: "descriptionOfAnimalUsed"
            },
            {
                label: "[EXT] Doses/Concentrations",
                type: "textarea",
                name: "dosesConcentrations"
            },
            {
                label: "[EXT] AC&U Committee approval?",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "aCAndUCommitteeApproval",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
            },
            {
                label: "[EXT] Endpoints",
                type: "text",
                placeholder: "Enter Endpoints",
                name: "endpoints"
            },
            {
                label: "[EXT] Model/Methodology",
                type: "textarea",
                name: "modelMethodology"
            },
            {
                label: "[EXT] Target Start Date",
                type: "date",
                name: "targetStartDate"
            },
            {
                label: "[EXT] Statistical Analysis",
                type: "textarea",
                name: "statisticalAnalysis"
            },
            {
                label: "[EXT] Report Submission Date",
                type: "date",
                name: "reportSubmissionDate"
            },
            {
                label: "[EXT] Human samples involved",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "humanSamplesInvolved",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
            },
            {
                label: "[EXT] Publication Date Anticipated",
                type: "date",
                name: "publicationDateAnticipated"
            },
            {
                label: "[EXT] Type of Human Samples",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "typeOfHumanSamples",
                options: [
                    "None",
                    "Cells From Biobank",
                    "Study Specific"
                ]
            },
            {
                label: "[EXT] Projected Milestone Timing",
                type: "text",
                placeholder: "Enter Projected Milestone Timing",
                name: "projectedMilestoneTiming"
            },
            {
                label: "[EXT] Unique, Personal, Clinical samples",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "uniquePersonalClinicalSamples",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
            },
            {
                label: "[EXT] Biobank owner",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "biobankOwner",
                options: [
                    "None",
                    "Institution",
                    "Third Party"
                ]
            },]}
    get inputFieldsArrayClinical() {
        return [ 
            {
                label: "[EXT] Sample Size",
                type: "text",
                placeholder: "Enter Sample Size",
                name: "sampleSize",
                helptext:"Screen failures are not included in enrollment estimations",
            },
            {
                label: "[EXT] Date of First Patient Visit",
                type: "date",
                name: "dateOfFirstPatientVisit",
                helptext:"Anticipated date",
            },
            {
                label: "[EXT] Number of Therapy Groups",
                type: "text",
                placeholder: "Enter Number of Therapy Groups",
                name: "numberOfTherapyGroups"
            },
            {
                label: "[EXT] Date First Pt Enters Treatment",
                type: "date",
                name: "dateFirstPtEntersTreatment",
                helptext:"Anticipated date of the first pateint entering treatment"
            },
            {
                label: "[EXT] Anticipated quarterly enrollment",
                type: "text",
                placeholder: "Enter Anticipated quarterly enrollment",
                name: "anticipatedQuarterlyEnrollment",
                helptext:"Screen failures are not included in enrollment estimations "
            },
            {
                label: "[EXT] Date Last Patient Enters Trial",
                type: "date",
                name: "dateLastPatientEntersTrial",
                helptext:"Anticipated date the last patient enters the trial",
            },
            // ********
            {
                label: "[EXT] Number of Investigative Sites",
                type: "text",
                placeholder: "Enter Number of Investigative Sites",
                name: "numberOfinvestigativeSite"
            },
            {
                label: "[EXT] Date Last Patient Enters Treatment",
                type: "date",
                name: "dateLastPatientEntersTreatment",
                helptext:"Anticipated date the last patient enters the treatment"
            },
            {
                label: "[EXT] Potential Countries",
                type: "text",
                name: "potentialCountries"
            },
            {
                label: "[EXT] Date of Last Patient Visit",
                type: "date",
                name: "dateOfLastPatientVisit",
                helptext:"Anticipated date of the last patient visit"
            },
            // multi
            {
                label: "Multi-Country Trial",
                type: "checkbox",
                name: "multiCountryTrial"
            },
            {
                label: "[EXT] Date of Report Submission",
                type: "date",
                name: "dateOfReportSubmission",
                helptext:"Anticipated date the final report will be submitted to Lilly "
            },
            {
                label: "[EXT] Clinical Trial Phase",
                type: "multi-select",
                placeholder: "Select Clinical Trial Phase",
                name: "clinicalTrialPhase",
                options: [
                    { value: "l", label: "l" },
                    { value: "ll", label: "ll" },
                    { value: "lll", label: "lll" },
                    { value: "lV", label: "lV" },
                ],
            },
            {
                label: "[EXT] Date of Publication Submission",
                type: "date",
                name: "dateOfPublicationSubmission"
            },
            {
                label: "[EXT] Include PreClinical Portion?",
                type: "checkbox",
                name: "includePreClinicalPortion"
            },
            {
                label: "[EXT] Lead-in Period",
                type: "multi-select",
                placeholder: "Select Lead-in Period",
                name: "leadInPeriod",
                options: [
                    { value: "None", label: "None" },
                    { value: "Placebo", label: "Placebo" },
                    { value: "Previous Therapy", label: "Previous Therapy" },
                    { value: "Previous Therapy Failure", label: "Previous Therapy Failure" }
                ],
                helptext:"None,Placebo,Previous Therapy,Previous Therapy Failure"
            },
            {
                label: "Total Study Length (In Months)",
                type: "text",
                name: "totalStudyLength",
                disabled: true
            },
            {
                label: "[EXT] Blinding",
                type: "multi-select",
                placeholder: "Select Blinding",
                name: "blinding",
                options: [
                    { value: "Open Label", label: "Open Label" },
                    { value: "Single-blind", label: "Single-blind" },
                    { value: "Double-blind", label: "Double-blind" },
                ],
                helptext:"Open Label,Single-blind,Double-blind"
            },
            {
                label: "Enrollment Period (In Months)",
                type: "text",
                name: "enrollmentPeriod",
                disabled: true
            },
            {
                label: "[EXT] Blinding Done By",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "blindingDoneBy",
                options: [
                    "None",
                    "Lilly",
                    "Site",
                    "Not Applicable"
                ],
                helptext:"Lilly,Site,Not Applicable"
            },
            {
                label: "[EXT] Assignment to Treatment",
                type: "multi-select",
                placeholder: "Select Assignment to Treatment",
                name: "assignmentToTreatment",
                options: [
                    { value: "Randomized", label: "Randomized" },
                    { value: "Non-Randomized", label: "Non-Randomized" },
                    { value: "Stratified", label: "Stratified" }
                ],
                helptext:"Randomized,Non-Randomized,Stratified"
            },
           
            {
                label: "[EXT] Patient Type",
                type: "multi-select",
                placeholder: "Select Patient Type",
                name: "patientType",
                options: [
                    { value: "Inpatients", label: "Inpatients" },
                    { value: "Outpatients", label: "Outpatients" },
                    { value: "Normal Volunteer", label: "Normal Volunteer" }
                ],
                helptext:"Inpatients,Outpatients,Normal Volunteer"
            },
            ///////////////////
            {
                label: "[EXT] Design",
                type: "multi-select",
                placeholder: "Select Design",
                name: "design",
                options: [
                    { value: "Uncontrolled", label: "Uncontrolled" },
                    { value: "Parallel", label: "Parallel" },
                    { value: "Crossover", label: "Crossover" },
                    { value: "Length of Washouts", label: "Length of Washout" }
                ],
                helptext:"Uncontrolled,Parallel,Crossover,Length of Washouts"
            },
          
            {
                label: "[EXT] Patient gender",
                type: "multi-select",
                placeholder: "Select Patient gender",
                name: "patientGender",
                options: [
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                    { value: "Both", label: "Both" }
                ],
            },
            {
                label: "[EXT] Controls",
                type: "multi-select",
                placeholder: "Select Controls",
                name: "controls",
                options: [
                    { value: "Active", label: "Active" },
                    { value: "Placebo", label: "Placebo" },
                    { value: "Both", label: "Both" },
                    { value: "None", label: "None" },
                ],
                helptext:"Active,Placebo,Both,None"
            },
            {
                label: "[EXT] Patient Treatment Period",
                type: "text",
                placeholder: "Enter Patient Treatment Period",
                name: "patientTreatmentPeriod",
                helptext:"Please define the treatment period of the average patient in months, please indicate if it will not be a full month (e.g. 2.5)"
            },
            {
                label: "[EXT] Extension",
                type: "multi-select",
                placeholder: "Select Extension",
                name: "extension",
                options: [
                    { value: "Open Label", label: "Open Label" },
                    { value: "Double Blind", label: "Double Blind" },
                    { value: "None", label: "None" },
                    { value: "length", label: "length" }
                ],
            },
          
            {
                label: "[EXT] Extension Length",
                type: "text",
                placeholder: "Enter Extension Length",
                name: "extensionLength"
            },
           
            {
                label: "[EXT] Age Range Start",
                type: "text",
                placeholder: "Enter Age Range Start",
                name: "ageRangeStart"
            },
           
            // {
            //     label: "[EXT] Study On/Off Label",
            //     type: "dropdown",
            //     placeholder: "Select any Option",
            //     name: "studyOnOffLabel",
            //     options: [
            //         "None",
            //         "Off Label",
            //         "On Label"
            //     ]
            // },
            {
                label: "[EXT] Age Range End",
                type: "text",
                placeholder: "Enter Age Range End",
                name: "ageRangeEnd"
            },]
         // {
            //     label: "[EXT] Line of Therapy",
            //     type: "dropdown",
            //     placeholder: "Select any Option",
            //     name: "LineOfTherapy",
            //     options: [
            //         "None",
            //         "1st Line",
            //         "2nd Line",
            //         "3rd Line",
            //         "Adjuvant",
            //         "Mainteinance",
            //         "Metastatic",
            //         "Neoadjuvant",
            //         "Other",
            //         "Perioperative"
            //     ]
            // },]
    }
    
    get inputFieldsArrayStudyCompound() {
        return [
            //[EXT] Lilly to Supply Study Compound
              {
            label: "[EXT] Lilly to Supply Study Compound",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "lillyToSupplyStudyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] If No, why?
        {
            label: "[EXT] If No, why?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "ifNoWhy",
            options: [
                "None",
                "The Study doesn't involve an intervention",
                "Local laws/regulations prohibit it",
                "Third-party support is being provided"
            ]
        },

        //[EXT] Lilly Study Compound 1	
        {
            label: "[EXT] Lilly Study Compound 1",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 1",
            name: "lillyStudyCompound1",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] require fifth Lilly compound?

        //[EXT] Lilly Study Compound 1 Other
        {
            label: "[EXT] Lilly Study Compound 1 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 1 Other",
            name: "lillyStudyCompound1Other"
        },
        //[EXT] Lilly Study Compound 5

        //[EXT] Compound 1 Available Material
        {
            label: "[EXT] Compound 1 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 1 Available Material",
            name: "compound1AvailableMaterial",
            options: this.state.lillyStudyCompound1MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Lilly Study Compound 5 Other

        //[EXT]Compound 1 Available Material Other
        {
            label: "[EXT]Compound 1 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 1 Available Material Other",
            name: "compound1AvailableMaterialOther"
        },
        //[EXT] Compound 5 Available Material	

        //[EXT] Compound 1 Formulation	
        {
            label: "[EXT] Compound 1 Formulation",
            type: "text",
            placeholder: "Enter Compound 1 Formulation",
            name: "compound1Formulation"
        },
        //[EXT]Compound 5 Available Material Other	

        //[EXT] Compound 1 dosing Schedule
        {
            label: "[EXT] Compound 1 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 1 dosing Schedule",
            name: "compound1DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 5 Formulation	

        //[EXT] Compound 1 dosing Schedule Other
        {
            label: "[EXT] Compound 1 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 1 dosing Schedule Other",
            name: "compound1DosingSchedulOther"
        },
        //[EXT] Compound 5 dosing Schedule

        //[EXT] Compound 1 Administered Dose
        {
            label: "[EXT] Compound 1 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 1 Administered Dose",
            name: "compound1AdministeredDose"
        },
        //[EXT] Compound 5 dosing Schedule Other

        //[EXT] Compound 1 Amount (Quantity)
        // {
        //     label: "[EXT] Compound 1 Amount (Quantity)",
        //     type: "text",
        //     placeholder: "Enter Compound 1 Amount (Quantity)",
        //     name: "compound1AmountQuantity"
        // },
        //[EXT] Compound 5 Administered Dose	

        //[EXT] Requested amt of comp 1 (PreClin)	
        {
            label: "[EXT] Requested amt of comp 1 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 1 (PreClin)",
            name: "requestedAmtOfComp1PreClin",
            helptext:"Please designate the amount of compound that you need for this study",
        },
        //[EXT] Compound 5 Amount (Quantity)

        //[EXT] require second Lilly compound?	
        {
            label: "[EXT] require second Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireSecondLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Requested amt of comp 5 (PreClin)
        // {
        //     label: "[EXT] Requested amt of comp 5 (PreClin)",
        //     type: "text",
        //     placeholder: "Enter Requested amt of comp 5 (PreClin)",
        //     name: "requestedAmtOfComp5PreClin"
        // },
        //[EXT] Lilly Study Compound 2	
        {
            label: "[EXT] Lilly Study Compound 2",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 2",
            name: "lillyStudyCompound2",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] require sixth Lilly compound?	

        //[EXT] Lilly Study Compound 2 Other
        {
            label: "[EXT] Lilly Study Compound 2 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 2 Other",
            name: "lillyStudyCompound2Other"
        },
        //[EXT] Lilly Study Compound 6

        //[EXT] Compound 2 Available Material	
        {
            label: "[EXT] Compound 2 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 2 Available Material",
            name: "compound2AvailableMaterial",
            options: this.state.lillyStudyCompound2MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Lilly Study Compound 6 Other

        //[EXT]Compound 2 Available Material Other
        {
            label: "[EXT]Compound 2 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 2 Available Material Other",
            name: "compound2AvailableMaterialOther"
        },
        //[EXT] Compound 6 Available Material

        //[EXT] Compound 2 Formulation	
        {
            label: "[EXT] Compound 2 Formulation",
            type: "text",
            placeholder: "Enter Compound 2 Formulation",
            name: "compound2Formulation"
        },
        //[EXT]Compound 6 Available Material Other

        //[EXT] Compound 2 dosing Schedule
        {
            label: "[EXT] Compound 2 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 2 dosing Schedule",
            name: "compound2DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 6 Formulation

        //[EXT] Compound 2 dosing Schedule Other
        {
            label: "[EXT] Compound 2 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 2 dosing Schedule Other",
            name: "compound2DosingScheduleOther"
        },
        //[EXT] Compound 6 dosing Schedule

        //[EXT] Compound 2 Administered Dose
        {
            label: "[EXT] Compound 2 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 2 Administered Dose",
            name: "compound2AdministeredDose"
        },
        //[EXT] Compound 6 dosing Schedule Other

        //[EXT] Compound 2 Amount (Quantity)
        // {
        //     label: "[EXT] Compound 2 Amount (Quantity)",
        //     type: "text",
        //     placeholder: "Enter Compound 2 Amount (Quantity)",
        //     name: "compound2AmountQuantity"
        // },
        //[EXT] Compound 6 Administered Dose

        //[EXT] Requested amt of comp 2 (PreClin)
        {
            label: "[EXT] Requested amt of comp 2 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 2 (PreClin)",
            name: "requestedAmtOfComp2PreClin",
            helptext:"Please designate the amount of compound that you need for this study",
        },
        //[EXT] Compound 6 Amount (Quantity)

        //[EXT] require third Lilly compound?
        {
            label: "[EXT] require third Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireThirdLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Requested amt of comp 6 (PreClin)	

        //[EXT] Lilly Study Compound 3	
        {
            label: "[EXT] Lilly Study Compound 3",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 3",
            name: "lillyStudyCompound3",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] require seventh Lilly compound?	

        //[EXT] Lilly Study Compound 3 Other
        {
            label: "[EXT] Lilly Study Compound 3 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 3 Other",
            name: "lillyStudyCompound3Other"
        },
        //[EXT] Lilly Study Compound 7	

        //[EXT] Compound 3 Available Material
        {
            label: "[EXT] Compound 3 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 3 Available Material",
            name: "compound3AvailableMaterial",
            options: this.state.lillyStudyCompound3MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT] Lilly Study Compound 7 Other

        //[EXT]Compound 3 Available Material Other	
        {
            label: "[EXT]Compound 3 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 3 Available Material Other",
            name: "compound3AvailableMaterialOther"
        },
        //[EXT] Compound 7 Available Material

        //[EXT] Compound 3 Formulation
        {
            label: "[EXT] Compound 3 Formulation",
            type: "text",
            placeholder: "Enter Compound 3 Formulation",
            name: "compound3Formulation"
        },
        //[EXT] Compound 7 Formulation	

        //[EXT] Compound 3 dosing Schedule
        {
            label: "[EXT] Compound 3 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 3 dosing Schedule",
            name: "compound3DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT]Compound 7 Available Material Other

        //[EXT] Compound 3 dosing Schedule Other
        {
            label: "[EXT] Compound 3 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 3 dosing Schedule Other",
            name: "compound3DosingScheduleOther"
        },
        //[EXT] Compound 7 dosing Schedule

        //[EXT] Compound 3 Administered Dose
        {
            label: "[EXT] Compound 3 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 3 Administered Dose",
            name: "compound3AdministeredDose"
        },
        //[EXT] Compound 7 dosing Schedule Other

        //[EXT] Requested amt of comp 3 (PreClin)
        {
            label: "[EXT] Requested amt of comp 3 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 3 (PreClin)",
            name: "requestedAmtOfComp3PreClin",
            helptext:"Please designate the amount of compound that you need for this study",
        },
        //[EXT] Compound 7 Amount (Quantity)

        //[EXT] require fourth Lilly compound?
        {
            label: "[EXT] require fourth Lilly compound?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requireFourthLillyCompound",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        //[EXT] Requested amt of comp 7 (PreClin)

        //[EXT] Lilly Study Compound 4
        {
            label: "[EXT] Lilly Study Compound 4",
            type: "searchableDropdown",
            placeholder: "Select Lilly Study Compound 4",
            name: "lillyStudyCompound4",
            options: this.state.compoundList,
            onSelect: this.onCompoundSelect
        },
        //[EXT] Lilly Study Compound 4 Other
        {
            label: "[EXT] Lilly Study Compound 4 Other",
            type: "text",
            placeholder: "Enter Lilly Study Compound 4 Other",
            name: "lillyStudyCompound4Other"
        },
        //[EXT] Compound 4 Available Material
        {
            label: "[EXT] Compound 4 Available Material",
            type: "searchableDropdown",
            placeholder: "Select Compound 4 Available Material",
            name: "compound4AvailableMaterial",
            options: this.state.lillyStudyCompound4MaterialList,
            onSelect: this.onSearchSelect
        },
        //[EXT]Compound 4 Available Material Other
        {
            label: "[EXT]Compound 4 Available Material Other",
            type: "text",
            placeholder: "Enter Compound 4 Available Material Other",
            name: "compound4AvailableMaterialOther"
        },
        //[EXT] Compound 4 Formulation	
        {
            label: "[EXT] Compound 4 Formulation",
            type: "text",
            placeholder: "Enter Compound 4 Formulation",
            name: "compound4Formulation"
        },
        //[EXT] Compound 4 dosing Schedule
        {
            label: "[EXT] Compound 4 dosing Schedule",
            type: "dropdown",
            placeholder: "Select Compound 4 dosing Schedule",
            name: "compound4DosingSchedule",
            options: DOSING_DSCHEDULE
        },
        //[EXT] Compound 4 dosing Schedule Other
        {
            label: "[EXT] Compound 4 dosing Schedule Other",
            type: "text",
            placeholder: "Enter Compound 4 dosing Schedule Other",
            name: "compound4DosingScheduleOther"
        },
        //[EXT] Compound 4 Administered Dose
        {
            label: "[EXT] Compound 4 Administered Dose",
            type: "text",
            placeholder: "Enter Compound 4 Administered Dose",
            name: "compound4AdministeredDose"
        },
        //[EXT] Compound 4 Amount (Quantity)

        //[EXT] Requested amt of comp 4 (PreClin)
        {
            label: "[EXT] Requested amt of comp 4 (PreClin)",
            type: "text",
            placeholder: "Enter Requested amt of comp 4 (PreClin)",
            name: "requestedAmtOfComp4PreClin",
            helptext:"Please designate the amount of compound that you need for this study",
        },
        /*****************************************************************************/
      ]
    }
    get inputFieldsArrayFinancial() {
        return [ 
            {
                label: "[EXT] Non-Lilly Support?",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "nonLillySupport",
                options: [
                    "None",
                    "Yes",
                    "No"
                ],
                helptext:"Is support being provided by another company?"
            },
    
            {
                label: "[EXT] Name of Company",
                type: "text",
                placeholder: "Enter Name of Company",
                name: "nameOfCompany"
            },
    
            {
                label: "[EXT] Type of Support",
                type: "text",
                placeholder: "Enter Type of Support",
                name: "typeOfSupport",
                helptext:"Grant,Drug,Both"
            },]
    }
    get inputFieldsArrayContactInfo() {
        return [
            {
                label: "Budget Office Contact Name",
                type: "text",
                placeholder: "Enter Budget Office Contact Name",
                name: "budgetOfficeContactName"
            },
            {
                label: "Contract Contact Name",
                type: "text",
                placeholder: "Enter Contract Contact Name",
                name: "contractContactName"
            },

            {
                label: "Budget Office Street Address",
                type: "text",
                placeholder: "Enter Budget Office Street Address",
                name: "budgetOfficeStreetAddress"
            },
            {
                label: "Contract Contact Street Address",
                type: "text",
                placeholder: "Enter Contract Contact Street Address",
                name: "contractContactStreetAddress"
            },

            {
                label: "Budget Office City",
                type: "text",
                placeholder: "Enter Budget Office City",
                name: "budgetOfficeCity"
            },
            {
                label: "Contract Contact City",
                type: "text",
                placeholder: "Enter Contract Contact City",
                name: "contractContactCity"
            },
            {
                label: "Budget Office Email",
                type: "text",
                placeholder: "Enter Budget Office Email",
                name: "budgetOfficeEmail"
            },
            {
                label: "Contract Contact Email",
                type: "text",
                placeholder: "Enter Contract Contact Email",
                name: "contractContactEmail"
            },
            {
                label: "Budget Office Phone Number",
                type: "text",
                placeholder: "Enter Budget Office Phone Number",
                name: "budgetOfficePhoneNumber"
            },
            {
                label: "Contract Contact Phone Number",
                type: "text",
                placeholder: "Enter Contract Contact Phone Number",
                name: "contractContactPhoneNumber"
            },
            {
                label: "Pharmacy Contact Name",
                type: "text",
                placeholder: "Enter Pharmacy Contact Name",
                name: "pharmacyContactName"
            },
            {
                label: "Pharmacy Contact City",
                type: "text",
                placeholder: "Enter Pharmacy Contact City",
                name: "pharmacyContactCity"
            },
            {
                label: "Pharmacy Contact Email",
                type: "text",
                placeholder: "Enter Pharmacy Contact Email",
                name: "pharmacyContactEmail"
            },
            {
                label: "Pharmacy Contact Phone Number",
                type: "text",
                placeholder: "Enter Pharmacy Contact Phone Number",
                name: "pharmacyContactPhoneNumber"
            },
            {
                label: "Pharmacy Contact Street Address",
                type: "text",
                placeholder: "Enter Pharmacy Contact Street Address",
                name: "pharmacyContactStreetAddress"
            }, ]
    }
        get inputFieldsArrayFeedback() {
            return [
               
                /*********************************************/
                
                /*********************************************/
                
                /*********************************************/
               
                /*********************************************/
                
                /*************************************************************************/
               
                /*****************************************************************************/
               
                /*****************************************************************************/
               
                
                {
                    label: "Lilly Feedback (Investigator Visible)",
                    type: "textarea",
                    name: "feedback",
    
                },
            ];
        }
  async  componentDidMount() {
        let res=await API.get('reviewConcept/schemaInformation/protocols')

        console.log(res)

        this.setState({

            proposallist:res.data

        })
        this.getCompoundList();

    }

    // getCompoundList = async () => {
    //     const res = await API.get(`seed/compound/list`)
    //     const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
    //     this.setState({
    //         compoundList: resultArray
    //     });

    // }
    getCompoundList = async () => {
        const res = await API.get(`seed/compound/list`)
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.name}));
        this.setState({compoundList: resultArray});
    }
    onCompoundSelect = (e, name) => {
        this.setState({
            [name]: e,
        });
        this.getMaterialList(name, e.name)
    }
    getMaterialList = async (name, compound) => {
        try {
            const res = await API.get(`seed/dosage_strength/compound/${compound}`);
            const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.name}));
            this.setState({[name+'MaterialList']: resultArray});
        }catch(e) {
            console.log("Error in compound material Api")
        }
    }
    onCompoundChange = (e, name) => {
        this.setState({
            [name]: e,
            patchObject: { ...this.state.patchObject, [name]: e.value },
            // hasChanged: true
        });
        this.getMaterialList(e.value)
    }

    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: dateChange(e) },
            });
        }
        else 
        { this.setState({ [e.target.name]: e.target.value });
        if (e.target.value !== "")
            this.setState({ error: { ...this.state.error, [e.target.name]: false } });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join(";") : e
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
        });
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }

    getHtmlFromEditorValue = (val) => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }

    getEditorValueFromHtml = (html) => {
        const blocksFromHTML = convertFromHTML(html);
        const state = ContentState.createFromBlockArray(blocksFromHTML);
        return EditorState.createWithContent(state);
    }


    onEditorChange = (val, e) => {
        this.setState({ [e]: val });
    };
    renderInputFieldsProposalIIR = () => {
        return this.inputFieldsArrayProposalIIR.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        // disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsInformation = () => {
        return this.inputFieldsArrayInformation.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        // disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsClinical = () => {
        return this.inputFieldsArrayClinical.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        // disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsNCRI = () => {
        return this.inputFieldsArrayNCRI.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        // disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsStudyCompound = () => {
        return this.inputFieldsArrayStudyCompound.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        // disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsContactInfo = () => {
        return this.inputFieldsArrayContactInfo.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        // disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsFeedback = () => {
        return this.inputFieldsArrayFeedback.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        // disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsFinancial = () => {
        return this.inputFieldsArrayFinancial.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        // disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    formValidation = () => {
        let is_valid = true;
        let error={};
        error["lengthValidation"]={};
        if (this.state['protocolTitle'].trim() === "") {
            is_valid = false;
            error.protocolTitle=true;
            // this.setState({
            //     error: {
            //         protocolTitle: true
            //     }
            // })
        }
        let{proposallist}=this.state;
        
        const obj = ({ ...this.state.patchObject });
        //let error={};
        for (let [key, value] of Object.entries(proposallist)) {
            if(value){
                if(this.state[key]&&this.state[key].length>value){
                    is_valid = false;
                    error[key] = true;
                    error["lengthValidation"][key]=true;
                }
            }
        }
        this.setState({error})
        return is_valid;
    }
    
    onNavOut = () => {
        this.props.history.push(`/submissions/${this.props.location.state.submissionId}`)
    }
    getErrorDescriptions=()=>{

        let {error,proposallist}=this.state;
    
        let allFields=[...this.inputFieldsArrayNCRI,
            ...this.inputFieldsArrayStudyCompound,
            ...this.inputFieldsArrayFinancial,
            ...this.inputFieldsArrayContactInfo,
            ...this.inputFieldsArrayClinical,
            ...this.inputFieldsArrayInformation,...this.inputFieldsArrayProposalIIR,];
    
        let messages = [];
    
        for (let [key, value] of Object.entries(error)) {
    
            let exist = allFields.find(p => p.name === key);
    
            if (exist) {
    
                let messageText=exist.label;
                if(error["lengthValidation"][key]){
                    messageText=messageText+' - Maximum Length should be less than '+proposallist[key]+" chars"
                }
                messages.push(messageText);
    
            }
    
        }
    
        return <ul>
    
            {messages&&messages.map((item,index)=>{
    
                return <li>{item}</li>
    
            })}
    
        </ul>}

    submitForm = async () => {
        console.log("submit", this.state)
        if (this.formValidation()) {
            const obj = {
                protocolSubmitted: this.state.protocolSubmitted,
                protocolTitle: this.state.protocolTitle,
                last_updated_by: this.state.last_updated_by,
                protocolNotes: this.state.protocolNotes,
                iACUCDocumentationAttached: this.state.iACUCDocumentationAttached,
                descriptionOfAnimalUsed: this.state.descriptionOfAnimalUsed,
                dosesConcentrations: this.state.dosesConcentrations,
                aCAndUCommitteeApproval: this.state.aCAndUCommitteeApproval,
                endpoints: this.state.endpoints,
                modelMethodology: this.state.modelMethodology,
                targetStartDate: this.state.targetStartDate,
                statisticalAnalysis: this.state.statisticalAnalysis,
                reportSubmissionDate: this.state.reportSubmissionDate,
                humanSamplesInvolved: this.state.humanSamplesInvolved,
                publicationDateAnticipated: this.state.publicationDateAnticipated,
                typeOfHumanSamples: this.state.typeOfHumanSamples,
                projectedMilestoneTiming: this.state.projectedMilestoneTiming,
                uniquePersonalClinicalSamples: this.state.uniquePersonalClinicalSamples,
                biobankOwner: this.state.biobankOwner,
                sampleSize: parseFloat(this.state.sampleSize),
                dateOfFirstPatientVisit: this.state.dateOfFirstPatientVisit,
                numberOfTherapyGroups: parseFloat(this.state.numberOfTherapyGroups),
                dateFirstPtEntersTreatment: this.state.dateFirstPtEntersTreatment,
                anticipatedQuarterlyEnrollment: parseFloat(this.state.anticipatedQuarterlyEnrollment),
                dateLastPatientEntersTrial: this.state.dateLastPatientEntersTrial,
                numberOfinvestigativeSite: parseFloat(this.state.numberOfinvestigativeSite),
                dateLastPatientEntersTreatment: this.state.dateLastPatientEntersTreatment,
                potentialCountries: this.state.potentialCountries,
                dateOfLastPatientVisit: this.state.dateOfLastPatientVisit,
                multiCountryTrial: this.state.multiCountryTrial,
                dateOfReportSubmission: this.state.dateOfReportSubmission,
                clinicalTrialPhase: this.state.clinicalTrialPhase.map(obj => obj.value).join() || "",
                includePreClinicalPortion: this.state.includePreClinicalPortion,
                dateOfPublicationSubmission: this.state.dateOfPublicationSubmission,
                assignmentToTreatment: this.state.assignmentToTreatment.map(obj => obj.value).join() || "",
                leadInPeriod: this.state.leadInPeriod.map(obj => obj.value).join() || "",
                // totalStudyLength:this.state.totalStudyLength,
                // enrollmentPeriod:this.state.enrollmentPeriod,
                design: this.state.design.map(obj => obj.value).join() || "",
                blinding: this.state.blinding.map(obj => obj.value).join() || "",
                controls: this.state.controls.map(obj => obj.value).join() || "",
                blindingDoneBy: this.state.blindingDoneBy,
                extension: this.state.extension.map(obj => obj.value).join() || "",
                patientType: this.state.patientType.map(obj => obj.value).join() || "",
                extensionLength: this.state.extensionLength,
                patientGender: this.state.patientGender.map(obj => obj.value).join() || "",
                ageRangeStart: parseFloat(this.state.ageRangeStart),
                patientTreatmentPeriod: parseFloat(this.state.patientTreatmentPeriod),
                ageRangeEnd: parseFloat(this.state.ageRangeEnd),
                // studyOnOffLabel: this.state.studyOnOffLabel,
                // LineOfTherapy: this.state.LineOfTherapy,
                lillyToSupplyStudyCompound: this.state.lillyToSupplyStudyCompound,
                ifNoWhy: this.state.ifNoWhy,
                lillyStudyCompound1: this.state.lillyStudyCompound1.value,
                lillyStudyCompound1Other: this.state.lillyStudyCompound1Other,
                compound1AvailableMaterial: this.state.compound1AvailableMaterial.value,
                compound1AvailableMaterialOther: this.state.compound1AvailableMaterialOther,
                compound1Formulation: this.state.compound1Formulation,
                compound1DosingSchedule: this.state.compound1DosingSchedule,
                compound1DosingSchedulOther: this.state.compound1DosingSchedulOther,
                compound1AdministeredDose: this.state.compound1AdministeredDose,
                // compound1AmountQuantity: this.state.compound1AmountQuantity,
                requestedAmtOfComp1PreClin: this.state.requestedAmtOfComp1PreClin,
                requireSecondLillyCompound: this.state.requireSecondLillyCompound,
                requestedAmtOfComp5PreClin: this.state.requestedAmtOfComp5PreClin,
                lillyStudyCompound2: this.state.lillyStudyCompound2.value,
                lillyStudyCompound2Other: this.state.lillyStudyCompound2Other,
                compound2AvailableMaterial: this.state.compound2AvailableMaterial.value,
                compound2AvailableMaterialOther: this.state.compound2AvailableMaterialOther,
                compound2Formulation: this.state.compound2Formulation,
                compound2DosingSchedule: this.state.compound2DosingSchedule,
                compound2DosingScheduleOther: this.state.compound2DosingScheduleOther,
                compound2AdministeredDose: this.state.compound2AdministeredDose,
                // compound2AmountQuantity: this.state.compound2AmountQuantity,
                requestedAmtOfComp2PreClin: this.state.requestedAmtOfComp2PreClin,
                requireThirdLillyCompound: this.state.requireThirdLillyCompound,
                lillyStudyCompound3: this.state.lillyStudyCompound3.value,
                lillyStudyCompound3Other: this.state.lillyStudyCompound3Other,
                compound3AvailableMaterial: this.state.compound3AvailableMaterial.value,
                compound3AvailableMaterialOther: this.state.compound3AvailableMaterialOther,
                compound3Formulation: this.state.compound3Formulation,
                compound3DosingSchedule: this.state.compound3DosingSchedule,
                compound3DosingScheduleOther: this.state.compound3DosingScheduleOther,
                compound3AdministeredDose: this.state.compound3AdministeredDose,
                requestedAmtOfComp3PreClin: this.state.requestedAmtOfComp3PreClin,
                requireFourthLillyCompound: this.state.requireFourthLillyCompound,
                lillyStudyCompound4: this.state.lillyStudyCompound4.value,
                lillyStudyCompound4Other: this.state.lillyStudyCompound4Other,
                compound4AvailableMaterial: this.state.compound4AvailableMaterial.value,
                compound4AvailableMaterialOther: this.state.compound4AvailableMaterialOther,
                compound4Formulation: this.state.compound4Formulation,
                compound4DosingSchedule: this.state.compound4DosingSchedule,
                compound4DosingScheduleOther: this.state.compound4DosingScheduleOther,
                compound4AdministeredDose: this.state.compound4AdministeredDose,
                requestedAmtOfComp4PreClin: this.state.requestedAmtOfComp4PreClin,
                nonLillySupport: this.state.nonLillySupport,
                nameOfCompany: this.state.nameOfCompany,
                typeOfSupport: this.state.typeOfSupport,
                hcpProtocolTitle: this.state.hcpProtocolTitle,
                feedback: this.state.feedback,
                protocolComments: this.state.protocolComments,
                budgetOfficeContactName: this.state.budgetOfficeContactName,
                contractContactName: this.state.contractContactName,
                budgetOfficeStreetAddress: this.state.budgetOfficeStreetAddress,
                contractContactStreetAddress: this.state.contractContactStreetAddress,
                budgetOfficeCity: this.state.budgetOfficeCity,
                contractContactCity: this.state.contractContactCity,
                budgetOfficeEmail: this.state.budgetOfficeEmail,
                contractContactEmail: this.state.contractContactEmail,
                budgetOfficePhoneNumber: this.state.budgetOfficePhoneNumber,
                contractContactPhoneNumber: this.state.contractContactPhoneNumber,
                pharmacyContactName: this.state.pharmacyContactName,
                pharmacyContactCity: this.state.pharmacyContactCity,
                pharmacyContactEmail: this.state.pharmacyContactEmail,
                pharmacyContactPhoneNumber: this.state.pharmacyContactPhoneNumber,
                pharmacyContactStreetAddress: this.state.pharmacyContactStreetAddress,
                createdByID: this.context.userSystemId,
                iirUrl: window.location.origin,

            }
            try {
                const res = await API.post("protocol/add", {
                    "protocol": { ...obj, createdByID: this.context.userSystemId }, "submission": {
                        id: this.props.location.state.submissionId
                        // stage: "Protocol"
                    }
                });
                this.setState({ successModal: true });
            } catch (err) {
                this.setState({ errorModal: true });
            }
        }else{
            
            this.setState({errorModal:true})
        }
    }
    changeShowState = () => {
      
      if(!this.state.errorModal)      
        this.props.history.push(`/submissions/${this.props.location.state.submissionId}`)

        this.setState({errorModal:false})
    }
    handleBackClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth' })}
    render() {

        return (
            <Container className="form-page">
                <div onClick={this.onNavOut} className="back-button">
                    <i className="bi bi-arrow-left">
                        {" "}Submission-{this.props.location.state.submissionId}
                    </i>
                </div>
                <h2 className="text-center">New Protocol</h2>
                <Accordion defaultActiveKey="1" ref={this.titleRef}>
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                           Information
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsInformation()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={this.handleBackClick}>
                                          Protocol Information
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsProposalIIR()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={this.handleBackClick}>
                                        Non Clinical Research Info
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="3">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsNCRI()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="4" onClick={this.handleBackClick}>
                                       Clinical Research Info
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="4">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsClinical()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="5" onClick={this.handleBackClick}>
                                        Study Compound Support
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="5">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsStudyCompound()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                   
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="6" onClick={this.handleBackClick}>
                                        Financial Support
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="6">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsFinancial()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="7" onClick={this.handleBackClick}>
                                        Feedback
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="7">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsFeedback()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="8" onClick={this.handleBackClick}>
                                        Contact Information
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="8">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsContactInfo()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    </Accordion>
                                    <Row>
                    
                    <div className="w-100 d-flex justify-content-center">
                        <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm}>
                            Save
                        </Button>
                    </div>
                </Row>
                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body="Protocol has been created!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body={<><div>An error occured! Please try again later!</div>{this.getErrorDescriptions()}</>}
                        changeShowState={this.changeShowState}
                    />
                }
            </Container>
        )
    }
}
