import React,{useState,useEffect,useCallback,useRef} from 'react'
import {Row,Col } from 'react-bootstrap';
import Tab from '../Tab';
// export default ({tabs,path,title,handleEvents})=>{
export default ({tabs,path,title,handleEvents,hrefPath,history})=>{

        return <div className='row d-print-none'>
            <div className="col-12">
                <div className='d-flex w-100 pb-3  pl-0  '>
                   {/* <a href='/submissions' className="pr-1">{path}</a>/ <span className="pl-1">{title}</span> */}
                   <a href={"javascript:void(0)"}
                   onClick={e=>{
                    history.push(hrefPath);
                   }}
                   className="pr-1">{path}</a>/ <span className="pl-1">{title}</span>
                </div>
                <div className="row">
                    <div className='col-md-3'>
                            <div className="d-flex align-items-center h-100"><h5>{title}</h5></div>
                    </div>
                    <div className='col-md-9'>
                        <Tab source={tabs} onChange={handleEvents} />
                    </div>
                </div>
            </div>
           
           
        </div>
}