import React, { Component } from 'react'
import { Col, Row, Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import API from '../../../../../api/apis';
import ReviewerModal from './ReviewerModal';
import Loader from '../../../../common/loader/Loader';
import BootstrapModal from '../../../../common/modal/BootstrapModal';
import swal from 'sweetalert'
import UserContext from '../../../../../context/UserContext';
import DatatableComponent from '../../../../common/datatable/DatatableComponent';
import { getUserInfo, dateTimeformat, enableWFActions } from '../../../../../utility/helperFunctions';


class Reviewers extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        reviewerDetails: [],
        error: false,
        successModal: false,
        errorModal: false,
    }
    componentDidMount() {
        this.getReviewersDetailsBySubmissionId(this.props.submissionDetails.id)
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                 create: selectedRole.includes("administrator") || selectedRole.includes("coordinator") ,
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("coordinator") ,
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    getReviewersDetailsBySubmissionId = async (submissionId) => {
        try {
            const reviewerDetails = await API.get(`review/reviewer/submission/${submissionId}`);
            let arr =[];
for(const e of reviewerDetails.data)    
            {let header = await getUserInfo(e.reviewer_system_id);
                arr.push({...e,header: header})
            }
            
            this.setState({
                reviewerDetails: arr,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    handleModalShowHide = async (flag, viewEditFlag, row) => {
        if (row && row.id && viewEditFlag === "edit") {
            await this.getReviewerById(row.id)
            this.setState({ showHideModal: flag, viewEditFlag });
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
    }

    getReviewerById = async (id) => {
        try {
            const reviewerDetails = await API.get(`review/reviewer/getbyid/${id}`);
            this.setState({
                viewEditData: reviewerDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }

    handleReviewerDetails = (row) => {
        this.props.history.push(`/reviewer/details`, {reviewerId: row.id,submissionId: this.props.submissionDetails.id});
    }
    columns = [{
        dataField: 'reviewer_system_id',
        text: 'Reviewer',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={() => this.handleReviewerDetails(row)}>{row.header}</p>
            </>
        }
    }, {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.access.create  || (this.context.userSystemId === row.reviewer_system_id)? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            
            return <>
                {<i className="bi bi-eye-fill" onClick={() => this.handleReviewerDetails(row)}
                    style={{ fontSize: "1.2rem", color: "black", padding: ".1rem" }}></i>}

               {enableWFActions() && <i className="bi bi-trash-fill" onClick={() => this.handleReviewerDelete(row)}
                    style={{visibility: visibilityDelete, fontSize: "1.2rem", color: "black", padding: ".1rem", float: "right" }}></i> }
                {enableWFActions() && <i className="bi bi-arrow-right-square-fill" rel="tooltip" title="Submit Review" onClick={() => this.handleModalShowHide(true, "edit", row)}
                    style={{ visibility: visibilityEdit, fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>}
            </>
        }
    }];


    handleReviewerSave = async (state) => {
        delete state.currencyList;
        delete state.showLoader;
       delete state.usersList;
       let obj = {
        reviewer_system_id : state.reviewer_system_id,
        comments : state.comments,
        review_type: state.review_type,
        due_date: state.due_date
       };
        obj.created_by = this.context.userSystemId;
        obj.submission_id = this.props.submissionDetails.id;
        try {
            const res = await API.post("review/reviewer/add", obj);
            if(res.data.error){
                return res;
            }
            else {
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getReviewersDetailsBySubmissionId(this.props.submissionDetails.id);
            return res;
            // this.props.getSubmissionDetail(this.props.submissionDetails.id);
            }
        } catch (err) {
            this.setState({ errorModal: true });
        }

    }
    handleReviewerUpdate = async (state) => {
        delete state.compoundList;
        delete state.showLoader;
        delete state.created_by;
        delete state.usersList;

        state.updated_by = this.context.userSystemId;
        // state.created_by = this.context.userSystemId;
        try {
            const res = await API.post("review/reviewer/update", state);
            console.log(res);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getReviewersDetailsBySubmissionId(this.props.submissionDetails.id)
            // this.props.getSubmissionDetail(this.props.submissionDetails.id);

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }


    handleReviewerDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })

          
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    const res = await API.post(`recycle/reviewer/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                    this.setState({
                        success_body: "Deleted successfully!",
                        successModal: true,
                        hasChanged: false
                    });
                    this.getReviewersDetailsBySubmissionId(this.props.submissionDetails.id)
                } catch (err) {

                    this.setState({ errorModal: true });
                }
              
            } 
          });

        // try {
        //     const res = await API.delete(`study/delete/${row.id}`);
        //     this.setState({
        //         success_body: "Deleted successfully!",
        //         successModal: true,
        //         hasChanged: false
        //     });
        //     this.getReviewersDetailsBySubmissionId(this.props.submissionDetails.id)
        // } catch (err) {
        //     this.setState({ errorModal: true });
        // }

    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }



    render() {
        const { showHideModal, viewEditFlag, viewEditData, reviewerDetails,
            successModal, showLoader, success_body, errorModal } = this.state;
        return (<> {
            showLoader ? <Loader /> :
                <>
                    <Row>
                        <Col md={6}>
                            <h6 className="table-top">  Reviewers ({reviewerDetails.length})</h6>
                        </Col>
                        <Col md={2}>
                            {this.state.access.create && enableWFActions() && <Link as={Button}>
                                <Button variant="outline-light" disabled ={reviewerDetails.length>=10} className="new-button" onClick={() => this.handleModalShowHide(true, null, null)}>New</Button>
                            </Link>}
                        </Col>
                        <DatatableComponent id="reviewer" data={reviewerDetails} columns={this.columns} pagination="true" />
                    </Row>
                    {
                        showHideModal &&
                        <ReviewerModal
                            showHideModal={showHideModal}
                            handleModalShowHide={this.handleModalShowHide}
                            handleReviewerSave={this.handleReviewerSave}
                            handleReviewerUpdate={this.handleReviewerUpdate}
                            viewEditFlag={viewEditFlag}
                            viewEditData={viewEditData}
                            submissionId={this.props.submissionDetails.id}
                        />
                    }
                    {this.state.successModal &&
                        <BootstrapModal
                            show={successModal}
                            header="Success"
                            body={success_body}
                            changeShowState={this.changeShowState}
                        />
                    }
                    {this.state.errorModal &&
                        <BootstrapModal
                            show={errorModal}
                            variant="danger"
                            header="Ooops!!"
                            body="An error occured! Please try again later!"
                            changeShowState={this.changeShowState}
                        />
                    }
                </>
        }
        </>
        )
    }
}


export default withRouter(Reviewers);