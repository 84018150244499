import React,{useEffect,useState} from 'react'
import { DEFAULT } from '../../../TooltipInfo';
import ToolTipEx from '../ToolTipEx';

export default(props)=>{

    const [showSearchBox,setShowSearchBox]=useState(props.alwaysOpenSearch||false);
    const [inputValue,setInputValue]=useState("");
    useEffect(()=>{
        if(props.searchText){
            setInputValue(props.searchText);
            setShowSearchBox(true);
        }
    },[props.searchText]);
    useEffect(()=>{
        if(props.clearSearch){
            setInputValue('');
            props.onSearch('');
        }
    },[props.clearSearch])
    const setSearchSession=(val)=>{
        console.log("props.tableId",props.tableId)
        if(props.tableId){
            localStorage.setItem("filter_" + props.tableId, val); 
        }
    }
    const handleClick = () => {
     setSearchSession(inputValue);
      props.onSearch(inputValue);
    };
    const handleSearch=()=>{
        if(inputValue.length<=0){
            setSearchSession('');
            props.onSearch('');
            if(!props.alwaysOpenSearch)
            setShowSearchBox(!showSearchBox);
        }
        else{
            handleClick();
        }
        
    }
    return <div className='d-flex align-items-center'>
        {showSearchBox&&<input className="form-control form-control-sm mr-1" placeholder='Search' onKeyDown={e=>{
            let code=(e.keyCode?e.keyCode:e.which);
            if(code==13){
                handleSearch();
            }
        }} onChange={e=>{setInputValue(e.target.value);}} value={inputValue}></input>}
       <ToolTipEx tooltip={DEFAULT.SEARCH}><i className="bi bi-search mr-3" onClick={handleSearch} style={{ fontSize: '1rem',cursor:'pointer' }}></i></ToolTipEx>
    </div>
}