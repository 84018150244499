import React from 'react'
import { Accordion, Row,Col,Card,Form } from 'react-bootstrap';
import API from '../../../api/apis'
import InputField from '../../common/input-fields/InputField';
export default class ConceptInfo extends React.Component{

    constructor(props) {
        super(props);
        this.state={         
            investigatorName:  "",
            extInvestigatorCountry: "",
            extPrimaryInstitution: "",
            requestType: "",
            therapeuticArea:  "",
            compound: "",
            indication: "",
            otherIndication:  "",
            conceptTitle: "",
            conceptDescription:  "",
            submissionId: "",
            submission_id:"",
            primaryOrganisation:"",
            compoundText:"",countryText:""
        }
    }    
    componentDidMount(){
        this.getConceptDetails();
    }
    getOtherInformations=async()=>{
        let responses={
            compoundInfo:null,
            countryInfo:null
        };
        let tasks=[responses.compoundInfo,responses.countryInfo];
        let {compound,extInvestigatorCountry}=this.state;
        responses.compoundInfo=API.get(`seed/compound/getbyid/${compound}`);
        responses.countryInfo=API.get(`seed/country/getbyid/${extInvestigatorCountry}`);
        await Promise.all(tasks) .catch((e) => {           
            responses={
                compoundInfo:null,
                countryInfo:null
            };
        });
        let compoundText="";
        let countryText="";
        if(responses.compoundInfo&&responses.compoundInfo.data){
            compoundText=responses.compoundInfo.data["name"]||"";
        }
        if(responses.countryInfo&&responses.countryInfo.data){
            countryText=responses.countryInfo.data["name"]||"";
        }
        this.setState({compoundText,countryText});
    }
     getConceptDetails=async()=>{
       let {submission_id}=this.props;
        if(submission_id){
            const res = await API.get(`reviewConcept/concept/getbyid/${submission_id}`);
            if(res&&res.data&&res.data.success){
            this.setState({
                conceptDetails: res.data.data,
                investigatorName: res.data.data.investigatorname || "",
                extInvestigatorCountry:res.data.data.country_name || "",
                extPrimaryInstitution: res.data.data.primary_institution || "",
                vitro_vivo_study:res.data.data.vitro_vivo_study,
                assessing_utility_biomarkers:res.data.data.assessing_utility_biomarkers,
                request_for_lilly_trial_data:res.data.data.request_for_lilly_trial_data,
                interventional_study:res.data.data.interventional_study||null,
                requesting_research_funding: res.data.data.requesting_research_funding || "",
                requestType: res.data.data.request_type || "",
                therapeuticArea: res.data.data.therapeutic_area || "",
                compound: res.data.data.compound,
                indication: res.data.data.indication||"",
                otherIndication: res.data.data.other_indication || "",
                conceptTitle: res.data.data.title || "",
                conceptDescription: res.data.data.description || "",
                submissionId: `Submission-${res.data.data.submissionId}` || "",
                submission_id:res.data.data.submissionId,
                primaryOrganisation:res.data.data.primary_institution
            });
        }}
    }
    render(){
        let newOptions=["Yes","No"]
        return <div>
            <Accordion className="border  shadow-sm" defaultActiveKey="0">
                <Card style={{ "margin-bottom": " 0! important", "box-shadow": "none" }} >
                    <Accordion.Toggle as={Card.Header} eventKey="0"  >
                        Investigator Information
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                        <Row>
                                <Col sm={6} xs={12} >
                                <div style={{marginLeft:'2%'}}>
                                    <InputField  
                                        label="Investigator Name"                                     
                                        value={this.state.investigatorName}
                                        type="text"
                                        disabled
                                    />
                                    </div>
                                </Col>
                                <Col sm={6} xs={12} >
                                <div style={{marginLeft:'2%'}}>
                                    <InputField
                                        label="Country"
                                         type="text"
                                        value={this.state.extInvestigatorCountry}
                                        disabled
                                    />
                                    </div>
                                </Col>
                        </Row>
                        <Row>
                                <Col sm={6} xs={12} >
                                <div style={{marginLeft:'2%'}}>
                                    <InputField 
                                        label="Primary Institution"                                      
                                        value={this.state.primaryOrganisation}
                                        type="text"
                                        disabled
                                    />
                                    </div>
                                </Col>
                                <Col sm={6}/>
                                <Col sm={6} style={{ left: "0.5rem", marginBottom: "2rem" }} >

                                    <label>{"Is this request for an interventional study (i.e., involving human subjects)?"}</label>
                                    <div>

                                        {newOptions.map((item, index) => {
                                            return <Form.Check value={item} checked={this.state.interventional_study === item} name={"interventional_study"} onChange={this.onInputChange} inline label={item} type="radio" />
                                        })}
                                    </div>

                                </Col>
                                <Col sm={6} style={{ left: "0.5rem", marginBottom: "2rem" }} >

                                    <label>Is this a request for <u>Lilly trial data or samples</u>?</label>
                                    <div>

                                        {newOptions.map((item, index) => {
                                            return <Form.Check value={item} checked={this.state.request_for_lilly_trial_data === item} name={"request_for_lilly_trial_data"} onChange={this.onInputChange} inline label={item} type="radio" />
                                        })}
                                    </div>

                                </Col>
                                <Col sm={6} style={{ left: "0.5rem", marginBottom: "2rem" }} >

                                    <label>{"Is this a pre-clinical/non-clinical request for an in vivo (i.e., mouse model) and/or in vitro (i.e., test tube/cell line) study?"}</label>
                                    <div>

                                        {newOptions.map((item, index) => {
                                            return <Form.Check value={item} checked={this.state.vitro_vivo_study === item} name={"vitro_vivo_study"} onChange={this.onInputChange} inline label={item} type="radio" />
                                        })}
                                    </div>

                                </Col>
                                <Col sm={6} style={{ left: "0.5rem", marginBottom: "2rem" }} >

                                    <label>{"Will you be assessing the utility of diagnostic methods and/or predictive or prognostic biomarkers?"}</label>
                                    <div>

                                        {newOptions.map((item, index) => {
                                            return <Form.Check value={item} checked={this.state.assessing_utility_biomarkers === item} name={"assessing_utility_biomarkers"} onChange={this.onInputChange} inline label={item} type="radio" />
                                        })}
                                    </div>

                                </Col>
                                <Col sm={6} style={{ left: "0.5rem", marginBottom: "2rem" }} >

                                    <label>{"Are you requesting research funding?"}</label>
                                    <div>

                                        {newOptions.map((item, index) => {
                                            return <Form.Check value={item} checked={this.state.requesting_research_funding === item} name={"requesting_research_funding"} onChange={this.onInputChange} inline label={item} type="radio" />
                                        })}
                                    </div>

                                </Col><Col sm={6} />
                               
                        </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card style={{ "margin-bottom": " 0! important", "box-shadow": "none" }} >
                    <Accordion.Toggle as={Card.Header} eventKey="0"  >
                        Concept Information
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                        <Row>
                                {/* <Col sm={6} xs={12} >
                                <div style={{marginLeft:'2%'}}>
                                    <InputField 
                                        label="Request Type"                                      
                                        value={this.state.requestType}
                                        type="text"
                                        disabled
                                    />
                                    </div>
                                </Col> */}
                                <Col sm={6} xs={12} >
                                <div style={{marginLeft:'2%'}}>
                                    <InputField
                                        label="Therapeutic Area"
                                         type="text"
                                        value={this.state.therapeuticArea}
                                        disabled
                                        
                                    />
                                    </div>
                                </Col>
                        </Row>
                        <Row>
                                <Col sm={6} xs={12} >
                                <div style={{marginLeft:'2%'}}>
                                    <InputField 
                                        label="Compound"                                      
                                        value={this.state.compound}
                                        type="text"
                                        disabled
                                    />
                                    </div>
                                </Col>
                                <Col sm={6} xs={12} >
                                <div style={{marginLeft:'2%'}}>
                                    <InputField
                                        label="Indication"
                                         type="text"
                                        value={this.state.indication}
                                        disabled
                                        placeholder="Indication"
                                    />
                                    </div>
                                </Col>
                        </Row>
                        <Row>
                                <Col sm={6} xs={12} >
                                <div style={{marginLeft:'2%'}}>
                                    <InputField 
                                        label="Other Indication"                                      
                                        value={this.state.otherIndication}
                                        type="text"
                                        placeholder="Other Indication"
                                        disabled
                                    />
                                    </div>
                                </Col>
                                <Col sm={6} xs={12} >
                                <div style={{marginLeft:'2%'}}>
                                    <InputField
                                        label="Concept Title"
                                         type="text"
                                        value={this.state.conceptTitle}
                                        disabled
                                    />
                                    </div>
                                </Col>
                        </Row>
                        <Row>
                                <Col sm={6} xs={12} >
                                <div style={{marginLeft:'2%'}}>
                                    <InputField 
                                        label="Submission Id"                                      
                                        value={this.state.submissionId}
                                        type="text"
                                        disabled
                                    />
                                    </div>
                                </Col>
                                
                        </Row>
                        <Row>
                                <Col sm={12} xs={12} >
                                    <InputField 
                                        label="Concept Description"                                      
                                        value={this.state.conceptDescription}
                                        type="textarea"
                                        disabled
                                    />
                                </Col>
                                
                        </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    }
}