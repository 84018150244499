import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import InputField from './common/input-fields/InputField';
import API from '../api/apis';
import BootstrapModal from './common/modal/BootstrapModal';

import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import UserContext from '../context/UserContext';
import { getUserInfo } from '../utility/helperFunctions';


export default class NewDelegee extends Component {
    static contextType = UserContext;
// {
//     "last_modified_by_id" : 1,
//     "last_updated_by" : 1,
//     "first_name" : "first",
//     "email" : "e242e2eem@email.com",
//     "submissionId_id" : 1,
//     "owner_id":"2"
    
// }

    // checkbox returns boolean value
    state={       
        owner_id:this.context.userSystemId,
        name:"",
        first_name:"",
        last_updated_by:"",
        last_name:"",
        email:"",
        accepted:false,
        last_modified_by_id:"",
        submissionId: `Submission-${this.props.location.state.submissionId}`,
        role_id:"",
        other_role:"",
        accept_privacy_statement:false,
        accept_privacy_statement_attested_date:"",
        no_confidential_information:false,
        no_confidential_info_attested_date:"",
        no_sensitive_personal_information:false,
        no_sensitive_personal_info_attested_date:"",
        working_on_sponsor_behalf:false,
        working_on_sponsor_behalf_attested_date:"",
        delegeeEmail:false,
        rolesList:[],
        cancelModal: false,
    }
   
get inputFieldsArray(){
    return [
        {
            type: "alert",
            label: "Information",
            variant: "secondary"
        },
        {
            label: "LLIR Delegee Name",
            type: "text", 
            name: "name",
            disabled: true
        },
         {
            label: "Owner",
            type: "text", 
            name: "owner_id",
        },
        {
            label: "[EXT] First Name",
            type: "text",
            placeholder: "Enter [EXT] First Name",
            name: "first_name",
        },
        {
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
           
        },
        {
            label: "[EXT] Last Name",
            type: "text",
            placeholder: "Enter [EXT] Last Name",
            name: "last_name",
        },
        {
            label: "[EXT] Email",
            type: "text",
            placeholder: "Enter [EXT] Email",
            name: "email",
           

        },
        {
            label: "Accepted",
            type: "checkbox",
           
            name: "accepted",
        },
        {
            label: "Submission",
            type: "text",
            name: "submissionId",
        },
        {
            label: "[EXT] Roles",
            type: "searchableDropdown",
            placeholder: "Select [EXT] Roles",
            name: "role_id",
            options: this.state.rolesList,
            onSelect: this.onCountryChange
        },
        {
            label: "[EXT] Other Role",
            type: "text",
            placeholder: "Enter [EXT] Other Role",
            name: "other_role",
        },
        {
            type: "alert",
            label: "Delegee Attestations",
            variant: "secondary"
        },
        {
            label: "Accept Privacy Statement",
            type: "checkbox",
            placeholder: "Enter Accept Privacy Statement",
            name: "accept_privacy_statement",
            
        },
       
        {
            label: " Accept Privacy Statement Attested Date",
            type: "datetime-local",
            placeholder: "Enter  Accept Privacy Statement Attested Date",
            name: "accept_privacy_statement_attested_date",
             
        },
        {
            label: "No confidential information",
            type: "checkbox",
            placeholder: "Enter No confidential information",
            name: "no_confidential_information",
            
        },
       
        {
            label: " No confidential info. Attested Date",
            type: "datetime-local",
            placeholder: "Enter  No confidential info. Attested Date",
            name: "no_confidential_info_attested_date",
            
        },
        {
            label: "No sensitive personal information",
            type: "checkbox",
            placeholder: "Enter No sensitive personal information",
            name: "no_sensitive_personal_information",
            
        },
        
        {
            label: "No sensitive personal info Attested Date",
            type: "datetime-local",
            placeholder: "Enter No sensitive personal info Attested Date",
            name: "no_sensitive_personal_info_attested_date",
            
        },
        {
            label: "Working on Sponsor Behalf",
            type: "checkbox",
            placeholder: "Enter Working on Sponsor Behalf",
            name: "working_on_sponsor_behalf",
            
        },
        {
            label: "Working on Sponsor Behalf Attested Date",
            type: "datetime-local",
            placeholder: "Enter Working on Sponsor Behalf Attested Date",
            name: "working_on_sponsor_behalf_attested_date",
            
        },
       
    ];
}
    componentDidMount() {
        this.getRoleslist();     
    }
   
    getRoleslist = async () =>{
        const res = await API.get("seed/roles/fetch");
        const resultArray = (res.data?.data || []).map(elm => ({ name: elm.name, value: elm.role_id}));
        console.log("result",resultArray)
        this.setState({
            owner_id:await getUserInfo(this.context.userSystemId),
            rolesList: resultArray,
            // role_id: resultArray.find(cnt => ""+cnt.value === this.state.role_id) || null,   
        });     
    }
    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [name]: e.value},
            hasChanged: true
        });
        
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/data");
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.countrycode}));
        this.setState({ 
            countryList: resultArray,
            // countryLegal: resultArray.find(cnt => cnt.value === this.props.submissionIdDetails.investigator_country) || []
        },
            // () => this.getStateList(this.state.countryLegal?.name)
            );
            
    }

    getStateList = async (country) => {
        if (country) {
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({ 
            stateList: resultArray,
            stateRepresentativeState: resultArray.find(state => state.value+"" === this.state.stateRepresentativeState) || ""
        });}
    }
    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [name]: e.value},
            // hasChanged: true
        });
        this.getStateList(e.name)
    }

  
    onInputChange = (e,eo) => {
        if (eo.type === "datetime-local") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
       else 
       { this.setState({[e.target.name]: e.target.value});
        if(e.target.value !== "")
            this.setState({error: { ...this.state.error, [e.target.name]: false}});}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
        });        
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
        }); 
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }

    getHtmlFromEditorValue = (val) => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }

    getEditorValueFromHtml = (html) => {
        const blocksFromHTML = convertFromHTML(html);        
        const state = ContentState.createFromBlockArray(blocksFromHTML);
        return EditorState.createWithContent(state);
    }
    changeShowState = () => {
        this.setState({
            cancelModal:false
        })
        this.props.history.push(`/submissions/${this.props.location.state.submissionId}`)
    }
    cancel =()=>{
        this.setState({
            cancelModal:true
          });  

    }
    onEditorChange = (val, e) => {
        this.setState({[e]: val});
    };

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    key={field.label}
                    error={this.state.error?.[field.name]}
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e) => { this.onInputChange(e, field) }) : this.checkboxChange}
                />
            </Col>
            )
        });            
    }

    formValidation = () => {
        let is_valid = true;
        let error = {};
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(this.state['email'])) {
            is_valid = false;
            console.log("email required");
            error.email = true;
        }
        
        if((this.state.role_id === "") || (this.state.role_id === null)) {
            is_valid = false;
            error.role_id =  true
               
        }
        this.setState({
            error : error
        })
        return is_valid;
    }

    submitForm = async () => {
       if(this.formValidation()){
     const obj = {
         name:this.state.name,
        first_name:this.state.first_name,
    last_updated_by:this.state.last_updated_by,
    last_name:this.state.last_name,
    email:this.state.email,
    accepted:this.state.accepted,
    submissionId:this.props.location.state.submissionId,
    role_id:this.state.role_id.value || "",
    other_role:this.state.other_role,
    accept_privacy_statement:this.state.accept_privacy_statement || false,
    accept_privacy_statement_attested_date:this.state.accept_privacy_statement_attested_date.length > 0 ? new Date(this.state.accept_privacy_statement_attested_date+":00").toISOString() : null,
    no_confidential_information:this.state.no_confidential_information || false,
    no_confidential_info_attested_date:this.state.no_confidential_info_attested_date.length > 0 ?new Date(this.state.no_confidential_info_attested_date+":00").toISOString()  : null,
    no_sensitive_personal_information:this.state.no_sensitive_personal_information || false,
    no_sensitive_personal_info_attested_date: this.state.no_sensitive_personal_info_attested_date.length > 0 ? new Date(this.state.no_sensitive_personal_info_attested_date+":00").toISOString()  : null,
    working_on_sponsor_behalf:this.state.working_on_sponsor_behalf || false,
    working_on_sponsor_behalf_attested_date:this.state.working_on_sponsor_behalf_attested_date.length > 0 ? new Date(this.state.working_on_sponsor_behalf_attested_date+(":00")).toISOString()  : null,
    owner_id:this.context.userSystemId,
     }
        try {
            const res = await API.post("/delegee/add", obj);
            this.setState({successModal: true});
        }catch(err){
            this.setState({errorModal: true});
      }
     }
     }
     onNavOut = () => {
        this.props.history.push(`/submissions/${this.props.location.state.submissionId}`)
    }
    render() {
        // console.log(this.props.location.state.submissionIdId)
        return (
            <Container className="form-page">
                <div onClick={this.onNavOut} className="back-button">
                    <i className="bi bi-arrow-left">
                    {" "}{this.state.submissionId}
                            </i>
                </div>
                <h2 className="text-center">New LLIR Delegee</h2>
                <Row>
                        {this.renderInputFields()}
                        {/* <TextEditor /> */}
                        <div className="w-100 d-flex justify-content-center">
                        <Button className="ml-1" variant="primary" type="submit" onClick={this.cancel}>
                            Cancel
                        </Button>
                        <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm}>
                            Save
                        </Button>
                        </div>
                </Row>
                {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Delegee has been created!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                  {this.state.cancelModal &&
                    <BootstrapModal 
                        show={this.state.cancelModal} 
                        header="Cancel"
                        body="Cancel Data"
                       changeShowState={this.changeShowState}
                    />
                }
            </Container>
        )
    }
}
