import React, { Component } from 'react'
import { Col, Row, Button, Nav } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import DatatableComponent from '../../../common/datatable/DatatableComponent';
import API from "../../../../api/apis";
import NewPublication from '../../../NewPublication';
import swal from 'sweetalert';
import UserContext from '../../../../context/UserContext';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import { dateChange, enableWFActions } from '../../../../utility/helperFunctions';
class Publication extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        publicationData: [],
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
    }
    columns = [{
        dataField: 'title',
        text: 'Title',
        sort: true,
        classes:"wrapClass",
        formatter: (cell, row, rowIndex) => {
            return <>
                <Nav.Link as={NavLink} to={{pathname:`/publication/details/${row.id}`,state:{publicationId: row.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={() => this.handlePublicationDetails(row)}>{row.title}</Nav.Link>
            </>
        }
    },
         {
        dataField: 'format',
        text: 'Format',
        sort: true
    }, {
        dataField: 'review',
        text: 'Review',
        sort: true
    },
    {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";


            return <>
                {enableWFActions() && <i className="bi bi-trash-fill" onClick={() => this.handlePublicationDelete(row)}
                    style={{ visibility: visibilityDelete,fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>}
                {enableWFActions() && <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row)}
                    style={{visibility: visibilityEdit, fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i>}
            </>
        }
    }
    ];

    componentDidMount() {
    
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:   selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
     this.getPublication(this.props.submissionDetails.id);
}
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }

    handleModalShowHide = async (flag, viewEditFlag, row) => {
        if (viewEditFlag === "edit" && row && row.id) {
            await this.getPublicationById(row.id);
            this.setState({ showHideModal: flag, viewEditFlag })
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} })
    }
    getPublication = async (submissionid) => {
        try {
            const res = await API.get(`publication/submission/${submissionid}`)
            this.setState({ publicationData: res.data });
            console.log(res.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }
    getPublicationById = async (id) => {
        const res = await API.get(`publication/getbyid/${id}`)
        this.setState({ viewEditData: res.data })
        console.log(this.state.viewEditData)
    }
    handlePublicationDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async(willDelete) => {
                if (willDelete) {
                    try {
                        const res =  await API.post(`recycle/publication/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                        swal("Deleted Successfully", {
                            icon: "success",
                        });
                        this.getPublication(this.props.submissionDetails.id);
                    }
                    catch (err) {
                        this.setState({ errorModal: true });
                    }
                    this.getPublication(this.props.submissionDetails.id);
                }
            });
    }
    handlePublicationSave = async (state) => {
        delete state.submissionId;
        state.submission_id = this.props.submissionDetails.id;
        state.created_by = this.context.userSystemId;
        state["date_of_publication"] = state.date_of_publication != "" && state.date_of_publication != null ?
            dateChange(state.date_of_publication) : null;
            state["planned_date_of_submission"] = state.planned_date_of_submission != "" && state.planned_date_of_submission != null ?
            dateChange(state.planned_date_of_submission) : null;
        try {
            const res = API.post("publication/add", state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })
            this.getPublication(this.props.submissionDetails.id);
        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
    }
    handlePublicationUpdate = async (state) => {
        delete state.submissionId;
        delete state.created_by;
        state.submission_id = this.props.submissionDetails.id;
        state.updated_by = this.context.userSystemId;
        state["date_of_publication"] = state.date_of_publication != "" && state.date_of_publication != null ?
        dateChange(state.date_of_publication): null;
        state["planned_date_of_submission"] = state.planned_date_of_submission != "" && state.planned_date_of_submission != null ?
        dateChange(state.planned_date_of_submission) : null;
        try {
            const res = API.put(`publication/update/`, state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            })
            this.getPublication(this.props.submissionDetails.id);
        }
        catch (err) {
            console.log(err);
            this.setState({ errorModal: true })
        }
    }
    handlePublicationDetails = (row) => {
        this.props.history.push(`/publication/details/${row.id}`, { publicationId: row.id })
    }
    // rowEvents = {
    //     onClick: (e, row, rowIndex) => {
    //         console.log(row['Record Number']);
    //         this.props.history.push(`/submissions/conceptPage`);
    //     }
    // };
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getPublication(this.props.submissionDetails.id);
    }
    render() {
        const { showHideModal, viewEditFlag, viewEditData , successModal,errorModal,success_body } = this.state
        return <>
            <Row>
                <Col md={4}>
                    <h6 class="table-top">
                        Publications ({this.state.publicationData.length})
                    </h6>
                </Col>
                <Col md={4}>
                   {this.state.access.create && enableWFActions() && <Link as={Button} onClick={() => this.handleModalShowHide(true, null, {})}>
                        <Button variant="outline-light" className="new-button">New</Button>
                    </Link>}
                </Col>
                <DatatableComponent id="publication" data={this.state.publicationData} columns={this.columns} pagination="true" />
            </Row>
            {showHideModal &&
                <NewPublication
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handlePublicationSave={this.handlePublicationSave}
                    handlePublicationUpdate={this.handlePublicationUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    submission_id={this.props.submissionDetails.id}
                />}
                 {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
        </>
    }
}
export default withRouter(Publication);