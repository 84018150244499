import React, { Component } from 'react';
import { Col, Form } from 'react-bootstrap';
import API from '../../../../api/apis';
import { isCoordinator } from '../../../ToReviewDetails/commonFunctions';
import UserContext from '../../../../context/UserContext'

class ImpactScale extends Component {
  static contextType = UserContext;
  ISD = this.props.submissionDetails?.impact_scale_details;

  state = {
    impact_scale_details: [],
    responses: {
      question1: (typeof (this.ISD?.find(p=>p.question==="1")?.choice) === 'boolean') ? (this.ISD?.find(p=>p.question==="1").choice ? "Yes" : "No") : "" || "",
      question2: (typeof (this.ISD?.find(p=>p.question==="2")?.choice) === 'boolean') ? (this.ISD?.find(p=>p.question==="2").choice ? "Yes" : "No") : "" || "",
      question3: (typeof (this.ISD?.find(p=>p.question==="3")?.choice) === 'boolean') ? (this.ISD?.find(p=>p.question==="3").choice ? "Yes" : "No") : "" || "",
      question4: (typeof (this.ISD?.find(p=>p.question==="4")?.choice) === 'boolean') ? (this.ISD?.find(p=>p.question==="4").choice ? "Yes" : "No") : "" || "",
      objectives: (this.ISD?.find(p=>p.question==="1")?.option)?.toString() || "",

      study_published: this.ISD?.find(p=>p.question==="2")?.option || [],
      study_warrant: this.ISD?.find(p=>p.question==="3")?.additional_info || "",
      guideline: this.ISD?.find(p=>p.question==="4")?.additional_info || "",
    },

  };

  get inputs() {
    return [
      {
        label: "1. Were primary and/or secondary objectives met? (+ or -)",
        name: "objectives",
        id: "question1",
        subQuestions: [
          { type: "radio", options: ["All objectives met", "Some Objectives Met"] },
        ],
      },
      {
        label: "2. Was the study published / disclosed externally? (excluding clinical trial registries)",
        name: "study_published",
        id: "question2",
        subQuestions: [
          { type: "checkbox", options: ["Abstract", "Manuscript", "Poster", "Oral Presentation"] },
        ],
      },
      {
        label: "3. Did conclusion from this study warrant further investigation?",
        name: "study_warrant",
        id: "question3",
        subQuestions: [
          { type: "text", placeholder: "Please elaborate"  },
        ],
      },
      {
        label: "4. Did conclusion of this study lead to one or more of the following: guideline change, SOC change, NILEX?",
        name: "guideline",
        id: "question4",
        subQuestions: [
          { type: "text", placeholder: "Please elaborate" },
        ],
      },
    ];
  }
  async componentDidMount() {
    let _isCoordinator = isCoordinator(this.context);

    this.setState({
      is_Coordinator: _isCoordinator,


    });
  }

  renderInputs = () => {
    return this.inputs.map((field, index) => {
      const val = this.state.responses[field.name];
      const Values = ["Yes", "No"]
      const ids = this.state.responses[field.id]


      return (
        <Col key={field.label}>
          <div>
            <label className="mt-2 font-weight-bold">{field.label}</label>
            <div className="ml-2"> {Values.map((item, index) => (
              <Form.Check
                key={index}
                name={field.id}
                value={item}
                checked={ids === item}
                inline
                disabled={!this.state.is_Coordinator}
                onChange={this.onInputChange}
                label={item}
                type={"radio"}
              />
            ))}</div>

            {field.subQuestions && field.subQuestions.map((subQuestion) => (

              <div key={subQuestion.id} className='ml-2'>

                {subQuestion.type === "radio" && subQuestion.options.map((subOption, index) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>   {String.fromCharCode(
                    97 + index) + ".\u00a0"}
                    <Form.Check
                      key={subOption}
                      name={field.name}
                      value={subOption}
                      checked={val === subOption}

                      onChange={this.onInputChange}
                      disabled={ids != "Yes"}
                      label={subOption}
                      type={subQuestion.type}
                    /></div>
                ))}
                {subQuestion.type === "checkbox" && subQuestion.options.map((subOption, index) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>   {String.fromCharCode(
                    97 + index) + ".\u00a0"}
                    <Form.Check
                      key={subOption}
                      name={field.name}
                      value={subOption}
                      checked={val.includes(subOption)}

                      onChange={this.onInputChange}
                      disabled={ids != "Yes"}
                      label={subOption}
                      type={subQuestion.type}
                    /></div>
                ))}
                {subQuestion.type === "text" && (
                  <div style={{ display: "flex", flexDirection: "row" }}>   {"a" + "."}
                    <Form.Control
                      type="text"
                      name={field.name}
                      className="bottom-border-input shadow-none "
                      autoComplete="none"
                      style={{ backgroundColor: "#DEDEDE", maxWidth: "50%" }}
                      // disabled={ids != "Yes"}
                      placeholder={subQuestion.placeholder}
                      value={val}
                      //label={`${String.fromCharCode(97+index)}`+"."}
                      onChange={this.onInputChange}
                    /></div>
                )}
              </div>
            ))}
          </div>
        </Col>
      );
    });
  };

  impactScaleData = () => {

    let impact_scale_details = [];
    let finalArray = [
      {
        question: 1,
        choice: this.state.responses.question1 ? (this.state.responses.question1 === "Yes" ? true : false) : null,
        option: [this.state.responses.objectives],
        //choice:this.state.responses.question1,
        //id:this.ISD[0]?.id || "",

      },
      {
        question: 2,
        choice: this.state.responses.question2 ? (this.state.responses.question2 === "Yes" ? true : false) : null,
        option: this.state.responses.study_published,
        //id:this.ISD[1]?.id || "",
      },
      {
        question: 3,
        choice: this.state.responses.question3 ? (this.state.responses.question3 === "Yes" ? true : false) : null,
        additional_info: this.state.responses.study_warrant,
        //id:this.ISD[2]?.id || "",
      },
      {
        question: 4,
        choice: this.state.responses.question4 ? (this.state.responses.question4 === "Yes" ? true : false) : null,
        additional_info: this.state.responses.guideline,
        //id:this.ISD[3]?.id || "",
      }];
    impact_scale_details.push(...finalArray)
    this.setState({
      impact_scale_details
    })
    return impact_scale_details

  }
  onInputChange = (e) => {
    const { name, value, type, checked } = e.target

    if (type === 'checkbox') {
      if (value === 'No') {
        if (name === 'question2') {
          this.setState((prevState) => ({
            ...prevState,
            responses: {
              ...prevState.responses,
              study_published: [],
              [name]: 'No',
            },
          }));
        }
      } else {
        const updatedArray = checked
          ? [...this.state.responses?.study_published, value]
          : this.state.responses[name].filter((item) => item !== value)

        this.setState((prevState) => ({
          responses: {
            ...prevState.responses,
            [name]: updatedArray,
          }
        }))
      }
    } else {
      if (value === 'Yes') {
        this.setState((prevState) => ({
          ...prevState,
          responses: {
            ...prevState.responses,
            [name]: value,
          },

        }));
      }

      else if (value === 'No') {
        if (name === 'question1') {
          this.setState((prevState) => ({
            ...prevState,
            responses: {
              ...prevState.responses,
              objectives: "",
              [name]: 'No',
            },
          }));
        } else if (name === 'question3') {
          this.setState((prevState) => ({
            ...prevState,
            responses: {
              ...prevState.responses,
              study_warrant: '',
              [name]: 'No',
            },
          }));
        } else if (name === 'question2') {
          this.setState((prevState) => ({
            ...prevState,
            responses: {
              ...prevState.responses,
              study_published: [],
              [name]: 'No',
            },
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            responses: {
              ...prevState.responses,
              guideline: '',
              [name]: 'No',
            },
          }));
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          responses: {
            ...prevState.responses,
            [name]: value,
          },
        }));
      }
    }
    setTimeout(() => {
      console.log(this.state.responses, 'respk')
    }, 2000);

  };



  render() {
    return (
      <div>
        {this.renderInputs()}


      </div>
    );
  }
}


export default ImpactScale;