import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import DatatableComponent from '../../common/datatable/DatatableComponent';
import DrugOptionsForSiteModal from './DrugOptionsForSiteModal'
import API from '../../../api/apis';
import swal from 'sweetalert';
import BootstrapModal from '../../common/modal/BootstrapModal';
import UserContext from '../../../context/UserContext';
import { enableWFActions } from '../../../utility/helperFunctions';
class DrugOptionsForSite extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        showLoader: false,
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        data: [],
        error: false,
        successModal: false,
        errorModal: false,
        name:this.props.name,
    }
    componentDidMount() {
        console.log("siteId",this.props.siteId)

        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:   selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
        this.getDrugDeliveryBySubmissionId(this.props.siteId);
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create:   selectedRole.includes("administrator") || selectedRole.includes("coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getDrugDeliveryBySubmissionId = async (siteId) => {
        try {
            const res = await API.get(`/sites/sitedrugoptions/all/${siteId}`)
            let sortedReviews = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            this.setState({ data: sortedReviews });
           
            
        }
        catch (error) {
            console.log("error", error);
        }
    }

    handleModalShowHide =  async (flag, viewEditFlag, row) => {
        if (row && row.id && viewEditFlag === "edit") {
            await this.getDrugDeliverSiteById(row.id)
            this.setState({ showHideModal: flag, viewEditFlag });
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
    }
    getDrugDeliverSiteById = async (id) => {
        try {
            const expenseDetails = await API.get(`/sites/sitedrugoptions/getbyid/${id}`);
            this.setState({
                viewEditData: expenseDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    // handleDrugDeliverSiteDetails = (row) => {
    //     this.props.history.push(`/drugoptions/details`, { drugDeliveryId: row.site_id,drugdeliverysiteId:this.props.siteId ,submissionId:this.props.submissionId});
    // }
    handleDrugDeliverSiteDelete = async (row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                try {
                    const res =  API.post(`sites/sitedrugoptions/remove/${row.id}`);
                    swal("Deleted Successfully", {
                        icon: "success",
                      });
                      this.getDrugDeliveryBySubmissionId(this.props.siteId);
                }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
                this.getDrugDeliveryBySubmissionId(this.props.siteId);
            } 
          });
        // try {
        //     const res = await API.delete(`sites/drugdeliverysites/remove/${row.id}`);
        //     this.setState({
        //         success_body: "Deleted successfully!",
        //         successModal: true,
        //         hasChanged: false
        //     });
        //     this.getStudyContactDetailsBySubmissionId(this.props.submissionDetails.id)
        // } catch (err) {
        //     this.setState({ errorModal: true });
        // }
    }
    handleDrugOptionsForSiteSave = async(state) => {
        state.created_by = this.context.userSystemId;
        state.site_id=this.props.siteId;
        // state["irb_erb_expiration_date"] = state.irb_erb_expiration_date != "" && state.irb_erb_expiration_date != null ?
        // new Date(state.irb_erb_expiration_date).toISOString() : null;
        // state["irb_erb_start_date"] = state.irb_erb_start_date != "" && state.irb_erb_start_date != null ?
        // new Date(state.irb_erb_start_date).toISOString() : null;
        try {
            const res = await API.post(`/sites/sitedrugoptions/${this.props.siteId}/upsert`,{"siteDrugOptions": {...state}});
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getDrugDeliveryBySubmissionId(this.props.siteId);
    
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleDrugOptionsForSiteUpdate =async (state) => {
        state.updated_by = this.context.userSystemId;
        state.last_modified_by_id=this.context.userSystemId;
        state.site_id=this.props.siteId;
       
        
        try {
            const res = await API.post(`/sites/sitedrugoptions/${this.props.siteId}/upsert`,{"siteDrugOptions": {...state}});
          
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getDrugDeliveryBySubmissionId(this.props.siteId);
    
        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    columns = [{
        dataField: 'id',
        text: 'Record ID',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                    onClick={() => this.handleDrugDeliverySiteDetails(row)}>{`Site-Drug-${row.id}`}</p>
            </>
        }
    },, {
        dataField: 'compound[name]',
        text: 'Compound',
        sort: true
    }, {
        dataField: 'presentation[name]',
        text: 'Presentation ',
        sort: true
    },
    {
        dataField: 'strength[name]',
        text: 'Strength',
        sort: true
    },
    {
        dataField: '',
        text: "Actions",
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.access.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.access.delete ? "visible" : "hidden";
            return <> {enableWFActions()&&<>
                <i className="bi bi-trash-fill" onClick={() => this.handleDrugDeliverSiteOptionsDelete(row)}
                    style={{  visibility: visibilityDelete ,fontSize: "1.2rem", color: "black", padding: ".1rem", float: "right" }}></i>
                <i className="bi bi-pencil-square"  onClick={() => this.handleModalShowHide(true, "edit", row)}
                 style={{ visibility: visibilityEdit ,fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "right" }}></i>
            </>}</>
        }
    }];

    data = [
        {
            record_ID: 'Record ID',
            compound: 'Compound',
            request_Type: 'Request Type',
            strength: 'Strength',
        }
    ]

    handleDrugDeliverSiteOptionsDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })

          
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    const res = await API.post(`recycle/sitedrugoption/delete/${row.id}/${this.props.submission_id}`,{system_id : this.context.userSystemId});
                    this.setState({
                        success_body: "Deleted successfully!",
                        successModal: true,
                        hasChanged: false
                    });
                    this.getDrugDeliveryBySubmissionId(this.props.siteId);
                } catch (err) {

                    this.setState({ errorModal: true });
                }
              
            } 
          });

    }
    handleDrugDeliverySiteDetails = (row) => {
        this.props.history.push(`/drugoptions/details/${row.id}`, { drugdeliverysiteId: row.id,siteHistoryId:this.props.siteId });
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
      
    }



    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal } = this.state;
        return <>
            <Row>
                <Col md={4}>
                    <h6 className="table-top"> Drug Options for Site ({this.state.data.length})</h6>
                </Col>
                <Col md={3}>
                   {this.state.access.create && enableWFActions()&&  <Link as={Button}>
                        <Button variant="outline-light" className="new-button" disabled={this.state.data?.length>=7} onClick={() => this.handleModalShowHide(true, null, {})}>New</Button>
                    </Link>}
                </Col>
                <DatatableComponent id="drugOptions" data={this.state.data} columns={this.columns} pagination="true"  />
            </Row>
            {
                showHideModal &&
                <DrugOptionsForSiteModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleDrugOptionsForSiteSave={this.handleDrugOptionsForSiteSave}
                    handleDrugOptionsForSiteUpdate={this.handleDrugOptionsForSiteUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    name={this.props.name}
                />
            }
             {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
        </>
    }
}

export default withRouter(DrugOptionsForSite);