import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row, Dropdown } from 'react-bootstrap';
import DatatableComponent from '../datatable/DatatableComponent';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import UserContext from '../../../context/UserContext';
import swal from 'sweetalert';
import API from '../../../api/apis';
import DatatTableEx from '../DataTableEx';
import ToolTipEx from '../ToolTipEx';
import { DELEGEE, DEFAULT } from '../../../TooltipInfo';
import { isCoordinator, showMessage } from './../../ToReviewDetails/commonFunctions';
import LoaderEx from '../loader/LoaderEx';
import DTAButton from "../Button/DTAButton";
import DelegeeModalEx from './DelegeeModalEx';



class DelegeeListEx extends Component {
    static contextType = UserContext;

    state = {
        delegeeList: [],
        showHideModal: false,
        viewEditFlag: null,
        loading: true
    }
    columns = [{
        dataField: 'id',
        text: 'id',
        sort: false,
        hidden: true,
        csvExport: false,
        excludeFilter: true,
        
    }, {
        dataField: 'name',
        text: 'Delegee Name',
        sort: true,
        sortValue: (cell, row) =>+(row.id??"0"),
    },
    {
        dataField: 'first_name',
        text: 'Delegee First Name',
        sort: true,
    },
    {
        dataField: 'last_name',
        text: 'Delegee LastName',
        sort: true,
    },
    {
        dataField: 'email',
        text: 'Delegee Email',
        sort: true,
    },
    {
        dataField: 'submissionId',
        text: 'Submission Number',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "marginBottom": "0" }}
                >{`Submission-${row.submissionId}`}</p>
            </>
        }
    },

    {
        dataField: '',
        text: "Actions",
        csvExport: false,
        headerClasses: "width-90",
        excludeFilter: true,
        headerAlign: 'center',
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        formatter: (cell, row, rowIndex) => {
            return this.state.is_Coordinator ?
                <>
                    <div className='ml-auto' >
                        <ToolTipEx tooltip={DEFAULT.DELETE} >
                            <DTAButton onClick={() => this.handleDelegeeDelete(row)} icon="DELETE" text='DELETE' ></DTAButton>
                        </ToolTipEx>
                    </div>
                </>
                : <></>
        }
    }];
    SERVICES = {
        'GET-DELEGEE-LIST': 'delegee/getdelegeelist/list',
        'DELETE-DELEGEE': 'delegee/delete',
    }
    async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.setState({
            is_Coordinator: _isCoordinator,
            loading: false
        }, async () => await this.getDelegeeList());
    }

    getDelegeeList = async () => {
        this.setState({
            tableLoading: true,
        })
        try {
            const res = await API.get(`${this.SERVICES['GET-DELEGEE-LIST']}`);
            if (res && res .data && res.data.success) {
                if (res.data?.data) {
                    this.setState({
                        delegeeList: res.data.data,
                        tableLoading: false
                    });
                }
                else {
                    this.setState({
                        delegeeList: [],
                        tableLoading: false
                    });
                }
            } else {
                this.setState({
                    delegeeList: [],
                    tableLoading: false
                });
                showMessage({ text: "Failed to get data", icon: "error", button: "Ok" });
            }
        }
        catch (e) {
            console.log(e);
            showMessage({ text: "Failed to get data", icon: "error", button: "Ok" });
        }
    }
    rowEvents = {
        onClick: (e, row, rowIndex) => {
            this.handleModalShowHide(true, row.id)
        }
    };
    handleModalShowHide = async (flag, id, reload) => {
        if (id) {
            this.setState({ showHideModal: flag, delegeeId: id });
        } else {
            this.setState({ showHideModal: flag, delegeeId: null }, async () => {
                if (reload) {
                    await this.getDelegeeList();
                }
            });
        }
    }
    handleDelegeeDelete = async (row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const res = await API.post(`${this.SERVICES['DELETE-DELEGEE']}/${row.id}`);
                        if (res.data.success) {
                            swal("Deleted Successfully", {
                                icon: "success",
                            });
                            this.getDelegeeList();
                        } else {
                            showMessage({ text: "Failed to delete", icon: "error", button: "Ok" });
                        }
                    }
                    catch (err) {
                        showMessage({ text: "Failed to delete", icon: "error", button: "Ok" });
                    }
                }
            });

    }
    renderPageIcons = () => {
        return this.state.is_Coordinator ?
            <ToolTipEx tooltip={DELEGEE.ADD}>
                <DTAButton onClick={() => this.handleModalShowHide(true)} icon="ADD" text={DELEGEE.ADD}></DTAButton>
            </ToolTipEx> : <></>
    }

    render() {
        const { showHideModal, viewEditFlag, viewEditData, data, loading, success_body, errorModal, tableLoading, delegeeId } = this.state;
        return <>
            <div className="inpage-content">
                {loading ? <LoaderEx /> : <Row>

                    <DatatTableEx
                        id="delegeeList"
                        data={this.state.delegeeList}
                        csvName='Delegee'
                        showLoader={tableLoading}
                        title={
                            <Alert.Heading>
                                <span><i className="bi bi-diagram-3" style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                                Delegee</Alert.Heading>
                        }
                        columns={this.columns}
                        autoFilter={true}
                        filterChange={() => { }}
                        rowEvents={this.rowEvents}
                        pageIcons={this.renderPageIcons()}
                    // pagination="true"
                    />
                </Row>}
            </div>
            {showHideModal ?
                <DelegeeModalEx
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    delegeeId={delegeeId}
                /> : <></>}
        </>
    }
}

export default withRouter(DelegeeListEx);
