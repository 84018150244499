import React from 'react'
import {Modal,Button, Row, Col} from 'react-bootstrap';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import InputField from '../../common/input-fields/InputField';
import Form from 'react-bootstrap/Form';
import { showMessage} from '../commonFunctions';
import {DateTime} from "luxon"
import { yyyymmddFormat } from '../../common/dateFormat';
import PropTypes from 'prop-types';
import FileUploader from '../FileUploader';
import { downloadSharepointFile, getSharepointFiles } from './commonReviewFn';
import { dateStringformat } from '../../../utility/helperFunctions';
import BaseReview from './BaseReview';
export default class ConceptReview extends BaseReview{
    constructor(props){
        super(props);
        this.state={
            reviewType:'',

            reviewForm:{               
            },
            errors:{}
        }
        this.state=this.INITIAL_STATE;
        console.log(this.props);
    }
    INITIAL_STATE={
        externalurl:'',
        investigatorName:  "",     
        formView:this.props.formView,            
        conceptTitle:"",
        fileInfo:{
            selectedFileName:'',
            file:null
        },
        sharePointFileInfo:{
            selectedFileName:'',
            customName:'',
            file:null
        },
        downloadableDocuments:[],
        reviewType:'',
        reviewForm:{
            "name_medical_reviewer_committee": "",
            "date_of_review": new Date(),
            "proposal_for_proposed_study": false,
            "comments": "",
            "submission_id": "",
            "reviewers_detail_id": "",
            "review_header_id": "",
            "status": "",
            "is_coordinator_entry": false,
            "is_committee_review": false,
            "name_of_reviewer": "",
            "concept_in_scope": null,
            "reason_for_not_inviting_proposal": "",
            "rational_for_not_inviting_proposal": "",
            "name_of_committee": "",
            "decision_rationale": "",
            "is_all_review_complete": null,
            "is_review_cycle_complete": null,
            "is_proposal_for_study_invited": null,
            "details_for_pi": "",
            concept_approved:null
        }
    }
    get InputElements(){
        let inputArrays= [
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Is this a committee review?",
                name: "is_committee_review",
                options: ["Yes", "No"],
                formType:[],
                committeOrder:1,
                reviewOrder:1
            },
            {
               
                type: "text",
                placeholder: "Name of Committee",
                label: "Name of Committee *",
                name: "name_medical_reviewer_committee",
                formType:["COMMITTEE"],
                committeOrder:2
            },
            {
               
                type: "text",
                placeholder: "Name of reviewer(s) or committee",
                label: "Name of reviewer(s) or committee *",
                name: "name_medical_reviewer_committee",
                formType:["COORDINATOR"],
                committeOrder:2
            },
            
            {
                label:"Name of reviewer(s) *",
                type: "text",
                placeholder: "Name of reviewer(s)",
                name: "name_of_reviewer",
                formType:["REVIEWER"],                
                reviewOrder:2
            },
            {
                label:"Concept in scope? *",
                type: "dropdown",
                placeholder: "Please Select",                
                name: "concept_in_scope",
                options: ["Yes", "No"],
                formType:["COMMITTEE","REVIEWER"],
                reviewOrder:4,
                committeOrder:4,
                col:6
            },
            {   label:"Review Date *",
                placeholder: "Review Date",
                type: "date",
                name: "date_of_review",               
                // minDate: new Date(),
                formType:["COMMITTEE","REVIEWER","COORDINATOR"] ,
                reviewOrder:3,
                committeOrder:3,
                col:6          
            },            
            {
                label:"Final review decision *",
                type: "dropdown",
                placeholder: "Please Select",                
                name: "status",
                options: ["Approved", "Declined","On Hold","Contingent"],
                formType:["COORDINATOR"]
            },
            {   label:"Decision rationale",
                placeholder: "Decision rationale",
                type: "textarea",
                name: "decision_rationale",
                formType:["COORDINATOR"]               
            },
             
            {
               
                type: "dropdown",
                label:"Would you like to request a proposal? *",
                placeholder: "Please Select",                
                name: "concept_approved",
                options: ["Yes", "No"],
                formType:["REVIEWER"],
                reviewOrder:5,
                col:6
            },
           
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Did committee invite a proposal for this study? *",
                name: "is_proposal_for_study_invited",
                options: ["Yes", "No"],
                formType:["COMMITTEE"]
            },
            {
               
                type: "text",
                placeholder: "Reason / Rationale for not inviting a proposal",
                 label: "Reason / Rationale for not inviting a proposal",
                name: "reason_for_not_inviting_proposal",
                formType:[],
                reviewOrder:7,
            },
            {
                placeholder: "Feedback for PI",
                label: "Feedback for PI",
                type: "textarea",
                name: "details_for_pi",
                formType:["COMMITTEE"]               
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are all reviews complete?",
                name: "is_all_review_complete",
                options: ["Yes", "No"],
                formType:["COORDINATOR"]
            },
            {               
                type: "dropdown",
                placeholder: "Please Select",
                label: "Are all review cycles complete?",
                name: "is_review_cycle_complete",
                options: ["Yes", "No"],
                formType:["COORDINATOR"]
            },          

            {
                placeholder: "Comments",
                label:"Comments",
                type: "textarea",
                name: "comments",
                helpAvailability:["REVIEWER"],
                helptext:"Please include your rationale for not inviting a proposal or for inviting a proposal that is out of scope. Other comments may be included as well.",
                formType:["COMMITTEE","REVIEWER","COORDINATOR"]  ,
                reviewOrder:6,              
            },
            // {
            //     placeholder: "Rational for not inviting proposal",
            //     label: "Rational for not inviting proposal",
            //     type: "textarea",
            //     name: "rational_for_not_inviting_proposal",
            //     formType:["REVIEWER"]   ,
            //     reviewOrder:8,             
            // }
        ];
        return inputArrays;
    }
    validateInput=()=>{
        // let {reviewForm,errors}=this.state;
        // errors={};
        // let is_valid=true;
        // let exclude=["comments","details_for_pi","reason_for_not_inviting_proposal","rational_for_not_inviting_proposal","is_review_cycle_complete"];
        // this.getFields().forEach((item)=>{
           
        //     if(!exclude.includes(item.name)){
        //         if(!reviewForm[item.name]){
        //             errors[item.name]=true;
        //             is_valid=false;
        //         }
                
        //     }
        // });
        // if(errors){
        //     errors["reason_for_not_inviting_proposal"]=false;  
        // }              
        // this.setState({errors:{...errors}})      
        // return is_valid;

        let {reviewForm,errors}=this.state;
        errors={};
        let is_valid=true;
        let include=["name_medical_reviewer_committee","date_of_review","is_proposal_for_study_invited","name_of_reviewer","concept_in_scope","concept_approved","status",];
        this.getFields().forEach((item)=>{
           
            if(include.includes(item.name)){
                if(this.isUndefinedOrNull(reviewForm[item.name])){
                    errors[item.name]=true;
                    is_valid=false;
                }
                
            }
        });
        this.setState({errors:{...errors}})      
        return is_valid;
    }
    
    render(){
        return <>
        {this.renderTopArea()}
         <Row>
             {this.renderInputs()}
             {this.renderFileArea()}
             {this.renderFileDownloadArea()}
         </Row></>
    }
}