import React from "react";
import DatatTableEx from "../../../common/DataTableEx";
import { dateStringformat, dateTimeformat } from "../../../../utility/helperFunctions";
import { Col, Row } from 'react-bootstrap';
import API from "../../../../api/apis";
import { iirDateFormat } from "../../../../utility/dateHelper";

export default class HistoryList extends React.Component{
    constructor(props){
        super(props);    
        this.state={
            auditData:[]
        }    
    }
    async componentDidMount(){
        await this.fetchStaticData();
        await this.getAuditList();
    }
    componentDidUpdate(prevProps,prevState){
        if(prevProps.historyData!=this.props.historyData){
            this.getAuditList();  
        }
    }
    columns = [
        {
            dataField: 'id',
            text: 'id',
            hidden: true,
            csvExport:false
        },
        {
        dataField: 'created_updated_at',
        text: 'Date',
        sort: true,
        sortValue: (cell, row) => new Date(row["created_updated_at"]),
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.created_updated_at}</p>

            </>
        }
    }, {
        dataField: 'field_label',
        text: 'Field',
        sort: true
    },{
        dataField: 'created_updated_by',
        text: 'User',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{ row.created_updated_by }</p>

            </>
        }


    },
    {
        dataField: 'old_value',
        text: 'Original Value',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.old_value !=null ? (row.old_value):""}</p>

            </>
        },
        csvFormatter: (cellContent, row) => { return row && (row.old_value != null) ? row.old_value : "" }
    }, {
        dataField: 'new_value',
        text: 'New Value',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
                >{row.new_value !=null ? (row.new_value):""}</p>

            </>
        },
        csvFormatter: (cellContent, row) => { return row && (row.new_value != null) ? row.new_value : "" }
    }, {
        dataField: 'row_type',
        text: 'Row Type',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>{row.row_type != null ? row.row_type : ""}</>;
        },
    }];
    getTrailList = async (id) => {
        try {
            const res = await API.get(`/user/fieldlabels/site`)           
            this.setState({ trailData: res.data })
        }
        catch (err) {
            console.log(err)
        }
    }
    getAllCountryList = async () => {
        const res = await API.get("seed/country/getall");
        this.setState({ countryAllList: res.data.data });

    }
    getAllStateList = async () => {      
            const res = await API.get(`seed/state/list`);
            this.setState({ 
                stateAllList: res.data,
            });
    }
    getUserList = async () => {
        const res = await API.get(`user/data`)
       
        this.setState({usersList: res.data});
    }
    fetchStaticData=async()=>{
        // await this.getTrailList();
        // await this.getUserList();
        await this.getAllCountryList();
        await this.getAllStateList();
    }
    getAuditList = async (id) => {
        try {
            let formatValues = (this.props.historyData || []).map((item, index) => {
                let { created_updated_at, old_value, new_value, field_label, field, ...otherItems } = item;

                created_updated_at = created_updated_at != null ? iirDateFormat(created_updated_at, true) : "";

                if (['irb_erb_start_date', 
                    'irb_erb_expiration_date', 
                    ].includes(field)) {
                    old_value = old_value != null ? iirDateFormat(old_value) : "";
                    new_value = new_value != null ? iirDateFormat(new_value) : ""
                }
                if ( field === 'country_id' ) {
                    let oldCountry = old_value != null ? this.state.countryAllList.filter(x=> x.id+"" === item.old_value )[0] : "";
                    let newCountry = new_value != null ? this.state.countryAllList.filter(x=> x.id+"" === item.new_value )[0] : "";
                    old_value = oldCountry && oldCountry?.name ? oldCountry.name : '';
                    new_value = newCountry && newCountry?.name ? newCountry.name : '';
                }
                if ( field === 'state_province_id' ) {
                    let oldState = old_value != null ? this.state.stateAllList.filter(x=> x.id+"" === item.old_value )[0] : "";
                    let newState = new_value != null ? this.state.stateAllList.filter(x=> x.id+"" === item.new_value )[0] : "";
                    old_value = oldState && oldState?.name ? oldState.name : '';
                    new_value = newState && newState?.name ? newState.name : '';
                }
                if ( field_label === 'Updated At' ||
                    field === 'updated_at' ) {
                    old_value = old_value != null ? iirDateFormat(old_value, true) : "";
                    new_value = new_value != null ? iirDateFormat(new_value, true) : ""
                }
                if (field_label === 'Created.' ||
                    field === 'created_at') {

                    new_value = new_value != null ? iirDateFormat(new_value, true) : "";
                }

                return {
                    created_updated_at, old_value, new_value, field_label, field, ...otherItems
                }
            })
            
            this.setState({
                auditData: formatValues,
            })
        }
        catch (err) {
            console.log(err)
        }
    }
    render(){
        const {historyTitle, historyData}= this.props;
        return (
            <Row>
                <Col md={7}>
                    <h5 class="table-top font-weight-bold">
                    {historyTitle}</h5>
                </Col>
                <DatatTableEx id="historyFiles" data={this.state.auditData||[]} columns={this.columns}  pagination="true" csvName='Drug Delivery Site History' />
            </Row>
        )
    }
}